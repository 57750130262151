import React from 'react';
import { ITableDataSource } from 'models/table';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { emptyFieldHolder } from 'components/molecules/CardList';
import { useTranslations } from 'hooks/useTranslations';
import { EPermission } from 'models/permissions';
import { usePermission } from 'hooks/usePermission';

interface IFirearmReferenceTableDetailsCard {
  data: ITableDataSource;
  isLocal?: boolean;
  handleSelect?: () => void;
  handleEdit?: (args: string) => void;
}

const FRTSpecificationsCard = ({
  data,
  isLocal = true,
  handleSelect,
  handleEdit,
}: IFirearmReferenceTableDetailsCard) => {
  const { t } = useTranslations();
  const { hasPermissions } = usePermission();

  return (
    <Grid
      container
      sx={{
        border: colors.gray200,
        borderRadius: '4px',
        overflow: 'hidden',
        mt: 1,
        backgroundColor: colors.gray100,
      }}
    >
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {isLocal
            ? t(
                'firearmsReferenceTable.editFirearm.general.accordion.firearmReferenceLocalID.label',
              )
            : t(
                'firearmsReferenceTable.editFirearm.general.accordion.firearmReferenceNo.label',
              )}
        </Typography>
        <Typography variant="body2">
          {emptyFieldHolder(isLocal ? data.specificationId : data.id)}
        </Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('firearmsReferenceTable.editFirearm.calibre.accordion.calibre.label')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.caliber)}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('firearmsReferenceTable.editFirearm.calibre.accordion.shots.label')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.shots)}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('firearmsReferenceTable.editFirearm.calibre.accordion.barrel.label')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.barrelLength)}</Typography>
      </Grid>
      <Grid item xs={12} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('firearmsReferenceTable.editFirearm.calibre.accordion.level.label')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.level)}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('firearmsReferenceTable.editFirearm.calibre.accordion.legalClassification.label')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.legalClassification)}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('firearmsReferenceTable.editFirearm.calibre.accordion.barrelTypeCode.label')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.barrelType)}</Typography>
      </Grid>

      {(handleSelect || (handleEdit && isLocal)) && (
        <Grid
          container
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderTop: {
              xs: `1px solid ${colors.primary25}`,
              lg: 'none',
            },
          }}
          p={2}
        >
          <>
            <Grid item xs={12} sm={3} sx={{ mb: { xs: 2, sm: 0 } }}>
              <Typography variant="button" sx={{ textTransform: 'uppercase' }}>
                {t('firearmsReferenceTable.editFirearm.calibre.accordion.action.label')}:{' '}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              {handleSelect && (
                <Button
                  label={t(
                    'firearmsReferenceTable.editFirearm.calibre.accordion.select.label',
                  )}
                  variant={EButtonVariants.outlined}
                  onClick={handleSelect}
                  size="small"
                  fullWidth
                />
              )}
              {handleEdit && isLocal && hasPermissions([EPermission.MANAGE_FRT_FIREARM]) && (
                <Button
                  label={t('firearmsReferenceTable.editFirearm.calibre.accordion.edit.label')}
                  variant={EButtonVariants.outlined}
                  onClick={() => handleEdit(data.id as string)}
                  size="small"
                  fullWidth
                />
              )}
            </Grid>
          </>
        </Grid>
      )}
    </Grid>
  );
};

export { FRTSpecificationsCard };
