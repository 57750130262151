import React, { SyntheticEvent, useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { StyledPageTitle } from 'theme/styles';
import { useQuery } from 'hooks/useQuery';
import { getGlobalSearchResults } from 'requests/dashboard';
import {
  EGlobalSearchCategories,
  GlobalSearchCategoriesPermissions,
  IGlobalSearchAccessory,
  IGlobalSearchAccessoryResponse,
  IGlobalSearchAmmunition,
  IGlobalSearchAmmunitionResponse,
  IGlobalSearchComponent,
  IGlobalSearchComponentResponse,
  IGlobalSearchFirearm,
  IGlobalSearchFirearmsResponse,
  IGlobalSearchLEIndividual,
  IGlobalSearchLEIndividualResponse,
  IGlobalSearchLEOrganization,
  IGlobalSearchLEOrganizationResponse,
  IGlobalSearchTransaction,
  IGlobalSearchTransactionsResponse,
  IGlobalSearchUser,
  IGlobalSearchUsersResponse,
} from 'models/dashboard';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import { Box, Typography } from '@mui/material';
import TabPanel from 'pages/DashboardSearchResultsPage/TabPanel';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import {
  NoResultWrapper,
  StyledTab,
  StyledTabs,
} from 'pages/DashboardSearchResultsPage/styles';
import { Table } from 'components/molecules/Table';
import {
  globalSearchAccessoriesTableColumns,
  globalSearchAccessoriesToDataSource,
  globalSearchAmmunitionTableColumns,
  globalSearchAmmunitionToDataSource,
  globalSearchComponentsTableColumns,
  globalSearchComponentsToDataSource,
  globalSearchFirearmsTableColumns,
  globalSearchFirearmsToDataSource,
  globalSearchLEIndividualTableColumns,
  globalSearchLEIndividualToDataSource,
  globalSearchLEOrgTableColumns,
  globalSearchLEOrgToDataSource,
  globalSearchTransactionsTableColumns,
  globalSearchTransactionsToDataSource,
  globalSearchUsersTableColumns,
  globalSearchUsersToDataSource,
} from 'pages/DashboardSearchResultsPage/helpers';
import { ELegalEntityTypes } from 'constants/LegalEntityTypes';
import GlobalSearch from 'pages/DashboardPage/GlobalSearch';
import { IPaginator } from 'models/paginator';
import { EPerPages, ISortOptions } from 'models/table';
import { Pagination } from 'components/molecules/Pagination';
import { useTranslations } from 'hooks/useTranslations';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { usePermission } from 'hooks/usePermission';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { CardList } from 'components/molecules/CardList';
import SearchResultLeIndTableCard from 'pages/DashboardSearchResultsPage/DashboardSearchResultsTableCard/SearchResultLEIndTableCard';
import SearchResultUserTableCard from 'pages/DashboardSearchResultsPage/DashboardSearchResultsTableCard/SearchResultUserTableCard';
import SearchResultLEOrgTableCard from 'pages/DashboardSearchResultsPage/DashboardSearchResultsTableCard/SearchResultLEOrgTableCard';
import SearchResultFirearmTableCard from 'pages/DashboardSearchResultsPage/DashboardSearchResultsTableCard/SearchResultFirearmTableCard';
import SearchResultTransactionTableCard from 'pages/DashboardSearchResultsPage/DashboardSearchResultsTableCard/SearchResultTransactionTableCard';
import SearchResultAccessoryTableCard from 'pages/DashboardSearchResultsPage/DashboardSearchResultsTableCard/SearchResultAccessoryTableCard';
import SearchResultAmmunitionTableCard from 'pages/DashboardSearchResultsPage/DashboardSearchResultsTableCard/SearchResultAmmunitionTableCard';
import SearchResultComponentTableCard from 'pages/DashboardSearchResultsPage/DashboardSearchResultsTableCard/SearchResultComponentTableCard';
import { useLang } from 'models/langContext';

const DashboardSearchResultsPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslations();
  const { hasPermissions } = usePermission();
  const [value, setValue] = useState(0);
  const { selectedLanguage } = useLang();

  const [leOrgList, setLeOrgList] = useState<IGlobalSearchLEOrganization[]>([]);
  const [leOrgCount, setLeOrgCount] = useState(0);
  const [leOrgPaginator, setLeOrgPaginator] = useState<IPaginator | null>(null);
  const [currentPageLeOrg, setCurrentPageLeOrg] = useState<number>(1);
  const [perPageLeOrg, setPerPageLeOrg] = useState<EPerPages>(EPerPages.perPage25);
  const [leOrgSort, setLeOrgSort] = useState<ISortOptions | null>(null);

  const [leIndList, setLeIndList] = useState<IGlobalSearchLEIndividual[]>([]);
  const [leIndCount, setLeIndCount] = useState(0);
  const [leIndPaginator, setLeIndPaginator] = useState<IPaginator | null>(null);
  const [currentPageLeInd, setCurrentPageLeInd] = useState<number>(1);
  const [perPageLeInd, setPerPageLeInd] = useState<EPerPages>(EPerPages.perPage25);
  const [leIndSort, setLeIndSort] = useState<ISortOptions | null>(null);

  const [usersList, setUsersList] = useState<IGlobalSearchUser[]>([]);
  const [usersCount, setUsersCount] = useState(0);
  const [usersPaginator, setUsersPaginator] = useState<IPaginator | null>(null);
  const [currentPageUsers, setCurrentPageUsers] = useState<number>(1);
  const [perPageUsers, setPerPageUsers] = useState<EPerPages>(EPerPages.perPage25);
  const [usersSort, setUsersSort] = useState<ISortOptions | null>(null);

  const [transactionsList, setTransactionsList] = useState<IGlobalSearchTransaction[]>([]);
  const [transactionsCount, setTransactionsCount] = useState(0);
  const [transactionsPaginator, setTransactionsPaginator] = useState<IPaginator | null>(null);
  const [currentPageTransactions, setCurrentPageTransactions] = useState<number>(1);
  const [perPageTransactions, setPerPageTransactions] = useState<EPerPages>(
    EPerPages.perPage25,
  );
  const [transactionsSort, setTransactionsSort] = useState<ISortOptions | null>(null);

  const [firearmsList, setFirearmsList] = useState<IGlobalSearchFirearm[]>([]);
  const [firearmsCount, setFirearmsCount] = useState(0);
  const [firearmsPaginator, setFirearmsPaginator] = useState<IPaginator | null>(null);
  const [currentPageFirearms, setCurrentPageFirearms] = useState<number>(1);
  const [perPageFirearms, setPerPageFirearms] = useState<EPerPages>(EPerPages.perPage25);
  const [firearmsSort, setFirearmsSort] = useState<ISortOptions | null>(null);

  const [accessoriesList, setAccessoriesList] = useState<IGlobalSearchAccessory[]>([]);
  const [accessoriesCount, setAccessoriesCount] = useState(0);
  const [accessoriesPaginator, setAccessoriesPaginator] = useState<IPaginator | null>(null);
  const [currentPageAccessories, setCurrentPageAccessories] = useState<number>(1);
  const [perPageAccessories, setPerPageAccessories] = useState<EPerPages>(EPerPages.perPage25);
  const [accessoriesSort, setAccessoriesSort] = useState<ISortOptions | null>(null);

  const [ammunitionList, setAmmunitionList] = useState<IGlobalSearchAmmunition[]>([]);
  const [ammunitionCount, setAmmunitionCount] = useState(0);
  const [ammunitionPaginator, setAmmunitionPaginator] = useState<IPaginator | null>(null);
  const [currentPageAmmunition, setCurrentPageAmmunition] = useState<number>(1);
  const [perPageAmmunition, setPerPageAmmunition] = useState<EPerPages>(EPerPages.perPage25);
  const [ammunitionSort, setAmmunitionSort] = useState<ISortOptions | null>(null);

  const [componentsList, setComponentsList] = useState<IGlobalSearchComponent[]>([]);
  const [componentsCount, setComponentsCount] = useState(0);
  const [componentsPaginator, setComponentsPaginator] = useState<IPaginator | null>(null);
  const [currentPageComponents, setCurrentPageComponents] = useState<number>(1);
  const [perPageComponents, setPerPageComponents] = useState<EPerPages>(EPerPages.perPage25);
  const [componentsSort, setComponentsSort] = useState<ISortOptions | null>(null);

  const [showLimitAlert, setShowLimitAlert] = useState(false);
  const [isFinishLoading, setIsFinishLoading] = useState(false);
  const { isDesktop } = useThemeBreakpoint();

  const [isLEIndividualPaginationLoading, setIsLEIndividualPaginationLoading] =
    useState(false);
  const [isLEOrganisationPaginationLoading, setIsLEOrganisationPaginationLoading] =
    useState(false);
  const [isFirearmPaginationLoading, setIsFirearmPaginationLoading] = useState(false);
  const [isTransationPaginationLoading, setIsTransactionPaginationLoading] = useState(false);
  const [isUserPaginationLoading, setIsUserPaginationLoading] = useState(false);
  const [isAccessoryPaginationLoading, setIsAccessoryPaginationLoading] = useState(false);
  const [isAmmunitionPaginationLoading, setIsAmmunitionPaginationLoading] = useState(false);
  const [isComponentPaginationLoading, setIsComponentPaginationLoading] = useState(false);

  const queryString = useQuery();
  const query: string | null = queryString.get('query');
  const category: string | null = queryString.get('category');

  const queryStriped = query ? query.replace(/<\/?[^>]+(>|$)/g, '') : null;

  const highlightText = (text: string | null) => {
    if (query && text) {
      const regexPattern = new RegExp(query, 'gi');
      text = text.replace(/(<strong>|<\/strong>)/gim, '');
      const newText = text.replace(regexPattern, '<strong>$&</strong>');
      return <Typography variant="body2" dangerouslySetInnerHTML={{ __html: newText }} />;
    }
    return '-';
  };

  const fetchResults = async (
    queryCategory: EGlobalSearchCategories,
    currentPage: number,
    perPage: EPerPages,
    sort?: ISortOptions | null,
  ) =>
    query !== null &&
    query?.length > 1 &&
    (category === null || category === queryCategory) &&
    hasPermissions(GlobalSearchCategoriesPermissions[queryCategory], true)
      ? getGlobalSearchResults(query, queryCategory, currentPage, perPage, sort)
      : Promise.resolve({});

  const parseUserData = (usersData: IGlobalSearchUsersResponse) => {
    if (usersData?.totalElements) {
      const { content, ...paginatorData } = usersData;
      setUsersList(content);
      setUsersCount(usersData.totalElements);
      setUsersPaginator(paginatorData);
      setValue(4);
    } else {
      setUsersList([]);
      setUsersCount(0);
      setUsersPaginator(null);
    }
  };

  const parseTransactionData = (transactionsData: IGlobalSearchTransactionsResponse) => {
    if (transactionsData?.totalElements) {
      const { content, ...paginatorData } = transactionsData;
      setTransactionsList(content);
      setTransactionsCount(transactionsData.totalElements);
      setTransactionsPaginator(paginatorData);
      setValue(3);
    } else {
      setTransactionsList([]);
      setTransactionsCount(0);
      setTransactionsPaginator(null);
    }
  };

  const parseFirearmsData = (firearmsData: IGlobalSearchFirearmsResponse) => {
    if (firearmsData?.totalElements) {
      const { content, ...paginatorData } = firearmsData;
      setFirearmsList(content);
      setFirearmsCount(firearmsData.totalElements);
      setFirearmsPaginator(paginatorData);
      setValue(2);
    } else {
      setFirearmsList([]);
      setFirearmsCount(0);
      setFirearmsPaginator(null);
    }
  };

  const parseLeOrg = (leOrgData: IGlobalSearchLEOrganizationResponse) => {
    if (leOrgData?.totalElements) {
      const { content, ...paginatorData } = leOrgData;
      setLeOrgList(content);
      setLeOrgCount(leOrgData.totalElements);
      setLeOrgPaginator(paginatorData);
      setValue(1);
    } else {
      setLeOrgList([]);
      setLeOrgCount(0);
      setLeOrgPaginator(null);
    }
  };

  const parseLeInd = (leIndData: IGlobalSearchLEIndividualResponse) => {
    if (leIndData?.totalElements) {
      const { content, ...paginatorData } = leIndData;
      setLeIndList(content);
      setLeIndCount(leIndData.totalElements);
      setLeIndPaginator(paginatorData);
      setValue(0);
    } else {
      setLeIndList([]);
      setLeIndCount(0);
      setLeIndPaginator(null);
    }
  };

  const parseAccessoriesData = (accessoriesData: IGlobalSearchAccessoryResponse) => {
    if (accessoriesData?.totalElements) {
      const { content, ...paginatorData } = accessoriesData;
      setAccessoriesList(content);
      setAccessoriesCount(accessoriesData.totalElements);
      setAccessoriesPaginator(paginatorData);
      setValue(5);
    } else {
      setAccessoriesList([]);
      setAccessoriesCount(0);
      setAccessoriesPaginator(null);
    }
  };

  const parseAmmunitionData = (ammunitionData: IGlobalSearchAmmunitionResponse) => {
    if (ammunitionData?.totalElements) {
      const { content, ...paginatorData } = ammunitionData;
      setAmmunitionList(content);
      setAmmunitionCount(ammunitionData.totalElements);
      setAmmunitionPaginator(paginatorData);
      setValue(6);
    } else {
      setAmmunitionList([]);
      setAmmunitionCount(0);
      setAmmunitionPaginator(null);
    }
  };

  const parseComponentsData = (componentsData: IGlobalSearchComponentResponse) => {
    if (componentsData?.totalElements) {
      const { content, ...paginatorData } = componentsData;
      setComponentsList(content);
      setComponentsCount(componentsData.totalElements);
      setComponentsPaginator(paginatorData);
      setValue(7);
    } else {
      setComponentsList([]);
      setComponentsCount(0);
      setComponentsPaginator(null);
    }
  };

  useEffect(() => {
    (async () => {
      if (query?.length && query.length > 1) {
        setShowLimitAlert(false);
        const [
          usersData,
          transactionsData,
          leOrgData,
          leIndData,
          firearmsData,
          accessoriesData,
          ammunitionData,
          componentsData,
        ]: [
          IGlobalSearchUsersResponse,
          IGlobalSearchTransactionsResponse,
          IGlobalSearchLEOrganizationResponse,
          IGlobalSearchLEIndividualResponse,
          IGlobalSearchFirearmsResponse,
          IGlobalSearchAccessoryResponse,
          IGlobalSearchAmmunitionResponse,
          IGlobalSearchComponentResponse,
        ] = await Promise.all([
          fetchResults(
            EGlobalSearchCategories.Users,
            currentPageUsers,
            perPageUsers,
            usersSort,
          ),
          fetchResults(
            EGlobalSearchCategories.Transactions,
            currentPageTransactions,
            perPageTransactions,
            transactionsSort,
          ),
          fetchResults(
            EGlobalSearchCategories.LegalEntitiesOrganisation,
            currentPageLeOrg,
            perPageLeOrg,
            leOrgSort,
          ),
          fetchResults(
            EGlobalSearchCategories.LegalEntitiesIndividual,
            currentPageLeInd,
            perPageLeInd,
            leIndSort,
          ),
          fetchResults(
            EGlobalSearchCategories.Firearms,
            currentPageFirearms,
            perPageFirearms,
            firearmsSort,
          ),
          fetchResults(
            EGlobalSearchCategories.Accessories,
            currentPageAccessories,
            perPageAccessories,
            accessoriesSort,
          ),
          fetchResults(
            EGlobalSearchCategories.Ammunition,
            currentPageAmmunition,
            perPageAmmunition,
            ammunitionSort,
          ),
          fetchResults(
            EGlobalSearchCategories.Components,
            currentPageComponents,
            perPageComponents,
            componentsSort,
          ),
        ]);

        parseComponentsData(componentsData);
        parseAmmunitionData(ammunitionData);
        parseAccessoriesData(accessoriesData);
        parseUserData(usersData);
        parseTransactionData(transactionsData);
        parseFirearmsData(firearmsData);
        parseLeOrg(leOrgData);
        parseLeInd(leIndData);

        setIsFinishLoading(true);
      } else {
        setIsFinishLoading(false);
        setShowLimitAlert(true);
      }
      return false;
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, category]);

  useEffect(() => {
    (async () => {
      setIsUserPaginationLoading(true);
      if (isFinishLoading) {
        const data = await fetchResults(
          EGlobalSearchCategories.Users,
          currentPageUsers,
          perPageUsers,
          usersSort,
        );
        parseUserData(data);
      }
      setIsUserPaginationLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageUsers, perPageUsers, usersSort, selectedLanguage]);

  useEffect(() => {
    (async () => {
      setIsTransactionPaginationLoading(true);
      if (isFinishLoading) {
        const data = await fetchResults(
          EGlobalSearchCategories.Transactions,
          currentPageTransactions,
          perPageTransactions,
          transactionsSort,
        );
        parseTransactionData(data);
      }
      setIsTransactionPaginationLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageTransactions, perPageTransactions, transactionsSort, selectedLanguage]);

  useEffect(() => {
    (async () => {
      setIsLEOrganisationPaginationLoading(true);
      if (isFinishLoading) {
        const data = await fetchResults(
          EGlobalSearchCategories.LegalEntitiesOrganisation,
          currentPageLeOrg,
          perPageLeOrg,
          leOrgSort,
        );
        parseLeOrg(data);
      }
      setIsLEOrganisationPaginationLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageLeOrg, perPageLeOrg, leOrgSort, selectedLanguage]);

  useEffect(() => {
    (async () => {
      setIsLEIndividualPaginationLoading(true);
      if (isFinishLoading) {
        const data = await fetchResults(
          EGlobalSearchCategories.LegalEntitiesIndividual,
          currentPageLeInd,
          perPageLeInd,
          leIndSort,
        );
        parseLeInd(data);
      }
      setIsLEIndividualPaginationLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageLeInd, perPageLeInd, leIndSort, selectedLanguage]);

  useEffect(() => {
    (async () => {
      setIsAccessoryPaginationLoading(true);
      if (isFinishLoading) {
        const data = await fetchResults(
          EGlobalSearchCategories.Accessories,
          currentPageAccessories,
          perPageAccessories,
          accessoriesSort,
        );
        parseAccessoriesData(data);
      }
      setIsAccessoryPaginationLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageAccessories, perPageAccessories, accessoriesSort, selectedLanguage]);

  useEffect(() => {
    (async () => {
      setIsAmmunitionPaginationLoading(true);
      if (isFinishLoading) {
        const data = await fetchResults(
          EGlobalSearchCategories.Ammunition,
          currentPageAmmunition,
          perPageAmmunition,
          ammunitionSort,
        );
        parseAmmunitionData(data);
      }
      setIsAmmunitionPaginationLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageAmmunition, perPageAmmunition, ammunitionSort, selectedLanguage]);

  useEffect(() => {
    (async () => {
      setIsComponentPaginationLoading(true);
      if (isFinishLoading) {
        const data = await fetchResults(
          EGlobalSearchCategories.Components,
          currentPageComponents,
          perPageComponents,
          componentsSort,
        );
        parseComponentsData(data);
      }
      setIsComponentPaginationLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageComponents, perPageComponents, componentsSort, selectedLanguage]);

  useEffect(() => {
    (async () => {
      setIsFirearmPaginationLoading(true);
      if (isFinishLoading) {
        const data = await fetchResults(
          EGlobalSearchCategories.Firearms,
          currentPageFirearms,
          perPageFirearms,
          firearmsSort,
        );
        parseFirearmsData(data);
      }
      setIsFirearmPaginationLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageFirearms, perPageFirearms, firearmsSort, selectedLanguage]);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.dashboard.name'), route: ERouteLinks.Dashboard },
          t('dashboard.searchResults.label'),
        ]}
      />
      <StyledPageTitle variant="h4">{t('dashboard.searchResults.label')}</StyledPageTitle>
      <Grid container sx={{ margin: '10px 0' }}>
        <Grid item xs={isDesktop ? 4 : 12}>
          <Button
            size="small"
            variant={EButtonVariants.outlined}
            label={t('dashboard.backToDashboard.label')}
            icon={EIconTypes.left}
            onClick={() => navigate(ERouteLinks.Dashboard)}
            fullWidth={!isDesktop}
          />
        </Grid>
      </Grid>

      <GlobalSearch query={query} category={category} />

      {showLimitAlert && (
        <Box sx={{ marginTop: '20px' }}>
          <Alert
            text={t('dashboard.search.alert.atLeast2Characters')}
            variant={EAlertVariants.warning}
          />
        </Box>
      )}

      {!showLimitAlert && (
        <>
          {isDesktop && (
            <Box sx={{ margin: '10px 10px 10px' }}>
              <StyledTabs value={value} onChange={handleChange} variant="fullWidth">
                <StyledTab
                  label={
                    <Typography variant="subtitle1">
                      {t('dashboard.search.legalEntityIndividual.label')}
                    </Typography>
                  }
                  icon={
                    <Chips variant={EChipsVariants.primary} text={leIndCount?.toString()} />
                  }
                  iconPosition="end"
                  sx={!leIndCount ? { display: 'none' } : {}}
                />
                <StyledTab
                  label={
                    <Typography variant="subtitle1">
                      {t('dashboard.search.legalEntityOrganisation.label')}
                    </Typography>
                  }
                  icon={
                    <Chips variant={EChipsVariants.primary} text={leOrgCount?.toString()} />
                  }
                  iconPosition="end"
                  sx={!leOrgCount ? { display: 'none' } : {}}
                />
                <StyledTab
                  label={
                    <Typography variant="subtitle1">
                      {t('dashboard.search.firearms.label')}
                    </Typography>
                  }
                  icon={
                    <Chips variant={EChipsVariants.primary} text={firearmsCount?.toString()} />
                  }
                  iconPosition="end"
                  sx={!firearmsCount ? { display: 'none' } : {}}
                />
                <StyledTab
                  label={
                    <Typography variant="subtitle1">
                      {t('dashboard.search.transactions.label')}
                    </Typography>
                  }
                  icon={
                    <Chips
                      variant={EChipsVariants.primary}
                      text={transactionsCount?.toString()}
                    />
                  }
                  iconPosition="end"
                  sx={!transactionsCount ? { display: 'none' } : {}}
                />
                <StyledTab
                  label={
                    <Typography variant="subtitle1">
                      {t('dashboard.search.users.label')}
                    </Typography>
                  }
                  icon={
                    <Chips variant={EChipsVariants.primary} text={usersCount?.toString()} />
                  }
                  iconPosition="end"
                  sx={!usersCount ? { display: 'none' } : {}}
                />
                <StyledTab
                  label={
                    <Typography variant="subtitle1">
                      {t('dashboard.search.accessories.label')}
                    </Typography>
                  }
                  icon={
                    <Chips
                      variant={EChipsVariants.primary}
                      text={accessoriesCount?.toString()}
                    />
                  }
                  iconPosition="end"
                  sx={!accessoriesCount ? { display: 'none' } : {}}
                />
                <StyledTab
                  label={
                    <Typography variant="subtitle1">
                      {t('dashboard.search.ammunition.label')}
                    </Typography>
                  }
                  icon={
                    <Chips
                      variant={EChipsVariants.primary}
                      text={ammunitionCount?.toString()}
                    />
                  }
                  iconPosition="end"
                  sx={!ammunitionCount ? { display: 'none' } : {}}
                />
                <StyledTab
                  label={
                    <Typography variant="subtitle1">
                      {t('dashboard.search.components.label')}
                    </Typography>
                  }
                  icon={
                    <Chips
                      variant={EChipsVariants.primary}
                      text={componentsCount?.toString()}
                    />
                  }
                  iconPosition="end"
                  sx={!componentsCount ? { display: 'none' } : {}}
                />
              </StyledTabs>
            </Box>
          )}

          {!!leIndCount && (
            <TabPanel
              value={value}
              index={0}
              isDesktop={isDesktop}
              title={t('dashboard.search.legalEntityIndividual.label')}
              counter={leIndCount?.toString()}
              onChange={handleChange}
            >
              {!!leIndList?.length && (
                <div>
                  {isDesktop ? (
                    <Table
                      columns={globalSearchLEIndividualTableColumns({
                        handleView: (id: string) =>
                          navigate(
                            `${ERouteLinks.ViewLegalEntity.replace(':id', id)}?type=${
                              ELegalEntityTypes.individual
                            }`,
                          ),
                        renderHighlight: highlightText,
                      })}
                      dataSource={globalSearchLEIndividualToDataSource(leIndList)}
                      onSort={setLeIndSort}
                    />
                  ) : (
                    <CardList
                      items={globalSearchLEIndividualToDataSource(leIndList)}
                      render={(data, index) => (
                        <SearchResultLeIndTableCard
                          data={data}
                          key={index}
                          handleView={(id: string) =>
                            navigate(
                              `${ERouteLinks.ViewLegalEntity.replace(':id', id)}?type=${
                                ELegalEntityTypes.individual
                              }`,
                            )
                          }
                          renderHighlight={highlightText}
                        />
                      )}
                    />
                  )}
                </div>
              )}

              {leIndPaginator && (
                <Pagination
                  count={leIndPaginator?.totalElements}
                  onChangePage={(page) => setCurrentPageLeInd(page)}
                  current={currentPageLeInd}
                  perPage={perPageLeInd}
                  onChangePerPage={(perPage) => setPerPageLeInd(perPage)}
                  isLoading={isLEIndividualPaginationLoading}
                />
              )}
            </TabPanel>
          )}

          {!!leOrgCount && (
            <TabPanel
              value={value}
              index={1}
              isDesktop={isDesktop}
              title={t('dashboard.search.legalEntityOrganisation.label')}
              counter={leOrgCount?.toString()}
              onChange={handleChange}
            >
              {!!leOrgList?.length && (
                <div>
                  {isDesktop ? (
                    <Table
                      columns={globalSearchLEOrgTableColumns({
                        handleView: (id: string) =>
                          navigate(
                            `${ERouteLinks.ViewLegalEntity.replace(':id', id)}?type=${
                              ELegalEntityTypes.organisation
                            }`,
                          ),
                        renderHighlight: highlightText,
                      })}
                      dataSource={globalSearchLEOrgToDataSource(leOrgList)}
                      onSort={setLeOrgSort}
                    />
                  ) : (
                    <CardList
                      items={globalSearchLEOrgToDataSource(leOrgList)}
                      render={(data, index) => (
                        <SearchResultLEOrgTableCard
                          data={data}
                          key={index}
                          handleView={(id: string) =>
                            navigate(
                              `${ERouteLinks.ViewLegalEntity.replace(':id', id)}?type=${
                                ELegalEntityTypes.organisation
                              }`,
                            )
                          }
                          renderHighlight={highlightText}
                        />
                      )}
                    />
                  )}
                </div>
              )}
              {leOrgPaginator && (
                <Pagination
                  count={leOrgPaginator?.totalElements}
                  onChangePage={(page) => setCurrentPageLeOrg(page)}
                  current={currentPageLeOrg}
                  perPage={perPageLeOrg}
                  onChangePerPage={(perPage) => setPerPageLeOrg(perPage)}
                  isLoading={isLEOrganisationPaginationLoading}
                />
              )}
            </TabPanel>
          )}

          {!!firearmsCount && (
            <TabPanel
              value={value}
              index={2}
              isDesktop={isDesktop}
              title={t('dashboard.search.firearms.label')}
              counter={firearmsCount?.toString()}
              onChange={handleChange}
            >
              {!!firearmsList?.length && (
                <div>
                  {isDesktop ? (
                    <Table
                      columns={globalSearchFirearmsTableColumns({
                        handleView: (id: string) =>
                          navigate(ERouteLinks.ViewRegisteredFirearm.replace(':id', id)),
                        renderHighlight: highlightText,
                      })}
                      dataSource={globalSearchFirearmsToDataSource(firearmsList)}
                      onSort={setFirearmsSort}
                    />
                  ) : (
                    <CardList
                      items={globalSearchFirearmsToDataSource(firearmsList)}
                      render={(data, index) => (
                        <SearchResultFirearmTableCard
                          data={data}
                          key={index}
                          handleView={(id: string) =>
                            navigate(ERouteLinks.ViewRegisteredFirearm.replace(':id', id))
                          }
                          renderHighlight={highlightText}
                        />
                      )}
                    />
                  )}
                </div>
              )}
              {firearmsPaginator && (
                <Pagination
                  count={firearmsPaginator?.totalElements}
                  onChangePage={(page) => setCurrentPageFirearms(page)}
                  current={currentPageFirearms}
                  perPage={perPageFirearms}
                  onChangePerPage={(perPage) => setPerPageFirearms(perPage)}
                  isLoading={isFirearmPaginationLoading}
                />
              )}
            </TabPanel>
          )}

          {!!transactionsCount && (
            <TabPanel
              value={value}
              index={3}
              isDesktop={isDesktop}
              title={t('dashboard.search.transactions.label')}
              counter={transactionsCount?.toString()}
              onChange={handleChange}
            >
              {!!transactionsList?.length && (
                <div>
                  {isDesktop ? (
                    <Table
                      columns={globalSearchTransactionsTableColumns({
                        handleView: (id: string) =>
                          navigate(ERouteLinks.ViewTransaction.replace(':id', id)),
                        renderHighlight: highlightText,
                      })}
                      dataSource={globalSearchTransactionsToDataSource(transactionsList)}
                      onSort={setTransactionsSort}
                    />
                  ) : (
                    <CardList
                      items={globalSearchTransactionsToDataSource(transactionsList)}
                      render={(data, index) => (
                        <SearchResultTransactionTableCard
                          data={data}
                          key={index}
                          handleView={(id: string) =>
                            navigate(ERouteLinks.ViewTransaction.replace(':id', id))
                          }
                          renderHighlight={highlightText}
                        />
                      )}
                    />
                  )}
                </div>
              )}
              {transactionsPaginator && (
                <Pagination
                  count={transactionsPaginator?.totalElements}
                  onChangePage={(page) => setCurrentPageTransactions(page)}
                  current={currentPageTransactions}
                  perPage={perPageTransactions}
                  onChangePerPage={(perPage) => setPerPageTransactions(perPage)}
                  isLoading={isTransationPaginationLoading}
                />
              )}
            </TabPanel>
          )}

          {!!usersCount && (
            <TabPanel
              value={value}
              index={4}
              isDesktop={isDesktop}
              title={t('dashboard.search.users.label')}
              counter={usersCount?.toString()}
              onChange={handleChange}
            >
              {!!usersList?.length && (
                <div>
                  {isDesktop ? (
                    <Table
                      columns={globalSearchUsersTableColumns({
                        handleView: (id: string) =>
                          navigate(ERouteLinks.ViewUser.replace(':id', id)),
                        renderHighlight: highlightText,
                      })}
                      dataSource={globalSearchUsersToDataSource(usersList)}
                      onSort={setUsersSort}
                    />
                  ) : (
                    <CardList
                      items={globalSearchUsersToDataSource(usersList)}
                      render={(data, index) => (
                        <SearchResultUserTableCard
                          data={data}
                          key={index}
                          handleView={(id: string) =>
                            navigate(ERouteLinks.ViewUser.replace(':id', id))
                          }
                          renderHighlight={highlightText}
                        />
                      )}
                    />
                  )}
                </div>
              )}
              {usersPaginator && (
                <Pagination
                  count={usersPaginator?.totalElements}
                  onChangePage={(page) => setCurrentPageUsers(page)}
                  current={currentPageUsers}
                  perPage={perPageUsers}
                  onChangePerPage={(perPage) => setPerPageUsers(perPage)}
                  isLoading={isUserPaginationLoading}
                />
              )}
            </TabPanel>
          )}

          {!!accessoriesCount && (
            <TabPanel
              value={value}
              index={5}
              isDesktop={isDesktop}
              title={t('dashboard.search.accessories.label')}
              counter={accessoriesCount?.toString()}
              onChange={handleChange}
            >
              {!!accessoriesList?.length && (
                <div>
                  {isDesktop ? (
                    <Table
                      columns={globalSearchAccessoriesTableColumns({
                        handleView: (id: string) =>
                          navigate(ERouteLinks.ViewAccessories.replace(':id', id)),
                        renderHighlight: highlightText,
                      })}
                      dataSource={globalSearchAccessoriesToDataSource(accessoriesList)}
                      onSort={setAccessoriesSort}
                    />
                  ) : (
                    <CardList
                      items={globalSearchAccessoriesToDataSource(accessoriesList)}
                      render={(data, index) => (
                        <SearchResultAccessoryTableCard
                          data={data}
                          key={index}
                          handleView={(id: string) =>
                            navigate(ERouteLinks.ViewAccessories.replace(':id', id))
                          }
                          renderHighlight={highlightText}
                        />
                      )}
                    />
                  )}
                </div>
              )}
              {accessoriesPaginator && (
                <Pagination
                  count={accessoriesPaginator?.totalElements}
                  onChangePage={(page) => setCurrentPageAccessories(page)}
                  current={currentPageAccessories}
                  perPage={perPageAccessories}
                  onChangePerPage={(perPage) => setPerPageAccessories(perPage)}
                  isLoading={isAccessoryPaginationLoading}
                />
              )}
            </TabPanel>
          )}

          {!!ammunitionCount && (
            <TabPanel
              value={value}
              index={6}
              isDesktop={isDesktop}
              title={t('dashboard.search.ammunition.label')}
              counter={ammunitionCount?.toString()}
              onChange={handleChange}
            >
              {!!ammunitionList?.length && (
                <div>
                  {isDesktop ? (
                    <Table
                      columns={globalSearchAmmunitionTableColumns({
                        handleView: (id: string) =>
                          navigate(ERouteLinks.ViewAmmunition.replace(':id', id)),
                        renderHighlight: highlightText,
                      })}
                      dataSource={globalSearchAmmunitionToDataSource(ammunitionList)}
                      onSort={setAmmunitionSort}
                    />
                  ) : (
                    <CardList
                      items={globalSearchAmmunitionToDataSource(ammunitionList)}
                      render={(data, index) => (
                        <SearchResultAmmunitionTableCard
                          data={data}
                          key={index}
                          handleView={(id: string) =>
                            navigate(ERouteLinks.ViewAmmunition.replace(':id', id))
                          }
                          renderHighlight={highlightText}
                        />
                      )}
                    />
                  )}
                </div>
              )}
              {ammunitionPaginator && (
                <Pagination
                  count={ammunitionPaginator?.totalElements}
                  onChangePage={(page) => setCurrentPageAmmunition(page)}
                  current={currentPageAmmunition}
                  perPage={perPageAmmunition}
                  onChangePerPage={(perPage) => setPerPageAmmunition(perPage)}
                  isLoading={isAmmunitionPaginationLoading}
                />
              )}
            </TabPanel>
          )}

          {!!componentsCount && (
            <TabPanel
              value={value}
              index={7}
              isDesktop={isDesktop}
              title={t('dashboard.search.components.label')}
              counter={componentsCount?.toString()}
              onChange={handleChange}
            >
              {!!componentsList?.length && (
                <div>
                  {isDesktop ? (
                    <Table
                      columns={globalSearchComponentsTableColumns({
                        handleView: (id: string) =>
                          navigate(ERouteLinks.ViewComponents.replace(':id', id)),
                        renderHighlight: highlightText,
                      })}
                      dataSource={globalSearchComponentsToDataSource(componentsList)}
                      onSort={setComponentsSort}
                    />
                  ) : (
                    <CardList
                      items={globalSearchComponentsToDataSource(componentsList)}
                      render={(data, index) => (
                        <SearchResultComponentTableCard
                          data={data}
                          key={index}
                          handleView={(id: string) =>
                            navigate(ERouteLinks.ViewComponents.replace(':id', id))
                          }
                          renderHighlight={highlightText}
                        />
                      )}
                    />
                  )}
                </div>
              )}
              {componentsPaginator && (
                <Pagination
                  count={componentsPaginator?.totalElements}
                  onChangePage={(page) => setCurrentPageComponents(page)}
                  current={currentPageComponents}
                  perPage={perPageComponents}
                  onChangePerPage={(perPage) => setPerPageComponents(perPage)}
                  isLoading={isComponentPaginationLoading}
                />
              )}
            </TabPanel>
          )}
        </>
      )}

      {query &&
        query?.length > 1 &&
        isFinishLoading &&
        !usersList?.length &&
        !transactionsList?.length &&
        !firearmsList?.length &&
        !accessoriesList?.length &&
        !ammunitionList?.length &&
        !componentsList?.length &&
        !leIndList?.length &&
        !leOrgList?.length && (
          <NoResultWrapper>
            <Typography
              variant="body1"
              component="span"
              dangerouslySetInnerHTML={{
                __html: t('dashboard.noResults.label').replace(
                  '%',
                  `<strong>${queryStriped}</strong>`,
                ),
              }}
            />
          </NoResultWrapper>
        )}
    </>
  );
};

export default DashboardSearchResultsPage;
