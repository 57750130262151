import * as yup from 'yup';
import { useTranslations } from 'hooks/useTranslations';

const useFRTFormSchema = () => {
  const { t } = useTranslations();
  const schema = yup.object({
    make: yup
      .string()
      .required(t('formAutocomplete.validation.text'))
      .max(64, t('general.field.validation.maxCharacters').replace('{0}', '64'))
      .trim(),
    model: yup
      .string()
      .required(t('formAutocomplete.validation.text'))
      .max(64, t('general.field.validation.maxCharacters').replace('{0}', '64'))
      .trim(),
    manufacturer: yup
      .string()
      .required(t('formAutocomplete.validation.text'))
      .max(64, t('general.field.validation.maxCharacters').replace('{0}', '64'))
      .trim(),
    action: yup.string().required(t('general.field.validation.required')).trim(),
    type: yup.string().required(t('general.field.validation.required')).trim(),
    level: yup.string().required(t('general.field.validation.required')).trim(),
    country: yup.string().required(t('general.field.validation.required')).trim(),
    legalClassification: yup.string().required(t('general.field.validation.required')).trim(),
    ACT: yup
      .string()
      .max(4000, t('general.field.validation.maxCharacters').replace('{0}', '4000'))
      .trim(),
    AKA: yup
      .string()
      .max(4000, t('general.field.validation.maxCharacters').replace('{0}', '4000'))
      .trim(),
    BAR: yup
      .string()
      .max(4000, t('general.field.validation.maxCharacters').replace('{0}', '4000'))
      .trim(),
    CAL: yup
      .string()
      .max(4000, t('general.field.validation.maxCharacters').replace('{0}', '4000'))
      .trim(),
    IMG: yup
      .string()
      .max(4000, t('general.field.validation.maxCharacters').replace('{0}', '4000'))
      .trim(),
    IMP: yup
      .string()
      .max(4000, t('general.field.validation.maxCharacters').replace('{0}', '4000'))
      .trim(),
    LAW: yup
      .string()
      .max(4000, t('general.field.validation.maxCharacters').replace('{0}', '4000'))
      .trim(),
    MAK: yup
      .string()
      .max(4000, t('general.field.validation.maxCharacters').replace('{0}', '4000'))
      .trim(),
    MAN: yup
      .string()
      .max(4000, t('general.field.validation.maxCharacters').replace('{0}', '4000'))
      .trim(),
    MDT: yup
      .string()
      .max(4000, t('general.field.validation.maxCharacters').replace('{0}', '4000'))
      .trim(),
    MOD: yup
      .string()
      .max(4000, t('general.field.validation.maxCharacters').replace('{0}', '4000'))
      .trim(),
    MRK: yup
      .string()
      .max(4000, t('general.field.validation.maxCharacters').replace('{0}', '4000'))
      .trim(),
    REM: yup
      .string()
      .max(4000, t('general.field.validation.maxCharacters').replace('{0}', '4000'))
      .trim(),
    SER: yup
      .string()
      .max(4000, t('general.field.validation.maxCharacters').replace('{0}', '4000'))
      .trim(),
    SHT: yup
      .string()
      .max(4000, t('general.field.validation.maxCharacters').replace('{0}', '4000'))
      .trim(),
    TYP: yup
      .string()
      .max(4000, t('general.field.validation.maxCharacters').replace('{0}', '4000'))
      .trim(),
    productCodes: yup.array().of(
      yup.object().shape({
        productCode: yup
          .string()
          .max(66, t('general.field.validation.maxCharacters').replace('{0}', '66')),
      }),
    ),
  });

  return { schema };
};

export default useFRTFormSchema;
