import { IParsedDictionary } from 'helpers/dictionary';
import {
  ISearchFilterAccessory,
  ISearchFilterAmmunition,
  ISearchFilterAncillaries,
  ISearchFilterComponent,
} from 'models/searchFilter';
import { clearObject } from 'helpers/objects';
import { EArtAncillaryType, EArtAncillaryTypeNameLowerCase } from 'constants/ArtAccessoryType';
import { ESearchFilterKeys } from 'constants/SearchFilterTypes';
import { formatDateToRequest, simpleFormatDate } from 'utils/date';
import { parse } from 'date-fns';

const mapValuesToAccessoryPresetRequest = (
  data: ISearchFilterAccessory,
): ISearchFilterAccessory => {
  const values: ISearchFilterAccessory = {
    ancillaryDictionaryUniqueId: EArtAncillaryType.ACCESSORIES,
    hostTypeUniqueId: data.hostTypeUniqueId,
    magnificationUniqueId: data.magnificationUniqueId,
    utilityUniqueId: data.utilityUniqueId,
    reticleUniqueId: data.reticleUniqueId,
    lightColourUniqueId: data.lightColourUniqueId,
    accessoryCapacity: data.accessoryCapacity,
    externalReferenceNumber: data.externalReferenceNumber,
    ancillaryTypeUniqueId: data.ancillaryTypeUniqueId,
    manufacturerUniqueId: data.manufacturerUniqueId,
    calibreUniqueId: data.calibreUniqueId,
    serialNumber: data.serialNumber,
    compositionUniqueId: data.compositionUniqueId,
    proofHouseUniqueId: data.proofHouseUniqueId,
    artId: data.artId,
    exhibitNumber: data.exhibitNumber,
    stateUniqueId: data.stateUniqueId,
    legalityUniqueId: data.legalityUniqueId,
    ownerUniqueId: data.ownerUniqueId,
    keeperUniqueId: data.keeperUniqueId,
    fireCycleId: data.fireCycleId,
    productName: data.productName,
  };

  return clearObject(values);
};

export const permittedAccessoryFilters = [
  'hostTypeUniqueId',
  'magnificationUniqueId',
  'utilityUniqueId',
  'reticleUniqueId',
  'lightColourUniqueId',
  'accessoryCapacity',
  'externalReferenceNumber',
  'ancillaryTypeUniqueId',
  'manufacturerUniqueId',
  'calibreUniqueId',
  'serialNumber',
  'compositionUniqueId',
  'proofHouseUniqueId',
  'exhibitNumber',
  'stateUniqueId',
  'legalityUniqueId',
  'ownerName',
  'ownerUniqueId',
  'keeperName',
  'keeperUniqueId',
  'fireCycleId',
  'productName',
];

const mapValuesToAmmunitionPresetRequest = (
  data: ISearchFilterAmmunition,
): ISearchFilterAmmunition => {
  const values: ISearchFilterAmmunition = {
    ancillaryDictionaryUniqueId: EArtAncillaryType.AMMUNITION,
    fireCycleId: data.fireCycleId,
    lotNumber: data.lotNumber,
    batchNumber: data.batchNumber,
    factoryUniqueId: data.factoryUniqueId,
    manufactureDateFrom: data.manufactureDateFrom,
    manufactureDateTo: data.manufactureDateTo,
    functionalTypeUniqueId: data.functionalTypeUniqueId,
    cartridgeCompositionUniqueId: data.cartridgeCompositionUniqueId,
    colourOfPrimerSealantUniqueId: data.colourOfPrimerSealantUniqueId,
    projectileShapeUniqueId: data.projectileShapeUniqueId,
    shotSizeUniqueId: data.shotSizeUniqueId,
    munitionTypeUniqueId: data.munitionTypeUniqueId,
    munitionVelocityUniqueId: data.munitionVelocityUniqueId,
    headstampYearMarking: data.headstampYearMarking,
    alphanumericalMarkins: data.alphanumericalMarkins,
    externalReferenceNumber: data.externalReferenceNumber,
    ancillaryTypeUniqueId: data.ancillaryTypeUniqueId,
    manufacturerUniqueId: data.manufacturerUniqueId,
    calibreUniqueId: data.calibreUniqueId,
    serialNumber: data.serialNumber,
    compositionUniqueId: data.compositionUniqueId,
    proofHouseUniqueId: data.proofHouseUniqueId,
    expiryDateFrom: data.expiryDateFrom,
    expiryDateTo: data.expiryDateTo,
    artId: data.artId,
    exhibitNumber: data.exhibitNumber,
    stateUniqueId: data.stateUniqueId,
    legalityUniqueId: data.legalityUniqueId,
    ownerUniqueId: data.ownerUniqueId,
    keeperUniqueId: data.keeperUniqueId,
    productName: data.productName,
  };
  return clearObject(values);
};

export const permittedAmmunitionFilters = [
  'fireCycleId',
  'lotNumber',
  'batchNumber',
  'factoryUniqueId',
  'manufactureDateFrom',
  'manufactureDateTo',
  'functionalTypeUniqueId',
  'cartridgeCompositionUniqueId',
  'colourOfPrimerSealantUniqueId',
  'projectileShapeUniqueId',
  'shotSizeUniqueId',
  'munitionTypeUniqueId',
  'munitionVelocityUniqueId',
  'headstampYearMarking',
  'alphanumericalMarkins',
  'externalReferenceNumber',
  'ancillaryTypeUniqueId',
  'manufacturerUniqueId',
  'calibreUniqueId',
  'serialNumber',
  'compositionUniqueId',
  'proofHouseUniqueId',
  'expiryDateFrom',
  'expiryDateTo',
  'exhibitNumber',
  'stateUniqueId',
  'legalityUniqueId',
  'ownerName',
  'ownerUniqueId',
  'keeperName',
  'keeperUniqueId',
  'productName',
];

const mapValuesToComponentPresetRequest = (
  data: ISearchFilterComponent,
): ISearchFilterComponent => {
  const values: ISearchFilterComponent = {
    ancillaryDictionaryUniqueId: EArtAncillaryType.COMPONENTS,
    frtType: data.frtType,
    frtMake: data.frtMake,
    frtModel: data.frtModel,
    frtManufacturer: data.frtManufacturer,
    componentCapacityUniqueId: data.componentCapacityUniqueId,
    length: data.length,
    externalReferenceNumber: data.externalReferenceNumber,
    ancillaryTypeUniqueId: data.ancillaryTypeUniqueId,
    manufacturerUniqueId: data.manufacturerUniqueId,
    calibreUniqueId: data.calibreUniqueId,
    serialNumber: data.serialNumber,
    compositionUniqueId: data.compositionUniqueId,
    proofHouseUniqueId: data.proofHouseUniqueId,
    artId: data.artId,
    exhibitNumber: data.exhibitNumber,
    fireCycleId: data.fireCycleId,
    stateUniqueId: data.stateUniqueId,
    legalityUniqueId: data.legalityUniqueId,
    ownerUniqueId: data.ownerUniqueId,
    keeperUniqueId: data.keeperUniqueId,
    productName: data.productName,
  };

  return clearObject(values);
};

export const permittedComponentFilters = [
  'frtType',
  'frtMake',
  'frtModel',
  'frtManufacturer',
  'componentCapacityUniqueId',
  'length',
  'externalReferenceNumber',
  'ancillaryTypeUniqueId',
  'manufacturerUniqueId',
  'frtType',
  'calibreUniqueId',
  'serialNumber',
  'compositionUniqueId',
  'proofHouseUniqueId',
  'exhibitNumber',
  'fireCycleId',
  'stateUniqueId',
  'legalityUniqueId',
  'ownerName',
  'ownerUniqueId',
  'keeperName',
  'keeperUniqueId',
  'productName',
];

export const mapValuesToAncillariesPresetRequest = (
  data: ISearchFilterAncillaries,
  type: ESearchFilterKeys | EArtAncillaryTypeNameLowerCase,
  shortDateFormat: string,
): ISearchFilterAccessory | ISearchFilterAmmunition | ISearchFilterComponent | null => {
  if (!type) {
    return null;
  }
  const newData = { ...data };

  newData.manufactureDateFrom = data.manufactureDateFrom
    ? formatDateToRequest(parse(data.manufactureDateFrom, shortDateFormat, new Date()), true)
    : '';
  newData.manufactureDateTo = data.manufactureDateTo
    ? formatDateToRequest(parse(data.manufactureDateTo, shortDateFormat, new Date()), true)
    : '';
  newData.expiryDateFrom = data.expiryDateFrom
    ? formatDateToRequest(parse(data.expiryDateFrom, shortDateFormat, new Date()), true)
    : '';
  newData.expiryDateTo = data.expiryDateTo
    ? formatDateToRequest(parse(data.expiryDateTo, shortDateFormat, new Date()), true)
    : '';
  switch (type) {
    case ESearchFilterKeys.accessory:
    case EArtAncillaryTypeNameLowerCase.ACCESSORY:
      return mapValuesToAccessoryPresetRequest(data);
    case ESearchFilterKeys.ammunition:
    case EArtAncillaryTypeNameLowerCase.AMMUNITION:
      return mapValuesToAmmunitionPresetRequest(newData);
    case ESearchFilterKeys.component:
    case EArtAncillaryTypeNameLowerCase.COMPONENTS:
      return mapValuesToComponentPresetRequest(data);
    default:
      return null;
  }
};

export const mapValuesWithDictionariesHelper = (
  values: { key: string; value: string }[],
  dictionaries: { [key: string]: IParsedDictionary | any },
) =>
  values.map((item) => {
    if (dictionaries?.[item.key] && dictionaries[item.key]?.[item.value]) {
      item.value = dictionaries[item.key]?.[item.value];
    }
    return item;
  });
export const mapValuesToAncillaryPresetForView = (
  values: { key: string; value: string }[],
  shortDateFormat: string,
) =>
  values.map((item) => {
    switch (item.key) {
      case 'manufactureDateFrom':
      case 'manufactureDateTo':
      case 'expiryDateFrom':
      case 'expiryDateTo':
        return {
          key: item.key,
          value: simpleFormatDate(item.value, shortDateFormat),
        };
      default:
        return item;
    }
  });
