import React, { useEffect, useState } from 'react';
import { FRTSearchForm } from 'pages/Firearms/FRTSearchForm';
import { ISearchFormValues } from 'pages/Firearms/RegisteredFirearmsSearchForm';
import { Loader } from 'components/atoms/Loader';
import { Table } from 'components/molecules/Table';
import {
  ESearchFRTContextType,
  FRTTableColumns,
  mapFRTToDataSource,
} from 'pages/Firearms/helpers';
import { CardList } from 'components/molecules/CardList';
import { FRTCard } from 'pages/Firearms/FRTCard';
import { Pagination } from 'components/molecules/Pagination';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { EPerPages, ISortOptions } from 'models/table';
import { useFirearms } from 'pages/Firearms/hooks';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { ERouteLinks } from 'models/route';
import { ELegalEntityTypes } from 'constants/LegalEntityTypes';
import { StyledPageTitle } from 'theme/styles';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'hooks/useQuery';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import Grid from '@mui/material/Grid';
import { ISingleFRT } from 'models/firearm';
import { useTranslations } from 'hooks/useTranslations';

const SearchForFRTFirearmPage = () => {
  const navigate = useNavigate();
  const { id = '' } = useParams<{ id: string }>();
  const query = useQuery();
  const type: string | null = query.get('type');
  const { isDesktop } = useThemeBreakpoint();
  const [isFRTLoading, setIsFRTLoading] = useState<boolean>(false);
  const [FRTSort, setFRTSort] = useState<ISortOptions | null>(null);
  const [queryFRT, setQueryFRT] = useState<Partial<ISearchFormValues>>({});
  const [perPageFRT, setPerPageFRT] = useState<EPerPages>(EPerPages.perPage25);
  const [currentPageFRT, setCurrentPageFRT] = useState<number>(1);
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);
  const { FRT, getFRTData, paginatorFRT } = useFirearms();
  const { state: routerState }: any = useLocation();
  const { t } = useTranslations();

  useEffect(() => {
    (async function getFRTDataInit() {
      setIsPaginationLoading(true);
      if (!FRT.length) {
        setIsFRTLoading(true);
      }
      await getFRTData(currentPageFRT, perPageFRT, queryFRT, FRTSort);
      setIsFRTLoading(false);
      setIsPaginationLoading(false);
    })();
  }, [currentPageFRT, perPageFRT, queryFRT, FRTSort]);

  return (
    <Grid>
      <Breadcrumbs
        items={
          routerState?.ancillaries
            ? [
                {
                  label: t('addFirearm.addAncillaries.breadcrumb'),
                  route: ERouteLinks.AddAncillaries,
                },
                t('addFirearm.searchForFrtFirearm.breadcrumb'),
              ]
            : [
                {
                  label: t('addFirearm.legalEntities.breadcrumb'),
                  route: ERouteLinks.LegalEntities,
                },
                {
                  label:
                    type === ELegalEntityTypes.individual
                      ? t('addFirearm.individual.breadcrumb')
                      : t('addFirearm.organisation.breadcrumb'),
                  route: `${ERouteLinks.LegalEntities}?expand=${
                    type === ELegalEntityTypes.individual ? 'individual' : 'organisation'
                  }`,
                },
                {
                  label: t('addFirearm.legalEntityDetails.breadcrumb'),
                  route: `${ERouteLinks.ViewLegalEntity.replace(
                    ':id',
                    id,
                  )}?type=${type}&expand=firearms`,
                },
                {
                  label: t('addFirearm.addFirearm.breadcrumb'),
                  route: `${ERouteLinks.LegalEntityAddFirearm.replace(
                    ':id',
                    id,
                  )}?type=${type}&expand=firearms`,
                },
                t('addFirearm.searchForFrtFirearm.breadcrumb'),
              ]
        }
      />

      <StyledPageTitle variant="h4">
        {t('searchforFRTfirearm.searchforFRTfirearm.header')}
      </StyledPageTitle>

      <Button
        sx={{ margin: '16px 0' }}
        label={t('searchforFRTfirearm.back.button')}
        size={EButtonSizes.small}
        variant={EButtonVariants.outlined}
        onClick={() =>
          navigate(
            routerState?.ancillaries
              ? ERouteLinks.AddAncillaries
              : `${ERouteLinks.LegalEntityAddFirearm.replace(
                  ':id',
                  id as string,
                )}?type=${type}&expand=firearms`,
            { state: { ...routerState } },
          )
        }
        icon={EIconTypes.left}
      />

      <FRTSearchForm
        isSearchButtonDisabled={isFRTLoading}
        onSubmit={setQueryFRT}
        showSaveMyFilter={false}
        isTransactionContext={true}
        isSearchForFRTContext={true}
      />
      {isFRTLoading ? (
        <Loader isLoading={isFRTLoading} />
      ) : (
        <>
          {isDesktop ? (
            <Table
              columns={FRTTableColumns(
                {
                  handleSelect: (firearm: ISingleFRT) => {
                    navigate(
                      routerState?.ancillaries
                        ? ERouteLinks.AddAncillaries
                        : `${ERouteLinks.FRTFirearmDetails.replace(
                            ':id',
                            id as string,
                          ).replace(':frtId', firearm.id)}?type=${type}&expand=firearms`,
                      {
                        state: {
                          ...routerState,
                          firearm,
                          prevRoute: 'prevRoute',
                          isLocal: !!queryFRT?.isLocal,
                        },
                      },
                    );
                  },
                },
                ESearchFRTContextType.LegalEntities,
              )}
              dataSource={mapFRTToDataSource(FRT)}
              onSort={setFRTSort}
            />
          ) : (
            <CardList
              items={mapFRTToDataSource(FRT)}
              render={(data, index) => (
                <FRTCard
                  data={data}
                  key={index}
                  handleSelect={() =>
                    navigate(
                      routerState?.ancillaries
                        ? ERouteLinks.AddAncillaries
                        : `${ERouteLinks.FRTFirearmDetails.replace(
                            ':id',
                            id as string,
                          ).replace(':frtId', data.id)}?type=${type}&expand=firearms`,
                      {
                        state: {
                          ...routerState,
                          firearm: data,
                          prevRoute: 'prevRoute',
                          isLocal: !!queryFRT?.isLocal,
                        },
                      },
                    )
                  }
                />
              )}
            />
          )}

          {paginatorFRT && (
            <Pagination
              count={paginatorFRT?.totalElements}
              perPage={perPageFRT}
              onChangePage={(page) => {
                setCurrentPageFRT(page);
              }}
              onChangePerPage={(value) => {
                if (value.toString() !== perPageFRT.toString()) {
                  setPerPageFRT(value);
                }
              }}
              current={currentPageFRT}
              isLoading={isPaginationLoading}
            />
          )}
        </>
      )}
    </Grid>
  );
};

export default SearchForFRTFirearmPage;
