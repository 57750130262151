import React, { useEffect } from 'react';
import { DetailsTemplate, EDetailsPageTypes } from 'components/templates/DetailsTemplate';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import Button from 'components/atoms/Button';
import { colors } from 'theme/colors';
import { StyledPageSubTitle } from 'theme/styles';
import { ERouteLinks } from 'models/route';
import { useNavigate } from 'react-router-dom';
import { IRegisteredFirearmDetailsProps } from 'models/registeredFirearm';
import { useTranslations } from 'hooks/useTranslations';
import { Chips } from 'components/atoms/Chips';
import Link from '@mui/material/Link';
import { useLang } from 'models/langContext';
import { formatDate } from 'utils/date';
import { ECustomFieldObjectType, ECustomFieldType } from 'models/customField';
import { ILegalEntityCustomField } from 'components/organisms/LegalEntityDetails';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { isFRTLocal } from 'pages/FRT/helpers';
import { emptyFieldHolder } from 'components/molecules/CardList';
import { usePermission } from 'hooks/usePermission';
import { EPermission } from 'models/permissions';
import { ELegalEntityTypes } from 'constants/LegalEntityTypes';
import { renderDataColorByValues } from 'components/molecules/Table/helpers';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import { EAlertVariants, IAlert } from 'components/atoms/Alert';
import { EFirearmStateId } from 'models/firearm';
import { StyledLegalLink } from 'components/atoms/StyledLegalLink';

// fix organization | organisation problem
export const getLegalEntityType = (type: string | undefined | null) => {
  if (type) {
    return type.toLowerCase() === ELegalEntityTypes.individual
      ? ELegalEntityTypes.individual
      : ELegalEntityTypes.organisation;
  }
  return null;
};

const RegisteredFirearmDetails = ({
  firearm,
  pageType = EDetailsPageTypes.view,
  disableActions = false,
  hideDisableButton = false,
  justShowFRT = false,
}: IRegisteredFirearmDetailsProps) => {
  const navigate = useNavigate();
  const { selectedLanguage, defaultLanguage } = useLang();
  const { t } = useTranslations();
  const { getValidTranslationName } = useLang();
  const { shortDateFormat, getDateFormat } = useGlobalProperty();
  const { hasPermissions } = usePermission();
  const { getCustomField, customFieldsFirearms } = useLegalEntities();
  const { setAlert } = useAlert();

  useEffect(() => {
    (async () => {
      getDateFormat();
      await getCustomField(ECustomFieldObjectType.FIREARM);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderDataColor = renderDataColorByValues(
    firearm.activityUniqueId,
    firearm?.legalityUniqueId,
    firearm.stateUniqueId,
  ).stateField;

  const renderCustomFieldData = (customField: ILegalEntityCustomField) => {
    if (customField.customFieldType === ECustomFieldType.BOOL) {
      if (customField.value === null) {
        return '-';
      }
      return customField.value
        ? t('customFields.value.yes.label')
        : t('customFields.value.no.label');
    }
    if (customField.customFieldType === ECustomFieldType.DATE) {
      return formatDate(customField.value, shortDateFormat);
    }
    return customField.value || '-';
  };

  const statesWithoutCoordinates = [
    EFirearmStateId.DESTROYED,
    EFirearmStateId.DISABLED,
    EFirearmStateId.EXPORTED,
  ];

  const handleRedirectToEditPageButton = (): (() => void) => {
    if (customFieldsFirearms?.length && firearm.stateUniqueId !== EFirearmStateId.DISABLED) {
      return () =>
        navigate(`${ERouteLinks.EditRegisteredFirearm.replace(':id', firearm.uniqueId)}`, {
          state: {
            prevPage: `${ERouteLinks.ViewRegisteredFirearm.replace(':id', firearm.uniqueId)}`,
          },
        });
    }
    if (firearm.stateUniqueId === EFirearmStateId.DISABLED) {
      return () =>
        setAlert(ActionTypes.SET_FIREARM_ALERT, {
          text: t('firearm.disabled.edit.info'),
          variant: EAlertVariants.error,
        } as IAlert);
    }
    return () =>
      setAlert(ActionTypes.SET_FIREARM_ALERT, {
        text: t('general.noAvaibleDataToEdit.label'),
        variant: EAlertVariants.warning,
      } as IAlert);
  };

  return (
    <div>
      {firearm && (
        <DetailsTemplate
          disableOnlyDelete
          showOnMapButtonId="firearm-details-show-on-map"
          title={`${firearm.make}, ${firearm.model}, ${firearm.action}, ${firearm.type}, ${firearm.caliber}`}
          pageType={pageType}
          redirectToEditView={
            disableActions || !hasPermissions([EPermission.MODIFY_FIREARM])
              ? undefined
              : handleRedirectToEditPageButton()
          }
          redirectToDisableView={
            disableActions || hideDisableButton || !hasPermissions(EPermission.DISABLE_FIREARM)
              ? undefined
              : () =>
                  navigate(
                    `${ERouteLinks.DisableRegisteredFirearm.replace(':id', firearm.uniqueId)}`,
                  )
          }
          redirectToView={() =>
            navigate(`${ERouteLinks.ViewRegisteredFirearm.replace(':id', firearm.uniqueId)}`)
          }
          redirectToShowOnMap={
            !!firearm.latitude &&
            !!firearm.longitude &&
            !statesWithoutCoordinates.includes(firearm.stateUniqueId as EFirearmStateId)
              ? () =>
                  navigate(ERouteLinks.RegisteredFirearmsMap, {
                    state: {
                      firearmUniqueId: firearm.uniqueId,
                      showAdvanced: true,
                      showLegalityBar: false,
                      navigateBack: `${ERouteLinks.ViewRegisteredFirearm.replace(
                        ':id',
                        firearm.uniqueId as string,
                      )}`,
                      breadcrumbs: [
                        {
                          label: t('firearms.registeredFirearms.accordion.name'),
                          route: ERouteLinks.Firearms,
                        },
                        {
                          label: t('registeredFirearms.firearmsDetails.header'),
                          route: `${ERouteLinks.ViewRegisteredFirearm.replace(
                            ':id',
                            firearm.uniqueId as string,
                          )}?expand=firearms`,
                        },
                        t('registeredFirearms.firearmsLocation.showOnMap.label'),
                      ],
                    },
                  })
              : undefined
          }
          disableActions={disableActions}
        >
          <Grid item xs={12} md={6} lg={3} container direction="column">
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('registeredFirearms.firearmsDetails.manufacturer.label')}
            </Typography>
            <Typography variant="body2">{emptyFieldHolder(firearm.manufacturer)}</Typography>
          </Grid>
          <Grid item xs={6} lg={3} container direction="column">
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {isFRTLocal(firearm.frtUniqueId)
                ? t('registeredFirearms.firearmsDetails.firearmLocalReferenceNo.label')
                : t('registeredFirearms.firearmsDetails.firearmReferenceNo.label')}
            </Typography>
            <Typography variant="body2">
              {emptyFieldHolder(
                isFRTLocal(firearm.frtUniqueId) ? firearm.frtId : firearm.frtUniqueId,
              )}
            </Typography>
          </Grid>
          {(!disableActions || justShowFRT) &&
            hasPermissions([EPermission.VIEW_FIREARMS]) &&
            pageType !== EDetailsPageTypes.auditLogView && (
              <Grid item xs={12} lg={6}>
                <Link
                  href={`${ERouteLinks.FirearmsReferenceTableDetails}?frtId=${firearm.frtUniqueId}`}
                  underline="none"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    fullWidth
                    label={t('registeredFirearms.firearmsDetails.showFRTInformation.button')}
                    variant={EButtonVariants.contained}
                    size={EButtonSizes.small}
                  />
                </Link>
              </Grid>
            )}
          <Grid
            container
            sx={{
              alignItems: 'center',
              borderTop: {
                xs: `1px solid ${colors.primary25}`,
              },
              mt: 3,
            }}
          >
            <StyledPageSubTitle
              variant="h5"
              sx={{ marginBottom: '4px', marginTop: '10px', textTransform: 'uppercase' }}
            >
              {t('registeredFirearms.firearmsDetails.detailedInformation.section.name')}
            </StyledPageSubTitle>
          </Grid>
          <Grid item xs={6} lg={3}>
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t(
                'registeredFirearms.firearmsDetails.detailedInformation.section.firecycleID.label',
              )}
            </Typography>
            <Typography variant="body2">{emptyFieldHolder(firearm.fireCycleId)}</Typography>
          </Grid>
          <Grid item xs={6} lg={3}>
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t(
                'registeredFirearms.firearmsDetails.detailedInformation.section.serialNumber.label',
              )}
            </Typography>
            <Typography variant="body2">{firearm.serialNumber ?? '-'}</Typography>
          </Grid>
          <Grid item xs={6} lg={3}>
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t(
                'registeredFirearms.firearmsDetails.detailedInformation.section.exhibit.label',
              )}
            </Typography>
            <Typography variant="body2">{firearm.exhibitNumber ?? '-'}</Typography>
          </Grid>
          <Grid item xs={6} lg={3}>
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t(
                'registeredFirearms.firearmsDetails.detailedInformation.section.externalReferenceNo.label',
              )}
            </Typography>
            <Typography variant="body2">
              {firearm.externalReferenceNumber ?? firearm.externalLocalReferenceNumber ?? '-'}
            </Typography>
          </Grid>
          <Grid item xs={6} lg={3}>
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('registeredFirearms.firearmsDetails.detailedInformation.section.state.label')}
            </Typography>
            <Chips
              text={firearm.state}
              variant={renderDataColor}
              textId="firearm-state-chip"
            />
          </Grid>
          <Grid item xs={6} lg={3}>
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t(
                'registeredFirearms.firearmsDetails.detailedInformation.section.currentOwner.label',
              )}
            </Typography>
            <Typography variant="body2">
              <StyledLegalLink
                legalId={firearm.ownerId}
                legalType={getLegalEntityType(firearm.ownerType)}
                labelText={firearm.owner}
                legalVisibilityUniqueId={firearm.ownerVisibilityUniqueId}
              />
            </Typography>
          </Grid>

          <Grid item xs={6} lg={3}>
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t(
                'registeredFirearms.firearmsDetails.detailedInformation.section.currentKeeper.label',
              )}
            </Typography>
            <Typography variant="body2">
              <StyledLegalLink
                legalId={firearm.keeperId}
                legalType={getLegalEntityType(firearm.keeperType)}
                labelText={firearm.keeper}
                legalVisibilityUniqueId={firearm.keeperVisibilityUniqueId}
              />
            </Typography>
          </Grid>
          {firearm.manufacturedAt && (
            <Grid item xs={6} lg={3}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t(
                  'registeredFirearms.firearmsDetails.detailedInformation.section.manufactureDate.label',
                )}
              </Typography>
              <Typography variant="body2">
                {formatDate(firearm.manufacturedAt, shortDateFormat)}
              </Typography>
            </Grid>
          )}
          {firearm.countryOfImport && firearm.countryOfImport.translations && (
            <Grid item xs={6} lg={3}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t(
                  'registeredFirearms.firearmsDetails.detailedInformation.section.countryOfImport.label',
                )}
              </Typography>
              <Typography variant="body2">
                {getValidTranslationName(firearm.countryOfImport.translations)}
              </Typography>
            </Grid>
          )}
          {firearm.countryOfExport && firearm.countryOfExport.translations && (
            <Grid item xs={6} lg={3}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t(
                  'registeredFirearms.firearmsDetails.detailedInformation.section.countryOfExport.label',
                )}
              </Typography>
              <Typography variant="body2">
                {getValidTranslationName(firearm.countryOfExport.translations)}
              </Typography>
            </Grid>
          )}
          {firearm?.customFields?.map((customField) => (
            <Grid item xs={6} lg={3} key={customField.customFieldUniqueId}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {customField?.translations?.filter(
                  (translation) => selectedLanguage?.uniqueId === translation.languageUniqueId,
                )?.[0]?.name ||
                  customField?.translations?.filter(
                    (translation) =>
                      defaultLanguage?.uniqueId === translation.languageUniqueId,
                  )?.[0]?.name}
              </Typography>
              <Typography variant="body2">{renderCustomFieldData(customField)}</Typography>
            </Grid>
          ))}
        </DetailsTemplate>
      )}
    </div>
  );
};

export { RegisteredFirearmDetails };
