import { EPageComponentTypes, ERouteLinks, IPageBuilderConfig } from 'models/route';
import { EPermission } from 'models/permissions';

export const config: IPageBuilderConfig[] = [
  {
    componentType: EPageComponentTypes.StartLicencePage,
    route: ERouteLinks.StartLicencePage,
    isProtected: false,
  },
  {
    componentType: EPageComponentTypes.AuthPage,
    route: ERouteLinks.Auth,
    isProtected: false,
  },
  {
    componentType: EPageComponentTypes.ForgotPasswordPage,
    route: ERouteLinks.ForgotPassword,
    isProtected: false,
  },
  {
    componentType: EPageComponentTypes.ResetPasswordPage,
    route: ERouteLinks.ResetPassword,
    isProtected: false,
    nestedRoutes: [
      {
        componentType: EPageComponentTypes.ResetPasswordPage,
        route: ERouteLinks.ResetPasswordLink,
        isProtected: false,
      },
    ],
  },
  {
    componentType: EPageComponentTypes.DashboardPage,
    route: ERouteLinks.Dashboard,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.DashboardSearchResultsPage,
    route: ERouteLinks.DashboardSearchResultsPage,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.LegalEntitiesPage,
    route: ERouteLinks.LegalEntities,
    isProtected: true,
    permissions: [EPermission.VIEW_ALL_LE, EPermission.VIEW_LE],
    permissionOrCondition: true,
    nestedRoutes: [
      {
        componentType: EPageComponentTypes.LegalEntitiesPage,
        route: ERouteLinks.LegalEntitiesExpand,
        isProtected: true,
        permissions: [EPermission.MANAGE_LE, EPermission.VIEW_ALL_LE, EPermission.VIEW_LE],
        permissionOrCondition: true,
      },
    ],
  },
  {
    componentType: EPageComponentTypes.AddLegalEntityPage,
    route: ERouteLinks.AddLegalEntity,
    isProtected: true,
    permissions: [EPermission.MANAGE_LE, EPermission.VIEW_LE],
  },
  {
    componentType: EPageComponentTypes.EditLegalEntityPage,
    route: ERouteLinks.EditLegalEntity,
    isProtected: true,
    permissions: [EPermission.MANAGE_LE, EPermission.VIEW_LE],
  },
  {
    componentType: EPageComponentTypes.DeleteLegalEntityPage,
    route: ERouteLinks.DeleteLegalEntity,
    isProtected: true,
    permissions: [EPermission.MANAGE_LE, EPermission.DELETE_LE],
  },
  {
    componentType: EPageComponentTypes.ViewLegalEntityPage,
    route: ERouteLinks.ViewLegalEntity,
    isProtected: true,
    permissions: [EPermission.VIEW_LE],
  },
  {
    componentType: EPageComponentTypes.LegalEntityAddCommentPage,
    route: ERouteLinks.LegalEntityAddComment,
    isProtected: true,
    permissions: [EPermission.MANAGE_LE, EPermission.MANAGE_COMMENTS_LE],
  },
  {
    componentType: EPageComponentTypes.LegalEntityDeleteCommentPage,
    route: ERouteLinks.LegalEntityDeleteComment,
    isProtected: true,
    permissions: [EPermission.MANAGE_LE, EPermission.MANAGE_COMMENTS_LE],
  },
  {
    componentType: EPageComponentTypes.LegalEntityAddAddressPage,
    route: ERouteLinks.LegalEntityAddAddress,
    isProtected: true,
    permissions: [EPermission.MANAGE_LE, EPermission.VIEW_LE],
  },
  {
    componentType: EPageComponentTypes.LegalEntityEditAddressPage,
    route: ERouteLinks.LegalEntityEditAddress,
    isProtected: true,
    permissions: [EPermission.MANAGE_LE, EPermission.VIEW_LE],
  },
  {
    componentType: EPageComponentTypes.LegalEntityDeleteAddressPage,
    route: ERouteLinks.LegalEntityDeleteAddress,
    isProtected: true,
    permissions: [EPermission.MANAGE_LE, EPermission.VIEW_LE],
  },
  {
    componentType: EPageComponentTypes.LegalEntityAddContactPage,
    route: ERouteLinks.LegalEntityAddContact,
    isProtected: true,
    permissions: [EPermission.MANAGE_LE, EPermission.VIEW_LE],
    permissionOrCondition: true,
  },
  {
    componentType: EPageComponentTypes.LegalEntityEditContactPage,
    route: ERouteLinks.LegalEntityEditContact,
    isProtected: true,
    permissions: [EPermission.MANAGE_LE, EPermission.VIEW_LE],
    permissionOrCondition: true,
  },
  {
    componentType: EPageComponentTypes.LegalEntityDeleteContactPage,
    route: ERouteLinks.LegalEntityDeleteContact,
    isProtected: true,
    permissions: [EPermission.MANAGE_LE, EPermission.VIEW_LE],
  },
  {
    componentType: EPageComponentTypes.LegalEntityAddGroupPage,
    route: ERouteLinks.LegalEntityAddGroup,
    isProtected: true,
    permissions: [EPermission.MANAGE_GROUPS_LE],
  },
  {
    componentType: EPageComponentTypes.LegalEntityGroupFirearmSearchPage,
    route: ERouteLinks.LegalEntityGroupFirearmSearch,
    isProtected: true,
    permissions: [EPermission.MANAGE_GROUPS_LE],
  },
  {
    componentType: EPageComponentTypes.LegalEntityEditGroupPage,
    route: ERouteLinks.LegalEntityEditGroup,
    isProtected: true,
    permissions: [EPermission.MANAGE_GROUPS_LE],
  },
  {
    componentType: EPageComponentTypes.LegalEntityDeleteGroupPage,
    route: ERouteLinks.LegalEntityDeleteGroup,
    isProtected: true,
    permissions: [EPermission.MANAGE_GROUPS_LE],
  },
  {
    componentType: EPageComponentTypes.ViewLegalEntityGroupPage,
    route: ERouteLinks.ViewLegalEntityGroup,
    isProtected: true,
    permissions: [
      EPermission.VIEW_FIREARMS_LE,
      EPermission.VIEW_FIREARMS,
      EPermission.VIEW_ALL_FIREARMS,
    ],
    permissionOrCondition: true,
  },
  {
    componentType: EPageComponentTypes.LegalEntityGroupAddFirearmPage,
    route: ERouteLinks.LegalEntityGroupAddFirearm,
    isProtected: true,
    permissions: [EPermission.MANAGE_GROUPS_LE],
  },
  {
    componentType: EPageComponentTypes.LegalEntityGroupDeleteFirearmPage,
    route: ERouteLinks.LegalEntityGroupDeleteFirearm,
    isProtected: true,
    permissions: [EPermission.MANAGE_GROUPS_LE],
  },

  {
    componentType: EPageComponentTypes.LegalEntityAddAncillaryPage,
    route: ERouteLinks.LegalEntityAddAncillary,
    permissions: [EPermission.ADD_TRANSACTION],
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.LegalEntityAddFirearmPage,
    route: ERouteLinks.LegalEntityAddFirearm,
    permissions: [EPermission.ADD_TRANSACTION],
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.LegalEntityAddAncillaryGroupPage,
    route: ERouteLinks.LegalEntityAddAncillaryGroup,
    permissions: [EPermission.MANAGE_GROUPS_LE],
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.ViewLegalEntityAncillaryGroupPage,
    route: ERouteLinks.ViewLegalEntityAncillaryGroup,
    permissions: [
      EPermission.VIEW_ANCILLARIES_LE,
      EPermission.VIEW_LIMITED_ANCILLARIES,
      EPermission.VIEW_ALL_ANCILLARIES,
    ],
    permissionOrCondition: true,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.LegalEntityGroupDeleteAncillaryPage,
    route: ERouteLinks.LegalEntityGroupDeleteAncillary,
    isProtected: true,
    permissions: [EPermission.MANAGE_GROUPS_LE],
  },
  {
    componentType: EPageComponentTypes.LegalEntityEditAncillaryGroupPage,
    route: ERouteLinks.EditAncillaryGroup,
    permissions: [EPermission.MANAGE_GROUPS_LE],
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.LegalEntityDeleteAncillariesGroupPage,
    route: ERouteLinks.DeleteAncillaryGroup,
    permissions: [EPermission.MANAGE_GROUPS_LE],
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.LegalEntityAddAncillaryGroupSearchPage,
    route: ERouteLinks.LegalEntityAddAncillaryGroupSearch,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.LegalEntityAddAncillaryGroupSearchPage,
    route: ERouteLinks.AddToAncillaryGroup,
    permissions: [EPermission.MANAGE_GROUPS_LE],
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.DetailAncillaries,
    route: ERouteLinks.LegalEntityViewAncillary,
    permissions: [EPermission.VIEW_LIMITED_ANCILLARIES, EPermission.VIEW_ALL_ANCILLARIES],
    permissionOrCondition: true,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.LegalEntityAddTransactionForGroupPage,
    route: ERouteLinks.LegalEntityAddTransactionForGroup,
    isProtected: true,
    permissions: [EPermission.ADD_TRANSACTION],
  },
  {
    componentType: EPageComponentTypes.LegalEntityAddTransactionForAncillaryGroupPage,
    route: ERouteLinks.LegalEntityAddTransactionForAncillaryGroup,
    isProtected: true,
    permissions: [EPermission.ADD_TRANSACTION],
  },
  {
    componentType: EPageComponentTypes.LegalEntityAddTransactionForGroupPage,
    route: ERouteLinks.MiddleTransaction,
    isProtected: true,
    permissions: [EPermission.ADD_TRANSACTION],
  },
  {
    componentType: EPageComponentTypes.ViewRegisteredFirearmAttachment,
    route: ERouteLinks.ViewRegisteredFirearmAttachment,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.FirearmsPage,
    route: ERouteLinks.Firearms,
    isProtected: true,
    permissions: [
      EPermission.VIEW_FIREARMS,
      EPermission.VIEW_ALL_FIREARMS,
      EPermission.MANAGE_FRT_FIREARM,
    ],
    permissionOrCondition: true,
  },
  {
    componentType: EPageComponentTypes.SaveFirearmsPresetPage,
    route: ERouteLinks.SaveFirearmsPreset,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.SaveAncillariesPresetPage,
    route: ERouteLinks.SaveAncillariesPreset,
    isProtected: true,
  },

  {
    componentType: EPageComponentTypes.DisableAncillary,
    route: ERouteLinks.DisableAncillary,
    permissions: [EPermission.DISABLE_ANCILLARY],
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.FirearmsReferenceTableDetailsPage,
    route: ERouteLinks.FirearmsReferenceTableDetails,
    permissions: [EPermission.VIEW_FIREARMS],
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.SelectLocationPage,
    route: ERouteLinks.SelectLocation,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.FRTFirearmDetailsPage,
    route: ERouteLinks.FRTFirearmDetails,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.EditFRTPage,
    route: ERouteLinks.EditFRT,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.AddFRTPage,
    route: ERouteLinks.AddFRT,
    isProtected: true,
    permissions: [EPermission.MANAGE_FRT_FIREARM],
  },
  {
    componentType: EPageComponentTypes.SearchForFRTFirearmPage,
    route: ERouteLinks.SearchForFRTFirearm,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.SearchForARTAncillaryPage,
    route: ERouteLinks.SearchForARTAncillary,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.ViewRegisteredFirearmPage,
    route: ERouteLinks.ViewRegisteredFirearm,
    isProtected: true,
    permissions: [EPermission.VIEW_FIREARMS],
  },
  {
    componentType: EPageComponentTypes.EditRegisteredFirearmPage,
    route: ERouteLinks.EditRegisteredFirearm,
    isProtected: true,
    permissions: [EPermission.MODIFY_FIREARM],
  },
  {
    componentType: EPageComponentTypes.DisableRegisteredFirearmPage,
    route: ERouteLinks.DisableRegisteredFirearm,
    isProtected: true,
    permissions: [EPermission.DISABLE_FIREARM],
  },
  {
    componentType: EPageComponentTypes.RegisteredFirearmAddCommentPage,
    route: ERouteLinks.RegisteredFirearmAddComment,
    isProtected: true,
    permissions: [EPermission.MANAGE_COMMENTS_FIREARMS],
  },
  {
    componentType: EPageComponentTypes.RegisteredFirearmDeleteCommentPage,
    route: ERouteLinks.RegisteredFirearmDeleteComment,
    isProtected: true,
    permissions: [EPermission.MANAGE_COMMENTS_FIREARMS],
  },
  {
    componentType: EPageComponentTypes.RegisteredFirearmAddAttachmentPage,
    route: ERouteLinks.RegisteredFirearmAddAttachment,
    isProtected: true,
    permissions: [EPermission.MANAGE_ATTACHMENTS_FIREARMS],
  },
  {
    componentType: EPageComponentTypes.RegisteredFirearmDeleteAttachmentPage,
    route: ERouteLinks.RegisteredFirearmDeleteAttachment,
    isProtected: true,
    permissions: [EPermission.MANAGE_ATTACHMENTS_FIREARMS],
  },
  {
    componentType: EPageComponentTypes.RegisteredFirearmsMapPage,
    route: ERouteLinks.RegisteredFirearmsMap,
    isProtected: true,
    permissions: [EPermission.VIEW_FIREARMS],
  },
  {
    componentType: EPageComponentTypes.RegisteredAncillariesMapPage,
    route: ERouteLinks.RegisteredAncillariesMap,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.SelectLegalEntityPage,
    route: ERouteLinks.SelectLegalEntity,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.FRTDetailsPage,
    route: ERouteLinks.FRTDetails,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.FRTAddCalibrePage,
    route: ERouteLinks.FRTAddCalibre,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.FRTEditCalibrePage,
    route: ERouteLinks.FRTEditCalibre,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.AncillariesPage,
    route: ERouteLinks.Ancillaries,
    permissions: [
      EPermission.VIEW_ALL_ANCILLARIES,
      EPermission.VIEW_LIMITED_ANCILLARIES,
      EPermission.MANAGE_LOCAL_ART,
    ],
    permissionOrCondition: true,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.AddAncillaries,
    route: ERouteLinks.AddAncillaries,
    permissions: [EPermission.MANAGE_LOCAL_ART],
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.SearchForFRTFirearmPage,
    route: ERouteLinks.AddAncillariesSearchForFRTFirearm,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.EditAncillaries,
    route: ERouteLinks.EditAncillaries,
    permissions: [EPermission.MANAGE_LOCAL_ART],
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.EditAncillary,
    route: ERouteLinks.EditAncillary,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.DetailAncillaries,
    route: ERouteLinks.ViewAncillaries,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.AncillaryDetails,
    route: ERouteLinks.ViewAncillaryDetails,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.AncillariesAddTransactionPage,
    route: ERouteLinks.AncillariesMiddleTransaction,
    permissions: [EPermission.ADD_TRANSACTION],
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.DetailAccessories,
    route: ERouteLinks.ViewAccessories,
    permissions: [EPermission.VIEW_LIMITED_ANCILLARIES, EPermission.VIEW_ALL_ANCILLARIES],
    permissionOrCondition: true,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.DetailComponents,
    route: ERouteLinks.ViewComponents,
    permissions: [EPermission.VIEW_LIMITED_ANCILLARIES, EPermission.VIEW_ALL_ANCILLARIES],
    permissionOrCondition: true,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.DetailAmmunition,
    route: ERouteLinks.ViewAmmunition,
    permissions: [EPermission.VIEW_LIMITED_ANCILLARIES, EPermission.VIEW_ALL_ANCILLARIES],
    permissionOrCondition: true,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.AccessoriesDeleteCommentPage,
    route: ERouteLinks.AccessoriesDeleteComment,
    permissions: [EPermission.MANAGE_COMMENTS_ANCILLARY],
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.AccessoriesAddCommentPage,
    route: ERouteLinks.AccessoriesAddComment,
    permissions: [EPermission.MANAGE_COMMENTS_ANCILLARY],
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.AmmunitionDeleteCommentPage,
    route: ERouteLinks.AmmunitionDeleteComment,
    permissions: [EPermission.MANAGE_COMMENTS_ANCILLARY],
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.ComponentsDeleteCommentPage,
    route: ERouteLinks.ComponentsDeleteComment,
    permissions: [EPermission.MANAGE_COMMENTS_ANCILLARY],
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.ComponentsAddCommentPage,
    route: ERouteLinks.ComponentsAddComment,
    permissions: [EPermission.MANAGE_COMMENTS_ANCILLARY],
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.AmmunitionAddCommentPage,
    route: ERouteLinks.AmmunitionAddComment,
    permissions: [EPermission.MANAGE_COMMENTS_ANCILLARY],
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.AccessoriesAddAttachmentPage,
    route: ERouteLinks.AccessoriesAddAttachment,
    permissions: [EPermission.MANAGE_ATTACHMENT_ANCILLARY],
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.AccessoriesViewAttachmentPage,
    route: ERouteLinks.AccessoriesViewAttachment,
    permissions: [EPermission.MANAGE_ATTACHMENT_ANCILLARY],
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.AccessoriesDeleteAttachmentPage,
    route: ERouteLinks.AccessoriesDeleteAttachment,
    permissions: [EPermission.MANAGE_ATTACHMENT_ANCILLARY],
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.AmmunitionAddAttachmentPage,
    route: ERouteLinks.AmmunitionAddAttachment,
    permissions: [EPermission.MANAGE_ATTACHMENT_ANCILLARY],
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.AmmunitionViewAttachmentPage,
    route: ERouteLinks.AmmunitionViewAttachment,
    permissions: [EPermission.MANAGE_ATTACHMENT_ANCILLARY],
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.AmmunitionDeleteAttachmentPage,
    route: ERouteLinks.AmmunitionDeleteAttachment,
    permissions: [EPermission.MANAGE_ATTACHMENT_ANCILLARY],
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.ComponentsAddAttachmentPage,
    route: ERouteLinks.ComponentsAddAttachment,
    permissions: [EPermission.MANAGE_ATTACHMENT_ANCILLARY],
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.ComponentsViewAttachmentPage,
    route: ERouteLinks.ComponentsViewAttachment,
    permissions: [EPermission.MANAGE_ATTACHMENT_ANCILLARY],
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.ComponentsDeleteAttachmentPage,
    route: ERouteLinks.ComponentsDeleteAttachment,
    permissions: [EPermission.MANAGE_ATTACHMENT_ANCILLARY],
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.TransactionsPage,
    route: ERouteLinks.Transactions,
    isProtected: true,
    permissions: [EPermission.VIEW_TRANSACTIONS, EPermission.VIEW_ALL_TRANSACTIONS],
    permissionOrCondition: true,
  },
  {
    componentType: EPageComponentTypes.ViewTransactionPage,
    route: ERouteLinks.ViewTransaction,
    isProtected: true,
    permissions: [EPermission.VIEW_TRANSACTIONS, EPermission.VIEW_ALL_TRANSACTIONS],
    permissionOrCondition: true,
  },
  {
    componentType: EPageComponentTypes.CancelTransactionPage,
    route: ERouteLinks.CancelTransaction,
    isProtected: true,
    permissions: [EPermission.DISCARD_TRANSACTION],
  },
  {
    componentType: EPageComponentTypes.TransactionAddCommentPage,
    route: ERouteLinks.TransactionAddComment,
    isProtected: true,
    permissions: [EPermission.MANAGE_TRANSACTION_COMMENTS],
  },
  {
    componentType: EPageComponentTypes.TransactionDeleteCommentPage,
    route: ERouteLinks.TransactionDeleteComment,
    isProtected: true,
    permissions: [EPermission.MANAGE_TRANSACTION_COMMENTS],
  },
  {
    componentType: EPageComponentTypes.SaveTransactionPresetPage,
    route: ERouteLinks.SaveTransactionPreset,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.UsersPage,
    route: ERouteLinks.Users,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.AddUserPage,
    route: ERouteLinks.AddUser,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.EditUserPage,
    route: ERouteLinks.EditUser,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.GeneratePasswordUserPage,
    route: ERouteLinks.GeneratePasswordUser,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.ViewUserPage,
    route: ERouteLinks.ViewUser,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.EditUserStatusPage,
    route: ERouteLinks.EditUserStatus,
    isProtected: true,
    permissions: [EPermission.MANAGE_USERS],
  },
  {
    componentType: EPageComponentTypes.AssignLESearchPage,
    route: ERouteLinks.AssignLESearch,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.AssignLEPage,
    route: ERouteLinks.AssignLE,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.DeleteAssignedLEPage,
    route: ERouteLinks.DeleteAssignedLE,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.DictionariesPage,
    route: ERouteLinks.Dictionaries,
    permissions: [EPermission.MANAGE_DICTIONARIES],
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.LicencePage,
    route: ERouteLinks.LicenceDetails,
    permissions: [EPermission.VIEW_AND_MANAGE_LICENCE],
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.ReplaceLicencePage,
    route: ERouteLinks.LicenceReplace,
    permissions: [EPermission.VIEW_AND_MANAGE_LICENCE],
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.ViewDictionaryPage,
    route: ERouteLinks.ViewDictionary,
    permissions: [EPermission.MANAGE_DICTIONARIES],
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.AddDictionaryValuePage,
    route: ERouteLinks.AddDictionaryValue,
    permissions: [EPermission.MANAGE_DICTIONARIES],
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.EditDictionaryPage,
    route: ERouteLinks.EditDictionaryValue,
    permissions: [EPermission.MANAGE_DICTIONARIES],
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.RolesPage,
    route: ERouteLinks.Roles,
    isProtected: true,
    permissions: [EPermission.MANAGE_ROLES],
  },
  {
    componentType: EPageComponentTypes.DictionariesPage,
    route: ERouteLinks.Dictionaries,
    permissions: [EPermission.MANAGE_DICTIONARIES],
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.AddRolePage,
    route: ERouteLinks.AddRole,
    isProtected: true,
    permissions: [EPermission.MANAGE_ROLES],
  },
  {
    componentType: EPageComponentTypes.ViewRolePage,
    route: ERouteLinks.ViewRole,
    isProtected: true,
    permissions: [EPermission.MANAGE_ROLES],
  },
  {
    componentType: EPageComponentTypes.EditRolePage,
    route: ERouteLinks.EditRole,
    isProtected: true,
    permissions: [EPermission.MANAGE_ROLES],
  },
  {
    componentType: EPageComponentTypes.DeleteRolePage,
    route: ERouteLinks.DeleteRole,
    isProtected: true,
    permissions: [EPermission.MANAGE_ROLES],
  },
  {
    componentType: EPageComponentTypes.AdministrationPage,
    route: ERouteLinks.Administration,
    permissions: [
      EPermission.MANAGE_USERS,
      EPermission.MANAGE_ROLES,
      EPermission.MANAGE_CUSTOM_FIELDS,
      EPermission.MANAGE_SETTINGS,
      EPermission.VIEW_AUDIT_LOG,
      EPermission.EXPORT_AUDIT_LOG,
      EPermission.VIEW_AND_MANAGE_LICENCE,
      EPermission.MANAGE_DICTIONARIES,
      EPermission.MANAGE_API_KEYS,
    ],
    permissionOrCondition: true,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.SystemSettingsPage,
    route: ERouteLinks.SystemSettings,
    isProtected: true,
    permissions: [EPermission.MANAGE_SETTINGS],
  },
  {
    componentType: EPageComponentTypes.ApiKeysPage,
    route: ERouteLinks.ApiKeysPage,
    isProtected: true,
    permissions: [EPermission.MANAGE_API_KEYS],
  },
  {
    componentType: EPageComponentTypes.AddApiKeyPage,
    route: ERouteLinks.AddApiKeyPage,
    isProtected: true,
    permissions: [EPermission.MANAGE_API_KEYS],
  },
  {
    componentType: EPageComponentTypes.EditApiKeyPage,
    route: ERouteLinks.EditApiKeyPage,
    isProtected: true,
    permissions: [EPermission.MANAGE_API_KEYS],
  },
  {
    componentType: EPageComponentTypes.ViewApiKeyPage,
    route: ERouteLinks.ViewApiKeyPage,
    isProtected: true,
    permissions: [EPermission.MANAGE_API_KEYS],
  },
  {
    componentType: EPageComponentTypes.DeleteApiKeyPage,
    route: ERouteLinks.DeleteApiKeyPage,
    isProtected: true,
    permissions: [EPermission.MANAGE_API_KEYS],
  },
  {
    componentType: EPageComponentTypes.AuditLogPage,
    route: ERouteLinks.AuditLogPage,
    isProtected: true,
    permissions: [EPermission.VIEW_AUDIT_LOG],
  },
  {
    componentType: EPageComponentTypes.AuditLogViewPage,
    route: ERouteLinks.AuditLogViewPage,
    isProtected: true,
    permissions: [EPermission.VIEW_AUDIT_LOG],
  },
  {
    componentType: EPageComponentTypes.AuthenticationSetupPage,
    route: ERouteLinks.AuthenticationSetup,
    isProtected: true,
    permissions: [EPermission.MANAGE_SETTINGS],
  },
  {
    componentType: EPageComponentTypes.DateTimeSetupPage,
    route: ERouteLinks.DateTimeSetup,
    isProtected: true,
    permissions: [EPermission.MANAGE_SETTINGS],
  },
  {
    componentType: EPageComponentTypes.DocumentsGeneratingSetupPage,
    route: ERouteLinks.DocumentsGeneratingSetupPage,
    isProtected: true,
    permissions: [EPermission.MANAGE_SETTINGS],
  },
  {
    componentType: EPageComponentTypes.NotificationsSetupPage,
    route: ERouteLinks.NotificationsSetup,
    isProtected: true,
    permissions: [EPermission.MANAGE_SETTINGS],
  },
  {
    componentType: EPageComponentTypes.LogosSetupPage,
    route: ERouteLinks.LogosSetupPage,
    isProtected: true,
    permissions: [EPermission.MANAGE_SETTINGS],
  },
  {
    componentType: EPageComponentTypes.CustomFieldsPage,
    route: ERouteLinks.CustomFields,
    isProtected: true,
    permissions: [EPermission.MANAGE_CUSTOM_FIELDS],
  },
  {
    componentType: EPageComponentTypes.AddCustomFieldPage,
    route: ERouteLinks.AddCustomField,
    isProtected: true,
    permissions: [EPermission.MANAGE_CUSTOM_FIELDS],
  },
  {
    componentType: EPageComponentTypes.EditCustomFieldPage,
    route: ERouteLinks.EditCustomField,
    isProtected: true,
    permissions: [EPermission.MANAGE_CUSTOM_FIELDS],
  },
  {
    componentType: EPageComponentTypes.ToggleCustomFieldPage,
    route: ERouteLinks.ToggleCustomField,
    isProtected: true,
    permissions: [EPermission.MANAGE_CUSTOM_FIELDS],
  },
  {
    componentType: EPageComponentTypes.NotificationsPage,
    route: ERouteLinks.Notifications,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.ViewNotificationPage,
    route: ERouteLinks.ViewNotification,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.AccountPage,
    route: ERouteLinks.Account,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.RemoveDevicePage,
    route: ERouteLinks.AccountRemoveDevice,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.AccountEditPage,
    route: ERouteLinks.AccountEdit,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.MyFiltersPage,
    route: ERouteLinks.MyFilters,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.DeleteFilterPage,
    route: ERouteLinks.DeleteFilter,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.ChangePasswordPage,
    route: ERouteLinks.AccountChangePassword,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.SavePresetPage,
    route: ERouteLinks.SavePreset,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.TableTestPage,
    route: ERouteLinks.TableTest,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.StyleGuidePage,
    route: ERouteLinks.StyleGuide,
    isProtected: false,
  },
  {
    componentType: EPageComponentTypes.NotFoundPage,
    route: ERouteLinks.NotFoundPage,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.AccessDeniedPage,
    route: ERouteLinks.AccessDeniedPage,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.ServerErrorPage,
    route: ERouteLinks.ServerErrorPage,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.DeleteUserPage,
    route: ERouteLinks.DeleteUser,
    isProtected: true,
    permissions: [EPermission.MANAGE_USERS],
  },
];
