import { createContext } from 'react';
import { ILegalEntityCustomField } from 'components/organisms/LegalEntityDetails';
import { ITranslation } from 'models/dictionary';
import { IPaginator } from './paginator';

export interface IARTAccessoriesFormValues {
  accessoryTypeUniqueId?: string;
  manufacturerUniqueId?: string;
  productName?: string;
  hostTypeUniqueId?: string;
  calibreUniqueId?: string;
  magnificationUniqueId?: string;
  utilityUniqueId?: string;
  rectileUniqueId?: string;
  lightColourUniqueId?: string;
  compositionUniqueId?: string;
  capacity?: string;
  proofHouseUniqueId?: string;
  notes?: string;

  [key: string]: any;
}

export interface IARTAccessories {
  uniqueId: string;
  accessoryType?: string;
  accessoryTypeUniqueId?: string;
  calibre?: string;
  calibreUniqueId?: string;
  composition?: string;
  compositionUniqueId?: string;
  hostType?: string;
  hostTypeUniqueId?: string;
  manufacturer?: string;
  manufacturerUniqueId?: string;
  productName?: string;
  proofHouse?: string;
  proofHouseUniqueId?: string;
  magnification?: string;
  magnificationUniqueId?: string;
  utility?: string;
  utilityUniqueId?: string;
  rectile?: string;
  rectileUniqueId?: string;
  lightColour?: string;
  lightColourUniqueId?: string;
  capacity?: string;
  artId: string;
  notes: string | null;
}

export interface IARTAccessoriesValues extends IPaginator {
  content: IARTAccessories[];
}

export interface IAncillary {
  uniqueId: string;
  fireCycleId: string;
  serialNumber: string;
  lotNumber: string;
  batchNumber: string;
  type: string;
  ancillary: string;
  manufacturer: string;
  calibre: string;
  artId: string;
  assetId: string;
  legalityUniqueId: string;
  activityUniqueId: string;
  state: string;
  hostType: string;
  hostTypeUniqueId: string;
  composition: string;
  compositionUniqueId: string;
  proofHouse: string;
  proofHouseUniqueId: string;
  factory: string;
  factoryUniqueId: string;
  catridgeComposition: string;
  catridgeCompositionUniqueId: string;
  headstampYearMarking: string;
  alphanumericalMarkins: string;
  frtMake: string;
  firearmMakeUniqueid: string;
  frtModel: string;
  frtType: string;
  firearmModelUniqueId: string;
  frtManufacturer: string;
  firearmManufacturerUniqueId: string;
  stateUniqueId: string;
  ownerUniqueId: string;
  keeperUniqueId: string;
  ancillarySpecificType: string;
  ancillaryType: string;
  ancillaryTypeDictionaryValue: string;
  cartridgeComposition: string;
  proofMark: string;
  alphanumericalMarkings: string;
  firearmManufacturer: string;
  firearmMake: string;
  firearmModel: string;
}

export interface IAncillaryResponse extends IPaginator {
  content: IAncillary[];
}

export interface IAccessory {
  uniqueId: string;
  artId: string;
  activityUniqueId: string;
  compositionUniqueId: string;
  assetId: string;
  type: string;
  typeUniqueId: string;
  hostType: string;
  hostTypeUniqueId: string;
  manufacturer: string;
  manufacturerUniqueId: string;
  productName: string;
  proofHouse: string;
  proofHouseUniqueId: string;
  composition: string;
  calibre: string;
  calibreUniqueId: string;
  serialNumber: string;
  fireCycleId: string;
  ownerName: string;
  keeperName: string;
  ownerId: string;
  keeperId: string;
  legalityUniqueId: string;
  state: string;
  stateUniqueId: string;
  exhibitNumber: string;
  latitude: number | null;
  longitude: number | null;
  customFields?: ILegalEntityCustomField[];
  notes: string | null;
}

export interface IAccessoryResponse extends IPaginator {
  content: IAccessory[];
}

export interface IAmmunition {
  uniqueId: string;
  artId: string;
  assetId: string;
  type: string;
  typeUniqueId: string;
  manufacturer: string;
  manufacturerUniqueId: string;
  productName: string;
  factory: string;
  factoryUniqueId: string;
  catridgeComposition: string;
  catridgeCompositionUniqueId: string;
  alphanumericalMarkins: string;
  colourOfPrimerSealant: string;
  colourOfPrimerSealantUniqueId: string;
  functionalType: string;
  functionalTypeUniqueId: string;
  projectileShape: string;
  projectileShapeUniqueId: string;
  headstampYearMarking: string;
  calibre: string;
  calibreUniqueId: string;
  fireCycleId: string;
  lotNumber: string;
  batchNumber: string;
  state: string;
  stateUniqueId: string;
  ownerName: string;
  keeperName: string;
  ownerId: string;
  keeperId: string;
  exhibitNumber: string;
  manufactureDateFrom: Date;
  manufactureDateTo: Date;
  legalityUniqueId: string;
  activityUniqueId: string;
  latitude?: number;
  longitude?: number;
  expiryDate: Date;
  customFields?: ILegalEntityCustomField[];
}

export interface IAmmunitionResponse extends IPaginator {
  content: IAmmunition[];
}

export interface IComponent {
  uniqueId: string;
  artId: string;
  type: string;
  typeUniqueId: string;
  manufacturer: string;
  productName: string;
  manufacturerUniqueId: string;
  activityUniqueId: string;
  legalityUniqueId: string;
  longitude?: number;
  latitude?: number;
  calibre: string;
  calibreUniqueId: string;
  composition: string;
  compositionUniqueId: string;
  proofHouse: string;
  proofHouseUniqueId: string;
  fireCycleId: string;
  serialNumber: string;
  ownerName: string;
  keeperName: string;
  ownerId: string;
  keeperId: string;
  state: string;
  stateUniqueId: string;
  exhibitNumber: string;
  frtType: string;
  firearmTypeUniqueId: string;
  frtMake: string;
  frtId: string;
  firearmMakeUniqueid: string;
  frtModel: string;
  firearmModelUniqueId: string;
  frtManufacturer: string;
  firearmManufacturerUniqueId: string;
  customFields?: ILegalEntityCustomField[];
  componentCapacityUniqueId: string;
  length: number;
}

export interface IComponentResponse extends IPaginator {
  content: IComponent[];
}

export interface IAncillariesReference {
  uniqueId: string;
  ancillary: string;
  artId: string;
  accessoryType: string;
  hostType: string;
  manufacturer: string;
  productName: string;
  calibre: string;
  composition: string;
  capacity: string;
  lightColour: string;
  magnification: string;
  proofHouse: string;
  rectile: string;
  utility: string;
  accessoryTypeUniqueId: string;
}

export interface IAncillariesReferenceResponse extends IPaginator {
  content: IAncillariesReference[];
}

export interface IARTAmmunition {
  alphanumericalMarkings: string | null;
  ammunitionType: string;
  ammunitionTypeUniqueId: string;
  calibre: string | null;
  calibreUniqueId: string | null;
  cartridgeComposition: string | null;
  cartridgeCompositionUniqueId: string | null;
  colourOfPrimerSealant: string | null;
  colourOfPrimerSealantUniqueId: string | null;
  factory: string;
  factoryUniqueId: string;
  functionalType: string | null;
  functionalTypeUniqueId: string | null;
  headstampYearMarking: string | null;
  manufacturer: string;
  productName: string;
  manufacturerUniqueId: string;
  munitionType: string | null;
  munitionTypeUniqueId: string | null;
  munitionVelocity: string | null;
  munitionVelocityUniqueId: string | null;
  projectileShape: string | null;
  projectileShapeUniqueId: string | null;
  shotSize: string | null;
  shotSizeUniqueId: string | null;
  uniqueId: string;
  artId: string;
  notes: string | null;
}

export interface IARTAmmunitionResponse extends IPaginator {
  content: IARTAmmunition[];
}

export interface IARTComponentsFormValues {
  componentTypeUniqueId: string;
  manufacturerUniqueId: string;
  productName: string;
  calibreUniqueId: string;
  length: string;
  compositionUniqueId: string;
  capacityUniqueId: string;
  proofHouseUniqueId: string;
  notes: string;

  frtId: string;
  frtLocal?: boolean;
  frtMake: string;
  frtModel: string;
  frtManufacturer: string;
  frtType: string;

  [key: string]: any;
}

export interface IARTComponent {
  calibre: string | null;
  calibreUniqueId: string | null;
  capacity: string | null;
  capacityUniqueId: string | null;
  componentType: string;
  componentTypeUniqueId: string;
  composition: string | null;
  compositionUniqueId: string | null;
  frtId: string;
  frtLocal: boolean;
  frtMake: string | null;
  frtManufacturer: string | null;
  frtModel: string | null;
  frtType: string | null;
  length: number | null;
  manufacturer: string;
  manufacturerUniqueId: string;
  productName?: string;
  proofHouse: string | null;
  proofHouseUniqueId: string | null;
  uniqueId: string;
  artId: string;
  notes: string | null;
}

export interface IARTComponentResponse extends IPaginator {
  content: IARTComponent[];
}

export interface IOptionsArt {
  expanded: boolean;
  acillaryValue?: string;
}

export interface IAncillariesContext {
  setAncillariesArtOptions: (type: string, payload: IOptionsArt) => void;
  clearAncillariesArtOptions: (type: string) => void;
  optionsArt: null | IOptionsArt;
}

export const AncillariesContext = createContext<IAncillariesContext>(
  {} as IAncillariesContext,
);

export interface IAncillaryDetailsCustomfield {
  customFieldUniqueId: string;
  customFieldName: string;
  customFieldType: string;
  value: string;
  translations?: ITranslation[];
}

export interface IAncillaryDetails {
  uniqueId: string;
  artId: string;
  assetId: string;
  artUniqueId: string;
  batchNumber: string;
  calibre: string;
  calibreUniqueId: string;
  composition: string;
  compositionUniqueId: string;
  exhibitNumber: string;
  externalReferenceNumber: string;
  fireCycleId: string;
  hostType: string;
  hostTypeUniqueId: string;
  keeperId: string;
  keeperName: string;
  keeperType: string;
  keeperVisibilityUniqueId: string;
  lotNumber: string;
  manufacturer: string;
  productName: string;
  manufacturerUniqueId: string;
  ownerId: string;
  ownerName: string;
  ownerType: string;
  ownerVisibilityUniqueId: string;
  proofHouse: string;
  proofHouseUniqueId: string;
  serialNumber: string;
  state: string;
  stateUniqueId: string;
  type: string;
  typeUniqueId: string;
  magnification: string;
  magnificationUniqueId: string;
  utility: string;
  utilityUniqueId: string;
  reticle: string;
  reticleUniqueId: string;
  lightcolour: string;
  lightcolourUniqueId: string;
  capacity: string;
  capacityUniqueId: string;
  factory: string;
  functionalType: string;
  cartridgeComposition: string;
  colourOfPrimerSealant: string;
  ancillaryType: string;
  activityUniqueId: string;
  legalityUniqueId: string;
  customFields: IAncillaryDetailsCustomfield[];
  latitude: 0;
  longitude: 0;
  objectUniqueId: string;
  projectileShape: string;
  alphanumericalMarkins: string;
  manufacturedAt: string;
  expiryDate: string;
  headstampYearMarking: string;
  productionDate: string;
  shotSize: string;
  shotSizeUniqueId: string;
  munitionVelocity: string;
  munitionVelocityUniqueId: string;
  munitionType: string;
  munitionTypeUniqueId: string;
  length: string;
  expirationDate: string;
  groupName: string;
  version: number;
}

export interface IAccessoryDetails extends IAncillaryDetails {}

export interface IAmmunitionDetails extends IAncillaryDetails {
  ammunitionExpiryDate: string;
}

export interface IComponentDetails extends IAncillaryDetails {
  frtId: string;
  frtMake: string;
  frtManufacturer: string;
  frtModel: string;
  frtType: string;
  frtReferenceNo: string;
}

export interface IAncillariesTransactionsFormValues {
  stateUniqueId?: string;
  transactionDateFrom?: string | Date;
  transactionDateTo?: string | Date;
}

export interface IAccessoriesTransactionsFormValues
  extends IAncillariesTransactionsFormValues {}

export interface IAmmunitionTransactionsFormValues
  extends IAncillariesTransactionsFormValues {}

export interface IComponentsTransactionsFormValues
  extends IAncillariesTransactionsFormValues {}

export interface IAncillariesTransactions {
  uniqueId: string;
  date: string;
  createdDate: string;
  transactionType: string;
  owner: string;
  keeper: string;
  ownerId: string;
  keeperId: string;
  detailsVisible: boolean;
}

export interface IAccessoriesTransactions extends IAncillariesTransactions {}

export interface IAmmunitionTransactions extends IAncillariesTransactions {}

export interface IComponentsTransactions extends IAncillariesTransactions {}

export interface IAccessoriesTransactionsResponse extends IPaginator {
  content: IAccessoriesTransactions[];
}

export interface IAmmunitionTransactionsResponse extends IPaginator {
  content: IAmmunitionTransactions[];
}

export interface IAncillariesComments {
  uniqueId: string;
  hidden: boolean;
  title: string;
  addedBy: string;
  date: string;
  comment: string;
}

export interface IAccessoriesComments extends IAncillariesComments {}

export interface IAmmunitionComments extends IAncillariesComments {}

export interface IComponentsComments extends IAncillariesComments {}

export interface IAccessoriesCommentsResponse extends IPaginator {
  content: IAccessoriesComments[];
}

export interface IAmmunitionCommentsResponse extends IPaginator {
  content: IAmmunitionComments[];
}

export interface IComponentsCommentsResponse extends IPaginator {
  content: IComponentsComments[];
}

export interface IAncillariesAttachments {
  uniqueId: string;
  createdDate: string;
  name: string;
  type: string;
  size: string;
  transactionType: string;
  report: string;
  transactionTypeUniqueId: string;
  transactionUniqueId: string;
  transactionTime: string;
}

export interface IAccessoriesAttachments extends IAncillariesAttachments {}

export interface IAmmunitionAttachments extends IAncillariesAttachments {}

export interface IComponentsAttachments extends IAncillariesAttachments {}
