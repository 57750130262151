import React, { ReactNode } from 'react';
import Grid from '@mui/material/Grid';
import { colors } from 'theme/colors';
import { Typography } from '@mui/material';
import Button, { IButton } from 'components/atoms/Button';
import { EButtonSizes, EButtonSizesPX, EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { useTranslations } from 'hooks/useTranslations';
import { ITranslationKey } from 'components/molecules/Table';
import { EPermission } from 'models/permissions';

export enum EDetailsPageTypes {
  view = 'view',
  delete = 'delete',
  userView = 'user_view',
  auditLogView = 'audit_log_view',
  transactionSelectView = 'transaction_select_view',
  apiKeyView = 'api_key_view',
}

interface IactionButton {
  text: string;
  onClick?: () => void;
  variant?: EButtonVariants;
}

export interface IDetailsTemplateProps {
  children: ReactNode;
  title: string | undefined;
  pageType?: EDetailsPageTypes;
  redirectToEditView?: (a: string) => void;
  redirectToDisableView?: (a: string) => void;
  redirectToGeneratePasswordView?: (a: string) => void;
  redirectToView?: (a: string) => void;
  redirectToSelect?: (a: string) => void;
  actionButtonProps?: IButton;
  actionButtonFullRow?: boolean;
  actionButtons?: IactionButton[];
  translationsKeys?: ITranslationKey | any;
  hasLongTitle?: boolean;
  disableActions?: boolean;
  changeActivationUserStatus?: () => void;
  unbanUserStatus?: () => void;
  userActivated?: boolean;
  userBlocked?: boolean;
  redirectToShowOnMap?: () => void;
  deletePermissions?: EPermission[];
  editPermissions?: EPermission[];
  disableOnlyDelete?: boolean;
  showOnMapButtonId?: string;
  loggedUserIdEqualsViewUserId?: boolean;
  handleDelete?: () => void;
}

const DetailsTemplate = ({
  children,
  title,
  showOnMapButtonId,
  pageType = EDetailsPageTypes.delete,
  redirectToEditView,
  editPermissions,
  redirectToDisableView,
  changeActivationUserStatus,
  unbanUserStatus,
  redirectToGeneratePasswordView,
  redirectToView,
  redirectToSelect,
  actionButtonProps,
  actionButtonFullRow = false,
  actionButtons,
  translationsKeys,
  userActivated,
  userBlocked,
  hasLongTitle = false,
  disableActions = false,
  deletePermissions,
  disableOnlyDelete = false,
  redirectToShowOnMap,
  handleDelete,
  loggedUserIdEqualsViewUserId,
}: IDetailsTemplateProps) => {
  const { isDesktop } = useThemeBreakpoint();
  const { t } = useTranslations();

  const renderLegalEntityDetailsActionButtons = (isTemplateFooter: boolean) => {
    if (
      pageType === EDetailsPageTypes.view ||
      pageType === EDetailsPageTypes.userView ||
      pageType === EDetailsPageTypes.auditLogView ||
      pageType === EDetailsPageTypes.transactionSelectView
    ) {
      let buttonProps: IButton | undefined;
      if (actionButtonProps) {
        buttonProps = actionButtonProps;
      } else {
        buttonProps = {
          ...(isDesktop
            ? { icon: EIconTypes.delete }
            : { label: t(translationsKeys?.deleteLink || 'general.delete.text') }),
          ...(isDesktop ? {} : { size: EButtonSizes.small }),
          ...(isDesktop ? {} : { sizePX: EButtonSizesPX.px24 }),
          ...(isDesktop
            ? { variant: EButtonVariants.outlinedIconTertiary }
            : { variant: EButtonVariants.outlined }),
        };
      }
      return (
        <Grid
          item
          xs={12}
          lg={hasLongTitle ? 2 : 10}
          container
          sx={{
            display: isTemplateFooter && !isDesktop ? 'none' : 'flex',
            justifyContent: isTemplateFooter ? 'flex-end' : 'space-between',
            alignItems: 'center',
            borderTop: {
              xs: `1px solid ${colors.primary25}`,
              lg: 'none',
            },
            mb: pageType === EDetailsPageTypes.userView ? { lg: 1, xl: 0 } : null,
            pt: {
              xs: isTemplateFooter ? 0 : 2,
              lg: 0,
            },
            mt: {
              xs: isTemplateFooter ? 0 : 2,
              lg: 0,
            },
          }}
        >
          {!isDesktop && !isTemplateFooter && (
            <Grid
              item
              xs={12}
              sm={actionButtonFullRow ? 12 : 'auto'}
              sx={{ mb: { xs: 2, sm: actionButtonFullRow ? 2 : 0 } }}
            >
              <Typography variant="button" sx={{ textTransform: 'uppercase' }}>
                {t('general.actions.text')}:
              </Typography>
            </Grid>
          )}
          <Grid
            item
            container
            xs={12}
            sm={actionButtonFullRow ? 12 : 'auto'}
            spacing={1}
            sx={{
              display: {
                xs: isTemplateFooter ? 'none' : 'flex',
                lg: isTemplateFooter ? 'flex' : 'none',
              },
              justifyContent:
                pageType === EDetailsPageTypes.userView
                  ? {
                      xs: 'flex-end',
                      md: 'flex-start',
                      xl: 'flex-end',
                    }
                  : 'flex-end',
              alignItems: 'center',
              flexWrap: { xs: 'wrap', xl: 'nowrap' },
            }}
          >
            {redirectToShowOnMap && pageType !== EDetailsPageTypes.auditLogView && (
              <Grid
                item
                xs={isTemplateFooter ? 'auto' : 12}
                sm={isTemplateFooter ? 'auto' : 12}
              >
                <Button
                  id={showOnMapButtonId}
                  label={t('registeredFirearms.firearmsDetails.showOnMap.button')}
                  variant={EButtonVariants.contained}
                  onClick={redirectToShowOnMap}
                  size="small"
                  fullWidth
                />
              </Grid>
            )}
            {redirectToEditView &&
              ![
                EDetailsPageTypes.transactionSelectView,
                EDetailsPageTypes.auditLogView,
              ].includes(pageType) && (
                <Grid
                  item
                  display="flex"
                  flexDirection="row"
                  gap={1}
                  xs={isTemplateFooter ? 'auto' : 12}
                  sm={
                    // eslint-disable-next-line no-nested-ternary
                    isTemplateFooter
                      ? 'auto'
                      : pageType === EDetailsPageTypes.userView
                      ? 12
                      : pageType === EDetailsPageTypes.view
                  }
                  sx={{
                    flexWrap: {
                      xs: pageType === EDetailsPageTypes.userView ? 'wrap' : 'nowrap',
                      sm: 'nowrap',
                    },
                  }}
                >
                  <Grid item xs={12} md={isTemplateFooter ? 'auto' : 12}>
                    <Button
                      label={t(
                        translationsKeys?.editLink ||
                          'registeredFirearms.firearmsDetails.edit.button',
                      )}
                      id="edit"
                      variant={EButtonVariants.outlined}
                      onClick={redirectToEditView}
                      permission={editPermissions}
                      size="small"
                      sx={{
                        width: { xs: '100%' },
                        minWdth: { xs: '109px', lg: '109px' },
                      }}
                      fullWidth
                    />
                  </Grid>
                  {userBlocked && unbanUserStatus && !loggedUserIdEqualsViewUserId && (
                    <Grid
                      item
                      ml={2}
                      xs={isTemplateFooter ? 'auto' : 12}
                      sm={isTemplateFooter ? 'auto' : 12}
                    >
                      <Button
                        label={t('userDetails.unlock.link')}
                        id="activate"
                        variant={EButtonVariants.outlined}
                        onClick={unbanUserStatus}
                        size="small"
                        fullWidth
                      />
                    </Grid>
                  )}
                  {handleDelete && (
                    <Grid
                      item
                      xs={isTemplateFooter ? 'auto' : 12}
                      sm={isTemplateFooter ? 'auto' : 12}
                    >
                      <Button
                        label={t('general.delete.text')}
                        id="delete_button"
                        variant={EButtonVariants.outlined}
                        onClick={handleDelete}
                        size="small"
                        fullWidth
                        sx={{
                          width: { xs: '100%' },
                          minWdth: { xs: '109px', lg: 'auto' },
                        }}
                      />
                    </Grid>
                  )}
                  {changeActivationUserStatus &&
                    !userBlocked &&
                    !loggedUserIdEqualsViewUserId && (
                      <Grid
                        item
                        xs={isTemplateFooter ? 'auto' : 12}
                        sm={isTemplateFooter ? 'auto' : 12}
                      >
                        <Button
                          label={
                            userActivated
                              ? t('userDetails.deactivate.link')
                              : t('userDetails.activate.link')
                          }
                          id={userActivated ? 'deactivate' : 'activate'}
                          variant={EButtonVariants.outlined}
                          onClick={changeActivationUserStatus}
                          size="small"
                          fullWidth
                        />
                      </Grid>
                    )}
                </Grid>
              )}
            {redirectToGeneratePasswordView && (
              <Grid
                item
                xs={isTemplateFooter ? 'auto' : 12}
                sm={isTemplateFooter ? 'auto' : 12}
              >
                <Button
                  label={t('userDetails.generatePassword.link')}
                  id="generatePassword"
                  variant={EButtonVariants.outlined}
                  onClick={redirectToGeneratePasswordView}
                  size="small"
                  fullWidth
                />
              </Grid>
            )}
            {redirectToSelect && pageType === EDetailsPageTypes.transactionSelectView && (
              <Grid
                item
                display="flex"
                flexDirection="row"
                spacing={2}
                xs={isTemplateFooter ? 'auto' : 12}
              >
                <Button
                  label={t('registeredFirearms.firearmsDetails.select.button')}
                  id="select"
                  variant={EButtonVariants.outlined}
                  onClick={redirectToSelect}
                  size="small"
                  fullWidth
                />
              </Grid>
            )}

            {actionButtons &&
              actionButtons.length &&
              actionButtons.map((button) => (
                <Grid
                  key={button.text}
                  item
                  display="flex"
                  flexDirection="row"
                  spacing={2}
                  xs={isTemplateFooter ? 'auto' : 12}
                >
                  <Button
                    label={t(button.text)}
                    variant={button.variant || EButtonVariants.outlined}
                    onClick={button.onClick || (() => {})}
                    size="small"
                    fullWidth
                  />
                </Grid>
              ))}

            {pageType === EDetailsPageTypes.view && redirectToDisableView && (
              <Grid
                item
                xs={isTemplateFooter ? 'auto' : 12}
                sm={isTemplateFooter ? 'auto' : 6}
              >
                <Button
                  label={t(
                    translationsKeys?.disableLink ||
                      'registeredFirearms.firearmsDetails.disable.button',
                  )}
                  id="disable"
                  variant={EButtonVariants.outlined}
                  onClick={redirectToDisableView}
                  size="small"
                  fullWidth
                />
              </Grid>
            )}

            {pageType === EDetailsPageTypes.view && !disableOnlyDelete && redirectToView && (
              <Grid
                item
                xs={isTemplateFooter ? 'auto' : 12}
                sm={isTemplateFooter ? 'auto' : 12}
              >
                <Button
                  {...buttonProps}
                  sx={buttonProps ? { ...buttonProps.sx } : {}}
                  permission={deletePermissions}
                  onClick={redirectToView}
                  id="delete"
                  fullWidth
                />
              </Grid>
            )}

            {pageType === EDetailsPageTypes.auditLogView && (
              <Grid
                item
                xs={isTemplateFooter ? 'auto' : 12}
                sm={isTemplateFooter ? 'auto' : 12}
              >
                <Button
                  label={t('roles.rolesTable.view.link')}
                  id="view"
                  variant={EButtonVariants.outlined}
                  onClick={redirectToView}
                  size="small"
                  fullWidth
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      );
    }
    return null;
  };

  return (
    <Grid
      container
      sx={{
        border: `1px solid ${colors.primary25}`,
        borderRadius: '4px',
        overflow: 'hidden',
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor: colors.primary25,
          flexDirection:
            pageType === EDetailsPageTypes.userView
              ? {
                  md: 'column',
                  lg: 'column',
                  xl: 'row',
                }
              : 'row',
        }}
        px={2}
        py={1}
      >
        <Grid
          item
          xs={12}
          lg={hasLongTitle ? 12 : 6}
          sx={{ mb: pageType === EDetailsPageTypes.userView ? { lg: 1, xl: 0 } : null }}
        >
          <Typography variant="h5" sx={{ fontWeight: 600, lineHeight: '2rem' }}>
            {title}
          </Typography>
        </Grid>
        {!disableActions && renderLegalEntityDetailsActionButtons(true)}
      </Grid>
      <Grid item container p={2} rowSpacing={2}>
        {children}
        {!disableActions && renderLegalEntityDetailsActionButtons(false)}
      </Grid>
    </Grid>
  );
};

export { DetailsTemplate };
