import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { FormInput } from 'components/molecules/FormInput';
import { EButtonSizesPX, EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import Button from 'components/atoms/Button';
import { FormDatePicker } from 'components/molecules/FormDatePicker';
import { Typography } from '@mui/material';
import { ICustomFieldByObjectType } from 'models/customField';
import FormCustomField from 'components/organisms/FormCustomField';
import {
  ANCILLARY_TRANSACTIONS_WITH_AUTOCOMPLETED_MANUFACTURED_AT,
  TRANSACTION_INITIAL_ILLEGAL,
  TRANSACTION_INITIAL_TYPES_FOR_AMMUNITION_EXPIRY_DATE_FIELD,
  TRANSACTION_INITIAL_TYPES_FOR_MANUFACTURED_DATE_FIELD_REQUIRED,
  TRANSACTION_INITIAL_TYPES_ONLY_FOR_ORG_IDS,
} from 'pages/LegalEntitiesPage/LegalEntityAddAncillaryPage';
import { ETransactionTypes } from 'constants/TransactionTypes';
import { useTranslations } from 'hooks/useTranslations';
import { FormCheckbox } from 'components/molecules/FormCheckbox';

interface IAddAncillaryListItem {
  index: number;
  arrayName?: string;
  removeItem: () => void;
  fields: any[];
  isAccessorySelected: boolean;
  serialNumberEnabled: boolean;
  externalReferenceNumberMandatory: boolean;
  isLotBatchEnabled: boolean;
  control: any;
  errors: any;
  setValue: any;
  customFields?: ICustomFieldByObjectType[];
  transactionTypeValue: ETransactionTypes;
  getValues: any;
  isComponentSelected: boolean;
  isReloadCausedLocationSelect: boolean;
  isDataImportedFromFile: boolean;
  setIsDataImportedFromFile: (val: boolean) => void;
  isManufacturedDateEnabled: boolean;
}

const AddAncillaryListItem = ({
  index,
  arrayName,
  removeItem,
  fields,
  isAccessorySelected,
  serialNumberEnabled,
  externalReferenceNumberMandatory,
  isLotBatchEnabled,
  control,
  setValue,
  errors,
  customFields = [],
  transactionTypeValue,
  getValues,
  isComponentSelected,
  isReloadCausedLocationSelect,
  isDataImportedFromFile,
  setIsDataImportedFromFile,
  isManufacturedDateEnabled,
}: IAddAncillaryListItem) => {
  const { t } = useTranslations();
  const isExhibitNumberEnabled =
    TRANSACTION_INITIAL_TYPES_ONLY_FOR_ORG_IDS.includes(transactionTypeValue);
  const isAmmunitionExpiryDateEnabled =
    TRANSACTION_INITIAL_TYPES_FOR_AMMUNITION_EXPIRY_DATE_FIELD.includes(transactionTypeValue);
  const isTransactionIllegal = TRANSACTION_INITIAL_ILLEGAL.includes(transactionTypeValue);

  const [checkboxStates, setCheckboxStates] = useState<{ [key: number]: boolean }>(() => ({
    [index]: getValues(`${arrayName}[${index}].isExhibitNumberChecked`) || false,
  }));

  const [isLotBatchNotPresentList, setIsLotBatchNotPresentList] = useState<{
    [key: number]: boolean;
  }>(() => ({
    [index]: getValues(`${arrayName}[${index}].isLotBatchNumberNotPresent`) || false,
  }));

  useEffect(() => {
    if (transactionTypeValue && !isReloadCausedLocationSelect && !isDataImportedFromFile) {
      setIsLotBatchNotPresentList({ [index]: false });
      setCheckboxStates({ [index]: false });
      setValue(`${arrayName}[${index}].isExhibitNumberChecked`, false);
      setValue(`${arrayName}[${index}].isLotBatchNumberNotPresent`, false);
      setValue(`${arrayName}[${index}].lotNumber`, '');
      setValue(`${arrayName}[${index}].batchNumber`, '');
      setValue(`${arrayName}[${index}].serialNumber`, '');
      setValue(`${arrayName}[${index}].serialNumberConfirmation`, '');
      setValue(`${arrayName}[${index}].assetId`, '');
      setValue(`${arrayName}[${index}].exhibitNumber`, '');
      setValue(`${arrayName}[${index}].externalReferenceNumber`, '');
      setValue(`${arrayName}[${index}].manufacturedAt`, '');
      setValue(`${arrayName}[${index}].ammunitionExpiryDate`, '');

      customFields?.forEach((customField) =>
        setValue(`${arrayName}[${index}].${customField.name}`, ''),
      );
    }
    setIsDataImportedFromFile(false);
  }, [transactionTypeValue, isReloadCausedLocationSelect]);

  const handleCheckboxChange = () => {
    setCheckboxStates((prevStates) => ({
      ...prevStates,
      [index]: !prevStates[index],
    }));
    setValue(`${arrayName}[${index}].serialNumber`, '');
    setValue(`${arrayName}[${index}].serialNumberConfirmation`, '');
  };

  const handleLotBatchCheckboxChange = () => {
    setIsLotBatchNotPresentList((prevStates) => ({
      ...prevStates,
      [index]: !prevStates[index],
    }));
    setValue(`${arrayName}[${index}].lotNumber`, '');
    setValue(`${arrayName}[${index}].batchNumber`, '');
  };

  return (
    <Grid container columnSpacing={1}>
      <Grid item xs={1} display="flex" alignItems="center">
        <Typography variant="subtitle1" mr={2}>
          {index + 1}.
        </Typography>
      </Grid>

      {isLotBatchEnabled && (
        <>
          {isTransactionIllegal && (
            <Grid item xs={12} lg={3} sx={{ display: 'flex', alignItems: 'center' }}>
              <FormCheckbox
                name={`${arrayName}[${index}].isLotBatchNumberNotPresent`}
                label={t('addAncillary.validation.lotBatchNotPresent')}
                control={control}
                errors={errors}
                onChange={handleLotBatchCheckboxChange}
              />
            </Grid>
          )}
          <Grid item xs={11} lg={3}>
            <FormInput
              name={`${arrayName}[${index}].lotNumber`}
              label={`${t('addAncillary.list.section.lotNumber.label')}`}
              setValue={setValue}
              control={control}
              errors={errors}
              arrayName={arrayName}
              arrayIndex={index}
              charsLimit={10}
              fieldName="lotNumber"
              disabled={isLotBatchNotPresentList[index]}
            />
          </Grid>
          <Grid item xs={11} lg={3}>
            <FormInput
              name={`${arrayName}[${index}].batchNumber`}
              label={`${t('addAncillary.list.section.batchNumber.label')}`}
              setValue={setValue}
              control={control}
              errors={errors}
              arrayName={arrayName}
              arrayIndex={index}
              charsLimit={10}
              fieldName="batchNumber"
              disabled={isLotBatchNotPresentList[index]}
            />
          </Grid>
        </>
      )}

      {isExhibitNumberEnabled && serialNumberEnabled && (
        <Grid item xs={12} lg={3} sx={{ display: 'flex', alignItems: 'center' }}>
          <FormCheckbox
            name={`${arrayName}[${index}].isExhibitNumberChecked`}
            label={t('addFirearm.firearmDetails.section.serialNumberRemoved.label')}
            control={control}
            errors={errors}
            onChange={handleCheckboxChange}
          />
        </Grid>
      )}

      {serialNumberEnabled && checkboxStates && (
        <Grid item xs={11} lg={3}>
          <FormInput
            name={`${arrayName}[${index}].serialNumber`}
            label={`${t('addAncillary.list.section.serialNumber.label')}${
              (isTransactionIllegal && !checkboxStates[index] && isAccessorySelected) ||
              (isAccessorySelected && serialNumberEnabled && !isTransactionIllegal) ||
              (isComponentSelected && !checkboxStates[index])
                ? '*'
                : ''
            }`}
            control={control}
            setValue={setValue}
            errors={errors}
            arrayName={arrayName}
            arrayIndex={index}
            fieldName="serialNumber"
            charsLimit={10}
            disabled={checkboxStates[index]}
          />
        </Grid>
      )}

      {serialNumberEnabled && checkboxStates && (
        <Grid item xs={11} lg={3}>
          <FormInput
            name={`${arrayName}[${index}].serialNumberConfirmation`}
            label={`${t('addAncillary.list.section.serialNumberConfirmation.label')}${
              (isTransactionIllegal && !checkboxStates[index] && isAccessorySelected) ||
              (isAccessorySelected && serialNumberEnabled && !isTransactionIllegal) ||
              (isComponentSelected && !checkboxStates[index])
                ? '*'
                : ''
            }`}
            control={control}
            setValue={setValue}
            errors={errors}
            disableCopyPaste
            arrayName={arrayName}
            arrayIndex={index}
            fieldName="serialNumberConfirmation"
            charsLimit={10}
            disabled={checkboxStates[index]}
          />
        </Grid>
      )}

      {(isAccessorySelected && !isTransactionIllegal) ||
        (transactionTypeValue === ETransactionTypes.DECLARED_PIECEMEAL_UNKNOWN_ORIGIN && (
          <Grid item xs={11} lg={3}>
            <FormInput
              name={`${arrayName}[${index}].assetId`}
              label={t('addAncillary.list.section.assetId.label')}
              control={control}
              setValue={setValue}
              errors={errors}
              arrayName={arrayName}
              arrayIndex={index}
              charsLimit={50}
              fieldName="assetId"
            />
          </Grid>
        ))}

      {isExhibitNumberEnabled && (
        <Grid item xs={11} lg={3}>
          <FormInput
            name={`${arrayName}[${index}].exhibitNumber`}
            label={`${t('addAncillary.list.section.exhibitNumber.label')}${
              (isTransactionIllegal && checkboxStates[index] && isAccessorySelected) ||
              (isTransactionIllegal && !serialNumberEnabled && isAccessorySelected) ||
              (isTransactionIllegal && isLotBatchEnabled && isLotBatchNotPresentList[index]) ||
              (isTransactionIllegal && checkboxStates[index] && isComponentSelected)
                ? '*'
                : ''
            }`}
            control={control}
            setValue={setValue}
            errors={errors}
            arrayName={arrayName}
            arrayIndex={index}
            charsLimit={10}
            fieldName="exhibitNumber"
          />
        </Grid>
      )}

      <Grid item xs={12} sm={6} lg={3}>
        <FormInput
          name={`${arrayName}[${index}].externalReferenceNumber`}
          label={
            t('addAncillary.list.section.externalReferenceNo.label') +
            (externalReferenceNumberMandatory ? '*' : '')
          }
          control={control}
          errors={errors}
          arrayName={arrayName}
          setValue={setValue}
          arrayIndex={index}
          fieldName="externalReferenceNumber"
          charsLimit={20}
        />
      </Grid>

      {isManufacturedDateEnabled && (
        <Grid item xs={12} sm={6} lg={3}>
          <FormDatePicker
            name={`${arrayName}[${index}].manufacturedAt`}
            label={`${t('addAncillary.list.section.manufacturedDate.label')}${
              TRANSACTION_INITIAL_TYPES_FOR_MANUFACTURED_DATE_FIELD_REQUIRED.includes(
                transactionTypeValue,
              )
                ? '*'
                : ''
            }`}
            control={control}
            errors={errors}
            arrayName={arrayName}
            arrayIndex={index}
            disabled={ANCILLARY_TRANSACTIONS_WITH_AUTOCOMPLETED_MANUFACTURED_AT.includes(
              transactionTypeValue,
            )}
            fieldName="manufacturedAt"
          />
        </Grid>
      )}
      {isAmmunitionExpiryDateEnabled && (
        <Grid item xs={12} sm={6} lg={3}>
          <FormDatePicker
            name={`${arrayName}[${index}].ammunitionExpiryDate`}
            label={`${t('addAncillary.list.section.ammunitionExpiryDate.label')}`}
            control={control}
            errors={errors}
            arrayName={arrayName}
            arrayIndex={index}
            fieldName="ammunitionExpiryDate"
          />
        </Grid>
      )}
      {customFields.map((customField) => (
        <Grid item xs={12} sm={6} lg={3} key={customField.uniqueId}>
          <FormCustomField
            data={customField}
            control={control}
            setValue={setValue}
            errors={errors}
            arrayName={arrayName}
            arrayIndex={index}
          />
        </Grid>
      ))}
      {fields.length > 1 && (
        <Grid item xs={1}>
          <Button
            variant={EButtonVariants.outlined}
            icon={EIconTypes.delete}
            onClick={removeItem}
            sizePX={EButtonSizesPX.px32}
            sx={{ marginTop: '28px' }}
          />
        </Grid>
      )}
    </Grid>
  );
};

export { AddAncillaryListItem };
