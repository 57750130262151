import { apiClient } from 'utils/apiClient';
import { RequestHeaderKeyTypes, RequestHeaderValueTypes } from 'constants/RequestHeaderTypes';
import { ApiEndpoints } from 'models/apiEndpoints';
import { isResponseError } from 'models/apiClient';
import { downscaleAttachmentIfRequired } from 'utils/image';
import { EMIMETypes } from 'constants/MIMETypes';
import { EFileType } from 'constants/FileType';

const downloadAttachment = async (
  firearmId: string,
  attachmentId: string,
  fileName: string,
  fileType: string,
): Promise<Blob | null> => {
  const { data } = await apiClient.get(
    ApiEndpoints.DOWNLOAD_FIREARM_ATTACHMENT(firearmId, attachmentId),
    {
      responseType: 'arraybuffer',
      headers: {
        [RequestHeaderKeyTypes.RESPONSE_TYPE]: RequestHeaderValueTypes.BLOB,
      },
    },
  );
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}.${fileType.toLowerCase()}`);
  document.body.appendChild(link);
  link.click();

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const deleteAttachment = async (firearmId: string, attachmentId: string): Promise<any> => {
  const res = await apiClient.delete(
    `${ApiEndpoints.DELETE_FIREARM_ATTACHMENT(firearmId, attachmentId)}`,
  );

  if (isResponseError(res.data)) {
    return null;
  }
  return res;
};

const previewAttachment = async (firearmId: string, attachmentId: string): Promise<any> => {
  const { data } = await apiClient.get(
    ApiEndpoints.DOWNLOAD_FIREARM_ATTACHMENT(firearmId, attachmentId),
    {
      responseType: 'arraybuffer',
      headers: {
        [RequestHeaderKeyTypes.RESPONSE_TYPE]: RequestHeaderValueTypes.BLOB,
      },
    },
  );

  if (isResponseError(data)) {
    return null;
  }

  const blob = new Blob([data], { type: 'pdf' });
  return window.URL.createObjectURL(blob);
};

const getType = (fileType: string) => {
  switch (fileType) {
    case EMIMETypes.JPG:
      return EFileType.JPG;
    case EMIMETypes.JPEG:
      return EFileType.JPEG;
    case EMIMETypes.PNG:
      return EFileType.PNG;
    case EMIMETypes.PDF:
      return EFileType.PDF;
    case EMIMETypes.XLSX:
      return EFileType.XLSX;
    default:
      return '';
  }
};

const addAttachment = async (firearmId: string, data: any): Promise<any> => {
  const { attachment, attachmentName } = data;

  const config = {
    headers: {
      [RequestHeaderKeyTypes.CONTENT_TYPE]: RequestHeaderValueTypes.MULTIPART_FORM_DATA,
    },
    timeout: 0,
  };

  const file: any = await downscaleAttachmentIfRequired(attachment);
  const endpoint = data.transactionUniqueId
    ? `${ApiEndpoints.ADD_FIREARM_ATTACHMENT_IN_TRANSACTION(firearmId)}?transactionUniqueId=${
        data.transactionUniqueId
      }`
    : `${ApiEndpoints.ADD_FIREARM_ATTACHMENT(firearmId)}`;
  const formData = new FormData();
  formData.append('multipartFile', file, `${attachmentName}.${getType(attachment?.type)}`);
  const res = await apiClient.post(endpoint, formData, config);

  if (isResponseError(res.data)) {
    return null;
  }
  return res;
};

const addAncillaryAttachment = async (ancillaryId: string, data: any): Promise<any> => {
  const { attachment, attachmentName } = data;
  const config = {
    headers: {
      [RequestHeaderKeyTypes.CONTENT_TYPE]: RequestHeaderValueTypes.MULTIPART_FORM_DATA,
    },
    timeout: 0,
  };

  const file: any = await downscaleAttachmentIfRequired(attachment);
  const endpoint = data.transactionUniqueId
    ? `${ApiEndpoints.ADD_ACCESSORY_ATTACHMENTS_IN_TRANSACTION(
        ancillaryId,
      )}?transactionUniqueId=${data.transactionUniqueId}`
    : `${ApiEndpoints.ADD_ACCESSORY_ATTACHMENTS(ancillaryId)}`;
  const formData = new FormData();
  formData.append('multipartFile', file, `${attachmentName}.${getType(attachment?.type)}`);
  const res = await apiClient.post(endpoint, formData, config);

  if (isResponseError(res.data)) {
    return null;
  }
  return res;
};

const previewAncillaryAttachment = async (
  ancillaryId: string,
  attachmentId: string,
): Promise<any> => {
  const { data } = await apiClient.get(
    ApiEndpoints.GET_ANCILLARY_ATTACHMENT(ancillaryId, attachmentId),
    {
      responseType: 'arraybuffer',
      headers: {
        [RequestHeaderKeyTypes.RESPONSE_TYPE]: RequestHeaderValueTypes.BLOB,
      },
    },
  );

  if (isResponseError(data)) {
    return null;
  }

  const blob = new Blob([data], { type: 'pdf' });
  return window.URL.createObjectURL(blob);
};

const downloadAncillaryAttachment = async (
  ancillaryId: string,
  attachmentId: string,
  fileName: string,
  fileType: string,
): Promise<Blob | null> => {
  const { data } = await apiClient.get(
    ApiEndpoints.GET_ANCILLARY_ATTACHMENT(ancillaryId, attachmentId),
    {
      responseType: 'arraybuffer',
      headers: {
        [RequestHeaderKeyTypes.RESPONSE_TYPE]: RequestHeaderValueTypes.BLOB,
      },
    },
  );
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}.${fileType.toLowerCase()}`);
  document.body.appendChild(link);
  link.click();

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const deleteAncillaryAttachment = async (
  ancillaryId: string,
  attachmentId: string,
): Promise<any> => {
  const res = await apiClient.delete(
    `${ApiEndpoints.DLETE_ANCILLARY_ATTACHMENT(ancillaryId, attachmentId)}`,
  );

  if (isResponseError(res.data)) {
    return null;
  }
  return res;
};

export {
  downloadAttachment,
  deleteAttachment,
  addAttachment,
  addAncillaryAttachment,
  previewAttachment,
  previewAncillaryAttachment,
  downloadAncillaryAttachment,
  deleteAncillaryAttachment,
};
