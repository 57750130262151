import { ITableColumns, ITableDataSource } from 'models/table';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import React from 'react';
import { IFRTSpecifications } from 'models/frt';

export const mapFRTSpecificationToDataSource = (
  specifications: IFRTSpecifications[],
): ITableDataSource[] =>
  specifications.map(
    ({
      id,
      specificationId,
      caliber,
      barrelLength,
      shots,
      level,
      legalClassification,
      barrelType,
    }) => ({
      id,
      specificationId,
      caliber,
      barrelLength,
      shots,
      level,
      legalClassification,
      barrelType,
    }),
  );

interface IFRTSpecificationsTableColumns {
  handleSelect: (specificationId: string, calibre: string) => void;
  isLocal?: boolean | null;
  hasEditButton?: boolean;
  t: (key: string) => string;
  hasPermissionsManageFRT: boolean | null;
}

export const FRTSpecificationsTableColumns = ({
  handleSelect,
  isLocal,
  hasEditButton = false,
  t,
  hasPermissionsManageFRT,
}: IFRTSpecificationsTableColumns): ITableColumns[] => {
  const columns: ITableColumns[] = [
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f511',
      key: isLocal ? 'specificationId' : 'id',
      title: isLocal
        ? t(
            'firearmsReferenceTable.editFirearm.general.accordion.firearmReferenceLocalID.label',
          )
        : t('firearmsReferenceTable.editFirearm.general.accordion.firearmReferenceNo.label'),
      isSortable: false,
    },
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f512',
      key: 'caliber',
      title: t('firearmsReferenceTable.editFirearm.calibre.accordion.calibre.label'),
      isSortable: false,
    },
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f513',
      key: 'shots',
      title: t('firearmsReferenceTable.editFirearm.calibre.accordion.shots.label'),
      width: '70px',
      isSortable: false,
    },
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f514',
      key: 'barrelLength',
      title: t('firearmsReferenceTable.editFirearm.calibre.accordion.barrel.label'),
      width: '150px',
      isSortable: false,
    },
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f515',
      key: 'level',
      title: t('firearmsReferenceTable.editFirearm.calibre.accordion.level.label'),
      isSortable: false,
    },
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f516',
      key: 'legalClassification',
      title: t(
        'firearmsReferenceTable.editFirearm.calibre.accordion.legalClassification.label',
      ),
      isSortable: false,
    },
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f517',
      key: 'barrelType',
      title: t('firearmsReferenceTable.editFirearm.calibre.accordion.barrelTypeCode.label'),
      width: '150px',
      isSortable: false,
    },
  ];

  if (isLocal || (!isLocal && !hasEditButton)) {
    columns.push({
      id: 'fe7a7688-1583-4233-8319-dd5c8973cc44',
      key: '',
      title: t('firearmsReferenceTable.editFirearm.calibre.accordion.action.label'),
      isSortable: false,
      width: '60px',
      align: 'center',
      isCenterTableHead: true,
      render: (_data, props) => (
        <span>
          {hasPermissionsManageFRT && hasEditButton && (
            <Button
              variant={EButtonVariants.outlined}
              icon={EIconTypes.edit}
              onClick={() => handleSelect && handleSelect(props.id, props.caliber)}
            />
          )}
          {!hasEditButton && (
            <Button
              variant={EButtonVariants.outlined}
              icon={EIconTypes.checkmark}
              onClick={() => handleSelect && handleSelect(props.id, props.caliber)}
            />
          )}
        </span>
      ),
    });
  }

  return columns;
};

export const isFRTLocal = (id: string): boolean => !!(id && id.length === 36);

export const mapFirearmsReferenceTableDetailsSpecificationToDataSource = (
  specifications: IFRTSpecifications[],
): ITableDataSource[] =>
  specifications.map(
    ({
      id,
      specificationId,
      caliber,
      barrelLength,
      shots,
      level,
      legalClassification,
      barrelType,
    }) => ({
      id,
      specificationId,
      caliber,
      barrelLength,
      shots,
      level,
      legalClassification,
      barrelType,
    }),
  );

export const FirearmsReferenceTableDetailsSpecificationTableColumns = (
  isLocal: boolean,
): ITableColumns[] => [
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f511',
    key: isLocal ? 'specificationId' : 'id',
    title: isLocal
      ? 'firearmsReferenceTable.firearmDetails.general.accordion.firearmReferenceLocalID.label'
      : 'firearmsReferenceTable.editFirearm.general.accordion.firearmReferenceNo.label',
    isSortable: false,
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f512',
    key: 'caliber',
    title: 'firearmsReferenceTable.firearmDetails.calibres.label',
    isSortable: false,
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f513',
    key: 'shots',
    title: 'firearmsReferenceTable.editFirearm.calibre.accordion.shots.label',
    isSortable: false,
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f514',
    key: 'barrelLength',
    title:
      'firearmsReferenceTable.firearmDetails.calibreShotsAndBarrelLength.accordion.barrelLength.column',
    isSortable: false,
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f515',
    key: 'level',
    title: 'firearmsReferenceTable.accordion.level.label',
    isSortable: false,
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f516',
    key: 'legalClassification',
    title:
      'firearmsReferenceTable.firearmDetails.calibreShotsAndBarrelLength.accordion.legalClassification.column',
    isSortable: false,
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f517',
    key: 'barrelType',
    title:
      'firearmsReferenceTable.firearmDetails.calibreShotsAndBarrelLength.accordion.barrelTypeCode.column',
    isSortable: false,
  },
];
