import React, { PropsWithChildren, useEffect, useMemo } from 'react';
import { licenceInitialState, reducer } from 'state/reducers/licence';
import { LicenceContext } from 'models/licenceContext';
import { getLicenceBasicInfo } from 'requests/licence';
import { LicenceActionTypes } from 'state/actions/licence';
import { ELicenceType } from 'models/licence';
import { getDateFromISO } from 'utils/date';
import { endOfDay } from 'date-fns';
import { ERouteLinks } from 'models/route';

export const LicenceProvider = ({ children }: PropsWithChildren<{}>) => {
  const [state, dispatch] = React.useReducer(reducer, licenceInitialState);

  const setLicenceData = (type: string, payload: any) => {
    dispatch({ type, payload });
  };

  const isOnlineLicence = () => state?.licenceType === ELicenceType.ONLINE;

  const isValidLicence = () => {
    if (state?.expiryDate === null) {
      return true;
    }

    const expiryDate = getDateFromISO(state?.expiryDate);
    if (!expiryDate) {
      return false;
    }

    return endOfDay(expiryDate) > new Date();
  };

  useEffect(() => {
    (async () => {
      const result = await getLicenceBasicInfo();
      if (result) {
        setLicenceData(LicenceActionTypes.SET_LICENCE_DATA, result);
      } else if (!window.location.href.includes(ERouteLinks.StartLicencePage)) {
        // @ts-ignore
        window.location = ERouteLinks.StartLicencePage;
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo(
    () => ({ ...state, setLicenceData, isOnlineLicence, isValidLicence }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state],
  );

  return <LicenceContext.Provider value={value}>{children}</LicenceContext.Provider>;
};
