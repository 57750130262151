import React, { useEffect, useState } from 'react';
import { StyledPageTitle } from 'theme/styles';
import { useQuery } from 'hooks/useQuery';
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import { Accordion } from 'components/molecules/Accordion';
import { Typography } from '@mui/material';
import { StyledDetailsWrapper } from 'pages/Firearms/RegisteredFirearmDeleteCommentPage/styles';
import { Loader } from 'components/atoms/Loader';
import { DetailsTemplate } from 'components/templates/DetailsTemplate';
import { useFRT } from 'pages/FRT/hooks';
import { Table } from 'components/molecules/Table';
import { CardList, emptyFieldHolder } from 'components/molecules/CardList';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import {
  FirearmsReferenceTableDetailsSpecificationTableColumns,
  mapFirearmsReferenceTableDetailsSpecificationToDataSource,
} from 'pages/FRT/helpers';
import { FirearmReferenceTableDetailsCard } from 'pages/Transactions/FirearmReferenceTableDetailsCard';
import { StyledButton } from 'pages/Firearms/ViewRegisteredFirearmPage/styles';
import { useTranslations } from 'hooks/useTranslations';
import { getHtmlText } from 'components/organisms/HtmlSlate';

const FirearmsReferenceTableDetailsPage = () => {
  const query = useQuery();
  const frtId = query.get('frtId') as string;
  const [isFRTSpecificationsLoading, setIsFRTSpecificationsLoading] = useState<boolean>(false);
  const [isAllExpanded, setIsAllExpanded] = useState<boolean>(false);
  const {
    getFRTDetailsData,
    FRTDetails,
    getFRTSpecificationsData,
    FRTSpecifications,
    getFRTNotesData,
    FRTNotes,
  } = useFRT();

  const { isDesktop } = useThemeBreakpoint();

  useEffect(() => {
    (async function getFRTSpecificationsDataInit() {
      try {
        setIsFRTSpecificationsLoading(true);
        await Promise.all([
          getFRTDetailsData(frtId),
          getFRTSpecificationsData(frtId),
          getFRTNotesData(frtId),
        ]);
      } catch (e) {
        console.error(e);
      }
      setIsFRTSpecificationsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frtId]);

  let frtDetailsTitle = '';
  if (FRTDetails !== null) {
    const { make, model, action } = FRTDetails;
    frtDetailsTitle = `${make}, ${model}, ${action}`;
  }
  const calibres = FRTSpecifications.map((spec) => spec.caliber).join(', ');
  const { t } = useTranslations();
  const frtNotes = FRTNotes.map((note) => (
    <Grid key={note.type} item xs={12}>
      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
        {note.type}
      </Typography>
      {getHtmlText(note.description)}
    </Grid>
  ));

  return (
    <div>
      {!isFRTSpecificationsLoading ? (
        <>
          <Box sx={{ mb: 3 }}>
            {FRTDetails && FRTDetails.isLocal ? (
              <StyledPageTitle variant="h4">
                {t('firearmsReferenceTable.editFirearm.viewLocalFirearm.header')}
              </StyledPageTitle>
            ) : (
              <StyledPageTitle variant="h4">
                {t('firearmsReferenceTable.editFirearm.viewFirearm.header')}
              </StyledPageTitle>
            )}
          </Box>
          <Grid container>
            <Grid item xs={12} mb={5}>
              <DetailsTemplate title={frtDetailsTitle}>
                <Grid item xs={12} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t('firearmsReferenceTable.firearmDetails.calibres.label')}
                  </Typography>
                  <Typography variant="body2">{calibres}</Typography>
                </Grid>
              </DetailsTemplate>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', mb: 1 }}>
              <StyledButton
                label={
                  isAllExpanded ? t('general.collapseAll.label') : t('general.expandAll.label')
                }
                onClick={() => setIsAllExpanded((prev) => !prev)}
                variant="text"
              />
            </Grid>
            <Grid item xs={12}>
              <Accordion title="General" expanded={isAllExpanded}>
                {FRTDetails && (
                  <Box mb={2}>
                    <StyledDetailsWrapper container rowGap={3}>
                      <Grid item xs={12} sm={6} lg={4}>
                        {FRTDetails && FRTDetails.isLocal ? (
                          <>
                            <Typography variant="subtitle2">
                              {t(
                                'firearmsReferenceTable.firearmDetails.general.accordion.firearmReferenceLocalID.label',
                              )}
                            </Typography>
                            <Typography variant="body2" sx={{ marginTop: '4px' }}>
                              {FRTDetails.frtId}
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Typography variant="subtitle2">
                              {t(
                                'firearmsReferenceTable.editFirearm.general.accordion.firearmReferenceNo.label',
                              )}
                            </Typography>
                            <Typography variant="body2" sx={{ marginTop: '4px' }}>
                              {FRTDetails.id}
                            </Typography>
                          </>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} lg={4}>
                        <Typography variant="subtitle2">
                          {t(
                            'firearmsReferenceTable.editFirearm.general.accordion.manufacturer.label',
                          )}
                        </Typography>
                        <Typography variant="body2" sx={{ marginTop: '4px' }}>
                          {emptyFieldHolder(FRTDetails.manufacturer)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} lg={4}>
                        <Typography variant="subtitle2">
                          {t('firearmsReferenceTable.accordion.level.label')}
                        </Typography>
                        <Typography variant="body2" sx={{ marginTop: '4px' }}>
                          {emptyFieldHolder(FRTDetails.level)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} lg={4}>
                        <Typography variant="subtitle2">
                          {t(
                            'firearmsReferenceTable.firearmDetails.general.accordion.countryOfManufacturer.label',
                          )}
                        </Typography>
                        <Typography variant="body2" sx={{ marginTop: '4px' }}>
                          {emptyFieldHolder(FRTDetails.country)}
                        </Typography>
                      </Grid>
                    </StyledDetailsWrapper>
                  </Box>
                )}
              </Accordion>
            </Grid>
            <Grid item xs={12}>
              {FRTDetails && (
                <Accordion
                  title={t(
                    'firearmsReferenceTable.firearmDetails.calibreShotsAndBarrelLength.accordion.name',
                  )}
                  expanded={isAllExpanded}
                >
                  <Box mb={2}>
                    {isFRTSpecificationsLoading ? (
                      <Loader isLoading={isFRTSpecificationsLoading} />
                    ) : (
                      <div>
                        {isDesktop ? (
                          <Table
                            columns={FirearmsReferenceTableDetailsSpecificationTableColumns(
                              Boolean(FRTDetails.isLocal),
                            )}
                            dataSource={mapFirearmsReferenceTableDetailsSpecificationToDataSource(
                              FRTSpecifications,
                            )}
                          />
                        ) : (
                          <CardList
                            items={mapFirearmsReferenceTableDetailsSpecificationToDataSource(
                              FRTSpecifications,
                            )}
                            render={(data, index) => (
                              <FirearmReferenceTableDetailsCard data={data} key={index} />
                            )}
                          />
                        )}
                      </div>
                    )}
                  </Box>
                </Accordion>
              )}
            </Grid>
            <Grid item xs={12}>
              <Accordion
                title={t('firearmsReferenceTable.firearmDetails.notes.accordion.name')}
                expanded={isAllExpanded}
              >
                <StyledDetailsWrapper container rowGap={3} mb={5}>
                  {frtNotes}
                </StyledDetailsWrapper>
              </Accordion>
            </Grid>

            {!!FRTDetails?.productCodes.length && (
              <Grid item xs={12}>
                <Accordion
                  title={t('firearmsReferenceTable.accordion.alsoKnownAsProductCode.label')}
                  expanded={isAllExpanded}
                >
                  <StyledDetailsWrapper container rowGap={3}>
                    {FRTDetails.productCodes.map((code) => (
                      <Typography variant="body2">&bull; {code}</Typography>
                    ))}
                  </StyledDetailsWrapper>
                </Accordion>
              </Grid>
            )}
          </Grid>
        </>
      ) : (
        <Loader isLoading={isFRTSpecificationsLoading} />
      )}
    </div>
  );
};

export { FirearmsReferenceTableDetailsPage };
