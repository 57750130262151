import { IPaginator } from 'models/paginator';
import { EPermission } from 'models/permissions';

export interface IDashboardChartData {
  chartValues: { label: string; value: number }[];
}

export enum EDashboardSortType {
  All = 'ALL',
  Day = 'DAY',
  Week = 'WEEK',
  Month = 'MONTH',
  Year = 'YEAR',
}

export enum EGlobalSearchCategories {
  Users = 'users',
  Transactions = 'transactions',
  LegalEntitiesOrganisation = 'legal-entities-organization',
  LegalEntitiesIndividual = 'legal-entities-individual',
  Firearms = 'firearms',
  Accessories = 'accessories',
  Ammunition = 'ammunition',
  Components = 'components',
}

export const GlobalSearchCategoriesPermissions = {
  [EGlobalSearchCategories.Users]: [EPermission.MANAGE_USERS],
  [EGlobalSearchCategories.Transactions]: [
    EPermission.VIEW_TRANSACTIONS,
    EPermission.VIEW_ALL_TRANSACTIONS,
  ],
  [EGlobalSearchCategories.LegalEntitiesOrganisation]: [
    EPermission.VIEW_ALL_LE,
    EPermission.VIEW_LE,
  ],
  [EGlobalSearchCategories.LegalEntitiesIndividual]: [
    EPermission.VIEW_ALL_LE,
    EPermission.VIEW_LE,
  ],
  [EGlobalSearchCategories.Firearms]: [
    EPermission.VIEW_FIREARMS,
    EPermission.VIEW_ALL_FIREARMS,
    EPermission.VIEW_FIREARMS_LE,
  ],
  [EGlobalSearchCategories.Accessories]: [
    EPermission.VIEW_LIMITED_ANCILLARIES,
    EPermission.VIEW_ALL_ANCILLARIES,
    EPermission.VIEW_ANCILLARIES_LE,
  ],
  [EGlobalSearchCategories.Ammunition]: [
    EPermission.VIEW_LIMITED_ANCILLARIES,
    EPermission.VIEW_ALL_ANCILLARIES,
    EPermission.VIEW_ANCILLARIES_LE,
  ],
  [EGlobalSearchCategories.Components]: [
    EPermission.VIEW_LIMITED_ANCILLARIES,
    EPermission.VIEW_ALL_ANCILLARIES,
    EPermission.VIEW_ANCILLARIES_LE,
  ],
};

export interface IGlobalSearchUser {
  active: boolean;
  createdDate: string;
  email: string;
  firstName: string;
  lastLoginDate: string;
  lastName: string;
  phoneNumber: string;
  uniqueId: string;
}

export interface IGlobalSearchUsersResponse extends IPaginator {
  content: IGlobalSearchUser[];
}

export interface IGlobalSearchTransaction {
  keeperName: string;
  make: string;
  model: string;
  ownerName: string;
  serialNumber: string;
  transactionDate: string;
  typeUniqueId: string;
  uniqueId: string;
}

export interface IGlobalSearchTransactionsResponse extends IPaginator {
  content: IGlobalSearchTransaction[];
}

export interface IGlobalSearchFirearm {
  calibre: string;
  fireCycleId: string;
  keeperName: string;
  make: string;
  manufacturer: string;
  model: string;
  ownerName: string;
  serialNumber: string;
  stateUniqueId: string;
  uniqueId: string;
}

export interface IGlobalSearchFirearmsResponse extends IPaginator {
  content: IGlobalSearchFirearm[];
}

export interface IGlobalSearchAccessory {
  uniqueId: string;
  serialNumber: string;
  fireCycleId: string;
  manufacturer: string;
  productName: string;
  accessoryType: string;
  owner: string;
  keeper: string;
}

export interface IGlobalSearchAccessoryResponse extends IPaginator {
  content: IGlobalSearchAccessory[];
}

export interface IGlobalSearchAmmunition {
  uniqueId: string;
  fireCycleId: string;
  lotNumber: string;
  batchNumber: string;
  ammunitionType: string;
  calibre: string;
  manufacturer: string;
  productName: string;
  owner: string;
  keeper: string;
}

export interface IGlobalSearchAmmunitionResponse extends IPaginator {
  content: IGlobalSearchAmmunition[];
}

export interface IGlobalSearchComponent {
  uniqueId: string;
  serialNumber: string;
  fireCycleId: string;
  componentType: string;
  manufacturer: string;
  productName: string;
  firearmType: string;
  make: string;
  model: string;
  owner: string;
  keeper: string;
}

export interface IGlobalSearchComponentResponse extends IPaginator {
  content: IGlobalSearchComponent[];
}

export interface IGlobalSearchLEIndividual {
  city: string | null;
  dateOfBirth: string;
  firstName: string;
  lastName: string;
  uniqueId: string;
}

export interface IGlobalSearchLEIndividualResponse extends IPaginator {
  content: IGlobalSearchLEIndividual[];
}

export interface IGlobalSearchLEOrganization {
  city: string | null;
  departmentName: string;
  organizationName: string;
  organizationTypeUniqueId: string;
  uniqueId: string;
}

export interface IGlobalSearchLEOrganizationResponse extends IPaginator {
  content: IGlobalSearchLEOrganization[];
}
