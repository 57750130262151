import React, { useEffect, useState } from 'react';
import { IAuditLogItemResponse } from 'models/auditLog';
import { Typography } from '@mui/material';
import { AuditLogEventName } from 'pages/AuditLogPage/AuditLogViewPage/styles';
import { ICustomField } from 'models/customField';
import { DetailsTemplate, EDetailsPageTypes } from 'components/templates/DetailsTemplate';
import Grid from '@mui/material/Grid';
import {
  mapCustomFieldToLabel,
  mapCustomFieldTypeToLabel,
} from 'pages/CustomFieldsPage/helpers';
import { useTranslations } from 'hooks/useTranslations';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import { EAuditLogEvents } from 'pages/AuditLogPage/AuditLogViewPage/index';
import { useCustomFields } from 'pages/CustomFieldsPage/hooks';
import { EPermission } from 'models/permissions';
import { usePermission } from 'hooks/usePermission';
import { ERouteLinks } from 'models/route';
import { useNavigate } from 'react-router-dom';

interface ICustomFieldEventDetails {
  auditLog: IAuditLogItemResponse;
}

const CustomFieldEventDetails = ({ auditLog }: ICustomFieldEventDetails) => {
  const { t } = useTranslations();
  const [previousCustomField, setPreviousCustomField] = useState<ICustomField | null>();
  const [customField, setCustomField] = useState<ICustomField | null>();
  const { handleGetCustomField } = useCustomFields();
  const { hasPermissions } = usePermission();
  const navigate = useNavigate();

  useEffect(() => {
    if (auditLog?.previousObjectValue) {
      (async () => {
        const previousObjectValue = auditLog?.previousObjectValue as any;
        const customFieldData: ICustomField = {
          deletable: previousObjectValue.deletable || '',
          dictionaryUniqueId: previousObjectValue.dictionaryUniqueId || '',
          enabled: previousObjectValue.enabled || '',
          inputMask: previousObjectValue.inputMask || '',
          inputMaskEnabled: previousObjectValue.inputMaskEnabled || '',
          mandatory: previousObjectValue.mandatory || '',
          name: previousObjectValue.name || '',
          objectType: previousObjectValue.objectType || '',
          placeholderUniqueId: previousObjectValue.placeholderUniqueId || '',
          searchCriteria: previousObjectValue.searchCriteria || '',
          uniqueId: previousObjectValue.uniqueId || '',
          uniqueValue: previousObjectValue.uniqueValue || '',
          visibleInSearchResults: previousObjectValue.visibleInSearchResults || '',
          customFieldType: previousObjectValue.customFieldType || '',
        };
        setPreviousCustomField(customFieldData);
      })();
    }
    (async () => {
      if (auditLog?.objectUniqueId && hasPermissions([EPermission.MANAGE_FRT_FIREARM])) {
        try {
          const response = await handleGetCustomField(auditLog.objectUniqueId);
          if (response) {
            setCustomField(response);
          }
        } catch (e) {
          console.error(e);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auditLog]);

  return (
    <>
      {previousCustomField && (
        <>
          <AuditLogEventName>
            <Typography variant="h5">
              {`${t('auditLogDetails.customFieldDetails.title')} ${
                auditLog?.eventNameUniqueId !== EAuditLogEvents.ADD_CUSTOM_FIELD
                  ? t('auditLogDetails.before.event.title')
                  : t('auditLogDetails.after.event.title')
              }`}
            </Typography>
          </AuditLogEventName>
          <DetailsTemplate
            pageType={EDetailsPageTypes.auditLogView}
            title={previousCustomField.name}
            disableActions={true}
          >
            <Grid item xs={12} sm={6} lg={3}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('customFields.card.objectType.label')}
              </Typography>
              <Typography variant="body2">
                {t(mapCustomFieldToLabel(previousCustomField.objectType))}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('customFields.card.name.label')}
              </Typography>
              <Typography variant="body2">{previousCustomField.name}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('customFields.card.type.label')}
              </Typography>
              <Typography variant="body2">
                {t(mapCustomFieldTypeToLabel(previousCustomField.customFieldType))}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('customFields.card.status.label')}
              </Typography>
              <Chips
                text={
                  previousCustomField.enabled
                    ? t('customFields.card.enabled.label')
                    : t('customFields.card.disabled.label')
                }
                variant={
                  previousCustomField.enabled
                    ? EChipsVariants.success
                    : EChipsVariants.inactive
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('customFields.card.mandatoryField.label')}
              </Typography>
              <Chips
                text={
                  previousCustomField.mandatory
                    ? t('customFields.card.yes.label')
                    : t('customFields.card.no.label')
                }
                variant={
                  previousCustomField.mandatory
                    ? EChipsVariants.primary
                    : EChipsVariants.inactive
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('customFields.searchCriterion.label')}
              </Typography>
              <Chips
                text={
                  previousCustomField.searchCriteria
                    ? t('customFields.card.yes.label')
                    : t('customFields.card.no.label')
                }
                variant={
                  previousCustomField.searchCriteria
                    ? EChipsVariants.primary
                    : EChipsVariants.inactive
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('customFields.visibleSearchResults.label')}
              </Typography>
              <Chips
                text={
                  previousCustomField.visibleInSearchResults
                    ? t('customFields.card.yes.label')
                    : t('customFields.card.no.label')
                }
                variant={
                  previousCustomField.visibleInSearchResults
                    ? EChipsVariants.primary
                    : EChipsVariants.inactive
                }
              />
            </Grid>
          </DetailsTemplate>
        </>
      )}

      {previousCustomField &&
        auditLog?.eventNameUniqueId !== EAuditLogEvents.DELETE_CUSTOM_FIELD && (
          <AuditLogEventName sx={{ marginTop: '20px' }}>
            <Typography variant="h5">
              {`${t('auditLogDetails.customFieldDetails.title')} ${t(
                'auditLogDetails.current.data.event.title',
              )}`}
            </Typography>
          </AuditLogEventName>
        )}

      {customField && (
        <DetailsTemplate
          pageType={EDetailsPageTypes.auditLogView}
          title={customField.name}
          redirectToView={() =>
            navigate(
              `${ERouteLinks.CustomFields.replace(':id', customField.uniqueId)}${
                customField.objectType ? `?expand=${customField.objectType}` : ''
              }`,
            )
          }
          disableActions={!customField.uniqueId}
        >
          <Grid item xs={12} sm={6} lg={3}>
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('customFields.card.objectType.label')}
            </Typography>
            <Typography variant="body2">
              {t(mapCustomFieldToLabel(customField.objectType))}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('customFields.card.name.label')}
            </Typography>
            <Typography variant="body2">{customField.name}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('customFields.card.type.label')}
            </Typography>
            <Typography variant="body2">
              {t(mapCustomFieldTypeToLabel(customField.customFieldType))}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('customFields.card.status.label')}
            </Typography>
            <Chips
              text={
                customField.enabled
                  ? t('customFields.card.enabled.label')
                  : t('customFields.card.disabled.label')
              }
              variant={customField.enabled ? EChipsVariants.success : EChipsVariants.inactive}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('customFields.card.mandatoryField.label')}
            </Typography>
            <Chips
              text={
                customField.mandatory
                  ? t('customFields.card.yes.label')
                  : t('customFields.card.no.label')
              }
              variant={
                customField.mandatory ? EChipsVariants.primary : EChipsVariants.inactive
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('customFields.searchCriterion.label')}
            </Typography>
            <Chips
              text={
                customField.searchCriteria
                  ? t('customFields.card.yes.label')
                  : t('customFields.card.no.label')
              }
              variant={
                customField.searchCriteria ? EChipsVariants.primary : EChipsVariants.inactive
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('customFields.visibleSearchResults.label')}
            </Typography>
            <Chips
              text={
                customField.visibleInSearchResults
                  ? t('customFields.card.yes.label')
                  : t('customFields.card.no.label')
              }
              variant={
                customField.visibleInSearchResults
                  ? EChipsVariants.primary
                  : EChipsVariants.inactive
              }
            />
          </Grid>
        </DetailsTemplate>
      )}
    </>
  );
};

export default CustomFieldEventDetails;
