import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { FormSelect } from 'components/molecules/FormSelect';
import { useForm } from 'react-hook-form';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { SearchWrapper } from 'components/atoms/SearchWrapper';
import { EFormInputTypes, FormInput } from 'components/molecules/FormInput';
import {
  mapDictionaryToOptionProp,
  mapSpecificDictionaryToOptionProp,
} from 'helpers/dictionary';
import { SelectedFilters } from 'components/organisms/SelectedFilters';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { Box, Typography } from '@mui/material';
import { ERouteLinks } from 'models/route';
import { ESearchFilterKeys, ESearchFilterTypes } from 'constants/SearchFilterTypes';
import { useFilterPresets } from 'hooks/useFilterPresets';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { prepareFilters } from 'helpers/filters';
import { useTranslations } from 'hooks/useTranslations';
import { useAncillaries } from 'pages/AncillariesPage/hooks';
import {
  IFormElementOptionProp,
  initialAncillariesSearchFormValues,
  ISearchAncillariesFormValues,
} from 'models/form';
import { useLang } from 'models/langContext';
import { EDictionaryTypes } from 'models/dictionary';
import { getFieldsByComponentType, isEmptyObject } from 'pages/AncillariesPage/helpers';
import {
  EAncillaryFilterTypes,
  EAncillaryState,
  EArtAncillaryExpandType,
  EArtAncillaryType,
  EArtAncillaryTypeNameLowerCase,
  EArtComponentType,
} from 'constants/ArtAccessoryType';
import { ECustomFieldType, ICustomFieldByObjectType } from 'models/customField';
import FormCustomField from 'components/organisms/FormCustomField';
import { ISearchFormCustomFieldValue } from 'pages/LegalEntitiesPage/IndividualSearchForm';
import { formatDateTimeToIso } from 'utils/date';
import { EFirearmType } from 'pages/AncillariesPage/AncillariesReferenceTablePage/ARTComponentsForm/helpers';
import { FormAutocomplete } from 'components/molecules/FormAutocomplete';
import { ESearchFormFirearmLabels } from 'pages/Firearms/RegisteredFirearmsSearchForm';
import { getSearchFilter } from 'requests/searchFilter';
import { ISearchParams } from 'pages/UsersPage';
import { isEmpty } from 'utils/search';
import { useDrawerClicked } from 'state/contexts/drawer';
import { IAncillary } from 'models/ancillaries';
import { EPermission } from 'models/permissions';
import { usePermission } from 'hooks/usePermission';
import {
  StyledAdvancedSearch,
  StyledFormSelect,
  StyledPresetSelect,
  StyledSubtitle,
} from './styles';

export enum ESearchFormLabels {
  ancillaryTypeUniqueId = 'componentsTable.accordion.componentType.label',
  fireCycleId = 'componentsTable.accordion.fireCycleId.label',
  serialNumber = 'componentsTable.accordion.serialNumber.label',
  exhibitNumber = 'componentsTable.accordion.exhibitNumber.label',
  manufacturerUniqueId = 'componentsTable.accordion.componentManufacturer.label',
  productName = 'componentsTable.accordion.productName.label',
  calibreUniqueId = 'componentsTable.accordion.calibre.label',
  length = 'componentsTable.accordion.length.label',
  compositionUniqueId = 'componentsTable.accordion.composition.label',
  componentCapacityUniqueId = 'componentsTable.accordion.capacity.label',
  proofHouseUniqueId = 'componentsTable.accordion.proofhouse.label',
  stateUniqueId = 'componentsTable.accordion.componentState.label',
  legalityUniqueId = 'componentsTable.accordion.componentLegality.label',
  frtType = 'componentsTable.accordion.frtType.label',
  frtMake = 'componentsTable.accordion.frtMake.label',
  frtModel = 'componentsTable.accordion.frtModel.label',
  frtManufacturer = 'componentsTable.accordion.frtManufacturer.label',
  owner = 'accessoriesTable.accordion.owner.label',
  keeper = 'accessoriesTable.accordion.keeper.label',
  ownerUniqueId = 'componentsTable.accordion.owner.label',
  keeperUniqueId = 'componentsTable.accordion.keeper.label',
}

export enum ESelectLE {
  keeper = 'keeper',
  owner = 'owner',
}

interface ISearchForm {
  onSubmit: (arg: any) => void;
  ownerOptions: IFormElementOptionProp[];
  setOwnerOptions: (opt: IFormElementOptionProp[]) => void;
  keeperOptions: IFormElementOptionProp[];
  setKeeperOptions: (opt: IFormElementOptionProp[]) => void;
  showAdvanced?: boolean;
  componentsUniqueIds?: string[];
  isFromMap?: boolean;
  returnPath: string;
  getNewQueryPath?: (args: any) => string;
  setShowAdvanced?: (val: boolean) => void;
  initialFormValues?: ISearchAncillariesFormValues;
  customFields?: ICustomFieldByObjectType[];
  searchAncillaryFromLE?: boolean;
  isSearchButtonDisabled?: boolean;
  addAncillaryToGroupContext?: boolean;
  selectedAncillaries?: IAncillary[];
}

const customFieldWrapper = (children: React.ReactNode) => (
  <Grid item lg={2.5} md={3} sm={5} xs={10}>
    {children}
  </Grid>
);

const ComponentsSearchForm = ({
  ownerOptions,
  keeperOptions,
  setOwnerOptions,
  setKeeperOptions,
  showAdvanced = true,
  setShowAdvanced = () => {},
  componentsUniqueIds,
  isFromMap = false,
  returnPath,
  onSubmit,
  getNewQueryPath,
  initialFormValues = initialAncillariesSearchFormValues,
  searchAncillaryFromLE = false,
  isSearchButtonDisabled = false,
  addAncillaryToGroupContext = false,
  selectedAncillaries,
  customFields = [],
}: ISearchForm) => {
  const [values, setValues] = useState<ISearchAncillariesFormValues>({
    ...initialFormValues,
    componentsUniqueIds,
  });
  const [accessFields, setAccessFields] = useState<string[]>([]);
  const { state: routerState }: any = useLocation();
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { hasPermissions } = usePermission();
  const { drawerClicked } = useDrawerClicked();
  const {
    control,
    handleSubmit,
    watch,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<ISearchAncillariesFormValues>({
    defaultValues: initialFormValues,
  });

  const { isDesktop } = useThemeBreakpoint();
  const { selectedLanguage } = useLang();
  const [searchParams] = useSearchParams();
  const presetId =
    searchParams.get('presetId') ||
    searchParams.get('preset') ||
    routerState?.presetAncillaries;
  const expandParam = searchParams.get('expand');
  const watchPreset = watch('preset');
  const watchComponentTypeUniqueId = watch('ancillaryTypeUniqueId');
  const [showSaveFilter, setShowSaveFilter] = useState(false);

  const {
    loadDictionaries,
    legality,
    state,
    artComponentCalibre,
    artComponentCapacity,
    artComposition,
    artComponentManufacturer,
    artComponentType,
    artComponentProofHouse,
    make,
    model,
    manufacturer,
    firearmType,
  } = useAncillaries();

  const { getFilterPresets, presets, getPresetValues, preparePresetsList } =
    useFilterPresets();

  const setPresetFilters = async (
    selectedPreset: string,
    isFromEmailLink: boolean,
    isClickedManually: boolean = false,
    isInitialLoad: boolean = false,
  ) => {
    const handleValues = async () => {
      if (isFromEmailLink) {
        const filter = await getSearchFilter(selectedPreset);
        if (filter) {
          const queryValues: ISearchParams = {};
          filter.values.forEach((it) => {
            queryValues[it.key] = it.value;
          });
          return queryValues;
        }
      }
      return getPresetValues(selectedPreset);
    };

    const initialValues = isInitialLoad
      ? { ...initialFormValues, ...routerState?.formValues }
      : initialFormValues;

    const presetValues: any = {
      ...(isClickedManually ? initialAncillariesSearchFormValues : initialValues),
      preset: selectedPreset,
      ...(await handleValues()),
    };

    if (isClickedManually) {
      customFields
        .filter((it) => it.searchCriteria)
        .forEach((it) => {
          if (it.customFieldType === ECustomFieldType.DATE) {
            presetValues[`from_${it.name}`] = '';
            presetValues[`to_${it.name}`] = '';
          } else {
            presetValues[it.name] = '';
          }
        });
    }

    if (presetValues.ownerUniqueId && presetValues.ownerName) {
      setOwnerOptions([
        {
          label: presetValues.ownerName,
          value: presetValues.ownerUniqueId,
        },
      ]);
    }

    if (presetValues.keeperUniqueId && presetValues.keeperName) {
      setKeeperOptions([
        {
          label: presetValues.keeperName,
          value: presetValues.keeperUniqueId,
        },
      ]);
    }

    if (presetValues.ancillaryTypeUniqueId) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      componentTypeChange(presetValues.ancillaryTypeUniqueId);
    }
    setValues(presetValues);
    if (selectedPreset !== '') {
      setShowAdvanced(true);
    }
    reset(presetValues);

    if (isClickedManually) {
      await handleSubmit(onSubmit)();
    }
  };

  const setLegalEntity = () => {
    const formValues = {
      ...initialFormValues,
      ...routerState?.formValues,
    };

    if (routerState?.type && routerState?.legalEntity) {
      const legalName = routerState?.legalEntity?.['organization.organizationName']
        ? routerState?.legalEntity?.['organization.organizationName']
        : `${routerState?.legalEntity?.['individual.firstName']} ${routerState?.legalEntity?.['individual.lastName']}`;
      const option = [
        {
          label: legalName,
          value: routerState.legalEntity.id,
        },
      ];
      if (routerState.type === ESelectLE.keeper) {
        setKeeperOptions(option);
        formValues.keeperName = legalName;
        if (routerState?.options?.ownerOptions) {
          setOwnerOptions(routerState.options.ownerOptions);
        }
      } else {
        setOwnerOptions(option);
        formValues.ownerName = legalName;
        if (routerState?.options?.keeperOptions) {
          setKeeperOptions(routerState.options.keeperOptions);
        }
      }
      formValues[routerState?.type === ESelectLE.owner ? 'ownerUniqueId' : 'keeperUniqueId'] =
        routerState.legalEntity.id;
    }
    setValues(formValues);
    setShowAdvanced(true);
    reset(formValues);
  };

  useEffect(() => {
    const subscription = watch((value) => {
      setValues(value as ISearchAncillariesFormValues);
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  useEffect(() => {
    (async function initDictionaries() {
      await loadDictionaries([
        EDictionaryTypes.Legality,
        EDictionaryTypes.State,
        EDictionaryTypes.ArtComponentCalibre,
        EDictionaryTypes.ArtComponentManufacturer,
        EDictionaryTypes.ArtComponentType,
        EDictionaryTypes.ArtComponentProofHouse,
        EDictionaryTypes.ManufacturerCountry,
        EDictionaryTypes.ArtComposition,
        EDictionaryTypes.ArtComponentCapacity,
        EDictionaryTypes.Make,
        EDictionaryTypes.Model,
        EDictionaryTypes.Manufacturer,
        EDictionaryTypes.FirearmType,
      ]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  useEffect(() => {
    if (
      routerState?.formValues &&
      routerState?.module === EArtAncillaryTypeNameLowerCase.COMPONENTS
    ) {
      const formValues = {
        ...routerState.formValues,
        legalityUniqueId: routerState?.legalityUniqueId,
        keeperUniqueId: routerState?.options?.keeperOptions[0]?.value || '',
        ownerUniqueId: routerState?.options?.ownerOptions[0]?.value || '',
      };
      setValues(formValues);
      reset(formValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      isEmpty(values.ancillaryTypeUniqueId) &&
      isEmpty(values.fireCycleId) &&
      isEmpty(values.serialNumber) &&
      isEmpty(values.exhibitNumber) &&
      isEmpty(values.manufacturerUniqueId) &&
      isEmpty(values.productName) &&
      isEmpty(values.calibreUniqueId) &&
      isEmpty(values.length) &&
      isEmpty(values.compositionUniqueId) &&
      isEmpty(values.componentCapacityUniqueId) &&
      isEmpty(values.proofHouseUniqueId) &&
      isEmpty(values.stateUniqueId) &&
      isEmpty(values.legalityUniqueId) &&
      isEmpty(values.ownerUniqueId) &&
      isEmpty(values.keeperUniqueId) &&
      isEmpty(values.frtType) &&
      isEmpty(values.frtMake) &&
      isEmpty(values.frtModel) &&
      isEmpty(values.frtManufacturer)
    ) {
      setShowSaveFilter(false);
    } else {
      setShowSaveFilter(true);
    }
  }, [values]);

  useEffect(() => {
    (async function init() {
      await getFilterPresets(ESearchFilterTypes.ancillaries, EArtAncillaryType.COMPONENTS);

      if (routerState?.options?.keeperOptions.length) {
        setKeeperOptions(routerState.options.keeperOptions);
      }

      if (routerState?.options?.ownerOptions.length) {
        setOwnerOptions(routerState.options.ownerOptions);
      }

      if (presetId && expandParam === EArtAncillaryExpandType.COMPONENTS) {
        await setPresetFilters(presetId, true);
        await handleSubmit(onSubmit)();
      }

      if (
        routerState?.presetAncillaries &&
        routerState?.ancillaryFilterType === EAncillaryFilterTypes.COMPONENT
      ) {
        setValue('preset', routerState.presetAncillaries);
      }

      if (!isEmptyObject(routerState?.formValues)) {
        setShowAdvanced(true);
      }
      if (routerState?.legalEntity) {
        setLegalEntity();
      }
      if (
        routerState?.module === EArtAncillaryTypeNameLowerCase.ACCESSORY ||
        routerState?.module === EArtAncillaryTypeNameLowerCase.AMMUNITION
      ) {
        reset(values);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      Object.values(EArtComponentType).includes(
        watchComponentTypeUniqueId as EArtComponentType,
      )
    ) {
      const fields = getFieldsByComponentType(watchComponentTypeUniqueId as EArtComponentType);
      setAccessFields(fields);
    }
    if (watchComponentTypeUniqueId === '') {
      setAccessFields([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchComponentTypeUniqueId]);

  const prepareData = (data: ISearchAncillariesFormValues): ISearchAncillariesFormValues => {
    const customFieldsPayload: ISearchFormCustomFieldValue[] = [];

    Object.keys(data).map((key) => {
      const isDateCF = key.startsWith('from_') || key.startsWith('to_');

      if (!isDateCF) {
        const customField = customFields.find((item) => item.name === key);
        if (customField && data[key]) {
          const customFieldItem: ISearchFormCustomFieldValue = {
            customFieldUniqueId: customField.uniqueId,
            customFieldType: customField.customFieldType,
            name: customField.name,
          };
          if (customField.customFieldType === ECustomFieldType.TEXT) {
            if (data[key] === undefined || data[key] === null) {
              customFieldItem.text = undefined;
            } else if (data[key].trim() === '') {
              customFieldItem.text = undefined;
            } else {
              customFieldItem.text = data[key];
            }
          } else if (customField.customFieldType === ECustomFieldType.BOOL) {
            customFieldItem.bool = data[key] === '1';
          }

          customFieldsPayload.push(customFieldItem);
          delete data[key];
        }
      } else {
        const customFieldKey = key.startsWith('from_')
          ? key.replace('from_', '')
          : key.replace('to_', '');

        const customField = customFields.find((item) => item.name === customFieldKey);

        if (customField && (data[`from_${customFieldKey}`] || data[`to_${customFieldKey}`])) {
          const customFieldItem: ISearchFormCustomFieldValue = {
            customFieldUniqueId: customField.uniqueId,
            customFieldType: customField.customFieldType,
            name: customField.name,
          };
          if (data[`from_${customFieldKey}`]) {
            customFieldItem.dateFrom =
              data[`from_${customFieldKey}`] !== null
                ? formatDateTimeToIso(data[`from_${customFieldKey}`])
                : formatDateTimeToIso(new Date().toString());
          }
          if (data[`to_${customFieldKey}`]) {
            customFieldItem.dateTo =
              data[`to_${customFieldKey}`] !== null
                ? formatDateTimeToIso(data[`to_${customFieldKey}`])
                : formatDateTimeToIso(new Date().toString());
          }
          customFieldsPayload.push(customFieldItem);
          if (data[`from_${customFieldKey}`]) {
            delete data[`from_${customFieldKey}`];
          }
          if (data[`to_${customFieldKey}`]) {
            delete data[`to_${customFieldKey}`];
          }
        }
      }
      return false;
    });

    if (customFieldsPayload?.length) {
      data.customFields = customFieldsPayload;
    } else {
      delete data.customFields;
    }

    if (ownerOptions.length > 0) {
      data.ownerName = ownerOptions[0]?.label || '';
    }
    if (keeperOptions.length > 0) {
      data.keeperName = keeperOptions[0]?.label || '';
    }
    return data;
  };

  const submitForm = (data: ISearchAncillariesFormValues) => {
    onSubmit({ ...prepareData(data), preset: undefined, formSubmitted: true });
  };

  const componentTypeChange = (value: any) => {
    const currentValues = getValues();
    const formValues = {
      ...initialAncillariesSearchFormValues,
      ancillaryTypeUniqueId: value,
      preset: currentValues.preset,
      ownerUniqueId: currentValues.ownerUniqueId,
      keeperUniqueId: currentValues.keeperUniqueId,
    };

    setValues(formValues);

    if (value) {
      const fields = getFieldsByComponentType(value);
      setAccessFields(fields);
    } else {
      setAccessFields([]);
    }
  };

  useEffect(() => {
    if (initialFormValues?.ancillaryTypeUniqueId) {
      const fields = getFieldsByComponentType(
        initialFormValues.ancillaryTypeUniqueId as EArtComponentType,
      );
      setAccessFields(fields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetValues = () => {
    const formValues = { ...getValues() };
    Object.keys(formValues).map((key: string) => {
      if (typeof values[key] === 'string') {
        values[key] = '';
      } else if (typeof values[key] === 'object') {
        values[key] = null;
      }
      return false;
    });
    reset(values);
  };

  const handleResetField = (name?: string, refreshResults: boolean = true) => {
    const currentValues = getValues();
    if (name) {
      if (name === 'ancillaryTypeUniqueId') {
        setValue('ancillaryTypeUniqueId', '');
        resetValues();
        componentTypeChange(null);
        onSubmit(values);
        return;
      }
      if (name === 'ownerUniqueId') {
        setOwnerOptions([]);
        currentValues.ownerUniqueId = '';
      }
      if (name === 'keeperUniqueId') {
        setKeeperOptions([]);
        currentValues.keeperUniqueId = '';
      }
      reset({ ...currentValues, [name]: '' });
    } else {
      resetValues();
    }

    if (refreshResults) {
      onSubmit(values);
    }
  };

  useEffect(() => {
    if (drawerClicked) {
      handleResetField();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerClicked]);

  useEffect(() => {
    (async function init() {
      if (watchPreset) {
        await setPresetFilters(watchPreset, false, false, true);
      } else {
        await setPresetFilters('', false, false, true);
      }
      await handleSubmit(onSubmit)();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initialAncillariesSearchFormValues.ancillaryTypeUniqueId) {
      componentTypeChange(initialAncillariesSearchFormValues.ancillaryTypeUniqueId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialAncillariesSearchFormValues]);

  const getFirearmTypesForComponent = (): EFirearmType[] => {
    switch (watchComponentTypeUniqueId) {
      case EArtComponentType.UPPER_RECEIVER:
        return [
          EFirearmType.AIRSOFT,
          EFirearmType.COMBINATION_GUN,
          EFirearmType.MACHINE_GUN,
          EFirearmType.OTHER,
          EFirearmType.RIFLE,
          EFirearmType.SHOTGUN,
          EFirearmType.SUBMACHINE_GUN,
          EFirearmType.GRENADE_LAUNCHER,
          EFirearmType.RECOILLESS_RIFLE,
        ];
      case EArtComponentType.LOWER_RECEIVER:
        return [
          EFirearmType.AIRSOFT,
          EFirearmType.COMBINATION_GUN,
          EFirearmType.MACHINE_GUN,
          EFirearmType.OTHER,
          EFirearmType.RIFLE,
          EFirearmType.SHOTGUN,
          EFirearmType.SUBMACHINE_GUN,
          EFirearmType.ROCKET_LAUNCHER,
          EFirearmType.MANPAD,
          EFirearmType.ANTI_TANK_MISSILE,
          EFirearmType.GRENADE_LAUNCHER,
          EFirearmType.RECOILLESS_RIFLE,
        ];
      case EArtComponentType.FRAME:
        return [
          EFirearmType.AIRSOFT,
          EFirearmType.COMBINATION_GUN,
          EFirearmType.MACHINE_GUN,
          EFirearmType.OTHER,
          EFirearmType.RIFLE,
          EFirearmType.SHOTGUN,
          EFirearmType.SUBMACHINE_GUN,
          EFirearmType.HANDGUN,
          EFirearmType.CANNON,
          EFirearmType.GRENADE_LAUNCHER,
          EFirearmType.RECOILLESS_RIFLE,
          EFirearmType.ROCKET_LAUNCHER,
          EFirearmType.MANPAD,
          EFirearmType.ANTI_TANK_MISSILE,
        ];
      case EArtComponentType.BARREL:
        return [
          EFirearmType.AIRSOFT,
          EFirearmType.CANNON,
          EFirearmType.COMBINATION_GUN,
          EFirearmType.GRENADE_LAUNCHER,
          EFirearmType.MACHINE_GUN,
          EFirearmType.OTHER,
          EFirearmType.RIFLE,
          EFirearmType.SHOTGUN,
          EFirearmType.SUBMACHINE_GUN,
          EFirearmType.RECOILLESS_RIFLE,
          EFirearmType.ROCKET_LAUNCHER,
          EFirearmType.MANPAD,
          EFirearmType.ANTI_TANK_MISSILE,
          EFirearmType.HANDGUN,
          EFirearmType.GRENADE_LAUNCHER,
        ];
      case EArtComponentType.BOLT:
        return [
          EFirearmType.AIRSOFT,
          EFirearmType.COMBINATION_GUN,
          EFirearmType.MACHINE_GUN,
          EFirearmType.OTHER,
          EFirearmType.RIFLE,
          EFirearmType.SHOTGUN,
          EFirearmType.SUBMACHINE_GUN,
          EFirearmType.HANDGUN,
          EFirearmType.CANNON,
        ];
      case EArtComponentType.SLIDE:
        return [EFirearmType.AIRSOFT, EFirearmType.OTHER, EFirearmType.HANDGUN];
      case EArtComponentType.CYLINDER:
        return [
          EFirearmType.GRENADE_LAUNCHER,
          EFirearmType.OTHER,
          EFirearmType.HANDGUN,
          EFirearmType.AIRSOFT,
        ];
      default:
        return Object.values(EFirearmType);
    }
  };

  const checkDisabled = (fieldName: string) => !accessFields.includes(fieldName);

  const clearAfterComponentTypeChange = () => {
    const formValues = { ...getValues() };
    Object.keys(formValues).map((key: string) => {
      if (typeof values[key] === 'string') {
        values[key] = '';
      } else if (typeof values[key] === 'object') {
        values[key] = null;
      }
      return false;
    });
    values.ancillaryTypeUniqueId = formValues.ancillaryTypeUniqueId;
    reset(values);
    onSubmit(values);
  };

  const getStateOptions = () => {
    if (!hasPermissions([EPermission.DISABLE_ANCILLARY])) {
      return mapDictionaryToOptionProp(state).filter(
        (item) => item.value !== EAncillaryState.DISABLED,
      );
    }
    return mapDictionaryToOptionProp(state);
  };

  return (
    <SearchWrapper>
      <form onSubmit={handleSubmit(submitForm)}>
        {!searchAncillaryFromLE && (
          <Grid
            container
            justifyContent="space-between"
            sx={{ mb: { xs: 2, md: 0 } }}
            spacing={1}
          >
            <Grid item md={6} sm={12} xs={12}>
              <StyledPresetSelect
                options={
                  presets
                    ? preparePresetsList(
                        presets,
                        'ancillaries.accordion.selectFilterPreset.label',
                      )
                    : preparePresetsList([], 'ancillaries.accordion.selectFilterPreset.label')
                }
                name="preset"
                control={control}
                errors={errors}
                setValue={(name: string, value: string) => {
                  setValue('preset', value);
                  handleResetField();
                }}
                withValidation={false}
                onSelect={(val) => {
                  if (!val) {
                    handleResetField();
                  } else {
                    setPresetFilters(val, false, true);
                  }
                }}
                withLabel={false}
              />
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  variant={EButtonVariants.outlined}
                  label={t('ancillaries.accordion.advancedFilters.button')}
                  onClick={() => setShowAdvanced(!showAdvanced)}
                  fullWidth={!isDesktop}
                  size={EButtonSizes.small}
                />
              </Box>
            </Grid>
          </Grid>
        )}
        {showAdvanced && (
          <StyledAdvancedSearch container spacing={2} columns={10}>
            <Grid item xs={12}>
              <StyledSubtitle item xs={3}>
                <Typography variant="subtitle2">
                  {t('ancillaries.accordion.subtilte.label')}
                </Typography>
              </StyledSubtitle>
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormSelect
                options={mapDictionaryToOptionProp(artComponentType)}
                name="ancillaryTypeUniqueId"
                label={t(ESearchFormLabels.ancillaryTypeUniqueId)}
                setValue={(name: string, value: string) => {
                  setValue('ancillaryTypeUniqueId', value);
                  if (!value) {
                    componentTypeChange(null);
                    handleResetField();
                  }
                }}
                canBeEmpty
                control={control}
                errors={errors}
                onSelect={clearAfterComponentTypeChange}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormInput
                name="fireCycleId"
                label={t(ESearchFormLabels.fireCycleId)}
                control={control}
                errors={errors}
                setValue={setValue}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormInput
                name="serialNumber"
                label={t(ESearchFormLabels.serialNumber)}
                control={control}
                errors={errors}
                disabled={checkDisabled('serialNumber')}
                setValue={setValue}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormInput
                name="exhibitNumber"
                label={t(ESearchFormLabels.exhibitNumber)}
                control={control}
                errors={errors}
                disabled={checkDisabled('exhibitNumber')}
                setValue={setValue}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormSelect
                name="manufacturerUniqueId"
                options={mapDictionaryToOptionProp(artComponentManufacturer)}
                label={t(ESearchFormLabels.manufacturerUniqueId)}
                control={control}
                errors={errors}
                setValue={setValue}
                disabled={checkDisabled('manufacturerUniqueId')}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormInput
                name="productName"
                label={t(ESearchFormLabels.productName)}
                control={control}
                errors={errors}
                disabled={checkDisabled('productName')}
                setValue={setValue}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormSelect
                options={mapDictionaryToOptionProp(artComponentCalibre)}
                name="calibreUniqueId"
                label={t(ESearchFormLabels.calibreUniqueId)}
                control={control}
                errors={errors}
                setValue={setValue}
                disabled={checkDisabled('calibreUniqueId')}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormInput
                name="length"
                label={t(ESearchFormLabels.length)}
                control={control}
                errors={errors}
                disabled={checkDisabled('length')}
                setValue={setValue}
                type={EFormInputTypes.number}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormSelect
                options={mapDictionaryToOptionProp(artComposition)}
                name="compositionUniqueId"
                label={t(ESearchFormLabels.compositionUniqueId)}
                canBeEmpty
                control={control}
                errors={errors}
                disabled={checkDisabled('compositionUniqueId')}
                setValue={setValue}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormSelect
                options={mapDictionaryToOptionProp(artComponentCapacity)}
                name="componentCapacityUniqueId"
                label={t(ESearchFormLabels.componentCapacityUniqueId)}
                canBeEmpty
                control={control}
                errors={errors}
                disabled={checkDisabled('componentCapacityUniqueId')}
                setValue={setValue}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormSelect
                options={mapDictionaryToOptionProp(artComponentProofHouse)}
                name="proofHouseUniqueId"
                label={t(ESearchFormLabels.proofHouseUniqueId)}
                canBeEmpty
                control={control}
                errors={errors}
                disabled={checkDisabled('proofHouseUniqueId')}
                setValue={setValue}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormSelect
                options={getStateOptions()}
                name="stateUniqueId"
                label={t(ESearchFormLabels.stateUniqueId)}
                canBeEmpty
                control={control}
                errors={errors}
                disabled={checkDisabled('stateUniqueId')}
                setValue={setValue}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormSelect
                options={mapDictionaryToOptionProp(legality)}
                name="legalityUniqueId"
                label={t(ESearchFormLabels.legalityUniqueId)}
                canBeEmpty
                control={control}
                errors={errors}
                disabled={checkDisabled('legalityUniqueId')}
                setValue={setValue}
              />
            </Grid>

            {customFields.map((customField) => (
              <FormCustomField
                data={customField}
                setValue={setValue}
                control={control}
                errors={errors}
                key={customField.uniqueId}
                wrapper={customFieldWrapper}
                isSearch={true}
              />
            ))}

            {new Array(4 - customFields.length >= 0 ? 4 - customFields.length : 0)
              .fill('')
              .map((index) => (
                <Grid item lg={2} md={5} sm={5} xs={10} key={index} />
              ))}

            {hasPermissions([EPermission.VIEW_LE]) && (
              <Grid item xs={12} container columns={10} columnSpacing={2}>
                <Grid item xs={10} sm={5} lg={7}>
                  <StyledFormSelect
                    name="ownerUniqueId"
                    label={t(ESearchFormLabels.owner)}
                    control={control}
                    errors={errors}
                    options={ownerOptions}
                    disabled={checkDisabled('ownerUniqueId')}
                    setValue={setValue}
                  />
                </Grid>
                <Grid item xs={10} sm={5} lg={3}>
                  <Button
                    variant={EButtonVariants.outlined}
                    size={EButtonSizes.small}
                    label={t('ancillaries.accordion.selectLegalEntity.button')}
                    fullWidth
                    sx={{
                      mt: {
                        sm: 3.5,
                      },
                    }}
                    onClick={() =>
                      navigate(ERouteLinks.SelectLegalEntity, {
                        state: {
                          legalityUniqueId: routerState?.legalityUniqueId,
                          type: ESelectLE.owner,
                          form: getValues(),
                          isFromMap: isFromMap || routerState?.isFromMap,
                          module: EArtAncillaryTypeNameLowerCase.COMPONENTS,
                          options: { keeperOptions, ownerOptions },
                          returnPath,
                          context: 'searchAncillaries',
                          ...(addAncillaryToGroupContext
                            ? {
                                ancillaryOption: EArtAncillaryType.COMPONENTS,
                                selectedAncillaries,
                              }
                            : undefined),
                          componentsUniqueIds,
                          breadcrumbs: [
                            {
                              label: t('module.ancillaries.name'),
                              route: ERouteLinks.Ancillaries,
                            },
                            t('ancillaries.search.selectLegal.label'),
                          ],
                        },
                      })
                    }
                  />
                </Grid>
              </Grid>
            )}
            {!addAncillaryToGroupContext && hasPermissions([EPermission.VIEW_LE]) && (
              <Grid item xs={12} container columns={10} columnSpacing={2}>
                <Grid item xs={10} sm={5} lg={7}>
                  <StyledFormSelect
                    name="keeperUniqueId"
                    label={t(ESearchFormLabels.keeper)}
                    control={control}
                    errors={errors}
                    options={keeperOptions}
                    disabled={true}
                    setValue={setValue}
                  />
                </Grid>
                <Grid item xs={10} sm={5} lg={3}>
                  <Button
                    variant={EButtonVariants.outlined}
                    size={EButtonSizes.small}
                    label={t('ancillaries.accordion.selectLegalEntity.button')}
                    fullWidth
                    sx={{
                      mt: {
                        sm: 3.5,
                      },
                    }}
                    onClick={() =>
                      navigate(ERouteLinks.SelectLegalEntity, {
                        state: {
                          type: ESelectLE.keeper,
                          form: getValues(),
                          isFromMap: isFromMap || routerState?.isFromMap,
                          module: EArtAncillaryTypeNameLowerCase.COMPONENTS,
                          options: { keeperOptions, ownerOptions },
                          returnPath,
                          context: 'searchAncillaries',
                          componentsUniqueIds,
                          breadcrumbs: [
                            {
                              label: t('module.ancillaries.name'),
                              route: ERouteLinks.Ancillaries,
                            },
                            t('ancillaries.search.selectLegal.label'),
                          ],
                        },
                      })
                    }
                  />
                </Grid>
              </Grid>
            )}
            <Grid item xs={12}>
              <StyledSubtitle item xs={3}>
                <Typography variant="subtitle2">
                  {t('ancillaries.accordion.firearmFilters.label')}
                </Typography>
              </StyledSubtitle>
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormSelect
                options={mapSpecificDictionaryToOptionProp(getFirearmTypesForComponent())}
                name="frtType"
                label={t(ESearchFormLabels.frtType)}
                canBeEmpty
                control={control}
                errors={errors}
                disabled={checkDisabled('frtType')}
                setValue={setValue}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormAutocomplete
                name="frtMake"
                options={mapSpecificDictionaryToOptionProp(make)}
                label={t(ESearchFormFirearmLabels.make)}
                control={control}
                disabled={checkDisabled('frtMake')}
                errors={errors}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormAutocomplete
                name="frtModel"
                options={mapSpecificDictionaryToOptionProp(model)}
                label={t(ESearchFormFirearmLabels.model)}
                control={control}
                disabled={checkDisabled('frtModel')}
                errors={errors}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormAutocomplete
                name="frtManufacturer"
                options={mapSpecificDictionaryToOptionProp(manufacturer)}
                label={t(ESearchFormFirearmLabels.manufacturer)}
                control={control}
                disabled={checkDisabled('frtManufacturer')}
                errors={errors}
              />
            </Grid>

            <Grid
              item
              xs={12}
              container
              columns={10}
              columnSpacing={2}
              sx={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Grid item xs={10} sm={5} lg={2}>
                <Button
                  variant={EButtonVariants.contained}
                  size={EButtonSizes.small}
                  disabled={isSearchButtonDisabled}
                  label={t('ancillaries.accordion.search.button')}
                  type="submit"
                  id="ancillaries_components_search-button"
                  fullWidth
                  sx={{
                    mt: {
                      sm: 3.5,
                    },
                  }}
                />
              </Grid>
            </Grid>
          </StyledAdvancedSearch>
        )}
      </form>
      {showAdvanced && (
        <SelectedFilters
          name="components"
          values={values}
          excludes={['preset', 'uniqueIds', 'ownerName', 'keeperName']}
          noRemovable={['uniqueIds']}
          labels={ESearchFormLabels}
          additionalSaveFilterValues={{
            owner: ownerOptions[0],
            keeper: keeperOptions[0],
            componentsUniqueIds,
          }}
          handleDelete={handleResetField}
          type={ESearchFilterKeys.component}
          updatedQueryPath={
            getNewQueryPath ? getNewQueryPath(prepareData(getValues())) : undefined
          }
          saveRoute={ERouteLinks.SaveAncillariesPreset.replace(
            ':type',
            ESearchFilterKeys.component,
          )}
          additionalPath={
            isFromMap || (routerState && routerState.isFromMap) ? '?fromMap=true' : ''
          }
          showSaveFilters={addAncillaryToGroupContext ? false : showSaveFilter}
          dictionaries={{
            ancillaryTypeUniqueId: prepareFilters(artComponentType, 'uniqueId', 'name'),
            manufacturerUniqueId: prepareFilters(artComponentManufacturer, 'uniqueId', 'name'),
            calibreUniqueId: prepareFilters(artComponentCalibre, 'uniqueId', 'name'),
            compositionUniqueId: prepareFilters(artComposition, 'uniqueId', 'name'),
            componentCapacityUniqueId: prepareFilters(
              artComponentCapacity,
              'uniqueId',
              'name',
            ),
            proofHouseUniqueId: prepareFilters(artComponentProofHouse, 'uniqueId', 'name'),
            stateUniqueId: prepareFilters(state, 'uniqueId', 'name'),
            legalityUniqueId: prepareFilters(legality, 'uniqueId', 'name'),
            frtMake: prepareFilters(make, 'uniqueId', 'name'),
            frtModel: prepareFilters(model, 'uniqueId', 'name'),
            frtType: prepareFilters(firearmType, 'uniqueId', 'name'),
            frtManufacturer: prepareFilters(manufacturer, 'uniqueId', 'name'),
            ownerUniqueId: prepareFilters(ownerOptions, 'value', 'label'),
            keeperUniqueId: prepareFilters(keeperOptions, 'value', 'label'),
          }}
          dictionariesToSave={['ownerUniqueId', 'keeperUniqueId']}
          customFields={customFields}
          saveRouterStateAfterClearAll={addAncillaryToGroupContext}
          saveQueryParams={addAncillaryToGroupContext ? ['type'] : undefined}
        />
      )}
    </SearchWrapper>
  );
};

export { ComponentsSearchForm };
