import { EPermission, EPermissionIds, IPermission } from 'models/permissions';

export const permissionsWithTooltip = [
  EPermission.VIEW_FIREARMS_LE,
  EPermission.VIEW_ANCILLARIES_LE,
  EPermission.MANAGE_FRT_FIREARM,
  EPermission.VIEW_FIREARMS,
  EPermission.VIEW_TRANSACTIONS,
];

const sortedPermissions = [
  EPermission.VIEW_LE,
  EPermission.VIEW_ALL_LE,
  EPermission.EXPORT_LE,
  EPermission.MANAGE_LE,
  EPermission.MANAGE_COMMENTS_LE,
  EPermission.VIEW_FIREARMS_LE,
  EPermission.VIEW_ANCILLARIES_LE,
  EPermission.MANAGE_GROUPS_LE,
  EPermission.DELETE_LE,

  EPermission.VIEW_FIREARMS,
  EPermission.VIEW_ALL_FIREARMS,
  EPermission.EXPORT_FIREARMS,
  EPermission.MODIFY_FIREARM,
  EPermission.MANAGE_COMMENTS_FIREARMS,
  EPermission.MANAGE_ATTACHMENTS_FIREARMS,
  EPermission.DISABLE_FIREARM,
  EPermission.MANAGE_FRT_FIREARM,

  EPermission.VIEW_LIMITED_ANCILLARIES,
  EPermission.VIEW_ALL_ANCILLARIES,
  EPermission.EXPORT_ANCILLARIES,
  EPermission.EDIT_ANCILLARY,
  EPermission.MANAGE_COMMENTS_ANCILLARY,
  EPermission.MANAGE_ATTACHMENT_ANCILLARY,
  EPermission.DISABLE_ANCILLARY,
  EPermission.MANAGE_LOCAL_ART,

  EPermission.VIEW_TRANSACTIONS,
  EPermission.VIEW_ALL_TRANSACTIONS,
  EPermission.ADD_TRANSACTION,
  EPermission.MANAGE_TRANSACTION_COMMENTS,
  EPermission.DISCARD_TRANSACTION,
];

export const getDependencePermission = (permisionId: string) => {
  switch (permisionId) {
    /** Legal Entity Module  */
    case EPermissionIds.VIEW_LE:
      return [];
    case EPermissionIds.VIEW_ALL_LE:
      return [EPermissionIds.VIEW_LE];
    case EPermissionIds.EXPORT_LE:
      return [EPermissionIds.VIEW_LE, EPermissionIds.VIEW_ALL_LE];
    case EPermissionIds.MANAGE_LE:
      return [EPermissionIds.VIEW_LE, EPermissionIds.VIEW_ALL_LE];
    case EPermissionIds.MANAGE_COMMENTS_LE:
      return [
        [EPermissionIds.VIEW_LE, EPermissionIds.MANAGE_LE],
        [EPermissionIds.VIEW_ALL_LE, EPermissionIds.MANAGE_LE],
      ];
    case EPermissionIds.VIEW_FIREARMS_LE:
      return [EPermissionIds.VIEW_LE, EPermissionIds.VIEW_ALL_LE];
    case EPermissionIds.VIEW_ANCILLARIES_LE:
      return [EPermissionIds.VIEW_LE, EPermissionIds.VIEW_ALL_LE];
    case EPermissionIds.MANAGE_GROUPS_LE:
      return [
        /** Add / Edit Firearm group */
        [EPermissionIds.VIEW_LE, EPermissionIds.VIEW_FIREARMS_LE],
        [EPermissionIds.VIEW_ALL_LE, EPermissionIds.VIEW_FIREARMS_LE],
        [EPermissionIds.VIEW_LE, EPermissionIds.VIEW_FIREARMS],
        [EPermissionIds.VIEW_ALL_LE, EPermissionIds.VIEW_FIREARMS],
        [EPermissionIds.VIEW_LE, EPermissionIds.VIEW_ALL_FIREARMS],
        [EPermissionIds.VIEW_ALL_LE, EPermissionIds.VIEW_ALL_FIREARMS],
        /** Add / Edit Ancillaries group */
        [EPermissionIds.VIEW_LE, EPermissionIds.VIEW_ANCILLARIES_LE],
        [EPermissionIds.VIEW_ALL_LE, EPermissionIds.VIEW_ANCILLARIES_LE],
        [EPermissionIds.VIEW_LE, EPermissionIds.VIEW_LIMITED_ANCILLARIES],
        [EPermissionIds.VIEW_ALL_LE, EPermissionIds.VIEW_LIMITED_ANCILLARIES],
        [EPermissionIds.VIEW_LE, EPermissionIds.VIEW_ALL_ANCILLARIES],
        [EPermissionIds.VIEW_ALL_LE, EPermissionIds.VIEW_ALL_ANCILLARIES],
      ];
    case EPermissionIds.DELETE_LE:
      return [
        [EPermissionIds.VIEW_LE, EPermissionIds.MANAGE_LE],
        [EPermissionIds.VIEW_ALL_LE, EPermissionIds.MANAGE_LE],
      ];
    /** Firearm Module  */
    case EPermissionIds.VIEW_FIREARMS:
      return [];
    case EPermissionIds.VIEW_ALL_FIREARMS:
      return [EPermissionIds.VIEW_FIREARMS];
    case EPermissionIds.EXPORT_FIREARMS:
      return [EPermissionIds.VIEW_FIREARMS, EPermissionIds.VIEW_ALL_FIREARMS];
    case EPermissionIds.MODIFY_FIREARM:
      return [EPermissionIds.VIEW_FIREARMS, EPermissionIds.VIEW_ALL_FIREARMS];
    case EPermissionIds.MANAGE_COMMENTS_FIREARMS:
      return [EPermissionIds.VIEW_FIREARMS, EPermissionIds.VIEW_ALL_FIREARMS];
    case EPermissionIds.MANAGE_ATTACHMENTS_FIREARMS:
      return [EPermissionIds.VIEW_FIREARMS, EPermissionIds.VIEW_ALL_FIREARMS];
    case EPermissionIds.DISABLE_FIREARM:
      return [EPermissionIds.VIEW_FIREARMS, EPermissionIds.VIEW_ALL_FIREARMS];
    case EPermissionIds.MANAGE_FRT_FIREARM:
      return [];
    /** Ancillaries Module  */
    case EPermissionIds.VIEW_LIMITED_ANCILLARIES:
      return [];
    case EPermissionIds.VIEW_ALL_ANCILLARIES:
      return [EPermissionIds.VIEW_LIMITED_ANCILLARIES];
    case EPermissionIds.EXPORT_ANCILLARIES:
      return [EPermissionIds.VIEW_LIMITED_ANCILLARIES, EPermissionIds.VIEW_ALL_ANCILLARIES];
    case EPermissionIds.EDIT_ANCILLARY:
      return [EPermissionIds.VIEW_LIMITED_ANCILLARIES, EPermissionIds.VIEW_ALL_ANCILLARIES];
    case EPermissionIds.MANAGE_COMMENTS_ANCILLARY:
      return [EPermissionIds.VIEW_LIMITED_ANCILLARIES, EPermissionIds.VIEW_ALL_ANCILLARIES];
    case EPermissionIds.MANAGE_ATTACHMENT_ANCILLARY:
      return [EPermissionIds.VIEW_LIMITED_ANCILLARIES, EPermissionIds.VIEW_ALL_ANCILLARIES];
    case EPermissionIds.DISABLE_ANCILLARY:
      return [EPermissionIds.VIEW_LIMITED_ANCILLARIES, EPermissionIds.VIEW_ALL_ANCILLARIES];
    case EPermissionIds.MANAGE_LOCAL_ART:
      return [];
    /** Transaction Module  */
    case EPermissionIds.VIEW_TRANSACTIONS:
      return [
        [EPermissionIds.VIEW_LE, EPermissionIds.VIEW_FIREARMS],
        [EPermissionIds.VIEW_LE, EPermissionIds.VIEW_LIMITED_ANCILLARIES],
      ];
    case EPermissionIds.VIEW_ALL_TRANSACTIONS:
      return [
        [EPermissionIds.VIEW_ALL_LE, EPermissionIds.VIEW_ALL_FIREARMS],
        [EPermissionIds.VIEW_ALL_LE, EPermissionIds.VIEW_ALL_ANCILLARIES],
      ];
    case EPermissionIds.ADD_TRANSACTION:
      return [EPermissionIds.VIEW_TRANSACTIONS, EPermissionIds.VIEW_ALL_TRANSACTIONS];
    case EPermissionIds.MANAGE_TRANSACTION_COMMENTS:
      return [EPermissionIds.VIEW_TRANSACTIONS, EPermissionIds.VIEW_ALL_TRANSACTIONS];
    case EPermissionIds.DISCARD_TRANSACTION:
      return [EPermissionIds.VIEW_TRANSACTIONS, EPermissionIds.VIEW_ALL_TRANSACTIONS];
    /** Administration Module  */
    case EPermissionIds.MANAGE_USERS:
      return [];
    case EPermissionIds.MANAGE_ROLES:
      return [];
    case EPermissionIds.MANAGE_CUSTOM_FIELDS:
      return [];
    case EPermissionIds.MANAGE_SETTINGS:
      return [];
    case EPermissionIds.VIEW_AUDIT_LOG:
      return [
        [
          EPermissionIds.VIEW_ALL_LE,
          EPermissionIds.VIEW_ALL_FIREARMS,
          EPermissionIds.VIEW_ALL_ANCILLARIES,
          EPermissionIds.VIEW_ALL_TRANSACTIONS,
          EPermissionIds.MANAGE_USERS,
          EPermissionIds.MANAGE_ROLES,
          EPermissionIds.MANAGE_CUSTOM_FIELDS,
          EPermissionIds.MANAGE_DICTIONARIES,
          EPermissionIds.MANAGE_API_KEYS,
        ],
      ];
    case EPermissionIds.EXPORT_AUDIT_LOG:
      return [EPermissionIds.VIEW_AUDIT_LOG];
    case EPermissionIds.MANAGE_DICTIONARIES:
      return [];
    case EPermissionIds.VIEW_AND_MANAGE_LICENCE:
      return [];
    case EPermissionIds.MANAGE_API_KEYS:
      return [];
    /** Dashboard Module  */
    case EPermissionIds.VIEW_DASHBOARD_CHARTS:
      return [
        [
          EPermissionIds.VIEW_ALL_LE,
          EPermissionIds.VIEW_ALL_FIREARMS,
          EPermissionIds.VIEW_ALL_ANCILLARIES,
          EPermissionIds.VIEW_ALL_TRANSACTIONS,
        ],
      ];
    default:
      return [];
  }
};

export const prepareRoleList = (permissions: IPermission[]) =>
  permissions.sort(
    (a, b) =>
      Object.values(sortedPermissions).indexOf(a.authority as EPermission) -
      Object.values(sortedPermissions).indexOf(b.authority as EPermission),
  );

export const roleIsDisabled = (permissionId: string, formPermissionIds: string[]) => {
  const dependence = getDependencePermission(permissionId);

  if (dependence && dependence.length) {
    let isDisabled = false;

    if (dependence.length && typeof dependence[0] === 'string') {
      isDisabled = !dependence.some((permission: any) =>
        formPermissionIds.includes(permission),
      );
    } else if (dependence.length && typeof dependence[0] === 'object') {
      isDisabled = true;
      dependence.forEach((permissionArr: any) => {
        if (permissionArr.length) {
          if (
            permissionArr.every((permission: EPermission) =>
              formPermissionIds.includes(permission),
            )
          ) {
            isDisabled = false;
          }
        }
      });
    }

    return isDisabled;
  }

  return false;
};
