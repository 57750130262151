import React, { useEffect, useState } from 'react';
import { ERouteLinks } from 'models/route';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { useParams } from 'react-router-dom';
import { StyledPageTitle } from 'theme/styles';
import Grid from '@mui/material/Grid';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { getLegalEntity, getOrgLegalEntity } from 'requests/legalEntities';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { ILegalEntityData, LegalEntityDetails } from 'components/organisms/LegalEntityDetails';
import { EDetailsPageTypes } from 'components/templates/DetailsTemplate';
import { useQuery } from 'hooks/useQuery';
import { ELegalEntityTypes } from 'constants/LegalEntityTypes';
import {
  IOrgLegalEntityData,
  OrgLegalEntityDetails,
} from 'components/organisms/OrgLegalEntityDetails';
import { useTranslations } from 'hooks/useTranslations';
import { EDictionaryTypes } from 'models/dictionary';
import { useLang } from 'models/langContext';

const DeleteLegalEntityPage = () => {
  const { id } = useParams<{ id: string }>();
  const query = useQuery();
  const [legalEntity, setLegalEntity] = useState<ILegalEntityData | null>();
  const [orgLegalEntity, setOrgLegalEntity] = useState<IOrgLegalEntityData | null>();
  const { handleDelete, handleOrgLEDelete } = useLegalEntities();
  const { loadDictionaries, navigateToList } = useLegalEntities();
  const { t } = useTranslations();
  const { selectedLanguage } = useLang();

  const [deleteFailure, setDeleteFailure] = useState<string>('');
  const [orgDeleteFailure, setOrgDeleteFailure] = useState<string>('');
  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);

  const type: string = query.get('type') || '';

  useEffect(() => {
    (async function init() {
      await loadDictionaries([EDictionaryTypes.Gender, EDictionaryTypes.LegalVisibility]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  useEffect(() => {
    if (type === ELegalEntityTypes.individual) {
      (async function getLegalEntityDataInit() {
        if (id) {
          const response = await getLegalEntity(id);
          if (response) {
            setLegalEntity(response);
          }
        }
      })();
    } else if (type === ELegalEntityTypes.organisation) {
      (async function getLegalEntityDataInit() {
        if (id) {
          const response = await getOrgLegalEntity(id);
          if (response) {
            setOrgLegalEntity(response);
          }
        }
      })();
    }
  }, [id]);

  const onDelete = async () => {
    setIsDeleteLoading(true);
    if (type === ELegalEntityTypes.individual) {
      try {
        if (legalEntity) {
          const result = await handleDelete(legalEntity?.uniqueId);
          if (result) {
            navigateToList();
            setIsDeleteLoading(false);
          }
        }
      } catch (e: any) {
        setIsDeleteLoading(false);
        setDeleteFailure(e?.response?.data?.message ? e?.response?.data?.message : e.message);
      }
    } else if (type === ELegalEntityTypes.organisation) {
      try {
        if (orgLegalEntity) {
          const result = await handleOrgLEDelete(orgLegalEntity?.uniqueId);
          setIsDeleteLoading(false);
          if (result) {
            navigateToList();
            setIsDeleteLoading(false);
          }
        }
      } catch (e: any) {
        setIsDeleteLoading(false);
        setOrgDeleteFailure(
          e?.response?.data?.message ? e?.response?.data?.message : e.message,
        );
      }
    }
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.legalEntity.name'), route: ERouteLinks.LegalEntities },
          {
            label:
              type === ELegalEntityTypes.individual
                ? t('legalEntities.individual.accordion.label')
                : t('legalEntities.organization.accordion.label'),
            route: `${ERouteLinks.LegalEntities}?expand=${
              type === ELegalEntityTypes.individual ? 'individual' : 'organisation'
            }`,
          },
          t('deleteLegalEntity.header'),
        ]}
      />
      {(legalEntity || orgLegalEntity) && (
        <>
          <StyledPageTitle variant="h4">{t('deleteLegalEntity.header')}</StyledPageTitle>

          {legalEntity && type === ELegalEntityTypes.individual && (
            <>
              <Grid item xs={12} style={{ margin: '10px 0 10px' }}>
                <Alert
                  text={t(
                    legalEntity.hasAssignedUser
                      ? 'deleteLegalEntity.info.text'
                      : 'deleteNotAssignedLegalEntity.info.text',
                  )}
                  variant={EAlertVariants.warning}
                />
              </Grid>
              <LegalEntityDetails
                legalEntity={legalEntity}
                pageType={EDetailsPageTypes.delete}
              />
            </>
          )}
          {orgLegalEntity && type === ELegalEntityTypes.organisation && (
            <>
              <Grid item xs={12} style={{ margin: '10px 0 10px' }}>
                <Alert
                  text={t(
                    orgLegalEntity.hasAssignedUser
                      ? 'deleteLegalEntity.info.text'
                      : 'deleteNotAssignedLegalEntity.info.text',
                  )}
                  variant={EAlertVariants.warning}
                />
              </Grid>
              <OrgLegalEntityDetails
                legalEntity={orgLegalEntity}
                pageType={EDetailsPageTypes.delete}
              />
            </>
          )}
          {!deleteFailure && !orgDeleteFailure ? (
            <Grid container sx={{ mt: 2 }} spacing={2}>
              <Grid item xs={12} sm={6} lg={3}>
                <Button
                  fullWidth
                  id="button-yes"
                  label={t('general.yes.button')}
                  variant={EButtonVariants.contained}
                  sx={{ mr: 1 }}
                  onClick={async () => onDelete()}
                  isLoading={isDeleteLoading}
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={3}>
                <Button
                  fullWidth
                  id="button-no"
                  label={t('general.no.button')}
                  variant={EButtonVariants.outlined}
                  onClick={navigateToList}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              <Grid item xs={12} mt={3} mb={5}>
                {type === ELegalEntityTypes.individual && (
                  <Alert text={deleteFailure} variant={EAlertVariants.error} />
                )}

                {type === ELegalEntityTypes.organisation && (
                  <Alert text={orgDeleteFailure} variant={EAlertVariants.error} />
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Button
                  fullWidth
                  id="button-back"
                  label="Back to legal entity"
                  variant={EButtonVariants.outlined}
                  onClick={navigateToList}
                />
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export { DeleteLegalEntityPage };
