import React from 'react';
import { ITableDataSource } from 'models/table';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { useTranslations } from 'hooks/useTranslations';
import { emptyFieldHolder } from 'components/molecules/CardList';
import { ERouteLinks } from 'models/route';
import { EPermission } from 'models/permissions';

interface ILegalEntityGroupAncillaryTableCard {
  data: ITableDataSource;
  id: string;
  type: string | null;
  groupId: string;
  viewAction?: (ancillaryId: string | any, ancillaryType: string | any) => void;
}

const LegalEntityGroupAncillaryTableCard = ({
  data,
  id,
  groupId,
  type,
  viewAction,
}: ILegalEntityGroupAncillaryTableCard) => {
  const isWarning = data.state?.toLowerCase().includes('stolen');
  const { t } = useTranslations();
  return (
    <Grid
      container
      sx={{
        border: `1px solid ${isWarning ? colors.error100 : colors.gray200}`,
        borderRadius: '4px',
        overflow: 'hidden',
        mt: 1,
        backgroundColor: isWarning ? colors.error15 : colors.gray100,
      }}
    >
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('ancillaries.list.fireCycleId.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.fireCycleId)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('ancillaries.list.serialNumber.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.serialNumber)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('ancillaries.list.lotNumber.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.lotNumber)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('ancillaries.list.batchNumber.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.batchNumber)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('ancillaries.list.ancillary.column')}
        </Typography>
        <Typography variant="body2">
          {emptyFieldHolder(data.ancillaryTypeDictionaryValue)}
        </Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('ancillaries.list.ancillaryType.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.ancillarySpecificType)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('ancillaries.list.manufacturer.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.manufacturer)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('ancillaries.list.calibre.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.calibre)}</Typography>
      </Grid>

      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTop: {
            xs: `1px solid ${colors.primary25}`,
            lg: 'none',
          },
          m: {
            xs: 2,
            lg: 2,
          },
        }}
        spacing={2}
      >
        <Grid item xs={12} sm={3} sx={{ mb: { xs: 2, sm: 0 } }}>
          <Typography variant="button" sx={{ textTransform: 'uppercase' }}>
            {t('groupDetails.ancillaryListSection.action.column')}:{' '}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} container columns={12} spacing={1}>
          <Grid item xs={12}>
            <Button
              label={t('ancillaries.ancillaryList.label')}
              variant={EButtonVariants.outlined}
              onClick={() => viewAction && viewAction(data?.id, data?.ancillaryType)}
              size="small"
              fullWidth
              permission={[EPermission.VIEW_LIMITED_ANCILLARIES]}
            />
          </Grid>
          <Grid item xs={12}>
            <Link
              to={`${ERouteLinks.LegalEntityGroupDeleteAncillary.replace(':id', id)
                .replace(':groupId', groupId)
                .replace(':ancillaryType', data.ancillaryType.toLowerCase())
                .replace(':ancillaryId', data?.id as string)}?type=${type}&expand=groups`}
            >
              <Button
                label={t('ancillaries.ancillaryDelete.label')}
                variant={EButtonVariants.outlined}
                size="small"
                fullWidth
              />
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export { LegalEntityGroupAncillaryTableCard };
