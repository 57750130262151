import React, { useEffect, useRef, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { StyledPageTitle } from 'theme/styles';
import { ERouteLinks } from 'models/route';
import { ELegalEntityTypes } from 'constants/LegalEntityTypes';
import { useQuery } from 'hooks/useQuery';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import { generateFirearmListTemplate } from 'requests/firearm';
import { FormSelect } from 'components/molecules/FormSelect';
import { mapDictionaryToOptionProp } from 'helpers/dictionary';
import { useFieldArray, useForm } from 'react-hook-form';
import { FormDatePicker } from 'components/molecules/FormDatePicker';
import { Accordion } from 'components/molecules/Accordion';
import { EButtonSizes, EButtonSizesPX, EButtonVariants } from 'constants/Buttons';
import Button from 'components/atoms/Button';
import { Typography } from '@mui/material';
import { StyledDetailsWrapper } from 'pages/Firearms/RegisteredFirearmDeleteCommentPage/styles';
import { getLegalEntity, getOrgLegalEntity } from 'requests/legalEntities';
import { ILegalEntityData } from 'components/organisms/LegalEntityDetails';
import { Loader } from 'components/atoms/Loader';
import { IOrgLegalEntityData } from 'components/organisms/OrgLegalEntityDetails';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import { colors } from 'theme/colors';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { EIconTypes } from 'constants/Icons';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { FormRadioGroup } from 'components/molecules/FormRadioGroup';
import { FormInput } from 'components/molecules/FormInput';
import {
  StyledFirearmListBox,
  StyledIconWrapper,
  StyledLocationMarkConfirmationMarginBottom,
} from 'pages/LegalEntitiesPage/LegalEntityAddFirearmPage/styles';
import Icon from 'components/atoms/Icon/Icon';
import { AddFirearmListItem } from 'components/organisms/AddFirearmListItem';
import { yupResolver } from '@hookform/resolvers/yup';
import Link from '@mui/material/Link';
import { useDictionary } from 'models/dictionaryContext';
import {
  EDictionaryTypes,
  ETransactionDictionaryItemTypes,
  IDictionary,
} from 'models/dictionary';
import FormErrorLabel from 'components/atoms/FormErrorLabel';
import { EMIMETypes } from 'constants/MIMETypes';
import { createInitialTransaction } from 'requests/transaction';
import { ETransactionTypes } from 'constants/TransactionTypes';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import { useTranslations } from 'hooks/useTranslations';
import { ImportFirearms } from 'components/organisms/ImportFirearms';
import { useLang } from 'models/langContext';
import {
  ECustomFieldObjectType,
  ECustomFieldType,
  ICustomFieldByObjectType,
} from 'models/customField';
import FormCustomField from 'components/organisms/FormCustomField';
import { useAddFirearmFormSchema } from 'pages/LegalEntitiesPage/LegalEntityAddFirearmPage/schemaValidation';
import { ICustomFieldForm } from 'pages/LegalEntitiesPage/IndividualSearchForm';
import { isFRTLocal } from 'pages/FRT/helpers';
import { convertSimpleDate, convertToSimpleDate, formatDateTimeToIso } from 'utils/date';
import { FormDateTimePicker } from 'components/molecules/FormDateTimePicker';
import { FormCheckbox } from 'components/molecules/FormCheckbox';
import { useScroll } from 'hooks/useScroll';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { removeFileExtension } from 'utils/removeFileExtension';
import { EPermission } from 'models/permissions';
import { usePermission } from 'hooks/usePermission';
import { StyledLegalLink } from 'components/atoms/StyledLegalLink';

const MAX_FILE_SIZE_IN_MB = 3;

export const TRANSACTION_INITIAL_TYPES_FOR_MANUFACTURED_DATE_FIELD = [
  ETransactionTypes.DECLARED_MANUFACTURED,
  ETransactionTypes.DECLARED_IMPORTED,
];

export const TRANSACTION_INITIAL_TYPES_FOR_MANUFACTURED_DATE_FIELD_NOT_MANDATORY = [
  ETransactionTypes.DECLARED_IMPORTED,
];

const TRANSACTION_INITIAL_TYPES_ONLY_FOR_ORG_IDS = [
  ETransactionTypes.SURRENDERED,
  ETransactionTypes.SEIZED,
  ETransactionTypes.RECOVERED,
];

export const TRANSACTION_ILLEGAL = [
  ETransactionTypes.SURRENDERED,
  ETransactionTypes.SEIZED,
  ETransactionTypes.RECOVERED,
];

const individualTransactionTypes = [
  ETransactionTypes.DECLARED_MANUFACTURED,
  ETransactionTypes.DECLARED_UNKNOWN_ORIGIN,
  ETransactionTypes.DECLARED_IMPORTED,
];

const organizationTransactionTypes = [
  ETransactionTypes.DECLARED_MANUFACTURED,
  ETransactionTypes.DECLARED_UNKNOWN_ORIGIN,
  ETransactionTypes.DECLARED_IMPORTED,
  ETransactionTypes.SEIZED,
  ETransactionTypes.RECOVERED,
  ETransactionTypes.SURRENDERED,
];
const isShowTracingDocumentsGeneratedMessage = [
  ETransactionTypes.SEIZED,
  ETransactionTypes.RECOVERED,
  ETransactionTypes.SURRENDERED,
];

const LegalEntityAddFirearmPage = () => {
  const { t } = useTranslations();

  const navigate = useNavigate();
  const { state: routerState }: any = useLocation();
  const { id = '' } = useParams<{ id: string }>();
  const query = useQuery();
  const type: string | null = query.get('type');
  const initialFormValues: Record<string, any> = {
    typeUniqueId: routerState?.typeUniqueId || '',
    validationOff: false,
    transactionDate: routerState?.transactionDate || '',
    firearmsQuantity: routerState?.firearmsQuantity || 'one',
    serialNumber: routerState?.serialNumber || '',
    serialNumberConfirmation: routerState?.serialNumberConfirmation || '',
    externalReferenceNumber: routerState?.externalReferenceNumber || '',
    exhibitNumber: routerState?.exhibitNumber || '',
    isExhibitNumberChecked: routerState?.isExhibitNumberChecked || false,
    manufacturedAt: routerState?.manufacturedAt || null,
    isCreateGroup: routerState?.isCreateGroup || 'no',
    groupName: routerState?.groupName || '',
    countryOfImportUniqueId: routerState?.countryOfImportUniqueId || '',
    importer: routerState?.importer || '',
    firearmsList: routerState?.firearmsList?.length
      ? routerState?.firearmsList
      : [
          {
            serialNumber: '',
            serialNumberConfirmation: '',
            externalReferenceNumber: '',
            exhibitNumber: '',
            isExhibitNumberChecked: false,
            ...(TRANSACTION_INITIAL_TYPES_FOR_MANUFACTURED_DATE_FIELD?.includes(
              routerState?.typeUniqueId,
            )
              ? { manufacturedAt: null }
              : {}),
          },
        ],
    attachments: routerState?.attachments?.length ? routerState?.attachments : [],
  };

  const [legalEntity, setLegalEntity] = useState<ILegalEntityData | null>();
  const [isLegalEntityLoading, setIsLegalEntityLoading] = useState<boolean>(false);
  const [orgLegalEntity, setOrgLegalEntity] = useState<IOrgLegalEntityData | null>();
  const [isOrgLegalEntityLoading, setIsOrgLegalEntityLoading] = useState<boolean>(false);
  const [isErrorLocation, setIsErrorLocation] = useState<boolean>(false);
  const { loadDictionaries, getCustomField, customFieldsFirearms } = useLegalEntities();
  const { isDesktop } = useThemeBreakpoint();
  const { hasPermissions } = usePermission();
  const { getTransactionInitialTypes, transactionInitialTypes } = useDictionary();
  const { setAlert } = useAlert();
  const [selectedFirearm, setSelectedFirearm] = useState<any>(null);
  const [createInitialTransactionError, setCreateInitialTransactionError] =
    useState<string>('');
  const [showImportDialog, setShowImportDialog] = useState<boolean>(false);
  const [isExhibitNumberChecked, setIsExhibitNumberChecked] = useState<boolean>(
    routerState?.isExhibitNumberChecked || false,
  );
  const [isReloadCausedLocationSelect, setIsReloadCausedLocationSelect] = useState(
    routerState?.isReloadCausedLocationSelect || false,
  );
  const [isDataImportedFromFile, setIsDataImportedFromFile] = useState<boolean>(false);
  const { selectedLanguage } = useLang();
  const { country } = useDictionary();
  const { getLegalEntityAddressesData, legalEntityAddress } = useLegalEntities();
  const [submitBlocked, setSubmitBlocked] = useState(false);
  const { elRef, scrollToError } = useScroll();
  const { shortDateFormat } = useGlobalProperty();

  const { schema } = useAddFirearmFormSchema(customFieldsFirearms);
  const {
    control,
    watch,
    formState: { errors },
    handleSubmit,
    setError,
    getValues,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: initialFormValues,
    resolver: yupResolver(schema),
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'firearmsList',
  });
  const attachments = useFieldArray({
    control,
    name: 'attachments',
  });

  const firearmsQuantityValue = watch('firearmsQuantity');
  const transactionTypeValue = watch('typeUniqueId');
  const isCreateGroupValue = watch('isCreateGroup');
  const transactionDate = watch('transactionDate');

  const isShowFirearmList = firearmsQuantityValue === 'many';
  const isShowLocation =
    transactionTypeValue === ETransactionTypes.SEIZED ||
    transactionTypeValue === ETransactionTypes.RECOVERED;
  const isShowCountry = transactionTypeValue === ETransactionTypes.DECLARED_IMPORTED;
  const isShowGroupNameFormElement = isCreateGroupValue === 'yes';
  const isShowManufacturedDate =
    TRANSACTION_INITIAL_TYPES_FOR_MANUFACTURED_DATE_FIELD.includes(transactionTypeValue);
  const isExhibitNumber =
    TRANSACTION_INITIAL_TYPES_ONLY_FOR_ORG_IDS.includes(transactionTypeValue);
  const isTransactionIllegal = TRANSACTION_ILLEGAL.includes(transactionTypeValue);

  const defaultAddress = legalEntityAddress.filter((address) => address.isDefault).shift();

  const firearmsQuantityOptions = [
    {
      value: 'one',
      label: t('addFirearm.firearmDetails.section.one.radio'),
    },
    {
      value: 'many',
      label: t('addFirearm.firearmDetails.section.many.radio'),
    },
  ];

  const createGroupOptions = [
    {
      value: 'yes',
      label: t('general.yes.label'),
    },
    {
      value: 'no',
      label: t('general.no.label'),
    },
  ];

  useEffect(() => {
    customFieldsFirearms.forEach((customField) => {
      if (routerState && routerState[customField.name]) {
        const value = routerState[customField.name] || '';
        setValue(customField.name, value);
      }
    });
  }, [customFieldsFirearms]);

  useEffect(() => {
    (async () => {
      scrollToError(errors);
    })();
  }, [errors]);

  useEffect(() => {
    (async function initDictionaries() {
      await loadDictionaries([EDictionaryTypes.CountryAll]);
      await getCustomField(ECustomFieldObjectType.FIREARM);
      getTransactionInitialTypes(ETransactionDictionaryItemTypes.FIREARM);
    })();
  }, [selectedLanguage]);

  useEffect(() => {
    if (routerState && routerState.isExhibitNumberChecked && !isExhibitNumberChecked) {
      setIsExhibitNumberChecked(true);
    }
  }, []);

  useEffect(() => {
    if (type === ELegalEntityTypes.individual) {
      (async function getLegalEntityDataInit() {
        setIsLegalEntityLoading(true);
        if (id) {
          const response = await getLegalEntity(id);
          if (response) {
            setLegalEntity(response);
          }
          setIsLegalEntityLoading(false);
        }
      })();
    } else if (type === ELegalEntityTypes.organisation) {
      (async function getLegalEntityDataInit() {
        setIsOrgLegalEntityLoading(true);
        if (id) {
          const response = await getOrgLegalEntity(id);
          if (response) {
            setOrgLegalEntity(response);
          }
          setIsOrgLegalEntityLoading(false);
        }
      })();
    }
    getLegalEntityAddressesData(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (routerState?.firearm) {
      setSelectedFirearm(routerState.firearm);
    }
  }, [routerState]);

  useEffect(() => {
    if (transactionTypeValue === ETransactionTypes.DECLARED_MANUFACTURED) {
      if (firearmsQuantityValue === 'one') {
        if (transactionDate instanceof Date) {
          const manufacturedAt: Date = new Date(transactionDate);
          manufacturedAt.setHours(0);
          manufacturedAt.setMinutes(0);
          manufacturedAt.setSeconds(0);
          manufacturedAt.setMilliseconds(0);
          setValue('manufacturedAt', manufacturedAt);
        } else {
          setValue('manufacturedAt', transactionDate);
        }
      } else if (firearmsQuantityValue === 'many') {
        if (transactionDate instanceof Date) {
          const manufacturedAt: Date = new Date(transactionDate);
          manufacturedAt.setHours(0);
          manufacturedAt.setMinutes(0);
          manufacturedAt.setSeconds(0);
          manufacturedAt.setMilliseconds(0);
          fields.forEach((_, index) => {
            setValue(`firearmsList[${index}].manufacturedAt`, manufacturedAt);
          });
        }
        fields.forEach((_, index) => {
          setValue(`firearmsList[${index}].manufacturedAt`, transactionDate);
        });
      }
    }
  }, [
    transactionTypeValue,
    transactionDate,
    firearmsQuantityValue,
    setValue,
    fields,
    isShowFirearmList,
  ]);

  const filteredTransactionInitialTypes = useRef<IDictionary[] | null>([]);
  const breadcrumbs = useRef([
    { label: t('addFirearm.legalEntities.breadcrumb'), route: ERouteLinks.LegalEntities },
    {
      label:
        type === ELegalEntityTypes.individual
          ? t('addFirearm.individual.breadcrumb')
          : t('addFirearm.organisation.breadcrumb'),
      route: `${ERouteLinks.LegalEntities}?expand=${
        type === ELegalEntityTypes.individual ? 'individual' : 'organisation'
      }`,
    },
    {
      label: t('addFirearm.legalEntityDetails.breadcrumb'),
      route: `${ERouteLinks.ViewLegalEntity.replace(':id', id)}?type=${type}&expand=firearms`,
    },
    t('addFirearm.addFirearm.breadcrumb'),
  ]);

  breadcrumbs.current = [
    { label: t('addFirearm.legalEntities.breadcrumb'), route: ERouteLinks.LegalEntities },
    {
      label:
        type === ELegalEntityTypes.individual
          ? t('addFirearm.individual.breadcrumb')
          : t('addFirearm.organisation.breadcrumb'),
      route: `${ERouteLinks.LegalEntities}?expand=${
        type === ELegalEntityTypes.individual ? 'individual' : 'organisation'
      }`,
    },
    {
      label: t('addFirearm.legalEntityDetails.breadcrumb'),
      route: `${ERouteLinks.ViewLegalEntity.replace(':id', id)}?type=${type}&expand=firearms`,
    },
    t('addFirearm.addFirearm.breadcrumb'),
  ];

  useEffect(() => {
    if (transactionInitialTypes) {
      if (type === ELegalEntityTypes.individual) {
        filteredTransactionInitialTypes.current = transactionInitialTypes?.filter(
          ({ uniqueId: id1 }) =>
            !TRANSACTION_INITIAL_TYPES_ONLY_FOR_ORG_IDS.some((id2) => id2 === id1),
        );
      } else if (type === ELegalEntityTypes.organisation) {
        filteredTransactionInitialTypes.current = transactionInitialTypes;
      }
    }
  }, [type, transactionInitialTypes]);

  useEffect(() => {
    if (selectedFirearm && !isReloadCausedLocationSelect) {
      setValue('isExhibitNumberChecked', false);
      setIsExhibitNumberChecked(false);
    }
  }, [selectedFirearm, transactionTypeValue]);

  const clearUserInput = () => {
    setIsReloadCausedLocationSelect(false);

    setValue('serialNumber', '');
    setValue('serialNumberConfirmation', '');
    setValue('exhibitNumber', '');
    setValue('externalReferenceNumber', '');
    setValue('manufacturedAt', '');
    setValue('isExhibitNumberChecked', false);
    setValue('importer', '');
    setValue('countryOfImportUniqueId', '');

    customFieldsFirearms.forEach((customField) => setValue(customField.name, ''));
  };

  const handleCreateInitialTransaction = () => {
    setCreateInitialTransactionError('');
    setValue('validationOff', false);
    handleSubmit(async (data) => {
      const getCustomFieldsData = (firearm: any) => {
        const customFieldsPayload: ICustomFieldForm[] = [];
        Object.keys(firearm).map((key) => {
          const customField = customFieldsFirearms.find((item) => item.name === key);
          if (customField && firearm[key]) {
            const customFieldItem: ICustomFieldForm = {
              customFieldUniqueId: customField.uniqueId,
            };
            if (customField.customFieldType === ECustomFieldType.TEXT) {
              if (firearm[key] === undefined || firearm[key] === null) {
                customFieldItem.text = undefined;
              } else if (firearm[key].trim() === '') {
                customFieldItem.text = undefined;
              } else {
                customFieldItem.text = firearm[key];
              }
            } else if (customField.customFieldType === ECustomFieldType.BOOL) {
              customFieldItem.bool = firearm[key] === '1';
            } else if (customField.customFieldType === ECustomFieldType.DATE) {
              customFieldItem.date = formatDateTimeToIso(firearm[key]);
            }
            customFieldsPayload.push(customFieldItem);
            delete firearm[key];
          }
          return false;
        });

        return customFieldsPayload?.length ? { customFields: customFieldsPayload } : {};
      };

      setIsErrorLocation(false);
      const fullDate = formatDateTimeToIso(data.transactionDate);
      if (selectedFirearm === null) {
        setSubmitBlocked(false);
        return setCreateInitialTransactionError(t('addFirearm.youMustAddFirearm.text'));
      }

      if (isShowLocation && !routerState?.coords) {
        setSubmitBlocked(false);
        return setIsErrorLocation(true);
      }

      const firearms = isShowFirearmList
        ? data.firearmsList.map((firearm: any) => ({
            serialNumber: firearm.isExhibitNumberChecked ? null : firearm.serialNumber,
            exhibitNumber: firearm.exhibitNumber || null,
            frtId: selectedFirearm?.id,
            ...getCustomFieldsData({ ...firearm }),
            ...(isShowManufacturedDate &&
            firearm.manufacturedAt &&
            firearm.manufacturedAt !== ''
              ? {
                  manufacturedAt: formatDateTimeToIso(firearm.manufacturedAt),
                }
              : {}),
            specificationId: selectedFirearm?.specificationId,
            ...(firearm.externalReferenceNumber
              ? { externalReferenceNumber: firearm.externalReferenceNumber }
              : {}),
          }))
        : [];
      const leId =
        type === ELegalEntityTypes.individual
          ? legalEntity?.uniqueId
          : orgLegalEntity?.uniqueId;

      try {
        setSubmitBlocked(true);

        await createInitialTransaction({
          request: {
            typeUniqueId: data.typeUniqueId,
            keeperId: leId,
            ownerId: leId,
            date: fullDate,
            ...(isShowGroupNameFormElement ? { groupName: data.groupName } : {}),
            ...(isShowCountry
              ? {
                  countryOfImportUniqueId: data.countryOfImportUniqueId,
                  importer: data.importer,
                  countryOfExportUniqueId: null,
                }
              : {}),
            firearms: isShowFirearmList
              ? firearms
              : [
                  {
                    serialNumber: !isExhibitNumberChecked ? data.serialNumber : null,
                    exhibitNumber: data.exhibitNumber || null,
                    frtId: selectedFirearm.id,
                    ...getCustomFieldsData({ ...data }),
                    specificationId: selectedFirearm?.specificationId,
                    ...(isShowManufacturedDate &&
                    data.manufacturedAt &&
                    data.manufacturedAt !== ''
                      ? {
                          manufacturedAt: formatDateTimeToIso(data.manufacturedAt),
                        }
                      : {}),
                    ...(data.externalReferenceNumber
                      ? { externalReferenceNumber: data.externalReferenceNumber }
                      : {}),
                  },
                ],
            ...(isShowLocation && routerState?.coords
              ? {
                  latitude: routerState?.coords[0],
                  longitude: routerState?.coords[1],
                  address: routerState?.address,
                  city: routerState?.city,
                  countryOfTransaction: routerState?.country,
                }
              : {
                  latitude: defaultAddress?.latitude,
                  longitude: defaultAddress?.longitude,
                  address:
                    defaultAddress !== null && defaultAddress !== undefined
                      ? `${defaultAddress?.postalCode} ${defaultAddress?.city} ${defaultAddress?.line1} ${defaultAddress?.line2}`
                      : null,
                  city: defaultAddress?.city || '',
                }),
          },
          ...(data.attachments?.filter((attachment: any) => attachment.file !== null)?.length
            ? { attachments: data.attachments }
            : {}),
        }).then(() => {
          if (isShowTracingDocumentsGeneratedMessage.includes(transactionTypeValue)) {
            if (isShowFirearmList && fields.length > 1) {
              setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
                text: t('legalEntityDetails.firearmsAdded.tracingDocumentGenerated.alert'),
              });
            } else {
              setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
                text: t('legalEntityDetails.firearmAdded.tracingDocumentGenerated.alert'),
              });
            }
          } else if (isShowFirearmList && fields.length > 1) {
            setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
              text: t('legalEntityDetails.firearmsAdded.alert'),
            });
          } else {
            setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
              text: t('legalEntityDetails.firearmAdded.alert'),
            });
          }
          navigate(
            `${ERouteLinks.ViewLegalEntity.replace(':id', id)}?type=${type}&expand=firearms`,
          );
        });
      } catch (e: any) {
        setSubmitBlocked(false);
        setCreateInitialTransactionError(e.response.data?.message);
      }
      return true;
    })();
  };

  let frtDetailsTitle = '';
  if (routerState?.firearm) {
    const { make, model, action, manufacturer, calibre } = routerState.firearm;
    const manufacturerName = manufacturer ? `${manufacturer}, ` : '';
    const calibreName = calibre ? `, ${calibre}` : '';
    frtDetailsTitle = `${make}, ${model}, ${manufacturerName} ${action}${calibreName}`;
  }

  const removeFirearmButtonProps = {
    ...(isDesktop ? { icon: EIconTypes.delete } : { label: 'Delete' }),
    ...(isDesktop ? {} : { size: EButtonSizes.small }),
    ...(isDesktop ? {} : { sizePX: EButtonSizesPX.px24 }),
    ...(isDesktop
      ? { variant: EButtonVariants.outlinedIconTertiary }
      : { variant: EButtonVariants.outlined }),
    fullWidth: !isDesktop,
  };

  const selectFirearmLabelButton = routerState?.firearm
    ? t('addFirearm.firearmDetails.section.selectFirearm.button')
    : t('addFirearm.firearmDetails.section.selectFirearmReference.button');

  const validateFile = (file: File, index: number) => {
    const fileType: any = file.type;
    if (file) {
      if (file.size === 0) {
        // @ts-ignore
        setError(`attachments[${index}].file`, {
          type: 'custom',
          message: t('addAttachment.emptyFile.text'),
        });
        return false;
      }
      const fileSizeInMB = file.size / 1024 / 1024;
      if (fileSizeInMB > MAX_FILE_SIZE_IN_MB) {
        // @ts-ignore
        setError(`attachments[${index}].file`, {
          type: 'custom',
          message: t('addAttachment.fileSizeValidation.text'),
        });
        return false;
      }
      if (
        ![
          EMIMETypes.JPG,
          EMIMETypes.JPEG,
          EMIMETypes.PNG,
          EMIMETypes.PDF,
          EMIMETypes.XLSX,
        ].includes(fileType)
      ) {
        // @ts-ignore
        setError(`attachments[${index}].file`, {
          type: 'custom',
          message: t('addAttachment.fileTypeValidation.text'),
        });
        return false;
      }
    }
    clearErrors(`attachments[${index}].file`);
    return true;
  };

  const handleUploadFile = (file: File, index: number) => {
    if (validateFile(file, index)) {
      attachments.update(index, {
        file,
      });
    }
  };

  const handleExhibitNumberChange = () => {
    setIsExhibitNumberChecked((prevState) => !prevState);
    setValue('serialNumber', '');
    setValue('serialNumberConfirmation', '');
  };

  const handleGenerateFirearmListTemplate = async () => {
    const data = getValues();
    const getCustomFieldsData = (firearm: any) => {
      const customFieldsPayload: ICustomFieldForm[] = [];
      Object.keys(firearm).map((key) => {
        const customField = customFieldsFirearms.find((item) => item.name === key);
        if (customField && firearm[key]) {
          const customFieldItem: ICustomFieldForm = {
            customFieldUniqueId: customField.uniqueId,
          };
          if (customField.customFieldType === ECustomFieldType.TEXT) {
            if (firearm[key] === undefined || firearm[key] === null) {
              customFieldItem.text = undefined;
            } else if (firearm[key].trim() === '') {
              customFieldItem.text = undefined;
            } else {
              customFieldItem.text = firearm[key];
            }
          } else if (customField.customFieldType === ECustomFieldType.BOOL) {
            customFieldItem.bool =
              firearm[key] === '1' ? t('general.yes.label') : t('general.no.label');
          } else if (customField.customFieldType === ECustomFieldType.DATE) {
            customFieldItem.date = convertToSimpleDate(firearm[key], shortDateFormat);
          }
          customFieldsPayload.push(customFieldItem);
        }
        return false;
      });

      return customFieldsPayload?.length ? { customFields: customFieldsPayload } : {};
    };

    const body = {
      typeUniqueId: data.typeUniqueId,
      values: data.firearmsList.map((item: any) => ({
        serialNumber: !item.isExhibitNumberChecked ? item.serialNumber : null,
        exhibitNumber: item.exhibitNumber || null,
        ...getCustomFieldsData(item),
        ...(isShowManufacturedDate
          ? {
              manufacturedAt: item.manufacturedAt
                ? convertToSimpleDate(item.manufacturedAt, shortDateFormat)
                : null,
            }
          : {}),
        ...(item.externalReferenceNumber
          ? { externalReferenceNumber: item.externalReferenceNumber }
          : {}),
      })),
    };

    try {
      await generateFirearmListTemplate(body);
    } catch (e: any) {
      console.error(e);
    }
    return true;
  };

  const renderFirearmDetailsActionButtons = (isTemplateFooter: boolean) => (
    <Grid
      container
      sx={{
        display: 'flex',
        justifyContent: isTemplateFooter ? 'flex-end' : 'space-between',
        alignItems: 'center',
        borderTop: {
          xs: `1px solid ${colors.primary25}`,
          lg: 'none',
        },
        pt: {
          xs: isTemplateFooter ? 0 : 2,
          lg: 0,
        },
        mt: {
          xs: isTemplateFooter ? 0 : 2,
          lg: 0,
        },
      }}
    >
      {!isDesktop && !isTemplateFooter && (
        <Grid item xs={12} sm={6} sx={{ mb: { xs: 2, sm: 0 } }}>
          <Typography variant="button" sx={{ textTransform: 'uppercase' }}>
            {`${t('actions.general.label')}: `}
          </Typography>
        </Grid>
      )}
      <Grid
        item
        container
        xs={12}
        sm={6}
        spacing={2}
        sx={{
          display: {
            xs: isTemplateFooter ? 'none' : 'flex',
            lg: isTemplateFooter ? 'flex' : 'none',
          },
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <Grid item xs={isTemplateFooter ? 'auto' : 12} sm={isTemplateFooter ? 'auto' : 6}>
          <Button
            {...removeFirearmButtonProps}
            onClick={() => {
              setSelectedFirearm(null);
              window.history.replaceState({}, document.title);
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );

  const renderRemoveAttachmentButton = (index: number) => (
    <Grid item xs={1} sx={!isDesktop ? { display: 'flex', justifyContent: 'flex-end' } : {}}>
      <Button
        variant={EButtonVariants.outlined}
        icon={EIconTypes.delete}
        onClick={() => attachments.remove(index)}
        sizePX={EButtonSizesPX.px32}
        sx={{ ...(isDesktop ? { marginLeft: '20px' } : {}), marginTop: '28px' }}
      />
    </Grid>
  );

  const mapAvailableTransactionType = (dictionaries: IDictionary[] | null) => {
    if (dictionaries) {
      const transactionTypes = dictionaries.filter((item) => {
        if (item) {
          return type === ELegalEntityTypes.individual
            ? individualTransactionTypes.includes(
                item.uniqueId.toString() as ETransactionTypes,
              )
            : organizationTransactionTypes.includes(
                item.uniqueId.toString() as ETransactionTypes,
              );
        }
        return false;
      });
      return mapDictionaryToOptionProp(transactionTypes);
    }
    return [];
  };

  const importItem = (itemRow: string[], customFields: ICustomFieldByObjectType[]) => {
    const getCustomFields = (index: number, customFieldItemRow: string[]) => {
      const res: any = {};
      customFields.map((field) => {
        if (field.customFieldType === ECustomFieldType.BOOL) {
          if (
            (customFieldItemRow &&
              customFieldItemRow[index] &&
              customFieldItemRow[index].toLowerCase().trim() === 'true') ||
            customFieldItemRow[index].toLowerCase().trim() === 'yes' ||
            customFieldItemRow[index].toLowerCase().trim() ===
              t('general.yes.label').toLowerCase()
          ) {
            res[field.name] = '1';
          } else if (
            (customFieldItemRow &&
              customFieldItemRow[index] &&
              customFieldItemRow[index].toLowerCase().trim() === 'false') ||
            customFieldItemRow[index].toLowerCase().trim() === 'no' ||
            customFieldItemRow[index].toLowerCase().trim() ===
              t('general.no.label').toLowerCase()
          ) {
            res[field.name] = '0';
          } else {
            res[field.name] = undefined;
          }
        } else if (field.customFieldType === ECustomFieldType.DATE) {
          res[field.name] =
            convertSimpleDate(customFieldItemRow?.[index], shortDateFormat) ?? '';
        } else {
          res[field.name] = customFieldItemRow?.[index] ?? '';
        }
        index++;
        return false;
      });
      return res;
    };

    if (
      transactionTypeValue === ETransactionTypes.RECOVERED ||
      transactionTypeValue === ETransactionTypes.SURRENDERED ||
      transactionTypeValue === ETransactionTypes.SEIZED
    ) {
      append({
        serialNumber: itemRow[0] ?? '',
        serialNumberConfirmation: itemRow[0] ?? '',
        exhibitNumber: itemRow[1] ?? '',
        externalReferenceNumber: itemRow[2] ?? '',
        ...getCustomFields(3, itemRow),
      });
    } else if (transactionTypeValue === ETransactionTypes.DECLARED_IMPORTED) {
      append({
        serialNumber: itemRow[0] ?? '',
        serialNumberConfirmation: itemRow[0] ?? '',
        externalReferenceNumber: itemRow[1] ?? '',
        manufacturedAt: convertSimpleDate(itemRow[2], shortDateFormat) ?? '',
        ...getCustomFields(3, itemRow),
      });
    } else {
      append({
        serialNumber: itemRow[0] ?? '',
        serialNumberConfirmation: itemRow[0] ?? '',
        externalReferenceNumber: itemRow[1] ?? '',
        ...getCustomFields(2, itemRow),
      });
    }
  };

  const attachmentNames = watch('attachments');

  useEffect(() => {
    attachments.fields.forEach((attachment: any, index) => {
      if (attachment?.file?.name && !attachmentNames[index]?.attachmentName) {
        setValue(
          `attachments.${index}.attachmentName`,
          removeFileExtension(attachment.file.name),
        );
      }
    });
  }, [attachments.fields, attachmentNames]);

  return (
    <div ref={elRef}>
      <Breadcrumbs items={breadcrumbs.current} />
      <Box sx={{ mb: 2 }}>
        <StyledPageTitle variant="h4">{t('addFirearm.addFirearm.header')}</StyledPageTitle>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={3}>
          <FormSelect
            options={mapAvailableTransactionType(transactionInitialTypes)}
            name="typeUniqueId"
            setValue={setValue}
            label={`${t('addFirearm.transactionType.label')}*`}
            onSelect={clearUserInput}
            control={control}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <FormDateTimePicker
            name="transactionDate"
            label={`${t('addFirearm.transactionDate.label')}*`}
            control={control}
            errors={errors}
            currentHour={true}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Accordion title={t('addFirearm.ownerAndKeeper.section.name')} alwaysExpanded>
            <Box mb={5}>
              {type === ELegalEntityTypes.individual && (
                <div>
                  {isLegalEntityLoading ? (
                    <Loader isLoading={isLegalEntityLoading} />
                  ) : (
                    legalEntity && (
                      <StyledDetailsWrapper container rowGap={3}>
                        <Grid item xs={12} md={6}>
                          <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                            {t('addFirearm.ownerAndKeeper.section.owner.label')}
                          </Typography>
                          <Typography variant="body2">
                            <StyledLegalLink
                              legalId={legalEntity.uniqueId}
                              legalType={ELegalEntityTypes.individual}
                              labelText={`${legalEntity?.firstName} ${legalEntity?.lastName}`}
                              legalVisibilityUniqueId={legalEntity.visibilityUniqueId}
                            />
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                            {t('addFirearm.ownerAndKeeper.section.keeper.label')}
                          </Typography>
                          <Typography variant="body2">
                            <StyledLegalLink
                              legalId={legalEntity.uniqueId}
                              legalType={ELegalEntityTypes.individual}
                              labelText={`${legalEntity?.firstName} ${legalEntity?.lastName}`}
                              legalVisibilityUniqueId={legalEntity.visibilityUniqueId}
                            />
                          </Typography>
                        </Grid>
                      </StyledDetailsWrapper>
                    )
                  )}
                </div>
              )}
              {type === ELegalEntityTypes.organisation && (
                <div>
                  {isOrgLegalEntityLoading ? (
                    <Loader isLoading={isOrgLegalEntityLoading} />
                  ) : (
                    orgLegalEntity && (
                      <StyledDetailsWrapper container rowGap={3}>
                        <Grid item xs={12} md={6}>
                          <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                            {t('addFirearm.ownerAndKeeper.section.owner.label')}
                          </Typography>
                          <Typography variant="body2">
                            <StyledLegalLink
                              legalId={orgLegalEntity.uniqueId}
                              legalType={ELegalEntityTypes.organisation}
                              labelText={orgLegalEntity?.organizationName}
                              legalVisibilityUniqueId={orgLegalEntity.visibilityUniqueId}
                            />
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                            {t('addFirearm.ownerAndKeeper.section.keeper.label')}
                          </Typography>
                          <Typography variant="body2">
                            <StyledLegalLink
                              legalId={orgLegalEntity.uniqueId}
                              legalType={ELegalEntityTypes.organisation}
                              labelText={orgLegalEntity?.organizationName}
                              legalVisibilityUniqueId={orgLegalEntity.visibilityUniqueId}
                            />
                          </Typography>
                        </Grid>
                      </StyledDetailsWrapper>
                    )
                  )}
                </div>
              )}
            </Box>
          </Accordion>
        </Grid>
        <Grid item xs={12}>
          <Accordion title={t('addFirearm.firearmDetails.section.name')} alwaysExpanded>
            <Grid item xs={12} lg={3} mb={2}>
              <Button
                fullWidth
                label={selectFirearmLabelButton}
                size={EButtonSizes.small}
                id="add_firearm-seleect_frt_button"
                variant={EButtonVariants.outlined}
                onClick={() => {
                  clearUserInput();
                  if (routerState) {
                    const { address, city } = routerState;
                    navigate(
                      `${ERouteLinks.SearchForFRTFirearm.replace(
                        ':id',
                        id as string,
                      )}?type=${type}&expand=firearms`,
                      { state: { ...routerState, ...getValues(), address, city } },
                    );
                  } else {
                    navigate(
                      `${ERouteLinks.SearchForFRTFirearm.replace(
                        ':id',
                        id as string,
                      )}?type=${type}&expand=firearms`,
                      { state: { ...routerState, ...getValues() } },
                    );
                  }
                }}
              />
            </Grid>

            {selectedFirearm !== null ? (
              <Grid
                container
                sx={{
                  border: `1px solid ${colors.primary25}`,
                  borderRadius: '4px',
                  overflow: 'hidden',
                }}
              >
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    backgroundColor: colors.primary25,
                  }}
                  px={2}
                  py={1}
                >
                  <Grid item xs={12}>
                    <Typography variant="h4" sx={{ fontWeight: 600 }}>
                      {frtDetailsTitle}
                    </Typography>
                  </Grid>
                  {renderFirearmDetailsActionButtons(true)}
                </Grid>
                <Grid item container p={2} rowSpacing={2}>
                  <Grid item xs={12} lg={6} container direction="column">
                    {routerState && selectedFirearm && isFRTLocal(selectedFirearm.id) ? (
                      <>
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t(
                            'firearmsReferenceTable.firearmDetails.general.accordion.firearmReferenceLocalID.label',
                          )}
                        </Typography>
                        <Typography variant="body2">{selectedFirearm.frtId}</Typography>
                      </>
                    ) : (
                      <>
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t('addFirearm.firearmDetails.section.firearmsReferenceNo.label')}
                        </Typography>
                        <Typography variant="body2">{selectedFirearm.id}</Typography>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Link
                      href={`${ERouteLinks.FirearmsReferenceTableDetails}?frtId=${routerState.firearm.id}`}
                      underline="none"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button
                        fullWidth
                        label={t(
                          'addFirearm.firearmDetails.section.showFRTInformation.button',
                        )}
                        variant={EButtonVariants.contained}
                        size={EButtonSizes.small}
                      />
                    </Link>
                  </Grid>
                  {renderFirearmDetailsActionButtons(false)}
                </Grid>
              </Grid>
            ) : null}
            {selectedFirearm ? (
              <Grid item xs={12} container mt={2}>
                <Grid item xs={12}>
                  <FormRadioGroup
                    name="firearmsQuantity"
                    label={t('addFirearm.firearmDetails.section.howManyFirearms.text')}
                    options={firearmsQuantityOptions}
                    control={control}
                    errors={errors}
                  />
                </Grid>
                {firearmsQuantityValue === 'one' ? (
                  <Grid item xs={12} container columnSpacing={2}>
                    {isExhibitNumber && (
                      <Grid item xs={12} lg={3} sx={{ display: 'flex', alignItems: 'center' }}>
                        <FormCheckbox
                          name="isExhibitNumberChecked"
                          label={t(
                            'addFirearm.firearmDetails.section.serialNumberRemoved.label',
                          )}
                          control={control}
                          errors={errors}
                          onChange={handleExhibitNumberChange}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} lg={3}>
                      <FormInput
                        name="serialNumber"
                        label={`${t('addFirearm.firearmList.section.serialNumber.label')}${
                          (isTransactionIllegal && !isExhibitNumberChecked) ||
                          !isTransactionIllegal
                            ? '*'
                            : ''
                        }`}
                        setValue={setValue}
                        control={control}
                        errors={errors}
                        charsLimit={10}
                        disabled={isExhibitNumberChecked}
                      />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                      <FormInput
                        name="serialNumberConfirmation"
                        label={`${t(
                          'addFirearm.firearmList.section.serialNumberConfirmation.label',
                        )}${
                          (isTransactionIllegal && !isExhibitNumberChecked) ||
                          !isTransactionIllegal
                            ? '*'
                            : ''
                        }`}
                        setValue={setValue}
                        control={control}
                        errors={errors}
                        disableCopyPaste
                        charsLimit={10}
                        disabled={isExhibitNumberChecked}
                      />
                    </Grid>
                    {isExhibitNumber && (
                      <Grid item xs={12} lg={3}>
                        <FormInput
                          name="exhibitNumber"
                          label={`${t('addFirearm.firearmList.section.exhibitNumber.label')}${
                            isTransactionIllegal && isExhibitNumberChecked ? '*' : ''
                          }`}
                          setValue={setValue}
                          control={control}
                          charsLimit={10}
                          errors={errors}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} sm={isShowManufacturedDate ? 6 : 12} lg={3}>
                      <FormInput
                        key="externalReferenceNumber"
                        name="externalReferenceNumber"
                        label={t('addFirearm.firearmList.section.externalReferenceNo.label')}
                        setValue={setValue}
                        control={control}
                        errors={errors}
                        charsLimit={20}
                      />
                    </Grid>
                    {isShowManufacturedDate && (
                      <Grid item xs={12} sm={6} lg={3}>
                        <FormDatePicker
                          name="manufacturedAt"
                          label={`${t(
                            'addFirearm.firearmList.section.manufacturedDate.label',
                          )}`}
                          disabled={
                            transactionTypeValue === ETransactionTypes.DECLARED_MANUFACTURED
                          }
                          control={control}
                          errors={errors}
                        />
                      </Grid>
                    )}
                    {customFieldsFirearms.map((customField) => (
                      <Grid item xs={12} sm={6} lg={3} key={customField.uniqueId}>
                        <FormCustomField
                          data={customField}
                          control={control}
                          setValue={setValue}
                          errors={errors}
                        />
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Grid item xs={12} container columnSpacing={2}>
                    <Grid item xs={12} lg={6}>
                      <FormRadioGroup
                        name="isCreateGroup"
                        label={t('addFirearm.firearmDetails.section.toCreateAGroup.text')}
                        options={createGroupOptions}
                        control={control}
                        errors={errors}
                        disabled={hasPermissions([EPermission.MANAGE_GROUPS_LE]) !== true}
                      />
                    </Grid>
                    {isShowGroupNameFormElement && (
                      <Grid item xs={12} lg={6}>
                        <FormInput
                          key="groupName"
                          name="groupName"
                          label={`${t('addFirearm.firearmDetails.section.groupName.label')}*`}
                          setValue={setValue}
                          control={control}
                          charsLimit={255}
                          errors={errors}
                        />
                      </Grid>
                    )}
                  </Grid>
                )}
              </Grid>
            ) : null}
          </Accordion>
        </Grid>
        {isShowFirearmList && (
          <Grid item xs={12}>
            <Accordion title={t('addFirearm.firearmList.section.name')} alwaysExpanded>
              <Grid container mb={5}>
                <Grid
                  item
                  xs={12}
                  container
                  gap={3}
                  mb={2}
                  sx={{ flexWrap: { lg: 'nowrap' } }}
                >
                  <StyledFirearmListBox item xs={12} lg={6}>
                    <Typography variant="body1" mb={3}>
                      {t('addFirearm.firearmList.section.downloadInfo.text')}
                    </Typography>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      alignSelf="flex-end"
                      display="flex"
                      alignItems="flex-end"
                    >
                      <Button
                        onClick={handleGenerateFirearmListTemplate}
                        label={t('addFirearm.firearmList.section.downloadTemplate.button')}
                        icon={EIconTypes.download}
                        variant={EButtonVariants.outlined}
                        size={EButtonSizes.small}
                        fullWidth={!isDesktop}
                        disabled={transactionTypeValue === ''}
                      />
                    </Grid>
                  </StyledFirearmListBox>

                  <StyledFirearmListBox item xs={12} lg={6}>
                    <Typography variant="body1" mb={3}>
                      {t('addFirearm.firearmList.section.importInfo.text')}
                    </Typography>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      alignSelf="flex-end"
                      display="flex"
                      alignItems="flex-end"
                    >
                      <Button
                        onClick={() => {
                          setShowImportDialog(true);
                        }}
                        label={t('addFirearm.firearmList.section.importFirearms.button')}
                        icon={EIconTypes.download}
                        variant={EButtonVariants.outlined}
                        size={EButtonSizes.small}
                        fullWidth={!isDesktop}
                        disabled={transactionTypeValue === ''}
                      />
                    </Grid>
                  </StyledFirearmListBox>

                  <ImportFirearms
                    open={showImportDialog}
                    handleClose={() => setShowImportDialog(false)}
                    changeFirearmsListValue={(firearmList) => {
                      remove();
                      setIsDataImportedFromFile(true);
                      firearmList.forEach((firearmsListRow: any, index: number) => {
                        if (index !== 0) {
                          importItem(firearmsListRow, customFieldsFirearms);
                        }
                      });
                    }}
                  />
                </Grid>
                {!!fields.length && (
                  <Grid item xs={12} container>
                    <Grid item xs={12} mb={2}>
                      <Typography variant="subtitle1" display="flex" alignItems="center">
                        {`${t('addFirearm.firearmList.section.totalRowsCount.text')}:`}
                        <Typography variant="body1" ml={1}>
                          {fields.length}
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {fields.map((field, index) => (
                        <AddFirearmListItem
                          key={field.id}
                          index={index}
                          arrayName="firearmsList"
                          removeItem={() => remove(index)}
                          fields={fields}
                          control={control}
                          setValue={setValue}
                          errors={errors}
                          isShowManufacturedDate={isShowManufacturedDate}
                          customFields={customFieldsFirearms}
                          getValues={getValues}
                          isExhibitNumber={isExhibitNumber}
                          transactionTypeValue={transactionTypeValue}
                          isDataImportedFromFile={isDataImportedFromFile}
                          setIsDataImportedFromFile={setIsDataImportedFromFile}
                          isReloadCausedLocationSelect={isReloadCausedLocationSelect}
                        />
                      ))}
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={12} lg={3}>
                  <Button
                    onClick={() => {
                      append({
                        serialNumber: '',
                        externalReferenceNumber: '',
                        ...(isShowManufacturedDate ? { manufacturedAt: '' } : {}),
                      });
                    }}
                    fullWidth
                    label={t('addFirearm.firearmList.addRow.button')}
                    icon={EIconTypes.plus}
                    size={EButtonSizes.small}
                  />
                </Grid>
              </Grid>
            </Accordion>
          </Grid>
        )}
        {isShowLocation && (
          <Grid item xs={12}>
            <Accordion title={t('addFirearm.location.section.name')} alwaysExpanded>
              <Grid
                container
                mb={4}
                lg={12}
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                columnGap={3}
              >
                <Grid sx={{ flexBasis: '40%' }}>
                  <FormInput
                    name="address"
                    label={t('addFirearm.location.section.address.text')}
                    setValue={setValue}
                    control={control}
                    errors={errors}
                    placeholder={routerState?.address || ''}
                    InputProps={{ readOnly: true }}
                    disabled
                    sx={{ minWidth: '272px' }}
                    helperText={t('addFirearm.location.section.address.helperText')}
                  />
                </Grid>
                {routerState?.address && (
                  <Grid item>
                    <StyledLocationMarkConfirmationMarginBottom>
                      <Typography variant="body1" color={colors.success900}>
                        {t('addFirearm.location.section.locationMarked.text')}
                      </Typography>
                      <StyledIconWrapper>
                        <Icon type={EIconTypes.checkmark} color={colors.white} fontSize={14} />
                      </StyledIconWrapper>
                    </StyledLocationMarkConfirmationMarginBottom>
                  </Grid>
                )}
                <Grid item mb={4}>
                  <Button
                    onClick={() =>
                      navigate(
                        `${ERouteLinks.SelectLocation.replace(
                          ':id',
                          id,
                        )}?type=${type}&expand=firearms`,
                        {
                          state: {
                            ...routerState,
                            ...getValues(),
                            breadcrumbs: [
                              ...breadcrumbs.current,
                              t(
                                'addFirearm.legalEntityDetails.transactions.searchFRT.breadcrumb',
                              ),
                            ],
                            leId: id,
                            type,
                            returnLink: `${ERouteLinks.LegalEntityAddFirearm.replace(
                              ':id',
                              id as string,
                            )}?type=${type}&expand=firearms`,
                          },
                        },
                      )
                    }
                    fullWidth={!isDesktop}
                    label={t('addFirearm.location.section.selectLocationOnMap.button')}
                    variant={EButtonVariants.outlined}
                    size={EButtonSizes.small}
                    sx={{ marginTop: '28px' }}
                  />
                </Grid>
                {isErrorLocation && (
                  <Grid item xs={12}>
                    <FormErrorLabel
                      label={t('addFirearm.location.section.youMustSelectLocationOnMap.text')}
                    />
                  </Grid>
                )}
              </Grid>
            </Accordion>
          </Grid>
        )}
        {isShowCountry && (
          <Grid item xs={12}>
            <Accordion title={t('addFirearm.countryOfImport.section.name')} alwaysExpanded>
              <Grid mb={2} container columnSpacing={2}>
                <Grid item xs={12} lg={6}>
                  <FormInput
                    name="importer"
                    label={`${t('addFirearm.countryOfImport.section.importer.label')}`}
                    setValue={setValue}
                    control={control}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <FormSelect
                    options={mapDictionaryToOptionProp(country)}
                    name="countryOfImportUniqueId"
                    setValue={setValue}
                    label={`${t('addFirearm.countryOfImport.section.country.label')}*`}
                    control={control}
                    errors={errors}
                  />
                </Grid>
              </Grid>
            </Accordion>
          </Grid>
        )}
        <Grid item xs={12}>
          <Accordion title={`${t('addFirearm.document.section.name')}`} alwaysExpanded>
            <Box>
              <Grid container>
                <Grid item xs={12}>
                  <Box mb={2}>
                    <Alert
                      text={t('general.field.availableAttachmentFormats.text')}
                      variant={EAlertVariants.warning}
                    />
                  </Box>
                </Grid>
                {attachments?.fields?.map((attachment: any, index) => (
                  <Grid key={attachment.id} item xs={12} container mb={!isDesktop ? 2 : 0}>
                    <Grid item xs={attachments.fields?.length > 0 ? 11 : 12} lg={6}>
                      <FormInput
                        name={`attachments[${index}].attachmentName`}
                        label={t('addFirearm.document.section.attachmentName.label')}
                        defaultValue={
                          attachment?.file?.name
                            ? removeFileExtension(attachment?.file?.name)
                            : ''
                        }
                        control={control}
                        setValue={setValue}
                        errors={errors}
                        arrayName="attachments"
                        arrayIndex={index}
                        fieldName="attachmentName"
                        InputProps={{ disabled: attachment?.file === null }}
                      />
                    </Grid>
                    {!isDesktop && renderRemoveAttachmentButton(index)}
                    <Grid
                      item
                      xs={12}
                      lg={3}
                      sx={{ marginTop: { lg: '28px' }, ml: { lg: 2 } }}
                    >
                      <Button
                        isUploadButton
                        onClick={(files) => handleUploadFile(files[0], index)}
                        fullWidth
                        label={t('addFirearm.document.section.browseFiles.button')}
                        id="add_file-button"
                        icon={EIconTypes.plus}
                        size={EButtonSizes.small}
                        isMultiple
                      />
                      {errors &&
                        errors.attachments &&
                        errors.attachments[index] &&
                        errors.attachments[index].file && (
                          <FormErrorLabel label={errors.attachments[index].file?.message} />
                        )}
                    </Grid>
                    {isDesktop && renderRemoveAttachmentButton(index)}
                  </Grid>
                ))}
                <Grid item xs={12} lg={3} sx={{ mt: { xs: 2, lg: 0 } }}>
                  <Button
                    onClick={() =>
                      attachments.append({
                        attachmentName: '',
                        file: null,
                      })
                    }
                    fullWidth
                    label={t('addFirearm.document.section.addFile.button')}
                    id="add_file-button"
                    variant={EButtonVariants.outlined}
                    size={EButtonSizes.small}
                  />
                </Grid>
              </Grid>
            </Box>
          </Accordion>
        </Grid>
        <Grid container justifyContent="flex-start" sx={{ mt: 4 }}>
          <Grid item xs={12} mb={2}>
            <Typography variant="body2">
              {t('addFirearm.validation.firearmReference')}.
            </Typography>
          </Grid>
          <Grid item xs={12} lg={6} container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Button
                onClick={handleCreateInitialTransaction}
                fullWidth
                label={t('addFirearm.save.button')}
                variant={EButtonVariants.contained}
                sx={{ mr: 1 }}
                disabled={selectedFirearm === null}
                isLoading={submitBlocked}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                label={t('addFirearm.cancel.button')}
                variant={EButtonVariants.outlined}
                onClick={() =>
                  navigate(
                    `${ERouteLinks.ViewLegalEntity.replace(
                      ':id',
                      id,
                    )}?type=${type}&expand=firearms`,
                  )
                }
              />
            </Grid>
            <Grid xs={12} item>
              <Typography variant="caption">
                *{t('addFirearm.mandatoryField.label')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} mt={4}>
          {createInitialTransactionError && (
            <Box mb={2}>
              <Alert text={createInitialTransactionError} variant={EAlertVariants.error} />
            </Box>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export { LegalEntityAddFirearmPage };
