import { useTranslations } from 'hooks/useTranslations';
import { useAlert } from 'models/alertContext';
import { useState } from 'react';
import { ActionTypes } from 'state/actions/alert';
import { getNotificationsSetup, updateNotificationsSetup } from 'requests/settings';
import { IQlobalPropertyDetail } from 'models/settings';
import { IFormValues } from './index';

export enum ENotificationsFrequency {
  HOURLY = 'HOURLY',
  TWO_TIMES_A_DAY = 'TWO_TIMES_A_DAY',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
}
enum ECronNotificationTime {
  '0 0 0/1 1/1 * ? *' = ENotificationsFrequency.HOURLY,
  '0 0 12,16 1/1 * ? *' = ENotificationsFrequency.TWO_TIMES_A_DAY,
  '0 0 18 1/1 * ? *' = ENotificationsFrequency.DAILY,
  '0 0 18 ? * MON *' = ENotificationsFrequency.WEEKLY,
}
export const useNotificationsSetup = () => {
  const [notificationsSetup, setNotificationsSetup] = useState<IFormValues | null>(null);
  const { setAlert } = useAlert();
  const { t } = useTranslations();
  const handleUpdate = async (data: IFormValues) => {
    if (data.notificationsFrequency) {
      const response = await updateNotificationsSetup(data.notificationsFrequency);
      if (response.status === 200) {
        setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
          text: t('notificationsSetupUpdate.success.text'),
        });
      }
      return response;
    }
    return false;
  };
  const getNotificationsSetupData = async () => {
    const dateTimeSetupData: IQlobalPropertyDetail | null = await getNotificationsSetup();
    if (dateTimeSetupData && dateTimeSetupData.value) {
      const notificationsFrequency = ECronNotificationTime[
        dateTimeSetupData.value as any
      ] as ENotificationsFrequency;
      if (notificationsFrequency) {
        setNotificationsSetup({
          notificationsFrequency: ENotificationsFrequency[notificationsFrequency],
        });
      }
    } else {
      setNotificationsSetup(null);
    }
  };

  return { handleUpdate, getNotificationsSetupData, notificationsSetup };
};
