import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { ERouteLinks } from 'models/route';
import { StyledPageTitle } from 'theme/styles';
import { useTranslations } from 'hooks/useTranslations';
import Grid from '@mui/material/Grid';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import { TableTopButtons } from 'components/atoms/TableTopButtons';
import { useApiKeys } from 'pages/ApiKeysPage/hooks';
import { EPerPages, ISortOptions } from 'models/table';
import { Pagination } from 'components/molecules/Pagination';
import { useNavigate } from 'react-router-dom';
import { Table } from 'components/molecules/Table';
import { apiKeysTableColumns, mapApiKeysToDataSource } from 'pages/ApiKeysPage/helpers';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { Box } from '@mui/material';
import { Alert } from 'components/atoms/Alert';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import { ApiKeysStatusIds, ApiKeyStatus } from 'models/apiKey';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { CardList } from 'components/molecules/CardList';
import ApiKeyTableCard from 'pages/ApiKeysPage/ApiKeyTableCard';
import { useLang } from 'models/langContext';
import { copyToClipboard } from 'utils/clipboard';

const ApiKeysPage = () => {
  const { t } = useTranslations();
  const { nextPageAlert, clearAlert, samePageAlert, setAlert } = useAlert();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<EPerPages>(EPerPages.perPage25);
  const [sort, setSort] = useState<ISortOptions | null>(null);
  const { getApiKeysData, apiKeys, paginator } = useApiKeys();
  const { isDesktop } = useThemeBreakpoint();
  const { longDateFormat, shortDateFormat } = useGlobalProperty();
  const { changeApiKeyHandler } = useApiKeys();
  const { selectedLanguage } = useLang();
  const [isPaginationLoading, setIsPaginationLoading] = useState(true);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_NEXT_PAGE_ALERT);
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    (async () => {
      setIsPaginationLoading(true);
      await getApiKeysData(currentPage, perPage, {}, sort);
      setIsPaginationLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, perPage, sort, selectedLanguage]);

  const handleChangeStatus = async (id: string, status: string) => {
    try {
      await changeApiKeyHandler(
        id,
        status === ApiKeysStatusIds.ACTIVE
          ? ApiKeyStatus.INACTIVE.toUpperCase()
          : ApiKeyStatus.ACTIVE.toUpperCase(),
      );
      await getApiKeysData(currentPage, perPage, {}, sort);
    } catch (e) {
      console.error(e);
    }
  };

  const handleCopyApiKey = async (key: string) => {
    try {
      await copyToClipboard(key);
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: t('apiKeys.copied.info'),
      });
    } catch (e: any) {
      console.error(e);
    }
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.administration.name'), route: ERouteLinks.Administration },
          t('apiKeys.header'),
        ]}
      />
      <StyledPageTitle variant="h4">{t('apiKeys.header')}</StyledPageTitle>

      {nextPageAlert && (
        <Box mt={2} mb={2}>
          <Alert text={nextPageAlert.text} variant={nextPageAlert.variant} />
        </Box>
      )}

      {samePageAlert && (
        <Box mt={2} mb={2}>
          <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
        </Box>
      )}

      <TableTopButtons>
        <Grid container justifyContent="flex-end" columns={10}>
          <Grid item lg={2} sm={5} xs={10}>
            <Button
              label={t('apiKeys.addApiKey.button')}
              variant={EButtonVariants.contained}
              icon={EIconTypes.plus}
              onClick={() => navigate(ERouteLinks.AddApiKeyPage)}
              fullWidth={true}
            />
          </Grid>
        </Grid>
      </TableTopButtons>

      {isDesktop ? (
        <Table
          columns={apiKeysTableColumns({
            handleEdit: (id: string) =>
              navigate(ERouteLinks.EditApiKeyPage.replace(':id', id)),
            handleChangeStatus: (id: string, status: string) => handleChangeStatus(id, status),
            handleCopyApiKey: (key: string) => handleCopyApiKey(key),
            longDateFormat,
            shortDateFormat,
          })}
          dataSource={mapApiKeysToDataSource(apiKeys)}
          onSort={setSort}
        />
      ) : (
        <CardList
          items={mapApiKeysToDataSource(apiKeys)}
          render={(data, index) => (
            <ApiKeyTableCard
              data={data}
              key={index}
              longDateFormat={longDateFormat}
              shortDateFormat={shortDateFormat}
              handleChangeStatus={handleChangeStatus}
              handleCopyApiKey={handleCopyApiKey}
            />
          )}
        />
      )}

      {paginator && (
        <Pagination
          count={paginator.totalElements}
          onChangePage={(page) => setCurrentPage(page)}
          current={currentPage}
          perPage={perPage}
          onChangePerPage={(value) => setPerPage(value)}
          isLoading={isPaginationLoading}
        />
      )}
    </>
  );
};

export default ApiKeysPage;
