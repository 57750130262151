import React from 'react';
import { ITableColumns, ITableDataSource } from 'models/table';
import {
  ITransaction,
  ITransactionDetailsAncillary,
  ITransactionDetailsFirearm,
  ITransactionItemType,
} from 'models/transaction';
import { formatDate } from 'utils/date';
import { ERouteLinks } from 'models/route';
import { EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import Button from 'components/atoms/Button';
import { Chips } from 'components/atoms/Chips';
import { ECustomFieldType, ICustomFieldByObjectType } from 'models/customField';
import { getColumnTitleFromCustomField } from 'utils/table';
import { ILegalEntityCustomField } from 'components/organisms/LegalEntityDetails';
import { Link as LinkRouter } from 'react-router-dom';
import Link from '@mui/material/Link';
import { getAncillaryViewRoute } from 'pages/TransactionsPage/ViewTransactionPage';

export const mapTransactionsToDataSource = (transactions: ITransaction[]) =>
  transactions.map((transaction) => ({
    id: `${transaction.transactionUniqueId}${transaction.serialNumber}`,
    transactionId: transaction.transactionUniqueId,
    date: transaction.date,
    type: transaction.type,
    state: transaction.state,
    legalityUniqueId: transaction.legalityUniqueId,
    activityUniqueId: transaction.activityUniqueId,
    typeUniqueId: transaction.typeUniqueId,
    transactionItemType: transaction.transactionItemType,
    serialNumber: transaction.serialNumber,
    owner: transaction.owner,
    keeper: transaction.keeper,
    manufacturer: transaction.manufacturer,
  }));

interface ITransactionsTableColumns {
  renderStateFieldColor: (arg: ITableDataSource) => any;
  midDateFormat: string;
  t: (key: string) => string;
}

export const transactionsTableColumns = ({
  midDateFormat,
  renderStateFieldColor,
  t,
}: ITransactionsTableColumns): ITableColumns[] => [
  {
    id: 'f0504c01-6b32-482d-b369-304a6f32f510',
    key: 'date',
    title: 'transactionsList.transactionDate.column',
    dateFormat: midDateFormat,
  },
  {
    id: 'f0504c02-6b32-482d-b369-304a6f32f510',
    key: 'type',
    title: 'transactionsList.transactionType.column',
    align: 'center',
    minWidth: '230px',
    isCenterTableHead: true,
    render: (_data, props) => (
      <Chips text={props.type} variant={renderStateFieldColor(props)} />
    ),
  },
  {
    id: '3401d192-48ba-11ee-be56-0242ac120002',
    key: 'state',
    title: 'transactionsList.state.column',
    align: 'center',
    minWidth: '130px',
    isCenterTableHead: true,
    render: (_data, props) => (
      <Chips text={props.state} variant={renderStateFieldColor(props)} />
    ),
  },
  {
    id: 'f0504c04-6b32-482d-b369-304a6f32f510',
    key: 'owner',
    title: 'transactionsList.owner.column',
    minWidth: '230px',
  },
  {
    id: 'f0504c05-6b32-482d-b369-304a6f32f510',
    key: 'keeper',
    title: 'transactionsList.keeper.column',
    minWidth: '230px',
  },
  {
    id: 'f0504c03-6b32-482d-b369-304a6f32f510',
    key: 'serialNumber',
    title: 'transactionsList.serialNumber.column',
    width: '250px',
  },
  {
    id: '9a7554b6-4893-11ee-be56-0242ac120002',
    key: 'transactionItemType',
    title: 'transactionsList.asset.label',
    align: 'center',
    isCenterTableHead: true,
    minWidth: '140px',
    render: (_data, props) =>
      ITransactionItemType[props.transactionItemType as keyof typeof ITransactionItemType] ===
      ITransactionItemType.FIREARM
        ? t('transaction.search.firearmType.label')
        : t('transaction.search.ancillaryType.label'),
  },
  {
    id: 'f0504c06-6b32-482d-b369-304a6f32f510',
    key: 'manufacturer',
    title: 'transactionsList.transactionType.manufacturer',
    minWidth: '180px',
  },
  {
    id: 'f0504c08-6b32-482d-b369-304a6f32f510',
    key: '',
    title: 'transactionsList.action.column',
    isSortable: false,
    width: '60px',
    align: 'center',
    minWidth: '100px',
    isCenterTableHead: true,
    render: (_data, props) => (
      <LinkRouter to={ERouteLinks.ViewTransaction.replace(':id', props.transactionId)}>
        <Button
          variant={EButtonVariants.outlined}
          icon={EIconTypes.view}
          sx={{ marginRight: '2px' }}
        />
      </LinkRouter>
    ),
  },
];

export const mapValuesToTransactionPresetForView = (
  values: { key: string; value: string }[],
  midDateFormat: string,
) =>
  values.map((item) => {
    switch (item.key) {
      case 'transactionTimeFrom':
      case 'transactionTimeTo':
      case 'createTimeFrom':
      case 'createTimeTo':
      case 'expirationTimeFrom':
      case 'expirationTimeTo':
        return {
          key: item.key,
          value: formatDate(item.value, midDateFormat),
        };
      default:
        return item;
    }
  });

interface ITransactionDetailsTableColumns {
  isDifferentMakesOrModels: boolean;
  handleView: (arg: string) => void | null;
  customFields?: ICustomFieldByObjectType[];
  t?: (key: string) => string;
}

export const transactionDetailsFirearmsTableColumns = (
  {
    isDifferentMakesOrModels,
    handleView,
    customFields = [],
    t,
  }: ITransactionDetailsTableColumns,
  selectedLanguage?: string | undefined,
  dateFormat?: string | undefined,
): ITableColumns[] => {
  const customFieldColumns: ITableColumns[] = customFields
    .filter((customField) => customField.visibleInSearchResults)
    .map((customField) => ({
      id: customField.uniqueId,
      key: `customField.${customField.name}`,
      title: getColumnTitleFromCustomField(customField, selectedLanguage),
      isSortable: false,
      render: (data, props) => {
        if (props?.customFields?.length) {
          const cf = props.customFields.find(
            (cField: ILegalEntityCustomField) => cField.customFieldName === customField.name,
          );
          if (cf) {
            if (cf.customFieldType === ECustomFieldType.BOOL && t) {
              if (cf.value === null) {
                return '-';
              }
              return cf.value
                ? t('customFields.value.yes.label')
                : t('customFields.value.no.label');
            }
            if (cf.customFieldType === ECustomFieldType.DATE && dateFormat) {
              return formatDate(cf.value, dateFormat);
            }
            return cf.value || '-';
          }
        }
        return '-';
      },
    }));

  return [
    {
      id: 'f0504ca-6b32-482d-b369-304a6f32f510',
      key: 'fireCycleId',
      title: 'transactionDetails.firearmDetails.accordion.firecycleID.label',
    },
    ...(isDifferentMakesOrModels
      ? [
          {
            id: 'f0504ca6-6b32-482d-b369-304a6f32f511',
            key: 'make',
            title: 'transactionDetails.firearmDetails.accordion.make.column',
          },
          {
            id: 'f0504ca6-6b32-482d-b369-304a6f32f512',
            key: 'model',
            title: 'transactionDetails.firearmDetails.accordion.model.column',
          },
          {
            id: 'f0504ca6-6b32-482d-b369-304a6f32f513',
            key: 'action',
            title: 'transactionDetails.firearmDetails.accordion.firearmAction.column',
          },
          {
            id: 'f0504ca6-6b32-482d-b369-304a6f32f515',
            key: 'type',
            title: 'transactionDetails.firearmDetails.accordion.type.column',
          },
          {
            id: 'f0504ca6-6b32-482d-b369-304a6f32f514',
            key: 'caliber',
            title: 'transactionDetails.firearmDetails.accordion.calibre.column',
          },
          {
            id: 'f0504ca6-6b32-482d-b369-304a6f32f524',
            key: 'frtId',
            title: 'transactionDetails.firearmDetails.accordion.firearmsReferenceNo.label',
            render: (_data: any) => (
              <Link
                href={`${ERouteLinks.FirearmsReferenceTableDetails}?frtId=${_data}`}
                underline="none"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ textDecoration: 'underline' }}
              >
                {_data}
              </Link>
            ),
          },
        ]
      : []),
    {
      id: 'f0504ca6-6b32-482d-b369-304a6f32f534',
      key: 'serialNumber',
      title: 'transactionDetails.firearmDetails.accordion.serialNumber.label',
    },
    {
      id: 'f0504ca6-6b32-482d-b369-304a6f32f544',
      key: 'externalReferenceNumber',
      title: 'transactionDetails.firearmDetails.accordion.firearmReferenceNo.column',
    },
    {
      id: 'f0504ca6-6b32-482d-b369-304a6f32f554',
      key: 'manufacturedAt',
      title: 'transactionDetails.firearmDetails.accordion.manufacturedDate.column',
    },
    ...customFieldColumns,
    {
      id: 'fe7a7688-1583-4233-8319-dd5c8973cc44',
      key: '',
      title: 'registeredFirearms.accordion.actions.column',
      isSortable: false,
      width: '60px',
      align: 'center',
      isCenterTableHead: true,
      render: (_data, props) =>
        !!handleView && (
          <Button
            variant={EButtonVariants.outlined}
            icon={EIconTypes.view}
            sx={{ marginRight: '2px' }}
            onClick={() => handleView(props.id as string)}
          />
        ),
    },
  ];
};

interface ITransactionDetailsAncillariesTableColumns {
  isDiscard?: boolean;
  showDetail: boolean | null;
}

export const transactionDetailsAncillariesTableColumns = ({
  isDiscard = false,
  showDetail,
}: ITransactionDetailsAncillariesTableColumns): ITableColumns[] => [
  {
    id: 'gg504ca-6b32-482d-b369-304a6f32f510',
    key: 'fireCycleId',
    title: 'transactionDetails.ancillaryDetails.accordion.firecycleID.label',
  },
  {
    id: '6h504ca6-6b32-482d-b369-304a6f32f534',
    key: 'serialNumber',
    title: 'transactionDetails.ancillaryDetails.accordion.serialNumber.label',
  },
  {
    id: 'f0504ca6-6b32-4822-b369-304a6f32f512',
    key: 'lotNumber',
    title: 'transactionDetails.ancillaryDetails.accordion.lotNumber.label',
  },
  {
    id: 'f0504ca6-6b32-4823-b369-304a6f32f513',
    key: 'batchNumber',
    title: 'transactionDetails.ancillaryDetails.accordion.batchNumber.label',
  },
  {
    id: 'f0504ca6-6b32-4825-b369-304a6f32f514',
    key: 'ancillaryDictionaryValue',
    title: 'transactionDetails.ancillaryDetails.accordion.ancillary.label',
  },
  {
    id: 'f0504ca6-6b32-4825-b369-304a6f32f515',
    key: 'ancillarySpecificType',
    title: 'transactionDetails.ancillaryDetails.accordion.ancillaryType.label',
  },
  {
    id: 'f0504ca6-6b32-4825-b369-304a6f32f516',
    key: 'manufacturer',
    title: 'transactionDetails.ancillaryDetails.accordion.manufacturer.label',
  },
  {
    id: 'f0504ca6-6b32-4825-b369-304a6f32f517',
    key: 'calibre',
    title: 'transactionDetails.ancillaryDetails.accordion.calibre.label',
  },
  {
    id: '347a7688-1583-4233-8319-dd5c8973cc44',
    key: '',
    title: 'transactionDetails.ancillaryDetails.accordion.actions.column',
    isSortable: false,
    width: '60px',
    align: 'center',
    isCenterTableHead: true,
    render: (_data, props) =>
      showDetail && (
        <LinkRouter
          to={getAncillaryViewRoute(props.ancillaryType, props.id)}
          {...(isDiscard ? { target: '_blank' } : {})}
        >
          <Button
            variant={EButtonVariants.outlined}
            icon={EIconTypes.view}
            sx={{ marginRight: '2px' }}
          />
        </LinkRouter>
      ),
  },
];

export const mapTransactionDetailsFirearms = (
  items: ITransactionDetailsFirearm[],
  shortDateFormat: string,
): ITableDataSource[] =>
  items.map((item) => ({
    id: item.uniqueId,
    fireCycleId: item.fireCycleId,
    make: item.make,
    model: item.model,
    action: item.action,
    type: item.type,
    caliber: item.caliber,
    frtId: item.frtId,
    serialNumber: item.serialNumber,
    externalReferenceNumber: item.externalReferenceNumber,
    customFields: item.customFields || [],
    manufacturedAt: item?.manufacturedAt
      ? formatDate(item.manufacturedAt, shortDateFormat)
      : '',
  }));

export const mapTransactionDetailsAncillaries = (
  items: ITransactionDetailsAncillary[],
  shortDateFormat: string,
): ITableDataSource[] =>
  items.map((item) => ({
    id: item.uniqueId,
    fireCycleId: item.fireCycleId,
    artUniqueId: item.artUniqueId,
    artId: item.artId,
    ancillaryDictionaryValue: item.ancillaryDictionaryValue,
    ancillaryType: item.ancillaryType,
    serialNumber: item.serialNumber,
    externalReferenceNumber: item.externalReferenceNumber,
    manufacturedAt: item?.productionDate
      ? formatDate(item.productionDate, shortDateFormat)
      : '-',
    manufacturer: item.manufacturer,
    ancillarySpecificType: item.ancillarySpecificType,
    calibre: item.calibre,
    lotNumber: item.lotNumber,
    batchNumber: item.batchNumber,
  }));
