import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { StyledPageTitle } from 'theme/styles';
import { RoleForm } from 'pages/RolesPage/RoleForm';
import TableFormWrapper from 'components/atoms/FormWrapper';
import { MAX_ROLES, useRoles } from 'pages/RolesPage/hooks';
import { useNavigate } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import { ActionTypes } from 'state/actions/alert';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { useAlert } from 'models/alertContext';
import { Box } from '@mui/material';
import { IRoleFormValues } from 'models/role';
import { initialRoleFormValues } from 'models/form';
import { useTranslations } from 'hooks/useTranslations';
import { Loader } from 'components/atoms/Loader';

const AddRolePage = () => {
  const navigate = useNavigate();
  const { handleCreate } = useRoles();
  const { setAlert, clearAlert, samePageAlert, dictionaryAlert } = useAlert();
  const { permissions, rolesAmount, getRolesAmount } = useRoles();
  const [initialValues, setInitialValues] = useState<IRoleFormValues | null>();
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslations();

  useEffect(() => {
    getRolesAmount().then(() => {
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    const formInitialValues = initialRoleFormValues;
    permissions.forEach((permission) => {
      formInitialValues.formPermissionIds[permission.uniqueId] = false;
    });
    setInitialValues(formInitialValues);
  }, [permissions]);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleSubmit = async (data: IRoleFormValues) => {
    try {
      await handleCreate(data);
      setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
        text: t('addRole.success.text'),
        variant: EAlertVariants.success,
      });
      navigate(ERouteLinks.Roles);
    } catch (e: any) {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: e.response.data.errors[0].message,
        variant: EAlertVariants.error,
      });
    }
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.administration.name'), route: ERouteLinks.Administration },
          {
            label: t('roles.header'),
            route: ERouteLinks.Roles,
          },
          t('addRole.header'),
        ]}
      />
      {!isLoading ? (
        <>
          <StyledPageTitle variant="h4">{t('addRole.header')}</StyledPageTitle>

          <TableFormWrapper>
            <RoleForm
              onCancel={() => navigate(ERouteLinks.Roles)}
              onSubmit={handleSubmit}
              initialFormValues={initialValues}
              formDisabled={rolesAmount !== null && rolesAmount >= MAX_ROLES}
            />
          </TableFormWrapper>
        </>
      ) : (
        <Loader isLoading={true} />
      )}

      {samePageAlert && (
        <Box mt={2}>
          <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
        </Box>
      )}
      {dictionaryAlert && (
        <Box mt={2}>
          <Alert text={dictionaryAlert.text} variant={dictionaryAlert.variant} />
        </Box>
      )}
    </>
  );
};

export { AddRolePage };
