import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { useTranslations } from 'hooks/useTranslations';
import { useAncillaries } from 'pages/AncillariesPage/hooks';
import { EDictionaryTypes } from 'models/dictionary';
import { useLang } from 'models/langContext';
import { DetailsTemplate, EDetailsPageTypes } from 'components/templates/DetailsTemplate';
import { EButtonSizes, EButtonSizesPX, EButtonVariants } from 'constants/Buttons';
import { Chips } from 'components/atoms/Chips';
import { getFieldsByAccessoriesType } from 'pages/AncillariesPage/helpers';
import {
  AccessoryInformationContainer,
  AccessoryInformationTitle,
} from 'pages/AncillariesPage/AccessoriesPage/DetailAccessories/styles';
import { ERouteLinks } from 'models/route';
import { EAccessoryLabels } from 'pages/AncillariesPage/AccessoriesPage/DetailAccessories';
import Link from '@mui/material/Link';
import { EArtAncillaryTypeName } from 'constants/ArtAccessoryType';
import Button from 'components/atoms/Button';
import { mapAncillaryTypeToTranslationKey } from 'utils/mapAncillaryTypeToTranslation';
import { renderDataColorByValues } from 'components/molecules/Table/helpers';
import { emptyFieldHolder } from 'components/molecules/CardList';
import { IAncillaryDetailsCustomfield } from 'models/ancillaries';
import { ECustomFieldType } from 'models/customField';
import { formatDate } from 'utils/date';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { EPermission } from 'models/permissions';
import { usePermission } from 'hooks/usePermission';

interface IAccessoryDetails {
  id: string;
  visibleCustomFieldsData?: boolean;
  disableActions?: boolean;
  customFieldsData?: IAncillaryDetailsCustomfield[];
}
const AccessoryDetails = ({
  id,
  visibleCustomFieldsData,
  disableActions,
  customFieldsData,
}: IAccessoryDetails) => {
  const { t } = useTranslations();
  const { selectedLanguage, defaultLanguage } = useLang();
  const { hasPermissions } = usePermission();
  const navigate = useNavigate();
  const { loadDictionaries, accessoryDetail, getAccessoryDetail } = useAncillaries();
  const [accessFields, setAccessFields] = useState<string[]>([]);
  const { shortDateFormat } = useGlobalProperty();

  useEffect(() => {
    if (accessoryDetail) {
      if (accessoryDetail.typeUniqueId) {
        const fields = getFieldsByAccessoriesType(accessoryDetail.typeUniqueId);
        setAccessFields(fields);
      } else {
        setAccessFields([]);
      }
    }
  }, [accessoryDetail]);

  useEffect(() => {
    (async () => {
      if (id && hasPermissions([EPermission.VIEW_LIMITED_ANCILLARIES])) {
        await getAccessoryDetail(id);
      }
    })();
  }, [id]);

  useEffect(() => {
    (async function initDictionaries() {
      await loadDictionaries([EDictionaryTypes.ArtAncillaryOptions]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  const showField = (fieldName: string) => accessFields.includes(fieldName);

  const renderCustomFieldData = (customField: IAncillaryDetailsCustomfield) => {
    if (customField.customFieldType === ECustomFieldType.BOOL) {
      if (customField.value === null) {
        return '-';
      }
      return customField.value
        ? t('customFields.value.yes.label')
        : t('customFields.value.no.label');
    }
    if (customField.customFieldType === ECustomFieldType.DATE) {
      return formatDate(customField.value, shortDateFormat);
    }
    return customField.value || '-';
  };

  // eslint-disable-next-line consistent-return
  const handleCustomFieldsData = () => {
    if (customFieldsData && customFieldsData.length) {
      return customFieldsData;
    }
    if (accessoryDetail?.customFields) {
      return accessoryDetail.customFields;
    }
  };

  return (
    <div>
      {accessoryDetail && (
        <Grid container item gap={3}>
          <DetailsTemplate
            title={accessoryDetail?.type}
            pageType={EDetailsPageTypes.view}
            editPermissions={[EPermission.EDIT_ANCILLARY]}
            redirectToView={() =>
              navigate(`${ERouteLinks.ViewAccessories.replace(':id', id)}`)
            }
            disableActions={disableActions}
            actionButtonFullRow={true}
            actionButtonProps={{
              ...{ size: 'small' },
              ...{ sx: { minWidth: '207px !important' } },
              ...{ label: t('legalEntityDetails.ancillary.accordion.details.link') },
              ...{ sizePX: EButtonSizesPX.px24 },
              ...{ variant: EButtonVariants.outlined },
            }}
          >
            <AccessoryInformationContainer item xs={12} lg={12} container>
              <Grid item xs={12} lg={12} container direction="column">
                <AccessoryInformationTitle variant="subtitle1">
                  {t(EAccessoryLabels.accessoryGeneralInformation)}
                </AccessoryInformationTitle>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EAccessoryLabels.ancillaryType)}
                </Typography>
                <Typography variant="body2">
                  {t(mapAncillaryTypeToTranslationKey(accessoryDetail?.ancillaryType))}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EAccessoryLabels.accessoryType)}
                </Typography>
                <Typography variant="body2">{accessoryDetail?.type}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography
                  variant="subtitle2"
                  sx={{ marginBottom: '4px', textTransform: 'uppercase' }}
                >
                  {t(EAccessoryLabels.artId)}
                </Typography>
                <Typography variant="body2" style={{ textTransform: 'capitalize' }}>
                  {accessoryDetail?.artId}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Link
                  href={`${ERouteLinks.ViewAncillaries.replace(
                    ':id',
                    accessoryDetail.artUniqueId,
                  ).replace(':type', EArtAncillaryTypeName.ACCESSORY.toLowerCase())}`}
                  underline="none"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    fullWidth
                    label={t('accessoryDetails.showArtInformation.button')}
                    variant={EButtonVariants.contained}
                    size={EButtonSizes.small}
                  />
                </Link>
              </Grid>
              {showField('manufacturer') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAccessoryLabels.manufacturer)}
                  </Typography>
                  <Typography variant="body2">
                    {accessoryDetail?.manufacturer ?? '-'}
                  </Typography>
                </Grid>
              )}
              {showField('productName') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAccessoryLabels.productName)}
                  </Typography>
                  <Typography variant="body2">
                    {accessoryDetail?.productName ?? '-'}
                  </Typography>
                </Grid>
              )}
              {showField('calibreUniqueId') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAccessoryLabels.calibre)}
                  </Typography>
                  <Typography variant="body2">{accessoryDetail?.calibre ?? '-'}</Typography>
                </Grid>
              )}
              {showField('composition') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAccessoryLabels.composition)}
                  </Typography>
                  <Typography variant="body2">
                    {accessoryDetail?.composition ?? '-'}
                  </Typography>
                </Grid>
              )}
              {showField('proofHouse') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAccessoryLabels.proofHouse)}
                  </Typography>
                  <Typography variant="body2">{accessoryDetail?.proofHouse ?? '-'}</Typography>
                </Grid>
              )}
              {showField('hostType') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAccessoryLabels.hostType)}
                  </Typography>
                  <Typography variant="body2">{accessoryDetail?.hostType ?? '-'}</Typography>
                </Grid>
              )}
              {showField('magnification') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAccessoryLabels.magnification)}
                  </Typography>
                  <Typography variant="body2">
                    {accessoryDetail?.magnification ?? '-'}
                  </Typography>
                </Grid>
              )}
              {showField('utility') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAccessoryLabels.utility)}
                  </Typography>
                  <Typography variant="body2">{accessoryDetail?.utility ?? '-'}</Typography>
                </Grid>
              )}
              {showField('reticle') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAccessoryLabels.reticle)}
                  </Typography>
                  <Typography variant="body2">{accessoryDetail?.reticle ?? '-'}</Typography>
                </Grid>
              )}
              {showField('lightcolour') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAccessoryLabels.lightcolour)}
                  </Typography>
                  <Typography variant="body2">
                    {accessoryDetail?.lightcolour ?? '-'}
                  </Typography>
                </Grid>
              )}
              {showField('capacity') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAccessoryLabels.capacity)}
                  </Typography>
                  <Typography variant="body2">{accessoryDetail?.capacity ?? '-'}</Typography>
                </Grid>
              )}
            </AccessoryInformationContainer>
            <AccessoryInformationContainer item xs={12} lg={12} container borderHidden>
              <Grid item xs={12} lg={12} container direction="column">
                <AccessoryInformationTitle variant="subtitle1">
                  {t(EAccessoryLabels.accessoryDetailedInformation)}
                </AccessoryInformationTitle>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EAccessoryLabels.firecycleID)}
                </Typography>
                <Typography variant="body2">{accessoryDetail?.fireCycleId ?? '-'}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EAccessoryLabels.serialNumber)}
                </Typography>
                <Typography variant="body2">{accessoryDetail?.serialNumber ?? '-'}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EAccessoryLabels.externalRefNo)}
                </Typography>
                <Typography variant="body2">
                  {accessoryDetail?.externalReferenceNumber ?? '-'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EAccessoryLabels.componentState)}
                </Typography>
                <Typography variant="body2">
                  <Chips
                    text={emptyFieldHolder(accessoryDetail?.state)}
                    variant={
                      renderDataColorByValues(
                        accessoryDetail.activityUniqueId,
                        accessoryDetail.legalityUniqueId,
                        accessoryDetail.stateUniqueId,
                      ).stateField
                    }
                  />
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EAccessoryLabels.exhibitNumber)}
                </Typography>
                <Typography variant="body2">
                  {accessoryDetail?.exhibitNumber ?? '-'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EAccessoryLabels.currentOwner)}
                </Typography>
                <Typography variant="body2">{accessoryDetail?.ownerName ?? '-'}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EAccessoryLabels.currentKeeper)}
                </Typography>
                <Typography variant="body2">{accessoryDetail?.keeperName ?? '-'}</Typography>
              </Grid>
              {visibleCustomFieldsData && (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                  {handleCustomFieldsData()?.map((customField) => (
                    <Grid item xs={6} lg={3} key={customField.customFieldUniqueId}>
                      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {customField?.translations?.filter(
                          (translation) =>
                            selectedLanguage?.uniqueId === translation.languageUniqueId,
                        )?.[0]?.name ||
                          customField?.translations?.filter(
                            (translation) =>
                              defaultLanguage?.uniqueId === translation.languageUniqueId,
                          )?.[0]?.name}
                      </Typography>
                      <Typography variant="body2">
                        {renderCustomFieldData(customField)}
                      </Typography>
                    </Grid>
                  ))}
                </>
              )}
            </AccessoryInformationContainer>
          </DetailsTemplate>
        </Grid>
      )}
    </div>
  );
};

export default AccessoryDetails;
