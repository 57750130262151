import React, { useEffect, useState } from 'react';
import { ERouteLinks } from 'models/route';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { useParams } from 'react-router-dom';
import { StyledPageTitle } from 'theme/styles';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import { useQuery } from 'hooks/useQuery';
import { ELegalEntityTypes } from 'constants/LegalEntityTypes';
import { useTranslations } from 'hooks/useTranslations';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { Loader } from 'components/atoms/Loader';
import { useAncillaries } from 'pages/AncillariesPage/hooks';
import {
  EArtAmmunitionType,
  EArtAncillaryTypeNameLowerCase,
  EArtComponentType,
} from 'constants/ArtAccessoryType';
import {
  getFieldsByAccessoriesType,
  getFieldsByAmmunitionType,
  getFieldsByComponentType,
} from 'pages/AncillariesPage/helpers';
import { EAccessoryLabels } from 'pages/AncillariesPage/AccessoriesPage/DetailAccessories';
import {
  AccessoryInformationContainer,
  AccessoryInformationTitle,
} from 'pages/AncillariesPage/AccessoriesPage/DetailAccessories/styles';
import { emptyFieldHolder } from 'components/molecules/CardList';
import { Chips } from 'components/atoms/Chips';
import { renderDataColorByValues } from 'components/molecules/Table/helpers';
import { DetailsTemplate, EDetailsPageTypes } from 'components/templates/DetailsTemplate';
import {
  AmmunitionInformationContainer,
  AmmunitionInformationTitle,
} from 'pages/AncillariesPage/AmmunitionPage/DetailAmmunition/styles';
import { EAmmunitionLabels } from 'pages/AncillariesPage/AmmunitionPage/DetailAmmunition';
import { formatDate } from 'utils/date';
import {
  ComponentInformationContainer,
  ComponentInformationTitle,
} from 'pages/AncillariesPage/ComponentsPage/DetailComponents/styles';
import { EComponentLabels } from 'pages/AncillariesPage/ComponentsPage/DetailComponents';
import { IAccessoryDetails, IAmmunitionDetails, IComponentDetails } from 'models/ancillaries';
import { ECustomFieldObjectType, ECustomFieldType } from 'models/customField';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import { usePermission } from 'hooks/usePermission';
import { EPermission } from 'models/permissions';

const LegalEntityGroupDeleteAncillaryPage = () => {
  const [isAncillaryLoading, setIsAncillaryLoading] = useState<boolean>(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
  const { setAlert, clearAlert, samePageAlert } = useAlert();
  const [accessFields, setAccessFields] = useState<string[]>([]);
  const { id, groupId, ancillaryId, ancillaryType } = useParams<{
    id: string;
    groupId: string;
    ancillaryId: string;
    ancillaryType: string;
  }>();

  const query = useQuery();
  const { t } = useTranslations();

  const { shortDateFormat } = useGlobalProperty();
  const { handleRemoveLEGroupAncillary } = useLegalEntities();
  const { navigateToGroupDetails } = useLegalEntities();
  const { hasPermissions } = usePermission();

  const type: string = query.get('type') || '';

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const {
    accessoryDetail,
    ammunitionDetail,
    componentDetail,
    getAccessoryDetail,
    getAmmunitionDetail,
    getComponentDetail,
    getCustomField,
  } = useAncillaries();

  useEffect(() => {
    setIsAncillaryLoading(true);
    (async () => {
      if (
        ancillaryId &&
        ancillaryType &&
        hasPermissions(EPermission.VIEW_LIMITED_ANCILLARIES)
      ) {
        if (ancillaryType === EArtAncillaryTypeNameLowerCase.ACCESSORY) {
          await getAccessoryDetail(ancillaryId);
        } else if (ancillaryType === EArtAncillaryTypeNameLowerCase.AMMUNITION) {
          await getAmmunitionDetail(ancillaryId);
        } else if (ancillaryType === EArtAncillaryTypeNameLowerCase.COMPONENTS) {
          await getComponentDetail(ancillaryId);
        }
      }
    })();
    setIsAncillaryLoading(false);
  }, [ancillaryId, ancillaryType]);

  const onDelete = async () => {
    setIsDeleteLoading(true);
    try {
      const result = await handleRemoveLEGroupAncillary(
        id as string,
        groupId as string,
        ancillaryId as string,
      );
      if (result) {
        navigateToGroupDetails();
      }
    } catch (e: any) {
      console.error(e);
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: t('deleteAncillaryFromGroup.error.alert'),
        variant: EAlertVariants.error,
      });
    }
    setIsDeleteLoading(false);
  };

  const parseAncillary = (
    item: IAccessoryDetails | IAmmunitionDetails | IComponentDetails,
  ) => {
    if (item.customFields) {
      item.customFields?.map((customField, index) => {
        if (
          customField.customFieldType === ECustomFieldType.BOOL &&
          customField.value === null
        ) {
          delete item?.customFields?.[index];
        }
        return false;
      });
      item.customFields = item.customFields?.filter((row) => row);
    }
    return item;
  };

  useEffect(() => {
    (async () => {
      let fields;

      if (accessoryDetail && accessoryDetail.typeUniqueId) {
        parseAncillary(accessoryDetail);
        fields = getFieldsByAccessoriesType(accessoryDetail.typeUniqueId);

        await getCustomField(ECustomFieldObjectType.AN_ACCESSORY);
      } else if (ammunitionDetail && ammunitionDetail.typeUniqueId) {
        parseAncillary(ammunitionDetail);
        fields = getFieldsByAmmunitionType(
          ammunitionDetail.typeUniqueId as EArtAmmunitionType,
        );

        await getCustomField(ECustomFieldObjectType.AN_AMMUNITION);
      } else if (componentDetail && componentDetail.typeUniqueId) {
        parseAncillary(componentDetail);
        fields = getFieldsByComponentType(componentDetail.typeUniqueId as EArtComponentType);

        await getCustomField(ECustomFieldObjectType.AN_COMPONENT);
      }

      setAccessFields(fields || []);
    })();
  }, [accessoryDetail, ammunitionDetail, componentDetail]);

  const showField = (fieldName: string) => accessFields.includes(fieldName);

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.legalEntity.name'), route: ERouteLinks.LegalEntities },
          {
            label:
              type === ELegalEntityTypes.individual
                ? t('legalEntities.individual.accordion.label')
                : t('legalEntities.organization.accordion.label'),
            route: `${ERouteLinks.LegalEntities}?expand=${
              type === ELegalEntityTypes.individual
                ? ELegalEntityTypes.individual
                : ELegalEntityTypes.organisation
            }`,
          },
          {
            label: t('legalEntityDetails.legalEntityDetails.text'),
            route: `${ERouteLinks.ViewLegalEntity.replace(
              ':id',
              id as string,
            )}?type=${type}&expand=groups`,
          },
          {
            label: t('groupDetails.header'),
            route: `${ERouteLinks.ViewLegalEntityGroup.replace(':id', id as string).replace(
              ':groupId',
              groupId as string,
            )}?type=${type}&expand=groups`,
          },
          t('deleteAncillaryFromGroup.header'),
        ]}
      />
      <>
        <StyledPageTitle variant="h4">{t('deleteAncillaryFromGroup.header')}</StyledPageTitle>

        <Box my={3}>
          <Alert
            text={t('deleteAncillaryFromGroup.info.text')}
            variant={EAlertVariants.warning}
          />
        </Box>

        {isAncillaryLoading ? (
          <Loader isLoading={isAncillaryLoading} />
        ) : (
          <>
            {accessoryDetail && (
              <DetailsTemplate title={accessoryDetail?.type} pageType={EDetailsPageTypes.view}>
                <AccessoryInformationContainer item xs={12} lg={12} container>
                  <Grid item xs={12} lg={12} container direction="column">
                    <AccessoryInformationTitle variant="subtitle1">
                      {t(EAccessoryLabels.accessoryGeneralInformation)}
                    </AccessoryInformationTitle>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAccessoryLabels.accessoryType)}
                    </Typography>
                    <Typography variant="body2">{accessoryDetail?.type}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                    <Typography
                      variant="subtitle2"
                      sx={{ marginBottom: '4px', textTransform: 'uppercase' }}
                    >
                      {t(EAccessoryLabels.artId)}
                    </Typography>
                    <Typography variant="body2" style={{ textTransform: 'capitalize' }}>
                      {accessoryDetail?.artId}
                    </Typography>
                  </Grid>
                  {showField('manufacturer') && (
                    <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {t(EAccessoryLabels.manufacturer)}
                      </Typography>
                      <Typography variant="body2">
                        {emptyFieldHolder(accessoryDetail?.manufacturer)}
                      </Typography>
                    </Grid>
                  )}
                  {showField('calibre') && (
                    <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {t(EAccessoryLabels.calibre)}
                      </Typography>
                      <Typography variant="body2">
                        {emptyFieldHolder(accessoryDetail?.calibre)}
                      </Typography>
                    </Grid>
                  )}
                  {showField('hostType') && (
                    <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {t(EAccessoryLabels.hostType)}
                      </Typography>
                      <Typography variant="body2">
                        {emptyFieldHolder(accessoryDetail?.hostType)}
                      </Typography>
                    </Grid>
                  )}
                  {showField('proofHouse') && (
                    <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {t(EAccessoryLabels.proofHouse)}
                      </Typography>
                      <Typography variant="body2">
                        {emptyFieldHolder(accessoryDetail?.proofHouse)}
                      </Typography>
                    </Grid>
                  )}
                  {showField('composition') && (
                    <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {t(EAccessoryLabels.composition)}
                      </Typography>
                      <Typography variant="body2">
                        {emptyFieldHolder(accessoryDetail?.composition)}
                      </Typography>
                    </Grid>
                  )}
                  {showField('magnification') && (
                    <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {t(EAccessoryLabels.magnification)}
                      </Typography>
                      <Typography variant="body2">
                        {emptyFieldHolder(accessoryDetail?.magnification)}
                      </Typography>
                    </Grid>
                  )}
                  {showField('utility') && (
                    <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {t(EAccessoryLabels.utility)}
                      </Typography>
                      <Typography variant="body2">
                        {emptyFieldHolder(accessoryDetail?.utility)}
                      </Typography>
                    </Grid>
                  )}
                  {showField('reticle') && (
                    <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {t(EAccessoryLabels.reticle)}
                      </Typography>
                      <Typography variant="body2">
                        {emptyFieldHolder(accessoryDetail?.reticle)}
                      </Typography>
                    </Grid>
                  )}
                  {showField('lightcolour') && (
                    <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {t(EAccessoryLabels.lightcolour)}
                      </Typography>
                      <Typography variant="body2">
                        {emptyFieldHolder(accessoryDetail?.lightcolour)}
                      </Typography>
                    </Grid>
                  )}
                  {showField('capacity') && (
                    <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {t(EAccessoryLabels.capacity)}
                      </Typography>
                      <Typography variant="body2">
                        {emptyFieldHolder(accessoryDetail?.capacity)}
                      </Typography>
                    </Grid>
                  )}
                </AccessoryInformationContainer>
                <AccessoryInformationContainer item xs={12} lg={12} container borderHidden>
                  <Grid item xs={12} lg={12} container direction="column">
                    <AccessoryInformationTitle variant="subtitle1">
                      {t(EAccessoryLabels.accessoryDetailedInformation)}
                    </AccessoryInformationTitle>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAccessoryLabels.firecycleID)}
                    </Typography>
                    <Typography variant="body2">
                      {emptyFieldHolder(accessoryDetail?.fireCycleId)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAccessoryLabels.serialNumber)}
                    </Typography>
                    <Typography variant="body2">
                      {emptyFieldHolder(accessoryDetail?.serialNumber)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAccessoryLabels.componentState)}
                    </Typography>
                    <Typography variant="body2">
                      <Chips
                        text={emptyFieldHolder(accessoryDetail?.state)}
                        variant={
                          renderDataColorByValues(
                            accessoryDetail.activityUniqueId,
                            accessoryDetail.legalityUniqueId,
                            accessoryDetail.stateUniqueId,
                          ).stateField
                        }
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAccessoryLabels.exhibitNumber)}
                    </Typography>
                    <Typography variant="body2">
                      {emptyFieldHolder(accessoryDetail?.exhibitNumber)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAccessoryLabels.currentOwner)}
                    </Typography>
                    <Typography variant="body2">
                      {emptyFieldHolder(accessoryDetail?.ownerName)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAccessoryLabels.currentKeeper)}
                    </Typography>
                    <Typography variant="body2">
                      {emptyFieldHolder(accessoryDetail?.keeperName)}
                    </Typography>
                  </Grid>
                </AccessoryInformationContainer>
              </DetailsTemplate>
            )}

            {ammunitionDetail && (
              <DetailsTemplate
                title={ammunitionDetail?.type}
                pageType={EDetailsPageTypes.view}
              >
                <>
                  <AmmunitionInformationContainer item xs={12} lg={12} container>
                    <Grid item xs={12} lg={12} container direction="column">
                      <AmmunitionInformationTitle variant="subtitle1">
                        {t(EAmmunitionLabels.ammunitionGeneralInformation)}
                      </AmmunitionInformationTitle>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {t(EAmmunitionLabels.ammunitionType)}
                      </Typography>
                      <Typography variant="body2">{ammunitionDetail?.type}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                      <Typography
                        variant="subtitle2"
                        sx={{ marginBottom: '4px', textTransform: 'uppercase' }}
                      >
                        {t(EAmmunitionLabels.artId)}
                      </Typography>
                      <Typography variant="body2" style={{ textTransform: 'capitalize' }}>
                        {ammunitionDetail?.artId}
                      </Typography>
                    </Grid>
                    {showField('productionDate') && (
                      <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t(EAmmunitionLabels.productionDate)}
                        </Typography>
                        <Typography variant="body2">
                          {formatDate(ammunitionDetail?.productionDate, shortDateFormat)}
                        </Typography>
                      </Grid>
                    )}
                    {showField('manufacturer') && (
                      <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t(EAmmunitionLabels.ammunitionManufacturer)}
                        </Typography>
                        <Typography variant="body2">
                          {ammunitionDetail?.manufacturer}
                        </Typography>
                      </Grid>
                    )}
                    {showField('factory') && (
                      <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t(EAmmunitionLabels.factory)}
                        </Typography>
                        <Typography variant="body2">{ammunitionDetail?.factory}</Typography>
                      </Grid>
                    )}
                    {showField('calibreGauge') && (
                      <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t(EAmmunitionLabels.calibreGauge)}
                        </Typography>
                        <Typography variant="body2">{ammunitionDetail?.calibre}</Typography>
                      </Grid>
                    )}
                    {showField('functionalType') && (
                      <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t(EAmmunitionLabels.functionalType)}
                        </Typography>
                        <Typography variant="body2">
                          {ammunitionDetail?.functionalType}
                        </Typography>
                      </Grid>
                    )}
                    {showField('cartridgeComposition') && (
                      <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t(EAmmunitionLabels.cartridgeComposition)}
                        </Typography>
                        <Typography variant="body2">
                          {ammunitionDetail?.cartridgeComposition}
                        </Typography>
                      </Grid>
                    )}
                    {showField('colourOfPrimerSealant') && (
                      <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t(EAmmunitionLabels.colourOfPrimerSealant)}
                        </Typography>
                        <Typography variant="body2">
                          {ammunitionDetail?.colourOfPrimerSealant}
                        </Typography>
                      </Grid>
                    )}
                    {showField('projectileShape') && (
                      <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t(EAmmunitionLabels.projectileShape)}
                        </Typography>
                        <Typography variant="body2">
                          {ammunitionDetail?.projectileShape}
                        </Typography>
                      </Grid>
                    )}
                    {showField('headStampYearMarking') && (
                      <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t(EAmmunitionLabels.headStampYearMarking)}
                        </Typography>
                        <Typography variant="body2">
                          {ammunitionDetail?.headstampYearMarking}
                        </Typography>
                      </Grid>
                    )}
                    {showField('alphanumericalMarkins') && (
                      <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t(EAmmunitionLabels.alphanumericalMarkins)}
                        </Typography>
                        <Typography variant="body2">
                          {ammunitionDetail?.alphanumericalMarkins}
                        </Typography>
                      </Grid>
                    )}
                  </AmmunitionInformationContainer>
                  <AmmunitionInformationContainer item xs={12} lg={12} container borderHidden>
                    <Grid item xs={12} lg={12} container direction="column">
                      <AmmunitionInformationTitle variant="subtitle1">
                        {t(EAmmunitionLabels.ammunitionDetailedInformation)}
                      </AmmunitionInformationTitle>
                    </Grid>
                    {showField('fireCycleId') && (
                      <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t(EAmmunitionLabels.firecycleID)}
                        </Typography>
                        <Typography variant="body2">
                          {emptyFieldHolder(ammunitionDetail?.fireCycleId)}
                        </Typography>
                      </Grid>
                    )}
                    {showField('lotNumber') && (
                      <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t(EAmmunitionLabels.lotNumber)}
                        </Typography>
                        <Typography variant="body2">
                          {emptyFieldHolder(ammunitionDetail?.lotNumber)}
                        </Typography>
                      </Grid>
                    )}
                    {showField('batchNumber') && (
                      <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t(EAmmunitionLabels.batchNumber)}
                        </Typography>
                        <Typography variant="body2">
                          {emptyFieldHolder(accessoryDetail?.batchNumber)}
                        </Typography>
                      </Grid>
                    )}
                    {showField('state') && (
                      <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t(EAmmunitionLabels.ammunitionState)}
                        </Typography>
                        <Typography variant="body2">
                          <Chips
                            text={emptyFieldHolder(ammunitionDetail?.state)}
                            variant={
                              renderDataColorByValues(
                                ammunitionDetail.activityUniqueId,
                                ammunitionDetail.legalityUniqueId,
                                ammunitionDetail.stateUniqueId,
                              ).stateField
                            }
                          />
                        </Typography>
                      </Grid>
                    )}
                    {showField('ownerName') && (
                      <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t(EAmmunitionLabels.currentOwner)}
                        </Typography>
                        <Typography variant="body2">
                          {emptyFieldHolder(ammunitionDetail?.ownerName)}
                        </Typography>
                      </Grid>
                    )}
                    {showField('keeperName') && (
                      <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t(EAmmunitionLabels.currentKeeper)}
                        </Typography>
                        <Typography variant="body2">
                          {emptyFieldHolder(ammunitionDetail?.keeperName)}
                        </Typography>
                      </Grid>
                    )}
                    {showField('manufacturedAt') && (
                      <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t(EAmmunitionLabels.manufacturedAt)}
                        </Typography>
                        <Typography variant="body2">
                          {emptyFieldHolder(ammunitionDetail?.manufacturedAt)}
                        </Typography>
                      </Grid>
                    )}
                    {showField('expiryDate') && (
                      <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t(EAmmunitionLabels.expiryDate)}
                        </Typography>
                        <Typography variant="body2">
                          {formatDate(ammunitionDetail?.expiryDate, shortDateFormat)}
                        </Typography>
                      </Grid>
                    )}
                    {showField('exhibitNumber') && (
                      <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t(EAmmunitionLabels.exhibitNumber)}
                        </Typography>
                        <Typography variant="body2">
                          {emptyFieldHolder(ammunitionDetail?.exhibitNumber)}
                        </Typography>
                      </Grid>
                    )}
                  </AmmunitionInformationContainer>
                </>
              </DetailsTemplate>
            )}

            {componentDetail && (
              <DetailsTemplate title={componentDetail?.type} pageType={EDetailsPageTypes.view}>
                <ComponentInformationContainer item xs={12} lg={12} container>
                  <Grid item xs={12} lg={12} container direction="column">
                    <ComponentInformationTitle variant="subtitle1">
                      {t(EComponentLabels.componentGeneralInformation)}
                    </ComponentInformationTitle>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EComponentLabels.componentType)}
                    </Typography>
                    <Typography variant="body2">{componentDetail?.type}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                    <Typography
                      variant="subtitle2"
                      sx={{ marginBottom: '4px', textTransform: 'uppercase' }}
                    >
                      {t(EComponentLabels.artId)}
                    </Typography>
                    <Typography variant="body2" style={{ textTransform: 'capitalize' }}>
                      {componentDetail?.artId}
                    </Typography>
                  </Grid>
                  {showField('manufacturer') && (
                    <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {t(EComponentLabels.manufacturer)}
                      </Typography>
                      <Typography variant="body2">
                        {emptyFieldHolder(componentDetail?.manufacturer)}
                      </Typography>
                    </Grid>
                  )}
                  {showField('calibre') && (
                    <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {t(EComponentLabels.calibre)}
                      </Typography>
                      <Typography variant="body2">
                        {emptyFieldHolder(componentDetail?.calibre)}
                      </Typography>
                    </Grid>
                  )}
                  {showField('composition') && (
                    <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {t(EComponentLabels.composition)}
                      </Typography>
                      <Typography variant="body2">
                        {emptyFieldHolder(componentDetail?.composition)}
                      </Typography>
                    </Grid>
                  )}
                  {showField('proofHouse') && (
                    <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {t(EComponentLabels.proofHouse)}
                      </Typography>
                      <Typography variant="body2">
                        {emptyFieldHolder(componentDetail?.proofHouse)}
                      </Typography>
                    </Grid>
                  )}
                </ComponentInformationContainer>
                <ComponentInformationContainer item xs={12} lg={12} container borderHidden>
                  <Grid item xs={12} lg={12} container direction="column">
                    <ComponentInformationTitle variant="subtitle1">
                      {t(EComponentLabels.componentDetailedInformation)}
                    </ComponentInformationTitle>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EComponentLabels.firecycleID)}
                    </Typography>
                    <Typography variant="body2">
                      {emptyFieldHolder(componentDetail?.fireCycleId)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EComponentLabels.serialNumber)}
                    </Typography>
                    <Typography variant="body2">
                      {emptyFieldHolder(componentDetail?.serialNumber)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EComponentLabels.componentState)}
                    </Typography>
                    <Typography variant="body2">
                      <Chips
                        text={emptyFieldHolder(componentDetail?.state)}
                        variant={
                          renderDataColorByValues(
                            componentDetail.activityUniqueId,
                            componentDetail.legalityUniqueId,
                            componentDetail.stateUniqueId,
                          ).stateField
                        }
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EComponentLabels.exhibitNumber)}
                    </Typography>
                    <Typography variant="body2">
                      {emptyFieldHolder(componentDetail?.exhibitNumber)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EComponentLabels.currentOwner)}
                    </Typography>
                    <Typography variant="body2">
                      {emptyFieldHolder(componentDetail?.ownerName)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EComponentLabels.currentKeeper)}
                    </Typography>
                    <Typography variant="body2">
                      {emptyFieldHolder(componentDetail?.keeperName)}
                    </Typography>
                  </Grid>
                </ComponentInformationContainer>
                <ComponentInformationContainer item xs={12} lg={12} container borderHidden>
                  <Grid item xs={12} lg={12} container direction="column">
                    <ComponentInformationTitle variant="subtitle1">
                      {t(EComponentLabels.firearmInformation)}
                    </ComponentInformationTitle>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EComponentLabels.firearmManufacturer)}
                    </Typography>
                    <Typography variant="body2">{componentDetail?.frtManufacturer}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EComponentLabels.firearmReferenceNo)}
                    </Typography>
                    <Typography variant="body2">{componentDetail?.frtReferenceNo}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EComponentLabels.firearmFirecycleType)}
                    </Typography>
                    <Typography variant="body2">
                      {emptyFieldHolder(componentDetail?.frtType)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EComponentLabels.firearmMake)}
                    </Typography>
                    <Typography variant="body2">
                      {emptyFieldHolder(componentDetail?.frtMake)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EComponentLabels.firearmModel)}
                    </Typography>
                    <Typography variant="body2">
                      {emptyFieldHolder(componentDetail?.frtModel)}
                    </Typography>
                  </Grid>
                </ComponentInformationContainer>
              </DetailsTemplate>
            )}
          </>
        )}
        {samePageAlert && (
          <Box mt={2}>
            <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
          </Box>
        )}
        <Grid container sx={{ mt: 2 }} spacing={2}>
          <Grid item xs={6} lg={3}>
            <Button
              fullWidth
              id="delete-group-yes-button"
              label={t('general.yes.button')}
              variant={EButtonVariants.contained}
              sx={{ mr: 1 }}
              onClick={async () => onDelete()}
              isLoading={isDeleteLoading}
            />
          </Grid>

          <Grid item xs={6} lg={3}>
            <Button
              fullWidth
              id="delete-group-no-button"
              label={t('general.no.button')}
              variant={EButtonVariants.outlined}
              onClick={navigateToGroupDetails}
            />
          </Grid>
        </Grid>
      </>
    </>
  );
};

export { LegalEntityGroupDeleteAncillaryPage };
