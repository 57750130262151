import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { Box, Typography } from '@mui/material';
import { DetailsTemplate, EDetailsPageTypes } from 'components/templates/DetailsTemplate';
import { EDictionaryTypes, IDictionary } from 'models/dictionary';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import { useQuery } from 'hooks/useQuery';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import { ERouteLinks } from 'models/route';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'hooks/useTranslations';
import { useLang } from 'models/langContext';
import { ILegalEntityCustomField } from 'components/organisms/LegalEntityDetails';
import { ECustomFieldType } from 'models/customField';
import { EPermission } from 'models/permissions';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { formatDate } from 'utils/date';
import { emptyFieldHolder } from 'components/molecules/CardList';

export interface IOrgLegalEntityData {
  city: string | null;
  organizationName: string;
  organizationTypeUniqueId: string;
  departmentName: string;
  uniqueId: string;
  visibilityUniqueId: string;
  hasAssignedUser: boolean;
  customFields?: ILegalEntityCustomField[];
  version: string;

  [key: string]: any;
}

interface ILegalEntityDetailsProps {
  legalEntity: IOrgLegalEntityData;
  pageType?: EDetailsPageTypes;
}

const OrgLegalEntityDetails = ({
  legalEntity,
  pageType = EDetailsPageTypes.view,
}: ILegalEntityDetailsProps) => {
  const navigate = useNavigate();
  const query = useQuery();
  const type: string = query.get('type') || '';
  const { id = '' } = useParams<{ id: string }>();
  const { organizationType, loadDictionaries, legalVisibility } = useLegalEntities();
  const { t } = useTranslations();
  const { selectedLanguage, defaultLanguage } = useLang();
  const { shortDateFormat, getDateFormat } = useGlobalProperty();

  useEffect(() => {
    (async function init() {
      await loadDictionaries([
        EDictionaryTypes.OrganizationType,
        EDictionaryTypes.LegalVisibility,
      ]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  useEffect(() => {
    getDateFormat();
  }, []);

  const renderCustomFieldData = (customField: ILegalEntityCustomField) => {
    if (customField.customFieldType === ECustomFieldType.BOOL) {
      return customField.value
        ? t('customFields.value.yes.label')
        : t('customFields.value.no.label');
    }
    if (customField.customFieldType === ECustomFieldType.DATE) {
      return formatDate(customField.value, shortDateFormat);
    }
    return customField.value;
  };

  const renderItemDetail = (dictionary: IDictionary[] | null, dictionaryItemId: string) => {
    const legal: IOrgLegalEntityData = legalEntity;
    if (legal) {
      return dictionary?.find(
        (item: IDictionary) =>
          item?.uniqueId === legal[dictionaryItemId as keyof IOrgLegalEntityData],
      )?.name;
    }
    return '';
  };
  return (
    <div>
      {legalEntity && (
        <DetailsTemplate
          deletePermissions={[EPermission.DELETE_LE]}
          editPermissions={[EPermission.MANAGE_LE]}
          title={legalEntity.organizationName}
          pageType={pageType}
          redirectToEditView={() =>
            navigate(`${ERouteLinks.EditLegalEntity.replace(':id', id)}?type=${type}`)
          }
          redirectToView={() =>
            navigate(`${ERouteLinks.DeleteLegalEntity.replace(':id', id)}?type=${type}`)
          }
        >
          {' '}
          <Grid item xs={6} lg={3} container direction="column">
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('deleteLegalEntity.legalEntityType.label')}
            </Typography>
            <Box>
              <Chips
                id="legalEntityDetails-legalEntityType"
                text={
                  type === 'individual'
                    ? t('legalEntityDetails.legalEntityType.individual.chip.label')
                    : t('legalEntityDetails.legalEntityType.organisation.chip.label')
                }
                variant={EChipsVariants.primary}
              />
            </Box>
          </Grid>
          <Grid item xs={6} lg={3} container direction="column">
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('legalEntityDetails.name.label')}
            </Typography>
            <Typography id="legalEntityDetails-name" variant="body2">
              {legalEntity.organizationName}
            </Typography>
          </Grid>
          <Grid item xs={6} lg={3} container direction="column">
            <Grid>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('legalEntityDetails.organizationType.label')}
              </Typography>
            </Grid>
            <Typography id="legalEntityDetails-organizationType" variant="body2">
              {renderItemDetail(organizationType, 'organizationTypeUniqueId')}
            </Typography>
          </Grid>
          <Grid item xs={6} lg={3} container direction="column">
            <Grid>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('legalEntityDetails.departmentName.label')}
              </Typography>
            </Grid>
            <Typography
              variant="body2"
              id="legalEntityDetails-departmentName"
              sx={{ wordBreak: 'break-all' }}
            >
              {legalEntity.departmentName}
            </Typography>
          </Grid>
          <Grid item xs={6} lg={3} container direction="column">
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('deleteLegalEntity.visibility.label')}
            </Typography>
            <Box>
              <Chips
                id="legalEntityDetails-visibility"
                text={renderItemDetail(legalVisibility, 'visibilityUniqueId')}
                variant={EChipsVariants.success}
              />
            </Box>
          </Grid>
          {legalEntity?.customFields?.map((customField) => (
            <Grid
              item
              xs={6}
              lg={3}
              container
              direction="column"
              key={customField.customFieldUniqueId}
            >
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {customField?.translations?.filter(
                  (translation) => selectedLanguage?.uniqueId === translation.languageUniqueId,
                )?.[0]?.name ||
                  customField?.translations?.filter(
                    (translation) =>
                      defaultLanguage?.uniqueId === translation.languageUniqueId,
                  )?.[0]?.name}
              </Typography>
              <Typography variant="body2">
                {emptyFieldHolder(renderCustomFieldData(customField))}
              </Typography>
            </Grid>
          ))}
        </DetailsTemplate>
      )}
    </div>
  );
};

export { OrgLegalEntityDetails };
