import { ISortOptions } from 'models/table';

interface IQueryString {
  [key: string]: any;
}

export const queryString = (params: IQueryString) => {
  const filtered: IQueryString = {};
  Object.keys(params).map((item) => {
    if (params[item] !== undefined && params[item] !== '') {
      filtered[item] = params[item];
    }
    return true;
  });

  return Object.keys(filtered)
    .map((key) => `${key}=${encodeURIComponent(filtered[key])}`)
    .join('&');
};

export const queryStringWithSort = (
  params: IQueryString,
  sort: ISortOptions | null | undefined,
) => {
  if (sort) {
    return queryString({ ...params, ...{ sort: `${sort.property},${sort.direction}` } });
  }
  return queryString(params);
};

export const queryStringOfSort = (sort: ISortOptions | null | undefined) => {
  if (sort) {
    return queryString({ sort: `${sort.property},${sort.direction}` });
  }
  return '';
};
