import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuditLog } from 'pages/AuditLogPage/hooks';
import { ERouteLinks } from 'models/route';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { useTranslations } from 'hooks/useTranslations';
import { StyledPageTitle } from 'theme/styles';
import Grid from '@mui/material/Grid';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import { AuditLogDetails } from 'pages/AuditLogPage/AuditLogViewPage/styles';
import { Box, Typography } from '@mui/material';
import { emptyFieldHolder } from 'components/molecules/CardList';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import { EventTypes, getEventTypeColor, getRoleNames } from 'pages/AuditLogPage/helpers';
import { formatDate } from 'utils/date';
import { useRoles } from 'pages/RolesPage/hooks';
import { useGlobalProperty } from 'models/globalPropertyContext';
import FirearmEventDetails from 'pages/AuditLogPage/AuditLogViewPage/FirearmEventDetails';
import TransactionEventDetails from 'pages/AuditLogPage/AuditLogViewPage/TransactionEventDetails';
import AdministrationRoleEventDetails from 'pages/AuditLogPage/AuditLogViewPage/AdministrationRoleEventDetails';
import AdministrationDictionaryEventDetails from 'pages/AuditLogPage/AuditLogViewPage/AdministrationDictionaryEventDetails';
import CustomFieldEventDetails from 'pages/AuditLogPage/AuditLogViewPage/CustomFieldEventDetails';
import LegalEntitiesEventDetails from 'pages/AuditLogPage/AuditLogViewPage/LegalEntitiesEventDetails';
import AncillariesEventDetails from 'pages/AuditLogPage/AuditLogViewPage/AncillariesEventDetails';
import { Alert } from 'components/atoms/Alert';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import AdministrationUserEventDetails from 'pages/AuditLogPage/AuditLogViewPage/AdministrationUserEventDetails';
import ApiKeyEventDetails from 'pages/AuditLogPage/AuditLogViewPage/ApiKeyEventDetails';

enum AuditLogModules {
  FIREARMS = '20fbd0b5-8668-4c03-8eea-cc92c0f4c200',
  TRANSACTIONS = 'f4517045-0c4b-4a14-be9e-a327f5e7a8cf',
  ADMINISTRATION = 'c6746456-24c6-4c53-b242-53ab63c86864',
  CUSTOM_FIELDS = 'e9cb112e-6645-46ea-a302-5ce7c44910fa',
  LEGAL_ENTITIES = '973f6d22-b2e7-43bd-929c-12c7c747dcd1',
  ANCILLARIES = '5bdfb1dc-4315-4de9-bc27-b08237fb6f7c',
  API_KEYS = 'daaca311-edd1-46f3-9ec4-5d0b3ddb2e77',
}

enum AdministrationObjectTypes {
  ROLE = 'ROLE',
  DICTIONARY = 'DICTIONARY',
  USER = 'USER',
  APIKEY = 'APIKEY',
}

export enum EAuditLogEvents {
  DELETE_LE_INDIVIDUAL = '5cf2444a-c8e0-412e-984c-ff0f684d4554',
  DELETE_LE_ORGANIZATION = 'e29a490a-11fe-46f1-acab-7396bc2379be',
  DELETE_CUSTOM_FIELD = 'bea587bd-5645-4689-835a-a97d272913b5',
  ADD_CUSTOM_FIELD = '5cddb97c-06da-48fa-879c-b3aa773ea781',
  ADD_USER = 'f9b2148a-53c1-4192-8286-675e4850d636',
  ADD_API_KEY = 'd69f11cd-74a5-463a-9a85-563a097f2759',
  DELETE_API_KEY = 'd317e4c6-283d-4ebb-a855-64ba93f213e2',
  ADD_FIREARM = '68e1ee27-9021-4c14-b4d7-10943342228d',
  DISABLE_FIREARM = 'ffcd97a3-4acb-4fb5-b61f-91c4b98355b9',
  ADD_FIREARM_TO_FRT_LOCAL = 'e0c00dc4-eba8-4596-9963-df01c65ab18b',
  EDIT_FIREARM_FROM_FRT_LOCAL = '2348ab2d-3818-489b-87de-80039637d9a6',
  ADD_LE_INDIVIDUAL = 'a47b34bf-029b-44a9-b457-c267bdff93d8',
  ADD_LE_ORGANIZATION = '5e1e0109-7431-4ac9-80d7-d2cccfb0e375',
  DELETE_USER = 'a7dcb7d6-da98-4fc0-86ff-bdb0f1c5e5ea',
}

const AuditLogViewPage = () => {
  const { id = '' } = useParams<{ id: string }>();
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { getAuditLogItemData, auditLog } = useAuditLog();
  const { getRolesData, roles } = useRoles();
  const { longDateFormat, getDateFormat } = useGlobalProperty();
  const { samePageAlert, auditLogSamePageAlert, clearAlert } = useAlert();

  useEffect(() => {
    (async () => {
      getRolesData(1, 100);
      getDateFormat();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
  }, []);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    [],
  );

  useEffect(() => {
    clearAlert(ActionTypes.CLEAR_AUDIT_LOG_SAME_PAGE_ALERT);
  }, []);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_AUDIT_LOG_SAME_PAGE_ALERT);
    },
    [],
  );

  const roleNamesArr = auditLog ? getRoleNames(auditLog?.author?.roleIds, roles) : [];

  useEffect(() => {
    if (id) {
      (async () => {
        await getAuditLogItemData(id);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderDetails = () => {
    if (
      auditLog &&
      (auditLog?.eventNameUniqueId === EAuditLogEvents.DELETE_LE_INDIVIDUAL ||
        auditLog?.eventNameUniqueId === EAuditLogEvents.DELETE_LE_ORGANIZATION)
    ) {
      return <LegalEntitiesEventDetails auditLog={auditLog} />;
    }
    if (auditLog && auditLog?.eventNameUniqueId === EAuditLogEvents.DELETE_CUSTOM_FIELD) {
      return <CustomFieldEventDetails auditLog={auditLog} />;
    }
    if (auditLog && auditLog?.eventNameUniqueId === EAuditLogEvents.DELETE_API_KEY) {
      return <ApiKeyEventDetails auditLog={auditLog} />;
    }
    if (auditLog && auditLog?.eventNameUniqueId === EAuditLogEvents.DISABLE_FIREARM) {
      return <FirearmEventDetails auditLog={auditLog} />;
    }
    if (auditLog && auditLog?.eventNameUniqueId === EAuditLogEvents.DELETE_USER) {
      return <AdministrationUserEventDetails auditLog={auditLog} />;
    }
    if (!auditLog?.objectUniqueId) {
      return null;
    }
    switch (auditLog.moduleUniqueId) {
      case AuditLogModules.FIREARMS:
        return <FirearmEventDetails auditLog={auditLog} />;

      case AuditLogModules.ANCILLARIES:
        return <AncillariesEventDetails auditLog={auditLog} />;

      case AuditLogModules.TRANSACTIONS:
        return <TransactionEventDetails auditLog={auditLog} />;

      case AuditLogModules.CUSTOM_FIELDS:
        return <CustomFieldEventDetails auditLog={auditLog} />;

      case AuditLogModules.ADMINISTRATION:
        if (auditLog?.objectType === AdministrationObjectTypes.ROLE) {
          return <AdministrationRoleEventDetails auditLog={auditLog} />;
        }
        if (auditLog?.objectType === AdministrationObjectTypes.DICTIONARY) {
          return <AdministrationDictionaryEventDetails auditLog={auditLog} />;
        }
        if (auditLog?.objectType === AdministrationObjectTypes.USER) {
          return <AdministrationUserEventDetails auditLog={auditLog} />;
        }
        return null;

      case AuditLogModules.API_KEYS:
        return <ApiKeyEventDetails auditLog={auditLog} />;

      case AuditLogModules.LEGAL_ENTITIES:
        return <LegalEntitiesEventDetails auditLog={auditLog} />;

      default:
        return null;
    }
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('user.breadcrumbs.administration'), route: ERouteLinks.Administration },
          {
            label: t('auditLogDetails.breadcrumbs.auditLog'),
            route: ERouteLinks.AuditLogPage,
          },
          t('auditLogDetails.breadcrumbs.eventDetails'),
        ]}
      />
      <StyledPageTitle variant="h4">
        {t('auditLogDetails.common.eventDetails')}
      </StyledPageTitle>

      <Grid container>
        <Grid item xs={12} lg={2}>
          <Button
            fullWidth
            label={t('general.back.link')}
            variant={EButtonVariants.outlined}
            size={EButtonSizes.medium}
            icon={EIconTypes.left}
            onClick={() => navigate(-1)}
          />
        </Grid>
      </Grid>

      {auditLog && (
        <>
          <AuditLogDetails container>
            <Grid item xs={12} sm={6} lg={3} p={2}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('auditLogDetails.common.eventAuthor')}
              </Typography>
              <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
                {`${auditLog?.author?.firstName} ${auditLog?.author?.lastName}`}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={3} p={2}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('auditLogDetails.common.roleName')}
              </Typography>
              <Typography variant="body2">
                {roleNamesArr.map((roleName) => (
                  <Chips text={roleName as string} variant={EChipsVariants.primary} />
                ))}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={3} p={2}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('auditLogDetails.common.phone')}
              </Typography>
              <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
                {emptyFieldHolder(auditLog?.author?.phoneNumber?.value)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={3} p={2}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('auditLogDetails.common.email')}
              </Typography>
              <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
                {emptyFieldHolder(auditLog?.author?.email)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={3} p={2}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('auditLogDetails.common.module')}
              </Typography>
              <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
                {emptyFieldHolder(auditLog?.module)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={3} p={2}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('auditLogDetails.common.eventType')}
              </Typography>
              <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
                {auditLog?.eventType ? (
                  <Chips
                    text={auditLog.eventType}
                    variant={getEventTypeColor(auditLog.eventTypeUniqueId as EventTypes)}
                  />
                ) : (
                  '-'
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={3} p={2}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('auditLogDetails.common.eventName')}
              </Typography>
              <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
                {emptyFieldHolder(auditLog.eventName)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={3} p={2}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('auditLogDetails.common.eventDate')}
              </Typography>
              <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
                {formatDate(auditLog.eventDate, longDateFormat)}
              </Typography>
            </Grid>
          </AuditLogDetails>
          {renderDetails()}
          {samePageAlert && (
            <Box sx={{ marginBottom: '16px' }}>
              <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
            </Box>
          )}
          {auditLogSamePageAlert && (
            <Box sx={{ marginBottom: '16px' }}>
              <Alert
                text={auditLogSamePageAlert.text}
                variant={auditLogSamePageAlert.variant}
              />
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default AuditLogViewPage;
