import { ITableColumns, ITableDataSource } from 'models/table';
import {
  IGlobalSearchAccessory,
  IGlobalSearchAmmunition,
  IGlobalSearchComponent,
  IGlobalSearchFirearm,
  IGlobalSearchLEIndividual,
  IGlobalSearchLEOrganization,
  IGlobalSearchTransaction,
  IGlobalSearchUser,
} from 'models/dashboard';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import React from 'react';
import { emptyFieldHolder } from 'components/molecules/CardList';
import { Link } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import { ELegalEntityTypes } from 'constants/LegalEntityTypes';

export const globalSearchUsersToDataSource = (
  users: IGlobalSearchUser[],
): ITableDataSource[] =>
  users.map((user) => ({
    id: user.uniqueId,
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    phoneNumber: user.phoneNumber,
  }));

interface IActionTableColumns {
  handleView: (arg: string) => void;
  renderHighlight: (arg: string) => '-' | JSX.Element;
}

export const globalSearchUsersTableColumns = ({
  renderHighlight,
}: IActionTableColumns): ITableColumns[] => [
  {
    id: '4eaea4ee-d36f-400b-895b-231c81f091cf',
    key: 'email',
    title: 'users.usersTable.email.column',
    render: (data) => renderHighlight(data as string),
  },
  {
    id: '4eaea4ee-d36f-410b-895b-231c81f091cf',
    key: 'firstName',
    title: 'users.usersTable.firstName.column',
    render: (data) => renderHighlight(data as string),
  },
  {
    id: '4eaea4ee-d36f-420b-895b-231c81f091cf',
    key: 'lastName',
    title: 'users.usersTable.lastName.column',
    render: (data) => renderHighlight(data as string),
  },
  {
    id: '4eaea4ee-d36f-430b-895b-231c81f091cf',
    key: 'phoneNumber',
    title: 'users.usersTable.phoneNumber.column',
    width: '170',
    render: (data) => renderHighlight(data as string),
  },
  {
    id: '4eaea4ee-d36f-500b-895b-231c81f091cf',
    key: '',
    title: 'users.usersTable.actions.column',
    isSortable: false,
    width: '60px',
    align: 'center',
    isCenterTableHead: true,
    render: (_data, props) => (
      <Link to={ERouteLinks.ViewUser.replace(':id', props.id)}>
        <Button variant={EButtonVariants.outlined} tableButton={true} icon={EIconTypes.view} />
      </Link>
    ),
  },
];

export const globalSearchFirearmsToDataSource = (
  firearms: IGlobalSearchFirearm[],
): ITableDataSource[] =>
  firearms.map((firearm) => ({
    id: firearm.uniqueId,
    serialNumber: firearm.serialNumber,
    fireCycleId: firearm.fireCycleId,
    make: firearm.make,
    model: firearm.model,
    manufacturer: firearm.manufacturer,
    calibre: firearm.calibre,
    ownerName: firearm.ownerName,
    keeperName: firearm.keeperName,
  }));

export const globalSearchFirearmsTableColumns = ({
  renderHighlight,
}: IActionTableColumns): ITableColumns[] => [
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f610',
    key: 'fireCycleId',
    title: 'FireCycle ID',
    render: (data) => renderHighlight(data as string),
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f510',
    key: 'serialNumber',
    title: 'registeredFirearms.accordion.serialNumber.column',
    render: (data) => renderHighlight(data as string),
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f511',
    key: 'make',
    title: 'registeredFirearms.accordion.make.column',
    render: (data) => renderHighlight(data as string),
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f512',
    key: 'model',
    title: 'registeredFirearms.accordion.model.column',
    render: (data) => renderHighlight(data as string),
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f513',
    key: 'manufacturer',
    title: 'registeredFirearms.accordion.manufacturer.column',
    render: (data) => renderHighlight(data as string),
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f514',
    key: 'calibre',
    title: 'registeredFirearms.accordion.calibre.column',
    render: (data) => renderHighlight(data as string),
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f516',
    key: 'ownerName',
    title: 'registeredFirearms.accordion.owner.column',
    render: (data) => renderHighlight(data as string),
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f515',
    key: 'keeperName',
    title: 'registeredFirearms.accordion.keeper.column',
    render: (data) => renderHighlight(data as string),
  },
  {
    id: 'fe7a7688-1583-4233-8319-dd5c8973cc44',
    key: '',
    title: 'registeredFirearms.accordion.actions.column',
    isSortable: false,
    width: '60px',
    align: 'center',
    isCenterTableHead: true,
    render: (_data, props) => (
      <Link to={ERouteLinks.ViewRegisteredFirearm.replace(':id', props.id)}>
        <Button
          variant={EButtonVariants.outlined}
          icon={EIconTypes.view}
          sx={{ marginRight: '2px' }}
        />
      </Link>
    ),
  },
];

export const globalSearchTransactionsToDataSource = (
  transactions: IGlobalSearchTransaction[],
): ITableDataSource[] =>
  transactions.map((transaction) => ({
    id: transaction.uniqueId,
    serialNumber: transaction.serialNumber,
    ownerName: transaction.ownerName,
    keeperName: transaction.keeperName,
    make: transaction.make,
    model: transaction.model,
  }));

export const globalSearchTransactionsTableColumns = ({
  renderHighlight,
}: IActionTableColumns): ITableColumns[] => [
  {
    id: 'f0504c03-6b32-482d-b369-304a6f32f510',
    key: 'serialNumber',
    title: 'transactionsList.serialNumber.column',
    width: '250px',
    render: (data) => renderHighlight(data as string),
  },
  {
    id: 'f0504c04-6b32-482d-b369-304a6f32f510',
    key: 'ownerName',
    title: 'transactionsList.owner.column',
    render: (data) => renderHighlight(data as string),
  },
  {
    id: 'f0504c05-6b32-482d-b369-304a6f32f510',
    key: 'keeperName',
    title: 'transactionsList.keeper.column',
    render: (data) => renderHighlight(data as string),
  },
  {
    id: 'f0504c06-6b32-482d-b369-304a6f32f510',
    key: 'make',
    title: 'transactionsList.make.column',
    render: (data) => renderHighlight(data as string),
  },
  {
    id: 'f0504c07-6b32-482d-b369-304a6f32f510',
    key: 'model',
    title: 'transactionsList.model.column',
    render: (data) => renderHighlight(data as string),
  },
  {
    id: 'f0504c08-6b32-482d-b369-304a6f32f510',
    key: '',
    title: 'transactionsList.action.column',
    isSortable: false,
    align: 'center',
    isCenterTableHead: true,
    width: '60px',
    render: (_data, props) => (
      <Link to={ERouteLinks.ViewTransaction.replace(':id', props.id)}>
        <Button
          variant={EButtonVariants.outlined}
          icon={EIconTypes.view}
          sx={{ marginRight: '2px' }}
        />
      </Link>
    ),
  },
];

export const globalSearchLEIndividualToDataSource = (
  entities: IGlobalSearchLEIndividual[],
): ITableDataSource[] =>
  entities.map((entity) => ({
    id: entity.uniqueId,
    firstName: entity.firstName,
    lastName: entity.lastName,
    city: entity.city,
  }));

export const globalSearchLEIndividualTableColumns = ({
  renderHighlight,
}: IActionTableColumns): ITableColumns[] => [
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f510',
    key: 'firstName',
    title: 'legalEntities.individual.accordion.legalEntityTable.firstName.column',
    render: (data) => renderHighlight(data as string),
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f511',
    key: 'lastName',
    title: 'legalEntities.individual.accordion.legalEntityTable.lastName.column',
    render: (data) => renderHighlight(data as string),
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f513',
    key: 'city',
    title: 'legalEntities.individual.accordion.legalEntityTable.city.column',
    render: (data) => renderHighlight(data as string),
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f515',
    key: '',
    title: 'legalEntities.individual.accordion.legalEntityTable.action.column',
    isSortable: false,
    width: '60px',
    align: 'center',
    isCenterTableHead: true,
    render: (_data, props) => (
      <Link
        to={`${ERouteLinks.ViewLegalEntity.replace(':id', props.id)}?type=${
          ELegalEntityTypes.individual
        }`}
      >
        <Button variant={EButtonVariants.outlined} tableButton={true} icon={EIconTypes.view} />
      </Link>
    ),
  },
];

export const globalSearchLEOrgToDataSource = (
  entities: IGlobalSearchLEOrganization[],
): ITableDataSource[] =>
  entities.map((entity) => ({
    id: entity.uniqueId,
    organizationName: entity.organizationName,
    departmentName: entity.departmentName,
    city: entity.city,
  }));

export const globalSearchLEOrgTableColumns = ({
  renderHighlight,
}: IActionTableColumns): ITableColumns[] => [
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f516',
    key: 'organizationName',
    title: 'legalEntities.organization.accordion.legalEntityTable.name.column',
    render: (data) => renderHighlight(data as string),
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f518',
    key: 'departmentName',
    title: 'legalEntities.organization.accordion.legalEntityTable.departmentName.column',
    render: (data) => renderHighlight(data as string),
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f519',
    key: 'city',
    title: 'legalEntities.organization.accordion.legalEntityTable.city.column',
    render: (data) => renderHighlight(data as string),
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f521',
    key: '',
    title: 'legalEntities.organization.accordion.legalEntityTable.action.column',
    isSortable: false,
    width: '60px',
    align: 'center',
    isCenterTableHead: true,
    render: (_data, props) => (
      <Link
        to={`${ERouteLinks.ViewLegalEntity.replace(':id', props.id)}?type=${
          ELegalEntityTypes.organisation
        }`}
      >
        <Button variant={EButtonVariants.outlined} tableButton={true} icon={EIconTypes.view} />
      </Link>
    ),
  },
];

export const globalSearchAccessoriesToDataSource = (
  entities: IGlobalSearchAccessory[],
): ITableDataSource[] =>
  entities.map((entity) => ({
    id: entity.uniqueId,
    serialNumber: entity.serialNumber,
    fireCycleId: entity.fireCycleId,
    manufacturer: entity.manufacturer,
    accessoryType: entity.accessoryType,
    productName: entity.productName,
    owner: entity.owner,
    keeper: entity.keeper,
  }));

export const globalSearchAccessoriesTableColumns = ({
  renderHighlight,
}: IActionTableColumns): ITableColumns[] => [
  {
    id: '26dfca6e-723b-4265-ab8e-b9039f479547',
    key: 'fireCycleId',
    title: 'accessoriesTable.accordion.firecycleId.column',
    render: (data) => renderHighlight(data as string),
  },
  {
    id: 'b3aafe1b-718d-4fa1-86ce-5371f970da21',
    key: 'serialNumber',
    title: 'accessoriesTable.accordion.serialNumber.column',
    render: (data) => renderHighlight(data as string),
  },
  {
    id: '946a0a8f-f1c4-445b-ac40-d6e5fe67ce5c',
    key: 'accessoryType',
    title: 'accessoriesTable.accordion.accessoryType.column',
    render: (data) => renderHighlight(emptyFieldHolder(data as string)),
  },
  {
    id: 'dab23eb9-97b9-479e-9725-54e05be45474',
    key: 'manufacturer',
    title: 'accessoriesTable.accordion.manufacturer.column',
    render: (data) => renderHighlight(data as string),
  },
  {
    id: 'dab23eb9-97b9-479e-9725-54e05be45474',
    key: 'productName',
    title: 'accessoriesTable.accordion.productName.column',
    render: (data) => renderHighlight(data as string),
  },
  {
    id: '3ad005f6-9cb4-4a69-bf44-1a311bae4815',
    key: 'owner',
    title: 'accessoriesTable.accordion.owner.column',
    render: (data) => renderHighlight(data as string),
  },
  {
    id: 'cca96b02-8455-462f-9c47-d61061d728e4',
    key: 'keeper',
    title: 'accessoriesTable.accordion.keeper.column',
    render: (data) => renderHighlight(data as string),
  },
  {
    id: '48503bbf-76da-41c7-9e83-e72ad901b6d0',
    key: '',
    title: 'accessoriesTable.accordion.action.column',
    isSortable: false,
    width: '60px',
    align: 'center',
    isCenterTableHead: true,
    render: (_data, props) => (
      <Link to={ERouteLinks.ViewAccessories.replace(':id', props.id)}>
        <Button variant={EButtonVariants.outlined} tableButton={true} icon={EIconTypes.view} />
      </Link>
    ),
  },
];

export const globalSearchAmmunitionToDataSource = (
  entities: IGlobalSearchAmmunition[],
): ITableDataSource[] =>
  entities.map((entity) => ({
    id: entity.uniqueId,
    fireCycleId: entity.fireCycleId,
    lotNumber: entity.lotNumber,
    batchNumber: entity.batchNumber,
    calibre: entity.calibre,
    ammunitionType: entity.ammunitionType,
    manufacturer: entity.manufacturer,
    productName: entity.productName,
    owner: entity.owner,
    keeper: entity.keeper,
  }));

export const globalSearchAmmunitionTableColumns = ({
  renderHighlight,
}: IActionTableColumns): ITableColumns[] => [
  {
    id: '91fc63ee-934e-4158-abe6-528e1a0c02ba',
    key: 'fireCycleId',
    title: 'ammunitionTable.accordion.fireCycleId.column',
    render: (data) => renderHighlight(data as string),
  },
  {
    id: '4ec43361-416f-4dfd-af32-57db12f7ee76',
    key: 'lotNumber',
    title: 'ammunitionTable.accordion.lotNumber.column',
    render: (data) => renderHighlight(data as string),
  },
  {
    id: '90fe8519-0624-4c1b-9ece-cd907112e307',
    key: 'batchNumber',
    title: 'ammunitionTable.accordion.batchNumber.column',
    render: (data) => renderHighlight(data as string),
  },
  {
    id: '3a99533e-f0f9-418a-b6fd-b21f7cb04d48',
    key: 'ammunitionType',
    title: 'ammunitionTable.accordion.ammunitionType.column',
    render: (data) => renderHighlight(emptyFieldHolder(data as string)),
  },
  {
    id: '7a7b04bc-be78-4f81-8b4a-7c02428cc2e0',
    key: 'calibre',
    title: 'ammunitionTable.accordion.calibre.column',
    render: (data) => renderHighlight(data as string),
  },
  {
    id: 'a7528012-29dc-4dcd-8c94-d0aed4a82853',
    key: 'manufacturer',
    title: 'ammunitionTable.accordion.ammunitionManufacturer.column',
    render: (data) => renderHighlight(data as string),
  },
  {
    id: 'dab23eb9-97b9-479e-9725-54e05be45474',
    key: 'productName',
    title: 'ammunitionTable.accordion.productName.column',
    render: (data) => renderHighlight(data as string),
  },
  {
    id: '4cd458fe-7e58-4df4-8b11-d14d63e565a0',
    key: 'owner',
    title: 'ammunitionTable.accordion.owner.column',
    render: (data) => renderHighlight(data as string),
  },
  {
    id: '39169033-b723-4032-ae3d-77bd842f3412',
    key: 'keeper',
    title: 'ammunitionTable.accordion.keeper.column',
    render: (data) => renderHighlight(data as string),
  },
  {
    id: '92d362bd-83f2-4c30-889f-308917ce68c2',
    key: '',
    title: 'ammunitionTable.accordion.action.column',
    isSortable: false,
    width: '60px',
    align: 'center',
    isCenterTableHead: true,
    render: (_data, props) => (
      <Link to={ERouteLinks.ViewAmmunition.replace(':id', props.id)}>
        <Button variant={EButtonVariants.outlined} tableButton={true} icon={EIconTypes.view} />
      </Link>
    ),
  },
];

export const globalSearchComponentsToDataSource = (
  entities: IGlobalSearchComponent[],
): ITableDataSource[] =>
  entities.map((entity) => ({
    id: entity.uniqueId,
    serialNumber: entity.serialNumber,
    fireCycleId: entity.fireCycleId,
    componentType: entity.componentType,
    manufacturer: entity.manufacturer,
    productName: entity.productName,
    firearmType: entity.firearmType,
    make: entity.make,
    model: entity.model,
    owner: entity.owner,
    keeper: entity.keeper,
  }));

export const globalSearchComponentsTableColumns = ({
  renderHighlight,
}: IActionTableColumns): ITableColumns[] => [
  {
    id: '093008f9-6824-4a80-a35d-a26bda75e94f',
    key: 'fireCycleId',
    title: 'componentsTable.accordion.fireCycleId.column',
    render: (data) => renderHighlight(data as string),
  },
  {
    id: '2ba59276-f1d3-4727-b4a7-200d678f30cf',
    key: 'serialNumber',
    title: 'componentsTable.accordion.serialNumber.column',
    render: (data) => renderHighlight(data as string),
  },
  {
    id: 'cef6fd1d-efc0-4e0b-a321-55dcc02ba684',
    key: 'componentType',
    title: 'componentsTable.accordion.componentType.column',
    render: (data) => renderHighlight(emptyFieldHolder(data as string)),
  },
  {
    id: '6c61a340-eb8f-437a-a36c-11e0caffda8f',
    key: 'manufacturer',
    title: 'componentsTable.accordion.manufacturer.column',
    render: (data) => renderHighlight(data as string),
  },
  {
    id: 'dab23eb9-97b9-479e-9725-54e05be45474',
    key: 'productName',
    title: 'componentsTable.accordion.productName.column',
    render: (data) => renderHighlight(data as string),
  },
  {
    id: '97f114b1-f6a6-4891-bda1-2f76139f48d9',
    key: 'firearmType',
    title: 'componentsTable.accordion.firearmType.column',
    render: (data) => renderHighlight(data as string),
  },
  {
    id: '8263ce3b-e7bd-4ea1-86a7-8d518a596a53',
    key: 'make',
    title: 'componentsTable.accordion.make.column',
    render: (data) => renderHighlight(data as string),
  },
  {
    id: '722b76d8-bea1-4b3d-a83c-79eb7b960237',
    key: 'model',
    title: 'componentsTable.accordion.model.column',
    render: (data) => renderHighlight(data as string),
  },
  {
    id: '995eb456-d7e3-4096-81ce-829e84f3bf0f',
    key: 'owner',
    title: 'componentsTable.accordion.owner.column',
    render: (data) => renderHighlight(data as string),
  },
  {
    id: '9d5aa698-a34e-49b0-9b1d-a99600d6a310',
    key: 'keeper',
    title: 'componentsTable.accordion.keeper.column',
    render: (data) => renderHighlight(data as string),
  },
  {
    id: 'accd333d-9827-417b-a633-ba62d8c2d99e',
    key: '',
    title: 'componentsTable.accordion.action.column',
    isSortable: false,
    width: '60px',
    align: 'center',
    isCenterTableHead: true,
    render: (_data, props) => (
      <Link to={ERouteLinks.ViewComponents.replace(':id', props.id)}>
        <Button variant={EButtonVariants.outlined} tableButton={true} icon={EIconTypes.view} />
      </Link>
    ),
  },
];
