export interface IPermission {
  uniqueId: string;
  name: string;
  description: string;
  authority: string;
  relateModule: keyof typeof EPermissionRelatedModule;
}

export enum EPermissionRelatedModule {
  LEGAL_ENTITY = 'LEGAL_ENTITY',
  FIREARM = 'FIREARM',
  TRANSACTION = 'TRANSACTION',
  ADMINISTRATION = 'ADMINISTRATION',
  ANCILLARY = 'ANCILLARY',
  DASHBOARD = 'DASHBOARD',
}

export const permissionsGroups = [
  { title: 'roleDetails.legalEntity.section', module: EPermissionRelatedModule.LEGAL_ENTITY },
  { title: 'roleDetails.firearm.section', module: EPermissionRelatedModule.FIREARM },
  {
    title: 'roleDetails.ancillary.section',
    module: EPermissionRelatedModule.ANCILLARY,
  },
  {
    title: 'roleDetails.transaction.section',
    module: EPermissionRelatedModule.TRANSACTION,
  },
  {
    title: 'roleDetails.administration.section',
    module: EPermissionRelatedModule.ADMINISTRATION,
  },
  {
    title: 'roleDetails.dashboard.section',
    module: EPermissionRelatedModule.DASHBOARD,
  },
];

export enum EPermission {
  /** Legal Entity Module  */
  DELETE_LE = 'DELETE_LE',
  MANAGE_COMMENTS_LE = 'MANAGE_COMMENTS_LE',
  EXPORT_LE = 'EXPORT_LE',
  VIEW_ALL_LE = 'VIEW_ALL_LE',
  VIEW_LE = 'VIEW_LE',
  VIEW_ANCILLARIES_LE = 'VIEW_ANCILLARIES_LE',
  VIEW_FIREARMS_LE = 'VIEW_FIREARMS_LE',
  MANAGE_LE = 'MANAGE_LE',
  MANAGE_GROUPS_LE = 'MANAGE_GROUPS_LE',

  /** Firearm Module  */
  VIEW_FIREARMS = 'VIEW_FIREARMS',
  VIEW_ALL_FIREARMS = 'VIEW_ALL_FIREARMS',
  MANAGE_COMMENTS_FIREARMS = 'MANAGE_COMMENTS_FIREARMS',
  MANAGE_ATTACHMENTS_FIREARMS = 'MANAGE_ATTACHMENTS_FIREARMS',
  DISABLE_FIREARM = 'DISABLE_FIREARM',
  MODIFY_FIREARM = 'MODIFY_FIREARM',
  MANAGE_FRT_FIREARM = 'MANAGE_FRT_FIREARM',
  EXPORT_FIREARMS = 'EXPORT_FIREARMS',
  MANAGE_IARMS_EXPORT = 'MANAGE_IARMS_EXPORT',

  /** Transaction Module  */
  ADD_TRANSACTION = 'ADD_TRANSACTION',
  VIEW_TRANSACTIONS = 'VIEW_TRANSACTIONS',
  VIEW_ALL_TRANSACTIONS = 'VIEW_ALL_TRANSACTIONS',
  DISCARD_TRANSACTION = 'DISCARD_TRANSACTION',
  MANAGE_TRANSACTION_COMMENTS = 'MANAGE_TRANSACTION_COMMENTS',

  /** Administration Module  */
  MANAGE_USERS = 'MANAGE_USERS',
  MANAGE_ROLES = 'MANAGE_ROLES',
  MANAGE_CUSTOM_FIELDS = 'MANAGE_CUSTOM_FIELDS',
  MANAGE_SETTINGS = 'MANAGE_SETTINGS',
  VIEW_MODIFY_SYSTEM_PARAMETERS = 'VIEW_MODIFY_SYSTEM_PARAMETERS',
  VIEW_AUDIT_LOG = 'VIEW_AUDIT_LOG',
  EXPORT_AUDIT_LOG = 'EXPORT_AUDIT_LOG',
  MANAGE_DICTIONARIES = 'MANAGE_DICTIONARIES',
  VIEW_AND_MANAGE_LICENCE = 'VIEW_AND_MANAGE_LICENCE',
  MANAGE_API_KEYS = 'MANAGE_API_KEYS',

  /** Ancillaries Module  */
  VIEW_LIMITED_ANCILLARIES = 'VIEW_LIMITED_ANCILLARIES',
  VIEW_ALL_ANCILLARIES = 'VIEW_ALL_ANCILLARIES',
  EDIT_ANCILLARY = 'EDIT_ANCILLARY',
  DISABLE_ANCILLARY = 'DISABLE_ANCILLARY',
  MANAGE_LOCAL_ART = 'MANAGE_LOCAL_ART',
  MANAGE_COMMENTS_ANCILLARY = 'MANAGE_COMMENTS_ANCILLARY',
  MANAGE_ATTACHMENT_ANCILLARY = 'MANAGE_ATTACHMENT_ANCILLARY',
  EXPORT_ANCILLARIES = 'EXPORT_ANCILLARIES',

  /** Dashboard Module  */
  VIEW_DASHBOARD_CHARTS = 'VIEW_DASHBOARD_CHARTS',
}

export enum EPermissionIds {
  /** Legal Entity Module  */
  VIEW_LE = 'b2625a10-7a39-11ee-b962-0242ac120002',
  MANAGE_LE = '180fe4af-360a-4761-805a-920d1b152d91',
  DELETE_LE = '2523fde6-193e-11ed-861d-0242ac120001',
  VIEW_ALL_LE = '299d9116-193e-11ed-861d-0242ac120001',
  MANAGE_COMMENTS_LE = '2f583566-193e-11ed-861d-0242ac120001',
  MANAGE_GROUPS_LE = '33c335c4-193e-11ed-861d-0242ac120001',
  VIEW_FIREARMS_LE = '386b84a0-193e-11ed-861d-0242ac120001',
  VIEW_ANCILLARIES_LE = '2d385ac0-f088-414d-a8a3-afb90b55e8da',
  EXPORT_LE = '3c505582-193e-11ed-861d-0242ac120001',

  /** Firearm Module  */
  VIEW_FIREARMS = 'b73d2f95-0872-4064-a457-b12035e0e305',
  VIEW_ALL_FIREARMS = 'db08a9c3-d4a8-490e-8c98-a786bd116b6e',
  MANAGE_COMMENTS_FIREARMS = '6999d8a9-f219-4cf3-8ba2-ee2533c0095a',
  MANAGE_ATTACHMENTS_FIREARMS = '6c2c585f-6d0c-45dd-a9ad-cd5526cd237a',
  DISABLE_FIREARM = '3df425f5-7f8d-4694-bbc9-d0ceea8f13b0',
  MODIFY_FIREARM = '0956147e-b9a5-43f5-9002-7f6ae87e37dc',
  MANAGE_FRT_FIREARM = '25d117cd-b886-4983-b328-9964b0271397',
  EXPORT_FIREARMS = 'd19905f4-62dc-4068-a918-0ba188ca1142',

  /** Transaction Module  */
  ADD_TRANSACTION = '3101f8de-df11-4a1b-a21f-4a5a0e61472a',
  DISCARD_TRANSACTION = '502734eb-87f1-40fa-8a8b-b571d9776acb',
  VIEW_ALL_TRANSACTIONS = '45fad9c2-cb30-4c6b-96d7-5e974bc61c88',
  VIEW_TRANSACTIONS = 'ac3fa90a-f106-4388-afcf-06bc62e405f1',
  MANAGE_TRANSACTION_COMMENTS = '37507121-db55-403b-b25a-996405b7c696',

  /** Administration Module  */
  MANAGE_USERS = '751ca6dc-b3ad-4648-8dfa-2c6cef2aa77b',
  MANAGE_ROLES = 'a32899ac-7eb5-4e12-a35a-191e87029676',
  MANAGE_CUSTOM_FIELDS = 'ad1653c3-953c-4850-a246-2a01d7a1559f',
  MANAGE_SETTINGS = '7782e2ac-7a2a-11ee-b962-0242ac120002',
  VIEW_AUDIT_LOG = 'e36f6738-7a2f-11ee-b962-0242ac120002',
  EXPORT_AUDIT_LOG = '14076eca-7c85-11ee-b962-0242ac120002',
  MANAGE_DICTIONARIES = '14f17976-8853-400c-9f8f-7b79d465f0e4',
  VIEW_AND_MANAGE_LICENCE = '43d53c80-c4d2-44d9-9128-e54d0e0eb7e2',
  MANAGE_API_KEYS = '888b92a9-4f9d-40e5-ab7b-106072cd33ed',

  /** Ancillaries Module  */
  VIEW_LIMITED_ANCILLARIES = '8cf360b7-f3fc-4f6d-8f6a-23b2f1ce74b2',
  VIEW_ALL_ANCILLARIES = '831f60ce-048a-4780-858c-a987af64b33b',
  MANAGE_COMMENTS_ANCILLARY = '83f7453a-ea86-4616-b6ac-944daf1bb396',
  MANAGE_ATTACHMENT_ANCILLARY = '17fc2d2c-861c-4f0e-bbf9-0dfe119c120d',
  DISABLE_ANCILLARY = 'de29e6d6-d080-48b0-9e3e-a8633827ed56',
  EDIT_ANCILLARY = '669dabdd-75d4-437b-aa60-e5cd701911d5',
  MANAGE_LOCAL_ART = 'c17c999e-aede-4d3a-91e3-e904f9c1a0b8',
  EXPORT_ANCILLARIES = '2df8ec2f-1ab1-4bd3-a153-d06c76bce0a8',

  /** Dashboard Module  */
  VIEW_DASHBOARD_CHARTS = '222f5ada-e3db-461d-bfbb-ae674b546a92',
}
