import { useTranslations } from 'hooks/useTranslations';
import { useAlert } from 'models/alertContext';
import { useState } from 'react';
import { ActionTypes } from 'state/actions/alert';
import { IAuthenticationSetupItem, ItrustedDeviceOptions } from '../../../models/settings';
import {
  getAuthenticationSetupDetail,
  getEnabledTrustedDeviceOptions,
  getTimeZoneInfo,
  getTrustedDeviceOptions,
  updateAuthenticationSetup,
} from '../../../requests/settings';

export const useAuthenticationSetup = () => {
  const [authenticationSetup, setAuthenticationSetup] =
    useState<IAuthenticationSetupItem | null>(null);
  const [trustedDeviceOptions, setTrustedDeviceOptions] = useState<
    ItrustedDeviceOptions[] | null
  >(null);
  const [timeZoneData, setTimeZoneData] = useState<string | null>(null);
  const { setAlert } = useAlert();
  const { t } = useTranslations();
  const handleUpdate = async (data: any) => {
    const response = await updateAuthenticationSetup(data);
    if (response) {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: t('AuthenticationSetupUpdate.success.text'),
      });
    }
    return response;
  };
  const getAuthenticationSetupData = async (isOnline: boolean) => {
    const data = await getAuthenticationSetupDetail(isOnline);
    if (data) {
      setAuthenticationSetup(data);
    } else {
      setAuthenticationSetup(null);
    }
  };

  const getTrustedDeviceOptionsData = async () => {
    const data: ItrustedDeviceOptions[] | null = await getTrustedDeviceOptions();
    if (data) {
      setTrustedDeviceOptions(data);
    } else {
      setTrustedDeviceOptions(null);
    }
  };

  const getEnabledTrustedDeviceOptionsData = async () => {
    const data: ItrustedDeviceOptions[] | null = await getEnabledTrustedDeviceOptions();
    if (data) {
      setTrustedDeviceOptions(data);
    } else {
      setTrustedDeviceOptions(null);
    }
  };

  const getTimeZoneData = async () => {
    const data: string | null = await getTimeZoneInfo();
    if (data) {
      setTimeZoneData(data);
    } else {
      setTimeZoneData(null);
    }
  };

  return {
    handleUpdate,
    getAuthenticationSetupData,
    authenticationSetup,
    getEnabledTrustedDeviceOptionsData,
    getTrustedDeviceOptionsData,
    trustedDeviceOptions,
    getTimeZoneData,
    timeZoneData,
  };
};
