import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { ERouteLinks } from 'models/route';
import { StyledPageTitle } from 'theme/styles';
import { useTranslations } from 'hooks/useTranslations';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/system';
import { FormInput } from 'components/molecules/FormInput';
import {
  StyledFilterChipWrapper,
  StyledSelectedFiltersWrapper,
} from 'pages/MyFiltersPage/styles';
import { Typography } from '@mui/material';
import { FilterChip } from 'components/atoms/FilterChip';
import { FormCheckbox } from 'components/molecules/FormCheckbox';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import FormWrapper from 'components/atoms/FormWrapper';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDictionary } from 'models/dictionaryContext';
import {
  mapValuesToTransactionPresetRequest,
  mapValuesWithDictionariesHelper,
} from 'pages/TransactionsPage/SaveTransactionPresetPage/helpers';
import { objectToKeyValueArray } from 'helpers/objects';
import SearchFilterStore from 'state/SearchFilterStore';
import { ESearchFilterKeys } from 'constants/SearchFilterTypes';
import { parseDictionary } from 'helpers/dictionary';
import { useSavePreset } from 'pages/TransactionsPage/SaveTransactionPresetPage/hooks';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import { preparePresetTransactionFilters } from 'helpers/filters';
import usePresetFormSchema from 'pages/TransactionsPage/SaveTransactionPresetPage/schema';
import { ESearchFormTransactionLabels } from 'pages/TransactionsPage/TransactionSearchForm';
import { useLicenceContext } from 'models/licenceContext';
import { useGlobalProperty } from 'models/globalPropertyContext';

export interface IPresetFormValues {
  name: string;
  emailNotificationEnabled: boolean;
  notificationEnabled: boolean;
}

const initialFormValues = {
  name: '',
  emailNotificationEnabled: false,
  notificationEnabled: false,
};

const SaveTransactionPresetPage = () => {
  const { t } = useTranslations();
  const { schema } = usePresetFormSchema();
  const navigate = useNavigate();
  const { savePreset } = useSavePreset();
  const { setAlert } = useAlert();
  const [error, setError] = useState<string>('');
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const { isOnlineLicence } = useLicenceContext();
  const { state: routerState }: any = useLocation();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<IPresetFormValues>({
    defaultValues: initialFormValues,
    resolver: yupResolver(schema),
  });
  const [filtersArray, setFiltersArray] = useState<{ key: string; value: string }[]>([]);
  const { midDateFormat } = useGlobalProperty();
  const {
    transactionType,
    getTransactionType,
    make,
    getMake,
    model,
    getModel,
    caliber,
    getCaliber,
    firearmAction,
    getFirearmAction,
    legality,
    getLegality,
  } = useDictionary();
  const getBackRoute = () => navigate(routerState.cancelPath || ERouteLinks.Transactions);
  const labels = ESearchFormTransactionLabels;
  const filters = SearchFilterStore.getValue(ESearchFilterKeys.transaction);

  useEffect(() => {
    if (transactionType === null) {
      getTransactionType();
    }
    if (make === null) {
      getMake();
    }
    if (model === null) {
      getModel();
    }
    if (caliber === null) {
      getCaliber();
    }
    if (firearmAction === null) {
      getFirearmAction();
    }
    if (legality === null) {
      getLegality();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filtersWithDictionaries = () => {
    const preparedFilters = preparePresetTransactionFilters(objectToKeyValueArray(filters), t);

    setFiltersArray(
      mapValuesWithDictionariesHelper(preparedFilters, {
        transactionTypeUniqueId: parseDictionary(transactionType, 'name'),
        firearmAction,
        legalityUniqueId: parseDictionary(legality, 'name'),
        make,
        model,
        caliber,
      }),
    );
  };

  useEffect(() => {
    if (filters) {
      filtersWithDictionaries();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionType, make, model, caliber, firearmAction, legality]);

  const onSubmitForm = async (data: IPresetFormValues) => {
    setError('');
    setIsSubmitLoading(true);
    const body = mapValuesToTransactionPresetRequest(filters, midDateFormat);

    if (body) {
      try {
        const res = await savePreset({
          name: data.name,
          emailNotificationEnabled: data.emailNotificationEnabled,
          notificationEnabled: data.notificationEnabled,
          body,
        });

        if (res.status === 200) {
          setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
            text: `${t('saveMyFilter.successConfirmation.info')}`,
            variant: EAlertVariants.success,
          });
          return navigate(ERouteLinks.MyFilters);
        }
        setError(res.response.data.message);
      } catch (e: any) {
        console.error(e);
      }
    }
    return setIsSubmitLoading(false);
  };

  return (
    <>
      <Breadcrumbs
        items={[
          {
            label: t('transactionsList.transactions.header'),
            route: ERouteLinks.Transactions,
          },
          t('transactionsList.saveAsMyFilter.link'),
        ]}
      />
      <StyledPageTitle variant="h4">
        {t('transactionsList.saveAsMyFilter.link')}
      </StyledPageTitle>

      <FormWrapper>
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <Grid container>
            <Grid item xs={12} lg={6}>
              <Box mb={1}>
                <FormInput
                  name="name"
                  label={`${t('saveMyFilter.filterName.label')}*`}
                  placeholder={t('saveMyFilter.filterName.label')}
                  control={control}
                  setValue={setValue}
                  errors={errors}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <StyledSelectedFiltersWrapper container rowGap={1}>
                <Grid item>
                  <Typography variant="body2">{`${t(
                    'saveMyFilter.selectedCriteria.section',
                  )}:`}</Typography>
                </Grid>
                <StyledFilterChipWrapper item>
                  {filtersArray &&
                    filtersArray.map((item: { key: string; value: string }) => (
                      <FilterChip
                        key={item.key}
                        name={item.key}
                        label={t(labels?.[item.key as keyof typeof labels]) || item.key}
                        values={item.value}
                      />
                    ))}
                </StyledFilterChipWrapper>
              </StyledSelectedFiltersWrapper>
            </Grid>

            <Grid item xs={12} container columnSpacing={3}>
              <Grid item xs={12}>
                <FormCheckbox
                  name="notificationEnabled"
                  label={t('saveMyFilter.notifyMeViaSystem.checkbox.label')}
                  control={control}
                  errors={errors}
                />
              </Grid>

              {isOnlineLicence() && (
                <Grid item xs={12}>
                  <FormCheckbox
                    name="emailNotificationEnabled"
                    label={t('saveMyFilter.notifyMeViaEmail.checkbox.label')}
                    control={control}
                    errors={errors}
                  />
                </Grid>
              )}
            </Grid>

            <Grid item xs={12} container columnSpacing={3} rowGap={3} mt={2}>
              <Grid item xs={12} sm={6} lg={3}>
                <Button
                  type="submit"
                  fullWidth
                  label="Save"
                  variant={EButtonVariants.contained}
                  isLoading={isSubmitLoading}
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={3}>
                <Button
                  fullWidth
                  label="Cancel"
                  variant={EButtonVariants.outlined}
                  onClick={getBackRoute}
                />
              </Grid>
            </Grid>
            <Grid item mt={2} xs={12}>
              <Typography variant="caption">{t('general.mandatoryField.text')}</Typography>
            </Grid>
            {error && (
              <Grid item mt={2} xs={12}>
                <Alert text={error} variant={EAlertVariants.error} />
              </Grid>
            )}
          </Grid>
        </form>
      </FormWrapper>
    </>
  );
};

export { SaveTransactionPresetPage };
