export const getNestedErrorMessage = (errors: any, keys: string[]): string | undefined => {
  if (keys.length === 0) {
    return undefined;
  }

  const [currentKey, ...remainingKeys] = keys;
  if (remainingKeys.length === 0) {
    return errors[currentKey]?.message;
  }

  if (errors[currentKey] === undefined) {
    return undefined;
  }

  return getNestedErrorMessage(errors[currentKey], remainingKeys);
};

export const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];
export const LIST_TYPES = ['numbered-list', 'bulleted-list'];

export const initialValue = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
];
