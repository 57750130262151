import {
  ISearchFilterIndividualRequest,
  ISearchFilterOrganizationRequest,
} from 'models/searchFilter';
import { clearObject } from 'helpers/objects';
import { ISearchFormValues as ISearchLEIFormValues } from 'pages/LegalEntitiesPage/IndividualSearchForm';
import { ISearchFormValues as ISearchLEOFormValues } from 'pages/LegalEntitiesPage/OrganisationSearchForm';
import { ESearchFilterKeys } from 'constants/SearchFilterTypes';
import { ELegalEntityTypes } from 'constants/LegalEntityTypes';
import { IParsedDictionary } from 'helpers/dictionary';
import { formatDateToRequest, simpleFormatDate } from 'utils/date';
import { parse } from 'date-fns';

const mapValuesToIndividualPresetRequest = (
  data: ISearchLEIFormValues,
  shortDateFormat: string,
): ISearchFilterIndividualRequest => {
  const values: ISearchFilterIndividualRequest = {
    firstname: data.firstname,
    lastName: data.lastName,
    city: data.city,
    birthdayFrom: data.birthdayFrom
      ? formatDateToRequest(
          parse(data.birthdayFrom as string, shortDateFormat, new Date()),
          true,
        )
      : '',
    birthdayTo: data.birthdayTo
      ? formatDateToRequest(
          parse(data.birthdayTo as string, shortDateFormat, new Date()),
          true,
        )
      : '',
    visibilityUniqueId: data.visibilityUniqueId,
    genderUniqueId: data.genderUniqueId,
    prefixGroupUniqueId: data.prefixGroupUniqueId,
  };

  return clearObject(values);
};

const mapValuesToOrganisationPresetRequest = (
  data: ISearchLEOFormValues,
): ISearchFilterOrganizationRequest => {
  const values: ISearchFilterOrganizationRequest = {
    name: data.name,
    organizationTypeUniqueId: data.organizationTypeUniqueId,
    departmentName: data.departmentName,
    city: data.city,
    visibilityUniqueId: data.visibilityUniqueId,
  };
  return clearObject(values);
};

export const mapValuesToPresetRequest = (
  type: ESearchFilterKeys | ELegalEntityTypes | undefined,
  data: ISearchLEIFormValues | ISearchLEOFormValues,
  shortDateFormat: string,
) => {
  if (!type) {
    return null;
  }

  switch (type) {
    case ESearchFilterKeys.individual:
    case ELegalEntityTypes.individual:
      return mapValuesToIndividualPresetRequest(data as ISearchLEIFormValues, shortDateFormat);

    case ESearchFilterKeys.organisation:
    case ELegalEntityTypes.organisation:
      return mapValuesToOrganisationPresetRequest(data as ISearchLEOFormValues);
    default:
      return null;
  }
};

export const permittedOrganisationFilterFields = [
  'name',
  'organizationTypeUniqueId',
  'departmentName',
  'city',
  'visibilityUniqueId',
];

export const permittedIndividualFilterFields = [
  'firstname',
  'lastName',
  'city',
  'birthdayFrom',
  'birthdayTo',
  'visibilityUniqueId',
  'genderUniqueId',
  'prefixGroupUniqueId',
];

export const mapValuesWithDictionariesHelper = (
  values: { key: string; value: string }[],
  dictionaries: { [key: string]: IParsedDictionary },
) =>
  values.map((item) => {
    if (dictionaries?.[item.key] && dictionaries[item.key]?.[item.value]) {
      item.value = dictionaries[item.key]?.[item.value];
    }
    return item;
  });

export const mapValuesToIndividualPresetForView = (
  values: { key: string; value: string }[],
  shortDateFormat: string,
) => {
  values.unshift({ key: 'legalEntityType', value: 'Individual' });
  return values.map((item) => {
    switch (item.key) {
      case 'birthdayFrom':
        return {
          key: 'birthdayFrom',
          value: simpleFormatDate(item.value, shortDateFormat),
        };
      case 'birthdayTo':
        return {
          key: 'birthdayTo',
          value: simpleFormatDate(item.value, shortDateFormat),
        };
      default:
        return item;
    }
  });
};

export const mapValuesToOrganisationPresetForView = (
  values: { key: string; value: string }[],
) => {
  values.unshift({ key: 'legalEntityType', value: 'Organisation' });
  return values;
};
