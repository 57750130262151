import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { StyledTitleSection } from 'pages/ApiKeysPage/styles';
import { FormRadioGroup } from 'components/molecules/FormRadioGroup';
import { FormInput } from 'components/molecules/FormInput';
import { useForm } from 'react-hook-form';
import { useTranslations } from 'hooks/useTranslations';
import { useApiKeys } from 'pages/ApiKeysPage/hooks';
import { EDictionaryTypes } from 'models/dictionary';
import { mapDictionaryToOptionProp } from 'helpers/dictionary';
import { FormDatePicker } from 'components/molecules/FormDatePicker';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { FormCheckbox } from 'components/molecules/FormCheckbox';
import { colors } from 'theme/colors';
import { apiKeyScopeList, IApiKeyRequest } from 'models/apiKey';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { Typography } from '@mui/material';
import useApiKeyFormSchema from 'pages/ApiKeysPage/ApiKeyForm/schemaValidation';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormSelect } from 'components/molecules/FormSelect';
import { formatDateTimeToIso } from 'utils/date';
import { ERouteLinks } from 'models/route';
import { useNavigate } from 'react-router-dom';
import FormErrorLabel from 'components/atoms/FormErrorLabel';
import { useScroll } from 'hooks/useScroll';

const initialValues = (() => {
  const values: { [key: string]: string | boolean | Date } = {
    type: 'READ',
    expiryDate: '',
    description: '',
    contactEmail: '',
    firstName: '',
    lastName: '',
    phonePrefix: '',
    phoneNumber: '',
  };

  apiKeyScopeList.map((scope) => {
    values[scope.value] = false;

    scope.items.map((item) => {
      values[item.value] = false;
      return false;
    });
    return false;
  });

  return values;
})();

interface IApiKeyForm {
  onSubmit: (arg: IApiKeyRequest) => void;
  initialFormValues?: { [key: string]: string | boolean | Date };
  isEdit?: boolean;
}

const ApiKeyForm = ({ onSubmit, initialFormValues, isEdit = false }: IApiKeyForm) => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { loadDictionaries, phoneCode } = useApiKeys();
  const { shortDateFormat } = useGlobalProperty();
  const { schema } = useApiKeyFormSchema();
  const { elRef, scrollToError } = useScroll();

  const readDataLabel = t('apiKeysForm.readData.label');

  const {
    control,
    setValue,
    getValues,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm({
    defaultValues: initialFormValues || initialValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset(initialFormValues || initialValues);
  }, [reset, initialFormValues]);

  useEffect(() => {
    (async () => {
      await loadDictionaries([EDictionaryTypes.ApiKeyType, EDictionaryTypes.PhoneCode]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      scrollToError(errors);
    })();
  }, [errors]);

  const toggleGroup = (
    scope: {
      label: string;
      value: string;
      items: { label: string; value: string }[];
    },
    isChecked: boolean,
  ) => {
    const group: { [key: string]: boolean } = {};
    scope.items.map((item) => {
      group[item.value] = isChecked;
      return false;
    });

    reset({ ...getValues(), ...group });
  };

  const toggleOne = (key: string, isChecked: boolean, group: string) => {
    reset({ ...getValues(), ...{ [key]: isChecked, [group]: false } });
  };

  const onSubmitHandler = (data: any) => {
    const payload: IApiKeyRequest = {
      apikeyLegalEntity: {},
      apikeyFirearm: {},
      apikeyAncillaryAccessory: {},
      apikeyAncillaryAmmunition: {},
      apikeyAncillaryComponent: {},
      apikeyTransaction: {},
      type: data.type,
      username: data.username,
      expiryDate: data.expiryDate ? formatDateTimeToIso(data.expiryDate) : null,
      description: data.description,
      contactEmail: data.contactEmail,
      firstName: data.firstName,
      lastName: data.lastName,
      contactPhone: {
        codeDictionaryUniqueId: data.phonePrefix,
        value: data.phoneNumber,
      },
    };

    apiKeyScopeList.map((scope) => {
      const scopeKey = `${scope.value}_`;
      Object.keys(data).map((key) => {
        if (key.includes(scopeKey) && key.split('_')?.[1]) {
          payload[scope.value][key.split('_')[1]] = data[key];
        }
        return false;
      });
      return false;
    });

    onSubmit(payload);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <Grid container columnSpacing={2} ref={elRef}>
        <Grid item xs={12}>
          <StyledTitleSection>{t('apiKeys.apiKeyDetails.label')}</StyledTitleSection>
        </Grid>
        <Grid item xs={12}>
          <FormRadioGroup
            name="type"
            label={t('apiKeysForm.apiKeyType.label')}
            options={[{ label: readDataLabel, value: 'READ' }]}
            control={control}
            errors={errors}
          />
        </Grid>

        <Grid container item xs={12} columnSpacing={2}>
          <Grid item xs={12} md={6} lg={6}>
            <FormInput
              name="username"
              label={`${t('apiKeysForm.apiKeyUser.label')}*`}
              setValue={setValue}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <FormDatePicker
              name="expiryDate"
              dateFormat={shortDateFormat}
              label={t('apiKeysForm.apiKeyExpiryDate.label')}
              control={control}
              errors={errors}
              helperText={t('apiKeysForm.apiKeyExpiryDateHelper.label')}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <FormInput
            name="description"
            label={t('apiKeysForm.apiKeyRoleDescription.label')}
            setValue={setValue}
            control={control}
            errors={errors}
            multiline={true}
            minRows={3}
          />
        </Grid>

        <Grid item xs={12}>
          <StyledTitleSection>{t('apiKeys.apiKeyContact.label')}</StyledTitleSection>
        </Grid>

        <Grid container item xs={12} columnSpacing={2}>
          <Grid item xs={12} sm={6} lg={6}>
            <FormInput
              name="contactEmail"
              label={`${t('apiKeysForm.apiKeyContactEmail.label')}*`}
              setValue={setValue}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={3} lg={3}>
            <FormInput
              name="firstName"
              label={`${t('apiKeysForm.firstName.label')}*`}
              setValue={setValue}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={3} lg={3}>
            <FormInput
              name="lastName"
              label={`${t('apiKeysForm.lastName.label')}*`}
              setValue={setValue}
              control={control}
              errors={errors}
            />
          </Grid>
        </Grid>

        <Grid container item xs={12} columnSpacing={2}>
          <Grid item xs={12} sm={3} md={3} lg={2}>
            <FormSelect
              name="phonePrefix"
              options={mapDictionaryToOptionProp(phoneCode)}
              label={`${t('apiKeysForm.phonePrefix.label')}*`}
              setValue={setValue}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={5} lg={5}>
            <FormInput
              name="phoneNumber"
              label={`${t('apiKeysForm.phoneNumber.label')}*`}
              setValue={setValue}
              control={control}
              errors={errors}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <StyledTitleSection>{t('apiKeys.apiKeyScope.label')}*</StyledTitleSection>
        </Grid>

        {apiKeyScopeList.map((scope) => (
          <Grid
            container
            item
            xs={12}
            style={{
              padding: '10px 0',
              margin: '0 20px',
              borderBottom: `1px solid ${colors.primary50}`,
            }}
          >
            <Grid item xs={12}>
              <FormCheckbox
                name={scope.value}
                label={t(scope.label)}
                control={control}
                errors={errors}
                withValidation={false}
                labelVariant="subtitle2"
                onChange={(isChecked) => {
                  toggleGroup(scope, isChecked);
                }}
              />
            </Grid>
            {scope.items.map((item) => (
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <FormCheckbox
                  name={item.value}
                  label={t(item.label)}
                  control={control}
                  errors={errors}
                  withValidation={false}
                  onChange={(isChecked) => {
                    toggleOne(item.value, isChecked, scope.value);
                  }}
                />
              </Grid>
            ))}
          </Grid>
        ))}

        {errors?.scopeKeys && (
          <Grid item lg={12} xs={12}>
            <FormErrorLabel label={errors?.scopeKeys?.message?.toString() as string} />
          </Grid>
        )}

        <Grid
          item
          xs={12}
          container
          columnSpacing={3}
          rowGap={3}
          mt={2}
          sx={{ marginTop: '30px' }}
        >
          <Grid item xs={12} sm={6} lg={3}>
            <Button
              type="submit"
              fullWidth
              label={isEdit ? t('general.save.button') : t('apiKeysForm.generate.button')}
              id="save-edit-api-key"
              variant={EButtonVariants.contained}
            />
          </Grid>

          <Grid item xs={12} sm={6} lg={3}>
            <Button
              fullWidth
              label={t('general.cancel.button')}
              id="cancel-button"
              variant={EButtonVariants.outlined}
              onClick={() => navigate(ERouteLinks.ApiKeysPage)}
            />
          </Grid>
        </Grid>

        <Grid item mt={2} xs={12}>
          <Typography variant="caption">{t('general.mandatoryField.text')}</Typography>
        </Grid>
      </Grid>
    </form>
  );
};

export { ApiKeyForm, initialValues };
