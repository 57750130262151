import { EIconTypes } from 'constants/Icons';
import { ERouteLinks } from 'models/route';
import { EPermission } from 'models/permissions';

export interface IMenuItem {
  id: string;
  label: string;
  icon: EIconTypes;
  url: string;
  isNotificationItem?: boolean;
  permissions?: EPermission | EPermission[];
  permissionOrCondition?: boolean;
  items?: string[];
}

export const MenuItems: IMenuItem[] = [
  {
    label: 'module.dashboard.name',
    icon: EIconTypes.home,
    url: ERouteLinks.Dashboard,
    id: 'dashboard',
  },
  {
    label: 'module.legalEntity.name',
    icon: EIconTypes.organization,
    url: ERouteLinks.LegalEntities,
    permissions: [EPermission.MANAGE_LE, EPermission.VIEW_ALL_LE, EPermission.VIEW_LE],
    permissionOrCondition: true,
    id: 'legal_entity',
    items: ['/legal-entity'],
  },
  {
    label: 'module.firearm.name',
    icon: EIconTypes.firearm,
    url: ERouteLinks.Firearms,
    permissions: [
      EPermission.VIEW_FIREARMS,
      EPermission.VIEW_ALL_FIREARMS,
      EPermission.MANAGE_FRT_FIREARM,
    ],
    permissionOrCondition: true,
    id: 'firearm',
    items: ['/registered-firearms'],
  },
  {
    label: 'module.ancillaries.name',
    icon: EIconTypes.ancillaries,
    url: ERouteLinks.Ancillaries,
    permissions: [
      EPermission.VIEW_LIMITED_ANCILLARIES,
      EPermission.VIEW_ALL_ANCILLARIES,
      EPermission.MANAGE_LOCAL_ART,
    ],
    permissionOrCondition: true,
    id: 'ancillaries',
    items: ['/ancillaries-reference-table', '/registered-ancillaries-map'],
  },
  {
    label: 'module.transactions.name',
    icon: EIconTypes.transactions,
    url: ERouteLinks.Transactions,
    permissions: [EPermission.VIEW_TRANSACTIONS, EPermission.VIEW_ALL_TRANSACTIONS],
    permissionOrCondition: true,
    id: 'transactions',
  },
  {
    label: 'module.administration.name',
    icon: EIconTypes.administration,
    url: ERouteLinks.Administration,
    permissions: [
      EPermission.MANAGE_USERS,
      EPermission.MANAGE_ROLES,
      EPermission.MANAGE_CUSTOM_FIELDS,
      EPermission.MANAGE_SETTINGS,
      EPermission.VIEW_AUDIT_LOG,
      EPermission.EXPORT_AUDIT_LOG,
      EPermission.VIEW_AND_MANAGE_LICENCE,
      EPermission.MANAGE_DICTIONARIES,
      EPermission.MANAGE_API_KEYS,
    ],
    permissionOrCondition: true,
    id: 'administration',
    items: [
      '/users',
      '/roles',
      '/role',
      '/dictionaries',
      '/dictionary',
      '/licence-details',
      '/licence',
      '/custom-fields',
      '/audit-log',
      '/settings',
      '/api-keys',
      '/api-key',
    ],
  },
  {
    label: 'module.notifications.name',
    icon: EIconTypes.notification,
    url: ERouteLinks.Notifications,
    isNotificationItem: true,
    id: 'notifications',
    items: ['/notification'],
  },
];
