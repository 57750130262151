export enum EArtAncillaryType {
  ACCESSORIES = '0a0436a7-8971-412e-b97d-6a24af2e5c57',
  AMMUNITION = 'de0bc1e3-173f-42ad-9de7-4a7d6549108f',
  COMPONENTS = 'f02686c9-148f-46ca-ac8c-3c63cd1f7eea',
}

export enum EArtAncillaryTypeName {
  ACCESSORY = 'Accessory',
  AMMUNITION = 'Ammunition',
  COMPONENTS = 'Component',
}

export enum EArtAncillaryExpandType {
  ACCESSORIES = 'accessories',
  AMMUNITION = 'ammunition',
  COMPONENTS = 'components',
}

export enum EArtAncillaryTypeNameLowerCase {
  ACCESSORY = 'accessory',
  AMMUNITION = 'ammunition',
  COMPONENTS = 'component',
}

export enum EArtAncillaryTypeNameUpperCase {
  ACCESSORY = 'ACCESSORY',
  AMMUNITION = 'AMMUNITION',
  COMPONENT = 'COMPONENT',
}

export enum EArtAccessoryType {
  MAGAZINES = 'a0a8a8f2-72e4-4f29-a4cd-f1e24866a632',
  OPTICAL_SIGHTS = '8bb68960-22a8-49d6-a6c4-f9e49580f15e',
  SUPPRESSORS = 'a11221e3-49fc-4663-8fd2-1617ee6f3689',
  FLASH_ELIMINATORS = 'a0e5e7ac-e39a-497b-a8a7-146e6cb839b2',
  LASER_LIGHT_MODULES = '32b71a0b-6463-4f93-ac5b-be0ff788340b',
}

export enum EArtAmmunitionType {
  HANDGUN_RIFLE = '162ae28b-36d4-4e19-a28c-1577da849def',
  SHOTGUN = 'fd4104fd-9375-4120-98b4-fb530f2be23a',
  GRENADE_LAUNCHER = '749ab514-5106-4eac-9391-87ab9d4d4667',
  MORTAR_SHELL = '51c83033-2514-4ed5-9139-ccf38dec6697',
  RECOILLESS_ARTILLERY_SHELL = '73bd94e8-8c10-4615-b919-f0efcf7040ec',
  ROCKET_PROPELLED = '32c67097-8fb0-4cd0-9e89-608383e7a54b',
  MISSILES = '2affbc06-304e-44c7-b5a1-4bf30d0a568d',
}

export enum EArtComponentType {
  UPPER_RECEIVER = 'e80086dc-6bb9-4b72-98a3-c2ecbed7f14e',
  LOWER_RECEIVER = '2f3e4d3d-3834-408b-a0cd-df0d3d718bd9',
  FRAME = '8dadab96-29b5-4ed6-ab7c-7d53fec2a9a1',
  BARREL = '453b3904-88c3-43ea-8383-089607b8a345',
  BOLT = '8ec1b5bd-b7fd-42cf-8cbc-da4e02d0040d',
  SLIDE = 'b9c6a456-533d-478c-b618-4e8aa12510e9',
  CYLINDER = '9ce3b7e2-0679-43a8-8646-afce26f68447',
}

export enum EAncillaryState {
  DISABLED = '3a8da198-8abf-11ee-b9d1-0242ac120002',
  DESTROYED = '95e3c28a-8ac8-11ee-b9d1-0242ac120002',
  DEPLETED = '707980d8-8abf-11ee-b9d1-0242ac120002',
}

export enum EAncillaryFilterTypes {
  ACCESSORY = 'Controlled Accessories',
  AMMUNITION = 'Ammunition',
  COMPONENT = 'Essential Components',
}
