import React, { useEffect, useState } from 'react';
import { ERouteLinks } from 'models/route';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { useTranslations } from 'hooks/useTranslations';
import { useNavigate, useParams } from 'react-router-dom';
import { EPermission } from 'models/permissions';
import { usePermission } from 'hooks/usePermission';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { StyledPageTitle } from 'theme/styles';
import { RegisteredFirearmDetails } from 'components/organisms/RegisteredFirearmDetails';
import { IRegisteredFirearmData } from 'models/registeredFirearm';
import { disableFirearm, getRegisteredFirearm } from 'requests/firearm';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { ActionTypes } from 'state/actions/alert';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { useAlert } from 'models/alertContext';
import Grid from '@mui/material/Grid';
import { EFirearmStateId } from 'models/firearm';

const DisableRegisteredFirearmPage = () => {
  const { t } = useTranslations();
  const { id = '' } = useParams<{ id: string }>();
  const { setAlert } = useAlert();
  const { hasPermissions } = usePermission();
  const navigate = useNavigate();
  const [firearm, setFirearm] = useState<IRegisteredFirearmData>({} as IRegisteredFirearmData);
  const [disableButtons, setDisableButtons] = useState(false);

  useEffect(() => {
    getRegisteredFirearm(id).then((result) => {
      if (result) {
        setFirearm(result);
        if (result.stateUniqueId === EFirearmStateId.DISABLED) {
          navigate(`${ERouteLinks.ViewRegisteredFirearm.replace(':id', id as string)}`);
        }
      }
    });
  }, [id]);

  const handleDisable = () => {
    setDisableButtons(true);
    disableFirearm(firearm.uniqueId).then(async () => {
      setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
        text: t('disableFirearm.firearmDisabled.text'),
        variant: EAlertVariants.success,
      });
      navigate(`${ERouteLinks.ViewRegisteredFirearm.replace(':id', id as string)}`);
    });
  };

  return (
    <>
      <Breadcrumbs
        items={[
          {
            label: t('firearms.registeredFirearms.accordion.name'),
            route: ERouteLinks.Firearms,
          },
          {
            label: t('registeredFirearms.firearmsDetails.header'),
            route: `${ERouteLinks.ViewRegisteredFirearm.replace(':id', id as string)}`,
          },
          t('disableFirearm.disableFirearm.header'),
        ]}
      />
      {hasPermissions([EPermission.DISABLE_FIREARM]) && firearm.state !== 'End' && (
        <Box>
          <Box mb={1}>
            <StyledPageTitle variant="h4">
              {t('registeredFirearms.firearmsDetails.header')}
            </StyledPageTitle>
          </Box>

          <Box mb={4}>
            <Typography variant="body2">{t('disableFirearm.disableInfo1.text')}</Typography>
          </Box>

          <Grid item xs={12} style={{ margin: '10px 0 10px' }}>
            <Alert
              text={t('disableFirearm.disableInfo2.text')}
              variant={EAlertVariants.warning}
            />
          </Grid>

          <RegisteredFirearmDetails firearm={firearm} disableActions={true} />
          <Box mt={5}>
            <Button
              label={t('general.yes.button')}
              id="yes-button"
              variant={EButtonVariants.contained}
              onClick={() => handleDisable()}
              disabled={disableButtons}
              sx={{
                width: { xs: '100%', sm: '250px' },
                margin: { xs: '0px 0px 20px 0px', sm: '0px 20px 0px 0px' },
              }}
            />
            <Button
              label={t('general.no.button')}
              id="no-button"
              variant={EButtonVariants.outlined}
              disabled={disableButtons}
              onClick={() =>
                navigate(`${ERouteLinks.ViewRegisteredFirearm.replace(':id', id as string)}`)
              }
              sx={{
                width: { xs: '100%', sm: '250px' },
              }}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export { DisableRegisteredFirearmPage };
