import { useState } from 'react';
import { ILimitedRole, IRole, IRoleFormValues } from 'models/role';
import {
  countRoles,
  createRole,
  deleteRole,
  getLimitedRoles,
  getRoles,
  updateRole,
} from 'requests/role';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import { IPaginator } from 'models/paginator';
import { EPerPages, ISortOptions } from 'models/table';
import { EAlertVariants } from 'components/atoms/Alert';
import { IPermission } from 'models/permissions';
import { fetchPermissions } from 'requests/permissions';
import { useTranslations } from 'hooks/useTranslations';
import { ISearchFormValues } from './RoleSearchForm';

export type IParsedPermissions = {
  [module: string]: IPermission[];
};

export const MAX_ROLES = 100;

interface IRolesData {
  content: IRole[];
  pagination: {
    [key: string]: any;
  };
}

export const useRoles = () => {
  const { t } = useTranslations();
  const [roles, setRoles] = useState<IRole[]>([]);
  const [limitedRoles, setLimitedRoles] = useState<ILimitedRole[]>([]);
  const [rolesAmount, setRolesAmount] = useState<number | null>(null);
  const [permissions, setPermissions] = useState<IPermission[]>([]);
  const [paginator, setPaginator] = useState<IPaginator | null>(null);

  const { setAlert } = useAlert();

  const getRolesData = async (
    currentPage: number = 1,
    perPage: EPerPages = EPerPages.perPage25,
    query: Partial<ISearchFormValues> = {},
    sort?: ISortOptions | null,
  ): Promise<IRolesData | null> => {
    const rolesData = await getRoles(currentPage, perPage, query, sort);
    if (rolesData) {
      const { content, ...paginatorData } = rolesData;
      setRoles(content);
      setPaginator(paginatorData);
      return {
        content,
        pagination: {
          ...paginatorData,
        },
      };
    }
    setRoles([]);
    setPaginator(null);
    return null;
  };

  const getLimitedRolesData = async (
    currentPage: number = 1,
    perPage: EPerPages = EPerPages.perPage25,
  ): Promise<IRolesData | null> => {
    const rolesData = await getLimitedRoles(currentPage, perPage);
    if (rolesData) {
      const { content, ...paginatorData } = rolesData;
      setLimitedRoles(content);
      setPaginator(paginatorData);
      return {
        content,
        pagination: {
          ...paginatorData,
        },
      };
    }
    setLimitedRoles([]);
    setPaginator(null);
    return null;
  };

  const handleCreate = async (data: IRoleFormValues) => {
    const { formPermissionIds, ...formData } = data;

    const response = await createRole(formData);
    if (response.status === 200) {
      await setAlert(ActionTypes.SET_ROLE_ALERT, {
        text: t('addRole.success.text'),
      });
    } else if (response.status === 400) {
      await setAlert(ActionTypes.SET_ROLE_ALERT, {
        text: t('addRole.roleName.validation.roleNameNotUnique'),
        variant: EAlertVariants.error,
      });
    }

    return response;
  };

  const handleEdit = async (role: IRole) => {
    const { formPermissionIds, ...roleData } = role;
    const response = await updateRole(roleData);
    if (typeof response === 'boolean') {
      if (response) {
        await setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
          text: t('editRole.success.text'),
        });
        return true;
      }
    } else {
      await setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: response,
        variant: EAlertVariants.error,
      });
    }
    return false;
  };

  const handleDelete = async (id: string) => {
    const response = await deleteRole(id);
    if (response) {
      await setAlert(ActionTypes.SET_ROLE_ALERT, {
        text: t('deleteRole.success.text'),
      });
      await getRolesData();
    }
    return response;
  };

  const parsePermissions = () => {
    const result: IParsedPermissions = {};

    if (!permissions) {
      return result;
    }

    permissions.forEach((permission) => {
      if (!result?.[permission.relateModule]) {
        result[permission.relateModule] = [];
      }
      result[permission.relateModule].push(permission);
    });

    return result;
  };

  const getPermissions = async () => {
    const res = await fetchPermissions();
    setPermissions(res);
  };

  const getRolesAmount = async () => {
    const res = await countRoles();
    setRolesAmount(res);
  };

  return {
    alert,
    roles,
    limitedRoles,
    paginator,
    handleCreate,
    handleEdit,
    handleDelete,
    getRolesData,
    getLimitedRolesData,
    getPermissions,
    parsePermissions,
    permissions,
    getRolesAmount,
    rolesAmount,
  };
};
