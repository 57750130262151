import React, { useEffect, useState } from 'react';
import { ISearchFilter, ISearchFilterDictionaries } from 'models/searchFilter';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import Button from 'components/atoms/Button';
import { EButtonSizesPX, EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import {
  StyledFilterChipWrapper,
  StyledPresetWrapper,
  StyledSelectedFiltersWrapper,
} from 'pages/MyFiltersPage/styles';
import { FilterChip } from 'components/atoms/FilterChip';
import { useNavigate } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import { useTranslations } from 'hooks/useTranslations';
import {
  mapValuesToIndividualPresetForView,
  mapValuesToOrganisationPresetForView,
  mapValuesWithDictionariesHelper,
} from 'pages/LegalEntitiesPage/SavePresetPage/helpers';
import { parseDictionary, parseMultipleDictionaries } from 'helpers/dictionary';
import { colors } from 'theme/colors';
import { ESearchFilterTypes } from 'constants/SearchFilterTypes';
import { mapValuesToTransactionPresetForView } from 'pages/TransactionsPage/helpers';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import { preparePresetTransactionFilters } from 'helpers/filters';
import { useLang } from 'models/langContext';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { mapValuesToAncillaryPresetForView } from 'pages/AncillariesPage/SaveAncillariesPresetPage/helpers';
import { EArtAncillaryExpandType, EArtAncillaryType } from 'constants/ArtAccessoryType';
import { useLicenceContext } from 'models/licenceContext';

interface IPresetItem {
  preset: ISearchFilter;
  noRemoveButton?: boolean;
  labels: { [key: string]: any };
  dictionaries?: ISearchFilterDictionaries;
}

const PresetItem = ({ preset, labels, dictionaries, noRemoveButton = false }: IPresetItem) => {
  const [filtersArray, setFiltersArray] = useState<{ key: string; value: string }[]>([]);
  const { shortDateFormat, midDateFormat, getDateFormat } = useGlobalProperty();
  const [ancillaryDictionaryUniqueId] = useState<string | undefined>(
    preset.values.filter((val) => val.key === 'ancillaryDictionaryUniqueId')[0]?.value,
  );
  const { isOnlineLicence } = useLicenceContext();

  const { t } = useTranslations();
  const { selectedLanguage } = useLang();
  const navigate = useNavigate();

  const forbiddenKeys = ['ownerId', 'keeperId'];

  useEffect(() => {
    getDateFormat();
  }, []);
  const filtersWithDictionaries = () => {
    let mappedValues = mapValuesWithDictionariesHelper(preset.values, {
      organizationTypeUniqueId: parseDictionary(dictionaries?.organizationType, 'name'),
      genderUniqueId: parseDictionary(dictionaries?.gender, 'name'),
      visibilityUniqueId: parseDictionary(dictionaries?.legalVisibility, 'name'),
      country: parseDictionary(dictionaries?.country, 'name'),
      legalityUniqueId: parseDictionary(dictionaries?.legality, 'name'),
      stateUniqueId: parseDictionary(dictionaries?.state, 'name'),
      type: parseDictionary(dictionaries?.firearmType, 'name'),
      action: parseDictionary(dictionaries?.firearmAction, 'name'),
      transactionTypeUniqueId: parseDictionary(dictionaries?.transactionType, 'name'),
      ancillaryDictionaryUniqueId: parseDictionary(dictionaries?.artAncillaryOptions, 'name'),
      ancillaryTypeUniqueId: parseMultipleDictionaries(
        [
          dictionaries?.artAccessoryType,
          dictionaries?.artAmmunitionType,
          dictionaries?.artComponentType,
        ],
        'name',
      ),
      manufacturerUniqueId: parseMultipleDictionaries(
        [
          dictionaries?.artAccessoryManufacturerMagazine,
          dictionaries?.artAccessoryManufacturerOpticalSight,
          dictionaries?.artAccessoryManufacturerSuppressor,
          dictionaries?.artAccessoryManufacturerFlashEliminator,
          dictionaries?.artAccessoryManufacturerLaserLightModule,
          dictionaries?.artAmmunitionManufacturer,
          dictionaries?.artComponentManufacturer,
        ],
        'name',
      ),
      calibreUniqueId: parseMultipleDictionaries(
        [
          dictionaries?.artAccessoryCalibre,
          dictionaries?.artAmmunitionCalibreGauge,
          dictionaries?.artComponentCalibre,
        ],
        'name',
      ),
      hostTypeUniqueId: parseDictionary(dictionaries?.artAccessoryHostType, 'name'),
      magnificationUniqueId: parseDictionary(dictionaries?.artAccessoryMagnification, 'name'),
      utilityUniqueId: parseDictionary(dictionaries?.artAccessoryUtility, 'name'),
      reticleUniqueId: parseDictionary(dictionaries?.artAccessoryReticle, 'name'),
      lightColourUniqueId: parseDictionary(dictionaries?.artAccessoryLightColour, 'name'),
      compositionUniqueId: parseMultipleDictionaries(
        [dictionaries?.artAccessoryComposition, dictionaries?.artComposition],
        'name',
      ),
      proofHouseUniqueId: parseMultipleDictionaries(
        [dictionaries?.artAccessoryProofHouse, dictionaries?.artComponentProofHouse],
        'name',
      ),
      accessoryLegality: parseDictionary(dictionaries?.legality, 'name'),
      factoryUniqueId: parseDictionary(dictionaries?.artAmmunitionFactory, 'name'),
      functionalTypeUniqueId: parseDictionary(
        dictionaries?.artAmmunitionFunctionalType,
        'name',
      ),
      cartridgeCompositionUniqueId: parseDictionary(
        dictionaries?.artAmmunitionCartridgeComposition,
        'name',
      ),
      colourOfPrimerSealantUniqueId: parseDictionary(
        dictionaries?.artAmmunitionColourOfPrimerSealant,
        'name',
      ),
      projectileShapeUniqueId: parseDictionary(
        dictionaries?.artAmmunitionProjectileShape,
        'name',
      ),
      shotSizeUniqueId: parseDictionary(dictionaries?.artAmmunitionShotSize, 'name'),
      munitionTypeUniqueId: parseDictionary(dictionaries?.artAmmunitionMunitionType, 'name'),
      munitionVelocityUniqueId: parseDictionary(
        dictionaries?.artAmmunitionMunitionVelocity,
        'name',
      ),
      componentCapacityUniqueId: parseDictionary(dictionaries?.artComponentCapacity, 'name'),
      prefixGroupUniqueId: parseDictionary(dictionaries?.legalEntityPrefix, 'name'),
    });

    switch (preset.type) {
      case ESearchFilterTypes.individual:
        mappedValues = mapValuesToIndividualPresetForView(mappedValues, shortDateFormat);
        break;
      case ESearchFilterTypes.organisation:
        mappedValues = mapValuesToOrganisationPresetForView(mappedValues);
        break;
      case ESearchFilterTypes.transaction:
        mappedValues = mapValuesToTransactionPresetForView(mappedValues, midDateFormat);
        break;
      case ESearchFilterTypes.ancillaries:
        mappedValues = mapValuesToAncillaryPresetForView(mappedValues, shortDateFormat);
        break;
      default:
        break;
    }

    const preparedFiltersArray = preparePresetTransactionFilters(mappedValues, t);
    setFiltersArray(preparedFiltersArray);
  };

  useEffect(() => {
    filtersWithDictionaries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage, dictionaries]);

  const handleShowResult = (presetChoosen: ISearchFilter) => {
    switch (presetChoosen.type) {
      case ESearchFilterTypes.organisation:
        navigate(`${ERouteLinks.LegalEntities}?expand=organisation`, {
          state: { presetOrganisation: presetChoosen.uniqueId },
        });
        break;
      case ESearchFilterTypes.individual:
        navigate(`${ERouteLinks.LegalEntities}?expand=individual`, {
          state: { presetIndividual: presetChoosen.uniqueId },
        });
        break;
      case ESearchFilterTypes.firearm:
        navigate(`${ERouteLinks.Firearms}?expand=registered-firearms`, {
          state: { presetFirearm: presetChoosen.uniqueId },
        });
        break;
      case ESearchFilterTypes.transaction:
        navigate(ERouteLinks.Transactions, {
          state: { presetTransaction: presetChoosen.uniqueId },
        });
        break;
      case ESearchFilterTypes.ancillaries: {
        const ancillaryItem = presetChoosen.values.find(
          (it) => it.key === 'ancillaryDictionaryUniqueId',
        );
        if (ancillaryItem) {
          let expandQueryString = '';
          switch (ancillaryDictionaryUniqueId as EArtAncillaryType) {
            case EArtAncillaryType.ACCESSORIES:
              expandQueryString = `?expand=${EArtAncillaryExpandType.ACCESSORIES}`;
              break;
            case EArtAncillaryType.AMMUNITION:
              expandQueryString = `?expand=${EArtAncillaryExpandType.AMMUNITION}`;
              break;
            case EArtAncillaryType.COMPONENTS:
              expandQueryString = `?expand=${EArtAncillaryExpandType.COMPONENTS}`;
              break;
            default:
              expandQueryString = '';
              break;
          }
          navigate(`${ERouteLinks.Ancillaries}${expandQueryString}`, {
            state: {
              presetAncillaries: presetChoosen.uniqueId,
              ancillaryFilterType: ancillaryItem.value,
            },
          });
        }
        break;
      }
      default:
        break;
    }
  };

  return (
    <StyledPresetWrapper container>
      <Grid item xs={12} display="flex" flexDirection="row" justifyContent="space-between">
        <Grid item xs={3}>
          <Typography variant="subtitle2">{t('saveMyFilter.filterName.label')}</Typography>
          <Typography mt={2} variant="body2">
            {preset.name}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle2">{t('saveMyFilter.notification.label')}</Typography>
          <Chips
            text={
              preset.notificationEnabled
                ? t('saveMyFilter.yes.label')
                : t('saveMyFilter.no.label')
            }
            variant={
              preset.notificationEnabled ? EChipsVariants.primary : EChipsVariants.inactive
            }
          />
        </Grid>
        {isOnlineLicence() && (
          <Grid item xs={3}>
            <Typography variant="subtitle2">
              {t('saveMyFilter.emailNotification.label')}
            </Typography>
            <Chips
              text={
                preset.emailNotificationEnabled
                  ? t('saveMyFilter.yes.label')
                  : t('saveMyFilter.no.label')
              }
              variant={
                preset.emailNotificationEnabled
                  ? EChipsVariants.primary
                  : EChipsVariants.inactive
              }
            />
          </Grid>
        )}
        <Grid
          item
          container
          xs={3}
          spacing={2}
          justifyContent="end"
          sx={{ display: { xs: 'none', md: 'flex' } }}
        >
          <Grid item mt={0.5}>
            {!noRemoveButton && (
              <Button
                label={t('saveMyFilter.showResult.button')}
                variant={EButtonVariants.outlined}
                onClick={() => {
                  handleShowResult(preset);
                }}
                size="small"
                fullWidth
              />
            )}
          </Grid>
          <Grid item xs={1} mt={0.5} mr={2}>
            {!noRemoveButton && (
              <Button
                sizePX={EButtonSizesPX.px32}
                variant={EButtonVariants.outlinedIconTertiary}
                icon={EIconTypes.delete}
                onClick={() =>
                  navigate(ERouteLinks.DeleteFilter.replace(':id', preset.uniqueId))
                }
              />
            )}
          </Grid>
        </Grid>
      </Grid>

      <StyledSelectedFiltersWrapper container rowGap={1}>
        <Grid item>
          <Typography variant="body2">
            {t('saveMyFilter.selectedCriteria.section')}:
          </Typography>
        </Grid>
        <StyledFilterChipWrapper item>
          {filtersArray &&
            filtersArray
              .filter((item) => !forbiddenKeys.includes(item.key))
              .sort((a, b) => {
                if (a.key === 'ancillaryDictionaryUniqueId') return -1;
                if (b.key === 'ancillaryDictionaryUniqueId') return 1;
                const labelA = t(labels?.[a.key as keyof typeof labels]) || a.key;
                const labelB = t(labels?.[b.key as keyof typeof labels]) || b.key;
                return labelA.localeCompare(labelB);
              })
              .map((item: { key: string; value: string }) => (
                <FilterChip
                  key={item.key}
                  name={item.key}
                  label={t(labels?.[item.key as keyof typeof labels]) || item.key}
                  values={item.value}
                />
              ))}
        </StyledFilterChipWrapper>
      </StyledSelectedFiltersWrapper>

      <Grid
        container
        sx={{
          display: { xs: 'flex', md: 'none' },
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTop: {
            xs: `1px solid ${colors.primary25}`,
            lg: 'none',
          },
          mt: {
            xs: 2,
            md: 0,
          },
        }}
        spacing={2}
      >
        <Grid item xs={12} sm={6}>
          <Button
            label={t('saveMyFilter.showResult.button')}
            variant={EButtonVariants.outlined}
            onClick={() => {
              handleShowResult(preset);
            }}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {!noRemoveButton && (
            <Button
              variant={EButtonVariants.outlined}
              label={t('saveMyFilter.delete.button')}
              onClick={() =>
                navigate(ERouteLinks.DeleteFilter.replace(':id', preset.uniqueId))
              }
              size="small"
              fullWidth
            />
          )}
        </Grid>
      </Grid>
    </StyledPresetWrapper>
  );
};

export { PresetItem };
