import styled from '@emotion/styled';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  css,
  Theme,
  Typography,
} from '@mui/material';
import { colors } from 'theme/colors';

const StyledAccordion = styled(Accordion)`
  box-shadow: none;
  margin-bottom: 16px;

  &:before {
    height: 0;
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  background-color: ${colors.primary15};
  border-radius: 4px;

  & .MuiAccordionSummary-root {
    height: 48px;
  }

  & .MuiAccordionSummary-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    ${({ theme }) => `
      ${(theme as Theme).breakpoints.down('sm')} {
        & {
          flex-direction: column;
          align-items: flex-start;
        };
      };
    `};
  }

  &.Mui-expanded {
    background-color: ${colors.primary900};

    &.MuiAccordionSummary-root {
      min-height: 48px;
    }

    & .MuiAccordionSummary-content {
      margin: 10px 0;
    }

    & .MuiAccordionSummary-content h5 {
      color: ${colors.white};
    }

    & .MuiAccordionSummary-expandIconWrapper button {
      background-color: ${colors.white};
    }
  }
`;

export const StyledAccordionCounter = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: ${colors.primary50};
  color: ${colors.primary900};
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  padding: 5px 0;
  text-align: center;
  margin-right: 20px;
`;

interface IStyledAccordionSubtitle {
  expanded: boolean;
}

export const StyledAccordionSubtitle = styled(Typography)<IStyledAccordionSubtitle>`
  margin-right: 15px;
  ${({ expanded }: IStyledAccordionSubtitle) =>
    css`
      ${expanded && 'color: white'};
    `};
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 0;
  margin-top: 20px;
`;

export { StyledAccordion, StyledAccordionSummary, StyledAccordionDetails };
