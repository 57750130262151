import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { Collapse, Typography } from '@mui/material';
import {
  ESortDirectionTypes,
  ISortOptions,
  ITableColumns,
  ITableDataSource,
} from 'models/table';
import { isFunction } from 'helpers/guards';
import { EIconTypes } from 'constants/Icons';
import Icon from 'components/atoms/Icon/Icon';
import { colors } from 'theme/colors';
import { useTranslations } from 'hooks/useTranslations';
import { formatDate } from 'utils/date';
import { Loader } from 'components/atoms/Loader';
import {
  StyledDetails,
  StyledTable,
  StyledTableBody,
  StyledTableBodyCell,
  StyledTableBodyRow,
  StyledTableCellDetails,
  StyledTableCellDetailsTitle,
  StyledTableContainer,
  StyledTableDetails,
  StyledTableDetailsBody,
  StyledTableDetailsBodyCell,
  StyledTableDetailsBodyRow,
  StyledTableDetailsHead,
  StyledTableDetailsHeadCell,
  StyledTableDetailsHeadRow,
  StyledTableHead,
  StyledTableHeadCell,
  StyledTableHeadRow,
} from './styles';

export interface ITranslationKey {
  noResults: string;

  [key: string]: string;
}

interface ITable {
  columns: ITableColumns[];
  dataSource: ITableDataSource[] | null;
  onSort?: ({ property, direction }: ISortOptions) => void;
  translationsKeys?: ITranslationKey | any;
  specificRowColor?: (arg: ITableDataSource) => string;
  specificRowColorHover?: (arg: ITableDataSource) => string;
  loading?: boolean;
  id?: string;
  detailId?: string;
  detailTitle?: string;
  detailsColumns?: ITableColumns[];
  isSortLoaded?: boolean;
  customPaddingIfNoData?: string | undefined;
}

const Table = ({
  columns,
  dataSource,
  onSort = () => {},
  translationsKeys,
  specificRowColor,
  specificRowColorHover,
  loading = true,
  id,
  detailId,
  detailTitle,
  detailsColumns,
  isSortLoaded = true,
  customPaddingIfNoData = undefined,
}: ITable): ReactElement => {
  const { t, loadingT } = useTranslations();
  const [modifiedColumns, setModifiedColumns] = useState<any[]>(columns);
  const [hideLoading, setHideLoading] = useState<boolean>((loading && loadingT) || !loading);
  const [detailsData, setDetailsData] = useState<ITableDataSource | undefined>(undefined);

  useEffect(() => {
    setHideLoading((loading && !loadingT) || !loading);
  }, [loading, loadingT]);

  useEffect(() => {
    setModifiedColumns((previousModifiedColumns) =>
      columns.map((column: ITableColumns) => {
        const previousColumn = previousModifiedColumns.find(({ key }) => key === column.key);

        let title: string | ((props: any) => ReactNode) = '';
        if (typeof column?.title === 'string') {
          title = t(column?.title) || column.title;
        } else {
          title = column?.title;
        }

        return {
          ...column,
          title,
          property: column.key,
          direction: previousColumn?.direction || ESortDirectionTypes.ASC,
        };
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  useEffect(() => {
    if (dataSource?.length && detailId) {
      setDetailsData(dataSource?.find((item) => item.id === detailId));
    } else {
      setDetailsData(undefined);
    }
  }, [detailId, dataSource]);

  const handleSort = (key: string) => {
    const sortableOptions: ISortOptions = {
      property: '',
      direction: ESortDirectionTypes.ASC,
    };
    setModifiedColumns(
      modifiedColumns.map((column) => {
        if (column.key === key) {
          const direction =
            column.direction === ESortDirectionTypes.ASC
              ? ESortDirectionTypes.DESC
              : ESortDirectionTypes.ASC;

          sortableOptions.property = column.key;
          sortableOptions.direction = direction;
          return { ...column, direction };
        }
        column.direction = ESortDirectionTypes.ASC;
        return column;
      }),
    );

    onSort(sortableOptions);
  };

  const defineSortingType = (columnKey: string) => {
    const { direction } = modifiedColumns.find((column) => column.key === columnKey);
    return direction;
  };

  const renderValue = (
    value: any,
    data: any,
    index: number,
    dateFormat?: string,
    render?: (text: string | number | boolean, props: any, index: number) => ReactNode,
  ) => {
    if (isFunction(render)) {
      return render(value, data, index);
    }
    if (dateFormat) {
      return formatDate(value, dateFormat);
    }
    return (
      <Typography variant="body2">
        {value === undefined || value === null || value.toString().trim() === '' ? '-' : value}
      </Typography>
    );
  };

  if (hideLoading) {
    return isSortLoaded ? (
      <StyledTableContainer id={id}>
        <StyledTable sx={{ minWidth: 650 }}>
          <StyledTableHead>
            <StyledTableHeadRow>
              {modifiedColumns.map(
                (col: ITableColumns): ReactElement => (
                  <StyledTableHeadCell
                    key={col.id}
                    justifyContentHead={col.justifyContentHead ?? 'left'}
                  >
                    <div
                      tabIndex={0}
                      onClick={() => col.isSortable !== false && handleSort(col.key)}
                      role="button"
                      style={{
                        ...(col.isCenterTableHead && {
                          display: 'flex',
                          justifyContent: 'center',
                          padding: 0,
                        }),
                        ...(!dataSource?.length &&
                          customPaddingIfNoData !== undefined && {
                            paddingLeft: col.isCenterTableHead
                              ? customPaddingIfNoData
                              : '12px',
                            paddingRight: customPaddingIfNoData,
                          }),
                      }}
                    >
                      <Typography variant="subtitle2" sx={{ width: 'max-content' }}>
                        {isFunction(col.title) ? col.title(col) : col.title}
                      </Typography>
                      {col.isSortable !== false && (
                        <Icon
                          type={
                            defineSortingType(col.key) === ESortDirectionTypes.ASC
                              ? EIconTypes.smallDropdownUp
                              : EIconTypes.smallDropdownDown
                          }
                        />
                      )}
                    </div>
                  </StyledTableHeadCell>
                ),
              )}
            </StyledTableHeadRow>
          </StyledTableHead>
          <StyledTableBody>
            {!!dataSource &&
              dataSource.map(
                (data: ITableDataSource, index): ReactElement => (
                  <>
                    <StyledTableBodyRow
                      /* eslint-disable-next-line react/no-array-index-key */
                      key={`${data.id}_${index}`}
                      rowColor={specificRowColor && specificRowColor(data)}
                      rowColorHover={specificRowColorHover && specificRowColorHover(data)}
                      showDetail={detailId ? data.id === detailId : false}
                    >
                      {columns.map(
                        ({
                          key,
                          render,
                          dateFormat,
                          width,
                          minWidth,
                          align,
                        }: ITableColumns): ReactElement => (
                          <StyledTableBodyCell
                            width={width}
                            maxWidth={width}
                            minWidth={minWidth}
                            /* eslint-disable-next-line react/no-array-index-key */
                            key={`${data.id}_${key}_${index}`}
                            align={align}
                          >
                            {renderValue(data[key], data, index, dateFormat, render)}
                          </StyledTableBodyCell>
                        ),
                      )}
                    </StyledTableBodyRow>
                    {detailId && data.id === detailId && detailsData && (
                      <StyledDetails>
                        <StyledTableCellDetails colSpan={columns.length}>
                          <StyledTableCellDetailsTitle variant="subtitle2">
                            {detailTitle}
                          </StyledTableCellDetailsTitle>
                          <Collapse in={true} timeout="auto" unmountOnExit>
                            <StyledTableDetails>
                              <StyledTableDetailsHead>
                                <StyledTableDetailsHeadRow>
                                  {!!detailsColumns &&
                                    detailsColumns.map(
                                      (col: ITableColumns): ReactElement => (
                                        <StyledTableDetailsHeadCell
                                          key={col.id}
                                          justifyContentHead={col.justifyContentHead ?? 'left'}
                                        >
                                          <Typography
                                            variant="subtitle2"
                                            sx={{ width: 'max-content' }}
                                          >
                                            {isFunction(col.title)
                                              ? col.title(col)
                                              : t(col.title)}
                                          </Typography>
                                        </StyledTableDetailsHeadCell>
                                      ),
                                    )}
                                </StyledTableDetailsHeadRow>
                              </StyledTableDetailsHead>
                              <StyledTableDetailsBody>
                                <StyledTableDetailsBodyRow
                                  /* eslint-disable-next-line react/no-array-index-key */
                                  key={`${detailsData.id}_${index}`}
                                >
                                  {!!detailsColumns &&
                                    detailsColumns.map(
                                      ({
                                        key,
                                        render,
                                        dateFormat,
                                        width,
                                        minWidth,
                                        align,
                                      }: ITableColumns): ReactElement => {
                                        if (key && !(key in detailsData)) {
                                          // console.error(`dataSource doesn't have key of ${key} in columns`);
                                        }
                                        return (
                                          <StyledTableDetailsBodyCell
                                            width={width}
                                            maxWidth={width}
                                            minWidth={minWidth}
                                            /* eslint-disable-next-line react/no-array-index-key */
                                            key={`${detailsData.id}_${key}_${index}`}
                                            align={align}
                                          >
                                            {renderValue(
                                              detailsData[key],
                                              detailsData,
                                              index,
                                              dateFormat,
                                              render,
                                            )}
                                          </StyledTableDetailsBodyCell>
                                        );
                                      },
                                    )}
                                </StyledTableDetailsBodyRow>
                              </StyledTableDetailsBody>
                            </StyledTableDetails>
                          </Collapse>
                        </StyledTableCellDetails>
                      </StyledDetails>
                    )}
                  </>
                ),
              )}
          </StyledTableBody>
        </StyledTable>
        {!dataSource?.length && (
          <Typography variant="subtitle2" my={2} textAlign="center" color={colors.primary100}>
            {t(translationsKeys?.noResults) || 'No results'}
          </Typography>
        )}
      </StyledTableContainer>
    ) : (
      <Loader isLoading={true} />
    );
  }
  return <Loader isLoading={!hideLoading} />;
};

export { Table };
