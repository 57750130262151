export const isPathEqualToRouteName = (path: string, route: string) => {
  const pathSegments = path.split('/');
  const routeSegments = route.split('/');

  if (pathSegments.length === routeSegments.length) {
    for (let i = 0; i < routeSegments.length; i++) {
      if (!routeSegments[i].startsWith(':')) {
        if (pathSegments[i] !== routeSegments[i]) return false;
      }
    }
    return true;
  }
  return false;
};
