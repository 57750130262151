import React from 'react';
import { ITableDataSource } from 'models/table';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { Chips } from 'components/atoms/Chips';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { useTranslations } from 'hooks/useTranslations';
import { useNavigate } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import { emptyFieldHolder } from 'components/molecules/CardList';

interface ILegalEntityAncillaryGroupTableCard {
  data: ITableDataSource;
  renderStateFieldColor: (arg: ITableDataSource) => any;
  id: string | undefined;
  legalEntityType: string;
  handleAddAncillary: (arg: ITableDataSource) => void;
  showAction: boolean | null;
}

const LegalEntityAncillaryGroupTableCard = ({
  data,
  renderStateFieldColor,
  id,
  handleAddAncillary,
  legalEntityType,
  showAction,
}: ILegalEntityAncillaryGroupTableCard) => {
  const { t } = useTranslations();
  const navigate = useNavigate();

  return (
    <Grid
      container
      sx={{
        border: `1px solid ${colors.gray200}`,
        borderRadius: '4px',
        overflow: 'hidden',
        mt: 1,
        backgroundColor: colors.gray100,
      }}
    >
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('legalEntityDetails.ancillaryGroup.accordion.groupName.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.name)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('legalEntityDetails.ancillaryGroup.accordion.ancillaryCount.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.ancillaryAmount)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('legalEntityDetails.ancillaryGroup.accordion.owner.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.owner)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('legalEntityDetails.ancillaryGroup.accordion.keeper.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.keeper)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('legalEntityDetails.ancillaryGroup.accordion.state.column')}
        </Typography>
        <Chips text={data.state} variant={renderStateFieldColor(data)} />
      </Grid>

      {showAction && (
        <Grid
          container
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderTop: {
              xs: `1px solid ${colors.primary25}`,
              lg: 'none',
            },
            m: {
              xs: 2,
              lg: 2,
            },
          }}
          spacing={2}
        >
          <Grid item xs={12} sx={{ mb: { xs: 2, sm: 0 } }}>
            <Typography variant="button" sx={{ textTransform: 'uppercase' }}>
              {t('actions.general.label')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              label={t('legalEntityDetails.ancillaryGroup.accordion.addToGroup.link')}
              variant={EButtonVariants.outlined}
              onClick={() => handleAddAncillary(data)}
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              label={t('legalEntityDetails.ancillaryGroup.accordion.details.link')}
              variant={EButtonVariants.outlined}
              onClick={() => {
                navigate(
                  `${ERouteLinks.ViewLegalEntityAncillaryGroup.replace(
                    ':id',
                    id as string,
                  ).replace(
                    ':groupId',
                    data.id as string,
                  )}?type=${legalEntityType}&expand=ancillaryGroups`,
                );
              }}
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              label={t('legalEntityDetails.ancillaryGroup.accordion.edit.link')}
              variant={EButtonVariants.outlined}
              onClick={() => {
                navigate(
                  `${ERouteLinks.EditAncillaryGroup.replace(':id', id as string).replace(
                    ':groupId',
                    data.id as string,
                  )}?type=${legalEntityType}&expand=ancillaryGroups`,
                );
              }}
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              label={t('legalEntityDetails.ancillaryGroup.accordion.delete.link')}
              variant={EButtonVariants.outlined}
              onClick={() => {
                navigate(
                  `${ERouteLinks.DeleteAncillaryGroup.replace(':id', id as string).replace(
                    ':groupId',
                    data.id as string,
                  )}`,
                );
              }}
              size="small"
              fullWidth
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export { LegalEntityAncillaryGroupTableCard };
