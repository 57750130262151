import styled from '@emotion/styled';
import { IMaskInput } from 'react-imask';
import { css } from '@mui/material';
import { colors } from 'theme/colors';

interface IStyledMaskedFormInput {
  error: boolean;
}

export const StyledMaskedFormInput = styled(IMaskInput)<IStyledMaskedFormInput>`
  border-width: 1px;
  border-style: solid;
  border-color: #d0d5dd;
  border-radius: 4px;
  font-size: 14px;
  height: 1.4375em;
  width: 100%;
  padding: 16px 14px;
  margin-bottom: 3px;

  &:hover {
    outline: 1px solid black;
  }

  &:focus {
    outline: 2px solid #1976d2;
  }

  &::placeholder {
    font-size: 14px;
    font-weight: 400;
    color: ${colors.gray300};
    font-family: 'NotoSansRegular, sans-serif', sans-serif;
    opacity: 1;
  }

  ${(props: IStyledMaskedFormInput) =>
    props.error &&
    css`
      border-color: #d32f2f;

      &:hover {
        outline: 1px solid #d32f2f;
      }

      &:focus {
        outline: 2px solid #d32f2f;
      }
    `}
`;
