import React from 'react';
import { ITableDataSource } from 'models/table';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { Chips } from 'components/atoms/Chips';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { useNavigate, useParams } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import { useQuery } from 'hooks/useQuery';
import { useTranslations } from 'hooks/useTranslations';
import { emptyFieldHolder } from 'components/molecules/CardList';

interface ILegalEntityGroupTableCard {
  data: ITableDataSource;
  renderDataColor: () => any;
  handleAddFirearm: (arg: ITableDataSource) => void;
  showAction: boolean | null;
}

const LegalEntityGroupTableCard = ({
  data,
  renderDataColor,
  handleAddFirearm,
  showAction,
}: ILegalEntityGroupTableCard) => {
  const navigate = useNavigate();
  const { id = '' } = useParams<{ id: string }>();
  const query = useQuery();
  const type: string | null = query.get('type');
  const { t } = useTranslations();
  return (
    <Grid
      container
      sx={{
        border: `1px solid ${renderDataColor().border}`,
        borderRadius: '4px',
        overflow: 'hidden',
        mt: 1,
        backgroundColor: renderDataColor().background,
        maxWidth: 'calc(100vw - 48px)',
      }}
    >
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('groups.accordion.groupName.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.name)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('groups.accordion.firearmCount.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.numberOfFirearm)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('groups.accordion.owner.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.owner)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('groups.accordion.keeper.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.keeper)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('groups.accordion.state.column')}
        </Typography>
        {data.state ? (
          <Chips text={emptyFieldHolder(data.state)} variant={renderDataColor().stateField} />
        ) : (
          '-'
        )}
      </Grid>
      {showAction && (
        <Grid
          container
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderTop: {
              xs: `1px solid ${colors.primary25}`,
              lg: 'none',
            },
            m: {
              xs: 2,
              lg: 2,
            },
          }}
          spacing={2}
        >
          <Grid item xs={12} sx={{ mb: { xs: 2, sm: 0 } }}>
            <Typography variant="button" sx={{ textTransform: 'uppercase' }}>
              {t('groups.accordion.action.column')}:{' '}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              label={t('groupDetails.firearmListSection.addFirearm.button')}
              variant={EButtonVariants.outlined}
              onClick={() => handleAddFirearm(data)}
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              label={t('groups.accordion.edit.link')}
              variant={EButtonVariants.outlined}
              onClick={() =>
                navigate(
                  `${ERouteLinks.LegalEntityEditGroup.replace(':id', id as string).replace(
                    ':groupId',
                    data.id as string,
                  )}?type=${type}&expand=groups`,
                )
              }
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              label={t('groups.accordion.details.link')}
              variant={EButtonVariants.outlined}
              onClick={() =>
                navigate(
                  `${ERouteLinks.ViewLegalEntityGroup.replace(':id', id).replace(
                    ':groupId',
                    data.id as string,
                  )}?type=${type}`,
                )
              }
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              label={t('groups.accordion.delete.link')}
              variant={EButtonVariants.outlined}
              onClick={() =>
                navigate(
                  `${ERouteLinks.LegalEntityDeleteGroup.replace(':id', id as string).replace(
                    ':groupId',
                    data.id as string,
                  )}?type=${type}&expand=groups`,
                )
              }
              size="small"
              fullWidth
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export { LegalEntityGroupTableCard };
