import React from 'react';
import { ITableDataSource } from 'models/table';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { ERouteLinks } from 'models/route';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'hooks/useTranslations';
import { useQuery } from 'hooks/useQuery';
import { emptyFieldHolder } from 'components/molecules/CardList';

interface ILegalEntityFirearmTableCard {
  data: ITableDataSource;
  renderColor: (data: ITableDataSource) => {
    border: string;
    stateField: EChipsVariants;
    background: string;
  };
  showAction: boolean | null;
}

const LegalEntityFirearmTableCard = ({
  data,
  renderColor,
  showAction,
}: ILegalEntityFirearmTableCard) => {
  const navigate = useNavigate();
  const { t } = useTranslations();
  const query = useQuery();
  const type: string = query.get('type') || '';
  return (
    <Grid
      container
      sx={{
        border: `1px solid ${renderColor(data).border}`,
        borderRadius: '4px',
        overflow: 'hidden',
        mt: 1,
        backgroundColor: renderColor(data).background,
      }}
    >
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('legalEntityDetails.firearm.accordion.serialNumber.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.serialNumber)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('legalEntityDetails.firearm.accordion.firecycleId.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.fireCycleId)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('legalEntityDetails.firearm.accordion.make.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.make)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('legalEntityDetails.firearm.accordion.model.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.model)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('legalEntityDetails.firearm.accordion.manufacturer.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.manufacturer)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('legalEntityDetails.firearm.accordion.calibre.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.caliber)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('legalEntityDetails.firearm.accordion.keeper.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.keeper)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('legalEntityDetails.firearm.accordion.state.column')}
        </Typography>
        {data.state ? <Chips text={data.state} variant={renderColor(data).stateField} /> : '-'}
      </Grid>
      {showAction && (
        <Grid
          container
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderTop: {
              xs: `1px solid ${colors.primary25}`,
              lg: 'none',
            },
            m: {
              xs: 2,
              lg: 2,
            },
          }}
          spacing={2}
        >
          <Grid item xs={12} sm={3} sx={{ mb: { xs: 2, sm: 0 } }}>
            <Typography variant="button" sx={{ textTransform: 'uppercase' }}>
              {`${t('actions.general.label')}: `}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Button
              label={t('legalEntityDetails.firearm.accordion.details.link')}
              variant={EButtonVariants.outlined}
              onClick={() =>
                navigate(
                  `${ERouteLinks.ViewRegisteredFirearm.replace(
                    ':id',
                    data.id as string,
                  )}?type=${type}`,
                )
              }
              size="small"
              fullWidth
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export { LegalEntityFirearmTableCard };
