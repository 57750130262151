import React, { createContext, useContext } from 'react';
import { ILanguage } from 'models/language';
import { IMessage } from 'models/message';
import { ITranslation } from 'models/dictionary';

export interface ILangContext {
  getLanguagesData: () => void;
  languages: ILanguage[];
  defaultLanguage: ILanguage | null;
  getTranslationsData: (p: ILanguage | null) => void;
  changeLanguage: (p: ILanguage | null) => any;
  selectedLanguage: ILanguage | null;
  translations: IMessage[];
  getValidTranslationName: (translations: ITranslation[]) => string;
  showKeyTranslations: boolean;
  setShowKeyTranslations: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LangContext = createContext<ILangContext>({} as ILangContext);

export const useLang = (): ILangContext => useContext(LangContext);
