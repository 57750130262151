import styled from '@emotion/styled';
import { colors } from 'theme/colors';
import { css } from '@mui/material';

interface IStyledLocationMarkConfirmationMarginBottom {
  fullWidth: boolean;
}
export const StyledLocationMarkConfirmationMarginBottom = styled.div<IStyledLocationMarkConfirmationMarginBottom>`
  max-width: 295px;
  height: 34px;
  justify-content: space-between !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 6px 16px 6px 16px;
  margin-top: 28px;
  background-color: ${colors.success50};
  border-radius: 4px;
  ${(props: IStyledLocationMarkConfirmationMarginBottom) =>
    props.fullWidth &&
    css`
      max-width: 100%;
    `}
`;

export const StyledIconWrapper = styled.div`
  width: 20px;
  height: 20px;
  padding: 3px;
  border-radius: 2px;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-left: 6px;
  background-color: ${colors.success900};
`;
