import * as yup from 'yup';
import { getFieldsByComponentType } from 'pages/AncillariesPage/helpers';
import { useTranslations } from 'hooks/useTranslations';

const isFieldRequired = (val: string | undefined, obj: any, t: (key: string) => string) => {
  const fields = getFieldsByComponentType(obj.parent.componentTypeUniqueId);
  if (fields.includes(obj.path) && !val) {
    return new yup.ValidationError(t('general.field.validation.required'), null, obj.path);
  }
  return true;
};
const useComponentsFieldsFormSchema = () => {
  const { t } = useTranslations();
  const schema = yup.object({
    componentTypeUniqueId: yup.string().required(t('general.field.validation.required')),

    manufacturerUniqueId: yup
      .string()
      .test('manufacturerUniqueId', '', (val, obj: any) => isFieldRequired(val, obj, t)),

    productName: yup
      .string()
      .max(80, t('general.field.validation.maxCharacters').replace('{0}', '80')),

    calibreUniqueId: yup
      .string()
      .test('calibreUniqueId', '', (val, obj: any) => isFieldRequired(val, obj, t)),

    length: yup
      .string()
      .test('length', '', (val, obj: any) => isFieldRequired(val, obj, t))
      .test('lengthNanValue', t('general.validation.onlyNumbersAllowed.label'), (val) => {
        if (val) {
          try {
            return !Number.isNaN(Number(val));
          } catch (e: any) {
            return false;
          }
        }
        return true;
      })
      .test(
        'lengthValue',
        t('ancillaryReferenceTable.validation.maxLength.text'),
        (val) => Number(val) < 1000000,
      ),
    compositionUniqueId: yup
      .string()
      .test('compositionUniqueId', '', (val, obj: any) => isFieldRequired(val, obj, t)),

    capacityUniqueId: yup
      .string()
      .test('capacityUniqueId', '', (val, obj: any) => isFieldRequired(val, obj, t)),

    proofHouseUniqueId: yup.string(),

    notes: yup
      .string()
      .max(2000, t('general.field.validation.maxCharacters').replace('{0}', '2000')),
  });

  return { schema };
};

export default useComponentsFieldsFormSchema;
