import { useTranslations } from 'hooks/useTranslations';
import * as yup from 'yup';
import { ETransactionTypes } from 'constants/TransactionTypes';
import {
  TRANSACTION_INITIAL_TYPES_FOR_MANUFACTURED_DATE_FIELD,
  TRANSACTION_INITIAL_TYPES_FOR_MANUFACTURED_DATE_FIELD_NOT_MANDATORY,
} from 'pages/LegalEntitiesPage/LegalEntityAddFirearmPage/index';
import { ECustomFieldType, ICustomFieldByObjectType } from 'models/customField';
import { CHARS_LIMIT } from 'components/organisms/FormCustomField';

export const useAddFirearmFormSchema = (customFields: ICustomFieldByObjectType[]) => {
  const { t } = useTranslations();

  const customFieldsValidationsOne: { [key: string]: any } = {};
  const customFieldsValidationsMany: { [key: string]: any } = {};

  customFields.map((customField) => {
    const baseValidation = yup.string().nullable();

    if (customField.customFieldType === ECustomFieldType.TEXT && customField.mandatory) {
      customFieldsValidationsOne[customField.name] = baseValidation.when('firearmsQuantity', {
        is: 'one',
        then: (yupSchema) =>
          yupSchema
            .required(t('general.field.validation.required'))
            .max(
              CHARS_LIMIT,
              t('general.field.validation.maxCharacters').replace(
                '{0}',
                CHARS_LIMIT.toString(),
              ),
            ),
      });
      customFieldsValidationsMany[customField.name] = yup
        .string()
        .nullable()
        .required(t('general.field.validation.required'))
        .max(
          CHARS_LIMIT,
          t('general.field.validation.maxCharacters').replace('{0}', CHARS_LIMIT.toString()),
        );
    } else if (customField.customFieldType === ECustomFieldType.TEXT) {
      customFieldsValidationsOne[customField.name] = baseValidation.when('firearmsQuantity', {
        is: 'one',
        then: (yupSchema) =>
          yupSchema.max(
            CHARS_LIMIT,
            t('general.field.validation.maxCharacters').replace('{0}', CHARS_LIMIT.toString()),
          ),
      });
      customFieldsValidationsMany[customField.name] = yup
        .string()
        .nullable()
        .max(
          CHARS_LIMIT,
          t('general.field.validation.maxCharacters').replace('{0}', CHARS_LIMIT.toString()),
        );
    } else if (customField.mandatory) {
      customFieldsValidationsOne[customField.name] = baseValidation.when('firearmsQuantity', {
        is: 'one',
        then: (yupSchema) => yupSchema.required(t('general.field.validation.required')),
      });
      customFieldsValidationsMany[customField.name] = yup
        .string()
        .nullable()
        .required(t('general.field.validation.required'));
    }

    return false;
  });

  yup.addMethod(yup.array, 'unique', function (message, mapper = (a: any) => a) {
    return this.test('unique', message, (list) =>
      list ? list.length === new Set(list.map(mapper)).size : true,
    );
  });

  const schema = yup.object({
    typeUniqueId: yup.string().required(t('general.field.validation.required')),
    attachments: yup.array().of(
      yup.object({
        attachmentName: yup
          .string()
          .test('unique', t('general.field.validation.unique'), (value, params) => {
            // @ts-ignore
            const attachments = params?.from[1]?.value.attachments ?? [];
            if (!value) return true;
            if (attachments.filter((r: any) => r.attachmentName === value).length > 1) {
              return params.createError({
                path: params.path,
                message: t('general.field.validation.unique'),
              });
            }
            return true;
          })
          .required(t('general.field.validation.required'))
          .max(64, t('general.field.validation.maxCharacters').replace('{0}', '64'))
          .matches(/^[a-zA-Z0-9_ -]+$/, t('general.field.validation.attachmentName'))
          .trim(),
      }),
    ),
    transactionDate: yup.string().when('validationOff', {
      is: false,
      then: yup
        .string()
        .required(t('general.field.validation.required'))
        .test(
          'date-validation',
          t('legalEntity.validation.maxDate').replace('{0}', '255'),
          (value) => {
            if (typeof value !== 'string') {
              return false;
            }
            const maxValidDate = new Date();
            const passedDate = new Date(value);
            return passedDate.valueOf() <= maxValidDate.valueOf();
          },
        ),
      otherwise: yup.string().nullable(),
    }),
    isExhibitNumberChecked: yup.boolean(),
    validationOff: yup.boolean(),
    serialNumber: yup.string().when(['firearmsQuantity', 'validationOff'], {
      is: (firearmsQuantity: string, validationOff: boolean) =>
        firearmsQuantity === 'one' && !validationOff,
      then: yup
        .string()
        .max(10, t('general.field.validation.maxCharacters').replace('{0}', '10'))
        .when('isExhibitNumberChecked', {
          is: false,
          then: yup.string().required(t('general.field.validation.required')),
        }),
      otherwise: yup.string().nullable(),
    }),
    serialNumberConfirmation: yup
      .string()
      .when(['firearmsQuantity', 'validationOff'], {
        is: (firearmsQuantity: string, validationOff: boolean) =>
          firearmsQuantity === 'one' && !validationOff,
        then: yup.string().when('isExhibitNumberChecked', {
          is: false,
          then: yup.string().required(t('general.field.validation.required')),
        }),
        otherwise: yup.string().nullable(),
      })
      .test(
        'serialNumber-confirmation-validation',
        t('general.field.validation.serialNumberConfirmationInvalid'),
        (value, obj) =>
          !(
            obj.parent.firearmsQuantity === 'one' &&
            !obj.parent.validationOff &&
            !obj.parent.isExhibitNumberChecked &&
            obj.parent.serialNumber &&
            value &&
            obj.parent.serialNumber !== value
          ),
      ),
    ...customFieldsValidationsOne,
    externalReferenceNumber: yup.string().when('firearmsQuantity', {
      is: 'one',
      then: (yupSchema) =>
        yupSchema.max(20, t('general.field.validation.maxCharacters').replace('{0}', '20')),
    }),
    exhibitNumber: yup.string().when('firearmsQuantity', {
      is: 'one',
      then: (yupSchema) =>
        yupSchema
          .max(10, t('general.field.validation.maxCharacters').replace('{0}', '10'))
          .when('isExhibitNumberChecked', {
            is: true,
            then: yup.string().required(t('general.field.validation.required')),
          }),
    }),
    manufacturedAt: yup
      .string()
      .nullable()
      .test(
        'manufacture-date-validation',
        t('legalEntity.validation.maxDate'),
        (value, obj) => {
          if (
            !TRANSACTION_INITIAL_TYPES_FOR_MANUFACTURED_DATE_FIELD.includes(
              obj.parent.typeUniqueId,
            )
          ) {
            return true;
          }
          if (typeof value !== 'string') {
            return true;
          }
          if (value === '') {
            return true;
          }
          const maxValidDate = new Date();
          const passedDate = new Date(value);
          return passedDate.valueOf() <= maxValidDate.valueOf();
        },
      )
      .when(['firearmsQuantity', 'typeUniqueId'], {
        is: (firearmsQuantity: string, typeUniqueId: ETransactionTypes) =>
          firearmsQuantity === 'one' &&
          TRANSACTION_INITIAL_TYPES_FOR_MANUFACTURED_DATE_FIELD.includes(typeUniqueId) &&
          !TRANSACTION_INITIAL_TYPES_FOR_MANUFACTURED_DATE_FIELD_NOT_MANDATORY.includes(
            typeUniqueId,
          ),
        then: (yupSchema) => yupSchema.required(t('general.field.validation.required')),
      }),
    firearmsList: yup.array().when(['firearmsQuantity', 'validationOff'], {
      is: (firearmsQuantity: string, validationOff: boolean) =>
        firearmsQuantity === 'many' && !validationOff,
      then: yup.array().of(
        yup.object().shape({
          isExhibitNumberChecked: yup.boolean(),
          serialNumber: yup
            .string()
            .max(10, t('general.field.validation.maxCharacters').replace('{0}', '10'))
            .when('isExhibitNumberChecked', {
              is: true,
              then: yup.string(),
              otherwise: yup.string().required(t('general.field.validation.required')),
            })
            .when(['isExhibitNumberChecked', 'from'], {
              is: (
                isExhibitNumberChecked: boolean,
                from: { value: { firearmsList: any } }[],
              ) => isExhibitNumberChecked && from?.[1]?.value?.firearmsList,
              then: yup.string().test('serialNumber', '', (val, obj: any) => {
                const firearmsList = obj.from?.[1]?.value?.firearmsList;
                if (
                  firearmsList &&
                  firearmsList.filter((item: any) => item.serialNumber === val).length > 1
                ) {
                  return new yup.ValidationError(
                    t('addFirearm.fieldUnique.text'),
                    null,
                    obj.path,
                  );
                }
                return true;
              }),
            })
            .test('uniqueSerialNumber', t('addFirearm.fieldUnique.text'), (val, obj) => {
              const firearmsList = (obj as any)?.from?.[1]?.value?.firearmsList;
              if (
                firearmsList &&
                firearmsList.filter(
                  (item: any) => !!item.serialNumber && item.serialNumber === val,
                ).length > 1
              ) {
                return false;
              }
              return true;
            }),
          serialNumberConfirmation: yup.string().when('isExhibitNumberChecked', {
            is: true,
            then: yup.string(),
            otherwise: yup
              .string()
              .required(t('general.field.validation.required'))
              .test(
                'serialNumber-confirmation-validation',
                t('general.field.validation.serialNumberConfirmationInvalid'),
                (value, obj) =>
                  !(
                    (obj as any).from[1].value.firearmsQuantity === 'many' &&
                    !(obj as any).from[1].value.validationOff &&
                    !obj.parent.isExhibitNumberChecked &&
                    obj.parent.serialNumber &&
                    value &&
                    obj.parent.serialNumber !== value
                  ),
              ),
          }),
          ...customFieldsValidationsMany,
          externalReferenceNumber: yup
            .string()
            .max(20, t('general.field.validation.maxCharacters').replace('{0}', '20')),
          exhibitNumber: yup
            .string()
            .max(10, t('general.field.validation.maxCharacters').replace('{0}', '10'))
            .when('isExhibitNumberChecked', {
              is: true,
              then: yup.string().required(t('general.field.validation.required')),
              otherwise: yup.string(),
            })
            .test('uniqueExhibitNumber', t('addFirearm.fieldUnique.text'), (val, obj) => {
              const firearmsList = (obj as any)?.from?.[1]?.value?.firearmsList;
              if (
                firearmsList &&
                firearmsList.filter(
                  (item: any) => !!item.exhibitNumber && item.exhibitNumber === val,
                ).length > 1
              ) {
                return false;
              }
              return true;
            }),
          manufacturedAt: yup
            .string()
            .nullable()
            .test('manufacturedAt', '', (val, obj: any) => {
              if (
                !val &&
                TRANSACTION_INITIAL_TYPES_FOR_MANUFACTURED_DATE_FIELD.includes(
                  obj.from[1].value.typeUniqueId,
                ) &&
                !TRANSACTION_INITIAL_TYPES_FOR_MANUFACTURED_DATE_FIELD_NOT_MANDATORY.includes(
                  obj.from[1].value.typeUniqueId,
                )
              ) {
                return new yup.ValidationError(
                  t('addFirearm.fieldRequired.text'),
                  null,
                  obj.path,
                );
              }
              return true;
            })
            .test(
              'manufacture-date-validation',
              t('legalEntity.validation.maxDate'),
              (value, obj: any) => {
                if (
                  !TRANSACTION_INITIAL_TYPES_FOR_MANUFACTURED_DATE_FIELD.includes(
                    obj.from[1].value.typeUniqueId,
                  )
                ) {
                  return true;
                }
                if (typeof value !== 'string') {
                  return true;
                }
                if (value === '') {
                  return true;
                }
                const maxValidDate = new Date();
                const passedDate = new Date(value);
                return passedDate.valueOf() <= maxValidDate.valueOf();
              },
            )
            .nullable(),
        }),
      ),
      otherwise: yup.array().nullable(true),
    }),
    groupName: yup.string().when(['isCreateGroup', 'firearmsQuantity'], {
      is: (isCreateGroup: string, firearmsQuantity: string) =>
        isCreateGroup === 'yes' && firearmsQuantity === 'many',
      then: (yupSchema) =>
        yupSchema
          .max(255, t('general.field.validation.maxCharacters').replace('{0}', '255'))
          .required(t('general.field.validation.required')),
    }),
    countryOfImportUniqueId: yup.string().when('validationOff', {
      is: false,
      then: yup.string().when('typeUniqueId', {
        is: ETransactionTypes.DECLARED_IMPORTED,
        then: (yupSchema) => yupSchema.required(t('addFirearm.fieldRequired.text')),
        otherwise: (yupSchema) => yupSchema.nullable(true),
      }),
      otherwise: yup.string().nullable(),
    }),
    importer: yup
      .string()
      .max(250, t('general.field.validation.maxCharacters').replace('{0}', '250')),
  });
  return { schema };
};
