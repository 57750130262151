import { IParsedDictionary } from 'helpers/dictionary';
import { ISearchFormValues } from 'models/transaction';
import { ISearchFilterTransactionRequest } from 'models/searchFilter';
import { clearObject } from 'helpers/objects';
import { parse } from 'date-fns';

export const mapValuesToTransactionPresetRequest = (
  data: ISearchFormValues,
  midDateFormat: string,
): ISearchFilterTransactionRequest => {
  const values: ISearchFilterTransactionRequest = {
    ownerUniqueId: data.ownerUniqueId,
    keeperUniqueId: data.keeperUniqueId,

    transactionTypeUniqueId: data.transactionTypeUniqueId,

    transactionTimeFrom: parse(data.transactionTimeFrom, midDateFormat, new Date()) || '',
    transactionTimeTo: parse(data.transactionTimeTo, midDateFormat, new Date()) || '',
    createTimeFrom: parse(data.createTimeFrom, midDateFormat, new Date()) || '',
    createTimeTo: parse(data.createTimeTo, midDateFormat, new Date()) || '',
    expirationTimeFrom: parse(data.expirationTimeFrom, midDateFormat, new Date()) || '',
    expirationTimeTo: parse(data.expirationTimeTo, midDateFormat, new Date()) || '',

    legalityUniqueId: data.legalityUniqueId,
    asset: data.asset?.toString() || '',
  };
  return clearObject(values);
};

export const mapValuesWithDictionariesHelper = (
  values: { key: string; value: string }[],
  dictionaries: { [key: string]: IParsedDictionary | any },
) =>
  values.map((item) => {
    if (dictionaries?.[item.key] && dictionaries[item.key]?.[item.value]) {
      item.value = dictionaries[item.key]?.[item.value];
    }
    return item;
  });
