import { apiClient } from 'utils/apiClient';
import { ApiEndpoints } from 'models/apiEndpoints';
import { isResponseError } from 'models/apiClient';
import { IAccountRequest, IAccountResponse } from 'models/account';

const getAccount = async (id: string): Promise<IAccountResponse | null> => {
  const { data } = await apiClient.get(ApiEndpoints.GET_ACCOUNT(id));

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getSelfAccount = async (): Promise<IAccountResponse | null> => {
  const { data } = await apiClient.get(ApiEndpoints.GET_SELF_ACCOUNT());

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const updateUser = async (user: IAccountRequest) => {
  const { uniqueId, ...body } = user;
  const { data } = await apiClient.put(ApiEndpoints.UPDATE_USER(uniqueId), body);
  return !isResponseError(data);
};

const updateSelfAccount = async (user: IAccountRequest) => {
  const { uniqueId, ...body } = user;
  const { data } = await apiClient.put(ApiEndpoints.UPDATE_SELF_ACCOUNT(), body);
  return !isResponseError(data);
};

const changePassword = async (currentPassword: string, newPassword: string) => {
  const { data } = await apiClient.put(ApiEndpoints.ACCOUNT_CHANGE_PASSWORD(), {
    currentPassword,
    newPassword,
  });

  return !isResponseError(data);
};

const getAssignedLegalsIdsToUser = async (id: string) => {
  const { data } = await apiClient.get(ApiEndpoints.GET_ASSIGNED_LEGALS_IDS_TO_USER(id));

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getAssignedSelfLegalsIdsToUser = async () => {
  const { data } = await apiClient.get(ApiEndpoints.GET_ASSIGNED_SELF_LEGALS_IDS_TO_USER());

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getAssignedLegalsToUser = async (leIds: string[]) => {
  const { data } = await apiClient.post(ApiEndpoints.GET_ASSIGNED_LEGALS_TO_USER(), leIds);

  if (isResponseError(data)) {
    return [];
  }

  return data;
};

export {
  getAccount,
  getSelfAccount,
  updateUser,
  updateSelfAccount,
  changePassword,
  getAssignedLegalsIdsToUser,
  getAssignedSelfLegalsIdsToUser,
  getAssignedLegalsToUser,
};
