import * as yup from 'yup';

import { useTranslations } from 'hooks/useTranslations';

const useAttachmentFormSchema = () => {
  const { t } = useTranslations();
  const schema = yup.object({
    attachmentName: yup
      .string()
      .required(t('general.field.validation.required'))
      .max(64, t('general.field.validation.maxCharacters').replace('{0}', '64'))
      .matches(/^[a-zA-Z0-9_ -]+$/, t('general.field.validation.attachmentName'))
      .trim(),
    transactionUniqueId: yup.string(),
  });
  return { schema };
};

export default useAttachmentFormSchema;
