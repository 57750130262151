import React from 'react';
import { useTranslations } from 'hooks/useTranslations';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { IFirearm } from 'models/firearm';
import { Chips } from 'components/atoms/Chips';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { useNavigate } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import { parseDictionary } from 'helpers/dictionary';
import { IDictionary } from 'models/dictionary';
import { LegalityTypes } from 'constants/LegalityTypes';
import { renderDataColorByValues } from 'components/molecules/Table/helpers';

interface IFirearmDataListItem {
  data: IFirearm;
  state: IDictionary[] | null;
}

const FirearmDataListItem = ({ data, state }: IFirearmDataListItem) => {
  const { t } = useTranslations();
  const navigate = useNavigate();

  const isIllegal = data.legalityUniqueId === LegalityTypes.ILLEGAL;

  return (
    <Grid
      container
      sx={{
        border: `1px solid ${colors.gray200}`,
        borderRadius: '4px',
        overflow: 'hidden',
        mt: 1,
        backgroundColor: `${isIllegal ? colors.error15 : colors.gray100}`,
      }}
    >
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t(
            'registeredFirearms.firearmsDetails.detailedInformation.section.serialNumber.label',
          )}
        </Typography>
        <Typography variant="body2">{data.serialNumber}</Typography>
      </Grid>

      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t(
            'registeredFirearms.firearmsDetails.detailedInformation.section.firecycleID.label',
          )}
        </Typography>
        <Typography variant="body2">{data.fireCycleId}</Typography>
      </Grid>

      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('registeredFirearms.accordion.make.column')}
        </Typography>
        <Typography variant="body2">{data.make}</Typography>
      </Grid>

      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('registeredFirearms.accordion.model.column')}
        </Typography>
        <Typography variant="body2">{data.model}</Typography>
      </Grid>

      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('registeredFirearms.accordion.state.column')}
        </Typography>
        <Chips
          text={parseDictionary(state, 'name')[data.stateUniqueId]}
          variant={
            renderDataColorByValues(
              data.activityUniqueId,
              data.legalityUniqueId,
              data.stateUniqueId,
            ).stateField
          }
        />
      </Grid>

      <Grid
        container
        sx={{
          margin: '0 10px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTop: {
            xs: `1px solid ${colors.gray200}`,
          },
        }}
        p={2}
      >
        <Grid item xs={12} sm={12}>
          <Button
            label={t('registeredFirearms.firearmsLocation.seeDetails.label')}
            variant={EButtonVariants.outlined}
            onClick={() =>
              navigate(`${ERouteLinks.ViewRegisteredFirearm.replace(':id', data.uniqueId)}`)
            }
            size="small"
            fullWidth
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FirearmDataListItem;
