import React, { useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from 'components/atoms/Button';
import { Pagination } from 'components/molecules/Pagination';
import {
  ComponentsTableColumns,
  mapComponentsToDataSource,
} from 'pages/AncillariesPage/helpers';
import { ERouteLinks } from 'models/route';
import { Table } from 'components/molecules/Table';
import { useTranslations } from 'hooks/useTranslations';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { useAlert } from 'models/alertContext';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ActionTypes } from 'state/actions/alert';
import { CardList } from 'components/molecules/CardList';
import { Loader } from 'components/atoms/Loader';
import { ComponentsSearchForm } from 'pages/AncillariesPage/ComponentsPage/ComponentsSearchForm';
import { ComponentsCard } from 'pages/AncillariesPage/ComponentsPage/ComponentsCard';
import { EPerPages, ISortOptions, ITableDataSource } from 'models/table';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import {
  customFieldsPrepareToSearch,
  objectToQueryString,
  prepareDataWithCustomFieldsToSearch,
  prepareQueryValues,
} from 'helpers/searchQuery';
import {
  IFormElementOptionProp,
  initialAncillariesSearchFormValues,
  ISearchAncillariesFormValues,
} from 'models/form';
import SearchFilterStore from 'state/SearchFilterStore';
import { useQuery } from 'hooks/useQuery';
import { useDrawerClicked } from 'state/contexts/drawer';
import {
  EAncillaryFilterTypes,
  EArtAncillaryExpandType,
  EArtAncillaryType,
} from 'constants/ArtAccessoryType';
import { exportAncillariesCSV } from 'requests/ancillaries';
import { IComponent } from 'models/ancillaries';
import { EPermission } from 'models/permissions';
import {
  ECustomFieldObjectType,
  ECustomFieldType,
  ICustomFieldByObjectType,
} from 'models/customField';
import { useLang } from 'models/langContext';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { EChipsVariants } from 'components/atoms/Chips';
import { renderRowColor, renderRowColorHover } from 'components/molecules/Table/helpers';
import { Tooltip } from '@mui/material';
import { isExportAvailable } from 'constants/Export';
import { EFirearmStateId } from 'models/firearm';
import { Alert, EAlertVariants, IAlert } from 'components/atoms/Alert';
import { Box } from '@mui/system';
import { useAncillaries } from '../hooks';

interface ComponentsPageProps {
  renderDataColor: (data: ITableDataSource) => {
    border: string;
    stateField: EChipsVariants;
    background: string;
  };
  setComponentsAccordionExpanded: (val: boolean | undefined) => void;
}

const ComponentsPage = ({
  renderDataColor,
  setComponentsAccordionExpanded,
}: ComponentsPageProps) => {
  const { isDesktop } = useThemeBreakpoint();
  const { t } = useTranslations();
  const { pathname }: any = useLocation();
  const navigate = useNavigate();
  const [isComponentsLoading, setIsComponentsLoading] = useState<boolean | null>(null);
  const [currentPageComponents, setCurrentPageComponents] = useState<number>(1);
  const [perPageComponents, setPerPageComponents] = useState<EPerPages>(EPerPages.perPage25);
  const [componentsSort, setComponentsSort] = useState<ISortOptions | null>(null);
  const [componentsQuery, setComponentsQuery] = useState<
    Partial<ISearchAncillariesFormValues>
  >({});
  const [isComponentsQueryLoading, setIsComponentsQueryLoading] = useState<boolean>(false);
  const [isSortLoaded, setIsSortLoaded] = useState<boolean>(true);
  const query = useQuery();
  const [showAdvanced, setShowAdvanced] = useState<boolean>(false);
  const [ownerOptions, setOwnerOptions] = useState<IFormElementOptionProp[]>([]);
  const [keeperOptions, setKeeperOptions] = useState<IFormElementOptionProp[]>([]);
  const [initialValues, setInitialValues] = useState<ISearchAncillariesFormValues | null>(
    null,
  );
  const [areInitialValuesLoaded, setAreInitialValuesLoaded] = useState(false);
  const [areCustomFieldsLoaded, setAreCustomFieldsLoaded] = useState(false);
  const { drawerClicked } = useDrawerClicked();
  const { selectedLanguage } = useLang();
  const { shortDateFormat } = useGlobalProperty();
  const { state: routerState }: any = useLocation();
  const [searchParams] = useSearchParams();
  const isFromMapQuery: boolean =
    query.get('fromMap') === 'true' || routerState?.componentsUniqueIds?.length > 0;
  const urlQuery = new URLSearchParams(searchParams);
  const expandType = urlQuery.get('expand');
  const storedComponents = SearchFilterStore.getComponents();
  const [isExportLoading, setExportLoading] = useState(false);
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const { setAlert, clearAlert, componentAlert } = useAlert();

  const scrollPosition = useRef(0);

  const uniqueIds =
    storedComponents.length > 0
      ? storedComponents.map((componentItem: IComponent) => componentItem.uniqueId)
      : routerState?.componentsUniqueIds;

  const {
    paginatorComponents,
    components,
    getComponentsData,
    getCustomField,
    customFieldsComponents,
  } = useAncillaries();

  useEffect(() => {
    if (isSortLoaded) {
      window.scrollTo({ top: scrollPosition.current, behavior: 'auto' });
      scrollPosition.current = 0;
    }
  }, [isSortLoaded]);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_NEXT_PAGE_ALERT);
      clearAlert(ActionTypes.CLEAR_COMPONENT_ALERT);
      setIsComponentsLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    (async () => {
      setAreCustomFieldsLoaded(false);
      await getCustomField(ECustomFieldObjectType.AN_COMPONENT);
      setAreCustomFieldsLoaded(true);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (drawerClicked) {
      // SearchFilterStore.setComponents([]);
      window.history.replaceState({}, '', `${pathname}`);
      setOwnerOptions([]);
      setKeeperOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerClicked]);

  const previousComponentsSortRef = useRef(componentsSort);

  useEffect(() => {
    (async function getDataInit() {
      if (!components.length) {
        setIsComponentsLoading(true);
      }
      setIsPaginationLoading(true);

      if (isFromMapQuery) {
        if (uniqueIds && uniqueIds.length) {
          componentsQuery.uniqueIds = uniqueIds;
        } else {
          delete componentsQuery.uniqueIds;
        }
      }
      if (componentsSort !== previousComponentsSortRef.current) {
        setIsSortLoaded(false);
      }
      if (Object.keys(componentsQuery).length !== 0) {
        customFieldsPrepareToSearch(componentsQuery.customFields);
        const componentsQueryData = prepareDataWithCustomFieldsToSearch(
          componentsQuery,
          customFieldsComponents.filter(
            (item: ICustomFieldByObjectType) => item.searchCriteria,
          ),
        );
        await getComponentsData(
          currentPageComponents,
          perPageComponents,
          {
            ...componentsQueryData,
          },
          componentsSort,
        );
      }

      setIsComponentsLoading(false);
      setIsComponentsQueryLoading(false);
      setIsPaginationLoading(false);
      setIsSortLoaded(true);
      previousComponentsSortRef.current = componentsSort;
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPageComponents,
    perPageComponents,
    componentsQuery,
    componentsSort,
    selectedLanguage,
  ]);

  useEffect(() => {
    if (initialValues !== undefined && areCustomFieldsLoaded) {
      setAreInitialValuesLoaded(true);
    }
  }, [initialValues, areCustomFieldsLoaded]);

  useEffect(() => {
    if (areCustomFieldsLoaded) {
      setAreInitialValuesLoaded(false);
      if (expandType === EArtAncillaryExpandType.COMPONENTS) {
        setShowAdvanced(true);
        const queryValues = prepareQueryValues(
          initialAncillariesSearchFormValues,
          urlQuery,
          [
            ...customFieldsComponents
              .filter((cf) => cf.customFieldType === ECustomFieldType.DATE)
              .map((cf) => [`from_${cf.name}`, `to_${cf.name}`]),
          ].flat(),
        );
        setInitialValues({ ...initialAncillariesSearchFormValues, ...queryValues });
      } else {
        setInitialValues(initialAncillariesSearchFormValues);
        setAreInitialValuesLoaded(true);
      }
    }
  }, [searchParams, areCustomFieldsLoaded]);

  const getNewQueryPath = (values: Partial<ISearchAncillariesFormValues>): string => {
    const valuesWithoutPreset = { ...values };
    valuesWithoutPreset.preset = undefined;
    valuesWithoutPreset.formSubmitted = undefined;
    const objQuery = objectToQueryString(valuesWithoutPreset);

    if (
      objQuery.length &&
      (!isInitialLoad || expandType === EArtAncillaryExpandType.COMPONENTS)
    ) {
      setIsInitialLoad(false);
      return `${pathname}?expand=components&${objQuery}`;
    }
    if (values.formSubmitted === true) {
      return `${pathname}?expand=components`;
    }
    setIsInitialLoad(false);
    return `${pathname}`;
  };

  const handleSetComponentsQuery = (values: Partial<ISearchAncillariesFormValues>) => {
    const valuesWithoutPreset = { ...values };
    valuesWithoutPreset.preset = undefined;
    valuesWithoutPreset.formSubmitted = undefined;

    window.history.replaceState({}, '', getNewQueryPath(values));

    setIsComponentsQueryLoading(true);
    setComponentsQuery(valuesWithoutPreset);
    if (
      routerState?.presetAncillaries &&
      routerState?.ancillaryFilterType === EAncillaryFilterTypes.COMPONENT
    ) {
      setComponentsAccordionExpanded(true);
    }
  };

  const exportCsv = async () => {
    setExportLoading(true);
    componentsQuery.ancillaryDictionaryUniqueId = EArtAncillaryType.COMPONENTS;
    await exportAncillariesCSV(componentsQuery, componentsSort);
    setExportLoading(false);
  };

  const handleRedirectToEditPageButton = (item: ITableDataSource) => {
    if (customFieldsComponents?.length && item.stateUniqueId !== EFirearmStateId.DISABLED) {
      navigate(
        ERouteLinks.EditAncillary.replace(':id', item.id as string).replace(
          ':type',
          'component',
        ),
      );
    } else if (item.stateUniqueId === EFirearmStateId.DISABLED) {
      setAlert(ActionTypes.SET_COMPONENT_ALERT, {
        text: t('ancillary.disabled.edit.info'),
        variant: EAlertVariants.error,
      } as IAlert);
    } else {
      setAlert(ActionTypes.SET_COMPONENT_ALERT, {
        text: t('general.noAvaibleDataToEdit.label'),
        variant: EAlertVariants.warning,
      } as IAlert);
    }
  };

  return (
    <div>
      {initialValues && areInitialValuesLoaded && areCustomFieldsLoaded && (
        <ComponentsSearchForm
          ownerOptions={ownerOptions}
          keeperOptions={keeperOptions}
          setOwnerOptions={setOwnerOptions}
          setKeeperOptions={setKeeperOptions}
          showAdvanced={showAdvanced}
          componentsUniqueIds={uniqueIds}
          isFromMap={isFromMapQuery}
          isSearchButtonDisabled={isComponentsLoading || isComponentsQueryLoading}
          returnPath={`${pathname}?expand=components`}
          getNewQueryPath={getNewQueryPath}
          setShowAdvanced={setShowAdvanced}
          onSubmit={handleSetComponentsQuery}
          initialFormValues={initialValues ?? undefined}
          customFields={customFieldsComponents.filter(
            (item: ICustomFieldByObjectType) => item.searchCriteria,
          )}
        />
      )}
      <Grid
        item
        xs={12}
        container
        gap={1}
        sx={{ display: 'flex', justifyContent: 'flex-end', margin: '10px 0' }}
      >
        {!isComponentsLoading && (
          <>
            {isExportAvailable(paginatorComponents) ? (
              <Tooltip title={t('dataExportRecordsLimitAmount.label')} arrow>
                <span>
                  <Button
                    variant={EButtonVariants.outlined}
                    size={EButtonSizes.small}
                    permission={[EPermission.EXPORT_ANCILLARIES]}
                    label={t('ancillaries.accordion.export.button')}
                    type="submit"
                    onClick={exportCsv}
                    isLoading={isExportLoading}
                    disabled
                  />
                </span>
              </Tooltip>
            ) : (
              <Button
                variant={EButtonVariants.outlined}
                size={EButtonSizes.small}
                permission={[EPermission.EXPORT_ANCILLARIES]}
                label={t('ancillaries.accordion.export.button')}
                type="submit"
                onClick={exportCsv}
                isLoading={isExportLoading}
              />
            )}
            <Button
              variant={EButtonVariants.contained}
              size={EButtonSizes.small}
              label={t('ancillaries.accordion.showOnMap.button')}
              onClick={() => {
                navigate(ERouteLinks.RegisteredAncillariesMap, {
                  state: {
                    ancillaryType: 'component',
                    ancillaryQuery: componentsQuery,
                    showAdvanced,
                    options: { ownerOptions, keeperOptions },
                    navigateBack: -1,
                  },
                });
              }}
            />
          </>
        )}
      </Grid>
      {isComponentsLoading || isComponentsQueryLoading ? (
        <Loader isLoading={true} />
      ) : (
        <>
          {isDesktop ? (
            <Table
              columns={ComponentsTableColumns(
                {
                  handleEdit: handleRedirectToEditPageButton,
                  customFields: customFieldsComponents,
                  t,
                },
                (data: ITableDataSource) => renderDataColor(data).stateField,
                selectedLanguage?.uniqueId,
                shortDateFormat,
              )}
              specificRowColor={renderRowColor}
              specificRowColorHover={renderRowColorHover}
              dataSource={mapComponentsToDataSource(components)}
              onSort={(it) => {
                setComponentsSort(it);
                scrollPosition.current = window.scrollY;
              }}
              translationsKeys={{
                noResults: 'ancillaries.accordion.noResults.text',
              }}
              isSortLoaded={isSortLoaded}
              customPaddingIfNoData="30px"
            />
          ) : (
            <CardList
              items={mapComponentsToDataSource(components)}
              render={(data, index) => (
                <ComponentsCard
                  data={data}
                  key={index}
                  renderColor={renderDataColor}
                  handleView={(id: string) =>
                    navigate(
                      ERouteLinks.ViewAncillary.replace(':id', id).replace(
                        ':type',
                        'component',
                      ),
                    )
                  }
                  handleEdit={handleRedirectToEditPageButton}
                />
              )}
            />
          )}

          {paginatorComponents && (
            <Pagination
              count={paginatorComponents?.totalElements}
              perPage={perPageComponents}
              onChangePage={(page) => {
                setCurrentPageComponents(page);
              }}
              onChangePerPage={(value) => {
                setPerPageComponents(value);
              }}
              current={currentPageComponents}
              isLoading={isPaginationLoading}
              isVisible={isSortLoaded}
            />
          )}
        </>
      )}
      {componentAlert && (
        <Box mt={2} sx={{ marginBottom: '10px' }}>
          <Alert text={componentAlert.text} variant={componentAlert.variant} />
        </Box>
      )}
    </div>
  );
};

export default ComponentsPage;
