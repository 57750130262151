import { IPaginator } from 'models/paginator';

export interface ICustomFieldFormValues {
  name: string;
  customFieldType: string;
  objectType: string;
  uniqueValue: boolean;
  inputMaskEnabled: boolean;
  inputMask: string | null;
  enabledString: string;
  enabled: boolean;
  mandatory: boolean;
  searchCriteria: boolean;
  visibleInSearchResults: boolean;
  placeholder?: ITranslation;
  translations: ITranslation;
}

export interface ICustomFieldUpdateRequest {
  name?: string;
  enabled?: boolean;
  searchCriteria?: boolean;
  visibleInSearchResults?: boolean;
  placeholder?: ITranslation;
  translations?: ITranslation;
}

export interface ICustomFieldUpdateFormValues extends ICustomFieldUpdateRequest {
  enabledString?: string;
}

interface ITranslation {
  [key: string]: string;
}

export const initialCustomFieldFormValues: ICustomFieldFormValues = {
  name: '',
  customFieldType: '',
  objectType: '',
  uniqueValue: false,
  inputMaskEnabled: false,
  inputMask: '',
  enabledString: '',
  enabled: false,
  mandatory: false,
  searchCriteria: false,
  visibleInSearchResults: false,
  placeholder: {},
  translations: {},
};

export interface ICustomFieldRequest {
  name: string;
  customFieldType: string;
  objectType: string;
  uniqueValue: boolean;
  inputMaskEnabled: boolean;
  enabled: boolean;
  mandatory: boolean;
  searchCriteria: boolean;
  visibleInSearchResults: boolean;
}

export enum ECustomFieldObjectType {
  FIREARM = 'FIREARM',
  TRANSACTION = 'TRANSACTION',
  LE_INDIVIDUAL = 'LE_INDIVIDUAL',
  LE_ORGANIZATION = 'LE_ORGANIZATION',
  AN_COMPONENT = 'AN_COMPONENT',
  AN_ACCESSORY = 'AN_ACCESSORY',
  AN_AMMUNITION = 'AN_AMMUNITION',
}

export enum ECustomFieldType {
  TEXT = 'TEXT',
  BOOL = 'BOOL',
  DATE = 'DATE',
}

export enum ECustomFieldStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export interface ICustomField {
  uniqueId: string;
  name: string;
  inputMask: string | null;
  customFieldType: ECustomFieldType;
  objectType: ECustomFieldObjectType;
  inputMaskEnabled: boolean;
  uniqueValue: boolean;
  enabled: boolean;
  mandatory: boolean;
  searchCriteria: boolean;
  visibleInSearchResults: boolean;
  deletable: boolean;
  placeholderUniqueId?: string;
  dictionaryUniqueId: string;
}

export interface ICustomFieldByObjectType {
  uniqueId: string;
  name: string;
  translations: {
    uniqueId: string;
    languageUniqueId: string;
    name: string;
  }[];
  inputMask: string | RegExp | null;
  customFieldType: ECustomFieldType;
  objectType: ECustomFieldObjectType;
  inputMaskEnabled: boolean;
  uniqueValue: boolean;
  enabled: boolean;
  mandatory: boolean;
  searchCriteria: boolean;
  placeholder?: {
    uniqueId: string;
    languageUniqueId: string;
    name: string;
  }[];
  visibleInSearchResults: boolean;
}

export interface ICustomFieldByObjectTypeResponse extends IPaginator {
  content: ICustomFieldByObjectType[];
}

export interface ICustomFieldProperties {
  limit: boolean;
  maxActiveCustomFields: number;
  maxPossibleCustomFields: number;
}
