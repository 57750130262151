import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { ERouteLinks } from 'models/route';
import { StyledPageTitle } from 'theme/styles';
import { useTranslations } from 'hooks/useTranslations';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { useNavigate } from 'react-router-dom';
import { useCustomFields } from 'pages/CustomFieldsPage/hooks';
import { Accordion } from 'components/molecules/Accordion';
import {
  ECustomFieldObjectType,
  ICustomField,
  ICustomFieldProperties,
} from 'models/customField';
import CustomFieldCardList from 'pages/CustomFieldsPage/CustomFieldCardList';
import Grid from '@mui/material/Grid';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { Box, Typography } from '@mui/material';
import { StyledNoResults } from 'pages/CustomFieldsPage/styles';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { mapCustomFieldToLabel } from './helpers';

const CustomFieldsPage = () => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { handleGetCustomFields, customFields, handleGetCustomFieldProperties } =
    useCustomFields();
  const { nextPageAlert, clearAlert } = useAlert();
  const [properties, setProperties] = useState<ICustomFieldProperties>();
  const [isLoaded, setIsLoaded] = useState(false);
  const { isDesktop } = useThemeBreakpoint();

  useEffect(() => {
    (async () => {
      await handleGetCustomFields();
      setIsLoaded(true);
      const cfProperties = await handleGetCustomFieldProperties();
      if (cfProperties) {
        setProperties(cfProperties);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_NEXT_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const calculateEnabledFields = (cfields: ICustomField[] | undefined) => {
    if (!cfields || !properties) {
      return '';
    }
    const filterEnabled = cfields.filter((item) => item.enabled);
    return `${t('customFields.enabledFields.label')} ${filterEnabled.length}/${
      properties.maxActiveCustomFields
    }`;
  };

  const isReachedLimit = (cfields: ICustomField[] | undefined) => {
    if (!cfields || !properties) {
      return false;
    }

    const activeCF = cfields.reduce((acc, item) => {
      if (item.enabled) {
        return acc + 1;
      }
      return acc;
    }, 0);

    return activeCF >= properties.maxActiveCustomFields;
  };

  const isAnyCustomFields =
    customFields &&
    Object.keys(customFields).some(
      (item: string) => customFields[item as ECustomFieldObjectType]?.length,
    );

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.administration.name'), route: ERouteLinks.Administration },
          t('customFields.header'),
        ]}
      />

      {isLoaded && (
        <>
          <Grid container sx={{ margin: '20px 0' }}>
            <Grid item lg={6} xs={12}>
              <StyledPageTitle variant="h4">{t('customFields.header')}</StyledPageTitle>
            </Grid>
            {isDesktop && (
              <Grid item lg={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant={EButtonVariants.contained}
                  label={t('customFields.add.button')}
                  onClick={() => navigate(ERouteLinks.AddCustomField)}
                />
              </Grid>
            )}
          </Grid>

          <Typography variant="body2" sx={{ marginBottom: '10px' }}>
            {t('customFields.limit.label')}
          </Typography>

          {!isDesktop && (
            <Grid
              item
              xs={12}
              sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}
            >
              <Button
                variant={EButtonVariants.contained}
                label={t('customFields.add.button')}
                onClick={() => navigate(ERouteLinks.AddCustomField)}
                fullWidth={true}
              />
            </Grid>
          )}

          {nextPageAlert && (
            <Box sx={{ marginBottom: '16px' }}>
              <Alert text={nextPageAlert.text} variant={nextPageAlert.variant} />
            </Box>
          )}

          {isAnyCustomFields ? (
            Object.keys(customFields).map(
              (customFieldKey) =>
                !!customFields[customFieldKey as ECustomFieldObjectType]?.length && (
                  <Accordion
                    title={t(mapCustomFieldToLabel(customFieldKey))}
                    subtitle={calculateEnabledFields(
                      customFields[customFieldKey as ECustomFieldObjectType],
                    )}
                    name={customFieldKey}
                  >
                    {isReachedLimit(
                      customFields[customFieldKey as ECustomFieldObjectType],
                    ) && (
                      <Alert
                        text={`${t('customField.details.theLimitOf')} ${
                          properties?.maxActiveCustomFields
                        } ${t(
                          'customField.details.enabledCustomFieldsForThisObjectTypeHasBeenReached',
                        )}`}
                        variant={EAlertVariants.warning}
                      />
                    )}
                    {customFields[customFieldKey as ECustomFieldObjectType]?.map(
                      (customField) => (
                        <CustomFieldCardList
                          data={customField}
                          key={customField.uniqueId}
                          canBeEnabled={
                            !isReachedLimit(
                              customFields[customFieldKey as ECustomFieldObjectType],
                            )
                          }
                          maxActive={properties?.maxActiveCustomFields}
                        />
                      ),
                    )}
                  </Accordion>
                ),
            )
          ) : (
            <StyledNoResults>{t('customFields.noResult.label')}</StyledNoResults>
          )}
        </>
      )}
    </>
  );
};

export default CustomFieldsPage;
