import React from 'react';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { DetailsTemplate, EDetailsPageTypes } from 'components/templates/DetailsTemplate';
import { ILegalEntityGroupResponse } from 'models/legalEntity';
import { ERouteLinks } from 'models/route';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'hooks/useQuery';
import { useTranslations } from 'hooks/useTranslations';
import { EPermission } from 'models/permissions';
import { getLegalEntityType } from 'components/organisms/RegisteredFirearmDetails';
import { StyledLegalLink } from 'components/atoms/StyledLegalLink';

interface ILegalEntityDetailsProps {
  legalEntityGroup: ILegalEntityGroupResponse;
}

const LegalEntityGroupDetails = ({ legalEntityGroup }: ILegalEntityDetailsProps) => {
  const navigate = useNavigate();
  const { id = '' } = useParams<{ id: string }>();
  const query = useQuery();
  const type: string | null = query.get('type');
  const { t } = useTranslations();
  return (
    <div>
      {legalEntityGroup && (
        <DetailsTemplate
          title={legalEntityGroup.name}
          pageType={EDetailsPageTypes.view}
          deletePermissions={[EPermission.MANAGE_GROUPS_LE]}
          editPermissions={[EPermission.MANAGE_GROUPS_LE]}
          redirectToEditView={() =>
            navigate(
              `${ERouteLinks.LegalEntityEditGroup.replace(':id', id).replace(
                ':groupId',
                legalEntityGroup.uniqueId,
              )}?type=${type}`,
            )
          }
          redirectToView={() =>
            navigate(
              `${ERouteLinks.LegalEntityDeleteGroup.replace(':id', id).replace(
                ':groupId',
                legalEntityGroup.uniqueId,
              )}?type=${type}&expand=groups`,
            )
          }
        >
          <Grid item xs={12} sm={6} lg={3} container direction="column">
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('groupDetails.owner.label')}
            </Typography>
            <Typography variant="body2" id="owner">
              <StyledLegalLink
                legalId={legalEntityGroup.ownerUniqueId}
                legalType={getLegalEntityType(legalEntityGroup.ownerType)}
                labelText={legalEntityGroup.owner}
                legalVisibilityUniqueId={legalEntityGroup.ownerVisibilityUniqueId}
              />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={3} container direction="column">
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('groupDetails.keeper.label')}
            </Typography>
            <Typography variant="body2" id="keeper">
              <StyledLegalLink
                legalId={legalEntityGroup.keeperUniqueId}
                legalType={getLegalEntityType(legalEntityGroup.keeperType)}
                labelText={legalEntityGroup.keeper}
                legalVisibilityUniqueId={legalEntityGroup.keeperVisibilityUniqueId}
              />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={3} container direction="column">
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('groupDetails.totalFirearmCount.label')}
            </Typography>
            <Typography variant="body2" id="totalFirearmCount">
              {legalEntityGroup.firearmAmount}
            </Typography>
          </Grid>
        </DetailsTemplate>
      )}
    </div>
  );
};

export { LegalEntityGroupDetails };
