import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { useTranslations } from 'hooks/useTranslations';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { StyledPageTitle } from 'theme/styles';
import { mapDictionaryToOptionProp } from 'helpers/dictionary';
import { EArtAncillaryType, EArtAncillaryTypeName } from 'constants/ArtAccessoryType';
import { useAncillaries } from 'pages/AncillariesPage/hooks';
import { EDictionaryTypes } from 'models/dictionary';
import { useLang } from 'models/langContext';
import ARTAccessoriesForm from 'pages/AncillariesPage/AncillariesReferenceTablePage/ARTAccessoriesForm';
import { IARTAccessoriesFormValues, IARTComponentsFormValues } from 'models/ancillaries';
import { Box } from '@mui/material';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import { ERouteLinks } from 'models/route';
import ARTAmmunitionForm from 'pages/AncillariesPage/AncillariesReferenceTablePage/ARTAmmunitionForm';
import { IAmmunitionFormValues } from 'pages/AncillariesPage/AncillariesReferenceTablePage/AncillariesReferenceAmmunitionSearchForm';
import ARTComponentsForm from 'pages/AncillariesPage/AncillariesReferenceTablePage/ARTComponentsForm';
import { StyledSelect } from '../styles';
import { AncillaryStyle, FormStyle } from './styles';

const EditAncillariesReference = () => {
  const { type, id } = useParams<{
    type: string;
    id: string;
  }>();
  const { t } = useTranslations();
  const { selectedLanguage } = useLang();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [ancillaryValue, setAncillaryValue] = useState<EArtAncillaryType | undefined>(
    undefined,
  );
  const [initialData, setInitialData] = useState<
    IARTAccessoriesFormValues | IAmmunitionFormValues | IARTComponentsFormValues | undefined
  >(undefined);
  const {
    loadDictionaries,
    artAncillaryOptions,
    handleUpdateAccessory,
    handleUpdateAmmunition,
    handleUpdateComponent,
    getARTAccessoryDetail,
    getARTAmmunitionDetail,
    getARTComponentDetail,
    artAccessoryDetail,
    artAmmunitionDetail,
    artComponentDetail,
  } = useAncillaries();
  const { samePageAlert, setAlert, clearAlert } = useAlert();

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (type && id) {
      if (type === EArtAncillaryTypeName.ACCESSORY.toLowerCase()) {
        (async () => {
          setAncillaryValue(EArtAncillaryType.ACCESSORIES);
          await getARTAccessoryDetail(id);
        })();
      } else if (type === EArtAncillaryTypeName.AMMUNITION.toLowerCase()) {
        (async () => {
          setAncillaryValue(EArtAncillaryType.AMMUNITION);
          await getARTAmmunitionDetail(id);
        })();
      } else if (type === EArtAncillaryTypeName.COMPONENTS.toLowerCase()) {
        (async () => {
          setAncillaryValue(EArtAncillaryType.COMPONENTS);
          await getARTComponentDetail(id);
        })();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, id]);

  useEffect(() => {
    (async function initDictionaries() {
      await loadDictionaries([EDictionaryTypes.ArtAncillaryOptions]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  useEffect(() => {
    if (type && id && artAncillaryOptions && initialData) {
      setIsLoading(false);
    }
  }, [type, id, artAncillaryOptions, initialData]);

  useEffect(() => {
    if (ancillaryValue === EArtAncillaryType.ACCESSORIES) {
      setInitialData({
        accessoryTypeUniqueId: artAccessoryDetail?.accessoryTypeUniqueId || '',
        manufacturerUniqueId: artAccessoryDetail?.manufacturerUniqueId || '',
        productName: artAccessoryDetail?.productName || '',
        hostTypeUniqueId: artAccessoryDetail?.hostTypeUniqueId || '',
        calibreUniqueId: artAccessoryDetail?.calibreUniqueId || '',
        magnificationUniqueId: artAccessoryDetail?.magnificationUniqueId || '',
        utilityUniqueId: artAccessoryDetail?.utilityUniqueId || '',
        rectileUniqueId: artAccessoryDetail?.rectileUniqueId || '',
        lightColourUniqueId: artAccessoryDetail?.lightColourUniqueId || '',
        compositionUniqueId: artAccessoryDetail?.compositionUniqueId || '',
        capacity: artAccessoryDetail?.capacity || '',
        proofHouseUniqueId: artAccessoryDetail?.proofHouseUniqueId || '',
        notes: artAccessoryDetail?.notes || '',
      });
    } else if (ancillaryValue === EArtAncillaryType.AMMUNITION) {
      setInitialData({
        ammunitionTypeUniqueId: artAmmunitionDetail?.ammunitionTypeUniqueId || '',
        manufacturerUniqueId: artAmmunitionDetail?.manufacturerUniqueId || '',
        productName: artAmmunitionDetail?.productName || '',
        factoryUniqueId: artAmmunitionDetail?.factoryUniqueId || '',
        calibreUniqueId: artAmmunitionDetail?.calibreUniqueId || '',
        cartridgeCompositionUniqueId: artAmmunitionDetail?.cartridgeCompositionUniqueId || '',
        colourOfPrimerSealantUniqueId:
          artAmmunitionDetail?.colourOfPrimerSealantUniqueId || '',
        projectileShapeUniqueId: artAmmunitionDetail?.projectileShapeUniqueId || '',
        functionalTypeUniqueId: artAmmunitionDetail?.functionalTypeUniqueId || '',
        munitionTypeUniqueId: artAmmunitionDetail?.munitionTypeUniqueId || '',
        shotSizeUniqueId: artAmmunitionDetail?.shotSizeUniqueId || '',
        munitionVelocityUniqueId: artAmmunitionDetail?.munitionVelocityUniqueId || '',
        headstampYearMarking: artAmmunitionDetail?.headstampYearMarking || '',
        alphanumericalMarkings: artAmmunitionDetail?.alphanumericalMarkings || '',
        notes: artAmmunitionDetail?.notes || '',
      });
    } else if (ancillaryValue === EArtAncillaryType.COMPONENTS) {
      setInitialData({
        componentTypeUniqueId: artComponentDetail?.componentTypeUniqueId || '',
        manufacturerUniqueId: artComponentDetail?.manufacturerUniqueId || '',
        productName: artComponentDetail?.productName || '',
        calibreUniqueId: artComponentDetail?.calibreUniqueId || '',
        length: artComponentDetail?.length || '',
        compositionUniqueId: artComponentDetail?.compositionUniqueId || '',
        capacityUniqueId: artComponentDetail?.capacityUniqueId || '',
        proofHouseUniqueId: artComponentDetail?.proofHouseUniqueId || '',
        notes: artComponentDetail?.notes || '',

        frtId: artComponentDetail?.frtId || '',
        frtMake: artComponentDetail?.frtMake || '',
        frtModel: artComponentDetail?.frtModel || '',
        frtManufacturer: artComponentDetail?.frtManufacturer || '',
        frtType: artComponentDetail?.frtType || '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artAccessoryDetail, artAmmunitionDetail, artComponentDetail]);

  const areValuesSame = (dataValue: any, formValue: any): boolean => {
    const isDataValueEmpty =
      dataValue === null ||
      dataValue === undefined ||
      (typeof dataValue === 'string' && dataValue?.trim() === '');
    const isFormValueEmpty =
      formValue === undefined ||
      formValue === null ||
      (typeof formValue === 'string' && formValue?.trim() === '');
    if (isDataValueEmpty && isFormValueEmpty) {
      return true;
    }
    if ((isDataValueEmpty && !isFormValueEmpty) || (!isDataValueEmpty && isFormValueEmpty)) {
      return false;
    }
    return dataValue.toString() === formValue.toString();
  };

  const isAmmunitionDataChanged = (data: IAmmunitionFormValues): boolean => {
    if (
      areValuesSame(
        data?.ammunitionTypeUniqueId,
        artAmmunitionDetail?.ammunitionTypeUniqueId,
      ) &&
      areValuesSame(data?.manufacturerUniqueId, artAmmunitionDetail?.manufacturerUniqueId) &&
      areValuesSame(data?.productName, artAmmunitionDetail?.productName) &&
      areValuesSame(data?.factoryUniqueId, artAmmunitionDetail?.factoryUniqueId) &&
      areValuesSame(data?.calibreUniqueId, artAmmunitionDetail?.calibreUniqueId) &&
      areValuesSame(
        data?.cartridgeCompositionUniqueId,
        artAmmunitionDetail?.cartridgeCompositionUniqueId,
      ) &&
      areValuesSame(
        data?.colourOfPrimerSealantUniqueId,
        artAmmunitionDetail?.colourOfPrimerSealantUniqueId,
      ) &&
      areValuesSame(
        data?.projectileShapeUniqueId,
        artAmmunitionDetail?.projectileShapeUniqueId,
      ) &&
      areValuesSame(
        data?.functionalTypeUniqueId,
        artAmmunitionDetail?.functionalTypeUniqueId,
      ) &&
      areValuesSame(data?.shotSizeUniqueId, artAmmunitionDetail?.shotSizeUniqueId) &&
      areValuesSame(data?.munitionTypeUniqueId, artAmmunitionDetail?.munitionTypeUniqueId) &&
      areValuesSame(
        data?.munitionVelocityUniqueId,
        artAmmunitionDetail?.munitionVelocityUniqueId,
      ) &&
      areValuesSame(data?.headstampYearMarking, artAmmunitionDetail?.headstampYearMarking) &&
      areValuesSame(data?.notes, artAmmunitionDetail?.notes) &&
      areValuesSame(data?.alphanumericalMarkings, artAmmunitionDetail?.alphanumericalMarkings)
    ) {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: t('editART.noChangesWarning.message'),
        variant: EAlertVariants.warning,
      });
      return false;
    }
    return true;
  };

  const isAccessoryDataChanged = (data: IARTAccessoriesFormValues): boolean => {
    if (
      areValuesSame(data?.accessoryTypeUniqueId, artAccessoryDetail?.accessoryTypeUniqueId) &&
      areValuesSame(data?.manufacturerUniqueId, artAccessoryDetail?.manufacturerUniqueId) &&
      areValuesSame(data?.productName, artAccessoryDetail?.productName) &&
      areValuesSame(data?.calibreUniqueId, artAccessoryDetail?.calibreUniqueId) &&
      areValuesSame(data?.hostTypeUniqueId, artAccessoryDetail?.hostTypeUniqueId) &&
      areValuesSame(data?.compositionUniqueId, artAccessoryDetail?.compositionUniqueId) &&
      areValuesSame(data?.magnificationUniqueId, artAccessoryDetail?.magnificationUniqueId) &&
      areValuesSame(data?.utilityUniqueId, artAccessoryDetail?.utilityUniqueId) &&
      areValuesSame(data?.capacity, artAccessoryDetail?.capacity) &&
      areValuesSame(data?.rectileUniqueId, artAccessoryDetail?.rectileUniqueId) &&
      areValuesSame(data?.lightColourUniqueId, artAccessoryDetail?.lightColourUniqueId) &&
      areValuesSame(data?.proofHouseUniqueId, artAccessoryDetail?.proofHouseUniqueId) &&
      areValuesSame(data?.notes, artAccessoryDetail?.notes)
    ) {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: t('editART.noChangesWarning.message'),
        variant: EAlertVariants.warning,
      });
      return false;
    }
    return true;
  };

  const isComponentDataChanged = (data: IARTComponentsFormValues): boolean => {
    if (
      areValuesSame(data?.componentTypeUniqueId, artComponentDetail?.componentTypeUniqueId) &&
      areValuesSame(data?.manufacturerUniqueId, artComponentDetail?.manufacturerUniqueId) &&
      areValuesSame(data?.productName, artComponentDetail?.productName) &&
      areValuesSame(data?.calibreUniqueId, artComponentDetail?.calibreUniqueId) &&
      areValuesSame(data?.length, artComponentDetail?.length) &&
      areValuesSame(data?.compositionUniqueId, artComponentDetail?.compositionUniqueId) &&
      areValuesSame(data?.capacityUniqueId, artComponentDetail?.capacityUniqueId) &&
      areValuesSame(data?.proofHouseUniqueId, artComponentDetail?.proofHouseUniqueId) &&
      areValuesSame(data?.frtType, artComponentDetail?.frtType) &&
      areValuesSame(data?.frtId, artComponentDetail?.frtId) &&
      areValuesSame(data?.frtMake, artComponentDetail?.frtMake) &&
      areValuesSame(data?.frtModel, artComponentDetail?.frtModel) &&
      areValuesSame(data?.notes, artComponentDetail?.notes) &&
      areValuesSame(data?.frtManufacturer, artComponentDetail?.frtManufacturer)
    ) {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: t('editART.noChangesWarning.message'),
        variant: EAlertVariants.warning,
      });
      return false;
    }
    return true;
  };

  const updateArtAncillary = async (
    data: IARTAccessoriesFormValues | IAmmunitionFormValues | IARTComponentsFormValues,
  ) => {
    clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    if (id) {
      try {
        let res;
        if (ancillaryValue === EArtAncillaryType.ACCESSORIES) {
          if (!isAccessoryDataChanged(data as IARTAccessoriesFormValues)) {
            return;
          }
          res = await handleUpdateAccessory(data, id as string);
        } else if (ancillaryValue === EArtAncillaryType.AMMUNITION) {
          if (!isAmmunitionDataChanged(data as IAmmunitionFormValues)) {
            return;
          }
          res = await handleUpdateAmmunition(data as IAmmunitionFormValues, id as string);
        } else if (ancillaryValue === EArtAncillaryType.COMPONENTS) {
          if (!isComponentDataChanged(data as IARTComponentsFormValues)) {
            return;
          }
          res = await handleUpdateComponent(data as IARTComponentsFormValues, id as string);
        }

        if (res) {
          navigate(
            `${ERouteLinks.Ancillaries}?expand=ancillaries-reference-table&ancillaryOptionName=${ancillaryValue}`,
          );
        }
      } catch (e: any) {
        if (e.response.data.message) {
          setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
            text: e.response.data.message,
            variant: EAlertVariants.error,
          });
        }
        setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
          text: e.response.data.errors[0].message,
          variant: EAlertVariants.error,
        });
      }
    }
  };

  const onCancel = () => {
    clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    navigate(
      `${ERouteLinks.Ancillaries}?expand=ancillaries-reference-table&ancillaryOptionName=${ancillaryValue}`,
    );
  };

  return (
    <>
      <Breadcrumbs
        items={[
          {
            label: t('module.ancillaries.name'),
            route: ERouteLinks.Ancillaries,
          },
          {
            label: t('module.ancillariesreferencetable.name'),
            route: `${ERouteLinks.Ancillaries}?expand=ancillaries-reference-table&ancillaryOptionName=${ancillaryValue}`,
          },
          t('module.editancilary.name'),
        ]}
      />
      {!isLoading && (
        <>
          <StyledPageTitle variant="h4">{t('module.editancilary.name')}</StyledPageTitle>
          <Grid container>
            <AncillaryStyle item md={6} sm={12} xs={12}>
              <StyledSelect
                options={mapDictionaryToOptionProp(artAncillaryOptions)}
                name="ancillary"
                label={`${t('ancillariesReferenceTable.accordion.ancillary.label')}*`}
                value={ancillaryValue}
                disabled={true}
              />
            </AncillaryStyle>
            <FormStyle item xs={12}>
              {ancillaryValue === EArtAncillaryType.ACCESSORIES && artAccessoryDetail && (
                <ARTAccessoriesForm
                  onSubmit={updateArtAncillary}
                  onCancel={onCancel}
                  initialData={initialData}
                  isEdit={true}
                />
              )}
              {ancillaryValue === EArtAncillaryType.AMMUNITION && artAmmunitionDetail && (
                <ARTAmmunitionForm
                  onSubmit={updateArtAncillary}
                  onCancel={onCancel}
                  initialData={initialData as IAmmunitionFormValues}
                  isEdit={true}
                />
              )}
              {ancillaryValue === EArtAncillaryType.COMPONENTS && artComponentDetail && (
                <ARTComponentsForm
                  onSubmit={updateArtAncillary}
                  onCancel={onCancel}
                  initialData={initialData as IARTComponentsFormValues}
                  isEdit={true}
                />
              )}
            </FormStyle>
          </Grid>
          {samePageAlert && (
            <Box mt={2}>
              <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default EditAncillariesReference;
