import React from 'react';
import { ITableColumns, ITableDataSource } from 'models/table';
import { ApiKeysStatusIds, IApiKey, IApiKeyHistory } from 'models/apiKey';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import { formatDate, getDateFromISO } from 'utils/date';
import { format } from 'date-fns';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import { ILimitedRole } from 'models/role';
import { getRoleNames } from 'pages/AuditLogPage/helpers';
import { Link } from 'react-router-dom';
import { ERouteLinks } from 'models/route';

interface IApiKeysTableColumns {
  handleEdit: (arg: string) => void;
  handleChangeStatus: (id: string, status: string) => void;
  handleCopyApiKey: (key: string) => void;
  longDateFormat: string;
  shortDateFormat: string;
}

export const apiKeysTableColumns = ({
  handleEdit,
  handleChangeStatus,
  handleCopyApiKey,
  longDateFormat,
  shortDateFormat,
}: IApiKeysTableColumns): ITableColumns[] => [
  {
    id: '4ea994ee-d36f-400b-895b-231c81f091c0',
    key: 'username',
    title: 'apiKeys.apiKeysTable.username.column',
  },
  {
    id: '4ea994ee-d36f-400b-895b-231c81f091c1',
    key: 'type',
    title: 'apiKeys.apiKeysTable.type.column',
    isCenterTableHead: true,
    align: 'center',
    render: (_data, props) => <Chips text={props.type} variant={EChipsVariants.primary} />,
  },
  {
    id: '4ea994ee-d36f-400b-895b-231c81f091c2',
    key: 'key',
    title: 'apiKeys.apiKeysTable.key.column',
  },
  {
    id: '4ea994ee-d36f-400b-895b-231c81f091c3',
    key: 'status',
    isCenterTableHead: true,
    title: 'apiKeys.apiKeysTable.status.column',
    align: 'center',
    width: '170',
    render: (_data, props) => (
      <Chips
        variant={
          props.statusDictionaryUniqueId === ApiKeysStatusIds.ACTIVE
            ? EChipsVariants.success100
            : EChipsVariants.error
        }
        text={props.status}
      />
    ),
  },
  {
    id: '4ea994ee-d36f-400b-895b-231c81f091c4',
    key: 'lastUsedDate',
    title: 'apiKeys.apiKeysTable.lastUsed.column',
    render: (_data, props) =>
      props.lastUsedDate ? formatDate(props.lastUsedDate, longDateFormat) : '-',
  },
  {
    id: '4ea994ee-d36f-400b-895b-231c81f091c5',
    key: 'expiryDate',
    title: 'apiKeys.apiKeysTable.expiryDate.column',
    isCenterTableHead: true,
    align: 'center',
    width: '170',
    render: (_data, props) => {
      const date = getDateFromISO(props.expiryDate);
      if (date === '') {
        return <Chips text={props.expiryStatus} variant={EChipsVariants.success100} />;
      }
      if (date > new Date()) {
        return format(date, shortDateFormat);
      }
      return <Chips text={props.expiryStatus} variant={EChipsVariants.error} />;
    },
  },
  {
    id: '4ea994ee-d36f-400b-895b-231c81f091c6',
    key: '',
    title: 'apiKeys.apiKeysTable.actions.column',
    isSortable: false,
    width: '130px',
    align: 'center',
    isCenterTableHead: true,
    render: (_data, props) => (
      <>
        <Button
          variant={EButtonVariants.outlined}
          icon={EIconTypes.copy}
          onClick={() => handleCopyApiKey(props.key)}
          sx={{ marginRight: '2px' }}
        />
        <Link to={ERouteLinks.ViewApiKeyPage.replace(':id', props.id)}>
          <Button
            variant={EButtonVariants.outlined}
            icon={EIconTypes.view}
            sx={{ marginRight: '2px' }}
          />
        </Link>
        <Button
          variant={EButtonVariants.outlined}
          icon={EIconTypes.edit}
          onClick={() => handleEdit(props.id)}
          sx={{ marginRight: '2px' }}
        />
        <Button
          variant={EButtonVariants.outlined}
          icon={
            props.statusDictionaryUniqueId === ApiKeysStatusIds.ACTIVE
              ? EIconTypes.shutDown
              : EIconTypes.checkmark
          }
          onClick={() => handleChangeStatus(props.id, props.statusDictionaryUniqueId)}
          sx={{ marginRight: '2px' }}
        />
      </>
    ),
  },
];

export const mapApiKeysToDataSource = (apiKeys: IApiKey[]): ITableDataSource[] =>
  apiKeys.map((item) => ({
    id: item.uniqueId,
    username: item.username,
    type: item.type,
    typeDictionaryUniqueId: item.typeDictionaryUniqueId,
    key: item.key,
    status: item.status,
    statusDictionaryUniqueId: item.statusDictionaryUniqueId,
    lastUsedDate: item.lastUsedDate,
    expiryDate: item.expiryDate,
    expiryStatus: item.expiryStatus,
  }));

enum EApiKeyHistoryEventTypes {
  ADD = '9f815892-29a2-4471-adab-e0358545f2c3',
  EDIT = 'b5643ce1-cb61-4839-b7b1-296c54066034',
  DELETE = 'c700995c-d3b5-4562-949d-8ae1935fe259',
  ACTIVATE = '4d064986-7fcc-4533-8d20-4c12a068c450',
  DEACTIVATE = '95d20adc-9e5b-46df-8813-255bb279fdd8',
}

export const getHistoryEventTypeColor = (eventType: EApiKeyHistoryEventTypes) => {
  switch (eventType) {
    case EApiKeyHistoryEventTypes.ACTIVATE:
    case EApiKeyHistoryEventTypes.ADD:
      return EChipsVariants.success;

    case EApiKeyHistoryEventTypes.EDIT:
      return EChipsVariants.primary;

    case EApiKeyHistoryEventTypes.DELETE:
    case EApiKeyHistoryEventTypes.DEACTIVATE:
      return EChipsVariants.error;

    default:
      return EChipsVariants.inactive;
  }
};

interface IApiKeysHistoryTableColumns {
  roles: ILimitedRole[];
  longDateFormat: string;
}

export const apiKeysHistoryTableColumns = ({
  roles,
  longDateFormat,
}: IApiKeysHistoryTableColumns): ITableColumns[] => [
  {
    id: '5ea994ee-d36f-400b-895b-231c81f091c0',
    key: 'username',
    title: 'apiKeys.apiKeysHistoryTable.username.column',
    isSortable: false,
  },
  {
    id: '5ea994ee-d36f-400b-895b-231c81f091c1',
    key: 'roleName',
    title: 'apiKeys.apiKeysHistoryTable.roleName.column',
    isSortable: false,
    isCenterTableHead: true,
    align: 'center',
    render: (_data, props) => {
      const roleNamesArr = getRoleNames(props?.roleName, roles);
      return roleNamesArr.map((roleName) => (
        <Chips text={roleName as string} variant={EChipsVariants.primary} />
      ));
    },
  },
  {
    id: '5ea994ee-d36f-400b-895b-231c81f091c2',
    key: 'eventType',
    title: 'apiKeys.apiKeysHistoryTable.eventType.column',
    isSortable: false,
    isCenterTableHead: true,
    align: 'center',
    render: (_data, props) => (
      <Chips
        text={props?.eventType}
        variant={getHistoryEventTypeColor(props.eventTypeUniqueId)}
      />
    ),
  },
  {
    id: '5ea994ee-d36f-400b-895b-231c81f091c3',
    key: 'eventName',
    title: 'apiKeys.apiKeysHistoryTable.eventName.column',
    isSortable: false,
  },
  {
    id: '5ea994ee-d36f-400b-895b-231c81f091c4',
    key: 'eventDate',
    title: 'apiKeys.apiKeysHistoryTable.eventDate.column',
    render: (_data, props) =>
      props.eventDate ? formatDate(props.eventDate, longDateFormat) : '-',
  },
];

export const mapApiKeysHistoryToDataSource = (history: IApiKeyHistory[]): ITableDataSource[] =>
  history.map((item) => ({
    id: item.uniqueId,
    username: `${item.authorFirstName} ${item.authorLastName}`,
    roleName: item.authorRoleIds,
    eventType: item.eventType,
    eventTypeUniqueId: item.eventTypeUniqueId,
    eventName: item.eventName,
    eventDate: item.eventDate,
  }));
