import { IPaginator } from 'models/paginator';
import { ILegalEntityCustomField } from 'components/organisms/LegalEntityDetails';
import { ELegalEntityTypes } from 'constants/LegalEntityTypes';
import { LETypes } from 'pages/AncillariesPage/AncillariesAddTransactionPage';
import { IAncillaryDetails } from './ancillaries';

export interface ILegalEntityResponse {
  uniqueId: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  genderUniqueId: string;
  visibilityUniqueId: string;
  city: string;
}

export interface ILegalEntity {
  uniqueId: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  genderUniqueId: string;
  visibilityUniqueId: string | null;
  city: string | null;
  customFields?: ILegalEntityCustomField[];
}

export interface ILegalEntityFirearm {
  uniqueId: string;
  serialNumber: string;
  ownerId: string;
  make: string;
  model: string;
  manufacturer: string;
  caliber: string;
  keeper: string;
  owner: string;
  exhibitNumber: string;
  stateUniqueId: string;
  state: string;
  groupName: string;
  fireCycleId: string;
  activityUniqueId: string;
  legalityUniqueId: string;
}

export interface ILegalEntityGroup {
  uniqueId: string;
  name: string;
  owner: string;
  keeper: string;
  firearmAmount: number;
  state: string | null;
  legalityUniqueId: string | null;
  activityUniqueId: string | null;
  stateUniqueId: string | null;
  hasDifferentStates: boolean;
}

export interface ILegalEntityGroupResponse {
  uniqueId: string;
  name: string;
  firearmIds: string[];
  legalEntityGroup: number;
  firearmAmount: number;
  keeper: string;
  keeperUniqueId: string;
  keeperType: string;
  keeperVisibilityUniqueId: string;
  owner: string;
  ownerUniqueId: string;
  ownerType: string;
  ownerVisibilityUniqueId: string;
  hasDifferentStates: boolean;
}

export interface ILegalEntityAncillaryGroupAddResponse {
  uniqueId: string;
  name: string;
  ancillariesIds: string[];
}

export interface ILegalEntityListResponse extends IPaginator {
  content: ILegalEntity[];
}

export interface ILegalEntityFirearmListResponse extends IPaginator {
  content: ILegalEntityFirearm[];
}

export interface ILegalEntityGroupListResponse extends IPaginator {
  content: ILegalEntityGroup[];
}

export interface ILegalEntityType {
  type: 'INDIVIDUAL' | 'ORGANIZATION';
}

export const mapILegalEntityTypeToELegalEntityTypes = (val: string | null | undefined) => {
  if (!val) {
    return null;
  }
  // eslint-disable-next-line default-case
  switch (val.toUpperCase()) {
    case LETypes.individual: {
      return ELegalEntityTypes.individual;
    }
    case LETypes.organization: {
      return ELegalEntityTypes.organisation;
    }
    default:
      return null;
  }
};

export interface ILegalEntityAncillaries {
  uniqueId: string;
  serialNumber: string;
  activityUniqueId: string;
  legalityUniqueId: string;
  fireCycleId: string;
  stateUniqueId: string;
  state: string;
  keeperId: string;
  keeperName: string;
  ancillaryDictionaryUniqueId: string;
  ancillaryDictionaryValue: string;
  manufacturerUniqueId: string;
  manufacturer: string;
  calibreUniqueId: string;
  calibre: string;
  ancillaryTypeUniqueId: string;
  ancillaryType: string;
  frtType: string;
  groupName: string;
}

export interface ILegalEntityAncillariesListResponse extends IPaginator {
  content: ILegalEntityAncillaries[];
}

export interface ILegalEntityAncillaryGroupAncillaries {
  ancillaries: IAncillaryDetails[];
  hasDifferentAncillaryTypes: boolean;
  groupName: string;
  artId: string;
  artSpecificType: string;
  artType: string;
  artTypeUniqueId: string;
  artUniqueId: string;
  calibre: string;
  manufacturer: string;
}

export interface ILegalEntityAncillaryGroup {
  uniqueId: string;
  name: string;
  state: string;
  keeper: string;
  keeperId: string;
  keeperType: string;
  keeperVisibilityUniqueId: string;
  owner: string;
  ownerId: string;
  ownerType: string;
  ownerVisibilityUniqueId: string;
  ancillaryAmount: string;
  legalityUniqueId: string;
  activityUniqueId: string;
  stateUniqueId: string;
  hasDifferentStates: boolean;
}

export interface ILegalEntityAncillaryGroupResponse extends IPaginator {
  content: ILegalEntityAncillaryGroup[];
}
export interface IOrgLegalEntityResponse {
  uniqueId: string;
  organizationName: string;
  organizationTypeUniqueId: string;
  departmentName: string;
  visibilityUniqueId: string;
  city: string;
}

export interface ILegalEntityAddress {
  uniqueId: string;
  typeUniqueId: string;
  line1: string;
  line2: string;
  city: string;
  postalCode: string;
  regionUniqueId: string;
  countryUniqueId: string;
  latitude: number | string;
  longitude: number | string;
  isDefault: boolean;
}

export interface ILegalEntityContact {
  uniqueId: string;
  name?: string;
  role?: string;
  email: string;
  phoneNumber: {
    codeDictionaryUniqueId: string;
    value: string;
  };
}

export interface ILegalEntityGroupFirearm {
  id: string;
  serialNumber: string;
  make: string;
  model: string;
  caliber: string;
  state: string;
  activityUniqueId: string;
  legalityUniqueId: string;
  stateUniqueId: string;
}

export interface IAssignLE {
  legalUniqueId: string;
  accountId: string | null | undefined;
}

export interface ITransactionType {
  uniqueId: string;
  name: string;
  type: string;
  isActive: string;
}
