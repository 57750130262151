import { ISearchFormCustomFieldValue } from 'pages/LegalEntitiesPage/IndividualSearchForm';
import { formatDateTimeToIso, getDateFromISOOrNull, isValidDate } from 'utils/date';
import { ECustomFieldType } from 'models/customField';

export interface ISearchParams {
  [key: string]: string | any;
}
export const objectToQueryString = (
  obj: any,
  customFieldsDefault?: any,
  shortDateFormat?: string,
) => {
  const keys = Object.keys(obj);
  let customFields: string[] = [];
  const keyValuePairs = keys.map((key) => {
    if (shortDateFormat && typeof obj[key] === 'object' && isValidDate(obj[key])) {
      return `${encodeURIComponent(key)}=${encodeURIComponent(formatDateTimeToIso(obj[key]))}`;
    }
    if (obj[key]) {
      if (typeof obj[key] !== 'object') {
        if (customFieldsDefault && customFieldsDefault.length) {
          if (key.startsWith('from_') || key.startsWith('to_')) {
            const customFieldKey = key.startsWith('from_')
              ? key.replace('from_', '')
              : key.replace('to_', '');
            const customField = customFieldsDefault.find(
              (item: any) => item.name === customFieldKey,
            );
            if (customField && customField?.name) {
              customFields = customFields.concat([
                `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`,
              ]);
              return null;
            }
          } else {
            const customField = customFieldsDefault.find((item: any) => item.name === key);
            if (customField && customField?.name) {
              let value = obj[key];
              switch (obj[key]) {
                case 'true':
                  value = 1;
                  break;
                case 'false':
                  value = 0;
                  break;
                default:
                  value = obj[key];
              }
              customFields = customFields.concat([
                `${encodeURIComponent(customField?.name)}=${encodeURIComponent(value)}`,
              ]);
              return null;
            }
          }
        }
        return `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`;
      }
      if (typeof obj[key] === 'object' && key === 'customFields' && obj[key].length) {
        const customFieldsObj = obj[key].map((item: any) => {
          if (item.customFieldType === ECustomFieldType.TEXT) {
            return `${encodeURIComponent(item?.name)}=${encodeURIComponent(item?.text)}`;
          }
          if (item.customFieldType === ECustomFieldType.BOOL) {
            return `${encodeURIComponent(item?.name)}=${encodeURIComponent(
              item?.bool ? 1 : 0,
            )}`;
          }
          if (item.customFieldType === ECustomFieldType.DATE) {
            return `${encodeURIComponent(`from_${item?.name}`)}=${encodeURIComponent(
              item?.dateFrom || '',
            )};${encodeURIComponent(`to_${item?.name}`)}=${encodeURIComponent(
              item?.dateTo || '',
            )}`;
          }
          return null;
        });
        customFields = customFields.concat(customFieldsObj);
      }
    }
    return null;
  });

  const customFieldsString = customFields.join(';');
  const keyValuePairsString = keyValuePairs.filter((item) => item !== null).join('&');
  return customFieldsString
    ? `${keyValuePairsString}&customFields[]=${customFieldsString}`
    : keyValuePairsString;
};

export const prepareQueryValues = (
  initialValues: any,
  queryData: URLSearchParams,
  dateFields?: string[],
): ISearchParams | null => {
  const queryValues: ISearchParams = {};
  queryData.forEach((value, field) => {
    if (dateFields !== undefined && dateFields.includes(field)) {
      queryValues[field] = getDateFromISOOrNull(value);
    } else if (field in initialValues) {
      queryValues[field] = value;
    } else {
      const isArray = field.slice(field.length - 2, field.length) === '[]';
      const newField = field.slice(0, field.length - 2);
      if (isArray && newField in initialValues) {
        value.split(';').forEach((item: string) => {
          const [name, data] = item.split('=');
          if (dateFields !== undefined && dateFields.includes(name)) {
            queryValues[name] = getDateFromISOOrNull(data);
          } else {
            queryValues[name] = data;
          }
        });
      }
    }
  });
  if (Object.keys(queryValues).length) {
    return queryValues;
  }

  return null;
};

export const prepareDataWithCustomFieldsToSearch = (data: any, customFields: any) => {
  const customFieldsPayload: ISearchFormCustomFieldValue[] = [];
  Object.keys(data).map((key) => {
    const isDateCF = key.startsWith('from_') || key.startsWith('to_');

    if (!isDateCF) {
      const customField = customFields.find((item: any) => item.name === key);
      if (customField && data[key]) {
        const customFieldItem: ISearchFormCustomFieldValue = {
          customFieldUniqueId: customField.uniqueId,
          customFieldType: customField.customFieldType,
        };
        if (customField.customFieldType === ECustomFieldType.TEXT) {
          if (data[key] === undefined || data[key] === null) {
            customFieldItem.text = undefined;
          } else if (data[key].trim() === '') {
            customFieldItem.text = undefined;
          } else {
            customFieldItem.text = data[key];
          }
        } else if (customField.customFieldType === ECustomFieldType.BOOL) {
          customFieldItem.bool = data[key] === '1';
        }

        customFieldsPayload.push(customFieldItem);
        delete data[key];
      }
    } else {
      const customFieldKey = key.startsWith('from_')
        ? key.replace('from_', '')
        : key.replace('to_', '');

      const customField = customFields.find((item: any) => item.name === customFieldKey);

      if (customField && (data[`from_${customFieldKey}`] || data[`to_${customFieldKey}`])) {
        const customFieldItem: ISearchFormCustomFieldValue = {
          customFieldUniqueId: customField.uniqueId,
          customFieldType: customField.customFieldType,
        };
        if (data[`from_${customFieldKey}`]) {
          customFieldItem.dateFrom =
            data[`from_${customFieldKey}`] !== null
              ? formatDateTimeToIso(data[`from_${customFieldKey}`])
              : formatDateTimeToIso(new Date().toString());
        }
        if (data[`to_${customFieldKey}`]) {
          customFieldItem.dateTo =
            data[`to_${customFieldKey}`] !== null
              ? formatDateTimeToIso(data[`to_${customFieldKey}`])
              : formatDateTimeToIso(new Date().toString());
        }
        customFieldsPayload.push(customFieldItem);
        if (data[`from_${customFieldKey}`]) {
          delete data[`from_${customFieldKey}`];
        }
        if (data[`to_${customFieldKey}`]) {
          delete data[`to_${customFieldKey}`];
        }
      }
    }
    return false;
  });

  if (customFieldsPayload?.length) {
    data.customFields = customFieldsPayload;
  }
  return data;
};

export const customFieldsPrepareToSearch = (
  customFields: ISearchFormCustomFieldValue[] | undefined,
) => {
  if (!customFields || !customFields.length) return customFields;
  return customFields.map((customField): ISearchFormCustomFieldValue => {
    delete customField.name;
    return customField;
  });
};
