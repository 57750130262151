import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { useNavigate, useParams } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import { ELegalEntityTypes } from 'constants/LegalEntityTypes';
import { Loader } from 'components/atoms/Loader';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import { LegalEntityGroupDetails } from 'components/organisms/LegalEntityGroupDetails';
import { useQuery } from 'hooks/useQuery';
import Grid from '@mui/material/Grid';
import { Accordion } from 'components/molecules/Accordion';
import { TableTopButtons } from 'components/atoms/TableTopButtons';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import { Table } from 'components/molecules/Table';
import {
  legalEntityGroupFirearmsTableColumns,
  mapLEGFirearmToDataSource,
} from 'pages/LegalEntitiesPage/helpers';
import { CardList } from 'components/molecules/CardList';
import { Pagination } from 'components/molecules/Pagination';
import { EPerPages, ISortOptions, ITableDataSource } from 'models/table';
import { ISearchFirearmFormValues } from 'models/form';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { GroupFirearmSearchForm } from 'pages/LegalEntitiesPage/GroupFirearmSearchForm';
import { LegalEntityGroupFirearmTableCard } from 'pages/LegalEntitiesPage/LegalEntityGroupFirearmTableCard';
import { Box } from '@mui/material';
import { Alert, EAlertVariants, IAlert } from 'components/atoms/Alert';
import { useAlert } from 'models/alertContext';
import { useTranslations } from 'hooks/useTranslations';
import { ActionTypes } from 'state/actions/alert';
import { useLang } from 'models/langContext';
import { EDictionaryTypes } from 'models/dictionary';
import {
  renderDataColor,
  renderRowColor,
  renderRowColorHover,
} from 'components/molecules/Table/helpers';
import { usePermission } from 'hooks/usePermission';
import { EPermission } from 'models/permissions';
import { ELegalVisibility } from 'constants/LegalVisibility';
import { useAuth } from 'models/authContext';
import { useAccount } from 'pages/AccountPage/hooks';

const ViewLegalEntityGroupPage = () => {
  const { id = '', groupId = '' } = useParams<{ id: string; groupId: string }>();
  const [isLegalEntityGroupLoading, setIsLegalEntityGroupLoading] = useState<boolean>(false);
  const { t } = useTranslations();
  const { selectedLanguage } = useLang();
  const { hasPermissions } = usePermission();

  const query = useQuery();
  const navigate = useNavigate();
  const type: string | null = query.get('type');

  const [currentPageLEGF, setCurrentPageLEGF] = useState<number>(1);
  const [perPageLEGF, setPerPageLEF] = useState<EPerPages>(EPerPages.perPage25);
  const [groupFirearmQuery, setGroupFirearmQuery] = useState<
    Partial<ISearchFirearmFormValues>
  >({});
  const [groupFirearmSort, setGroupFirearmSort] = useState<ISortOptions | null>(null);
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);

  const {
    getLegalEntityGroupData,
    legalEntityGroup,
    legalEntityGroupFirearms,
    getLegalEntityGroupFirearmsData,
    paginatorLEGF,
    loadDictionaries,
  } = useLegalEntities();
  const { isDesktop } = useThemeBreakpoint();
  const {
    legalEntityAlert,
    nextPageAlert,
    clearAlert,
    samePageAlert,
    transactionAlert,
    setAlert,
  } = useAlert();

  const { user, getUser } = useAuth();
  const { account, getSelfAccountData } = useAccount();

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    (async function getAccountDataInit() {
      if (user?.userId) {
        await getSelfAccountData();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const getLegalEntityGroupFirearmsDataInit = async () => {
    setIsPaginationLoading(true);
    await getLegalEntityGroupFirearmsData(
      id as string,
      groupId as string,
      currentPageLEGF,
      perPageLEGF,
      groupFirearmQuery,
      groupFirearmSort,
    );
    setIsPaginationLoading(false);
  };

  const getLegalEntityGroupDataInit = async (withLoader = true) => {
    if (withLoader) {
      setIsLegalEntityGroupLoading(true);
    }
    try {
      await getLegalEntityGroupData(id as string, groupId as string);
    } catch (e: any) {
      navigate(`${ERouteLinks.ViewLegalEntity.replace(':id', id)}?type=${type}&expand=groups`);
    }
    setIsLegalEntityGroupLoading(false);
  };

  useEffect(() => {
    getLegalEntityGroupDataInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, groupId]);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_LEGAL_ENTITY_ALERT);
      clearAlert(ActionTypes.CLEAR_NEXT_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    getLegalEntityGroupFirearmsDataInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageLEGF, perPageLEGF, groupFirearmQuery, groupFirearmSort]);

  useEffect(() => {
    (async function initDictionaries() {
      await loadDictionaries([
        EDictionaryTypes.Caliber,
        EDictionaryTypes.Make,
        EDictionaryTypes.Model,
      ]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  const transactionForbiddenAlert = (
    <Grid mb={2}>
      <Alert
        variant={EAlertVariants.warning}
        text={t('groupDetails.emptyFirearmList.warning.alert')}
      />
    </Grid>
  );

  const handleAddTransaction = () => {
    if (legalEntityGroup?.firearmIds.length) {
      navigate(
        `${ERouteLinks.LegalEntityAddTransactionForGroup.replace(':id', id).replace(
          ':groupId',
          groupId,
        )}?type=${type}&expand=groups`,
        { state: { isDetailsContext: true, type } },
      );
    } else {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
      clearAlert(ActionTypes.CLEAR_TRANSACTION_ALERT);
      setAlert(ActionTypes.SET_TRANSACTION_ALERT, {} as IAlert);
    }
  };

  const handleAddFirearmToGroup = () => {
    if (!legalEntityGroup?.hasDifferentStates) {
      navigate(
        `${ERouteLinks.LegalEntityGroupFirearmSearch.replace(
          ':id',
          id,
        )}?type=${type}&expand=groups&groupId=${groupId}`,
        { state: { isDetailsContext: true } },
      );
    } else {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: t('groupDetails.addToGroup.groupHasElementsWithDifferentStates.message'),
        variant: EAlertVariants.warning,
      } as IAlert);
    }
  };

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
      clearAlert(ActionTypes.CLEAR_TRANSACTION_ALERT);
    },
    [],
  );

  const isAddTransactionVisible = () => {
    if (hasPermissions(EPermission.VIEW_ALL_LE)) return true;
    if (hasPermissions(EPermission.VIEW_LE)) {
      return (
        (legalEntityGroup?.keeperVisibilityUniqueId !== ELegalVisibility.NON_PUBLIC ||
          account?.assignedLegalIds.includes(legalEntityGroup.keeperUniqueId)) &&
        (legalEntityGroup?.ownerVisibilityUniqueId !== ELegalVisibility.NON_PUBLIC ||
          account?.assignedLegalIds.includes(legalEntityGroup.ownerUniqueId))
      );
    }
    return false;
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.legalEntity.name'), route: ERouteLinks.LegalEntities },
          {
            label:
              type === ELegalEntityTypes.individual
                ? t('legalEntities.individual.accordion.label')
                : t('legalEntities.organization.accordion.label'),
            route: `${ERouteLinks.LegalEntities}?expand=${
              type === ELegalEntityTypes.individual ? 'individual' : 'organisation'
            }`,
          },
          {
            label: t('legalEntityDetails.legalEntityDetails.text'),
            route: `${ERouteLinks.ViewLegalEntity.replace(
              ':id',
              id as string,
            )}?type=${type}&expand=groups`,
          },
          t('groupDetails.header'),
        ]}
      />
      <div>
        {isLegalEntityGroupLoading ? (
          <Loader isLoading={isLegalEntityGroupLoading} />
        ) : (
          legalEntityGroup && <LegalEntityGroupDetails legalEntityGroup={legalEntityGroup} />
        )}
      </div>

      <Grid container>
        <Grid item xs={12} sx={{ my: 2 }}>
          <Box sx={{ visibility: legalEntityAlert ? 'visible' : 'hidden' }}>
            <Alert
              text={legalEntityAlert ? legalEntityAlert.text : ''}
              variant={legalEntityAlert ? legalEntityAlert.variant : EAlertVariants.success}
            />
          </Box>
          {nextPageAlert && legalEntityAlert === null && (
            <Box mt={2}>
              <Alert text={nextPageAlert.text} variant={nextPageAlert.variant} />
            </Box>
          )}
        </Grid>
        <Grid item xs={12}>
          <Accordion title={t('groupDetails.firearmList.section.label')} alwaysExpanded={true}>
            <GroupFirearmSearchForm onSubmit={setGroupFirearmQuery} />
            <TableTopButtons>
              {isAddTransactionVisible() && (
                <Button
                  label={t('groupDetails.firearmListSection.addTransaction.button')}
                  variant={EButtonVariants.contained}
                  icon={EIconTypes.plus}
                  onClick={handleAddTransaction}
                  permission={[EPermission.ADD_TRANSACTION]}
                  sx={{ ml: 1 }}
                />
              )}
              <Button
                label={t('groupDetails.firearmListSection.addFirearm.button')}
                variant={EButtonVariants.contained}
                icon={EIconTypes.plus}
                onClick={handleAddFirearmToGroup}
                permission={[EPermission.MANAGE_GROUPS_LE]}
                sx={{ ml: 1, minWidth: '200px' }}
              />
            </TableTopButtons>
            {transactionAlert && transactionForbiddenAlert}
            {samePageAlert && (
              <Grid mb={2}>
                <Alert variant={samePageAlert.variant} text={samePageAlert.text} />
              </Grid>
            )}
            {isDesktop ? (
              <Table
                columns={legalEntityGroupFirearmsTableColumns({
                  id,
                  groupId,
                  type,
                  renderStateFieldColor: (data: ITableDataSource) =>
                    renderDataColor(data).stateField,
                })}
                dataSource={mapLEGFirearmToDataSource(legalEntityGroupFirearms)}
                specificRowColor={renderRowColor}
                specificRowColorHover={renderRowColorHover}
                onSort={setGroupFirearmSort}
                translationsKeys={{
                  noResults: 'registeredFirearms.accordion.noResults.text',
                }}
              />
            ) : (
              <CardList
                items={mapLEGFirearmToDataSource(legalEntityGroupFirearms)}
                render={(data, index) => (
                  <LegalEntityGroupFirearmTableCard
                    data={data}
                    key={index}
                    renderDataColor={() => renderDataColor(data)}
                    id={id}
                    type={type}
                    groupId={groupId}
                  />
                )}
              />
            )}

            {paginatorLEGF && (
              <Pagination
                count={paginatorLEGF?.totalElements}
                perPage={perPageLEGF}
                onChangePage={(page) => {
                  setCurrentPageLEGF(page);
                }}
                onChangePerPage={(value) => {
                  setPerPageLEF(value);
                }}
                current={currentPageLEGF}
                isLoading={isPaginationLoading}
              />
            )}
          </Accordion>
        </Grid>
      </Grid>
    </>
  );
};

export { ViewLegalEntityGroupPage };
