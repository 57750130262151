import React from 'react';
import { ITableDataSource } from 'models/table';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';
import { Checkbox, Typography } from '@mui/material';
import { Chips } from 'components/atoms/Chips';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { IFirearm } from 'models/firearm';
import { useTranslations } from 'hooks/useTranslations';
import { emptyFieldHolder } from 'components/molecules/CardList';
import { ERouteLinks } from 'models/route';
import { EPermission } from 'models/permissions';
import { Link } from 'react-router-dom';

interface ILegalEntityFirearmTableCard {
  data: ITableDataSource;
  selectAction: (p: any) => void;
  selectedFirearms: IFirearm[];
  renderColor: (arg: ITableDataSource) => any;
}

const LegalEntityGroupSearchFirearmTableCard = ({
  data,
  selectAction,
  selectedFirearms,
  renderColor,
}: ILegalEntityFirearmTableCard) => {
  const { t } = useTranslations();

  const isItemSelected = selectedFirearms?.find((item) => item.id === data.id);

  const renderToggleSelectButtonLabel = () => {
    let label: string;
    if (isItemSelected) {
      label = t('searchForLegalEntitysFirearm.unselectFirearm.button');
    } else {
      label = t('searchForLegalEntitysFirearm.selectFirearm.button');
    }
    return label;
  };

  return (
    <Grid
      container
      sx={{
        border: `1px solid ${renderColor(data).border}`,
        borderRadius: '4px',
        overflow: 'hidden',
        mt: 1,
        backgroundColor: renderColor(data).background,
      }}
    >
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('searchForLegalEntitysFirearm.serialNumber.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.serialNumber)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('searchForLegalEntitysFirearm.make.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.make)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('searchForLegalEntitysFirearm.model.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.model)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('searchForLegalEntitysFirearm.calibre.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.caliber)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('searchForLegalEntitysFirearm.owner.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.owner)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('searchForLegalEntitysFirearm.keeper.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.keeper)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('searchForLegalEntitysFirearm.manufacturer.column')}
        </Typography>

        <Typography variant="body2">{emptyFieldHolder(data.manufacturer)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('searchForLegalEntitysFirearm.state.column')}
        </Typography>
        {data.state ? <Chips text={data.state} variant={renderColor(data).stateField} /> : '-'}
      </Grid>
      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTop: {
            xs: `1px solid ${colors.primary25}`,
            lg: 'none',
          },
          m: {
            xs: 2,
            lg: 2,
          },
        }}
      >
        <Grid item xs={12} container mt={1} rowGap={1} justifyContent="right">
          <Grid item xs={6} sm={5}>
            <Typography variant="button" sx={{ textTransform: 'uppercase' }}>
              {t('general.actions.text')}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sm={1}
            textAlign="right"
            sx={{
              pr: {
                sm: 2,
                lg: 0,
              },
            }}
          >
            <Checkbox
              onClick={() => selectAction(data)}
              checked={!!isItemSelected}
              sx={{
                marginTop: '3px',
                color: colors.gray300,

                '&.Mui-checked': {
                  color: colors.primary500,
                },
                '&.MuiCheckbox-root': {
                  maxWidth: '24px',
                  maxHeight: '24px',
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ mt: { xs: 1, sm: 0 } }}>
            <Button
              label={renderToggleSelectButtonLabel()}
              variant={EButtonVariants.outlined}
              onClick={() => selectAction(data)}
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Link to={ERouteLinks.ViewRegisteredFirearm.replace(':id', data.id as string)}>
              <Button
                variant={EButtonVariants.outlined}
                tableButton={true}
                label={t('general.details.text')}
                permission={[EPermission.VIEW_FIREARMS]}
                size="small"
                fullWidth
              />
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export { LegalEntityGroupSearchFirearmTableCard };
