import { StyledRouterLink } from 'components/atoms/RouterLink/styles';
import { ERouteLinks } from 'models/route';
import React, { useEffect } from 'react';
import { getLegalEntityType } from 'components/organisms/RegisteredFirearmDetails';
import { usePermission } from 'hooks/usePermission';
import { EPermission } from 'models/permissions';
import { emptyFieldHolder } from 'components/molecules/CardList';
import { useAccount } from 'pages/AccountPage/hooks';
import { ELegalVisibility } from 'constants/LegalVisibility';

type StyledLegalLinkProps = {
  labelText: string | null | undefined;
  legalType: string | null | undefined;
  legalId: string | null | undefined;
  legalVisibilityUniqueId: string | null | undefined;
};

export const StyledLegalLink = ({
  labelText,
  legalId,
  legalType,
  legalVisibilityUniqueId,
}: StyledLegalLinkProps) => {
  const { hasPermissions } = usePermission();
  const { account, getSelfAccountData } = useAccount();
  const hasViewLEPermissionAndLegalVisibilityisPublicOrNonPublicAndAssigned =
    !!legalId &&
    hasPermissions([EPermission.VIEW_LE]) &&
    (legalVisibilityUniqueId === ELegalVisibility.PUBLIC ||
      (legalVisibilityUniqueId === ELegalVisibility.NON_PUBLIC &&
        account?.assignedLegalIds.includes(legalId)));
  const hasLEPermissions =
    !!legalId &&
    (hasPermissions([EPermission.VIEW_ALL_LE]) ||
      hasViewLEPermissionAndLegalVisibilityisPublicOrNonPublicAndAssigned);

  useEffect(() => {
    (async () => {
      await getSelfAccountData();
    })();
  }, []);

  if (hasLEPermissions) {
    return labelText && legalType && legalId ? (
      <StyledRouterLink
        to={`${ERouteLinks.ViewLegalEntity.replace(':id', legalId)}?type=${getLegalEntityType(
          legalType,
        )}`}
      >
        {labelText}
      </StyledRouterLink>
    ) : (
      '-'
    );
  }
  return emptyFieldHolder(labelText);
};
