import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFRT } from 'pages/FRT/hooks';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { ERouteLinks } from 'models/route';
import { Box } from '@mui/system';
import { StyledPageTitle } from 'theme/styles';
import { Loader } from 'components/atoms/Loader';
import { DetailsTemplate, EDetailsPageTypes } from 'components/templates/DetailsTemplate';
import { Accordion } from 'components/molecules/Accordion';
import { StyledDetailsWrapper } from 'pages/Firearms/RegisteredFirearmDeleteCommentPage/styles';
import { Table } from 'components/molecules/Table';
import { FRTSpecificationsTableColumns, isFRTLocal } from 'pages/FRT/helpers';
import { CardList } from 'components/molecules/CardList';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import { StyledButton } from 'pages/Firearms/ViewRegisteredFirearmPage/styles';
import { useAlert } from 'models/alertContext';
import { Alert } from 'components/atoms/Alert';
import { ActionTypes } from 'state/actions/alert';
import { FRTSpecificationsCard } from 'pages/FRT/FRTSpecificationsCard';
import { useTranslations } from 'hooks/useTranslations';
import { EPermission } from 'models/permissions';
import { usePermission } from 'hooks/usePermission';
import { getHtmlText } from 'components/organisms/HtmlSlate';

const FRTDetailsPage = () => {
  const { frtId = '' } = useParams<{ id: string; frtId: string }>();
  const { hasPermissions } = usePermission();
  const [isFRTSpecificationsLoading, setIsFRTSpecificationsLoading] = useState<boolean>(false);
  const [firearmDetailsAllExpanded, setFirearmDetailsAllExpanded] = useState<boolean>(false);
  const {
    getFRTDetailsData,
    FRTDetails,
    getFRTSpecificationsData,
    FRTSpecifications,
    getFRTNotesData,
    FRTNotes,
  } = useFRT();
  const { isDesktop } = useThemeBreakpoint();
  const navigate = useNavigate();
  const { nextPageAlert, clearAlert } = useAlert();
  const { t } = useTranslations();

  useEffect(() => {
    (async function getFRTSpecificationsDataInit() {
      try {
        setIsFRTSpecificationsLoading(true);
        await Promise.all([
          getFRTDetailsData(frtId),
          getFRTSpecificationsData(frtId),
          getFRTNotesData(frtId),
        ]);
      } catch (e) {
        console.error(e);
      }
      setIsFRTSpecificationsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frtId]);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_NEXT_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const isLocal: boolean = isFRTLocal(frtId);

  let frtDetailsTitle = '';
  if (FRTDetails !== null) {
    const { make, model, action } = FRTDetails;
    frtDetailsTitle = `${make}, ${model}, ${action}`;
  }
  const calibres = FRTSpecifications.map((spec) => spec.caliber).join(', ') || '-';
  const frtNotes = FRTNotes.map((note) => (
    <Grid key={note.type} item xs={12}>
      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
        {note.type}
      </Typography>
      {getHtmlText(note.description)}
    </Grid>
  ));
  const handleToggleExpandAll = () => {
    setFirearmDetailsAllExpanded((prev) => !prev);
  };

  return (
    <div>
      <Breadcrumbs
        items={[
          {
            label: t('firearms.registeredFirearms.breadcrumbs.firearms'),
            route: ERouteLinks.Firearms,
          },
          {
            label: t('firearmsReferenceTable.firearmDetails.firearmDetails.header'),
            route: `${ERouteLinks.Firearms}?expand=frt`,
          },
          isLocal
            ? t('firearmsReferenceTable.editFirearm.viewLocalFirearm.header')
            : t('firearmsReferenceTable.editFirearm.viewFirearm.header'),
        ]}
      />
      <Box sx={{ mb: 2 }}>
        <StyledPageTitle variant="h4">
          {isLocal
            ? t('firearmsReferenceTable.editFirearm.viewLocalFirearm.header')
            : t('firearmsReferenceTable.editFirearm.viewFirearm.header')}
        </StyledPageTitle>
      </Box>

      {nextPageAlert && (
        <Box mb={2}>
          <Alert text={nextPageAlert.text} variant={nextPageAlert.variant} />
        </Box>
      )}

      <Grid container>
        <Grid item xs={12} mb={5}>
          {isFRTSpecificationsLoading ? (
            <Loader isLoading={isFRTSpecificationsLoading} />
          ) : (
            <DetailsTemplate
              title={frtDetailsTitle}
              deletePermissions={[EPermission.MANAGE_FRT_FIREARM]}
              editPermissions={[EPermission.MANAGE_FRT_FIREARM]}
              redirectToEditView={
                isLocal
                  ? () => navigate(ERouteLinks.EditFRT.replace(':frtId', frtId))
                  : undefined
              }
              pageType={EDetailsPageTypes.view}
              hasLongTitle={true}
            >
              <Grid item xs={12} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t('firearmsReferenceTable.editFirearm.calibres.label')}
                </Typography>
                <Typography variant="body2">{calibres}</Typography>
              </Grid>
            </DetailsTemplate>
          )}
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', mt: -2, mb: 1 }}>
          <StyledButton
            label={
              firearmDetailsAllExpanded
                ? t('firearmsReferenceTable.firearmDetails.collapseAll.link')
                : t('firearmsReferenceTable.firearmDetails.expandAll.link')
            }
            onClick={handleToggleExpandAll}
            variant={EButtonVariants.outlined}
          />
        </Grid>

        <Grid item xs={12}>
          <Accordion
            title={t('firearmsReferenceTable.editFirearm.general.accordion.name')}
            expanded={firearmDetailsAllExpanded}
          >
            {FRTDetails && (
              <Box mb={2}>
                <StyledDetailsWrapper container rowGap={3}>
                  <Grid item xs={12} sm={6} lg={3}>
                    <Typography variant="subtitle2">
                      {isLocal
                        ? t(
                            'firearmsReferenceTable.editFirearm.general.accordion.firearmReferenceLocalID.label',
                          )
                        : t(
                            'firearmsReferenceTable.editFirearm.general.accordion.firearmReferenceNo.label',
                          )}
                    </Typography>
                    <Typography variant="body2" sx={{ marginTop: '4px' }}>
                      {isLocal ? FRTDetails.frtId : FRTDetails.id}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <Typography variant="subtitle2">
                      {t(
                        'firearmsReferenceTable.editFirearm.general.accordion.manufacturer.label',
                      )}
                    </Typography>
                    <Typography variant="body2" sx={{ marginTop: '4px' }}>
                      {FRTDetails.manufacturer}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <Typography variant="subtitle2">
                      {t('firearmsReferenceTable.editFirearm.general.accordion.level.label')}
                    </Typography>
                    <Typography variant="body2" sx={{ marginTop: '4px' }}>
                      {FRTDetails.level}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <Typography variant="subtitle2">
                      {t(
                        'firearmsReferenceTable.editFirearm.general.accordion.countryOfManufacturer.label',
                      )}
                    </Typography>
                    <Typography variant="body2" sx={{ marginTop: '4px' }}>
                      {FRTDetails.country}
                    </Typography>
                  </Grid>
                </StyledDetailsWrapper>
              </Box>
            )}
          </Accordion>
        </Grid>
        <Grid item xs={12}>
          <Accordion
            title={t(
              'firearmsReferenceTable.firearmDetails.calibreShotsAndBarrelLength.accordion.name',
            )}
            expanded={firearmDetailsAllExpanded}
            name="calibre"
          >
            <Box mb={2}>
              {isFRTSpecificationsLoading ? (
                <Loader isLoading={isFRTSpecificationsLoading} />
              ) : (
                <div>
                  <Grid container columnSpacing={1} my={2} justifyContent="flex-end">
                    <Grid item xs={12} lg="auto" display="flex" justifyContent="flex-end">
                      {isLocal && hasPermissions([EPermission.MANAGE_FRT_FIREARM]) && (
                        <Button
                          fullWidth
                          label={t(
                            'firearmsReferenceTable.firearmDetails.calibreShotsAndBarrelLength.accordion.addCalibre.button',
                          )}
                          icon={EIconTypes.plus}
                          variant={EButtonVariants.contained}
                          onClick={() =>
                            FRTDetails
                              ? navigate(
                                  ERouteLinks.FRTAddCalibre.replace(':frtId', FRTDetails.id),
                                )
                              : {}
                          }
                        />
                      )}
                    </Grid>
                  </Grid>
                  {isDesktop ? (
                    <Table
                      columns={FRTSpecificationsTableColumns({
                        handleSelect: (specificationId: string) =>
                          navigate(
                            `${ERouteLinks.FRTEditCalibre.replace(':frtId', frtId).replace(
                              ':specificationId',
                              specificationId,
                            )}`,
                          ),
                        isLocal,
                        hasEditButton: true,
                        t,
                        hasPermissionsManageFRT: hasPermissions([
                          EPermission.MANAGE_FRT_FIREARM,
                        ]),
                      })}
                      dataSource={FRTSpecifications}
                    />
                  ) : (
                    <CardList
                      items={FRTSpecifications}
                      render={(data, index) => (
                        <FRTSpecificationsCard
                          data={data}
                          key={index}
                          isLocal={isLocal}
                          handleEdit={(specificationId: string) =>
                            navigate(
                              `${ERouteLinks.FRTEditCalibre.replace(':frtId', frtId).replace(
                                ':specificationId',
                                specificationId,
                              )}`,
                            )
                          }
                        />
                      )}
                    />
                  )}
                </div>
              )}
            </Box>
          </Accordion>
        </Grid>

        <Grid item xs={12}>
          <Accordion
            title={t('firearmsReferenceTable.editFirearm.notes.accordion.name')}
            expanded={firearmDetailsAllExpanded}
          >
            <div>
              {isFRTSpecificationsLoading ? (
                <Loader isLoading={isFRTSpecificationsLoading} />
              ) : (
                <StyledDetailsWrapper container rowGap={3}>
                  {frtNotes}
                </StyledDetailsWrapper>
              )}
            </div>
          </Accordion>
        </Grid>
      </Grid>
    </div>
  );
};

export { FRTDetailsPage };
