import React, { useEffect, useState } from 'react';
import SearchFilterStore from 'state/SearchFilterStore';
import { ESearchFilterKeys } from 'constants/SearchFilterTypes';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { StyledPageTitle } from 'theme/styles';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/system';
import { FormInput } from 'components/molecules/FormInput';
import Grid from '@mui/material/Grid';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { Typography } from '@mui/material';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import FormWrapper from 'components/atoms/FormWrapper';
import {
  StyledFilterChipWrapper,
  StyledSelectedFiltersWrapper,
} from 'pages/MyFiltersPage/styles';
import { FilterChip } from 'components/atoms/FilterChip';
import { objectToKeyValueArray } from 'helpers/objects';
import { useDictionary } from 'models/dictionaryContext';
import { parseDictionary, parseMultipleDictionaries } from 'helpers/dictionary';
import { FormCheckbox } from 'components/molecules/FormCheckbox';
import { useTranslations } from 'hooks/useTranslations';
import { useAlert } from 'models/alertContext';
import useSafePresetSchema from 'pages/LegalEntitiesPage/SavePresetPage/schema';
import { EArtAncillaryTypeNameLowerCase } from 'constants/ArtAccessoryType';
import { ActionTypes } from 'state/actions/alert';
import { useSaveAncillaryPreset } from 'pages/AncillariesPage/SaveAncillariesPresetPage/hooks';
import {
  mapValuesToAncillariesPresetRequest,
  mapValuesWithDictionariesHelper,
  permittedAccessoryFilters,
  permittedAmmunitionFilters,
  permittedComponentFilters,
} from 'pages/AncillariesPage/SaveAncillariesPresetPage/helpers';
import {
  EAncillaryComponentLabels,
  EAncillaryLabels,
} from 'pages/AncillariesPage/AncillariesReferenceTablePage/DetailAncillariesReference';
import { useLicenceContext } from 'models/licenceContext';
import { useGlobalProperty } from 'models/globalPropertyContext';

export interface IPresetFormValues {
  name: string;
  emailNotificationEnabled: boolean;
  notificationEnabled: boolean;
}

const initialFormValues = {
  name: '',
  emailNotificationEnabled: false,
  notificationEnabled: false,
};

const SaveAncillariesPresetPage = () => {
  const {
    state,
    getState,
    legality,
    getLegality,
    artAncillaryOptions,
    artAccessoryCalibre,
    artAccessoryComposition,
    artAccessoryHostType,
    artAccessoryLightColour,
    artAccessoryMagnification,
    artAccessoryManufacturerFlashEliminator,
    artAccessoryManufacturerLaserLightModule,
    artAccessoryManufacturerMagazine,
    artAccessoryManufacturerOpticalSight,
    artAccessoryManufacturerSuppressor,
    artAccessoryReticle,
    artAccessoryType,
    artAccessoryUtility,
    artAccessoryProofHouse,
    getArtAncillaryOptions,
    getArtAccessoryCalibre,
    getArtAccessoryComposition,
    getArtAccessoryHostType,
    getArtAccessoryLightColour,
    getArtAccessoryMagnification,
    getArtAccessoryManufacturerFlashEliminator,
    getArtAccessoryManufacturerLaserLightModule,
    getArtAccessoryManufacturerMagazine,
    getArtAccessoryManufacturerOpticalSight,
    getArtAccessoryManufacturerSuppressor,
    getArtAccessoryReticle,
    getArtAccessoryType,
    getArtAccessoryUtility,
    getArtAccessoryProofHouse,
    artAmmunitionType,
    artAmmunitionManufacturer,
    artAmmunitionFactory,
    artAmmunitionCalibreGauge,
    artAmmunitionCartridgeComposition,
    artAmmunitionColourOfPrimerSealant,
    artAmmunitionProjectileShape,
    artAmmunitionFunctionalType,
    artAmmunitionShotSize,
    artAmmunitionMunitionType,
    artAmmunitionMunitionVelocity,
    getArtAmmunitionType,
    getArtAmmunitionManufacturer,
    getArtAmmunitionFactory,
    getArtAmmunitionCalibreGauge,
    getArtAmmunitionCartridgeComposition,
    getArtAmmunitionColourOfPrimerSealant,
    getArtAmmunitionProjectileShape,
    getArtAmmunitionFunctionalType,
    getArtAmmunitionShotSize,
    getArtAmmunitionMunitionType,
    getArtAmmunitionMunitionVelocity,
    artComponentType,
    artComponentManufacturer,
    artComponentCalibre,
    artComponentCapacity,
    artComponentProofHouse,
    getArtComponentType,
    getArtComponentManufacturer,
    getArtComponentCalibre,
    getArtComponentCapacity,
    getArtComponentProofHouse,
    artComposition,
    getArtComposition,
    make,
    getMake,
    model,
    getModel,
    firearmType,
    getFirearmType,
    manufacturer,
    getManufacturer,
  } = useDictionary();
  const { t } = useTranslations();
  const { schema } = useSafePresetSchema();

  const [filtersArray, setFiltersArray] = useState<{ key: string; value: string }[]>([]);
  const { type } = useParams<{ type: ESearchFilterKeys | EArtAncillaryTypeNameLowerCase }>();
  const [error, setError] = useState<string>('');
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { savePreset } = useSaveAncillaryPreset();
  const { setAlert } = useAlert();
  const { isOnlineLicence } = useLicenceContext();
  const { shortDateFormat } = useGlobalProperty();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IPresetFormValues>({
    defaultValues: initialFormValues,
    resolver: yupResolver(schema),
  });
  const filters = SearchFilterStore.getValue(type as ESearchFilterKeys);
  const { state: routerState }: any = useLocation();

  const filterPassedValues = (parsedFilters: { key: string; value: string }[]) => {
    switch (type) {
      case EArtAncillaryTypeNameLowerCase.ACCESSORY: {
        return parsedFilters.filter((record) =>
          permittedAccessoryFilters.includes(record.key),
        );
      }
      case EArtAncillaryTypeNameLowerCase.AMMUNITION: {
        return parsedFilters.filter((record) =>
          permittedAmmunitionFilters.includes(record.key),
        );
      }
      case EArtAncillaryTypeNameLowerCase.COMPONENTS: {
        return parsedFilters.filter((record) =>
          permittedComponentFilters.includes(record.key),
        );
      }
      default:
        return [];
    }
  };

  const filtersWithDictionaries = () => {
    switch (type) {
      case EArtAncillaryTypeNameLowerCase.ACCESSORY:
      case EArtAncillaryTypeNameLowerCase.AMMUNITION:
      case EArtAncillaryTypeNameLowerCase.COMPONENTS:
        return setFiltersArray(
          mapValuesWithDictionariesHelper(filterPassedValues(objectToKeyValueArray(filters)), {
            ancillaryTypeUniqueId: parseMultipleDictionaries(
              [artAccessoryType, artAmmunitionType, artComponentType],
              'name',
            ),
            manufacturerUniqueId: parseMultipleDictionaries(
              [
                artAccessoryManufacturerMagazine,
                artAccessoryManufacturerOpticalSight,
                artAccessoryManufacturerSuppressor,
                artAccessoryManufacturerFlashEliminator,
                artAccessoryManufacturerLaserLightModule,
                artAmmunitionManufacturer,
                artComponentManufacturer,
              ],
              'name',
            ),
            calibreUniqueId: parseMultipleDictionaries(
              [artAccessoryCalibre, artAmmunitionCalibreGauge, artComponentCalibre],
              'name',
            ),
            hostTypeUniqueId: parseDictionary(artAccessoryHostType, 'name'),
            magnificationUniqueId: parseDictionary(artAccessoryMagnification, 'name'),
            utilityUniqueId: parseDictionary(artAccessoryUtility, 'name'),
            reticleUniqueId: parseDictionary(artAccessoryReticle, 'name'),
            lightColourUniqueId: parseDictionary(artAccessoryLightColour, 'name'),
            compositionUniqueId: parseMultipleDictionaries(
              [artAccessoryComposition, artComposition],
              'name',
            ),
            proofHouseUniqueId: parseMultipleDictionaries(
              [artAccessoryProofHouse, artComponentProofHouse],
              'name',
            ),
            stateUniqueId: parseDictionary(state, 'name'),
            legalityUniqueId: parseDictionary(legality, 'name'),
            componentCapacityUniqueId: parseDictionary(artComponentCapacity, 'name'),
            frtMake: parseDictionary(make, 'name'),
            frtModel: parseDictionary(model, 'name'),
            frtType: parseDictionary(firearmType, 'name'),
            frtManufacturer: parseDictionary(manufacturer, 'name'),
            factoryUniqueId: parseDictionary(artAmmunitionFactory, 'name'),
            cartridgeCompositionUniqueId: parseDictionary(
              artAmmunitionCartridgeComposition,
              'name',
            ),
            colourOfPrimerSealantUniqueId: parseDictionary(
              artAmmunitionColourOfPrimerSealant,
              'name',
            ),
            projectileShapeUniqueId: parseDictionary(artAmmunitionProjectileShape, 'name'),
            functionalTypeUniqueId: parseDictionary(artAmmunitionFunctionalType, 'name'),
            munitionTypeUniqueId: parseDictionary(artAmmunitionMunitionType, 'name'),
            shotSizeUniqueId: parseDictionary(artAmmunitionShotSize, 'name'),
            munitionVelocityUniqueId: parseDictionary(artAmmunitionMunitionVelocity, 'name'),
          }),
        );
      default:
        return [];
    }
  };

  useEffect(() => {
    (async function init() {
      if (type === EArtAncillaryTypeNameLowerCase.ACCESSORY) {
        if (!state) {
          getState();
        }

        if (!legality) {
          getLegality();
        }

        if (!artAncillaryOptions) {
          getArtAncillaryOptions();
        }

        if (!artAccessoryCalibre) {
          getArtAccessoryCalibre();
        }

        if (!artAccessoryComposition) {
          getArtAccessoryComposition();
        }

        if (!artAccessoryHostType) {
          getArtAccessoryHostType();
        }

        if (!artAccessoryLightColour) {
          getArtAccessoryLightColour();
        }

        if (!artAccessoryMagnification) {
          getArtAccessoryMagnification();
        }

        if (!artAccessoryManufacturerFlashEliminator) {
          getArtAccessoryManufacturerFlashEliminator();
        }

        if (!artAccessoryManufacturerLaserLightModule) {
          getArtAccessoryManufacturerLaserLightModule();
        }

        if (!artAccessoryManufacturerMagazine) {
          getArtAccessoryManufacturerMagazine();
        }

        if (!artAccessoryManufacturerOpticalSight) {
          getArtAccessoryManufacturerOpticalSight();
        }

        if (!artAccessoryManufacturerSuppressor) {
          getArtAccessoryManufacturerSuppressor();
        }

        if (!artAccessoryReticle) {
          getArtAccessoryReticle();
        }

        if (!artAccessoryType) {
          getArtAccessoryType();
        }

        if (!artAccessoryUtility) {
          getArtAccessoryUtility();
        }

        if (!artAccessoryProofHouse) {
          getArtAccessoryProofHouse();
        }
      } else if (type === EArtAncillaryTypeNameLowerCase.AMMUNITION) {
        if (!artAncillaryOptions) {
          getArtAncillaryOptions();
        }

        if (!artAmmunitionType) {
          getArtAmmunitionType();
        }

        if (!artAmmunitionManufacturer) {
          getArtAmmunitionManufacturer();
        }

        if (!artAmmunitionFactory) {
          getArtAmmunitionFactory();
        }

        if (!artAmmunitionCalibreGauge) {
          getArtAmmunitionCalibreGauge();
        }

        if (!artAmmunitionCartridgeComposition) {
          getArtAmmunitionCartridgeComposition();
        }

        if (!artAmmunitionColourOfPrimerSealant) {
          getArtAmmunitionColourOfPrimerSealant();
        }

        if (!artAmmunitionProjectileShape) {
          getArtAmmunitionProjectileShape();
        }

        if (!artAmmunitionFunctionalType) {
          getArtAmmunitionFunctionalType();
        }

        if (!artAmmunitionShotSize) {
          getArtAmmunitionShotSize();
        }

        if (!artAmmunitionMunitionType) {
          getArtAmmunitionMunitionType();
        }

        if (!artAmmunitionMunitionVelocity) {
          getArtAmmunitionMunitionVelocity();
        }
      } else if (type === EArtAncillaryTypeNameLowerCase.COMPONENTS) {
        if (!state) {
          getState();
        }
        if (!artAncillaryOptions) {
          getArtAncillaryOptions();
        }

        if (!artComponentType) {
          getArtComponentType();
        }

        if (!artComponentManufacturer) {
          getArtComponentManufacturer();
        }

        if (!artComponentCalibre) {
          getArtComponentCalibre();
        }

        if (!artComponentCapacity) {
          getArtComponentCapacity();
        }

        if (!artComponentProofHouse) {
          getArtComponentProofHouse();
        }
        if (!artComposition) {
          getArtComposition();
        }
        if (!make) {
          getMake();
        }
        if (!model) {
          getModel();
        }
        if (!firearmType) {
          getFirearmType();
        }
        if (!manufacturer) {
          getManufacturer();
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (filters) {
      filtersWithDictionaries();
    }
  }, [
    state,
    legality,
    artAncillaryOptions,
    artAccessoryCalibre,
    artAccessoryComposition,
    artAccessoryHostType,
    artAccessoryLightColour,
    artAccessoryMagnification,
    artAccessoryManufacturerFlashEliminator,
    artAccessoryManufacturerLaserLightModule,
    artAccessoryManufacturerMagazine,
    artAccessoryManufacturerOpticalSight,
    artAccessoryManufacturerSuppressor,
    artAccessoryReticle,
    artAccessoryType,
    artAccessoryUtility,
    artAccessoryProofHouse,
    artAmmunitionType,
    artAmmunitionManufacturer,
    artAmmunitionFactory,
    artAmmunitionCalibreGauge,
    artAmmunitionCartridgeComposition,
    artAmmunitionColourOfPrimerSealant,
    artAmmunitionProjectileShape,
    artAmmunitionFunctionalType,
    artAmmunitionShotSize,
    artAmmunitionMunitionType,
    artAmmunitionMunitionVelocity,
    artComponentType,
    artComponentManufacturer,
    artComponentCalibre,
    artComponentCapacity,
    artComponentProofHouse,
  ]);

  const getBackRoute = () => {
    switch (type) {
      case EArtAncillaryTypeNameLowerCase.ACCESSORY:
        return `${ERouteLinks.Ancillaries}?expand=accessories`;
      case EArtAncillaryTypeNameLowerCase.AMMUNITION:
        return `${ERouteLinks.Ancillaries}?expand=ammunition`;
      case EArtAncillaryTypeNameLowerCase.COMPONENTS:
        return `${ERouteLinks.Ancillaries}?expand=components`;

      default:
        return ERouteLinks.Ancillaries;
    }
  };

  const getLabelsByType = () => {
    switch (type) {
      case EArtAncillaryTypeNameLowerCase.ACCESSORY:
        return EAncillaryLabels;
      case EArtAncillaryTypeNameLowerCase.AMMUNITION:
        return EAncillaryLabels;
      case EArtAncillaryTypeNameLowerCase.COMPONENTS:
        return EAncillaryComponentLabels;
      default:
        return EAncillaryLabels;
    }
  };

  const labels = getLabelsByType();

  useEffect(() => {
    if (filters === null) {
      navigate(getBackRoute());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const onSubmitForm = async (data: IPresetFormValues) => {
    if (type) {
      setIsSubmitLoading(true);
      const body = mapValuesToAncillariesPresetRequest(filters, type, shortDateFormat);

      if (body) {
        try {
          const res = await savePreset({
            name: data.name,
            emailNotificationEnabled: data.emailNotificationEnabled,
            notificationEnabled: data.notificationEnabled,
            type,
            body,
          });
          if (res.status === 200) {
            setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
              text: t('saveMyFilter.save.success.text'),
              variant: EAlertVariants.success,
            });
            return navigate(`${ERouteLinks.MyFilters}`);
          }

          setError(res.response.data.message);
        } catch (e: any) {
          console.error(e);
        }
      }
    }
    return setIsSubmitLoading(false);
  };

  const getBreadcrumbsLabel = () => {
    let label = '';
    if (type) {
      switch (type) {
        case EArtAncillaryTypeNameLowerCase.ACCESSORY:
          label = t('ancillaries.accessories.accordion.name');
          break;
        case EArtAncillaryTypeNameLowerCase.AMMUNITION:
          label = t('ancillaries.ammunition.accordion.name');
          break;
        case EArtAncillaryTypeNameLowerCase.COMPONENTS:
          label = t('ancillaries.components.accordion.name');
          break;
        default:
          break;
      }
    }
    return label;
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.ancillaries.name'), route: ERouteLinks.Ancillaries },
          {
            label: getBreadcrumbsLabel(),
            route: getBackRoute(),
          },
          t('saveMyFilter.header'),
        ]}
      />
      <StyledPageTitle variant="h4">{t('saveMyFilter.header')}</StyledPageTitle>

      <FormWrapper>
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <Grid container>
            <Grid item xs={12} lg={6}>
              <Box mb={1}>
                <FormInput
                  name="name"
                  label={`${t('saveMyFilter.filterName.label')}*`}
                  placeholder={t('saveMyFilter.filterName.label')}
                  setValue={setValue}
                  control={control}
                  errors={errors}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <StyledSelectedFiltersWrapper container rowGap={1}>
                <Grid item>
                  <Typography variant="body2">{`${t(
                    'saveMyFilter.selectedCriteria.section',
                  )}:`}</Typography>
                </Grid>
                <StyledFilterChipWrapper item>
                  {filtersArray &&
                    filtersArray.map((item: { key: string; value: string }) => {
                      if (
                        item &&
                        item.key === 'ownerUniqueId' &&
                        routerState &&
                        routerState.additionalSaveFilterValues.owner !== undefined
                      ) {
                        item.value = routerState.additionalSaveFilterValues.owner.label;
                      }
                      if (
                        item &&
                        item.key === 'keeperUniqueId' &&
                        routerState &&
                        routerState.additionalSaveFilterValues.keeper !== undefined
                      ) {
                        item.value = routerState.additionalSaveFilterValues.keeper.label;
                      }
                      return (
                        <FilterChip
                          key={item.key}
                          name={item.key}
                          label={t(labels?.[item.key as keyof typeof labels]) || item.key}
                          values={item.value}
                        />
                      );
                    })}
                </StyledFilterChipWrapper>
              </StyledSelectedFiltersWrapper>
            </Grid>
            <Grid item xs={12} container columnSpacing={3}>
              <Grid item xs={12}>
                <FormCheckbox
                  name="notificationEnabled"
                  label={t('saveMyFilter.notifyMeViaSystem.checkbox.label')}
                  control={control}
                  errors={errors}
                />
              </Grid>
              {isOnlineLicence() && (
                <Grid item xs={12}>
                  <FormCheckbox
                    name="emailNotificationEnabled"
                    label={t('saveMyFilter.notifyMeViaEmail.checkbox.label')}
                    control={control}
                    errors={errors}
                  />
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} container columnSpacing={3} rowGap={3} mt={2}>
              <Grid item xs={12} sm={6} lg={3}>
                <Button
                  type="submit"
                  fullWidth
                  label={t('saveMyFilter.save.button')}
                  variant={EButtonVariants.contained}
                  isLoading={isSubmitLoading}
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={3}>
                <Button
                  fullWidth
                  label={t('general.cancel.button')}
                  variant={EButtonVariants.outlined}
                  onClick={() =>
                    navigate(routerState.cancelPath || getBackRoute(), {
                      state: { uniqueIds: routerState?.additionalSaveFilterValues.uniqueIds },
                    })
                  }
                />
              </Grid>
            </Grid>
            <Grid item mt={2} xs={12}>
              <Typography variant="caption">{t('general.mandatoryField.text')}</Typography>
            </Grid>
            {error && (
              <Grid item mt={2} xs={12}>
                <Alert text={error} variant={EAlertVariants.error} />
              </Grid>
            )}
          </Grid>
        </form>
      </FormWrapper>
    </>
  );
};

export { SaveAncillariesPresetPage };
