import React, { useEffect, useState } from 'react';
import { IAuditLogSearchFormValues, initialAuditLogSearchValues } from 'models/auditLog';
import { useForm } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import { SearchWrapper } from 'components/atoms/SearchWrapper';
import { FormSelect } from 'components/molecules/FormSelect';
import { FormDatePicker } from 'components/molecules/FormDatePicker';
import { useAuditLog } from 'pages/AuditLogPage/hooks';
import { useLang } from 'models/langContext';
import { EDictionaryTypes, IDictionary } from 'models/dictionary';
import { mapDictionaryToOptionProp } from 'helpers/dictionary';
import { useUsers } from 'pages/UsersPage/hooks';
import { IFormElementOptionProp } from 'models/form';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { isDateAfter } from 'utils/date';
import { useTranslations } from 'hooks/useTranslations';
import { getEnumByValue } from 'utils/enum';
import { StyledSearchForm } from 'theme/styles';
import { IUser } from 'models/user';
import { SelectedFilters } from 'components/organisms/SelectedFilters';
import { prepareFilters } from 'helpers/filters';
import { EPerPages } from 'models/table';

interface IAuditLogSearchForm {
  onSubmit: (arg: IAuditLogSearchFormValues) => void;
  isSearchButtonDisabled?: boolean;
  initialSearchForm: IAuditLogSearchFormValues;
  currentPage: number;
  perPage: EPerPages;
}

enum EAuditLogSearchFormLabels {
  accountUniqueId = 'searchForAuditLog.auditLogTable.eventAuthor.column',
  moduleUniqueId = 'searchForAuditLog.auditLogTable.module.column',
  eventTypeUniqueId = 'searchForAuditLog.auditLogTable.eventType.column',
  eventDateFrom = 'searchForAuditLog.auditLogTable.dateFrom.column',
  eventDateTo = 'searchForAuditLog.auditLogTable.dateTo.column',
}

enum EModules {
  AUDIT_LOG = '0dd7b846-7cbe-11ee-b962-0242ac120002',
  AUTHENTICATION = '3acfc8d6-0dfd-4bb5-bb40-6d39d33f70a4',
  LEGAL_ENTITIES = '973f6d22-b2e7-43bd-929c-12c7c747dcd1',
  FIREARMS = '20fbd0b5-8668-4c03-8eea-cc92c0f4c200',
  TRANSACTIONS = 'f4517045-0c4b-4a14-be9e-a327f5e7a8cf',
  ADMINISTRATION = 'c6746456-24c6-4c53-b242-53ab63c86864',
  CUSTOM_FIELDS = 'e9cb112e-6645-46ea-a302-5ce7c44910fa',
  ANCILLARIES = '5bdfb1dc-4315-4de9-bc27-b08237fb6f7c',
  API_KEYS = 'daaca311-edd1-46f3-9ec4-5d0b3ddb2e77',
}

enum EEventTypes {
  LOGIN = '7d41df96-dbc2-425b-985a-467585f52ac4',
  LOGOUT = 'f4e6c23a-eb0b-450f-9524-6d004db53447',
  BLOCK = '64b007ab-6d8c-4959-9833-60d8e48d0391',
  PASSWORD_CHANGE = 'ccf0cf7c-53a8-4e61-9ecf-da59c5d7a0f7',
  ADD = '9f815892-29a2-4471-adab-e0358545f2c3',
  EDIT = 'b5643ce1-cb61-4839-b7b1-296c54066034',
  DELETE = 'c700995c-d3b5-4562-949d-8ae1935fe259',
  DATA_EXPORT = '0157cdfd-0090-406f-9909-cee2b2384115',
  ENABLE = 'cb8c00c3-10ed-4686-a8a7-31bcee580f90',
  DISABLE = '93a3654c-3cb7-403b-ba42-576f2e655854',
  DISCARD = '234cca19-f7fe-4e21-8593-3394a76ac6ea',
  ACTIVE = '4d064986-7fcc-4533-8d20-4c12a068c450',
  DEACTIVE = '95d20adc-9e5b-46df-8813-255bb279fdd8',
}

const filters: Record<EModules, EEventTypes[]> = {
  [EModules.AUDIT_LOG]: [EEventTypes.DATA_EXPORT],
  [EModules.AUTHENTICATION]: [
    EEventTypes.LOGIN,
    EEventTypes.LOGOUT,
    EEventTypes.PASSWORD_CHANGE,
    EEventTypes.BLOCK,
  ],
  [EModules.LEGAL_ENTITIES]: [
    EEventTypes.DATA_EXPORT,
    EEventTypes.ADD,
    EEventTypes.EDIT,
    EEventTypes.DELETE,
  ],
  [EModules.FIREARMS]: [
    EEventTypes.DATA_EXPORT,
    EEventTypes.ADD,
    EEventTypes.EDIT,
    EEventTypes.DISABLE,
    EEventTypes.DEACTIVE,
  ],
  [EModules.ANCILLARIES]: [
    EEventTypes.DATA_EXPORT,
    EEventTypes.ADD,
    EEventTypes.EDIT,
    EEventTypes.DISABLE,
  ],
  [EModules.TRANSACTIONS]: [EEventTypes.DATA_EXPORT, EEventTypes.ADD, EEventTypes.DISCARD],
  [EModules.ADMINISTRATION]: [
    EEventTypes.EDIT,
    EEventTypes.DEACTIVE,
    EEventTypes.ACTIVE,
    EEventTypes.ADD,
  ],
  [EModules.CUSTOM_FIELDS]: [
    EEventTypes.EDIT,
    EEventTypes.ADD,
    EEventTypes.ENABLE,
    EEventTypes.DISABLE,
    EEventTypes.DELETE,
  ],
  [EModules.API_KEYS]: [
    EEventTypes.ADD,
    EEventTypes.EDIT,
    EEventTypes.DELETE,
    EEventTypes.ACTIVE,
    EEventTypes.DEACTIVE,
  ],
};

const filterEventType = (
  module: string,
  dictionary: IDictionary[] | null,
): IDictionary[] | null => {
  if (dictionary === null) {
    return null;
  }
  if (module === '') {
    return dictionary;
  }
  const validModule: EModules = getEnumByValue(EModules, module);

  return dictionary.filter((dictionaryRecord) =>
    filters[validModule]
      .map((moduleRecord) => moduleRecord.toString())
      .includes(dictionaryRecord.uniqueId.toString()),
  );
};

const compareUserNames = (user1: IUser, user2: IUser): number => {
  if (user1.firstName.toLowerCase() > user2.firstName.toLowerCase()) {
    return 1;
  }
  if (user1.firstName.toLowerCase() < user2.firstName.toLowerCase()) {
    return -1;
  }
  if (user1.lastName.toLowerCase() > user2.lastName.toLowerCase()) {
    return 1;
  }
  if (user1.lastName.toLowerCase() < user2.lastName.toLowerCase()) {
    return -1;
  }
  return 0;
};

const AuditLogSearchForm = ({
  onSubmit,
  isSearchButtonDisabled = false,
  initialSearchForm,
  perPage,
  currentPage,
}: IAuditLogSearchForm) => {
  const {
    control,
    setValue,
    handleSubmit,
    reset,
    getValues,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<IAuditLogSearchFormValues>({
    defaultValues: initialSearchForm,
  });

  const { t } = useTranslations();

  const { shortDateFormat, getDateFormat } = useGlobalProperty();
  const { selectedLanguage } = useLang();

  const { auditLogModule, auditLogEventType, loadDictionaries } = useAuditLog();
  const [userOptions, setUserOptions] = useState<IFormElementOptionProp[]>([]);
  const { users, getUsersData } = useUsers();
  const [values, setValues] = useState<IAuditLogSearchFormValues>(initialAuditLogSearchValues);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    getDateFormat();
    (async () => {
      await getUsersData(1, 9999, undefined);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (users?.length) {
      const usersOptions = users.sort(compareUserNames).map((user) => ({
        label: `${user.firstName} ${user.lastName}`,
        value: user.uniqueId,
      }));
      setUserOptions(usersOptions);
    }
  }, [users]);

  useEffect(() => {
    (async () => {
      await loadDictionaries([
        EDictionaryTypes.AuditLogModule,
        EDictionaryTypes.AuditLogEventType,
      ]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  useEffect(() => {
    const subscription = watch((value) => {
      setValues(value as IAuditLogSearchFormValues);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    setValues(initialSearchForm);
    reset(initialSearchForm);
    handleSubmit(onSubmit)();
  }, [initialSearchForm]);

  useEffect(() => {
    if (!isInitialLoad) {
      handleSubmit(onSubmit)();
    } else {
      setIsInitialLoad(false);
    }
  }, [perPage, currentPage]);

  useEffect(() => {
    const subscription = watch((value) => {
      const message = t('general.validation.dateShouldBeLater.message');
      if (isDateAfter(getValues().eventDateFrom, value.eventDateTo as string)) {
        setError('eventDateTo', { type: 'custom', message });
      } else {
        clearErrors('eventDateTo');
      }
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  const handleResetField = (name?: string, refreshResults: boolean = true) => {
    if (name) {
      reset({ ...getValues(), [name]: '' });
    } else {
      reset(initialAuditLogSearchValues);
    }

    if (refreshResults) {
      onSubmit(getValues());
    }
  };

  return (
    <SearchWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledSearchForm container justifyContent="space-between" spacing={2} columns={10}>
          <Grid item lg={2} sm={5} xs={10}>
            <FormSelect
              options={userOptions}
              name="accountUniqueId"
              setValue={setValue}
              canBeEmpty={true}
              label={t(EAuditLogSearchFormLabels.accountUniqueId)}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item lg={2} sm={5} xs={10}>
            <FormSelect
              options={mapDictionaryToOptionProp(auditLogModule)}
              name="moduleUniqueId"
              setValue={setValue}
              onSelect={(arg) => {
                const module: EModules = getEnumByValue(EModules, arg);
                const eventValue = getValues('eventTypeUniqueId');
                const isEventValidForNewModule = filters[module]
                  .map((moduleRecord) => moduleRecord.toString())
                  .includes(eventValue);
                if (!isEventValidForNewModule) {
                  setValue('eventTypeUniqueId', '');
                }
              }}
              canBeEmpty={true}
              label={t(EAuditLogSearchFormLabels.moduleUniqueId)}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item lg={2} sm={10} xs={10}>
            <FormSelect
              options={mapDictionaryToOptionProp(
                filterEventType(getValues('moduleUniqueId'), auditLogEventType),
              )}
              name="eventTypeUniqueId"
              setValue={setValue}
              canBeEmpty={true}
              label={t(EAuditLogSearchFormLabels.eventTypeUniqueId)}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item lg={2} md={5} sm={5} xs={5}>
            <FormDatePicker
              name="eventDateFrom"
              dateFormat={shortDateFormat}
              label={t(EAuditLogSearchFormLabels.eventDateFrom)}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item lg={2} md={5} sm={5} xs={5}>
            <FormDatePicker
              name="eventDateTo"
              dateFormat={shortDateFormat}
              label={t(EAuditLogSearchFormLabels.eventDateTo)}
              control={control}
              errors={errors}
              minDate={
                getValues().eventDateFrom ? (getValues().eventDateFrom as Date) : undefined
              }
            />
          </Grid>

          <Grid
            item
            xs={12}
            container
            columns={10}
            columnSpacing={2}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Grid item xs={10} sm={10} md={5} lg={2}>
              <Button
                variant={EButtonVariants.contained}
                size={EButtonSizes.small}
                label={t('searchForAuditLog.search.button')}
                disabled={isSearchButtonDisabled}
                type="submit"
                fullWidth
                sx={{
                  mt: {
                    xs: 1,
                  },
                }}
              />
            </Grid>
          </Grid>
        </StyledSearchForm>
      </form>
      <SelectedFilters
        name="auditLog"
        values={values}
        labels={EAuditLogSearchFormLabels}
        handleDelete={handleResetField}
        dictionaries={{
          accountUniqueId: prepareFilters(userOptions, 'value', 'label'),
          moduleUniqueId: prepareFilters(auditLogModule, 'uniqueId', 'name'),
          eventTypeUniqueId: prepareFilters(auditLogEventType, 'uniqueId', 'name'),
        }}
        noPreset
        showSaveFilters={false}
        saveRouterStateAfterClearAll={false}
      />
    </SearchWrapper>
  );
};

export default AuditLogSearchForm;
