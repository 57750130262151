import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IFormElementOptionProp, initialOrgLegalEntityFormValues } from 'models/form';
import { yupResolver } from '@hookform/resolvers/yup';
import Grid from '@mui/material/Grid';
import { FormInput } from 'components/molecules/FormInput';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { FormSelect } from 'components/molecules/FormSelect';
import { FormRadioGroup } from 'components/molecules/FormRadioGroup';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import { Typography } from '@mui/material';
import { mapDictionaryToOptionProp } from 'helpers/dictionary';
import { IOrgLegalEntityData } from 'components/organisms/OrgLegalEntityDetails';
import { useTranslations } from 'hooks/useTranslations';
import { useOrgLEFormSchema } from 'pages/LegalEntitiesPage/schemaValidation';
import { EDictionaryTypes } from 'models/dictionary';
import { useLang } from 'models/langContext';
import { ECustomFieldObjectType, ECustomFieldType } from 'models/customField';
import FormCustomField from 'components/organisms/FormCustomField';
import { ICustomFieldForm } from 'pages/LegalEntitiesPage/IndividualSearchForm';
import { ActionTypes } from 'state/actions/alert';
import { EAlertVariants } from 'components/atoms/Alert';
import { useAlert } from 'models/alertContext';
import { formatDateTimeToIso } from 'utils/date';
import { useScroll } from 'hooks/useScroll';
import { usePermission } from 'hooks/usePermission';
import { EPermission } from 'models/permissions';
import { ELegalVisibility } from 'constants/LegalVisibility';

export interface IOrgLegalEntityFormValues {
  uniqueId: string;
  organizationName: string;
  organizationTypeUniqueId: string;
  departmentName: string | null;
  visibilityUniqueId: string;
  city: string;
  customFields?: ICustomFieldForm[];
}

export interface ILegalEntityForm {
  onCancel?: () => void;
  onSubmit: (arg: IOrgLegalEntityFormValues) => void;
  initialFormValues?: IOrgLegalEntityData | null;
  isEdit?: boolean;
}

const OrgLegalEntityForm = ({
  onCancel,
  onSubmit,
  initialFormValues,
  isEdit = false,
}: ILegalEntityForm) => {
  const {
    loadDictionaries,
    legalVisibility,
    organizationType,
    getCustomField,
    customFieldsOLE,
  } = useLegalEntities();
  const { hasPermissions } = usePermission();
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [optionsVisibility, setOptionsVisibility] = useState<
    IFormElementOptionProp[] | undefined
  >(undefined);
  const [isDisableVisibility, setIsDisableVisibility] = useState<boolean>(true);
  const { t } = useTranslations();
  const { schema } = useOrgLEFormSchema(customFieldsOLE);
  const { selectedLanguage } = useLang();
  const { setAlert } = useAlert();
  const { elRef, scrollToError } = useScroll();
  let wasCustomFieldsDataChanged = false;

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<IOrgLegalEntityData | IOrgLegalEntityFormValues>({
    defaultValues: initialFormValues || initialOrgLegalEntityFormValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset(initialFormValues || initialOrgLegalEntityFormValues);
  }, [reset, initialFormValues]);

  useEffect(() => {
    if (initialFormValues?.uniqueId) {
      setValue('city', 'default');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFormValues]);

  useEffect(() => {
    (async () => {
      scrollToError(errors);
    })();
  }, [errors]);

  useEffect(() => {
    if (legalVisibility) {
      setOptionsVisibility(mapDictionaryToOptionProp(legalVisibility));
    }
  }, [legalVisibility]);

  useEffect(() => {
    if (optionsVisibility && !hasPermissions([EPermission.VIEW_ALL_LE])) {
      if (!isEdit) {
        setValue('visibilityUniqueId', ELegalVisibility.PUBLIC as string);
      }
      setIsDisableVisibility(true);
    } else {
      setIsDisableVisibility(false);
    }
  }, [initialFormValues, isEdit, optionsVisibility]);

  const hasDataChanged = (data: any): boolean => {
    let hasChanged = false;
    if (
      initialFormValues?.departmentName !== data.departmentName ||
      initialFormValues?.organizationName !== data.organizationName ||
      initialFormValues?.visibilityUniqueId !== data.visibilityUniqueId ||
      initialFormValues?.organizationTypeUniqueId !== data.organizationTypeUniqueId
    ) {
      hasChanged = true;
    }

    customFieldsOLE.forEach((customField) => {
      let matchingDataCustomFieldValue;
      // eslint-disable-next-line default-case
      switch (customField.customFieldType) {
        case ECustomFieldType.DATE: {
          const value = data[customField.name];
          if (value !== undefined && value !== null) {
            matchingDataCustomFieldValue = formatDateTimeToIso(value);
          }
          break;
        }
        case ECustomFieldType.BOOL: {
          const value = data[customField.name];
          if (value !== undefined && value !== null && value.trim() !== '') {
            matchingDataCustomFieldValue = data[customField.name] === '1';
          }
          break;
        }
        case ECustomFieldType.TEXT: {
          const value = data[customField.name];
          if (value !== undefined && value !== null && value.trim() !== '') {
            matchingDataCustomFieldValue = data[customField.name];
          }
          break;
        }
      }
      let initialCustomField;
      let initCustomFieldData;
      if (!!initialFormValues && initialFormValues.customFields !== undefined) {
        initialCustomField = initialFormValues.customFields.find(
          (initCustomField) => initCustomField.customFieldUniqueId === customField.uniqueId,
        );
        initCustomFieldData =
          initialCustomField &&
          initialCustomField.value !== undefined &&
          initialCustomField.value !== null
            ? initialCustomField.value
            : undefined;
      }
      if (initCustomFieldData !== matchingDataCustomFieldValue) {
        wasCustomFieldsDataChanged = true;
        hasChanged = true;
      }
    });

    return hasChanged;
  };

  const onSubmitForm = async (data: any) => {
    if (!hasDataChanged(data)) {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: t('addLegalEntity.save.warning.text'),
        variant: EAlertVariants.warning,
      });
      return;
    }

    if (wasCustomFieldsDataChanged) {
      data.customFields = [];

      const customFieldPayload: ICustomFieldForm[] = [];

      Object.keys(data).map((key) => {
        const customField = customFieldsOLE.find((item) => item.name === key);
        if (customField) {
          const customFieldItem: ICustomFieldForm = {
            customFieldUniqueId: customField.uniqueId,
          };
          if (customField.customFieldType === ECustomFieldType.TEXT) {
            if (data[key] === undefined || data[key] === null) {
              customFieldItem.text = undefined;
            } else if (data[key].trim() === '') {
              customFieldItem.text = undefined;
            } else {
              customFieldItem.text = data[key];
            }
          } else if (customField.customFieldType === ECustomFieldType.BOOL) {
            if (data[key] === '') {
              customFieldItem.bool = null;
            } else {
              customFieldItem.bool = data[key] === '1';
            }
          } else if (customField.customFieldType === ECustomFieldType.DATE) {
            customFieldItem.date = data[key] ? formatDateTimeToIso(data[key]) : null;
          }
          customFieldPayload.push(customFieldItem);
          delete data[key];
        }
        return false;
      });

      if (customFieldPayload?.length && customFieldPayload.length > 0) {
        data.customFields = customFieldPayload;
      } else {
        delete data.customFields;
      }
    } else {
      delete data.customFields;
    }

    setIsSubmitLoading(true);
    if (initialFormValues) {
      await onSubmit({
        ...data,
      });
      setIsSubmitLoading(false);
    } else {
      await onSubmit(data);
      setIsSubmitLoading(false);
    }
    setIsSubmitLoading(false);
  };

  useEffect(() => {
    (async function init() {
      await loadDictionaries([
        EDictionaryTypes.LegalVisibility,
        EDictionaryTypes.OrganizationType,
      ]);
      await getCustomField(ECustomFieldObjectType.LE_ORGANIZATION);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Grid container columnSpacing={3} ref={elRef}>
        <Grid item xs={12} lg={4}>
          <FormInput
            name="organizationName"
            label={`${t('editLegalEntity.name.label')}*`}
            control={control}
            setValue={setValue}
            errors={errors}
            charsLimit={80}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <FormSelect
            options={mapDictionaryToOptionProp(organizationType)}
            name="organizationTypeUniqueId"
            label={`${t('editLegalEntity.organizationType.label')}*`}
            setValue={setValue}
            control={control}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <FormInput
            name="departmentName"
            label={`${t('editLegalEntity.departmentName.label')}*`}
            setValue={setValue}
            control={control}
            errors={errors}
            charsLimit={80}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <FormRadioGroup
            name="visibilityUniqueId"
            label={`${t('editLegalEntity.visibility.label')}*`}
            options={optionsVisibility ?? []}
            control={control}
            errors={errors}
            disabled={isDisableVisibility}
          />
        </Grid>

        {customFieldsOLE.map((customField) => (
          <Grid item xs={12} lg={4} key={customField.uniqueId}>
            <FormCustomField
              data={customField}
              control={control}
              errors={errors}
              setValue={setValue}
            />
          </Grid>
        ))}

        <Grid item xs={12} container columnSpacing={1} rowGap={1} mt={1}>
          <Grid item xs={12} sm={6} lg={4}>
            <Button
              type="submit"
              fullWidth
              label={t('general.save.button')}
              id="legal_entity-save"
              variant={EButtonVariants.contained}
              isLoading={isSubmitLoading}
            />
          </Grid>

          {onCancel && (
            <Grid item xs={12} sm={6} lg={4}>
              <Button
                fullWidth
                label={t('general.cancel.button')}
                id="legal_entity-cancel"
                variant={EButtonVariants.outlined}
                onClick={onCancel}
              />
            </Grid>
          )}
        </Grid>
        <Grid item mt={2}>
          <Typography variant="caption">{t('general.mandatoryField.text')}</Typography>
        </Grid>
      </Grid>
    </form>
  );
};

export { OrgLegalEntityForm };
