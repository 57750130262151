import React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { colors } from 'theme/colors';
import { Box } from '@mui/material';

const ToBeStyledTooltip = ({ className, ...props }: TooltipProps) => (
  <Tooltip enterTouchDelay={0} {...props} classes={{ tooltip: className }} />
);

export const StyledTooltip = styled(ToBeStyledTooltip)(() => ({
  backgroundColor: colors.error700,
  color: colors.white,
  fontWeight: 600,
  fontSize: '14px',
}));

export const StyledNoResults = styled(Box)(() => ({
  backgroundColor: colors.gray200,
  borderRadius: '4px',
  padding: '20px',
}));
