import React from 'react';
import { Controller } from 'react-hook-form';

import FormErrorLabel from 'components/atoms/FormErrorLabel';
import { Checkbox, FormControlLabel } from '@mui/material';
import {
  defaultCheckboxStyle,
  StyledCheckboxBackgroundWrapper,
  StyledCheckboxLabel,
  StyledFormCheckboxWrapper,
} from 'components/molecules/FormCheckbox/styles';
import { IFormElementProps } from 'models/form';

export const FormCheckbox = ({
  name,
  label,
  control,
  errors,
  withValidation = true,
  disabled = false,
  onChange,
  style = defaultCheckboxStyle,
  labelVariant = 'body2',
  checked,
}: IFormElementProps) => (
  <StyledFormCheckboxWrapper>
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          {...field}
          control={
            <StyledCheckboxBackgroundWrapper backgroundColor={style?.backgroundColorUnChecked}>
              <Checkbox
                disabled={disabled}
                checked={checked !== undefined ? checked : field.value}
                inputProps={{ 'aria-label': 'controlled' }}
                sx={{
                  color: style.borderColor,
                  '&.Mui-checked': {
                    color: style.backgroundColorChecked,
                  },
                  '&.Mui-disabled': {
                    color: style.backgroundColorDisabled,
                  },
                }}
                onChange={(e) => {
                  field.onChange(e.target.checked);
                  if (onChange) {
                    onChange(e.target.checked);
                  }
                }}
              />
            </StyledCheckboxBackgroundWrapper>
          }
          label={
            label !== undefined ? (
              <StyledCheckboxLabel disabled={disabled} variant={labelVariant || 'body2'}>
                {label}
              </StyledCheckboxLabel>
            ) : (
              ''
            )
          }
        />
      )}
    />
    {withValidation && errors[name]?.message && (
      <FormErrorLabel label={errors[name]?.message} />
    )}
  </StyledFormCheckboxWrapper>
);
