import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { Box } from '@mui/system';
import { StyledPageTitle } from 'theme/styles';
import { FormInput } from 'components/molecules/FormInput';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import Button from 'components/atoms/Button';
import { useForm } from 'react-hook-form';
import { LocationMap } from 'components/organisms/LocationMap';
import {
  ISearchParams,
  searchLocationAddressByCoords,
  searchLocationCoordsByAddress,
} from 'requests/legalEntities';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import { useTranslations } from 'hooks/useTranslations';
import { useLicenceContext } from 'models/licenceContext';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAlert } from 'models/alertContext';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { ActionTypes } from 'state/actions/alert';

const initialFormValues = {
  address: '',
  city: '',
};

const SelectLocationPage = () => {
  const [coords, setCoords] = useState<any>(null);
  const [address, setAddress] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [isAddressLoaded, setIsAddressLoaded] = useState(false);
  const [country, setCountry] = useState('');
  const { state: routerState }: any = useLocation();
  const navigate = useNavigate();
  const { id = '' } = useParams<{ id: string }>();
  const { t } = useTranslations();
  const { isOnlineLicence } = useLicenceContext();
  const { clearAlert, setAlert, samePageAlert } = useAlert();

  const schema = yup.object().shape({
    address: yup
      .string()
      .required(t('general.field.validation.required'))
      .max(255, t('general.field.validation.maxCharacters').replace('{0}', '255')),
    city: yup
      .string()
      .required(t('general.field.validation.required'))
      .max(255, t('general.field.validation.maxCharacters').replace('{0}', '255')),
  });

  if (!routerState) {
    navigate(ERouteLinks.MiddleTransaction.replace(':id', id));
  }

  useEffect(() => {
    if (routerState && routerState.coords && coords === null) {
      setCoords(routerState.coords);
    }
  }, [routerState]);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    [],
  );

  let defaultLatitude: number = parseFloat(
    process.env.REACT_APP_MAP_DEFAULT_COUNTRY_CENTER_LATITUDE
      ? process.env.REACT_APP_MAP_DEFAULT_COUNTRY_CENTER_LATITUDE
      : '0',
  );
  let defaultLongitude: number = parseFloat(
    process.env.REACT_APP_MAP_DEFAULT_COUNTRY_CENTER_LONGITUDE
      ? process.env.REACT_APP_MAP_DEFAULT_COUNTRY_CENTER_LONGITUDE
      : '0',
  );
  let defaultZoom: number = parseInt(
    process.env.REACT_APP_MAP_DEFAULT_ZOOM ? process.env.REACT_APP_MAP_DEFAULT_ZOOM : '5',
    10,
  );

  if (routerState?.coords?.length) {
    [defaultLatitude, defaultLongitude] = routerState.coords;
    defaultZoom = 14;
  }

  const {
    control,
    reset,
    formState: { errors },
    getValues,
    setValue,
    setError,
    handleSubmit,
    clearErrors,
  } = useForm({ defaultValues: initialFormValues, resolver: yupResolver(schema) });

  const handleSearchLocationCoordsByAddress = async (
    searchParams: ISearchParams,
    successCallback: (p: any) => void,
    isOnline: boolean = true,
  ) => {
    if (getValues().address.length === 0) {
      setError('address', {
        type: 'custom',
        message: t('selectLocationOnMap.addressValidation.text'),
      });
      return;
    }
    if (getValues().address.length > 255) {
      setError('address', {
        type: 'custom',
        message: t('general.field.validation.maxCharacters').replace('{0}', '255'),
      });
      return;
    }
    searchLocationCoordsByAddress(searchParams, (d) => successCallback(d), isOnline).then(
      (value) => {
        if (value) {
          clearErrors('address');
        } else {
          setError('address', {
            type: 'custom',
            message: t('selectLocationOnMap.addressValidation.text'),
          });
        }
      },
    );
  };

  useEffect(() => {
    if (coords) {
      const lat = coords[0];
      const lon = coords[1];

      searchLocationAddressByCoords(lat, lon, isOnlineLicence()).then((value) => {
        if (value && value.address) {
          clearErrors('address');
          clearErrors('city');
          const place =
            value.address.city ||
            value.address.town ||
            value.address.village ||
            // @ts-ignore
            '';
          // @ts-ignore
          const road = value.address.road || value.address.municipality || '';
          // @ts-ignore
          const houseNumber = value.address.house_number || '';
          const newAddress =
            `${place} ${road} ${houseNumber}`.trim() || value.name || value.display_name || '';
          setCountry(value.address.country);
          setAddress(newAddress);
          setCity(place);
          setIsAddressLoaded(true);
        } else {
          setError('address', {
            type: 'custom',
            message: t('selectLocationOnMap.enterAddress.text'),
          });
        }
      });
    }
  }, [coords]);

  // eslint-disable-next-line consistent-return
  const handleSetLocation = () => {
    if (coords === null) {
      return setError('address', {
        type: 'custom',
        message: t('selectLocationOnMap.enterAddress.text'),
      });
    }

    navigate(routerState.returnLink, {
      state: {
        ...routerState,
        coords,
        address: getValues('address').trim(),
        city: getValues('city').trim(),
        country,
        isReloadCausedLocationSelect: true,
      },
    });
  };

  useEffect(() => {
    if (address && isAddressLoaded) {
      reset({ address, city });
      routerState.address = address;
      setIsAddressLoaded(false);
    }
  }, [address, isAddressLoaded]);

  const handleSearchButtonClick = async (e: { preventDefault: () => void }) => {
    clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    // eslint-disable-next-line no-restricted-globals
    e.preventDefault();
    await handleSearchLocationCoordsByAddress(
      {
        q: `${getValues().city} ${getValues().address}`.trim(),
      },
      (data) => {
        setCoords([data[0].lat, data[0].lon]);
        searchLocationAddressByCoords(data[0].lat, data[0].lon, isOnlineLicence()).then(
          (value) => {
            setCountry(value?.address?.country);
          },
        );
      },
      isOnlineLicence(),
    );
  };

  return (
    <div>
      <Breadcrumbs items={routerState?.breadcrumbs || []} />
      <Box sx={{ mb: 2 }}>
        <StyledPageTitle variant="h4">
          {t('selectLocationOnMap.selectLocationOnMap.header')}
        </StyledPageTitle>
      </Box>
      <Typography variant="body1" mb={2}>
        {t('selectLocationOnMap.info.text')}
      </Typography>
      <form onSubmit={handleSubmit(handleSetLocation)}>
        <Grid item xs={12} container columnGap={3}>
          <Grid item xs={12} lg={6}>
            <FormInput
              name="address"
              label={`${t('selectLocationOnMap.address.label')}*`}
              control={control}
              setValue={setValue}
              errors={errors}
              placeholder={t('selectLocationOnMap.placeholder.text')}
            />
          </Grid>
          <Grid item xs={12} lg={3} sx={{ paddingTop: { lg: '28px' } }}>
            <Button
              label={t('selectLocationOnMap.search.button')}
              variant={EButtonVariants.outlined}
              size={EButtonSizes.small}
              fullWidth
              onClick={handleSearchButtonClick}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormInput
              name="city"
              label={`${t('selectLocationOnMap.city.label')}*`}
              control={control}
              setValue={setValue}
              errors={errors}
              placeholder={t('selectLocationOnMap.cityPlaceholder.text')}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} container columnGap={5} rowGap={1} my={2}>
          <Grid item xs={12} sm="auto">
            <Typography variant="subtitle1" display="flex">
              {t('selectLocationOnMap.latitude.label')}:{' '}
              <Typography variant="body1" ml={1}>
                {coords !== null ? coords[0] : '-'}
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} sm="auto">
            <Typography variant="subtitle1" display="flex">
              {t('selectLocationOnMap.longitude.label')}:{' '}
              <Typography variant="body1" ml={1}>
                {coords !== null ? coords[1] : '-'}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
        {samePageAlert && (
          <Box mt={2} sx={{ marginBottom: '10px' }}>
            <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
          </Box>
        )}
        <LocationMap
          coords={coords !== null ? coords : [defaultLatitude, defaultLongitude]}
          dimensions={{ width: '100%', height: '600px' }}
          defaultZoom={defaultZoom}
          getCoordsCallback={(data: any) => {
            setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
              text: t('selectLocationOnMap.locationManuallyAdded.message'),
              variant: EAlertVariants.warning,
            });

            reset(initialFormValues);
            setCoords([data.lat, data.lng]);
          }}
          isOnline={isOnlineLicence()}
        />
        <Grid item xs={12} container mt={5} spacing={2}>
          <Grid item xs={12} md={6} lg={3}>
            <Button
              fullWidth
              type="submit"
              label={t('selectLocationOnMap.setLocation.button')}
              variant={EButtonVariants.contained}
              sx={{ mr: 1 }}
              onClick={handleSubmit}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <Button
              fullWidth
              label={t('selectLocationOnMap.cancel.button')}
              variant={EButtonVariants.outlined}
              onClick={() => {
                routerState.address = null;
                navigate(routerState.returnLink, {
                  state: { ...routerState, isReloadCausedLocationSelect: true },
                });
              }}
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export { SelectLocationPage };
