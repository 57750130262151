import React from 'react';
import { ITableColumns, ITableDataSource } from 'models/table';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import { IUser } from 'models/user';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import { ELegalEntityTypes } from 'constants/LegalEntityTypes';
import {
  IAssignedIndividualLegalResponse,
  IAssignedOrganizationLegalResponse,
} from 'models/account';
import { formatDate } from 'utils/date';
import { ELegalVisibility } from 'constants/LegalVisibility';
import { Link } from 'react-router-dom';
import { ERouteLinks } from 'models/route';

export enum EUserStatusActivated {
  activate = 'activate',
  deactivate = 'deactivate',
}

export const mapUsersToDataSource = (roles: IUser[]): ITableDataSource[] =>
  roles.map((user) => ({
    id: user.uniqueId,
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    createdDate: user.created,
    lastLoginDate: user.lastLoginDate,
    active: user.status,
    blocked: user.blocked,
    removed: user.removed,
    phoneNumber: user.phoneNumber,
  }));

interface IUsersTableColumns {
  handleEdit: (arg: string) => void;
  handleActivate: (arg: string) => void;
  handleDeactivate: (arg: string) => void;
  handleUnban: (arg: string) => void;
  longDateFormat: string;
  t: (key: string) => string;
  loggedUserId: string;
}

export const usersTableColumns = ({
  handleEdit,
  handleActivate,
  handleDeactivate,
  handleUnban,
  longDateFormat,
  t,
  loggedUserId,
}: IUsersTableColumns): ITableColumns[] => [
  {
    id: '4eaea4ee-d36f-400b-895b-231c81f091cf',
    key: 'email',
    title: 'users.usersTable.email.column',
  },
  {
    id: '4eaea4ee-d36f-410b-895b-231c81f091cf',
    key: 'firstName',
    title: 'users.usersTable.firstName.column',
  },
  {
    id: '4eaea4ee-d36f-420b-895b-231c81f091cf',
    key: 'lastName',
    title: 'users.usersTable.lastName.column',
  },
  {
    id: '4eaea4ee-d36f-430b-895b-231c81f091cf',
    key: 'phoneNumber',
    title: 'users.usersTable.phoneNumber.column',
    width: '170',
  },
  {
    id: '4eaea4ee-d36f-440b-895b-231c81f091cf',
    key: 'active',
    title: 'users.usersTable.status.column',
    isSortable: false,
    isCenterTableHead: true,
    align: 'center',
    render: (_data, props) => {
      if (props.removed) {
        return <Chips text={t('users.removed.label')} variant={EChipsVariants.error200} />;
      }
      if (props.blocked) {
        return <Chips text={t('users.blocked.label')} variant={EChipsVariants.warning100} />;
      }
      return (
        <Chips
          text={props.active ? t('users.active.label') : t('users.inactive.label')}
          variant={props.active ? EChipsVariants.success100 : EChipsVariants.error}
        />
      );
    },
  },
  {
    id: '4eaea4ee-d36f-450b-895b-231c81f091cf',
    key: 'createdDate',
    title: 'users.usersTable.created.column',
    width: '170',
    render: (_data, props) =>
      props.createdDate ? formatDate(props.createdDate, longDateFormat) : '-',
  },
  {
    id: '4eaea4ee-d36f-460b-895b-231c81f091cf',
    key: 'lastLoginDate',
    title: 'users.usersTable.lastLogin.column',
    width: '180',
    render: (_data, props) =>
      props.lastLoginDate && !props.removed
        ? formatDate(props.lastLoginDate, longDateFormat)
        : '-',
  },
  {
    id: '4eaea4ee-d36f-500b-895b-231c81f091cf',
    key: '',
    title: 'users.usersTable.actions.column',
    isSortable: false,
    width: '110px',
    align: 'center',
    isCenterTableHead: true,
    render: (_data, props) => (
      <>
        <Link to={ERouteLinks.ViewUser.replace(':id', props.id)}>
          <Button
            variant={EButtonVariants.outlined}
            tableButton={true}
            icon={EIconTypes.view}
          />
        </Link>
        {!props.removed && (
          <Button
            variant={EButtonVariants.outlined}
            tableButton={true}
            icon={EIconTypes.edit}
            onClick={() => handleEdit(props.id)}
          />
        )}

        {props.id !== loggedUserId &&
          !props.removed &&
          (props.blocked ? (
            <Button
              variant={EButtonVariants.outlined}
              tableButton={true}
              icon={EIconTypes.checkmark}
              onClick={() => handleUnban(props.id)}
            />
          ) : (
            <Button
              variant={EButtonVariants.outlined}
              tableButton={true}
              icon={props.active ? EIconTypes.shutDown : EIconTypes.checkmark}
              onClick={() =>
                props.active ? handleDeactivate(props.id) : handleActivate(props.id)
              }
            />
          ))}
      </>
    ),
  },
];

interface IAssignedLEsTableColumns {
  handleDelete: (arg: string, type: ELegalEntityTypes) => void;
}

export const assignedLEsTableColumns = ({
  handleDelete,
}: IAssignedLEsTableColumns): ITableColumns[] => [
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f510',
    key: 'type',
    title: 'userDetails.assignedLegalEntityTable.legalEntityType.column',
    isSortable: false,
    isCenterTableHead: true,
    align: 'center',
    render: (_data, props) => (
      <Chips
        text={props.type}
        variant={
          props.type === ELegalEntityTypes.individual
            ? EChipsVariants.warning
            : EChipsVariants.primary
        }
      />
    ),
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f511',
    key: 'orgName',
    title: 'userDetails.assignedLegalEntityTable.organizationName.column',
    isSortable: false,
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f512',
    key: 'orgType',
    title: 'userDetails.assignedLegalEntityTable.organizationType.column',
    isSortable: false,
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f513',
    key: 'firstName',
    title: 'userDetails.assignedLegalEntityTable.firstName.column',
    isSortable: false,
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f514',
    key: 'lastName',
    title: 'userDetails.assignedLegalEntityTable.lastName.column',
    isSortable: false,
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f515',
    key: '',
    title: 'userDetails.assignedLegalEntityTable.action.column',
    isSortable: false,
    width: '60px',
    align: 'center',
    isCenterTableHead: true,
    render: (_data, props) => (
      <Button
        variant={EButtonVariants.outlined}
        tableButton
        icon={EIconTypes.delete}
        onClick={() => handleDelete(props.id, props.type)}
      />
    ),
  },
];

export const mapAssignedLEsToDataSource = (
  assignedLEs: (IAssignedIndividualLegalResponse | IAssignedOrganizationLegalResponse)[],
  hasViewAllLEPermission: boolean | null,
): ITableDataSource[] => {
  assignedLEs = assignedLEs.filter(
    (legal) =>
      legal.visibilityUniqueId === ELegalVisibility.PUBLIC ||
      (legal.visibilityUniqueId === ELegalVisibility.NON_PUBLIC && hasViewAllLEPermission),
  );
  return assignedLEs.map(
    (assignedLE: IAssignedIndividualLegalResponse | IAssignedOrganizationLegalResponse) => ({
      id: assignedLE.uniqueId,
      type:
        assignedLE.legalType?.toLowerCase() === 'organization'
          ? 'organisation'
          : ELegalEntityTypes[assignedLE.legalType?.toLowerCase() as ELegalEntityTypes],
      orgName: 'organizationName' in assignedLE ? assignedLE.organizationName : '',
      orgType: 'departmentName' in assignedLE ? assignedLE.organizationType : '',
      firstName: 'firstName' in assignedLE ? assignedLE.firstName : '',
      lastName: 'lastName' in assignedLE ? assignedLE.lastName : '',
    }),
  );
};
