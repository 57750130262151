import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { colors } from 'theme/colors';
import styled from '@emotion/styled';
import Grid from '@mui/material/Grid';
import LoginBackground from 'assets/images/login-background.png';
import { IImageData, useLogoSetup } from 'pages/SystemSettingsPage/LogoSetupPage/hooks';
import { ELoginPartTypes } from 'models/loginScreenSetup';
import Logo from 'components/atoms/Logo';
import LogoImage from 'assets/images/logo-white.svg';
import { Loader } from 'components/atoms/Loader';

const StyledBackgroundSection = styled(Grid)`
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${LoginBackground});
  background-position: center;
`;
const StyledBackgroundSectionSubtitle = styled.p`
  margin-top: 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${colors.white};
`;

export const StyledImg = styled.img`
  object-fit: cover;
  width: 100%;
  max-height: 192px;
`;

export const StyledAuthHeader = styled(Typography)`
  color: ${colors.primary500};
  font-weight: 600;
  font-size: 2rem;
  text-align: center;
  line-height: 32px;
`;

export const getBase64Data = (image: IImageData) =>
  image ? `data:image/${image.fileType.toLowerCase()};base64,${image.content}` : '';

const AuthTemplate = ({ children }: PropsWithChildren<{}>) => {
  const [isLoadingLoginScreen, setLoadingLoginScreen] = useState<boolean>(true);
  const { getLoginScreen, loginScreenDataParsed } = useLogoSetup();

  useEffect(() => {
    setLoadingLoginScreen(true);
    (async () => {
      await getLoginScreen().then(() => {
        setLoadingLoginScreen(false);
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container justifyContent="center" sx={{ minHeight: '100vh' }}>
      {children}
      <StyledBackgroundSection
        item
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
        lg={6}
        sx={{ display: { xs: 'none', xl: 'flex' } }}
        p={10}
      >
        <Box>
          <Logo src={LogoImage} alt="Logo" />
        </Box>
        <Box>
          {loginScreenDataParsed?.[ELoginPartTypes.HEADER] && (
            <Typography variant="h3" fontWeight={700} color={colors.white} textAlign="center">
              {loginScreenDataParsed[ELoginPartTypes.HEADER] as string}
            </Typography>
          )}
          {(!loginScreenDataParsed?.[ELoginPartTypes.HEADER] ||
            !loginScreenDataParsed?.[ELoginPartTypes.SUBHEADER]) &&
            isLoadingLoginScreen && (
              <Box justifyContent="center">
                <Loader isLoading={isLoadingLoginScreen} color={colors.white} />
              </Box>
            )}
          {loginScreenDataParsed?.[ELoginPartTypes.SUBHEADER] && (
            <StyledBackgroundSectionSubtitle>
              {loginScreenDataParsed[ELoginPartTypes.SUBHEADER] as string}
            </StyledBackgroundSectionSubtitle>
          )}
        </Box>
      </StyledBackgroundSection>
    </Grid>
  );
};

export { AuthTemplate };
