import React from 'react';
import { ITableDataSource } from 'models/table';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { emptyFieldHolder } from 'components/molecules/CardList';
import { useTranslations } from 'hooks/useTranslations';

interface IFRTCard {
  data: ITableDataSource;
  handleSelect?: () => void;
  handleView?: (args: string) => void;
}

const FRTCard = ({ data, handleSelect, handleView }: IFRTCard) => {
  const { t } = useTranslations();

  return (
    <Grid
      container
      sx={{
        border: colors.gray200,
        borderRadius: '4px',
        overflow: 'hidden',
        mt: 1,
        backgroundColor: colors.gray100,
      }}
    >
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          Make
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.make)}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          Model
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.model)}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          Manufacturer
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.manufacturer)}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          Firearm Action
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.action)}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          Type
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.type)}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          Country
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.country)}</Typography>
      </Grid>

      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTop: {
            xs: `1px solid ${colors.primary25}`,
            lg: 'none',
          },
        }}
        p={2}
      >
        <Grid item xs={12} sm={3} sx={{ mb: { xs: 2, sm: 0 } }}>
          <Typography variant="button" sx={{ textTransform: 'uppercase' }}>
            Actions:{' '}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          {handleSelect && (
            <Button
              label={t('general.select.text')}
              variant={EButtonVariants.outlined}
              onClick={handleSelect}
              size="small"
              fullWidth
            />
          )}
          {handleView && (
            <Button
              label={t('general.details.text')}
              variant={EButtonVariants.outlined}
              onClick={() => handleView(data.id as string)}
              size="small"
              fullWidth
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export { FRTCard };
