import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from 'components/atoms/Button';
import { ERouteLinks } from 'models/route';
import { Table } from 'components/molecules/Table';
import { useTranslations } from 'hooks/useTranslations';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { useNavigate } from 'react-router-dom';
import { CardList } from 'components/molecules/CardList';
import { EButtonVariants } from 'constants/Buttons';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { EIconTypes } from 'constants/Icons';
import { useAncillaries } from 'pages/AncillariesPage/hooks';
import { EPerPages, ISortOptions } from 'models/table';
import { Pagination } from 'components/molecules/Pagination';
import { useLang } from 'models/langContext';
import {
  AmmunitionAttachmentsTableColumns,
  mapAmmunitionAttachmentsToDataSource,
} from '../helpers';
import { AmmunitionAttachmentsCard } from '../AmmunitionAttachmentsCard';

export enum EAmmunitionAttachmentsLabel {
  dateAndTime = 'ammunitionDetails.attachments.DateAndTime.column',
  attachmentName = 'ammunitionDetails.attachments.attachmentName.column',
  attachmentSize = 'ammunitionDetails.attachments.attachmentSize.column',
  transactionType = 'ammunitionDetails.attachments.transactionType.column',
  addAttachment = 'ammunitionDetails.attachments.addAttachment.button',
}

interface IAmmunitionAttachments {
  idAncillary: string | undefined;
}
const AmmunitionAttachments = ({ idAncillary }: IAmmunitionAttachments) => {
  const { isDesktop } = useThemeBreakpoint();
  const navigate = useNavigate();
  const { t } = useTranslations();
  const {
    getAmmunitionAttachmentsData,
    ammunitionAttachments,
    paginatorAmmunitionAttachments,
    transactionType,
    getTransactionType,
  } = useAncillaries();
  const { longDateFormat, midDateFormat } = useGlobalProperty();
  const [isLoading, setIsLoading] = useState<boolean | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<EPerPages>(EPerPages.perPage25);
  const [sort, setSort] = useState<ISortOptions | null>(null);
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);
  const { selectedLanguage } = useLang();

  useEffect(() => {
    getTransactionType();
  }, [selectedLanguage]);

  useEffect(() => {
    (async function getDataInit() {
      setIsLoading(true);
      if (idAncillary) {
        setIsPaginationLoading(true);
        await getAmmunitionAttachmentsData(idAncillary, { currentPage, perPage, sort });
        setIsPaginationLoading(false);
      }
      setIsLoading(false);
    })();
  }, [idAncillary, currentPage, perPage, sort]);

  return (
    <div>
      <Grid
        item
        xs={12}
        container
        sx={{ display: 'flex', justifyContent: 'flex-end', margin: '24px 0' }}
      >
        <Button
          variant={EButtonVariants.contained}
          icon={EIconTypes.plus}
          label={t(EAmmunitionAttachmentsLabel.addAttachment)}
          onClick={() =>
            idAncillary
              ? navigate(ERouteLinks.AmmunitionAddAttachment.replace(':id', idAncillary))
              : {}
          }
        />
      </Grid>
      {isDesktop ? (
        <Table
          columns={AmmunitionAttachmentsTableColumns({
            ancillaryId: idAncillary ?? '',
            handleView: (id: string) =>
              window.open(
                ERouteLinks.AmmunitionViewAttachment.replace(':id', id).replace(
                  ':ancillaryId',
                  idAncillary ?? '',
                ),
                '_blank',
              ),
            handleDelete: (id: string) =>
              navigate(
                ERouteLinks.AmmunitionDeleteAttachment.replace(':id', id).replace(
                  ':ancillaryId',
                  idAncillary ?? '',
                ),
                { state: { ammunitionAttachments } },
              ),
            dateFormat: longDateFormat,
          })}
          dataSource={mapAmmunitionAttachmentsToDataSource(
            ammunitionAttachments,
            transactionType,
            midDateFormat,
          )}
          translationsKeys={{
            noResults: 'ancillaries.accordion.noResults.text',
          }}
          onSort={setSort}
          loading={isLoading === true}
        />
      ) : (
        <CardList
          items={mapAmmunitionAttachmentsToDataSource(
            ammunitionAttachments,
            transactionType,
            midDateFormat,
          )}
          render={(data, index) => (
            <AmmunitionAttachmentsCard
              data={data}
              key={index}
              idAncillary={idAncillary}
              handleView={(id: string) =>
                window.open(
                  ERouteLinks.AmmunitionViewAttachment.replace(':id', id).replace(
                    ':ancillaryId',
                    idAncillary ?? '',
                  ),
                  '_blank',
                )
              }
              handleDelete={(id: string) =>
                navigate(
                  ERouteLinks.AmmunitionDeleteAttachment.replace(':id', id).replace(
                    ':ancillaryId',
                    idAncillary ?? '',
                  ),
                  { state: { ammunitionAttachments } },
                )
              }
            />
          )}
        />
      )}
      {paginatorAmmunitionAttachments && (
        <Pagination
          count={paginatorAmmunitionAttachments?.totalElements}
          perPage={perPage}
          onChangePage={(page) => {
            setCurrentPage(page);
          }}
          onChangePerPage={(value) => {
            setPerPage(value);
          }}
          current={currentPage}
          isLoading={isPaginationLoading}
        />
      )}
    </div>
  );
};

export { AmmunitionAttachments };
