import * as yup from 'yup';
import { ERegExpTypes } from 'constants/RegExpTypes';
import { useTranslations } from 'hooks/useTranslations';

export const useChangePasswordSchema = () => {
  const { t } = useTranslations();

  return yup.object({
    currentPassword: yup
      .string()
      .required(t('general.field.validation.required'))
      .min(8, t('ChangePasswordCurrentPasswordDoesNotMatchException'))
      .max(64, t('ChangePasswordCurrentPasswordDoesNotMatchException'))
      .test(
        'password-validation',
        t('ChangePasswordCurrentPasswordDoesNotMatchException'),
        (value) => {
          if (value) {
            const regex = new RegExp(ERegExpTypes.password);
            return regex.test(value);
          }
          return true;
        },
      ),
    newPassword: yup
      .string()
      .required(t('general.field.validation.required'))
      .min(8, t('changePasswordPage.validation.passwordNotValid.text'))
      .max(64, t('changePasswordPage.validation.passwordNotValid.text'))
      .test(
        'password-validation',
        t('changePasswordPage.validation.passwordNotValid.text'),
        (value) => {
          if (value) {
            const regex = new RegExp(ERegExpTypes.password);
            return regex.test(value);
          }
          return true;
        },
      ),

    newPasswordRepeat: yup
      .string()
      .required(t('general.field.validation.required'))
      .oneOf(
        [yup.ref('newPassword'), null],
        t('changePasswordPage.validation.passwordsDifferent.text'),
      ),
  });
};
