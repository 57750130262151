import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { StyledPageTitle } from 'theme/styles';
import FormWrapper from 'components/atoms/FormWrapper';
import { useParams } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import {
  ILegalEntityFormValues,
  LegalEntityForm,
} from 'pages/LegalEntitiesPage/LegalEntityForm';
import {
  IOrgLegalEntityFormValues,
  OrgLegalEntityForm,
} from 'pages/LegalEntitiesPage/OrgLegalEntityForm';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import { getLegalEntity, getOrgLegalEntity } from 'requests/legalEntities';
import { ILegalEntityData } from 'components/organisms/LegalEntityDetails';
import { useQuery } from 'hooks/useQuery';
import { IOrgLegalEntityData } from 'components/organisms/OrgLegalEntityDetails';
import { ELegalEntityTypes } from 'constants/LegalEntityTypes';
import { useTranslations } from 'hooks/useTranslations';
import { ECustomFieldType } from 'models/customField';
import { Box } from '@mui/material';
import { Alert } from 'components/atoms/Alert';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import { EDateFormats } from 'constants/dates';
import { formatDate } from 'utils/date';

const EditLegalEntityPage = () => {
  const { id } = useParams<{ id: string }>();
  const query = useQuery();
  const [legalEntity, setLegalEntity] = useState<ILegalEntityData | null>();
  const [orgLegalEntity, setOrgLegalEntity] = useState<IOrgLegalEntityData | null>();
  const { handleEdit, navigateToList, navigateToDetails, handleOrgLEEdit } =
    useLegalEntities();
  const { t } = useTranslations();
  const { clearAlert, samePageAlert } = useAlert();

  const type: string | null = query.get('type');
  const isView: boolean = !!query.get('view');

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (type === ELegalEntityTypes.individual) {
      (async function getLegalEntityDataInit() {
        if (id) {
          const response = await getLegalEntity(id);

          if (response) {
            response.dateOfBirth = formatDate(
              response.dateOfBirth,
              EDateFormats['yyyy/MM/dd'],
            );
            if (response?.customFields?.length) {
              response.customFields.map((customField) => {
                if (customField.customFieldType === ECustomFieldType.BOOL) {
                  if (customField.value === null) {
                    response[customField.customFieldName] = '';
                  } else {
                    response[customField.customFieldName] = customField.value ? '1' : '0';
                  }
                } else {
                  response[customField.customFieldName] = customField.value;
                }

                return false;
              });
            }
            setLegalEntity(response);
          }
        }
      })();
    } else if (type === ELegalEntityTypes.organisation) {
      (async function getLegalEntityDataInit() {
        if (id) {
          const response = await getOrgLegalEntity(id);
          if (response) {
            if (response?.customFields?.length) {
              response.customFields.map((customField) => {
                if (customField.customFieldType === ECustomFieldType.BOOL) {
                  if (customField.value === null) {
                    response[customField.customFieldName] = '';
                  } else {
                    response[customField.customFieldName] = customField.value ? '1' : '0';
                  }
                } else {
                  response[customField.customFieldName] = customField.value;
                }

                return false;
              });
            }

            setOrgLegalEntity(response);
          }
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const validateChangeInIndividualCustomFields = (
    data: ILegalEntityFormValues,
  ): ILegalEntityFormValues => {
    const validatedData = data;
    if (data.customFields) {
      data.customFields.forEach((customFieldObject, index) => {
        const customField = legalEntity?.customFields?.find(
          (oldCustomField) =>
            oldCustomField.customFieldUniqueId === customFieldObject.customFieldUniqueId,
        );
        if (
          customField &&
          customField.customFieldType === 'TEXT' &&
          customField.value === customFieldObject.text
        ) {
          validatedData.customFields?.splice(index, 1);
        }
      });
    }
    return validatedData;
  };

  const validateChangeInOrganisationCustomFields = (
    data: IOrgLegalEntityFormValues,
  ): IOrgLegalEntityFormValues => {
    const validatedData = data;
    if (data.customFields) {
      data.customFields.forEach((customFieldObject, index) => {
        const customField = orgLegalEntity?.customFields?.find(
          (oldCustomField) =>
            oldCustomField.customFieldUniqueId === customFieldObject.customFieldUniqueId,
        );
        if (
          customField &&
          customField.customFieldType === 'TEXT' &&
          customField.value === customFieldObject.text
        ) {
          validatedData.customFields?.splice(index, 1);
        }
      });
    }
    return validatedData;
  };

  const handleSubmit = async (data: ILegalEntityFormValues) => {
    const validatedData = validateChangeInIndividualCustomFields(data);
    const result = await handleEdit(validatedData);
    if (result) {
      navigateToList();
    }
  };

  const handleOrgLESubmit = async (data: IOrgLegalEntityFormValues) => {
    const validatedData = validateChangeInOrganisationCustomFields(data);
    const result = await handleOrgLEEdit(validatedData);
    if (result) {
      navigateToList();
    }
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.legalEntity.name'), route: ERouteLinks.LegalEntities },
          {
            label:
              type === ELegalEntityTypes.individual
                ? t('legalEntities.individual.accordion.label')
                : t('legalEntities.organization.accordion.label'),
            route: `${ERouteLinks.LegalEntities}?expand=${
              type === ELegalEntityTypes.individual ? 'individual' : 'organisation'
            }`,
          },
          t('editLegalEntity.header'),
        ]}
      />
      {(legalEntity || orgLegalEntity) && (
        <>
          <StyledPageTitle variant="h4">{t('editLegalEntity.header')}</StyledPageTitle>

          <FormWrapper>
            {legalEntity && type === ELegalEntityTypes.individual && (
              <LegalEntityForm
                onCancel={isView ? navigateToDetails : navigateToList}
                onSubmit={handleSubmit}
                initialFormValues={legalEntity}
                isEdit={true}
              />
            )}
            {orgLegalEntity && type === ELegalEntityTypes.organisation && (
              <OrgLegalEntityForm
                onCancel={isView ? navigateToDetails : navigateToList}
                onSubmit={handleOrgLESubmit}
                initialFormValues={orgLegalEntity}
                isEdit={true}
              />
            )}
          </FormWrapper>

          {samePageAlert && (
            <Box mt={2}>
              <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
            </Box>
          )}
        </>
      )}
    </>
  );
};

export { EditLegalEntityPage };
