import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { StyledPageTitle } from 'theme/styles';
import { Box, Typography } from '@mui/material';
import { ERouteLinks } from 'models/route';
import Grid from '@mui/material/Grid';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchTrustedDevices, removeTrustedDevice } from 'requests/trustedDevice';
import { IAddTrustedDeviceResponse } from 'models/trustedDevice';
import { useTranslations } from 'hooks/useTranslations';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import { EAlertVariants } from 'components/atoms/Alert';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { formatDate } from 'utils/date';
import { StyledDeviceInfo } from './style';

const RemoveDevicePage = () => {
  const { id = '' } = useParams<{ id: string }>();
  const { setAlert } = useAlert();
  const navigate = useNavigate();
  const [trustedDevice, setTrustedDevice] = useState<IAddTrustedDeviceResponse>();
  const { t } = useTranslations();
  const { longDateFormat } = useGlobalProperty();

  useEffect(() => {
    (async function init() {
      if (id) {
        await fetchTrustedDevices((devices) => {
          devices.map((device) => {
            if (device.uniqueId === id) {
              setTrustedDevice(device);
            }
            return false;
          });
        });
      }
    })();
  }, [id]);

  const handleDelete = async () => {
    if (trustedDevice) {
      const result = await removeTrustedDevice(trustedDevice.uniqueId);
      if (result) {
        setAlert(ActionTypes.SET_TRUSTED_DEVICE_ALERT, {
          text: t('deleteTrustedDevice.success.text'),
          variant: EAlertVariants.success,
        });
        navigate(ERouteLinks.Account);
      }
    }
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('userSettings.header'), route: ERouteLinks.Account },
          t('removeDevice.header'),
        ]}
      />
      <Box sx={{ mb: 1 }}>
        <StyledPageTitle variant="h4">{t('removeDevice.header')}</StyledPageTitle>
        <Typography variant="body1" sx={{ mb: 2 }}>
          {t('removeDevice.info.text')}
        </Typography>
      </Box>

      <StyledDeviceInfo>
        <Typography variant="subtitle2" sx={{ mb: 1 }}>
          {t('removeDevice.trustedDevice.text')}
        </Typography>
        <Typography variant="body2">
          {trustedDevice?.title},{' '}
          {trustedDevice?.createdDate
            ? formatDate(trustedDevice.createdDate, longDateFormat)
            : ''}
        </Typography>
      </StyledDeviceInfo>

      <Grid container justifyContent="flex-start" sx={{ mt: 4 }}>
        <Grid item xs={12} lg={6} container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              label={t('general.confirmation.yes.button')}
              id="yes-button"
              variant={EButtonVariants.contained}
              sx={{ mr: 1 }}
              onClick={() => handleDelete()}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              label={t('general.confirmation.no.button')}
              id="no-button"
              variant={EButtonVariants.outlined}
              onClick={() => navigate(ERouteLinks.Account)}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export { RemoveDevicePage };
