import { IRoleFormValues } from 'models/role';
import { IFRTCalibreFormValues } from 'models/frt';
import { CheckboxStyle } from 'components/molecules/FormCheckbox/styles';
import { IDictionary, IDictionaryValueFormValues } from 'models/dictionary';
import { IShortTransaction } from 'models/transaction';
import { ISearchFormCustomFieldValue } from 'pages/LegalEntitiesPage/IndividualSearchForm';
import { Variant } from '@mui/material/styles/createTypography';
import { EUserStatus } from 'pages/UsersPage/UserSearchForm';

const defaultLatitude: number = parseFloat(
  process.env.REACT_APP_MAP_DEFAULT_COUNTRY_CENTER_LATITUDE
    ? process.env.REACT_APP_MAP_DEFAULT_COUNTRY_CENTER_LATITUDE
    : '0',
);
const defaultLongitude: number = parseFloat(
  process.env.REACT_APP_MAP_DEFAULT_COUNTRY_CENTER_LONGITUDE
    ? process.env.REACT_APP_MAP_DEFAULT_COUNTRY_CENTER_LONGITUDE
    : '0',
);

export const initialExampleFormValues = {
  input: '',
  textarea: '',
  checkbox: true,
  switch: false,
  radioGroup: 'male',
  select: 'one',
  datepicker: null,
};

export const initialLoginFormValues = {
  email: '',
  password: '',
};

export const initialForgotPasswordFormValues = {
  email: '',
};

export const initialResetPasswordFormValues = {
  newPassword: '',
  newPasswordRepeat: '',
};

export const initialTwoFactorAuthFormValues: ITwoFactorAuthForm = {
  authCode: '',
  isRememberDevice: false,
  trustedDeviceTimeOptionUniqueId: undefined,
};

export interface ITwoFactorAuthForm {
  authCode: string;
  isRememberDevice: boolean;
  trustedDeviceTimeOptionUniqueId: string | undefined;
}

export const initialRoleFormValues: IRoleFormValues = {
  name: '',
  description: '',
  formPermissionIds: {},
  permissionIds: [],
};

export const initialDictionaryValueFormValues: IDictionaryValueFormValues = {
  isActive: 'true',
  translations: {},
};

export interface IFormElementProps {
  name: string;
  label?: string;
  control: any;
  errors: any;
  setError?: any;
  clearErrors?: any;
  withValidation?: boolean;
  disabled?: boolean;
  style?: CheckboxStyle;
  onChange?: (arg: boolean) => void;
  labelVariant?: Variant;
  checked?: boolean;
}

export interface IFormElementOptionProp {
  label: string;
  value: string | number;
}

export const initialLegalEntityFormValues = {
  firstName: '',
  lastName: '',
  dateOfBirth: null,
  genderUniqueId: '',
  visibilityUniqueId: '',
  prefixGroupUniqueId: '',
  prefixValueUniqueId: '',
  city: '',
};

export const initialOrgLegalEntityFormValues = {
  organizationName: '',
  organizationTypeUniqueId: '',
  departmentName: '',
  visibilityUniqueId: '',
  city: '',
};

export const initialCommentFormValues = {
  title: '',
  comment: '',
};

export const initialAddressFormValues = {
  isDefault: true,
  typeUniqueId: '',
  line1: '',
  line2: '',
  postalCode: '',
  city: '',
  regionUniqueId: '',
  countryUniqueId: '',
  latitude: defaultLatitude,
  longitude: defaultLongitude,
};

export const initialLegalEntityIndividualContactFormValues = {
  email: '',
  codeDictionaryUniqueId: '',
  value: '',
};

export const initialLegalEntityOrganizationContactFormValues = {
  name: '',
  role: '',
  email: '',
  codeDictionaryUniqueId: '',
  value: '',
};

export interface ISearchGroupFormValues {
  firearmSerialNumber: string;
}

export const initialGroupSearchFormValues: ISearchGroupFormValues = {
  firearmSerialNumber: '',
};

export interface ISearchFirearmFormValues {
  legalUniqueId?: string;
  serialNumber: string;
  externalReferenceNo: string;
  make: string;
  stateUniqueId: string;
}

export interface ISearchAncillaryFormValues {
  ancillarySpecificTypeUniqueId: string;
  ancillaryType: string;
  firearmType: string;
  serialNumber: string;
  lotNumber: string;
  batchNumber: string;
  manufacturerUniqueId: string;
}

export const initialFirearmSearchFormValues: ISearchFirearmFormValues = {
  legalUniqueId: '',
  serialNumber: '',
  externalReferenceNo: '',
  make: '',
  stateUniqueId: '',
};

export interface ISearchAncillariesFormValues {
  preset: string | undefined;
  artId: string;
  ancillaryDictionaryUniqueId: string;
  lotNumber: string;
  batchNumber: string;
  factoryUniqueId: string;
  manufactureDateFrom: Date | string | null;
  manufactureDateTo: Date | string | null;
  functionalTypeUniqueId: string;
  cartridgeCompositionUniqueId: string;
  colourOfPrimerSealantUniqueId: string;
  projectileShapeUniqueId: string;
  shotSizeUniqueId: string;
  munitionTypeUniqueId: string;
  munitionVelocityUniqueId: string;
  headstampYearMarking: number | string;
  alphanumericalMarkins: string;
  hostTypeUniqueId: string;
  magnificationUniqueId: string;
  productName: string;
  utilityUniqueId: string;
  reticleUniqueId: string;
  lightColourUniqueId: string;
  accessoryCapacity: string;
  frtType: string;
  frtMake: string;
  frtModel: string;
  frtManufacturer: string;
  componentCapacityUniqueId: string;
  length: number | string;
  ancillaryTypeUniqueId: string;
  manufacturerUniqueId: string;
  calibreUniqueId: string;
  serialNumber: string;
  compositionUniqueId: string;
  proofHouseUniqueId: string;
  fireCycleId: string;
  exhibitNumber: string;
  stateUniqueId: string;
  ownerUniqueId: string;
  keeperUniqueId: string;
  uniqueIds?: string[];
  ammunitionTypeUniqueId: string;
  expiryDateFrom: string | null;
  expiryDateTo: string | null;
  componentTypeUniqueId: string;
  componentManufacturerUniqueId: string;
  legalityUniqueId: string;
  capacity: string;
  customFields?: ISearchFormCustomFieldValue[];
  ownerName: string;
  keeperName: string;

  [key: string]: any;
}

export const initialAncillariesSearchFormValues: ISearchAncillariesFormValues = {
  preset: undefined,
  artId: '',
  ancillaryDictionaryUniqueId: '',
  lotNumber: '',
  batchNumber: '',
  factoryUniqueId: '',
  manufactureDateFrom: null,
  manufactureDateTo: null,
  functionalTypeUniqueId: '',
  cartridgeCompositionUniqueId: '',
  colourOfPrimerSealantUniqueId: '',
  projectileShapeUniqueId: '',
  shotSizeUniqueId: '',
  munitionTypeUniqueId: '',
  munitionVelocityUniqueId: '',
  headstampYearMarking: '',
  alphanumericalMarkins: '',
  hostTypeUniqueId: '',
  magnificationUniqueId: '',
  utilityUniqueId: '',
  reticleUniqueId: '',
  lightColourUniqueId: '',
  accessoryCapacity: '',
  frtType: '',
  frtMake: '',
  frtModel: '',
  frtManufacturer: '',
  componentCapacityUniqueId: '',
  length: '',
  ancillaryTypeUniqueId: '',
  manufacturerUniqueId: '',
  productName: '',
  calibreUniqueId: '',
  serialNumber: '',
  compositionUniqueId: '',
  proofHouseUniqueId: '',
  fireCycleId: '',
  exhibitNumber: '',
  stateUniqueId: '',
  ownerUniqueId: '',
  keeperUniqueId: '',
  ammunitionTypeUniqueId: '',
  expiryDateFrom: null,
  expiryDateTo: null,
  componentTypeUniqueId: '',
  componentManufacturerUniqueId: '',
  legalityUniqueId: '',
  capacity: '',
  ownerName: '',
  keeperName: '',
  customFields: [],
};

export interface ISearchLegalEntityAncillariesFormValues {
  legalUniqueId: string;
  ancillaryUniqueId: string;
  ancillaryTypeUniqueId: string;
  fireCycleId: string;
  stateUniqueId: string;
}

export const initialLegalEntityAncillariesSearchFormValues: ISearchLegalEntityAncillariesFormValues =
  {
    legalUniqueId: '',
    ancillaryUniqueId: '',
    ancillaryTypeUniqueId: '',
    fireCycleId: '',
    stateUniqueId: '',
  };

export interface ISearchLegalEntityAncillaryGroupFormValues {
  ancillaryType: string;
}

export const initialLegalEntityAncillaryGroupSearchFormValues: ISearchLegalEntityAncillaryGroupFormValues =
  {
    ancillaryType: '',
  };

export interface ILegalEntityGroupFormValues {
  uniqueId: string;
  name: string;
  firearmIds: string[];
}

export interface ILegalEntityAncillaryGroupFormValues {
  uniqueId: string;
  name: string;
  ancillariesIds: string[];
}

export interface ILegalEntityEditGroupFormValues {
  uniqueId: string;
  name: string;
}

export const initialLegalEntityGroupFormValues = {
  name: '',
};

export const initialLegalEntityAncillaryGroupFormValues = {
  name: '',
};

export interface ISearchGroupFirearmFormValues {
  serialNumber: string;
  make: string;
  model: string;
  caliber: string;
}

export const initialGroupFirearmSearchFormValues: ISearchGroupFirearmFormValues = {
  serialNumber: '',
  make: '',
  model: '',
  caliber: '',
};

export const initialGroupAncillarySearchFormValues: ISearchAncillaryFormValues = {
  ancillarySpecificTypeUniqueId: '',
  ancillaryType: '',
  firearmType: '',
  serialNumber: '',
  lotNumber: '',
  batchNumber: '',
  manufacturerUniqueId: '',
};

export interface ISearchUserFormValues {
  email: string;
  firstName: string;
  lastName: string;
  status?: EUserStatus;
  role: string;
  roleUniqueId: string;
  phoneNumber: string;
}

export const initialUserSearchFormValues: ISearchUserFormValues = {
  email: '',
  firstName: '',
  lastName: '',
  status: undefined,
  role: '',
  roleUniqueId: '',
  phoneNumber: '',
};

interface IRolesObject {
  [key: string]: undefined | boolean;
}

interface IPrefixGroupObject {
  [key: string]: undefined | boolean;
}

export interface IUserFormValues {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumberCodeDictionaryUniqueId: string;
  phoneNumberValue: string;
  roleIds: string[];
  formRoleIds: IRolesObject;
  formPrefixGroupIds: IPrefixGroupObject;
  prefixGroupIds: string[];
  password: string;
  version: number | undefined;
}

export interface IUserRequest {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: {
    codeDictionaryUniqueId: string;
    value: string;
  };
  roleIds: string[];
  password: string;
}

export interface IUserUpdateRequest {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: {
    codeDictionaryUniqueId: string;
    value: string;
  };
  roleIds: string[];
}

export const initialUserFormValues: IUserFormValues = {
  email: '',
  firstName: '',
  lastName: '',
  phoneNumberCodeDictionaryUniqueId: '',
  phoneNumberValue: '',
  roleIds: [],
  prefixGroupIds: [],
  formRoleIds: {},
  formPrefixGroupIds: {},
  password: '',
  version: undefined,
};

export interface ISearchFirearmsFormValues {
  serialNumber: string;
  externalReferenceNo: string;
  externalReferenceNumber: string;
  stateUniqueId: string;
  make: string;
  model: string;
  legalityUniqueId: string;
  manufacturer: string;
  caliber: string;
  groupUniqueId: string;
}

export const initialSearchFirearmsFormValues: ISearchFirearmsFormValues = {
  serialNumber: '',
  externalReferenceNo: '',
  externalReferenceNumber: '',
  stateUniqueId: '',
  make: '',
  model: '',
  legalityUniqueId: '',
  manufacturer: '',
  caliber: '',
  groupUniqueId: '',
};

export interface ISearchTransactionFormValues {
  stateUniqueId: string;
  transactionDateFrom: string | Date | number | null;
  transactionDateTo: string | Date | number | null;
}

export const initialTransactionSearchFormValues: ISearchTransactionFormValues = {
  stateUniqueId: '',
  transactionDateFrom: '',
  transactionDateTo: '',
};

export const initialCalibreFormValues: IFRTCalibreFormValues = {
  caliber: '',
  barrelLength: '',
  shots: '',
  level: '',
  legalClassification: '',
  barrelType: '',
};

interface IProductCodeObject {
  productCode: string;
}

export interface IFRTNotesFormValues {
  ACT: string;
  AKA: string;
  BAR: string;
  CAL: string;
  IMG: string;
  IMP: string;
  MAK: string;
  MAN: string;
  MDT: string;
  MOD: string;
  MRK: string;
  REM: string;
  SER: string;
  SHT: string;
  TYP: string;
}

export interface IFRTFormValues extends IFRTNotesFormValues {
  make: string;
  model: string;
  manufacturer: string;
  country: string;
  legalClassification: string;
  level: string;
  type: string;
  action: string;
  productCodes: IProductCodeObject[];
}

export const initialFRTNotesFormValues: IFRTNotesFormValues = {
  MAK: '',
  MOD: '',
  MAN: '',
  ACT: '',
  CAL: '',
  SHT: '',
  SER: '',
  AKA: '',
  MRK: '',
  REM: '',
  BAR: '',
  IMP: '',
  TYP: '',
  IMG: '',
  MDT: '',
};

export const initialFRTFormValues: IFRTFormValues = {
  make: '',
  model: '',
  manufacturer: '',
  country: '',
  legalClassification: '',
  level: '',
  type: '',
  action: '',
  productCodes: [],
  ...initialFRTNotesFormValues,
};

export interface IFRTLocalNoteObject {
  type: string;
  description: string;
}

export interface IFRTLocalPatchRequest {
  level: string;
  legalClassification: string;
  notes: IFRTLocalNoteObject[];
}

export interface IFRTLocalUpdateRequest extends IFRTLocalPatchRequest {
  make: string;
  model: string;
  manufacturer: string;
  action: string;
  type: string;
  country: string;
  productCodes: string[];
}

export const initialAttachmentFormValues = {
  transactionTypeUniqueId: '',
  attachmentName: '',
};

export interface IAttachment {
  attachmentName: string;
  file: File;
}

export interface IAttachmentFormValues {
  transactionUniqueId: string;
  attachmentName: string;
}

export interface IAttachmentForm {
  onCancel?: () => void;
  onSubmit: (arg: IAttachmentFormValues) => void;
  initialFormValues?: IAttachmentFormValues | null;
  usedTransactions: IShortTransaction[];
  transactionType?: IDictionary[] | null;
}

export interface ICommentInTransactionBody {
  title: string;
  comment: string;
}

export const initialCommentInTransactionFormValues = {
  title: '',
  comment: '',
};
