import React from 'react';
import { ITableColumns, ITableDataSource } from 'models/table';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import { ISingleFirearm, ISingleFRT } from 'models/firearm';
import { Chips } from 'components/atoms/Chips';
import {
  IRegisteredFirearmAttachment,
  IRegisteredFirearmAttachmentProps,
  IRegisteredFirearmTransaction,
} from 'models/registeredFirearm';
import { downloadAttachment } from 'requests/attachment';
import { isFRTLocal } from 'pages/FRT/helpers';
import { ECustomFieldType, ICustomFieldByObjectType } from 'models/customField';
import { ILegalEntityCustomField } from 'components/organisms/LegalEntityDetails';
import { getColumnTitleFromCustomField } from 'utils/table';
import { formatDate } from 'utils/date';
import { IDictionary } from 'models/dictionary';
import { parseDictionary } from 'helpers/dictionary';
import { EPermission } from 'models/permissions';
import { Link } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import { EFileType } from 'constants/FileType';

export const mapRegisteredFirearmsToDataSource = (
  firearms: ISingleFirearm[],
): ITableDataSource[] =>
  firearms.map((firearm) => ({
    id: firearm.uniqueId,
    serialNumber: firearm.serialNumber,
    fireCycleId: firearm.fireCycleId,
    exhibitNumber: firearm.exhibitNumber,
    make: firearm.make,
    model: firearm.model,
    manufacturer: firearm.manufacturer,
    caliber: firearm.caliber,
    owner: firearm.owner,
    keeper: firearm.keeper,
    state: firearm.state,
    stateUniqueId: firearm.stateUniqueId,
    legality: firearm.legality,
    legalityUniqueId: firearm.legalityUniqueId,
    activityUniqueId: firearm.activityUniqueId,
    customFields: firearm.customFields || [],
  }));

interface IRegisteredFirearmsTableColumns {
  handleEdit: (item: ITableDataSource) => void;
  renderStateFieldColor: (arg: ITableDataSource) => any;
  hasPermission: boolean | null;
  customFields?: ICustomFieldByObjectType[];
  t?: (key: string) => string;
}

interface IRegisteredFirearmTransactionsTableColumns {
  discardableFirearmTransactionId?: string | null;
  handleView: ((arg: string) => void) | null;
  handleDelete: ((arg: string) => void) | null;
  showDetails: (props: any) => boolean;
  longDateFormat: string;
}

interface IRegisteredFirearmAttachmentsTableColumns {
  firearmId: string;
  handleDelete: (arg: IRegisteredFirearmAttachmentProps) => void;
  handleShowTransaction: (arg: string) => void;
  handleShowAttachment: (firearmId: string, attachmentId: string) => void;
  longDateFormat: string;
}

export const registeredFirearmsTableColumns = (
  { handleEdit, renderStateFieldColor, customFields = [], t }: IRegisteredFirearmsTableColumns,
  selectedLanguage: string | undefined,
  dateFormat: string,
): ITableColumns[] => {
  const customFieldColumns: ITableColumns[] = customFields
    .filter((customField) => customField.visibleInSearchResults)
    .map((customField) => ({
      id: customField.uniqueId,
      key: `customField.${customField.name}`,
      title: getColumnTitleFromCustomField(customField, selectedLanguage),
      isSortable: false,
      render: (data, props) => {
        if (props?.customFields?.length) {
          const cf = props.customFields.find(
            (cField: ILegalEntityCustomField) => cField.customFieldName === customField.name,
          );
          if (cf) {
            if (cf.customFieldType === ECustomFieldType.BOOL && t) {
              if (cf.value === null) {
                return '-';
              }
              return cf.value
                ? t('customFields.value.yes.label')
                : t('customFields.value.no.label');
            }
            if (cf.customFieldType === ECustomFieldType.DATE) {
              return formatDate(cf.value, dateFormat);
            }
            return cf.value || '-';
          }
        }
        return '-';
      },
    }));

  return [
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f610',
      key: 'fireCycleId',
      title: 'FireCycle ID',
    },
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f510',
      key: 'serialNumber',
      title: 'registeredFirearms.accordion.serialNumber.column',
    },
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f699',
      key: 'exhibitNumber',
      title: 'accessoriesTable.accordion.exhibitNumber.column',
    },
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f511',
      key: 'make',
      title: 'registeredFirearms.accordion.make.column',
    },
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f512',
      key: 'model',
      title: 'registeredFirearms.accordion.model.column',
    },
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f513',
      key: 'manufacturer',
      title: 'registeredFirearms.accordion.manufacturer.column',
    },
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f514',
      key: 'caliber',
      title: 'registeredFirearms.accordion.calibre.column',
    },
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f516',
      key: 'owner',
      title: 'registeredFirearms.accordion.owner.column',
    },
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f515',
      key: 'keeper',
      title: 'registeredFirearms.accordion.keeper.column',
    },
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f517',
      key: 'state',
      isCenterTableHead: true,
      title: 'registeredFirearms.accordion.state.column',
      align: 'center',
      render: (_data, props) => (
        <Chips text={props.state} variant={renderStateFieldColor(props)} />
      ),
    },
    ...customFieldColumns,
    {
      id: 'fe7a7688-1583-4233-8319-dd5c8973cc44',
      key: '',
      title: 'registeredFirearms.accordion.actions.column',
      isSortable: false,
      width: '80px',
      align: 'center',
      isCenterTableHead: true,
      render: (_data, props) => (
        <>
          <Link to={`${ERouteLinks.ViewRegisteredFirearm.replace(':id', props.id)}`}>
            <Button
              variant={EButtonVariants.outlined}
              icon={EIconTypes.view}
              sx={{ marginRight: '2px' }}
            />
          </Link>

          <Button
            variant={EButtonVariants.outlined}
            icon={EIconTypes.edit}
            onClick={() => handleEdit(props)}
            permission={[EPermission.MODIFY_FIREARM]}
          />
        </>
      ),
    },
  ];
};

export enum ESearchFRTContextType {
  LegalEntities = 'LegalEntities',
  Firearms = 'Firearms',
}

const presetKey = 'preset';

export const hasQuerySetPresetValues = (query: { [k: string]: any }) => {
  const keys = Object.keys(query);
  if (keys.includes(presetKey) && query[presetKey].length) {
    let result = false;
    keys.forEach((key) => {
      const value = query[key];
      if (
        key !== presetKey &&
        ((!Array.isArray(value) && value) || (Array.isArray(value) && value.length))
      ) {
        result = true;
      }
    });
    return result;
  }
  return true;
};

export const mapFRTToDataSource = (frt: ISingleFRT[]): ITableDataSource[] =>
  frt.map((firearm) => ({
    id: firearm.id,
    make: firearm.make,
    model: firearm.model,
    manufacturer: firearm.manufacturer,
    action: firearm.action,
    type: firearm.type,
    country: firearm.country,
    frtId: firearm.frtId,
  }));

interface IFRTTableColumns {
  handleEdit?: (arg: string) => void;
  handleSelect?: (arg: ISingleFRT) => void;
  hasPermissionManageFRT?: boolean | null;
}

export const FRTTableColumns = (
  { handleEdit, handleSelect, hasPermissionManageFRT }: IFRTTableColumns,
  context: ESearchFRTContextType,
): ITableColumns[] => [
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f511',
    key: 'make',
    title: 'firearmsReferenceTable.accordion.make.column',
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f512',
    key: 'model',
    title: 'firearmsReferenceTable.accordion.model.column',
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f513',
    key: 'manufacturer',
    title: 'firearmsReferenceTable.accordion.manufacturer.column',
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f514',
    key: 'action',
    title: 'firearmsReferenceTable.accordion.firearmAction.column',
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f515',
    key: 'type',
    title: 'firearmsReferenceTable.accordion.type.column',
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f516',
    key: 'country',
    title: 'firearmsReferenceTable.accordion.country.column',
  },

  {
    id: 'fe7a7688-1583-4233-8319-dd5c8973cc44',
    key: '',
    title: 'firearmsReferenceTable.accordion.action.column',
    isSortable: false,
    width: '80px',
    align: 'center',
    isCenterTableHead: true,
    render: (_data, props) => (
      <div>
        {context === ESearchFRTContextType.Firearms ? (
          <>
            <Link to={ERouteLinks.FRTDetails.replace(':frtId', props.id)}>
              <Button
                variant={EButtonVariants.outlined}
                icon={EIconTypes.view}
                sx={{ marginRight: '2px' }}
              />
            </Link>

            {isFRTLocal(props.id) && hasPermissionManageFRT && (
              <Button
                variant={EButtonVariants.outlined}
                icon={EIconTypes.edit}
                onClick={() => handleEdit && handleEdit(props.id)}
              />
            )}
          </>
        ) : (
          <Button
            variant={EButtonVariants.outlined}
            icon={EIconTypes.checkmark}
            onClick={() => handleSelect && handleSelect(props)}
          />
        )}
      </div>
    ),
  },
];

export const firearmTransactionsTableColumns = ({
  discardableFirearmTransactionId,
  handleView,
  handleDelete,
  showDetails,
  longDateFormat,
}: IRegisteredFirearmTransactionsTableColumns): ITableColumns[] => [
  {
    id: 'a0504ca5-6b32-482d-b369-304a6f32f512',
    key: 'transactionType',
    title: 'registeredFirearms.firearmsDetails.transactions.accordion.transactionType.column',
  },
  {
    id: 'a0504ca5-6b32-482d-b369-304a6f32f510',
    key: 'date',
    title:
      'registeredFirearms.firearmsDetails.transactions.accordion.transactionDateAndTime.column',
    dateFormat: longDateFormat,
  },
  {
    id: 'a0504ca5-6b32-482d-b369-304a6f32f511',
    key: 'createdDate',
    title: 'registeredFirearms.firearmsDetails.transactions.accordion.createdDate.column',
    dateFormat: longDateFormat,
  },
  {
    id: 'a0504ca5-6b32-482d-b369-304a6f32f513',
    key: 'owner',
    title: 'registeredFirearms.firearmsDetails.transactions.accordion.owner.column',
  },
  {
    id: 'a0504ca5-6b32-482d-b369-304a6f32f514',
    key: 'keeper',
    title: 'registeredFirearms.firearmsDetails.transactions.accordion.keeper.column',
  },
  {
    id: 'ae7a7688-1583-4233-8319-dd5c8973cc44',
    key: '',
    title: 'registeredFirearms.firearmsDetails.transactions.accordion.action.column',
    isSortable: false,
    align: 'center',
    width: '80px',
    isCenterTableHead: true,
    render: (_data, props) => (
      <>
        {showDetails(props) && handleView && (
          <Link to={ERouteLinks.ViewTransaction.replace(':id', props.id)}>
            <Button
              variant={EButtonVariants.outlined}
              icon={EIconTypes.view}
              sx={{ marginRight: '2px' }}
            />
          </Link>
        )}

        {props.id === discardableFirearmTransactionId && handleDelete && (
          <Button
            variant={EButtonVariants.outlined}
            icon={EIconTypes.delete}
            onClick={() => handleDelete(props.id)}
          />
        )}
      </>
    ),
  },
];

export const mapFirearmTransactionToDataSource = (
  items: IRegisteredFirearmTransaction[],
): ITableDataSource[] =>
  items.map((item) => ({
    id: item.uniqueId,
    date: item.date,
    createdDate: item.createdDate,
    transactionType: item.transactionType,
    keeper: item.keeper,
    owner: item.owner,
    keeperId: item.keeperId,
    ownerId: item.ownerId,
    detailsVisible: item.detailsVisible,
  }));

export const firearmAttachmentsTableColumns = ({
  firearmId,
  handleDelete,
  handleShowTransaction,
  handleShowAttachment,
  longDateFormat,
}: IRegisteredFirearmAttachmentsTableColumns): ITableColumns[] => [
  {
    id: 'a0504ca5-6b33-482d-b369-304a6f32f510',
    key: 'createdDate',
    title: 'registeredFirearms.firearmsDetails.attachments.accordion.dateAndTime.column',
    dateFormat: longDateFormat,
  },
  {
    id: 'a0504ca5-6b33-482d-b369-304a6f32f511',
    key: 'name',
    title: 'registeredFirearms.firearmsDetails.attachments.accordion.attachmentName.column',
  },
  {
    id: 'a0504ca5-6b33-482d-b369-304a6f32f512',
    key: 'size',
    title: 'registeredFirearms.firearmsDetails.attachments.accordion.attachmentSize.column',
  },
  {
    id: 'a0504ca5-6b33-482d-b369-304a6f32f513',
    key: 'transactionType',
    title: 'registeredFirearms.firearmsDetails.attachments.accordion.transactionType.column',
    isSortable: false,
  },
  {
    id: 'a0504ca5-6b33-482d-b369-304a6f32f515',
    key: '',
    title: 'registeredFirearms.firearmsDetails.attachments.accordion.action.column',
    isSortable: false,
    align: 'center',
    width: '80px',
    isCenterTableHead: true,
    render: (_data, props) => (
      <>
        <Button
          variant={EButtonVariants.outlined}
          icon={EIconTypes.download}
          onClick={() => downloadAttachment(firearmId, props.id, props.name, props.type)}
          sx={{ marginRight: '2px' }}
        />
        {props.transactionId && (
          <Button
            variant={EButtonVariants.outlined}
            icon={EIconTypes.view}
            onClick={() => handleShowTransaction(props.id)}
            sx={{ marginRight: '2px' }}
          />
        )}
        {!props.transactionId && props.type.toLowerCase() !== EFileType.XLSX && (
          <Button
            variant={EButtonVariants.outlined}
            icon={props.isReport ? EIconTypes.view : EIconTypes.delete}
            onClick={() =>
              props.isReport ? handleShowAttachment(firearmId, props.id) : handleDelete(props)
            }
            sx={{ marginRight: '2px' }}
          />
        )}
      </>
    ),
  },
];

export const mapFirearmAttachmentToDataSource = (
  items: IRegisteredFirearmAttachment[],
  transactionType: IDictionary[] | null,
  dateFormat: string,
): ITableDataSource[] =>
  items.map((item) => ({
    id: item.uniqueId,
    name: item.name,
    type: item.type,
    createdDate: item.createdDate,
    transactionType: `${
      item.transactionTypeUniqueId
        ? parseDictionary(transactionType, 'name')[item.transactionTypeUniqueId]
        : ''
    } ${formatDate(item.transactionTime, dateFormat)}`,
    transactionId: item.transactionId,
    size: Math.trunc(Number(item.size) / 1024),
    isReport: item.report,
  }));

export const isEmptyObject = (object?: { [key: string]: any }) => {
  if (object) {
    const objectRef = object;
    const reducedObject = Object.keys(objectRef)
      .filter((k) => objectRef[k] != null && objectRef[k].length)
      .reduce((a, k) => ({ ...a, [k]: objectRef[k] }), {});
    return !Object.keys(reducedObject).length;
  }
  return true;
};
