import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import { Accordion } from 'components/molecules/Accordion';
import { useTranslations } from 'hooks/useTranslations';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { useAncillaries } from 'pages/AncillariesPage/hooks';
import { EDictionaryTypes } from 'models/dictionary';
import { useLang } from 'models/langContext';
import { ERouteLinks } from 'models/route';
import { useAlert } from 'models/alertContext';
import { Alert, EAlertVariants, IAlert } from 'components/atoms/Alert';
import { DetailsTemplate, EDetailsPageTypes } from 'components/templates/DetailsTemplate';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import Button from 'components/atoms/Button';
import { Chips } from 'components/atoms/Chips';
import { getFieldsByAccessoriesType } from 'pages/AncillariesPage/helpers';
import {
  EAncillaryState,
  EArtAncillaryTypeName,
  EArtAncillaryTypeNameLowerCase,
} from 'constants/ArtAccessoryType';
import { EPermission } from 'models/permissions';
import { usePermission } from 'hooks/usePermission';
import { ECustomFieldObjectType, ECustomFieldType } from 'models/customField';
import { clearAlertTimeout, EAlertTime, formatDate } from 'utils/date';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { IAncillaryDetailsCustomfield } from 'models/ancillaries';
import { emptyFieldHolder } from 'components/molecules/CardList';
import Link from '@mui/material/Link';
import { ActionTypes } from 'state/actions/alert';
import { EFirearmStateId } from 'models/firearm';
import { renderDataColorByValues } from 'components/molecules/Table/helpers';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import { StyledLegalLink } from 'components/atoms/StyledLegalLink';
import { getLegalEntityType } from 'components/organisms/RegisteredFirearmDetails';
import { AccessoryInformationContainer, AccessoryInformationTitle } from './styles';
import { AccessoriesTransactions } from './AccessoriesTransactions';
import { AccessoriesComments } from './AccessoriesComments';
import { AccessoriesAttachments } from './AccessoriesAttachments';

export enum EAccessoryLabels {
  accessoryGeneralInformation = 'accessoryDetails.accessoryGeneralInformation.header',
  accessoryDetailedInformation = 'accessoryDetails.accessoryDetailedInformation.header',
  accessoryType = 'accessoryDetails.accessoryType.label',
  ancillaryType = 'ancillaryDetails.ancillaryType.label',
  artId = 'accessoryDetails.artId.label',
  hostType = 'accessoryDetails.hostType.label',
  manufacturer = 'accessoryDetails.accessoryManufacturer.label',
  productName = 'accessoryDetails.productName.label',
  calibre = 'accessoryDetails.calibre.label',
  composition = 'accessoryDetails.composition.label',
  proofHouse = 'accessoryDetails.proofMark.label',
  firecycleID = 'accessoryDetails.firecycleID.label',
  serialNumber = 'accessoryDetails.serialNumber.label',
  externalRefNo = 'accessoryDetails.externalRefNo.label',
  componentState = 'accessoryDetails.componentState.label',
  exhibitNumber = 'accessoryDetails.exhibitNumber.label',
  currentOwner = 'accessoryDetails.currentOwner.label',
  currentKeeper = 'accessoryDetails.currentKeeper.label',
  magnification = 'accessoryDetails.magnification.label',
  utility = 'accessoryDetails.utility.label',
  reticle = 'accessoryDetails.reticle.label',
  lightcolour = 'accessoryDetails.lightcolour.label',
  capacity = 'accessoryDetails.capacity.label',
  externalReferenceNumber = 'accessoryDetails.externalReferenceNumber.label',
  manufacturedDate = 'accessoryDetails.manufacturedDate.label',
}

const DetailAccessories = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslations();
  const { selectedLanguage, defaultLanguage } = useLang();
  const navigate = useNavigate();
  const { nextPageAlert, clearAlert, transactionAlert, ancillaryAlert, setAlert } = useAlert();
  const { loadDictionaries, accessoryDetail, getAccessoryDetail } = useAncillaries();
  const [accessFields, setAccessFields] = useState<string[]>([]);
  const [isAllExpanded, setIsAllExpanded] = useState<boolean>(false);
  const { hasPermissions } = usePermission();
  const { shortDateFormat } = useGlobalProperty();
  const { getCustomField, customFieldsAncillaries } = useLegalEntities();

  useEffect(() => {
    if (accessoryDetail) {
      if (accessoryDetail.typeUniqueId) {
        const fields = getFieldsByAccessoriesType(accessoryDetail.typeUniqueId);
        setAccessFields(fields);
      } else {
        setAccessFields([]);
      }
    }
  }, [accessoryDetail]);

  useEffect(() => {
    (async () => {
      if (id) {
        await getAccessoryDetail(id);
      }
    })();
  }, [id]);

  useEffect(
    () => {
      clearAlertTimeout(clearAlert, ActionTypes.CLEAR_SAME_PAGE_ALERT, EAlertTime.SHORT);
      clearAlertTimeout(clearAlert, ActionTypes.CLEAR_NEXT_PAGE_ALERT, EAlertTime.SHORT);
      clearAlertTimeout(clearAlert, ActionTypes.CLEAR_TRANSACTION_ALERT, EAlertTime.SHORT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    (async () => {
      await getCustomField(ECustomFieldObjectType.AN_ACCESSORY);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_ANCILLARY_ALERT);
    },
    [],
  );

  useEffect(() => {
    (async function initDictionaries() {
      await loadDictionaries([EDictionaryTypes.ArtAncillaryOptions]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  const showField = (fieldName: string) => accessFields.includes(fieldName);

  const renderCustomFieldData = (customField: IAncillaryDetailsCustomfield) => {
    if (customField.customFieldType === ECustomFieldType.BOOL) {
      if (customField.value === null) {
        return '-';
      }
      return customField.value
        ? t('customFields.value.yes.label')
        : t('customFields.value.no.label');
    }
    if (customField.customFieldType === ECustomFieldType.DATE) {
      return formatDate(customField.value, shortDateFormat);
    }
    return customField.value || '-';
  };

  const navigateToMap = () => {
    navigate(ERouteLinks.RegisteredAncillariesMap, {
      state: {
        ancillaryUniqueId: accessoryDetail?.uniqueId,
        ancillaryType: EArtAncillaryTypeName.ACCESSORY.toLowerCase(),
        legalityUniqueId: accessoryDetail?.legalityUniqueId,
        navigateBack: `${ERouteLinks.ViewAccessories.replace(':id', id as string)}`,
        showAdvanced: true,
        showLegalityBar: false,
        breadcrumbs: [
          {
            label: t('module.ancillaries.name'),
            route: ERouteLinks.Ancillaries,
          },
          {
            label: t('module.accessories.name'),
            route: `${ERouteLinks.Ancillaries}?expand=accessories`,
          },
          {
            label: t('accessoryDetails.accordion.title.label'),
            route: `${ERouteLinks.ViewAccessories.replace(':id', id as string)}`,
          },
          t('ancillaries.ancillariesLocation.showOnMap.label'),
        ],
      },
    });
  };

  const handleRedirectToEditPageButton = (): (() => void) => {
    if (
      customFieldsAncillaries?.length &&
      accessoryDetail?.stateUniqueId !== EFirearmStateId.DISABLED
    ) {
      return () =>
        navigate(
          ERouteLinks.EditAccessories.replace(':id', accessoryDetail?.uniqueId as string),
        );
    }
    if (accessoryDetail?.stateUniqueId === EFirearmStateId.DISABLED) {
      return () =>
        setAlert(ActionTypes.SET_ANCILLARY_ALERT, {
          text: t('ancillary.disabled.edit.info'),
          variant: EAlertVariants.error,
        } as IAlert);
    }
    return () =>
      setAlert(ActionTypes.SET_ANCILLARY_ALERT, {
        text: t('general.noAvaibleDataToEdit.label'),
        variant: EAlertVariants.warning,
      } as IAlert);
  };

  return (
    <div>
      <Breadcrumbs
        items={[
          {
            label: t('module.ancillaries.name'),
            route: ERouteLinks.Ancillaries,
          },
          {
            label: t('module.accessories.name'),
            route: `${ERouteLinks.Ancillaries}?expand=accessories`,
          },
          t('accessoryDetails.accordion.title.label'),
        ]}
      />
      {accessoryDetail && (
        <Grid container item gap={3}>
          <DetailsTemplate
            title={accessoryDetail?.type}
            pageType={EDetailsPageTypes.view}
            redirectToShowOnMap={
              ![
                EAncillaryState.DESTROYED,
                EAncillaryState.DEPLETED,
                EAncillaryState.DISABLED,
              ].includes(accessoryDetail.stateUniqueId as EAncillaryState) &&
              accessoryDetail?.latitude
                ? navigateToMap
                : undefined
            }
            showOnMapButtonId="accessory-show-on-map-button"
            editPermissions={[EPermission.EDIT_ANCILLARY]}
            redirectToEditView={handleRedirectToEditPageButton()}
            redirectToDisableView={
              accessoryDetail.stateUniqueId === EAncillaryState.DISABLED ||
              !hasPermissions([EPermission.DISABLE_ANCILLARY])
                ? undefined
                : () =>
                    navigate(
                      `${ERouteLinks.DisableAncillary.replace(
                        ':id',
                        accessoryDetail.uniqueId,
                      ).replace(':type', EArtAncillaryTypeName.ACCESSORY.toLowerCase())}`,
                    )
            }
          >
            <AccessoryInformationContainer item xs={12} lg={12} container>
              <Grid item xs={12} lg={12} container direction="column">
                <AccessoryInformationTitle variant="subtitle1">
                  {t(EAccessoryLabels.accessoryGeneralInformation)}
                </AccessoryInformationTitle>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EAccessoryLabels.accessoryType)}
                </Typography>
                <Typography variant="body2">{accessoryDetail?.type}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography
                  variant="subtitle2"
                  sx={{ marginBottom: '4px', textTransform: 'uppercase' }}
                >
                  {t(EAccessoryLabels.artId)}
                </Typography>
                <Typography variant="body2" style={{ textTransform: 'capitalize' }}>
                  {accessoryDetail?.artId}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12} lg={6} container direction="column">
                {accessoryDetail.artUniqueId && (
                  <Link
                    href={ERouteLinks.ViewAncillaryDetails.replace(
                      ':id',
                      accessoryDetail.artUniqueId,
                    ).replace(':type', EArtAncillaryTypeNameLowerCase.ACCESSORY)}
                    underline="none"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button
                      variant={EButtonVariants.contained}
                      size={EButtonSizes.small}
                      label={t('accessoryDetails.showArtInformation.button')}
                      fullWidth
                    />
                  </Link>
                )}
              </Grid>
              {showField('manufacturer') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAccessoryLabels.manufacturer)}
                  </Typography>
                  <Typography variant="body2">
                    {emptyFieldHolder(accessoryDetail?.manufacturer)}
                  </Typography>
                </Grid>
              )}
              {showField('productName') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAccessoryLabels.productName)}
                  </Typography>
                  <Typography variant="body2">
                    {emptyFieldHolder(accessoryDetail?.productName)}
                  </Typography>
                </Grid>
              )}
              {showField('calibre') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAccessoryLabels.calibre)}
                  </Typography>
                  <Typography variant="body2">
                    {emptyFieldHolder(accessoryDetail?.calibre)}
                  </Typography>
                </Grid>
              )}
              {showField('hostType') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAccessoryLabels.hostType)}
                  </Typography>
                  <Typography variant="body2">
                    {emptyFieldHolder(accessoryDetail?.hostType)}
                  </Typography>
                </Grid>
              )}
              {showField('proofHouse') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAccessoryLabels.proofHouse)}
                  </Typography>
                  <Typography variant="body2">
                    {emptyFieldHolder(accessoryDetail?.proofHouse)}
                  </Typography>
                </Grid>
              )}
              {showField('composition') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAccessoryLabels.composition)}
                  </Typography>
                  <Typography variant="body2">
                    {emptyFieldHolder(accessoryDetail?.composition)}
                  </Typography>
                </Grid>
              )}
              {showField('magnification') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAccessoryLabels.magnification)}
                  </Typography>
                  <Typography variant="body2">
                    {emptyFieldHolder(accessoryDetail?.magnification)}
                  </Typography>
                </Grid>
              )}
              {showField('utility') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAccessoryLabels.utility)}
                  </Typography>
                  <Typography variant="body2">
                    {emptyFieldHolder(accessoryDetail?.utility)}
                  </Typography>
                </Grid>
              )}
              {showField('reticle') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAccessoryLabels.reticle)}
                  </Typography>
                  <Typography variant="body2">
                    {emptyFieldHolder(accessoryDetail?.reticle)}
                  </Typography>
                </Grid>
              )}
              {showField('lightcolour') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAccessoryLabels.lightcolour)}
                  </Typography>
                  <Typography variant="body2">
                    {emptyFieldHolder(accessoryDetail?.lightcolour)}
                  </Typography>
                </Grid>
              )}
              {showField('capacity') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAccessoryLabels.capacity)}
                  </Typography>
                  <Typography variant="body2">
                    {emptyFieldHolder(accessoryDetail?.capacity)}
                  </Typography>
                </Grid>
              )}
            </AccessoryInformationContainer>
            <AccessoryInformationContainer item xs={12} lg={12} container borderHidden>
              <Grid item xs={12} lg={12} container direction="column">
                <AccessoryInformationTitle variant="subtitle1">
                  {t(EAccessoryLabels.accessoryDetailedInformation)}
                </AccessoryInformationTitle>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EAccessoryLabels.firecycleID)}
                </Typography>
                <Typography variant="body2">
                  {emptyFieldHolder(accessoryDetail?.fireCycleId)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EAccessoryLabels.serialNumber)}
                </Typography>
                <Typography variant="body2">
                  {emptyFieldHolder(accessoryDetail?.serialNumber)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EAccessoryLabels.componentState)}
                </Typography>
                <Typography variant="body2">
                  <Chips
                    text={emptyFieldHolder(accessoryDetail?.state)}
                    variant={
                      renderDataColorByValues(
                        accessoryDetail.activityUniqueId,
                        accessoryDetail.legalityUniqueId,
                        accessoryDetail.stateUniqueId,
                      ).stateField
                    }
                  />
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EAccessoryLabels.exhibitNumber)}
                </Typography>
                <Typography variant="body2">
                  {emptyFieldHolder(accessoryDetail?.exhibitNumber)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EAccessoryLabels.currentOwner)}
                </Typography>
                <Typography variant="body2">
                  <StyledLegalLink
                    legalId={accessoryDetail.ownerId}
                    legalType={getLegalEntityType(accessoryDetail.ownerType)}
                    labelText={accessoryDetail.ownerName}
                    legalVisibilityUniqueId={accessoryDetail.ownerVisibilityUniqueId}
                  />
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EAccessoryLabels.currentKeeper)}
                </Typography>

                <Typography variant="body2">
                  <StyledLegalLink
                    legalId={accessoryDetail.keeperId}
                    legalType={getLegalEntityType(accessoryDetail.keeperType)}
                    labelText={accessoryDetail.keeperName}
                    legalVisibilityUniqueId={accessoryDetail.keeperVisibilityUniqueId}
                  />
                </Typography>
              </Grid>
              {accessoryDetail?.customFields?.map((customField) => (
                <Grid item xs={6} lg={3} key={customField.customFieldUniqueId}>
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {customField?.translations?.filter(
                      (translation) =>
                        selectedLanguage?.uniqueId === translation.languageUniqueId,
                    )?.[0]?.name ||
                      customField?.translations?.filter(
                        (translation) =>
                          defaultLanguage?.uniqueId === translation.languageUniqueId,
                      )?.[0]?.name}
                  </Typography>
                  <Typography variant="body2">{renderCustomFieldData(customField)}</Typography>
                </Grid>
              ))}
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EAccessoryLabels.externalReferenceNumber)}
                </Typography>
                <Typography variant="body2">
                  {emptyFieldHolder(accessoryDetail?.externalReferenceNumber)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EAccessoryLabels.manufacturedDate)}
                </Typography>
                <Typography variant="body2">
                  {formatDate(accessoryDetail?.productionDate, shortDateFormat)}
                </Typography>
              </Grid>
            </AccessoryInformationContainer>
          </DetailsTemplate>
          <Grid item xs={12} pb={2} container direction="column">
            {nextPageAlert && (
              <Box sx={{ marginBottom: '16px' }}>
                <Alert text={nextPageAlert.text} variant={nextPageAlert.variant} />
              </Box>
            )}
            {ancillaryAlert && (
              <Box sx={{ marginBottom: '16px' }}>
                <Alert text={ancillaryAlert.text} variant={ancillaryAlert.variant} />
              </Box>
            )}
            {transactionAlert && (
              <Box sx={{ marginBottom: '16px' }}>
                <Alert text={transactionAlert.text} variant={transactionAlert.variant} />
              </Box>
            )}
            {!hasPermissions([EPermission.VIEW_LIMITED_ANCILLARIES]) && (
              <Button
                label={
                  isAllExpanded
                    ? t('accessoryDetails.collapseAll.button')
                    : t('accessoryDetails.expandAll.button')
                }
                onClick={() => setIsAllExpanded((prev) => !prev)}
                variant={EButtonVariants.outlined}
                sx={{ width: '150px', alignSelf: 'flex-end' }}
              />
            )}
          </Grid>
        </Grid>
      )}
      {hasPermissions(
        [EPermission.VIEW_LIMITED_ANCILLARIES, EPermission.VIEW_ALL_ANCILLARIES],
        true,
      ) && (
        <Accordion
          title={t('accessoryDetails.transactions.header')}
          name="transactions"
          key="transactions"
          expanded={isAllExpanded}
        >
          <AccessoriesTransactions
            inGroup={
              !!accessoryDetail?.groupName &&
              ![EFirearmStateId.EXPORTED].includes(
                accessoryDetail?.stateUniqueId as EFirearmStateId,
              )
            }
            ownerId={accessoryDetail?.ownerId}
            ownerVisibilityUniqueId={accessoryDetail?.ownerVisibilityUniqueId}
            keeperId={accessoryDetail?.keeperId}
            keeperVisibilityUniqueId={accessoryDetail?.keeperVisibilityUniqueId}
            stateUniqueId={accessoryDetail?.stateUniqueId}
          />
        </Accordion>
      )}
      {hasPermissions([EPermission.MANAGE_COMMENTS_ANCILLARY]) && (
        <Accordion
          title={t('accessoryDetails.comments.header')}
          name="comments"
          key="comments"
          expanded={isAllExpanded}
        >
          <AccessoriesComments id={id} />
        </Accordion>
      )}
      {hasPermissions([EPermission.MANAGE_ATTACHMENT_ANCILLARY]) && (
        <Accordion
          title={t('accessoryDetails.attachments.header')}
          name="attachments"
          key="attachments"
          expanded={isAllExpanded}
        >
          <AccessoriesAttachments idAncillary={id} />
        </Accordion>
      )}
    </div>
  );
};

export default DetailAccessories;
