import {
  ECustomFieldObjectType,
  ECustomFieldStatus,
  ICustomField,
  ICustomFieldFormValues,
  ICustomFieldUpdateFormValues,
} from 'models/customField';
import {
  changeCustomFieldStatus,
  createCustomField,
  deleteCustomField,
  getAllCustomFields,
  getCustomField,
  getCustomFieldProperties,
  updateCustomField,
} from 'requests/customField';
import { useState } from 'react';
import { ActionTypes } from 'state/actions/alert';
import { useAlert } from 'models/alertContext';
import { EAlertVariants } from 'components/atoms/Alert';
import { useNavigate } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import { useTranslations } from 'hooks/useTranslations';

export const useCustomFields = () => {
  const [customFields, setCustomFields] = useState<
    | {
        [key in ECustomFieldObjectType]?: ICustomField[];
      }
    | null
  >(null);
  const { setAlert } = useAlert();
  const { t } = useTranslations();
  const navigate = useNavigate();

  const handleCreateCustomField = async (data: ICustomFieldFormValues) => {
    const { enabledString, ...formData } = data;
    formData.enabled = enabledString === ECustomFieldStatus.ENABLED;

    await createCustomField(formData);
  };

  const handleUpdateCustomField = async (id: string, data: ICustomFieldUpdateFormValues) => {
    const { enabledString, ...formData } = data;

    if (enabledString !== undefined) {
      formData.enabled = enabledString === ECustomFieldStatus.ENABLED;
    }

    await updateCustomField(id, formData);
  };

  const handleGetCustomFields = async () => {
    const customFieldsData = await getAllCustomFields();
    if (customFieldsData) {
      const temp: {
        [key in ECustomFieldObjectType]: ICustomField[];
      } = {
        [ECustomFieldObjectType.LE_INDIVIDUAL]: [],
        [ECustomFieldObjectType.LE_ORGANIZATION]: [],
        [ECustomFieldObjectType.FIREARM]: [],
        [ECustomFieldObjectType.TRANSACTION]: [],
        [ECustomFieldObjectType.AN_ACCESSORY]: [],
        [ECustomFieldObjectType.AN_AMMUNITION]: [],
        [ECustomFieldObjectType.AN_COMPONENT]: [],
      };
      customFieldsData.map((customField) => {
        if (customField?.objectType) {
          temp[customField.objectType as ECustomFieldObjectType].push(customField);
        }
        return true;
      });

      Object.keys(temp).map((key: string) => {
        temp[key as ECustomFieldObjectType] = temp[key as ECustomFieldObjectType].sort(
          (a: ICustomField, b: ICustomField) => {
            if (a.enabled < b.enabled) {
              return 1;
            }
            if (a.enabled > b.enabled) {
              return -1;
            }
            return 0;
          },
        );
        return false;
      });

      setCustomFields(temp);
      return true;
    }
    setCustomFields(null);
    return false;
  };

  const handleGetCustomField = async (id: string) => getCustomField(id);

  const handleChangeStatus = async (id: string, toggleType: string = 'disable') => {
    if (id && toggleType) {
      try {
        await changeCustomFieldStatus(id, toggleType !== 'disable');
        setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
          text: t('customFields.changedStatus.alert'),
        });
        navigate(ERouteLinks.CustomFields);
        return true;
      } catch (e) {
        setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
          text: t('customFields.problem.alert'),
          variant: EAlertVariants.error,
        });
      }
    }
    return false;
  };

  const handleDelete = async (id: string) => {
    if (id) {
      try {
        await deleteCustomField(id);
        setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
          text: t('customFields.deleted.alert'),
        });
        navigate(ERouteLinks.CustomFields);
        return true;
      } catch (e) {
        setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
          text: t('customFields.deleteProblem.alert'),
          variant: EAlertVariants.error,
        });
      }
    }
    return false;
  };

  const handleGetCustomFieldProperties = async () => getCustomFieldProperties();

  return {
    handleCreateCustomField,
    handleUpdateCustomField,
    handleGetCustomFields,
    customFields,
    handleGetCustomField,
    handleChangeStatus,
    handleDelete,
    handleGetCustomFieldProperties,
  };
};
