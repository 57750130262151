import { EActionTypes } from 'state/actions/lang';
import { IReducerAction } from 'models/context';
import { ILanguage } from 'models/language';
import { IMessage } from 'models/message';

interface IState {
  languages: ILanguage[];
  translations: IMessage[];
  selectedLanguage: ILanguage | null;
  defaultLanguage: ILanguage | null;
}

export const initialState: IState = {
  languages: [],
  translations: [],
  selectedLanguage: null,
  defaultLanguage: null,
};

export const reducer = (state: IState, { type, payload }: IReducerAction) => {
  switch (type) {
    case EActionTypes.GET_LANGUAGES: {
      return {
        ...state,
        languages: payload,
        defaultLanguage: payload.filter((it: any) => it.defaultLanguage)?.[0],
      };
    }
    case EActionTypes.GET_TRANSLATIONS: {
      return {
        ...state,
        translations: payload,
      };
    }
    case EActionTypes.CHANGE_LANGUAGE: {
      return {
        ...state,
        selectedLanguage: payload,
      };
    }
    default:
      return state;
  }
};
