import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { ERouteLinks } from 'models/route';
import { Box, Typography } from '@mui/material';
import { StyledPageTitle } from 'theme/styles';
import Grid from '@mui/material/Grid';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { useForm } from 'react-hook-form';
import { initialResetPasswordFormValues } from 'models/form';
import { EFormInputTypes, FormInput } from 'components/molecules/FormInput';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAccount } from 'pages/AccountPage/hooks';
import { useTranslations } from 'hooks/useTranslations';
import FormErrorLabel from 'components/atoms/FormErrorLabel';
import { useChangePasswordSchema } from 'pages/AccountPage/ChangePasswordPage/schemaValidation';

interface IChangePasswordFormValues {
  newPassword: string;
  newPasswordRepeat: string;
  currentPassword: string;
}

const ChangePasswordPage = () => {
  const navigate = useNavigate();
  const { changeAccountPassword } = useAccount();
  const { t } = useTranslations();
  const [isSubmitLoading, setSubmitLoading] = useState(false);
  const schema = useChangePasswordSchema();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<IChangePasswordFormValues>({
    defaultValues: initialResetPasswordFormValues,
    resolver: yupResolver(schema),
  });

  const onSubmitForm = async (data: IChangePasswordFormValues) => {
    if (data.newPassword) {
      setSubmitLoading(true);
      try {
        const response = await changeAccountPassword(data.currentPassword, data.newPassword);
        if (response) {
          navigate(ERouteLinks.Account);
        }
      } catch (e: any) {
        if (e?.response?.data?.message) {
          setError('currentPassword', { type: 'custom', message: e.response.data.message });
        }
      } finally {
        setSubmitLoading(false);
      }
    }
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('userSettings.header'), route: ERouteLinks.Account },
          t('changePassword.header'),
        ]}
      />

      <Box sx={{ mb: 2 }}>
        <StyledPageTitle variant="h4">{t('changePassword.header')}</StyledPageTitle>
      </Box>

      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Grid container>
          <Grid item xs={12} md={9} lg={6}>
            <Box mb={1}>
              <FormInput
                name="currentPassword"
                label={`${t('changePassword.currentPassword.label')}*`}
                type={EFormInputTypes.password}
                control={control}
                errors={errors}
              />
              {errors && errors.currentPassword && errors.currentPassword.type === 'custom' && (
                <Box sx={{ marginTop: '-15px' }}>
                  <FormErrorLabel label={errors.currentPassword.message as string} />
                </Box>
              )}
            </Box>
            <Box mb={1}>
              <FormInput
                name="newPassword"
                label={`${t('changePassword.newPassword.label')}*`}
                type={EFormInputTypes.password}
                control={control}
                errors={errors}
              />
            </Box>
            <Box mb={1}>
              <FormInput
                name="newPasswordRepeat"
                label={`${t('changePassword.RetypeNewPassword.label')}*`}
                type={EFormInputTypes.password}
                control={control}
                errors={errors}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-start" sx={{ mt: 4 }}>
          <Grid item xs={12} md={9} lg={6} container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Button
                type="submit"
                fullWidth
                label={t('changePassword.setPassword.button')}
                variant={EButtonVariants.contained}
                isLoading={isSubmitLoading}
                sx={{ mr: 1 }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                label={t('changePassword.cancel.button')}
                variant={EButtonVariants.outlined}
                onClick={() => navigate(ERouteLinks.Account)}
              />
            </Grid>
          </Grid>
        </Grid>
      </form>

      <Grid xs={12} item mt={2}>
        <Typography variant="caption">{t('general.mandatoryField.text')}</Typography>
      </Grid>
    </>
  );
};

export { ChangePasswordPage };
