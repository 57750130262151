import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { Box } from '@mui/system';
import { StyledPageTitle } from 'theme/styles';
import { useTranslations } from 'hooks/useTranslations';
import Grid from '@mui/material/Grid';
import { FormSelect } from 'components/molecules/FormSelect';
import { FormDateTimePicker } from 'components/molecules/FormDateTimePicker';
import { useFieldArray, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAncillaries } from 'pages/AncillariesPage/hooks';
import { Accordion } from 'components/molecules/Accordion';
import { StyledDetailsWrapper } from 'pages/Firearms/RegisteredFirearmDeleteCommentPage/styles';
import { Typography } from '@mui/material';
import { colors } from 'theme/colors';
import {
  createAncillaryMiddleTransaction,
  getPossibleAncillariesTransactionType,
} from 'requests/transaction';
import { EDictionaryTypes, IDictionary } from 'models/dictionary';
import { mapDictionaryToOptionProp } from 'helpers/dictionary';
import { capitalizeFirstLetter } from 'utils/capitalize';
import { FormInput } from 'components/molecules/FormInput';
import {
  StyledIconWrapper,
  StyledLocationMarkConfirmationMarginBottom,
} from 'pages/LegalEntitiesPage/LegalEntityAddFirearmPage/styles';
import Icon from 'components/atoms/Icon/Icon';
import { EIconTypes } from 'constants/Icons';
import Button from 'components/atoms/Button';
import { ERouteLinks } from 'models/route';
import { EButtonSizes, EButtonSizesPX, EButtonVariants } from 'constants/Buttons';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { ETransactionTypes } from 'constants/TransactionTypes';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import { useLang } from 'models/langContext';
import { FormDatePicker } from 'components/molecules/FormDatePicker';
import { EMIMETypes } from 'constants/MIMETypes';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import FormErrorLabel from 'components/atoms/FormErrorLabel';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { formatDateTimeToIso } from 'utils/date';
import { ActionTypes } from 'state/actions/alert';
import { useAlert } from 'models/alertContext';
import {
  EArtAncillaryTypeName,
  EArtAncillaryTypeNameLowerCase,
} from 'constants/ArtAccessoryType';
import Link from '@mui/material/Link';
import { emptyFieldHolder } from 'components/molecules/CardList';
import { useScroll } from 'hooks/useScroll';
import { mapILegalEntityTypeToELegalEntityTypes } from 'models/legalEntity';
import { removeFileExtension } from 'utils/removeFileExtension';
import { StyledLegalLink } from 'components/atoms/StyledLegalLink';
import { FormWyswigEditor } from 'components/molecules/FormWyswigEditor';

const MAX_FILE_SIZE_IN_MB = 3;

let DISABLED_FOR_TRANSACTION_FORM: ETransactionTypes[] = [];

const CHANGE_LE_REQUIRED_TRANSACTION_TYPES = [
  ETransactionTypes.DECLARED_IMPORTED,
  ETransactionTypes.RECOVERED,
  ETransactionTypes.SEIZED,
  ETransactionTypes.SOLD,
  ETransactionTypes.GIVEN,
  ETransactionTypes.LEASED,
  ETransactionTypes.ASSIGNED_TEMPORARILY,
  ETransactionTypes.ASSIGNED_INDEFINITELY,
  ETransactionTypes.TRANSFERRED,
  ETransactionTypes.SURRENDERED,
  ETransactionTypes.RETURNED,
];

export enum LETypes {
  individual = 'INDIVIDUAL',
  organization = 'ORGANIZATION',
}

const AncillariesAddTransactionPage = () => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { state: routerState, pathname }: any = useLocation();
  const { id = '' } = useParams<{ id: string }>();
  const { accessoryDetail, getAccessoryDetail, getAncillaryLastTransactionUniqueId } =
    useAncillaries();
  const [possibleTransactionDictionaries, setPossibleTransactionDictionaries] = useState<
    IDictionary[] | null
  >(null);
  const [isErrorLocation, setIsErrorLocation] = useState<boolean>(false);
  const [createTransactionError, setCreateTransactionError] = useState<string>('');
  const { isDesktop } = useThemeBreakpoint();
  const { selectedLanguage } = useLang();
  const { setAlert } = useAlert();
  const { loadDictionaries, country } = useLegalEntities();
  const [submitBlocked, setSubmitBlocked] = useState(false);
  const { elRef, scrollToError } = useScroll();
  const [lastTransactionUniqueId, setLastTransactionUniqueId] = useState<string | null>();

  const initialFormValues = {
    typeUniqueId: routerState?.typeUniqueId || '',
    transactionDate: routerState?.transactionDate || '',
    countryOfImportUniqueId: routerState?.countryOfImportUniqueId || '',
    importer: routerState?.importer || '',
    countryOfExportUniqueId: routerState?.countryOfExportUniqueId || '',
    expirationDate: routerState?.expirationDate || '',
    expirationTime: routerState?.expirationTime || '',
    attachments: routerState?.attachments?.length ? routerState?.attachments : [],
    legalEntityFullName: routerState?.legalEntityFullName || '',
    legalEntityId: routerState?.legalEntityId || '',
    description: routerState?.description || '',
    address: routerState?.address || '',
  };

  const schema = yup.object().shape({
    typeUniqueId: yup.string().required(t('general.field.validation.required')),
    transactionDate: yup
      .string()
      .required(t('general.field.validation.required'))
      .test(
        'date-validation',
        t('legalEntity.validation.maxDate').replace('{0}', '255'),
        (value) => {
          if (typeof value !== 'string') {
            return false;
          }
          const maxValidDate = new Date();
          const passedDate = new Date(value);
          return passedDate.valueOf() <= maxValidDate.valueOf();
        },
      ),
    attachments: yup.array().of(
      yup.object({
        attachmentName: yup
          .string()
          .test('unique', t('general.field.validation.unique'), (value, params) => {
            // @ts-ignore
            const attachments = params?.from[1]?.value.attachments ?? [];
            if (!value) return true;
            if (attachments.filter((r: any) => r.attachmentName === value).length > 1) {
              return params.createError({
                path: params.path,
                message: t('general.field.validation.unique'),
              });
            }
            return true;
          })
          .required(t('general.field.validation.required'))
          .max(64, t('general.field.validation.maxCharacters').replace('{0}', '64'))
          .matches(/^[a-zA-Z0-9_ -]+$/, t('general.field.validation.attachmentName'))
          .trim(),
      }),
    ),
    legalEntityFullName: yup.string().when('typeUniqueId', {
      is: (typeVar: any) => CHANGE_LE_REQUIRED_TRANSACTION_TYPES.includes(typeVar),
      then: (yupSchema) => yupSchema.required(t('general.field.validation.required')),
    }),
    countryOfImportUniqueId: yup.string().when('typeUniqueId', {
      is: ETransactionTypes.DECLARED_IMPORTED,
      then: (yupSchema) => yupSchema.required(t('general.field.validation.required')),
    }),
    countryOfExportUniqueId: yup.string().when('typeUniqueId', {
      is: ETransactionTypes.EXPORTED,
      then: (yupSchema) => yupSchema.required(t('general.field.validation.required')),
    }),
    importer: yup
      .string()
      .max(250, t('general.field.validation.maxCharacters').replace('{0}', '250')),
    description: yup
      .string()
      .max(1000, t('general.field.validation.maxCharacters').replace('{0}', '1000'))
      .when('typeUniqueId', {
        is: ETransactionTypes.MODIFIED,
        then: (yupSchema) => yupSchema.required(t('general.field.validation.required')),
      }),
    address: yup.string(),
    expirationTime: yup.string().when('typeUniqueId', {
      is: (typeVar: any) =>
        [ETransactionTypes.ASSIGNED_TEMPORARILY, ETransactionTypes.LEASED].includes(typeVar),
      then: (yupSchema) =>
        yupSchema
          .required(t('general.field.validation.required'))
          .test(
            'expiration-before-transaction-date-validation',
            t('legalEntity.validation.expirationDateValidation').replace('{0}', '255'),
            (value, context) => {
              if (typeof value !== 'string') {
                return false;
              }
              return (
                new Date(value).valueOf() >= new Date(context.parent.transactionDate).valueOf()
              );
            },
          ),
    }),
    expirationDate: yup.string().when('typeUniqueId', {
      is: (typeVar: any) => [ETransactionTypes.TEST_FIRED].includes(typeVar),
      then: (yupSchema) =>
        yupSchema
          .required(t('general.field.validation.required'))
          .test(
            'expiration-before-transaction-date-validation',
            t('legalEntity.validation.expirationDateValidation').replace('{0}', '255'),
            (value, context) => {
              if (typeof value !== 'string') {
                return false;
              }
              return (
                new Date(value).valueOf() >= new Date(context.parent.transactionDate).valueOf()
              );
            },
          ),
    }),
  });

  const {
    control,
    watch,
    formState: { errors },
    handleSubmit,
    setError,
    setValue,
    getValues,
    clearErrors,
  } = useForm({ defaultValues: initialFormValues, resolver: yupResolver(schema) });

  const attachments = useFieldArray({
    control,
    name: 'attachments',
  });

  const transactionTypeValue = watch('typeUniqueId');

  const canChangeLegalEntity = [...CHANGE_LE_REQUIRED_TRANSACTION_TYPES].includes(
    transactionTypeValue,
  );

  const isShowLocation = [
    ETransactionTypes.SEIZED,
    ETransactionTypes.RECOVERED,
    ETransactionTypes.STOLEN,
    ETransactionTypes.LOST,
    ETransactionTypes.SPOT_CHECK,
  ].includes(transactionTypeValue);

  const isShowCountryOfImport = [ETransactionTypes.DECLARED_IMPORTED].includes(
    transactionTypeValue,
  );

  const isModificationDescription = [ETransactionTypes.MODIFIED].includes(
    transactionTypeValue,
  );

  const isShowCountryOfExport = transactionTypeValue === ETransactionTypes.EXPORTED;

  const canSetNewKeeperOnly = [
    ETransactionTypes.GIVEN,
    ETransactionTypes.LEASED,
    ETransactionTypes.ASSIGNED_TEMPORARILY,
    ETransactionTypes.ASSIGNED_INDEFINITELY,
    ETransactionTypes.RETURNED,
  ].includes(transactionTypeValue);

  const isShowExpirationDate = [ETransactionTypes.TEST_FIRED].includes(transactionTypeValue);

  const isShowExpirationTime = [
    ETransactionTypes.ASSIGNED_TEMPORARILY,
    ETransactionTypes.LEASED,
  ].includes(transactionTypeValue);

  const isMapOnlyForOrganization = [
    ETransactionTypes.RECOVERED,
    ETransactionTypes.SEIZED,
    ETransactionTypes.SURRENDERED,
    ETransactionTypes.TRANSFERRED,
  ].includes(transactionTypeValue);

  useEffect(() => {
    (async () => {
      await loadDictionaries([EDictionaryTypes.CountryAll]);
    })();
  }, [selectedLanguage]);

  useEffect(() => {
    if (id) {
      getAncillaryLastTransactionUniqueId(id).then((result) =>
        setLastTransactionUniqueId(result.transactionUniqueId),
      );
    }
  }, [id]);

  useEffect(() => {
    (async () => {
      if (id) {
        await getAccessoryDetail(id);

        const transactionTypes = await getPossibleAncillariesTransactionType(id);
        if (transactionTypes) {
          setPossibleTransactionDictionaries(transactionTypes);
        }
      }
    })();
  }, [id, selectedLanguage]);

  useEffect(() => {
    if (
      possibleTransactionDictionaries &&
      !possibleTransactionDictionaries?.map((d) => d.uniqueId).includes(transactionTypeValue)
    ) {
      setValue('typeUniqueId', '');
    }
  }, [possibleTransactionDictionaries]);

  const getReturnUrl = () => {
    if (accessoryDetail) {
      switch (accessoryDetail?.ancillaryType?.toLowerCase()) {
        case EArtAncillaryTypeNameLowerCase.ACCESSORY:
          return ERouteLinks.ViewAccessories.replace(':id', id);

        case EArtAncillaryTypeNameLowerCase.AMMUNITION:
          return ERouteLinks.ViewAmmunition.replace(':id', id);

        case EArtAncillaryTypeNameLowerCase.COMPONENTS:
          return ERouteLinks.ViewComponents.replace(':id', id);

        default:
          return '';
      }
    }
    return '';
  };

  const onSubmit = () => {
    let ownerId: any = null;
    let keeperId: any = null;
    setCreateTransactionError('');
    handleSubmit(async (data) => {
      setIsErrorLocation(false);
      const date = formatDateTimeToIso(data.transactionDate);

      if (canSetNewKeeperOnly) {
        keeperId = data?.legalEntityId;
        ownerId = accessoryDetail?.ownerId;
      } else if (
        [ETransactionTypes.STOLEN, ETransactionTypes.LOST].includes(transactionTypeValue)
      ) {
        keeperId = null;
        ownerId = accessoryDetail?.ownerId;
      } else if (
        [
          ETransactionTypes.EXPORTED,
          ETransactionTypes.DESTROYED,
          ETransactionTypes.DEPLETED,
        ].includes(transactionTypeValue)
      ) {
        keeperId = null;
        ownerId = null;
      } else if (routerState?.legalEntityId) {
        keeperId = routerState?.legalEntityId;
        ownerId = routerState?.legalEntityId;
      } else {
        keeperId = accessoryDetail?.keeperId;
        ownerId = accessoryDetail?.ownerId;
      }

      if (isShowLocation && !routerState?.coords) {
        setSubmitBlocked(false);
        return setIsErrorLocation(true);
      }

      const body = {
        request: {
          typeUniqueId: data.typeUniqueId,
          ...(keeperId ? { keeperId } : {}),
          ...(ownerId ? { ownerId } : {}),
          date,
          ancillaryUniqueId: id,

          ...(isShowCountryOfImport
            ? {
                countryOfImportUniqueId: data.countryOfImportUniqueId,
                importer: data.importer,
              }
            : {}),
          ...(isShowCountryOfExport
            ? {
                countryOfExportUniqueId: data.countryOfExportUniqueId,
              }
            : {}),
          ...(isShowLocation && routerState?.coords
            ? {
                latitude: routerState?.coords[0],
                longitude: routerState?.coords[1],
                address: routerState?.address,
                city: routerState?.city,
                countryOfTransaction: routerState?.country,
              }
            : {}),
          ...(isModificationDescription ? { description: data.description } : {}),
          ...(isShowExpirationDate
            ? {
                expirationDate: formatDateTimeToIso(data.expirationDate),
              }
            : {}),
          ...(isShowExpirationTime
            ? {
                expirationDate: formatDateTimeToIso(data.expirationTime),
              }
            : {}),
          lastTransactionUniqueId,
        },
        ...(data.attachments?.filter((attachment: any) => attachment.file !== null)?.length
          ? { attachments: data.attachments }
          : {}),
      };

      try {
        setSubmitBlocked(true);
        const transaction = await createAncillaryMiddleTransaction(body);
        if (transaction) {
          setAlert(ActionTypes.SET_TRANSACTION_ALERT, {
            text: t('addTransaction.transactionAdded.text'),
          });
          navigate(pathname, {
            state: {
              ...routerState,
              typeUniqueId: undefined,
              transactionDate: undefined,
              countryOfImportUniqueId: undefined,
              importer: undefined,
              countryOfExportUniqueId: undefined,
              expirationDate: undefined,
              expirationTime: undefined,
              attachments: undefined,
              legalEntityFullName: undefined,
              legalEntityId: undefined,
              address: undefined,
              currentKeeperUniqueId: undefined,
              currentOwnerUniqueId: undefined,
              type: undefined,
              breadcrumbs: undefined,
            },
            replace: true,
          });
          navigate(getReturnUrl());
        }
      } catch (e: any) {
        setSubmitBlocked(false);
        setCreateTransactionError(e?.response?.data?.message);
      }

      return true;
    })();
  };

  useEffect(() => {
    (async function () {
      scrollToError(errors);
    })();
  }, [errors]);

  const validateFile = (file: File, index: number) => {
    const fileType: any = file.type;
    if (file) {
      if (file.size === 0) {
        // @ts-ignore
        setError(`attachments[${index}].file`, {
          type: 'custom',
          message: t('addAttachment.emptyFile.text'),
        });
        return false;
      }
      const fileSizeInMB = file.size / 1024 / 1024;
      if (fileSizeInMB > MAX_FILE_SIZE_IN_MB) {
        // @ts-ignore
        setError(`attachments[${index}].file`, {
          type: 'custom',
          message: t('addAttachment.fileSizeValidation.text'),
        });
        return false;
      }
      if (
        ![
          EMIMETypes.JPG,
          EMIMETypes.JPEG,
          EMIMETypes.PNG,
          EMIMETypes.PDF,
          EMIMETypes.XLSX,
        ].includes(fileType)
      ) {
        // @ts-ignore
        setError(`attachments[${index}].file`, {
          type: 'custom',
          message: t('addAttachment.fileTypeValidation.text'),
        });
        return false;
      }
    }
    clearErrors('attachments');
    return true;
  };

  const handleUploadFile = (file: File, index: number) => {
    if (validateFile(file, index)) {
      attachments.update(index, {
        file,
      });
    } else {
      attachments.update(index, {
        file: null,
        attachmentName: '',
      });
    }
  };

  const clearUserInput = () => {
    setValue('description', '');
    setValue('countryOfImportUniqueId', '');
    setValue('countryOfExportUniqueId', '');
    setValue('expirationDate', '');
    setValue('expirationTime', '');
    setValue('importer', '');
    setValue('legalEntityId', '');
    setValue('legalEntityFullName', '');
  };

  const renderRemoveAttachmentButton = (index: number) => (
    <Grid item xs={1} sx={!isDesktop ? { display: 'flex', justifyContent: 'flex-end' } : {}}>
      <Button
        variant={EButtonVariants.outlined}
        icon={EIconTypes.delete}
        onClick={() => attachments.remove(index)}
        sizePX={EButtonSizesPX.px32}
        sx={{ ...(isDesktop ? { marginLeft: '20px' } : {}), marginTop: '28px' }}
      />
    </Grid>
  );

  const handleLimitToParam = () => {
    if (isMapOnlyForOrganization) {
      return `?limitTo=${LETypes.organization}`;
    }
    if (transactionTypeValue === ETransactionTypes.GIVEN) {
      return `?limitTo=${LETypes.individual}`;
    }
    return '';
  };

  const filterTransactionTypeOptions = (
    transactions: IDictionary[] | null,
  ): IDictionary[] | null => {
    if (transactions === null) {
      return null;
    }
    if (accessoryDetail?.keeperType === LETypes.organization) {
      DISABLED_FOR_TRANSACTION_FORM = [ETransactionTypes.GIVEN];
    }
    if (accessoryDetail?.keeperType === LETypes.individual) {
      DISABLED_FOR_TRANSACTION_FORM = [
        ETransactionTypes.ASSIGNED_INDEFINITELY,
        ETransactionTypes.ASSIGNED_TEMPORARILY,
        ETransactionTypes.LEASED,
        ETransactionTypes.TRANSFERRED,
        ETransactionTypes.DESTROYED,
        ETransactionTypes.MARKED,
        ETransactionTypes.MARKED_PENDING,
        ETransactionTypes.DEACTIVATED,
        ETransactionTypes.PROOFED,
      ];
    }
    return transactions.filter(
      (transaction) =>
        !DISABLED_FOR_TRANSACTION_FORM.map((disabled) => disabled.toString()).includes(
          transaction.uniqueId.toString(),
        ),
    );
  };

  const breadcrumbs = [
    { label: t('module.ancillaries.name'), route: ERouteLinks.Ancillaries },
    {
      label: t('ancillaries.ancillaryList.label'),
      route: ERouteLinks.ViewAncillary.replace(':id', id).replace(
        ':type',
        accessoryDetail?.ancillaryType?.toLowerCase() as string,
      ),
    },
    t('addTransaction.addTransaction.breadcrumb'),
  ];

  const attachmentNames = watch('attachments');

  useEffect(() => {
    attachments.fields.forEach((attachment: any, index) => {
      if (attachment?.file?.name && !attachmentNames[index]?.attachmentName) {
        setValue(
          `attachments.${index}.attachmentName`,
          removeFileExtension(attachment.file.name),
        );
      }
    });
  }, [attachments.fields, attachmentNames]);

  return (
    <>
      <Breadcrumbs items={breadcrumbs} />
      <Box sx={{ mb: 2 }}>
        <StyledPageTitle variant="h4">
          {t('addTransaction.addTransaction.header')}
        </StyledPageTitle>
      </Box>
      <div ref={elRef}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={3}>
            <FormSelect
              options={mapDictionaryToOptionProp(
                filterTransactionTypeOptions(possibleTransactionDictionaries),
              )}
              name="typeUniqueId"
              label={t('addFirearm.location.section.transactionType')}
              setValue={setValue}
              control={control}
              onSelect={clearUserInput}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <FormDateTimePicker
              name="transactionDate"
              label={t('addFirearm.location.section.transactionDate')}
              control={control}
              errors={errors}
              currentHour={true}
            />
          </Grid>
        </Grid>

        <Grid container>
          {accessoryDetail && (
            <Grid item xs={12}>
              <Accordion
                title={t('addTransaction.currentOwnerAndKeeper.section.name')}
                alwaysExpanded
              >
                <Box mb={5}>
                  <div>
                    <StyledDetailsWrapper container rowGap={3}>
                      <Grid item xs={12} md={6}>
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t('addTransaction.currentOwnerAndKeeper.section.owner.label')}
                        </Typography>
                        <Typography variant="body2">
                          <StyledLegalLink
                            legalId={accessoryDetail.ownerId}
                            legalType={mapILegalEntityTypeToELegalEntityTypes(
                              accessoryDetail.ownerType,
                            )}
                            labelText={accessoryDetail.ownerName}
                            legalVisibilityUniqueId={accessoryDetail.ownerVisibilityUniqueId}
                          />
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t('addTransaction.currentOwnerAndKeeper.section.keeper.label')}
                        </Typography>
                        <Typography variant="body2">
                          <StyledLegalLink
                            legalId={accessoryDetail.keeperId}
                            legalType={mapILegalEntityTypeToELegalEntityTypes(
                              accessoryDetail.keeperType,
                            )}
                            labelText={accessoryDetail.keeperName}
                            legalVisibilityUniqueId={accessoryDetail.keeperVisibilityUniqueId}
                          />
                        </Typography>
                      </Grid>
                    </StyledDetailsWrapper>
                  </div>
                </Box>
              </Accordion>
            </Grid>
          )}

          {canChangeLegalEntity && (
            <Grid item xs={12}>
              <Accordion
                title={
                  [
                    ETransactionTypes.ASSIGNED_INDEFINITELY,
                    ETransactionTypes.ASSIGNED_TEMPORARILY,
                    ETransactionTypes.GIVEN,
                    ETransactionTypes.RETURNED,
                    ETransactionTypes.LEASED,
                  ].includes(transactionTypeValue)
                    ? t('addTransaction.newKeeper.section.name')
                    : t('addTransaction.newOwnerAndKeeper.section.name')
                }
                alwaysExpanded
              >
                <Grid container mb={5} rowGap={2}>
                  <Grid item xs={12} lg={6}>
                    <FormInput
                      name="legalEntityFullName"
                      label={`${t(
                        'addTransaction.newOwnerAndKeeper.section.legalEntity.label',
                      )}`}
                      control={control}
                      errors={errors}
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3} sx={{ marginTop: { lg: '28px' }, ml: { lg: 2 } }}>
                    <Button
                      fullWidth
                      label={t(
                        'addTransaction.newOwnerAndKeeper.section.searchForLegalEntity.button',
                      )}
                      variant={EButtonVariants.contained}
                      size={EButtonSizes.small}
                      onClick={() =>
                        navigate(`${ERouteLinks.SelectLegalEntity}${handleLimitToParam()}`, {
                          state: {
                            context: 'ancillaryTransactions',
                            ...routerState,
                            ...getValues(),
                            id,
                            currentKeeperUniqueId: accessoryDetail?.keeperId,
                            currentOwnerUniqueId:
                              transactionTypeValue === ETransactionTypes.SOLD
                                ? accessoryDetail?.ownerId
                                : undefined,
                            breadcrumbs: [
                              ...breadcrumbs,
                              t('ancillaries.transaction.selectLegal.label'),
                            ],
                            returnLink: `${ERouteLinks.AncillariesMiddleTransaction.replace(
                              ':id',
                              id,
                            )}`,
                          },
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </Accordion>
            </Grid>
          )}

          <Grid item xs={12}>
            <Accordion
              title={t('addTransaction.ancillaryDetails.section.name')}
              alwaysExpanded
            />
            <Grid
              container
              sx={{
                border: `1px solid ${colors.primary25}`,
                borderRadius: '4px',
                overflow: 'hidden',
              }}
              mb={4}
            >
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  backgroundColor: colors.primary25,
                }}
                px={2}
                py={1}
              >
                <Grid item xs={12}>
                  <Typography variant="h4" sx={{ fontWeight: 600 }}>
                    {accessoryDetail?.type}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item container p={2} rowSpacing={2}>
                <Grid item xs={12} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t('ancillary.transaction.details.ancillary.label')}
                  </Typography>
                  <Typography variant="body2">
                    {accessoryDetail?.ancillaryType
                      ? capitalizeFirstLetter(accessoryDetail.ancillaryType.toLowerCase())
                      : '-'}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={3} direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t('ancillary.transaction.details.type.label')}
                  </Typography>
                  <Typography variant="body2">
                    {emptyFieldHolder(accessoryDetail?.type)}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={3} direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t('ancillary.transaction.details.artId.label')}
                  </Typography>
                  <Typography variant="body2">
                    {emptyFieldHolder(accessoryDetail?.artId)}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={3} container direction="column">
                  <Link
                    href={`${ERouteLinks.ViewAncillaries.replace(
                      ':id',
                      accessoryDetail?.artUniqueId as string,
                    ).replace(
                      ':type',
                      accessoryDetail?.ancillaryType?.toLowerCase() as string,
                    )}`}
                    underline="none"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button
                      fullWidth
                      label={t('ancillary.transaction.details.showArt.label')}
                      variant={EButtonVariants.contained}
                      size={EButtonSizes.small}
                    />
                  </Link>
                </Grid>
                <Grid item xs={12} lg={3} direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t('ancillary.transaction.details.manufacturer.label')}
                  </Typography>
                  <Typography variant="body2">
                    {emptyFieldHolder(accessoryDetail?.manufacturer)}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={3} direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t('ancillary.transaction.details.calibre.label')}
                  </Typography>
                  <Typography variant="body2">
                    {emptyFieldHolder(accessoryDetail?.calibre)}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={3} direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t('ancillary.transaction.details.composition.label')}
                  </Typography>
                  <Typography variant="body2">
                    {emptyFieldHolder(accessoryDetail?.composition)}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={3} direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t('ancillary.transaction.details.proofHouse.label')}
                  </Typography>
                  <Typography variant="body2">
                    {emptyFieldHolder(accessoryDetail?.proofHouse)}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    height: '1px',
                    backgroundColor: colors.primary25,
                    padding: 0,
                    margin: '20px 0 10px',
                  }}
                />
                <Grid item xs={12} lg={3} direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t('ancillary.transaction.details.fireCycleId.label')}
                  </Typography>
                  <Typography variant="body2">
                    {emptyFieldHolder(accessoryDetail?.fireCycleId)}
                  </Typography>
                </Grid>
                {!(
                  accessoryDetail?.ancillaryType ===
                  EArtAncillaryTypeName.AMMUNITION.toUpperCase()
                ) && (
                  <Grid item xs={12} lg={3} direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t('ancillary.transaction.details.serialNumber.label')}
                    </Typography>
                    <Typography variant="body2">
                      {emptyFieldHolder(accessoryDetail?.serialNumber)}
                    </Typography>
                  </Grid>
                )}
                {accessoryDetail?.ancillaryType ===
                  EArtAncillaryTypeName.AMMUNITION.toUpperCase() && (
                  <Grid item xs={12} lg={3} direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t('addAncillary.list.section.lotNumber.label')}
                    </Typography>
                    <Typography variant="body2">
                      {emptyFieldHolder(accessoryDetail?.lotNumber)}
                    </Typography>
                  </Grid>
                )}
                {accessoryDetail?.ancillaryType ===
                  EArtAncillaryTypeName.AMMUNITION.toUpperCase() && (
                  <Grid item xs={12} lg={3} direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t('addAncillary.list.section.batchNumber.label')}
                    </Typography>
                    <Typography variant="body2">
                      {emptyFieldHolder(accessoryDetail?.batchNumber)}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12} lg={3} direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t('ancillary.transaction.details.externalRefNo.label')}
                  </Typography>
                  <Typography variant="body2">
                    {emptyFieldHolder(accessoryDetail?.externalReferenceNumber)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {isShowLocation && (
            <Grid item xs={12}>
              <Accordion title={t('addTransaction.location.section.name')} alwaysExpanded>
                <Grid
                  container
                  mb={4}
                  lg={12}
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-start"
                  columnGap={3}
                >
                  <Grid sx={{ flexBasis: '40%' }}>
                    <FormInput
                      name="address"
                      label={t('addFirearm.location.section.address.text')}
                      control={control}
                      errors={errors}
                      placeholder={routerState?.address || ''}
                      InputProps={{ readOnly: true }}
                      disabled
                      sx={{ minWidth: '272px' }}
                      helperText={t('addFirearm.location.section.address.helperText')}
                    />
                  </Grid>
                  {routerState?.coords && (
                    <StyledLocationMarkConfirmationMarginBottom>
                      <Typography variant="body1" color={colors.success900}>
                        {t('addTransaction.location.section.locationMarked.text')}
                      </Typography>
                      <StyledIconWrapper>
                        <Icon type={EIconTypes.checkmark} color={colors.white} fontSize={14} />
                      </StyledIconWrapper>
                    </StyledLocationMarkConfirmationMarginBottom>
                  )}
                  <Button
                    onClick={() =>
                      navigate(
                        `${ERouteLinks.SelectLocation.replace(
                          ':id',
                          id,
                        )}?type=&expand=firearms`,
                        {
                          state: {
                            ...routerState,
                            ...getValues(),
                            leId: id,
                            returnLink: `${ERouteLinks.AncillariesMiddleTransaction.replace(
                              ':id',
                              id,
                            )}`,
                          },
                        },
                      )
                    }
                    fullWidth={!isDesktop}
                    label={t('selectLocationOnMap.page.text')}
                    variant={EButtonVariants.outlined}
                    size={EButtonSizes.small}
                    sx={{ marginTop: '28px' }}
                  />
                  {isErrorLocation && (
                    <Grid item xs={12}>
                      <FormErrorLabel
                        label={t(
                          'addTransaction.location.section.selectLocationOnMap.errorLabel',
                        )}
                      />
                    </Grid>
                  )}
                </Grid>
              </Accordion>
            </Grid>
          )}

          {isShowCountryOfImport && (
            <Grid item xs={12}>
              <Accordion title={t('addFirearm.countryOfImport.section.name')} alwaysExpanded>
                <Grid mb={2} container columnSpacing={2}>
                  <Grid item xs={12} lg={6}>
                    <FormInput
                      name="importer"
                      label={t('addFirearm.countryOfImport.section.importer.label.validation')}
                      control={control}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <FormSelect
                      options={mapDictionaryToOptionProp(country)}
                      name="countryOfImportUniqueId"
                      setValue={setValue}
                      label={t('addFirearm.countryOfImport.section.country.label.validation')}
                      control={control}
                      errors={errors}
                    />
                  </Grid>
                </Grid>
              </Accordion>
            </Grid>
          )}

          {isShowCountryOfExport && (
            <Grid item xs={12}>
              <Accordion title={t('addFirearm.countryOfExport.section.name')} alwaysExpanded>
                <Box mb={4}>
                  <Grid item xs={12} lg={6}>
                    <FormSelect
                      options={mapDictionaryToOptionProp(country)}
                      name="countryOfExportUniqueId"
                      label={t('addFirearm.countryOfExport.section.country.label.validation')}
                      setValue={setValue}
                      control={control}
                      errors={errors}
                    />
                  </Grid>
                </Box>
              </Accordion>
            </Grid>
          )}

          {isShowExpirationDate && (
            <Grid item xs={12}>
              <Accordion title={t('addTransaction.expiration.section.name')} alwaysExpanded>
                <Box mb={4}>
                  <Grid item xs={12} lg={6}>
                    <FormDatePicker
                      name="expirationDate"
                      label={t(
                        'addTransaction.expiration.section.expirationDate.label.validation',
                      )}
                      control={control}
                      errors={errors}
                    />
                  </Grid>
                </Box>
              </Accordion>
            </Grid>
          )}

          {isShowExpirationTime && (
            <Grid item xs={12}>
              <Accordion title={t('addTransaction.expiration.section.name')} alwaysExpanded>
                <Box mb={4}>
                  <Grid item xs={12} lg={6}>
                    <FormDateTimePicker
                      name="expirationTime"
                      label={`${t(
                        'addTransaction.expiration.section.expirationTime.label.validation',
                      )}*`}
                      control={control}
                      errors={errors}
                    />
                  </Grid>
                </Box>
              </Accordion>
            </Grid>
          )}

          {isModificationDescription && (
            <Grid item xs={12}>
              <Accordion
                title={t('ancillaries.transaction.modification.label')}
                alwaysExpanded
              >
                <Box mb={4}>
                  <Grid item xs={12} lg={12}>
                    <FormWyswigEditor
                      name="description"
                      label={`${t('ancillaries.transaction.modification.label')}*`}
                      placeholder={t('ancillaries.transaction.modification.label')}
                      setValue={setValue}
                      control={control}
                      errors={errors}
                    />
                  </Grid>
                </Box>
              </Accordion>
            </Grid>
          )}

          <Grid item xs={12}>
            <Accordion title={t('addTransaction.document.section.name')} alwaysExpanded>
              <Box>
                <Grid container>
                  <Grid item xs={12}>
                    <Box mb={2}>
                      <Alert
                        text={t('general.field.availableAttachmentFormats.text')}
                        variant={EAlertVariants.warning}
                      />
                    </Box>
                  </Grid>
                  {attachments?.fields?.map((attachment: any, index) => (
                    <Grid key={attachment.id} item xs={12} container mb={!isDesktop ? 2 : 0}>
                      <Grid item xs={attachments.fields?.length > 1 ? 11 : 12} lg={6}>
                        <FormInput
                          name={`attachments[${index}].attachmentName`}
                          label={t('addTransaction.document.section.attachmentName.label')}
                          defaultValue={
                            attachment?.file?.name
                              ? removeFileExtension(attachment?.file?.name)
                              : ''
                          }
                          control={control}
                          errors={errors}
                          arrayName="attachments"
                          arrayIndex={index}
                          fieldName="attachmentName"
                          InputProps={{ disabled: attachment?.file === null }}
                        />
                      </Grid>
                      {!isDesktop && renderRemoveAttachmentButton(index)}
                      <Grid
                        item
                        xs={12}
                        lg={3}
                        sx={{ marginTop: { lg: '28px' }, ml: { lg: 2 } }}
                      >
                        <Button
                          isUploadButton
                          onClick={(files) => handleUploadFile(files[0], index)}
                          fullWidth
                          label={t('addTransaction.document.section.browseFiles.button')}
                          id="add_file-button"
                          icon={EIconTypes.plus}
                          size={EButtonSizes.small}
                          isMultiple
                        />
                        {errors &&
                          errors?.attachments &&
                          errors?.attachments[index] &&
                          errors?.attachments[index]?.file && (
                            <FormErrorLabel
                              label={errors?.attachments[index]?.file?.message}
                            />
                          )}
                      </Grid>
                      {isDesktop && renderRemoveAttachmentButton(index)}
                    </Grid>
                  ))}
                  <Grid item xs={12} lg={3} sx={{ mt: { xs: 2, lg: 0 } }}>
                    <Button
                      onClick={() =>
                        attachments.append({
                          attachmentName: '',
                          file: null,
                        })
                      }
                      fullWidth
                      id="add_file-button"
                      label={t('addFirearm.document.section.addFile.button')}
                      variant={EButtonVariants.outlined}
                      size={EButtonSizes.small}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Accordion>
          </Grid>

          <Grid container justifyContent="flex-start" sx={{ mt: 4 }}>
            <Grid item xs={12} lg={6} container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Button
                  onClick={onSubmit}
                  fullWidth
                  label={t('addTransaction.save.button')}
                  disabled={accessoryDetail === undefined}
                  variant={EButtonVariants.contained}
                  isLoading={submitBlocked}
                  sx={{ mr: 1 }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Button
                  fullWidth
                  label={t('addTransaction.cancel.button')}
                  variant={EButtonVariants.outlined}
                  onClick={() => navigate(getReturnUrl())}
                />
              </Grid>
              <Grid xs={12} item>
                <Typography variant="caption">{t('general.mandatoryField.text')}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} mt={4}>
            {createTransactionError && (
              <Box mb={2}>
                <Alert text={createTransactionError} variant={EAlertVariants.error} />
              </Box>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default AncillariesAddTransactionPage;
