import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import { Accordion } from 'components/molecules/Accordion';
import { useTranslations } from 'hooks/useTranslations';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { useAncillaries } from 'pages/AncillariesPage/hooks';
import { EDictionaryTypes } from 'models/dictionary';
import { useLang } from 'models/langContext';
import { ERouteLinks } from 'models/route';
import { useAlert } from 'models/alertContext';
import { Alert, EAlertVariants, IAlert } from 'components/atoms/Alert';
import { DetailsTemplate, EDetailsPageTypes } from 'components/templates/DetailsTemplate';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import Button from 'components/atoms/Button';
import { Chips } from 'components/atoms/Chips';
import { getFieldsByAmmunitionType } from 'pages/AncillariesPage/helpers';
import {
  EAncillaryState,
  EArtAmmunitionType,
  EArtAncillaryTypeName,
  EArtAncillaryTypeNameLowerCase,
} from 'constants/ArtAccessoryType';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { clearAlertTimeout, EAlertTime, formatDate } from 'utils/date';
import { IAncillaryDetailsCustomfield } from 'models/ancillaries';
import { ECustomFieldObjectType, ECustomFieldType } from 'models/customField';
import { EPermission } from 'models/permissions';
import { usePermission } from 'hooks/usePermission';
import Link from '@mui/material/Link';
import { emptyFieldHolder } from 'components/molecules/CardList';
import { ActionTypes } from 'state/actions/alert';
import { EFirearmStateId } from 'models/firearm';
import { renderDataColorByValues } from 'components/molecules/Table/helpers';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import { StyledLegalLink } from 'components/atoms/StyledLegalLink';
import { getLegalEntityType } from 'components/organisms/RegisteredFirearmDetails';
import { AmmunitionInformationContainer, AmmunitionInformationTitle } from './styles';
import { AmmunitionTransactions } from './AmmunitionTransactions';
import { AmmunitionComments } from './AmmunitionComments';
import { AmmunitionAttachments } from './AmmunitionAttachments';

export enum EAmmunitionLabels {
  ammunitionGeneralInformation = 'ammunitionDetails.ammunitionGeneralInformation.header',
  showArtInformation = 'ammunitionDetails.showArtInformation.button',
  ammunitionType = 'ammunitionDetails.ammunitionType.label',
  artId = 'ammunitionDetails.artId.label',
  ancillaryType = 'ancillaryDetails.ancillaryType.label',
  ammunitionManufacturer = 'ammunitionDetails.ammunitionManufacturer.label',
  productName = 'ammunitionDetails.productName.label',
  factory = 'ammunitionDetails.factory.label',
  calibreGauge = 'ammunitionDetails.CalibreGauge.label',
  functionalType = 'ammunitionDetails.functionalType.label',
  cartridgeComposition = 'ammunitionDetails.cartrigeComposition.label',
  colourOfPrimerSealant = 'ammunitionDetails.colourOfPrimerSealant.label',
  projectileShape = 'ammunitionDetails.projectileShape.label',
  headStampYearMarking = 'ammunitionDetails.headStampYearMarking.label',
  alphanumericalMarkins = 'ammunitionDetails.alphanumericalMarkins.label',
  expirationDate = 'ammunitionDetails.expirationDate.label',
  productionDate = 'ammunitionDetails.manufacturedDate.label',
  munitionVelocity = 'ammunitionDetails.munitionVelocity.label',
  shotSize = 'ammunitionDetails.shotSize.label',
  munitionType = 'ammunitionDetails.munitionType.label',
  ammunitionDetailedInformation = 'ammunitionDetails.ammunitionDetailedInformation.header',
  firecycleID = 'ammunitionDetails.firecycleID.label',
  lotNumber = 'ammunitionDetails.lotNumber.label',
  batchNumber = 'ammunitionDetails.batchNumber.label',
  assetId = 'ammunitionDetails.assetId.label',
  ammunitionState = 'ammunitionDetails.ammunitionState.label',
  currentOwner = 'ammunitionDetails.currentOwner.label',
  currentKeeper = 'ammunitionDetails.currentKeeper.label',
  manufacturedAt = 'ammunitionDetails.accordion.manufacturedAt.label',
  expiryDate = 'ammunitionDetails.expiryDate.label',
  exhibitNumber = 'ammunitionDetails.exhibitNumber.label',
  externalReferenceNumber = 'ammunitionDetails.externalReferenceNumber.label',
}

const DetailAmmunition = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslations();
  const { selectedLanguage, defaultLanguage } = useLang();
  const navigate = useNavigate();
  const { nextPageAlert, clearAlert, transactionAlert, ancillaryAlert, setAlert } = useAlert();
  const { loadDictionaries, ammunitionDetail, getAmmunitionDetail } = useAncillaries();
  const { shortDateFormat } = useGlobalProperty();
  const [accessFields, setAccessFields] = useState<string[]>([]);
  const [isAllExpanded, setIsAllExpanded] = useState<boolean>(false);
  const { hasPermissions } = usePermission();
  const { getCustomField, customFieldsAncillaries } = useLegalEntities();

  useEffect(() => {
    if (ammunitionDetail) {
      if (ammunitionDetail.typeUniqueId) {
        const fields = getFieldsByAmmunitionType(
          ammunitionDetail.typeUniqueId as EArtAmmunitionType,
        );
        setAccessFields(fields);
      } else {
        setAccessFields([]);
      }
    }
  }, [ammunitionDetail]);

  useEffect(() => {
    (async () => {
      if (id) {
        await getAmmunitionDetail(id);
      }
    })();
  }, [id]);

  useEffect(() => {
    (async () => {
      await getCustomField(ECustomFieldObjectType.AN_AMMUNITION);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => {
      clearAlertTimeout(clearAlert, ActionTypes.CLEAR_SAME_PAGE_ALERT, EAlertTime.SHORT);
      clearAlertTimeout(clearAlert, ActionTypes.CLEAR_NEXT_PAGE_ALERT, EAlertTime.SHORT);
      clearAlertTimeout(clearAlert, ActionTypes.CLEAR_TRANSACTION_ALERT, EAlertTime.SHORT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_ANCILLARY_ALERT);
    },
    [],
  );

  useEffect(() => {
    (async function initDictionaries() {
      await loadDictionaries([EDictionaryTypes.ArtAncillaryOptions]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  const showField = (fieldName: string) => accessFields.includes(fieldName);

  const renderCustomFieldData = (customField: IAncillaryDetailsCustomfield) => {
    if (customField.customFieldType === ECustomFieldType.BOOL) {
      if (customField.value === null) {
        return '-';
      }
      return customField.value
        ? t('customFields.value.yes.label')
        : t('customFields.value.no.label');
    }
    if (customField.customFieldType === ECustomFieldType.DATE) {
      return formatDate(customField.value, shortDateFormat);
    }
    return customField.value || '-';
  };

  const navigateToMap = () => {
    navigate(ERouteLinks.RegisteredAncillariesMap, {
      state: {
        ancillaryUniqueId: ammunitionDetail?.uniqueId,
        ancillaryType: EArtAncillaryTypeName.AMMUNITION.toLowerCase(),
        legalityUniqueId: ammunitionDetail?.legalityUniqueId,
        navigateBack: `${ERouteLinks.ViewAmmunition.replace(':id', id as string)}`,
        showAdvanced: true,
        showLegalityBar: false,
        breadcrumbs: [
          {
            label: t('module.ancillaries.name'),
            route: ERouteLinks.Ancillaries,
          },
          {
            label: t('module.ammunition.name'),
            route: `${ERouteLinks.Ancillaries}?expand=ammunition`,
          },
          {
            label: t('ammunitionDetails.accordion.title.label'),
            route: `${ERouteLinks.ViewAmmunition.replace(':id', id as string)}`,
          },
          t('ancillaries.ancillariesLocation.showOnMap.label'),
        ],
      },
    });
  };

  const handleRedirectToEditPageButton = (): (() => void) => {
    if (
      customFieldsAncillaries?.length &&
      ammunitionDetail?.stateUniqueId !== EFirearmStateId.DISABLED
    ) {
      return () =>
        navigate(
          ERouteLinks.EditAncillary.replace(
            ':id',
            ammunitionDetail?.uniqueId as string,
          ).replace(':type', 'ammunition'),
        );
    }
    if (ammunitionDetail?.stateUniqueId === EFirearmStateId.DISABLED) {
      return () =>
        setAlert(ActionTypes.SET_ANCILLARY_ALERT, {
          text: t('ancillary.disabled.edit.info'),
          variant: EAlertVariants.error,
        } as IAlert);
    }
    return () =>
      setAlert(ActionTypes.SET_ANCILLARY_ALERT, {
        text: t('general.noAvaibleDataToEdit.label'),
        variant: EAlertVariants.warning,
      } as IAlert);
  };

  return (
    <div>
      <Breadcrumbs
        items={[
          {
            label: t('module.ancillaries.name'),
            route: ERouteLinks.Ancillaries,
          },
          {
            label: t('module.ammunition.name'),
            route: `${ERouteLinks.Ancillaries}?expand=ammunition`,
          },
          t('ammunitionDetails.accordion.title.label'),
        ]}
      />
      {ammunitionDetail && accessFields.length && (
        <Grid container item gap={3}>
          <DetailsTemplate
            title={ammunitionDetail?.type}
            pageType={EDetailsPageTypes.view}
            redirectToShowOnMap={
              ![
                EAncillaryState.DESTROYED,
                EAncillaryState.DEPLETED,
                EAncillaryState.DISABLED,
              ].includes(ammunitionDetail.stateUniqueId as EAncillaryState) &&
              ammunitionDetail?.latitude
                ? navigateToMap
                : undefined
            }
            showOnMapButtonId="ammunition-show-on-map-button"
            editPermissions={[EPermission.MANAGE_LE]}
            redirectToEditView={handleRedirectToEditPageButton()}
            redirectToDisableView={
              ammunitionDetail.stateUniqueId === EAncillaryState.DISABLED ||
              !hasPermissions([EPermission.DISABLE_ANCILLARY])
                ? undefined
                : () =>
                    navigate(
                      `${ERouteLinks.DisableAncillary.replace(
                        ':id',
                        ammunitionDetail.uniqueId,
                      ).replace(':type', EArtAncillaryTypeName.AMMUNITION.toLowerCase())}`,
                    )
            }
          >
            <AmmunitionInformationContainer item xs={12} lg={12} container>
              <Grid item xs={12} lg={12} container direction="column">
                <AmmunitionInformationTitle variant="subtitle1">
                  {t(EAmmunitionLabels.ammunitionGeneralInformation)}
                </AmmunitionInformationTitle>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EAmmunitionLabels.ammunitionType)}
                </Typography>
                <Typography variant="body2">
                  {emptyFieldHolder(ammunitionDetail?.type)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography
                  variant="subtitle2"
                  sx={{ marginBottom: '4px', textTransform: 'uppercase' }}
                >
                  {t(EAmmunitionLabels.artId)}
                </Typography>
                <Typography variant="body2" style={{ textTransform: 'capitalize' }}>
                  {emptyFieldHolder(ammunitionDetail?.artId)}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12} lg={6} container direction="column">
                {ammunitionDetail.artUniqueId && (
                  <Link
                    href={ERouteLinks.ViewAncillaryDetails.replace(
                      ':id',
                      ammunitionDetail.artUniqueId,
                    ).replace(':type', EArtAncillaryTypeNameLowerCase.AMMUNITION)}
                    underline="none"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button
                      variant={EButtonVariants.contained}
                      size={EButtonSizes.small}
                      label={t(EAmmunitionLabels.showArtInformation)}
                      fullWidth
                    />
                  </Link>
                )}
              </Grid>
              {showField('manufacturer') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.ammunitionManufacturer)}
                  </Typography>
                  <Typography variant="body2">
                    {emptyFieldHolder(ammunitionDetail?.manufacturer)}
                  </Typography>
                </Grid>
              )}
              {showField('productName') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.productName)}
                  </Typography>
                  <Typography variant="body2">
                    {emptyFieldHolder(ammunitionDetail?.productName)}
                  </Typography>
                </Grid>
              )}
              {showField('factory') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.factory)}
                  </Typography>
                  <Typography variant="body2">
                    {emptyFieldHolder(ammunitionDetail?.factory)}
                  </Typography>
                </Grid>
              )}
              {showField('calibreGauge') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.calibreGauge)}
                  </Typography>
                  <Typography variant="body2">
                    {emptyFieldHolder(ammunitionDetail?.calibre)}
                  </Typography>
                </Grid>
              )}
              {showField('cartridgeComposition') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.cartridgeComposition)}
                  </Typography>
                  <Typography variant="body2">
                    {emptyFieldHolder(ammunitionDetail?.cartridgeComposition)}
                  </Typography>
                </Grid>
              )}
              {showField('colourOfPrimerSealant') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.colourOfPrimerSealant)}
                  </Typography>
                  <Typography variant="body2">
                    {emptyFieldHolder(ammunitionDetail?.colourOfPrimerSealant)}
                  </Typography>
                </Grid>
              )}
              {showField('shotSize') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.shotSize)}
                  </Typography>
                  <Typography variant="body2">
                    {emptyFieldHolder(ammunitionDetail?.shotSize)}
                  </Typography>
                </Grid>
              )}
              {showField('munitionVelocity') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.munitionVelocity)}
                  </Typography>
                  <Typography variant="body2">
                    {emptyFieldHolder(ammunitionDetail?.munitionVelocity)}
                  </Typography>
                </Grid>
              )}
              {showField('functionalType') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.functionalType)}
                  </Typography>
                  <Typography variant="body2">
                    {emptyFieldHolder(ammunitionDetail?.functionalType)}
                  </Typography>
                </Grid>
              )}
              {showField('munitionType') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.munitionType)}
                  </Typography>
                  <Typography variant="body2">
                    {emptyFieldHolder(ammunitionDetail?.munitionType)}
                  </Typography>
                </Grid>
              )}
              {showField('projectileShape') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.projectileShape)}
                  </Typography>
                  <Typography variant="body2">
                    {emptyFieldHolder(ammunitionDetail?.projectileShape)}
                  </Typography>
                </Grid>
              )}
              {showField('headstampYearMarking') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.headStampYearMarking)}
                  </Typography>
                  <Typography variant="body2">
                    {emptyFieldHolder(ammunitionDetail?.headstampYearMarking)}
                  </Typography>
                </Grid>
              )}
              {showField('alphanumericalMarkins') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.alphanumericalMarkins)}
                  </Typography>
                  <Typography variant="body2">
                    {emptyFieldHolder(ammunitionDetail?.alphanumericalMarkins)}
                  </Typography>
                </Grid>
              )}
            </AmmunitionInformationContainer>
            <AmmunitionInformationContainer item xs={12} lg={12} container borderHidden>
              <Grid item xs={12} lg={12} container direction="column">
                <AmmunitionInformationTitle variant="subtitle1">
                  {t(EAmmunitionLabels.ammunitionDetailedInformation)}
                </AmmunitionInformationTitle>
              </Grid>
              {showField('fireCycleId') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.firecycleID)}
                  </Typography>
                  <Typography variant="body2">
                    {emptyFieldHolder(ammunitionDetail?.fireCycleId)}
                  </Typography>
                </Grid>
              )}
              {showField('lotNumber') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.lotNumber)}
                  </Typography>
                  <Typography variant="body2">
                    {emptyFieldHolder(ammunitionDetail?.lotNumber)}
                  </Typography>
                </Grid>
              )}
              {showField('batchNumber') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.batchNumber)}
                  </Typography>
                  <Typography variant="body2">
                    {emptyFieldHolder(ammunitionDetail?.batchNumber)}
                  </Typography>
                </Grid>
              )}
              {showField('assetId') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.assetId)}
                  </Typography>
                  <Typography variant="body2">
                    {emptyFieldHolder(ammunitionDetail?.assetId)}
                  </Typography>
                </Grid>
              )}
              {showField('state') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.ammunitionState)}
                  </Typography>
                  <Typography variant="body2">
                    <Chips
                      text={emptyFieldHolder(ammunitionDetail?.state)}
                      variant={
                        renderDataColorByValues(
                          ammunitionDetail.activityUniqueId,
                          ammunitionDetail.legalityUniqueId,
                          ammunitionDetail.stateUniqueId,
                        ).stateField
                      }
                    />
                  </Typography>
                </Grid>
              )}

              {showField('ownerName') && ammunitionDetail.ownerType && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.currentOwner)}
                  </Typography>
                  <Typography variant="body2">
                    <StyledLegalLink
                      legalId={ammunitionDetail.ownerId}
                      legalType={getLegalEntityType(ammunitionDetail.ownerType)}
                      labelText={ammunitionDetail.ownerName}
                      legalVisibilityUniqueId={ammunitionDetail.ownerVisibilityUniqueId}
                    />
                  </Typography>
                </Grid>
              )}
              {showField('keeperName') && ammunitionDetail.keeperType && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.currentKeeper)}
                  </Typography>
                  <Typography variant="body2">
                    <Typography variant="body2">
                      <StyledLegalLink
                        legalId={ammunitionDetail.keeperId}
                        legalType={getLegalEntityType(ammunitionDetail.keeperType)}
                        labelText={ammunitionDetail.keeperName}
                        legalVisibilityUniqueId={ammunitionDetail.keeperVisibilityUniqueId}
                      />
                    </Typography>
                  </Typography>
                </Grid>
              )}
              {showField('productionDate') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.productionDate)}
                  </Typography>
                  <Typography variant="body2">
                    {ammunitionDetail?.productionDate
                      ? formatDate(ammunitionDetail?.productionDate, shortDateFormat)
                      : '-'}
                  </Typography>
                </Grid>
              )}
              {showField('ammunitionExpiryDate') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.expirationDate)}
                  </Typography>
                  <Typography variant="body2">
                    {ammunitionDetail?.ammunitionExpiryDate
                      ? formatDate(ammunitionDetail?.ammunitionExpiryDate, shortDateFormat)
                      : '-'}
                  </Typography>
                </Grid>
              )}
              {showField('exhibitNumber') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.exhibitNumber)}
                  </Typography>
                  <Typography variant="body2">
                    {emptyFieldHolder(ammunitionDetail?.exhibitNumber)}
                  </Typography>
                </Grid>
              )}
              {ammunitionDetail?.customFields?.map((customField) => (
                <Grid item xs={6} lg={3} key={customField.customFieldUniqueId}>
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {customField?.translations?.filter(
                      (translation) =>
                        selectedLanguage?.uniqueId === translation.languageUniqueId,
                    )?.[0]?.name ||
                      customField?.translations?.filter(
                        (translation) =>
                          defaultLanguage?.uniqueId === translation.languageUniqueId,
                      )?.[0]?.name}
                  </Typography>
                  <Typography variant="body2">{renderCustomFieldData(customField)}</Typography>
                </Grid>
              ))}
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EAmmunitionLabels.externalReferenceNumber)}
                </Typography>
                <Typography variant="body2">
                  {emptyFieldHolder(ammunitionDetail?.externalReferenceNumber)}
                </Typography>
              </Grid>
            </AmmunitionInformationContainer>
          </DetailsTemplate>
          <Grid item xs={12} pb={2} container direction="column">
            {nextPageAlert && (
              <Box sx={{ marginBottom: '16px' }}>
                <Alert text={nextPageAlert.text} variant={nextPageAlert.variant} />
              </Box>
            )}
            {ancillaryAlert && (
              <Box sx={{ marginBottom: '16px' }}>
                <Alert text={ancillaryAlert.text} variant={ancillaryAlert.variant} />
              </Box>
            )}
            {transactionAlert && (
              <Box sx={{ marginBottom: '16px' }}>
                <Alert text={transactionAlert.text} variant={transactionAlert.variant} />
              </Box>
            )}
            {!hasPermissions([EPermission.VIEW_LIMITED_ANCILLARIES]) && (
              <Button
                label={
                  isAllExpanded
                    ? t('ammunitionDetails.collapseAll.button')
                    : t('ammunitionDetails.expandAll.button')
                }
                onClick={() => setIsAllExpanded((prev) => !prev)}
                variant={EButtonVariants.outlined}
                sx={{ width: '150px', alignSelf: 'flex-end' }}
              />
            )}
          </Grid>
        </Grid>
      )}
      {hasPermissions(
        [EPermission.VIEW_LIMITED_ANCILLARIES, EPermission.VIEW_ALL_ANCILLARIES],
        true,
      ) && (
        <Accordion
          title={t('ammunitionDetails.transactions.header')}
          name="transactions"
          key="transactions"
          expanded={isAllExpanded}
        >
          <AmmunitionTransactions
            inGroup={
              !!ammunitionDetail?.groupName &&
              ![EFirearmStateId.EXPORTED].includes(
                ammunitionDetail?.stateUniqueId as EFirearmStateId,
              )
            }
            ownerId={ammunitionDetail?.ownerId}
            ownerVisibilityUniqueId={ammunitionDetail?.ownerVisibilityUniqueId}
            keeperId={ammunitionDetail?.keeperId}
            keeperVisibilityUniqueId={ammunitionDetail?.keeperVisibilityUniqueId}
            stateUniqueId={ammunitionDetail?.stateUniqueId}
          />
        </Accordion>
      )}
      {hasPermissions([EPermission.MANAGE_COMMENTS_ANCILLARY]) && (
        <Accordion
          title={t('ammunitionDetails.comments.header')}
          name="comments"
          key="comments"
          expanded={isAllExpanded}
        >
          <AmmunitionComments id={id} />
        </Accordion>
      )}
      {hasPermissions([EPermission.MANAGE_ATTACHMENT_ANCILLARY]) && (
        <Accordion
          title={t('ammunitionDetails.attachments.header')}
          name="attachments"
          key="attachments"
          expanded={isAllExpanded}
        >
          <AmmunitionAttachments idAncillary={id} />
        </Accordion>
      )}
    </div>
  );
};

export default DetailAmmunition;
