import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
  ECustomFieldObjectType,
  ECustomFieldStatus,
  ECustomFieldType,
  ICustomFieldFormValues,
  ICustomFieldProperties,
  initialCustomFieldFormValues,
} from 'models/customField';
import useCustomFieldFormSchema from 'pages/CustomFieldsPage/schemaValidation';
import Grid from '@mui/material/Grid';
import { FormSelect } from 'components/molecules/FormSelect';
import { FormInput } from 'components/molecules/FormInput';
import { FormSwitch } from 'components/molecules/FormSwitch';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { useTranslations } from 'hooks/useTranslations';
import { useLang } from 'models/langContext';
import { StyledCFNameTranslation } from 'pages/CustomFieldsPage/CustomFieldForm/styles';
import { Typography } from '@mui/material';
import { useCustomFields } from 'pages/CustomFieldsPage/hooks';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import MaskForm from 'pages/CustomFieldsPage/CustomFieldForm/MaskForm';
import { useScroll } from 'hooks/useScroll';

const objectTypes = [
  { label: 'customFields.objectType.individual', value: ECustomFieldObjectType.LE_INDIVIDUAL },
  {
    label: 'customFields.objectType.organisation',
    value: ECustomFieldObjectType.LE_ORGANIZATION,
  },
  { label: 'customFields.objectType.firearm', value: ECustomFieldObjectType.FIREARM },
  { label: 'customFields.objectType.accessories', value: ECustomFieldObjectType.AN_ACCESSORY },
  { label: 'customFields.objectType.ammunition', value: ECustomFieldObjectType.AN_AMMUNITION },
  { label: 'customFields.objectType.components', value: ECustomFieldObjectType.AN_COMPONENT },
];

const fieldTypes = [
  { label: 'customFields.type.text.label', value: ECustomFieldType.TEXT },
  { label: 'customFields.type.boolean.label', value: ECustomFieldType.BOOL },
  { label: 'customFields.type.date.label', value: ECustomFieldType.DATE },
];

const fieldStatus = [
  { label: 'customFields.enabled.label', value: ECustomFieldStatus.ENABLED },
  { label: 'customFields.disabled.number.label', value: ECustomFieldStatus.DISABLED },
];

interface ICustomFieldForm {
  onCancel?: () => void;
  onSubmit: (arg: ICustomFieldFormValues) => void;
  initialFormValues?: ICustomFieldFormValues | null;
  isEditForm?: boolean;
}

const CustomFieldForm = ({
  onCancel,
  onSubmit,
  initialFormValues,
  isEditForm = false,
}: ICustomFieldForm) => {
  const { schema } = useCustomFieldFormSchema();
  const { t } = useTranslations();
  const { languages } = useLang();
  const { elRef, scrollToError } = useScroll();
  const { handleGetCustomFields, customFields, handleGetCustomFieldProperties } =
    useCustomFields();
  const [properties, setProperties] = useState<ICustomFieldProperties>();

  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [isNotTextCustomField, setIsNotTextCustomField] = useState(false);
  const [hasLimit, setHasLimit] = useState(false);
  const {
    control,
    setValue,
    handleSubmit,
    watch,
    reset,
    getValues,
    formState: { errors },
  } = useForm<ICustomFieldFormValues>({
    defaultValues: initialFormValues || initialCustomFieldFormValues,
    resolver: yupResolver(schema),
  });

  const watchCustomFieldType = watch('customFieldType');
  const watchObjectType = watch('objectType');

  useEffect(() => {
    if (watchObjectType && customFields && properties && !isEditForm) {
      setHasLimit(
        (customFields[watchObjectType as ECustomFieldObjectType] || []).length >=
          properties.maxPossibleCustomFields,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchObjectType]);

  useEffect(() => {
    (async () => {
      await handleGetCustomFields();
      const cfProperties = await handleGetCustomFieldProperties();
      if (cfProperties) {
        setProperties(cfProperties);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (watchCustomFieldType === ECustomFieldType.TEXT || watchCustomFieldType === '') {
      setIsNotTextCustomField(false);
    } else {
      reset({ ...getValues(), uniqueValue: false, inputMaskEnabled: false });
      setIsNotTextCustomField(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchCustomFieldType]);

  useEffect(() => {
    (async () => {
      scrollToError(errors);
    })();
  }, [errors]);

  const onSubmitForm = async (data: ICustomFieldFormValues) => {
    setIsSubmitLoading(true);
    onSubmit(data);
    setIsSubmitLoading(false);
  };

  const mapToTranslations = (
    options: { label: string; value: string }[],
  ): { label: string; value: string }[] =>
    options.map((option) => ({
      label: t(option.label),
      value: option.value,
    }));

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Grid container columnSpacing={2} ref={elRef}>
        <Grid container item xs={12}>
          <Grid item xs={12} lg={6}>
            <FormSelect
              name="objectType"
              label={`${t('customFields.card.objectType.label')}*`}
              options={mapToTranslations(objectTypes)}
              setValue={setValue}
              control={control}
              errors={errors}
              disabled={isEditForm}
            />
          </Grid>
        </Grid>

        {hasLimit && (
          <Grid item xs={12} sx={{ margin: '0 0 20px' }}>
            <Alert
              text={t('customFields.limitError.label').replace(
                '{0}',
                `${properties?.maxPossibleCustomFields ?? 5}`,
              )}
              variant={EAlertVariants.warning}
            />
          </Grid>
        )}

        <Grid item xs={12} sm={6} lg={3}>
          <FormInput
            name="name"
            label={`${t('customFields.card.name.label')}*`}
            setValue={setValue}
            control={control}
            errors={errors}
            disabled={hasLimit}
            charsLimit={20}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <FormSelect
            name="customFieldType"
            label={`${t('customFields.card.type.label')}*`}
            options={mapToTranslations(fieldTypes)}
            setValue={setValue}
            control={control}
            errors={errors}
            disabled={isEditForm || hasLimit}
          />
        </Grid>
        <Grid item xs={6} sm={6} lg={3}>
          <FormSwitch
            name="uniqueValue"
            label={t('customFields.uniqueValue.label')}
            control={control}
            errors={errors}
            disabled={isEditForm || isNotTextCustomField || hasLimit}
          />
        </Grid>
        <Grid item xs={6} sm={6} lg={3}>
          <FormSwitch
            name="inputMaskEnabled"
            label={t('customFields.mask.label')}
            control={control}
            errors={errors}
            disabled={isEditForm || isNotTextCustomField || hasLimit}
          />
        </Grid>

        <MaskForm
          control={control}
          errors={errors}
          watch={watch}
          show={watch('inputMaskEnabled')}
          isEditForm={isEditForm}
        />

        <Grid item xs={12} sm={6} lg={3}>
          <FormSelect
            name="enabledString"
            label={t('customFields.customFieldStatus.label')}
            options={mapToTranslations(fieldStatus)}
            control={control}
            setValue={setValue}
            errors={errors}
            disabled={hasLimit}
          />
        </Grid>
        <Grid item xs={6} sm={6} lg={3}>
          <FormSwitch
            name="mandatory"
            label={t('customFields.mandatoryField.label')}
            control={control}
            errors={errors}
            disabled={isEditForm || hasLimit}
          />
        </Grid>
        <Grid item xs={6} sm={6} lg={3}>
          <FormSwitch
            name="searchCriteria"
            label={t('customFields.search.label')}
            control={control}
            errors={errors}
            disabled={hasLimit}
          />
        </Grid>
        <Grid item xs={6} sm={6} lg={3}>
          <FormSwitch
            name="visibleInSearchResults"
            label={t('customFields.visibleInResults.label')}
            control={control}
            errors={errors}
            disabled={hasLimit}
          />
        </Grid>

        <Grid item container xs={12}>
          <StyledCFNameTranslation>
            {t('customFields.customFieldNameTranslation.label')}
          </StyledCFNameTranslation>
        </Grid>

        {languages.map((language) => (
          <Grid item xs={12} sm={12} md={6} xl={3} key={language.uniqueId}>
            <FormInput
              key={`translations.${language.uniqueId}`}
              name={`translations.${language.uniqueId}`}
              label={`${language.code.toUpperCase()} - ${t(
                'customFields.customFieldName.label',
              )}`}
              setValue={setValue}
              control={control}
              errors={errors}
              disabled={hasLimit}
            />
          </Grid>
        ))}

        <Grid item container xs={12}>
          <StyledCFNameTranslation>
            {t('customFields.placeholderTranslation.label')}
          </StyledCFNameTranslation>
        </Grid>

        {languages.map((language) => (
          <Grid item xs={12} sm={12} md={6} xl={3} key={language.uniqueId}>
            <FormInput
              key={`placeholder.${language.uniqueId}`}
              name={`placeholder.${language.uniqueId}`}
              label={`${language.code.toUpperCase()} - ${t('customFields.placeholder.label')}`}
              setValue={setValue}
              control={control}
              errors={errors}
              disabled={hasLimit}
            />
          </Grid>
        ))}

        <Grid item xs={12} container columnSpacing={3} rowGap={3} mt={2}>
          <Grid item xs={12} sm={6} lg={3}>
            <Button
              type="submit"
              fullWidth
              label={t('general.save.button')}
              id="save-button"
              variant={EButtonVariants.contained}
              isLoading={isSubmitLoading}
              disabled={hasLimit}
            />
          </Grid>

          {onCancel && (
            <Grid item xs={12} sm={6} lg={3}>
              <Button
                fullWidth
                label={t('general.cancel.button')}
                id="cancel-button"
                variant={EButtonVariants.outlined}
                onClick={onCancel}
              />
            </Grid>
          )}
        </Grid>
        <Grid item mt={2}>
          <Typography variant="caption">{t('general.mandatoryField.text')}</Typography>
        </Grid>
      </Grid>
    </form>
  );
};

export default CustomFieldForm;
