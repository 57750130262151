export const ActionTypes = {
  SET_ROLE_ALERT: 'SET_ROLE_ALERT',
  CLEAR_ROLE_ALERT: 'CLEAR_ROLE_ALERT',
  SET_DICTIONARY_ALERT: 'SET_DICTIONARY_ALERT',
  CLEAR_DICTIONARY_ALERT: 'CLEAR_DICTIONARY_ALERT',
  SET_USER_ALERT: 'SET_USER_ALERT',
  CLEAR_USER_ALERT: 'CLEAR_USER_ALERT',
  SET_AUTH_ALERT: 'SET_AUTH_ALERT',
  CLEAR_AUTH_ALERT: 'CLEAR_AUTH_ALERT',
  SET_LEGAL_ENTITY_ALERT: 'SET_LEGAL_ENTITY_ALERT',
  CLEAR_LEGAL_ENTITY_ALERT: 'CLEAR_LEGAL_ENTITY_ALERT',
  SET_LEGAL_ENTITY_ORG_ALERT: 'SET_LEGAL_ENTITY_ORG_ALERT',
  CLEAR_LEGAL_ENTITY_ORG_ALERT: 'CLEAR_LEGAL_ENTITY_ORG_ALERT',
  SET_SAME_PAGE_ALERT: 'SET_SAME_PAGE_ALERT',
  CLEAR_SAME_PAGE_ALERT: 'CLEAR_SAME_PAGE_ALERT',
  SET_NEXT_PAGE_ALERT: 'SET_NEXT_PAGE_ALERT',
  CLEAR_NEXT_PAGE_ALERT: 'CLEAR_NEXT_PAGE_ALERT',
  SET_TRANSACTION_ALERT: 'SET_TRANSACTION_ALERT',
  CLEAR_TRANSACTION_ALERT: 'CLEAR_TRANSACTION_ALERT',
  SET_ANCILLARY_ALERT: 'SET_ANCILLARY_ALERT',
  CLEAR_ANCILLARY_ALERT: 'CLEAR_ANCILLARY_ALERT',
  SET_TRUSTED_DEVICE_ALERT: 'SET_TRUSTED_DEVICE_ALERT',
  CLEAR_TRUSTED_DEVICE_ALERT: 'CLEAR_TRUSTED_DEVICE',
  SET_FIREARM_ALERT: 'SET_FIREARM_ALERT',
  CLEAR_FIREARM_ALERT: 'CLEAR_FIREARM_ALERT',
  SET_ACCESSORY_ALERT: 'SET_ACCESSORY_ALERT',
  CLEAR_ACCESSORY_ALERT: 'CLEAR_ACCESSORY_ALERT',
  SET_AMMUNITION_ALERT: 'SET_AMMUNITION_ALERT',
  CLEAR_AMMUNITION_ALERT: 'CLEAR_AMMUNITION_ALERT',
  SET_COMPONENT_ALERT: 'SET_COMPONENT_ALERT',
  CLEAR_COMPONENT_ALERT: 'CLEAR_COMPONENT_ALERT',
  SET_AUDIT_LOG_SAME_PAGE_ALERT: 'SET_AUDIT_LOG_SAME_PAGE_ALERT',
  CLEAR_AUDIT_LOG_SAME_PAGE_ALERT: 'CLEAR_AUDIT_LOG_SAME_PAGE_ALERT',
};
