import React from 'react';
import { slateToHtml, slateToHtmlConfig } from '@slate-serializers/html';
import { emptyFieldHolder } from 'components/molecules/CardList';

const config = {
  ...slateToHtmlConfig,
  elementMap: {
    ...slateToHtmlConfig.elementMap,
    'list-item': 'li',
    'numbered-list': 'ol',
  },
};

export const getHtmlText = (slate: any) => {
  try {
    const json = JSON.parse(slate);
    const html = slateToHtml(json, config);
    // eslint-disable-next-line react/no-danger
    return <div dangerouslySetInnerHTML={{ __html: html.replace(/\n/g, '<br />') }} />;
  } catch {
    return emptyFieldHolder(slate);
  }
};
