import * as yup from 'yup';
import { ERegExpTypes } from 'constants/RegExpTypes';

export const schema = yup.object({
  authCode: yup
    .string()
    .required('Please, enter 2FA code.')
    .matches(
      new RegExp(ERegExpTypes['4digits']),
      'Two Factor Authentication code is incorrect.',
    ),
});
