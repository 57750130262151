/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useRef, useState } from 'react';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { Marker, TileLayer, useMap } from 'react-leaflet';
import L from 'leaflet';
import { EIconTypes } from 'constants/Icons';
import { EButtonVariants } from 'constants/Buttons';
import 'leaflet-fullscreen/dist/Leaflet.fullscreen.js';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import SearchFilterStore from 'state/SearchFilterStore';
import { IAccessory, IAmmunition, IAncillary, IComponent } from 'models/ancillaries';
import { useDictionary } from 'models/dictionaryContext';
import { useLang } from 'models/langContext';
import MapLegalityDropdown from 'pages/Firearms/RegisteredFirearmsMapPage/MapLegalityDropdown';
import { LEGAL_ID } from 'pages/Firearms/RegisteredFirearmsMapPage/FirearmsLocationMap';
import AmmunitionDataSidebar from 'pages/AncillariesPage/AncillariesMap/AncillariesLocationMap/AmmunitionDataSidebar';
import ComponentDataSidebar, {
  ELegalityFilter,
} from 'pages/AncillariesPage/AncillariesMap/AncillariesLocationMap/ComponentDataSidebar';
import { EArtAncillaryTypeName } from 'constants/ArtAccessoryType';
import AccessoryDataSidebar from 'pages/AncillariesPage/AncillariesMap/AncillariesLocationMap/AncillaryDataSidebar';
import AncillariesDataSidebar from 'pages/AncillariesPage/AncillariesMap/AncillariesLocationMap/AllAncillariesDataSidebar';
import {
  ButtonFullScreen,
  ButtonZoomIn,
  ButtonZoomOut,
  StyledLocationMapWrapper,
  StyledMapContainer,
} from './style';

const MarkerIcons = {
  Legal: `data:image/svg+xml;utf8,${encodeURIComponent(
    '<svg width="32" height="39" viewBox="0 0 32 39" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
      '<path d="M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H20L16 39L12 32H4C1.79086 32 0 30.2091 0 28V4Z" fill="#127858"/>\n' +
      '<path d="M16.414 9H25C25.2652 9 25.5196 9.10536 25.7071 9.29289C25.8946 9.48043 26 9.73478 26 10V24C26 24.2652 25.8946 24.5196 25.7071 24.7071C25.5196 24.8946 25.2652 25 25 25H7C6.73478 25 6.48043 24.8946 6.29289 24.7071C6.10536 24.5196 6 24.2652 6 24V8C6 7.73478 6.10536 7.48043 6.29289 7.29289C6.48043 7.10536 6.73478 7 7 7H14.414L16.414 9ZM8 9V23H24V11H15.586L13.586 9H8ZM15 16V13H17V16H20V18H17V21H15V18H12V16H15Z" fill="white"/>\n' +
      '</svg>\n',
  )}`,
  LegalHover: `data:image/svg+xml;utf8,${encodeURIComponent(
    '<svg width="32" height="39" viewBox="5 3 36 39" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
      '<g filter="url(#filter0_d_9513_212182)">\n' +
      '<path d="M7 7C7 4.79086 8.79086 3 11 3H35C37.2091 3 39 4.79086 39 7V31C39 33.2091 37.2091 35 35 35H27L23 42L19 35H11C8.79086 35 7 33.2091 7 31V7Z" fill="white"/>\n' +
      '<path d="M21.9147 42.6202L23 44.5195L24.0853 42.6202L27.7254 36.25H35C37.8995 36.25 40.25 33.8995 40.25 31V7C40.25 4.1005 37.8995 1.75 35 1.75H11C8.10051 1.75 5.75 4.10051 5.75 7V31C5.75 33.8995 8.1005 36.25 11 36.25H18.2746L21.9147 42.6202Z" stroke="#127858" stroke-width="2.5"/>\n' +
      '</g>\n' +
      '<path d="M23.414 12H32C32.2652 12 32.5196 12.1054 32.7071 12.2929C32.8946 12.4804 33 12.7348 33 13V27C33 27.2652 32.8946 27.5196 32.7071 27.7071C32.5196 27.8946 32.2652 28 32 28H14C13.7348 28 13.4804 27.8946 13.2929 27.7071C13.1054 27.5196 13 27.2652 13 27V11C13 10.7348 13.1054 10.4804 13.2929 10.2929C13.4804 10.1054 13.7348 10 14 10H21.414L23.414 12ZM15 12V26H31V14H22.586L20.586 12H15ZM22 19V16H24V19H27V21H24V24H22V21H19V19H22Z" fill="#127858"/>\n' +
      '<defs>\n' +
      '<filter id="filter0_d_9513_212182" x="0.5" y="0.5" width="45" height="54.5389" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">\n' +
      '<feFlood flood-opacity="0" result="BackgroundImageFix"/>\n' +
      '<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>\n' +
      '<feOffset dy="4"/>\n' +
      '<feGaussianBlur stdDeviation="2"/>\n' +
      '<feComposite in2="hardAlpha" operator="out"/>\n' +
      '<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.301961 0 0 0 0 0.188235 0 0 0 0.3 0"/>\n' +
      '<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9513_212182"/>\n' +
      '<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9513_212182" result="shape"/>\n' +
      '</filter>\n' +
      '</defs>\n' +
      '</svg>\n',
  )}`,
  Illegal: `data:image/svg+xml;utf8,${encodeURIComponent(
    '<svg width="32" height="39" viewBox="0 0 32 39" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
      '<path d="M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H20L16 39L12 32H4C1.79086 32 0 30.2091 0 28V4Z" fill="#C41C2B"/>\n' +
      '<path d="M16.414 9H25C25.2652 9 25.5196 9.10536 25.7071 9.29289C25.8946 9.48043 26 9.73478 26 10V24C26 24.2652 25.8946 24.5196 25.7071 24.7071C25.5196 24.8946 25.2652 25 25 25H7C6.73478 25 6.48043 24.8946 6.29289 24.7071C6.10536 24.5196 6 24.2652 6 24V8C6 7.73478 6.10536 7.48043 6.29289 7.29289C6.48043 7.10536 6.73478 7 7 7H14.414L16.414 9ZM8 9V23H24V11H15.586L13.586 9H8ZM15 16V13H17V16H20V18H17V21H15V18H12V16H15Z" fill="white"/>\n' +
      '</svg>\n',
  )}`,
  IllegalHover: `data:image/svg+xml;utf8,${encodeURIComponent(
    '<svg width="32" height="39" viewBox="5 3 36 39" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
      '<g filter="url(#filter0_d_9513_212111)">\n' +
      '<path d="M7 7C7 4.79086 8.79086 3 11 3H35C37.2091 3 39 4.79086 39 7V31C39 33.2091 37.2091 35 35 35H27L23 42L19 35H11C8.79086 35 7 33.2091 7 31V7Z" fill="white"/>\n' +
      '<path d="M21.9147 42.6202L23 44.5195L24.0853 42.6202L27.7254 36.25H35C37.8995 36.25 40.25 33.8995 40.25 31V7C40.25 4.1005 37.8995 1.75 35 1.75H11C8.10051 1.75 5.75 4.10051 5.75 7V31C5.75 33.8995 8.1005 36.25 11 36.25H18.2746L21.9147 42.6202Z" stroke="#C41C2B" stroke-width="2.5"/>\n' +
      '</g>\n' +
      '<path d="M23.414 12H32C32.2652 12 32.5196 12.1054 32.7071 12.2929C32.8946 12.4804 33 12.7348 33 13V27C33 27.2652 32.8946 27.5196 32.7071 27.7071C32.5196 27.8946 32.2652 28 32 28H14C13.7348 28 13.4804 27.8946 13.2929 27.7071C13.1054 27.5196 13 27.2652 13 27V11C13 10.7348 13.1054 10.4804 13.2929 10.2929C13.4804 10.1054 13.7348 10 14 10H21.414L23.414 12ZM15 12V26H31V14H22.586L20.586 12H15ZM22 19V16H24V19H27V21H24V24H22V21H19V19H22Z" fill="#C41C2B"/>\n' +
      '<defs>\n' +
      '<filter id="filter0_d_9513_212111" x="0.5" y="0.5" width="45" height="54.5389" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">\n' +
      '<feFlood flood-opacity="0" result="BackgroundImageFix"/>\n' +
      '<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>\n' +
      '<feOffset dy="4"/>\n' +
      '<feGaussianBlur stdDeviation="2"/>\n' +
      '<feComposite in2="hardAlpha" operator="out"/>\n' +
      '<feColorMatrix type="matrix" values="0 0 0 0 0.658824 0 0 0 0 0 0 0 0 0 0.0980392 0 0 0 0.3 0"/>\n' +
      '<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9513_212111"/>\n' +
      '<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9513_212111" result="shape"/>\n' +
      '</filter>\n' +
      '</defs>\n' +
      '</svg>\n',
  )}`,
};

const AncillaryIcon = (isLegal: boolean) =>
  L.icon({
    iconUrl: isLegal ? MarkerIcons.Legal : MarkerIcons.Illegal,
    iconSize: [32, 39],
    iconAnchor: [16, 39],
    popupAnchor: [0, 0],
  });

interface IAncillariesLocationMap {
  accessories: IAccessory[];
  ammunition: IAmmunition[];
  components: IComponent[];
  ancillaries: IAncillary[];
  showLegalityBar: boolean;
  defaultLegality: string | undefined;
  isOnline: boolean;
  latitude?: number;
  longitude?: number;
  legalityUniqueId?: string;
  type?: string;

  updateLegality(id: string): void;
}

const AncillariesLocationMap = ({
  accessories,
  ammunition,
  components,
  ancillaries,
  updateLegality,
  defaultLegality = '',
  showLegalityBar = true,
  latitude,
  longitude,
  type,
  legalityUniqueId,
  isOnline,
}: IAncillariesLocationMap) => {
  (window as any).xAccessories = accessories; // fix for leaflet event function/no access to props
  (window as any).xAmmunition = ammunition; // fix for leaflet event function/no access to props
  (window as any).xComponents = components; // fix for leaflet event function/no access to props
  (window as any).xAncillaries = ancillaries; // fix for leaflet event function/no access to props
  const mapRef = useRef<L.Map | null>(null);
  const { legality, getLegality } = useDictionary();
  const [fullScreenState, setFullScreenState] = useState<boolean>(false);
  const legalityRef = useRef('');
  const { selectedLanguage } = useLang();

  const url = isOnline
    ? 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
    : `${process.env.REACT_APP_MAP_TILE_URL}tile/{z}/{x}/{y}.png`;

  useEffect(() => {
    (async function init() {
      legalityRef.current = defaultLegality;
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async function initDictionaries() {
      await getLegality();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  const ChangeView = () => {
    const map = useMap();
    if (map) {
      mapRef.current = map;
    }
    const bounds: [number, number][] = [];
    if (!latitude && !longitude) {
      if (type === EArtAncillaryTypeName.ACCESSORY.toLowerCase()) {
        accessories.map((accessory) => {
          if (accessory.latitude && accessory.longitude) {
            bounds.push([accessory.latitude, accessory.longitude]);
          }
          return false;
        });
      } else if (type === EArtAncillaryTypeName.AMMUNITION.toLowerCase()) {
        ammunition.map((singleAmmunition) => {
          if (singleAmmunition.latitude && singleAmmunition.longitude) {
            bounds.push([singleAmmunition.latitude, singleAmmunition.longitude]);
          }
          return false;
        });
      } else if (type === EArtAncillaryTypeName.COMPONENTS.toLowerCase()) {
        components.map((component) => {
          if (component.latitude && component.longitude) {
            bounds.push([component.latitude, component.longitude]);
          }
          return false;
        });
      }
    }

    if (latitude && longitude) {
      bounds.push([latitude, longitude]);
    }
    if (bounds.length) {
      map.fitBounds(bounds);
    }
    if (map.getZoom() > 10) {
      map.setZoom(10);
    }
    return null;
  };

  const zoomMap = (isZoomIn: boolean) => {
    if (mapRef.current) {
      mapRef.current.setZoom(
        isZoomIn ? mapRef.current.getZoom() + 1 : mapRef.current.getZoom() - 1,
      );
    }
  };

  const toggleFullScreen = () => {
    if (mapRef.current) {
      mapRef.current.toggleFullscreen();
      setFullScreenState((prevState) => !prevState);
    }
  };

  const updateCurrentLegality = (value: string) => {
    legalityRef.current = value;
    updateLegality(value);
  };

  const getClusterClassName = () => {
    if (!legalityRef.current) {
      return '';
    }
    return legalityRef.current === LEGAL_ID
      ? 'custom-marker-cluster-legal'
      : 'custom-marker-cluster-illegal';
  };

  const createClusterCustomIcon = (cluster: any) =>
    L.divIcon({
      html: `<span>${cluster.getChildCount()}</span>`,
      className: `custom-marker-cluster ${getClusterClassName()}`,
      iconSize: L.point(56, 56, true),
    });

  const showAccessoriesDataSidebar = (markers: any[]) => {
    const accessoriesIds = markers.map((marker) => marker.options.alt.split(',')[0]);
    const accessoriesList = (window as any).xAccessories;
    SearchFilterStore.setAccessories(
      accessoriesList.filter((accessory: IAccessory) =>
        accessoriesIds.includes(accessory.uniqueId),
      ),
    );
  };

  const showAmmunitionDataSidebar = (markers: any[]) => {
    const ammunitionIds = markers.map((marker) => marker.options.alt.split(',')[0]);
    const ammunitionList = (window as any).xAmmunition;
    SearchFilterStore.setAmmunition(
      ammunitionList.filter((singleAmmunition: IAmmunition) =>
        ammunitionIds.includes(singleAmmunition.uniqueId),
      ),
    );
  };

  const showComponentsDataSidebar = (markers: any[]) => {
    const componentIds = markers.map((marker) => marker.options.alt.split(',')[0]);
    const componentsList = (window as any).xComponents;
    SearchFilterStore.setComponents(
      componentsList.filter((component: IComponent) =>
        componentIds.includes(component.uniqueId),
      ),
    );
  };

  const showAncillariesDataSidebarByMarkers = (markers: any) => {
    const ancillariesIds = markers.length
      ? markers.map((marker: any) => marker.options.alt.split(',')[0])
      : markers.options.alt;
    const ancillariesList = (window as any).xAncillaries;
    SearchFilterStore.setAncillaries(
      ancillariesList.filter((ancillary: IAncillary) =>
        ancillariesIds.includes(ancillary.uniqueId),
      ),
    );
  };

  const showAncillariesDataSidebar = () => {
    const ancillariesList = (window as any).xAncillaries;
    SearchFilterStore.setAncillaries(ancillariesList);
  };

  const showSingleAccessoryDataSidebar = () => {
    const accessoriesList = (window as any).xAccessories;
    SearchFilterStore.setAccessories(accessoriesList);
  };

  const showSingleAmmunitionDataSidebar = () => {
    const ammunitionList = (window as any).xAmmunition;
    SearchFilterStore.setAmmunition(ammunitionList);
  };

  const showSingleComponentDataSidebar = () => {
    const componentsList = (window as any).xComponents;
    SearchFilterStore.setComponents(componentsList);
  };

  return (
    <StyledLocationMapWrapper>
      {accessories && <AccessoryDataSidebar />}
      {ammunition && <AmmunitionDataSidebar />}
      {components && <ComponentDataSidebar />}
      {ancillaries && <AncillariesDataSidebar />}
      <StyledMapContainer scrollWheelZoom zoomControl={false}>
        {showLegalityBar && (
          <MapLegalityDropdown
            updateLegality={updateCurrentLegality}
            legality={legality}
            label="ancillaries.ancillariesLocation.accessoryLegality"
          />
        )}
        <ChangeView />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url={url}
        />
        <MarkerClusterGroup
          chunkedLoading
          showCoverageOnHover={false}
          iconCreateFunction={createClusterCustomIcon}
          onClick={(e: any) => {
            if (type === EArtAncillaryTypeName.ACCESSORY.toLowerCase()) {
              showAccessoriesDataSidebar(e.layer.getAllChildMarkers());
            } else if (type === EArtAncillaryTypeName.AMMUNITION.toLowerCase()) {
              showAmmunitionDataSidebar(e.layer.getAllChildMarkers());
            } else if (type === EArtAncillaryTypeName.COMPONENTS.toLowerCase()) {
              showComponentsDataSidebar(e.layer.getAllChildMarkers());
            } else {
              showAncillariesDataSidebarByMarkers(e.layer.getAllChildMarkers());
            }
          }}
        >
          {!latitude &&
            type === EArtAncillaryTypeName.ACCESSORY.toLowerCase() &&
            accessories.map(
              (accessory) =>
                !!(accessory.latitude && accessory.longitude) && (
                  <Marker
                    key={accessory.uniqueId}
                    alt={`${accessory.uniqueId},${accessory.legalityUniqueId}`}
                    position={[accessory.latitude, accessory.longitude]}
                    icon={AncillaryIcon(accessory.legalityUniqueId === ELegalityFilter.legal)}
                    eventHandlers={{
                      click: (e) => {
                        showAccessoriesDataSidebar([e.target]);
                      },
                      mouseover: (e) => {
                        e.target.setIcon(
                          L.icon({
                            iconUrl:
                              e.target.options.alt.split(',')?.[1] === LEGAL_ID
                                ? MarkerIcons.LegalHover
                                : MarkerIcons.IllegalHover,
                            iconSize: [32, 39],
                            iconAnchor: [16, 39],
                            popupAnchor: [0, 0],
                          }),
                        );
                      },
                      mouseout: (e) => {
                        e.target.setIcon(
                          L.icon({
                            iconUrl:
                              e.target.options.alt.split(',')?.[1] === LEGAL_ID
                                ? MarkerIcons.Legal
                                : MarkerIcons.Illegal,
                            iconSize: [32, 39],
                            iconAnchor: [16, 39],
                            popupAnchor: [0, 0],
                          }),
                        );
                      },
                    }}
                  />
                ),
            )}
          {!latitude &&
            type === EArtAncillaryTypeName.AMMUNITION.toLowerCase() &&
            ammunition.map(
              (singleAmmunition) =>
                !!(singleAmmunition.latitude && singleAmmunition.longitude) && (
                  <Marker
                    key={singleAmmunition.uniqueId}
                    alt={`${singleAmmunition.uniqueId},${singleAmmunition.legalityUniqueId}`}
                    position={[singleAmmunition.latitude, singleAmmunition.longitude]}
                    icon={AncillaryIcon(
                      singleAmmunition.legalityUniqueId ===
                        'd90f05bf-6baf-4f86-9ca3-9501af6901ea',
                    )}
                    eventHandlers={{
                      click: (e) => {
                        showAmmunitionDataSidebar([e.target]);
                      },
                      mouseover: (e) => {
                        e.target.setIcon(
                          L.icon({
                            iconUrl:
                              e.target.options.alt.split(',')?.[1] === LEGAL_ID
                                ? MarkerIcons.LegalHover
                                : MarkerIcons.IllegalHover,
                            iconSize: [32, 39],
                            iconAnchor: [16, 39],
                            popupAnchor: [0, 0],
                          }),
                        );
                      },
                      mouseout: (e) => {
                        e.target.setIcon(
                          L.icon({
                            iconUrl:
                              e.target.options.alt.split(',')?.[1] === LEGAL_ID
                                ? MarkerIcons.Legal
                                : MarkerIcons.Illegal,
                            iconSize: [32, 39],
                            iconAnchor: [16, 39],
                            popupAnchor: [0, 0],
                          }),
                        );
                      },
                    }}
                  />
                ),
            )}
          {!latitude &&
            type === EArtAncillaryTypeName.COMPONENTS.toLowerCase() &&
            components.map(
              (component) =>
                !!(component.latitude && component.longitude) && (
                  <Marker
                    key={component.uniqueId}
                    alt={`${component.uniqueId},${component.legalityUniqueId}`}
                    position={[component.latitude, component.longitude]}
                    icon={AncillaryIcon(component.legalityUniqueId === ELegalityFilter.legal)}
                    eventHandlers={{
                      click: (e) => {
                        showComponentsDataSidebar([e.target]);
                      },
                      mouseover: (e) => {
                        e.target.setIcon(
                          L.icon({
                            iconUrl:
                              e.target.options.alt.split(',')?.[1] === LEGAL_ID
                                ? MarkerIcons.LegalHover
                                : MarkerIcons.IllegalHover,
                            iconSize: [32, 39],
                            iconAnchor: [16, 39],
                            popupAnchor: [0, 0],
                          }),
                        );
                      },
                      mouseout: (e) => {
                        e.target.setIcon(
                          L.icon({
                            iconUrl:
                              e.target.options.alt.split(',')?.[1] === LEGAL_ID
                                ? MarkerIcons.Legal
                                : MarkerIcons.Illegal,
                            iconSize: [32, 39],
                            iconAnchor: [16, 39],
                            popupAnchor: [0, 0],
                          }),
                        );
                      },
                    }}
                  />
                ),
            )}
          {latitude && longitude && !ancillaries && (
            <Marker
              key={`${latitude}-${longitude}`}
              alt={`$${latitude}-${longitude}`}
              position={[latitude, longitude]}
              icon={AncillaryIcon(legalityUniqueId === ELegalityFilter.legal)}
              eventHandlers={{
                click: () => {
                  if (type === EArtAncillaryTypeName.ACCESSORY.toLowerCase()) {
                    showSingleAccessoryDataSidebar();
                  } else if (type === EArtAncillaryTypeName.AMMUNITION.toLowerCase()) {
                    showSingleAmmunitionDataSidebar();
                  } else if (type === EArtAncillaryTypeName.COMPONENTS.toLowerCase()) {
                    showSingleComponentDataSidebar();
                  } else {
                    showAncillariesDataSidebar();
                  }
                },
                mouseover: (e) => {
                  e.target.setIcon(
                    L.icon({
                      iconUrl:
                        e.target.options.alt.split(',')?.[1] === LEGAL_ID
                          ? MarkerIcons.LegalHover
                          : MarkerIcons.IllegalHover,
                      iconSize: [32, 39],
                      iconAnchor: [16, 39],
                      popupAnchor: [0, 0],
                    }),
                  );
                },
                mouseout: (e) => {
                  e.target.setIcon(
                    L.icon({
                      iconUrl:
                        e.target.options.alt.split(',')?.[1] === LEGAL_ID
                          ? MarkerIcons.Legal
                          : MarkerIcons.Illegal,
                      iconSize: [32, 39],
                      iconAnchor: [16, 39],
                      popupAnchor: [0, 0],
                    }),
                  );
                },
              }}
            />
          )}
          {latitude && longitude && ancillaries && (
            <>
              {ancillaries.map((ancillary) => (
                <Marker
                  key={ancillary.uniqueId}
                  alt={ancillary.uniqueId}
                  position={[latitude, longitude]}
                  icon={AncillaryIcon(legalityUniqueId === ELegalityFilter.legal)}
                  eventHandlers={{
                    click: (e) => {
                      showAncillariesDataSidebarByMarkers(e.target);
                    },
                    mouseover: (e) => {
                      e.target.setIcon(
                        L.icon({
                          iconUrl:
                            legalityUniqueId === LEGAL_ID
                              ? MarkerIcons.LegalHover
                              : MarkerIcons.IllegalHover,
                          iconSize: [32, 39],
                          iconAnchor: [16, 39],
                          popupAnchor: [0, 0],
                        }),
                      );
                    },
                    mouseout: (e) => {
                      e.target.setIcon(
                        L.icon({
                          iconUrl:
                            legalityUniqueId === LEGAL_ID
                              ? MarkerIcons.Legal
                              : MarkerIcons.Illegal,
                          iconSize: [32, 39],
                          iconAnchor: [16, 39],
                          popupAnchor: [0, 0],
                        }),
                      );
                    },
                  }}
                />
              ))}
            </>
          )}
        </MarkerClusterGroup>
        <ButtonZoomIn
          icon={EIconTypes.plus1}
          variant={EButtonVariants.outlined}
          onClick={() => zoomMap(true)}
        />
        <ButtonZoomOut
          icon={EIconTypes.minus}
          variant={EButtonVariants.outlined}
          onClick={() => zoomMap(false)}
        />
        <ButtonFullScreen
          icon={fullScreenState ? EIconTypes.fullScreenExit : EIconTypes.fullScreen}
          variant={EButtonVariants.outlined}
          onClick={() => toggleFullScreen()}
        />
      </StyledMapContainer>
    </StyledLocationMapWrapper>
  );
};

export { AncillariesLocationMap };
