import React, { useEffect, useState } from 'react';
import { ERouteLinks } from 'models/route';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { useParams } from 'react-router-dom';
import { StyledPageTitle } from 'theme/styles';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { getRegisteredFirearm } from 'requests/firearm';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import { useQuery } from 'hooks/useQuery';
import { ELegalEntityTypes } from 'constants/LegalEntityTypes';
import { useTranslations } from 'hooks/useTranslations';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { IRegisteredFirearmData } from 'models/registeredFirearm';
import { Loader } from 'components/atoms/Loader';
import { RegisteredFirearmDetails } from 'components/organisms/RegisteredFirearmDetails';
import { ActionTypes } from 'state/actions/alert';
import { useAlert } from 'models/alertContext';
import { usePermission } from 'hooks/usePermission';
import { EPermission } from 'models/permissions';

const LegalEntityGroupDeleteFirearmPage = () => {
  const [isFirearmLoading, setIsFirearmLoading] = useState<boolean>(false);
  const { setAlert, clearAlert, samePageAlert } = useAlert();
  const { id, groupId, firearmId } = useParams<{
    id: string;
    groupId: string;
    firearmId: string;
  }>();

  const query = useQuery();
  const [firearm, setFirearm] = useState<IRegisteredFirearmData | null>();
  const { t } = useTranslations();

  const { hasPermissions } = usePermission();

  const { handleRemoveLEGroupFirearm } = useLegalEntities();

  const { navigateToGroupDetails } = useLegalEntities();

  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);

  const type: string = query.get('type') || '';

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    setIsFirearmLoading(true);
    (async function getFirearmDataInit() {
      if (firearmId && hasPermissions(EPermission.VIEW_FIREARMS)) {
        const response = await getRegisteredFirearm(firearmId);
        if (response) {
          setFirearm(response);
        }
      }
    })();
    setIsFirearmLoading(false);
  }, [firearmId]);

  const onDelete = async () => {
    setIsDeleteLoading(true);
    try {
      if (id && groupId && firearmId) {
        const result = await handleRemoveLEGroupFirearm(id, groupId, firearmId);
        if (result) {
          navigateToGroupDetails();
        }
      }
    } catch (e: any) {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: t('deleteFirearmFromGroup.error.alert'),
        variant: EAlertVariants.error,
      });
      console.error(e);
    }
    setIsDeleteLoading(false);
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.legalEntity.name'), route: ERouteLinks.LegalEntities },
          {
            label:
              type === ELegalEntityTypes.individual
                ? t('legalEntities.individual.accordion.label')
                : t('legalEntities.organization.accordion.label'),
            route: `${ERouteLinks.LegalEntities}?expand=${
              type === ELegalEntityTypes.individual
                ? ELegalEntityTypes.individual
                : ELegalEntityTypes.organisation
            }`,
          },
          {
            label: t('legalEntityDetails.legalEntityDetails.text'),
            route: `${ERouteLinks.ViewLegalEntity.replace(
              ':id',
              id as string,
            )}?type=${type}&expand=groups`,
          },
          {
            label: t('groupDetails.header'),
            route: `${ERouteLinks.ViewLegalEntityGroup.replace(':id', id as string).replace(
              ':groupId',
              groupId as string,
            )}?type=${type}&expand=groups`,
          },
          t('deleteFirearmFromGroup.header'),
        ]}
      />
      <>
        <StyledPageTitle variant="h4">{t('deleteFirearmFromGroup.header')}</StyledPageTitle>

        <Box my={3}>
          <Alert
            text={t('deleteFirearmFromGroup.info.text')}
            variant={EAlertVariants.warning}
          />
        </Box>

        {isFirearmLoading ? (
          <Loader isLoading={isFirearmLoading} />
        ) : (
          firearm && (
            <RegisteredFirearmDetails
              firearm={firearm}
              hideDisableButton={true}
              disableActions={true}
            />
          )
        )}
        {samePageAlert && (
          <Box mt={2}>
            <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
          </Box>
        )}

        <Grid container sx={{ mt: 2 }} spacing={2}>
          <Grid item xs={6} lg={3}>
            <Button
              fullWidth
              id="delete-group-yes-button"
              label={t('general.yes.button')}
              variant={EButtonVariants.contained}
              sx={{ mr: 1 }}
              onClick={async () => onDelete()}
              isLoading={isDeleteLoading}
            />
          </Grid>

          <Grid item xs={6} lg={3}>
            <Button
              fullWidth
              id="delete-group-no-button"
              label={t('general.no.button')}
              variant={EButtonVariants.outlined}
              onClick={navigateToGroupDetails}
            />
          </Grid>
        </Grid>
      </>
    </>
  );
};

export { LegalEntityGroupDeleteFirearmPage };
