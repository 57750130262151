import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import {
  ChartNoDataGridItem,
  DashboardBarChartWrapper,
  StyledBar,
} from 'pages/DashboardPage/BarCharts/styles';
import Grid from '@mui/material/Grid';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { ERouteLinks } from 'models/route';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'hooks/useTranslations';
import { EDashboardSortType, IDashboardChartData } from 'models/dashboard';
import { getFirearmsStates } from 'requests/dashboard';
import { useDictionary } from 'models/dictionaryContext';
import { useLang } from 'models/langContext';
import { debounce } from 'pages/DashboardPage/BarCharts/helpers';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';

const dataColors: string[] = [
  '#0e3156',
  '#7da7cc',
  '#fad164',
  '#98a3b4',
  '#d89b10',
  '#54b599',
  '#d52631',
];

interface IFirearmsStatsChart {
  sortType: EDashboardSortType;
  setLoader: React.Dispatch<React.SetStateAction<boolean>>;
}

const FirearmsStatesChart = ({ sortType, setLoader }: IFirearmsStatsChart) => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const [data, setData] = useState<IDashboardChartData>({ chartValues: [] });
  const maxValue = data ? Math.max(...data.chartValues.map((o) => o.value)) : 0;
  const totalValue = data
    ? data.chartValues.reduce((total, current) => total + current.value, 0)
    : 0;
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const { state, getState } = useDictionary();
  const { selectedLanguage } = useLang();
  const [noData, setNoData] = useState(false);
  const [noDataText, setNoDataText] = useState<string>('');
  const { isDesktop } = useThemeBreakpoint();

  useEffect(() => {
    const debouncedHandleResize = debounce(() => {
      setWindowWidth(window.innerWidth);
    }, 20);

    window.addEventListener('resize', debouncedHandleResize);

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  });

  useEffect(() => {
    setLoader(true);
    getFirearmsStates(sortType).then((response) => {
      if (response) {
        response.chartValues = response.chartValues.sort((a, b) => b.value - a.value);
        setData(response);
        if (response.chartValues.every((item) => item.value === 0)) {
          setNoData(true);
        } else {
          setNoData(false);
        }
      } else {
        setNoData(true);
      }
      setLoader(false);
    });
  }, [selectedLanguage, sortType]);

  useEffect(() => {
    getState();
  }, [selectedLanguage]);

  useEffect(() => {
    switch (sortType) {
      case EDashboardSortType.Day: {
        setNoDataText('dashboard.chart.firearms.states.noDataToday');
        break;
      }
      case EDashboardSortType.Week: {
        setNoDataText('dashboard.chart.firearms.states.noData7days');
        break;
      }
      case EDashboardSortType.Month: {
        setNoDataText('dashboard.chart.firearms.states.noData30days');
        break;
      }
      case EDashboardSortType.Year: {
        setNoDataText('dashboard.chart.firearms.states.noDataYear');
        break;
      }
      case EDashboardSortType.All: {
        setNoDataText('dashboard.chart.firearms.states.noDataAll');
        break;
      }
      default: {
        setNoDataText('dashboard.chart.firearms.states.noDataAll');
        break;
      }
    }
  }, [sortType]);

  const chartRender = (
    <Grid
      container
      direction="column"
      alignItems="center"
      alignContent="center"
      spacing={1}
      sx={{ padding: '10px 10px' }}
    >
      {!!data &&
        data.chartValues.map((item, index) => (
          <Grid container item spacing={2} key={`${item.label}-${item.value}`}>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={5} sx={{ textAlign: 'right' }}>
              <Typography variant="body2">
                {state!.find((s) => s.uniqueId === item.label)?.name}
              </Typography>
            </Grid>
            <Grid item sm={1} xs={2} sx={{ textAlign: 'right' }}>
              <Typography variant="subtitle2">{item.value}</Typography>
            </Grid>
            <Grid item xl={7} lg={7} md={7} sm={7} xs={5}>
              <StyledBar
                color={dataColors?.[index]}
                width={((item.value / maxValue) * windowWidth) / (isDesktop ? 5 : 4)}
              />
            </Grid>
          </Grid>
        ))}

      <Grid container item spacing={2}>
        <Grid item md={3} xs={4} sx={{ textAlign: 'right' }}>
          <Typography variant="body2">
            {t('dashboard.chart.firearms.states.total').toUpperCase()}
          </Typography>
        </Grid>
        <Grid item md={1} xs={2} sx={{ textAlign: 'right' }}>
          <Typography variant="subtitle2">{totalValue}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );

  const noDataRender = (
    <ChartNoDataGridItem container item xs={12}>
      <Typography variant="body2">{t(noDataText)}</Typography>
    </ChartNoDataGridItem>
  );

  return (
    <DashboardBarChartWrapper>
      <Box
        sx={
          noData
            ? {
                minHeight: '120px',
              }
            : {
                minHeight: '120px',
              }
        }
      >
        <Typography variant="subtitle1" sx={{ textAlign: 'center', marginBottom: 2 }}>
          {t('dashboard.chart.firearms.states.label')}
        </Typography>
        {noData ? noDataRender : chartRender}
      </Box>
      <Box sx={{ padding: '20px 10px 0 10px' }}>
        <Button
          variant={EButtonVariants.outlined}
          fullWidth={true}
          label={t('dashboard.chart.firearms.states.seeFirearms')}
          onClick={() => navigate(ERouteLinks.Firearms)}
          size={EButtonSizes.small}
        />
      </Box>
    </DashboardBarChartWrapper>
  );
};

export default FirearmsStatesChart;
