import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { useNavigate, useParams } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { StyledPageTitle } from 'theme/styles';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { colors } from 'theme/colors';
import { INotificationDetails, ISearchFilterValue } from 'models/notification';
import { getNotification } from 'requests/notification';
import {
  StyledFilterChipWrapper,
  StyledPresetWrapper,
  StyledSelectedFiltersWrapper,
} from 'pages/MyFiltersPage/styles';
import { FilterChip } from 'components/atoms/FilterChip';
import { formatDate } from 'utils/date';
import { useTranslations } from 'hooks/useTranslations';
import {
  ENotificationFilterType,
  getLabelsByFilterType,
  mapFilterTypeToLabel,
  prepareNotificationFiltersArray,
  sortFiltersArray,
} from 'pages/NotificationsPage/helpers';
import { useDictionary } from 'models/dictionaryContext';
import { useLang } from 'models/langContext';
import { IDictionary } from 'models/dictionary';
import {
  EAncillaryFilterTypes,
  EArtAncillaryExpandType,
  EArtAncillaryType,
} from 'constants/ArtAccessoryType';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { useNotifications } from '../hooks';

const shortDateFormatFields = [
  'birthdayFrom',
  'birthdayTo',
  'manufactureDateFrom',
  'manufactureDateTo',
  'expiryDateFrom',
  'expiryDateTo',
];

const midDateFormatFields = [
  'transactionTimeFrom',
  'transactionTimeTo',
  'createTimeFrom',
  'createTimeTo',
  'expirationTimeFrom',
  'expirationTimeTo',
];

const ViewNotificationPage = () => {
  const { t } = useTranslations();
  const { id = '' } = useParams<{ id: string }>();
  const { selectedLanguage } = useLang();
  const navigate = useNavigate();
  const [notification, setNotification] = useState<INotificationDetails | null>();
  const [labels, setLabels] = useState<any>({});
  const [filtersArray, setFiltersArray] = useState<ISearchFilterValue[]>([]);
  const { handleMarkAsReadNotifications } = useNotifications();
  const { longDateFormat, midDateFormat, shortDateFormat } = useGlobalProperty();
  const [ancillaryType, setAncillaryType] = useState<EArtAncillaryType | undefined>(undefined);
  const {
    legalVisibility,
    getLegalVisibility,
    gender,
    getGender,
    legalEntityPrefix,
    getLegalEntityPrefix,
    organizationType,
    getOrganizationType,
    state,
    getState,
    legality,
    getLegality,
    transactionType,
    getTransactionType,
    activity,
    getActivity,
    artAncillaryOptions,
    getArtAncillaryOptions,
    artAccessoryType,
    getArtAccessoryType,
    artAccessoryManufacturerFlashEliminator,
    getArtAccessoryManufacturerFlashEliminator,
    artAccessoryManufacturerLaserLightModule,
    getArtAccessoryManufacturerLaserLightModule,
    artAccessoryManufacturerMagazine,
    getArtAccessoryManufacturerMagazine,
    artAccessoryManufacturerOpticalSight,
    getArtAccessoryManufacturerOpticalSight,
    artAccessoryManufacturerSuppressor,
    getArtAccessoryManufacturerSuppressor,
    artAccessoryCalibre,
    getArtAccessoryCalibre,
    artAccessoryComposition,
    getArtAccessoryComposition,
    artAccessoryHostType,
    getArtAccessoryHostType,
    artAccessoryLightColour,
    getArtAccessoryLightColour,
    artAccessoryMagnification,
    getArtAccessoryMagnification,
    artAccessoryReticle,
    getArtAccessoryReticle,
    artAccessoryUtility,
    getArtAccessoryUtility,
    artAccessoryProofHouse,
    getArtAccessoryProofHouse,
    artAmmunitionType,
    artAmmunitionManufacturer,
    artAmmunitionFactory,
    artAmmunitionCalibreGauge,
    artAmmunitionFunctionalType,
    artAmmunitionCartridgeComposition,
    artAmmunitionColourOfPrimerSealant,
    artAmmunitionProjectileShape,
    artAmmunitionShotSize,
    artAmmunitionMunitionType,
    artAmmunitionMunitionVelocity,
    getArtAmmunitionType,
    getArtAmmunitionManufacturer,
    getArtAmmunitionFactory,
    getArtAmmunitionCalibreGauge,
    getArtAmmunitionFunctionalType,
    getArtAmmunitionCartridgeComposition,
    getArtAmmunitionColourOfPrimerSealant,
    getArtAmmunitionProjectileShape,
    getArtAmmunitionShotSize,
    getArtAmmunitionMunitionType,
    getArtAmmunitionMunitionVelocity,
    artComponentCalibre,
    artComponentCapacity,
    artComposition,
    artComponentManufacturer,
    artComponentType,
    artComponentProofHouse,
    getArtComponentCalibre,
    getArtComponentCapacity,
    getArtComposition,
    getArtComponentManufacturer,
    getArtComponentType,
    getArtComponentProofHouse,
  } = useDictionary();

  useEffect(() => {
    (async function getNotificationData() {
      if (id) {
        const response = await getNotification(id);
        if (response) {
          const responseWithProperDates = response.values.map((filter) => {
            if (shortDateFormatFields.includes(filter.key)) {
              return { ...filter, value: formatDate(filter.value, shortDateFormat) };
            }
            if (midDateFormatFields.includes(filter.key)) {
              return { ...filter, value: formatDate(filter.value, midDateFormat) };
            }
            return filter;
          });
          setNotification({
            ...response,
            values: responseWithProperDates,
          });
          setAncillaryType(
            response.values.filter((val) => val.key === 'ancillaryDictionaryUniqueId')[0]
              ?.value as EArtAncillaryType,
          );
          await handleMarkAsReadNotifications([response.uniqueId]);
        }
      }
    })();
  }, [id, selectedLanguage]);

  useEffect(() => {
    if (notification) {
      setLabels(getLabelsByFilterType(notification.filterType));
      switch (notification.filterType.toLowerCase()) {
        case ENotificationFilterType.individual:
          getGender();
          getLegalVisibility();
          getLegalEntityPrefix();
          break;
        case ENotificationFilterType.organization:
          getOrganizationType();
          getLegalVisibility();
          break;
        case ENotificationFilterType.firearm:
          getState();
          getLegality();
          break;
        case ENotificationFilterType.transaction:
          getTransactionType();
          getLegality();
          getActivity();
          break;
        case ENotificationFilterType.ancillaries:
          getState();
          getLegality();
          getArtAncillaryOptions();
          getArtAccessoryType();
          getArtAccessoryManufacturerFlashEliminator();
          getArtAccessoryManufacturerLaserLightModule();
          getArtAccessoryManufacturerMagazine();
          getArtAccessoryManufacturerOpticalSight();
          getArtAccessoryManufacturerSuppressor();
          getArtAccessoryCalibre();
          getArtAccessoryComposition();
          getArtAccessoryHostType();
          getArtAccessoryLightColour();
          getArtAccessoryMagnification();
          getArtAccessoryReticle();
          getArtAccessoryUtility();
          getArtAccessoryProofHouse();
          getArtAmmunitionType();
          getArtAmmunitionManufacturer();
          getArtAmmunitionFactory();
          getArtAmmunitionCalibreGauge();
          getArtAmmunitionFunctionalType();
          getArtAmmunitionCartridgeComposition();
          getArtAmmunitionColourOfPrimerSealant();
          getArtAmmunitionProjectileShape();
          getArtAmmunitionShotSize();
          getArtAmmunitionMunitionType();
          getArtAmmunitionMunitionVelocity();
          getArtComponentCalibre();
          getArtComponentCapacity();
          getArtComposition();
          getArtComponentManufacturer();
          getArtComponentType();
          getArtComponentProofHouse();
          break;
        default:
          break;
      }
    }
  }, [notification, selectedLanguage]);

  const mergeDictionaries = (dictionaries: (IDictionary[] | null)[]): IDictionary[] => {
    let mergedDictionaries: IDictionary[] = [];
    dictionaries.forEach((dictionaryArray) => {
      if (dictionaryArray) {
        mergedDictionaries = mergedDictionaries.concat(dictionaryArray);
      }
    });
    return mergedDictionaries;
  };

  const prepareFiltersArraysDictionaries = (): {
    [key: string]: IDictionary[] | null;
  } | null => {
    switch (notification?.filterType.toLowerCase()) {
      case ENotificationFilterType.individual:
        return {
          genderUniqueId: gender,
          visibilityUniqueId: legalVisibility,
          prefixGroupUniqueId: legalEntityPrefix,
        };
      case ENotificationFilterType.organization:
        return {
          visibilityUniqueId: legalVisibility,
          organizationTypeUniqueId: organizationType,
        };
      case ENotificationFilterType.firearm:
        return {
          stateUniqueId: state,
          legalityUniqueId: legality,
        };
      case ENotificationFilterType.transaction:
        return {
          transactionTypeUniqueId: transactionType,
          activityUniqueId: activity,
          legalityUniqueId: legality,
        };
      case ENotificationFilterType.ancillaries:
        return {
          activityUniqueId: activity,
          legalityUniqueId: legality,
          stateUniqueId: state,
          ancillaryDictionaryUniqueId: artAncillaryOptions,
          ancillaryTypeUniqueId: mergeDictionaries([
            artAccessoryType,
            artAmmunitionType,
            artComponentType,
          ]),
          manufacturerUniqueId: mergeDictionaries([
            artAccessoryManufacturerMagazine,
            artAccessoryManufacturerOpticalSight,
            artAccessoryManufacturerSuppressor,
            artAccessoryManufacturerFlashEliminator,
            artAccessoryManufacturerLaserLightModule,
            artAmmunitionManufacturer,
            artComponentManufacturer,
          ]),
          calibreUniqueId: mergeDictionaries([
            artAccessoryCalibre,
            artAmmunitionCalibreGauge,
            artComponentCalibre,
          ]),
          hostTypeUniqueId: artAccessoryHostType,
          magnificationUniqueId: artAccessoryMagnification,
          utilityUniqueId: artAccessoryUtility,
          reticleUniqueId: artAccessoryReticle,
          lightColourUniqueId: artAccessoryLightColour,
          compositionUniqueId: mergeDictionaries([artAccessoryComposition, artComposition]),
          proofHouseUniqueId: mergeDictionaries([
            artAccessoryProofHouse,
            artComponentProofHouse,
          ]),
          accessoryLegality: legality,
          factoryUniqueId: artAmmunitionFactory,
          functionalTypeUniqueId: artAmmunitionFunctionalType,
          cartridgeCompositionUniqueId: artAmmunitionCartridgeComposition,
          colourOfPrimerSealantUniqueId: artAmmunitionColourOfPrimerSealant,
          projectileShapeUniqueId: artAmmunitionProjectileShape,
          shotSizeUniqueId: artAmmunitionShotSize,
          munitionTypeUniqueId: artAmmunitionMunitionType,
          munitionVelocityUniqueId: artAmmunitionMunitionVelocity,
          componentCapacityUniqueId: artComponentCapacity,
        };
      default:
        return null;
    }
  };

  useEffect(() => {
    const sortedFilters = sortFiltersArray(notification?.values, notification?.filterType);
    const preparedFilters = prepareNotificationFiltersArray(
      sortedFilters,
      prepareFiltersArraysDictionaries(),
    );
    setFiltersArray(preparedFilters);
  }, [
    notification,
    transactionType,
    legality,
    activity,
    state,
    gender,
    legalEntityPrefix,
    legalVisibility,
    organizationType,
    state,
    legality,
    artAncillaryOptions,
    artAccessoryType,
    artAccessoryManufacturerFlashEliminator,
    artAccessoryManufacturerLaserLightModule,
    artAccessoryManufacturerMagazine,
    artAccessoryManufacturerOpticalSight,
    artAccessoryManufacturerSuppressor,
    artAccessoryCalibre,
    artAccessoryComposition,
    artAccessoryHostType,
    artAccessoryLightColour,
    artAccessoryMagnification,
    artAccessoryReticle,
    artAccessoryUtility,
    artAccessoryProofHouse,
    artAmmunitionType,
    artAmmunitionManufacturer,
    artAmmunitionFactory,
    artAmmunitionCalibreGauge,
    artAmmunitionFunctionalType,
    artAmmunitionCartridgeComposition,
    artAmmunitionColourOfPrimerSealant,
    artAmmunitionProjectileShape,
    artAmmunitionShotSize,
    artAmmunitionMunitionType,
    artAmmunitionMunitionVelocity,
    artComponentCalibre,
    artComponentCapacity,
    artComposition,
    artComponentManufacturer,
    artComponentType,
    artComponentProofHouse,
  ]);

  const handleShowResults = () => {
    switch (notification?.filterType.toLowerCase()) {
      case ENotificationFilterType.individual:
        navigate(`${ERouteLinks.LegalEntities}?expand=individual`, {
          state: { presetIndividual: notification?.filterUniqueId },
        });
        break;
      case ENotificationFilterType.organization:
        navigate(`${ERouteLinks.LegalEntities}?expand=organisation`, {
          state: { presetOrganisation: notification?.filterUniqueId },
        });
        break;
      case ENotificationFilterType.firearm:
        navigate(`${ERouteLinks.Firearms}?expand=registered-firearms`, {
          state: { presetFirearm: notification?.filterUniqueId },
        });
        break;
      case ENotificationFilterType.transaction:
        navigate(ERouteLinks.Transactions, {
          state: { presetTransaction: notification?.filterUniqueId },
        });
        break;
      case ENotificationFilterType.ancillaries: {
        if (ancillaryType) {
          let expandQueryString = '';
          let ancillaryFilterType = '';
          switch (ancillaryType) {
            case EArtAncillaryType.ACCESSORIES:
              expandQueryString = `?expand=${EArtAncillaryExpandType.ACCESSORIES}`;
              ancillaryFilterType = EAncillaryFilterTypes.ACCESSORY;
              break;
            case EArtAncillaryType.AMMUNITION:
              expandQueryString = `?expand=${EArtAncillaryExpandType.AMMUNITION}`;
              ancillaryFilterType = EAncillaryFilterTypes.AMMUNITION;
              break;
            case EArtAncillaryType.COMPONENTS:
              expandQueryString = `?expand=${EArtAncillaryExpandType.COMPONENTS}`;
              ancillaryFilterType = EAncillaryFilterTypes.COMPONENT;
              break;
            default:
              expandQueryString = '';
              break;
          }
          navigate(`${ERouteLinks.Ancillaries}${expandQueryString}`, {
            state: {
              presetAncillaries: notification?.filterUniqueId,
              ancillaryFilterType,
            },
          });
        }
        break;
      }
      default:
        break;
    }
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('notification.breadcrumb'), route: ERouteLinks.Notifications },
          t('notification.details.label'),
        ]}
      />

      {notification && (
        <>
          <StyledPageTitle mb={2} variant="h4">
            {t('notification.subject')
              .replace('{0}', mapFilterTypeToLabel(notification.filterType, t))
              .replace('{1}', notification.filterName)}
          </StyledPageTitle>
          <StyledPresetWrapper container>
            <Grid item container xs={12} justifyContent="space-between" rowSpacing={1}>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2">
                  {t('notification.filterName.label')}
                </Typography>
                <Typography variant="body2">{notification.filterName}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2">{t('notification.date.label')}</Typography>
                <Typography variant="body2">
                  {formatDate(notification.createdDate, longDateFormat)}
                </Typography>
              </Grid>
            </Grid>
            <Typography variant="subtitle2" mt={2}>
              {t('notification.searchCriteria.label')}
            </Typography>
            <StyledSelectedFiltersWrapper
              container
              rowGap={1}
              sx={{ marginTop: '8px !important' }}
            >
              <StyledFilterChipWrapper item>
                {filtersArray &&
                  filtersArray.map((item: { key: string; value: string }) => (
                    <FilterChip
                      key={item.key}
                      name={item.key}
                      label={t(labels[item.key])}
                      values={item.value}
                    />
                  ))}
              </StyledFilterChipWrapper>
            </StyledSelectedFiltersWrapper>

            <Grid
              container
              sx={{
                borderTop: {
                  xs: `1px solid ${colors.primary25}`,
                  lg: 'none',
                },
                mt: {
                  xs: 2,
                  md: 0,
                },
              }}
              spacing={2}
            >
              <Grid item xs={12} lg={6}>
                <Typography variant="subtitle2">
                  {t('notification.newRecordCount.label')}
                </Typography>
                <Typography variant="body2">{notification.newItemIds.length}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  label={t('notification.showResults.button')}
                  variant={EButtonVariants.outlined}
                  onClick={handleShowResults}
                  size="small"
                  fullWidth
                />
              </Grid>
            </Grid>
          </StyledPresetWrapper>
        </>
      )}
    </>
  );
};

export { ViewNotificationPage };
