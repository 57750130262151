import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import {
  StyledAdvancedSearch,
  StyledSubtitle,
} from 'pages/AncillariesPage/AncillariesReferenceTablePage/AncillariesReferenceAccesoriessSearchForm/styles';
import { Typography } from '@mui/material';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { SearchWrapper } from 'components/atoms/SearchWrapper';
import { useTranslations } from 'hooks/useTranslations';
import { useAncillaries } from 'pages/AncillariesPage/hooks';
import { useLang } from 'models/langContext';
import { EDictionaryTypes } from 'models/dictionary';
import { FormSelect } from 'components/molecules/FormSelect';
import { mapDictionaryToOptionProp } from 'helpers/dictionary';
import { EFormInputTypes, FormInput } from 'components/molecules/FormInput';
import { getFieldsByAmmunitionType } from 'pages/AncillariesPage/helpers';
import { EArtAmmunitionType, EArtAncillaryType } from 'constants/ArtAccessoryType';
import { ESearchFilterKeys } from 'constants/SearchFilterTypes';
import { prepareFilters } from 'helpers/filters';
import { SelectedFilters } from 'components/organisms/SelectedFilters';
import { useLocation } from 'react-router-dom';
import { objectToQueryString } from 'helpers/searchQuery';

export enum ESearchFormLabels {
  ammunitionTypeUniqueId = 'ammunitionTable.accordion.ammunitionType.label',
  manufacturerUniqueId = 'ammunitionTable.accordion.ammunitionManufacturer.label',
  productName = 'ammunitionTable.accordion.productName.label',
  factoryUniqueId = 'ammunitionTable.accordion.factory.label',
  calibreUniqueId = 'ammunitionTable.accordion.calibre.label',
  cartridgeCompositionUniqueId = 'ammunitionTable.accordion.catridgeComposition.label',
  colourOfPrimerSealantUniqueId = 'ammunitionTable.accordion.colourOfPrimerSealant.label',
  projectileShapeUniqueId = 'ammunitionTable.accordion.projectileShape.label',
  functionalTypeUniqueId = 'ammunitionTable.accordion.functionalType.label',
  munitionTypeUniqueId = 'ammunitionTable.accordion.munitionType.label',
  shotSizeUniqueId = 'ammunitionTable.accordion.shotSize.label',
  munitionVelocityUniqueId = 'ammunitionTable.accordion.munitionVelocity.label',
  headstampYearMarking = 'ammunitionTable.accordion.headstampYearMarking.label',
  alphanumericalMarkings = 'ammunitionTable.accordion.alphanumericalMarkins.label',
}

export interface IAmmunitionFormValues {
  ammunitionTypeUniqueId: string;
  manufacturerUniqueId: string;
  productName: string;
  factoryUniqueId: string;
  calibreUniqueId: string;
  cartridgeCompositionUniqueId: string;
  colourOfPrimerSealantUniqueId: string;
  projectileShapeUniqueId: string;
  functionalTypeUniqueId: string;
  munitionTypeUniqueId: string;
  shotSizeUniqueId: string;
  munitionVelocityUniqueId: string;
  headstampYearMarking: number | null;
  alphanumericalMarkings: string;
  notes?: string;
}

export const initialFormValues: IAmmunitionFormValues = {
  ammunitionTypeUniqueId: '',
  manufacturerUniqueId: '',
  productName: '',
  factoryUniqueId: '',
  calibreUniqueId: '',
  cartridgeCompositionUniqueId: '',
  colourOfPrimerSealantUniqueId: '',
  projectileShapeUniqueId: '',
  functionalTypeUniqueId: '',
  munitionTypeUniqueId: '',
  shotSizeUniqueId: '',
  munitionVelocityUniqueId: '',
  headstampYearMarking: null,
  alphanumericalMarkings: '',
};

interface IAncillariesReferenceAmmunitionSearchForm {
  onSubmit: (arg: any) => void;
  initialValues: IAmmunitionFormValues;
  isTransactionPage?: boolean;
  type?: string | null;
  setIsQueryAncillariesReferenceLoading: (arg: any) => void;
  isSearchButtonDisabled?: boolean;
}

const AncillariesReferenceAmmunitionSearchForm = ({
  onSubmit,
  initialValues,
  isTransactionPage,
  type = null,
  isSearchButtonDisabled = false,
  setIsQueryAncillariesReferenceLoading,
}: IAncillariesReferenceAmmunitionSearchForm) => {
  const { pathname }: any = useLocation();
  const { t } = useTranslations();
  const [accessFields, setAccessFields] = useState<string[]>([]);
  const [values, setValues] = useState<IAmmunitionFormValues>(initialValues);
  const [isLoadingDictionaries, setIsLoadingDictionaries] = useState<boolean>(false);
  const {
    control,
    handleSubmit,
    watch,
    reset,
    getValues,
    setValue,
    setError,
    formState: { errors },
  } = useForm<IAmmunitionFormValues>({
    defaultValues: initialValues,
  });

  const { selectedLanguage } = useLang();
  const {
    loadDictionaries,
    artAmmunitionType,
    artAmmunitionManufacturer,
    artAmmunitionFactory,
    artAmmunitionCalibreGauge,
    artAmmunitionCartridgeComposition,
    artAmmunitionColourOfPrimerSealant,
    artAmmunitionProjectileShape,
    artAmmunitionFunctionalType,
    artAmmunitionShotSize,
    artAmmunitionMunitionType,
    artAmmunitionMunitionVelocity,
  } = useAncillaries();

  useEffect(() => {
    (async function initDictionaries() {
      setIsLoadingDictionaries(true);
      await loadDictionaries([
        EDictionaryTypes.ArtAmmunitionType,
        EDictionaryTypes.ArtAmmunitionManufacturer,
        EDictionaryTypes.ArtAmmunitionFactory,
        EDictionaryTypes.ArtAmmunitionCalibreGauge,
        EDictionaryTypes.ArtAmmunitionCartridgeComposition,
        EDictionaryTypes.ArtAmmunitionColourOfPrimerSealant,
        EDictionaryTypes.ArtAmmunitionProjectileShape,
        EDictionaryTypes.ArtAmmunitionFunctionalType,
        EDictionaryTypes.ArtAmmunitionShotSize,
        EDictionaryTypes.ArtAmmunitionMunitionType,
        EDictionaryTypes.ArtAmmunitionMunitionVelocity,
      ]);
      setIsLoadingDictionaries(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  useEffect(() => {
    const subscription = watch((value) => {
      setValues(value as IAmmunitionFormValues);
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  useEffect(() => {
    if (!isLoadingDictionaries) {
      setValues(initialValues);
      reset(initialValues);
      handleSubmit(onSubmit)();
    }
  }, [initialValues, isLoadingDictionaries]);

  const ammunitionTypeChange = (value: EArtAmmunitionType | string) => {
    reset({ ...initialValues, ammunitionTypeUniqueId: value });
    if (value) {
      const fields = getFieldsByAmmunitionType(value as EArtAmmunitionType);
      setAccessFields(fields);
    } else {
      setAccessFields([]);
    }
  };

  useEffect(() => {
    if (initialValues.ammunitionTypeUniqueId) {
      ammunitionTypeChange(initialValues.ammunitionTypeUniqueId);
    }
  }, [initialValues]);

  const handleResetField = (name?: string, refreshResults: boolean = true) => {
    if (name && name !== 'ammunitionTypeUniqueId') {
      reset({ ...getValues(), [name]: '' });
    } else {
      reset(initialValues);
      ammunitionTypeChange('');
    }

    if (refreshResults) {
      onSubmit(getValues());
    }
  };

  const checkDisabled = (fieldName: string) => !accessFields.includes(fieldName);

  const validateHeadstampYearIfPresent = (value: number | null): boolean => {
    if (!!value && (value < 1 || value > 3000)) {
      setError('headstampYearMarking', {
        message: t('general.field.validation.valueIsNotCorrect'),
      });
      return false;
    }
    return true;
  };

  const submitForm = (data: IAmmunitionFormValues) => {
    const isValid = validateHeadstampYearIfPresent(data.headstampYearMarking);
    const query = objectToQueryString(data);

    setIsQueryAncillariesReferenceLoading(true);

    if (isTransactionPage && query.length) {
      window.history.replaceState(
        {},
        '',
        `${pathname}?type=${type}&expand=ancillaries&ancillaryOptionName=${EArtAncillaryType.AMMUNITION}&${query}`,
      );
    } else if (query.length) {
      window.history.replaceState(
        {},
        '',
        `${pathname}?expand=ancillaries-reference-table&ancillaryOptionName=${EArtAncillaryType.AMMUNITION}&${query}`,
      );
    }
    if (isValid) onSubmit(data);
  };

  return (
    <SearchWrapper>
      <form onSubmit={handleSubmit(submitForm)}>
        <StyledAdvancedSearch container spacing={2} columns={10}>
          <Grid item xs={12}>
            <StyledSubtitle item xs={3}>
              <Typography variant="subtitle2">
                {t('ancillaries.accordion.subtitle.label')}
              </Typography>
            </StyledSubtitle>
          </Grid>
          <Grid item lg={2.5} md={3} sm={5} xs={12}>
            <FormSelect
              options={mapDictionaryToOptionProp(artAmmunitionType)}
              name="ammunitionTypeUniqueId"
              label={t(ESearchFormLabels.ammunitionTypeUniqueId)}
              setValue={(name: string, value: string) => {
                // @ts-ignore
                setValue(name, value);
                ammunitionTypeChange(value as EArtAmmunitionType | '');
              }}
              canBeEmpty
              control={control}
              errors={errors}
              onSelect={ammunitionTypeChange}
            />
          </Grid>
          <Grid item lg={2.5} md={3} sm={5} xs={10}>
            <FormSelect
              options={mapDictionaryToOptionProp(artAmmunitionManufacturer)}
              name="manufacturerUniqueId"
              label={t(ESearchFormLabels.manufacturerUniqueId)}
              setValue={setValue}
              canBeEmpty
              control={control}
              errors={errors}
              disabled={checkDisabled('manufacturerUniqueId')}
            />
          </Grid>
          <Grid item lg={2.5} md={3} sm={5} xs={10}>
            <FormInput
              name="productName"
              label={t(ESearchFormLabels.productName)}
              control={control}
              errors={errors}
              disabled={checkDisabled('productName')}
              setValue={setValue}
            />
          </Grid>
          <Grid item lg={2.5} md={3} sm={5} xs={10}>
            <FormSelect
              options={mapDictionaryToOptionProp(artAmmunitionFactory)}
              name="factoryUniqueId"
              label={t(ESearchFormLabels.factoryUniqueId)}
              setValue={setValue}
              canBeEmpty
              control={control}
              errors={errors}
              disabled={checkDisabled('factoryUniqueId')}
            />
          </Grid>
          <Grid item lg={2.5} md={3} sm={5} xs={10}>
            <FormSelect
              options={mapDictionaryToOptionProp(artAmmunitionCalibreGauge)}
              name="calibreUniqueId"
              label={t(ESearchFormLabels.calibreUniqueId)}
              setValue={setValue}
              canBeEmpty
              control={control}
              errors={errors}
              disabled={checkDisabled('calibreUniqueId')}
            />
          </Grid>
          <Grid item lg={2.5} md={3} sm={5} xs={10}>
            <FormSelect
              options={mapDictionaryToOptionProp(artAmmunitionCartridgeComposition)}
              name="cartridgeCompositionUniqueId"
              label={t(ESearchFormLabels.cartridgeCompositionUniqueId)}
              setValue={setValue}
              canBeEmpty
              control={control}
              errors={errors}
              disabled={checkDisabled('cartridgeCompositionUniqueId')}
            />
          </Grid>
          <Grid item lg={2.5} md={3} sm={5} xs={10}>
            <FormSelect
              options={mapDictionaryToOptionProp(artAmmunitionColourOfPrimerSealant)}
              name="colourOfPrimerSealantUniqueId"
              label={t(ESearchFormLabels.colourOfPrimerSealantUniqueId)}
              setValue={setValue}
              canBeEmpty
              control={control}
              errors={errors}
              disabled={checkDisabled('colourOfPrimerSealantUniqueId')}
            />
          </Grid>
          <Grid item lg={2.5} md={3} sm={5} xs={10}>
            <FormSelect
              options={mapDictionaryToOptionProp(artAmmunitionProjectileShape)}
              name="projectileShapeUniqueId"
              label={t(ESearchFormLabels.projectileShapeUniqueId)}
              setValue={setValue}
              canBeEmpty
              control={control}
              errors={errors}
              disabled={checkDisabled('projectileShapeUniqueId')}
            />
          </Grid>
          <Grid item lg={2.5} md={3} sm={5} xs={10}>
            <FormSelect
              options={mapDictionaryToOptionProp(artAmmunitionFunctionalType)}
              name="functionalTypeUniqueId"
              label={t(ESearchFormLabels.functionalTypeUniqueId)}
              setValue={setValue}
              canBeEmpty
              control={control}
              errors={errors}
              disabled={checkDisabled('functionalTypeUniqueId')}
            />
          </Grid>
          <Grid item lg={2.5} md={3} sm={5} xs={10}>
            <FormSelect
              options={mapDictionaryToOptionProp(artAmmunitionShotSize)}
              name="shotSizeUniqueId"
              label={t(ESearchFormLabels.shotSizeUniqueId)}
              setValue={setValue}
              canBeEmpty
              control={control}
              errors={errors}
              disabled={checkDisabled('shotSizeUniqueId')}
            />
          </Grid>
          <Grid item lg={2.5} md={3} sm={5} xs={10}>
            <FormSelect
              options={mapDictionaryToOptionProp(artAmmunitionMunitionType)}
              name="munitionTypeUniqueId"
              label={t(ESearchFormLabels.munitionTypeUniqueId)}
              setValue={setValue}
              canBeEmpty
              control={control}
              errors={errors}
              disabled={checkDisabled('munitionTypeUniqueId')}
            />
          </Grid>
          <Grid item lg={2.5} md={3} sm={5} xs={10}>
            <FormSelect
              options={mapDictionaryToOptionProp(artAmmunitionMunitionVelocity)}
              name="munitionVelocityUniqueId"
              label={t(ESearchFormLabels.munitionVelocityUniqueId)}
              setValue={setValue}
              canBeEmpty
              control={control}
              errors={errors}
              disabled={checkDisabled('munitionVelocityUniqueId')}
            />
          </Grid>
          <Grid item lg={2.5} md={3} sm={5} xs={10}>
            <FormInput
              name="headstampYearMarking"
              type={EFormInputTypes.number}
              label={t(ESearchFormLabels.headstampYearMarking)}
              control={control}
              errors={errors}
              disabled={checkDisabled('headstampYearMarking')}
              setValue={setValue}
            />
          </Grid>
          <Grid item lg={2.5} md={3} sm={5} xs={10}>
            <FormInput
              name="alphanumericalMarkings"
              label={t(ESearchFormLabels.alphanumericalMarkings)}
              control={control}
              errors={errors}
              disabled={checkDisabled('alphanumericalMarkings')}
              setValue={setValue}
            />
          </Grid>

          <Grid
            item
            xs={12}
            container
            columns={10}
            columnSpacing={2}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Grid item xs={10} sm={10} md={5} lg={2}>
              <Button
                variant={EButtonVariants.contained}
                size={EButtonSizes.small}
                id="ancillary-reference-table-ammunition-search"
                label={t('ancillaries.accordion.search.button')}
                disabled={isSearchButtonDisabled}
                type="submit"
                fullWidth
                sx={{
                  mt: {
                    sm: 3.5,
                  },
                }}
              />
            </Grid>
          </Grid>
        </StyledAdvancedSearch>
      </form>
      <SelectedFilters
        name="ancillaries_reference_ammunition"
        values={values}
        labels={ESearchFormLabels}
        handleDelete={handleResetField}
        type={ESearchFilterKeys.ancillaries}
        showSaveFilters={false}
        saveQueryParams={['type', 'ancillaryOptionName']}
        dictionaries={{
          ammunitionTypeUniqueId: prepareFilters(artAmmunitionType, 'uniqueId', 'name'),
          manufacturerUniqueId: prepareFilters(artAmmunitionManufacturer, 'uniqueId', 'name'),
          factoryUniqueId: prepareFilters(artAmmunitionFactory, 'uniqueId', 'name'),
          calibreUniqueId: prepareFilters(artAmmunitionCalibreGauge, 'uniqueId', 'name'),
          cartridgeCompositionUniqueId: prepareFilters(
            artAmmunitionCartridgeComposition,
            'uniqueId',
            'name',
          ),
          colourOfPrimerSealantUniqueId: prepareFilters(
            artAmmunitionColourOfPrimerSealant,
            'uniqueId',
            'name',
          ),
          projectileShapeUniqueId: prepareFilters(
            artAmmunitionProjectileShape,
            'uniqueId',
            'name',
          ),
          functionalTypeUniqueId: prepareFilters(
            artAmmunitionFunctionalType,
            'uniqueId',
            'name',
          ),
          munitionTypeUniqueId: prepareFilters(artAmmunitionMunitionType, 'uniqueId', 'name'),
          shotSizeUniqueId: prepareFilters(artAmmunitionShotSize, 'uniqueId', 'name'),
          munitionVelocityUniqueId: prepareFilters(
            artAmmunitionMunitionVelocity,
            'uniqueId',
            'name',
          ),
        }}
        saveRouterStateAfterClearAll={false}
      />
    </SearchWrapper>
  );
};

export default AncillariesReferenceAmmunitionSearchForm;
