import React, { useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from 'components/atoms/Button';
import { Pagination } from 'components/molecules/Pagination';
import {
  AmmunitionTableColumns,
  mapAmmunitionToDataSource,
} from 'pages/AncillariesPage/helpers';
import { ERouteLinks } from 'models/route';
import { Table } from 'components/molecules/Table';
import { useTranslations } from 'hooks/useTranslations';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { useAlert } from 'models/alertContext';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ActionTypes } from 'state/actions/alert';
import { CardList } from 'components/molecules/CardList';
import { Loader } from 'components/atoms/Loader';
import { EPerPages, ISortOptions, ITableDataSource } from 'models/table';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import {
  customFieldsPrepareToSearch,
  objectToQueryString,
  prepareDataWithCustomFieldsToSearch,
  prepareQueryValues,
} from 'helpers/searchQuery';
import {
  IFormElementOptionProp,
  initialAncillariesSearchFormValues,
  ISearchAncillariesFormValues,
} from 'models/form';
import SearchFilterStore from 'state/SearchFilterStore';
import { useQuery } from 'hooks/useQuery';
import { EArtAncillaryExpandType, EArtAncillaryType } from 'constants/ArtAccessoryType';
import { useDrawerClicked } from 'state/contexts/drawer';
import { exportAncillariesCSV } from 'requests/ancillaries';
import {
  ECustomFieldObjectType,
  ECustomFieldType,
  ICustomFieldByObjectType,
} from 'models/customField';
import { useLang } from 'models/langContext';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { EPermission } from 'models/permissions';
import { EChipsVariants } from 'components/atoms/Chips';
import { renderRowColor, renderRowColorHover } from 'components/molecules/Table/helpers';
import { formatDateTimeToIso } from 'utils/date';
import { Tooltip } from '@mui/material';
import { isExportAvailable } from 'constants/Export';
import { Box } from '@mui/system';
import { Alert, EAlertVariants, IAlert } from 'components/atoms/Alert';
import { EFirearmStateId } from 'models/firearm';
import { useAncillaries } from '../hooks';
import { AmmunitionCard } from './AmmunitionCard';
import { AmmunitionSearchForm } from './AmmunitionSearchForm';

interface AmmunitionPageProps {
  renderDataColor: (data: ITableDataSource) => {
    border: string;
    stateField: EChipsVariants;
    background: string;
  };
}

const AmmunitionPage = ({ renderDataColor }: AmmunitionPageProps) => {
  const { isDesktop } = useThemeBreakpoint();
  const { t } = useTranslations();
  const { pathname }: any = useLocation();
  const navigate = useNavigate();
  const [isAmmunitionLoading, setIsAmmunitionLoading] = useState<boolean | null>(null);
  const [currentPageAmmunition, setCurrentPageAmmunition] = useState<number>(1);
  const [perPageAmmunition, setPerPageAmmunition] = useState<EPerPages>(EPerPages.perPage25);
  const [ammunitionSort, setAmmunitionSort] = useState<ISortOptions | null>(null);
  const [ammunitionQuery, setAmmunitionQuery] = useState<
    Partial<ISearchAncillariesFormValues>
  >({});
  const [isAmmunitionQueryLoading, setIsAmmunitionQueryLoading] = useState<boolean>(false);
  const [isSortLoaded, setIsSortLoaded] = useState<boolean>(true);
  const query = useQuery();
  const [showAdvanced, setShowAdvanced] = useState<boolean>(false);
  const [ownerOptions, setOwnerOptions] = useState<IFormElementOptionProp[]>([]);
  const [keeperOptions, setKeeperOptions] = useState<IFormElementOptionProp[]>([]);
  const [initialValues, setInitialValues] = useState<ISearchAncillariesFormValues | null>(
    null,
  );
  const [areInitialValuesLoaded, setAreInitialValuesLoaded] = useState(false);
  const [areCustomFieldsLoaded, setAreCustomFieldsLoaded] = useState(false);
  const { selectedLanguage } = useLang();
  const { shortDateFormat } = useGlobalProperty();
  const [searchParams] = useSearchParams();
  const urlQuery = new URLSearchParams(searchParams);
  const expandType = urlQuery.get('expand');
  const { state: routerState }: any = useLocation();
  const isFromMapQuery: boolean =
    query.get('fromMap') === 'true' || routerState?.ammunitionUniqueIds?.length > 0;
  const storedAmmunition = SearchFilterStore.getAmmunition();
  const { drawerClicked } = useDrawerClicked();
  const [isExportLoading, setExportLoading] = useState(false);
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const { setAlert, clearAlert, ammunitionAlert } = useAlert();

  const scrollPosition = useRef(0);

  const uniqueIds =
    storedAmmunition.length > 0
      ? storedAmmunition.map((ammunitionItem) => ammunitionItem.uniqueId)
      : routerState?.ammunitionUniqueIds;

  const {
    paginatorAmmunition,
    ammunition,
    getAmmunitionData,
    getCustomField,
    customFieldsAmmunition,
  } = useAncillaries();

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_NEXT_PAGE_ALERT);
      clearAlert(ActionTypes.CLEAR_AMMUNITION_ALERT);
      setIsAmmunitionLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (isSortLoaded) {
      window.scrollTo({ top: scrollPosition.current, behavior: 'auto' });
      scrollPosition.current = 0;
    }
  }, [isSortLoaded]);

  useEffect(() => {
    (async () => {
      setAreCustomFieldsLoaded(false);
      await getCustomField(ECustomFieldObjectType.AN_AMMUNITION);
      setAreCustomFieldsLoaded(true);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  useEffect(() => {
    if (drawerClicked) {
      SearchFilterStore.setAmmunition([]);
      window.history.replaceState({}, '', `${pathname}`);
      setOwnerOptions([]);
      setKeeperOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerClicked]);

  const previousAmmunitionSortRef = useRef(ammunitionSort);

  useEffect(() => {
    (async function getDataInit() {
      setIsPaginationLoading(true);
      if (!ammunition.length) {
        setIsAmmunitionLoading(true);
      }
      if (isFromMapQuery) {
        if (uniqueIds && uniqueIds.length) {
          ammunitionQuery.uniqueIds = uniqueIds;
        } else {
          delete ammunitionQuery.uniqueIds;
        }
      }
      if (ammunitionSort !== previousAmmunitionSortRef.current) {
        setIsSortLoaded(false);
      }

      if (Object.keys(ammunitionQuery).length !== 0) {
        customFieldsPrepareToSearch(ammunitionQuery.customFields);
        const ammunitionQueryData = prepareDataWithCustomFieldsToSearch(
          ammunitionQuery,
          customFieldsAmmunition.filter(
            (item: ICustomFieldByObjectType) => item.searchCriteria,
          ),
        );
        await getAmmunitionData(
          currentPageAmmunition,
          perPageAmmunition,
          {
            ...ammunitionQueryData,
          },
          ammunitionSort,
        );
      }

      setIsAmmunitionLoading(false);
      setIsAmmunitionQueryLoading(false);
      setIsPaginationLoading(false);
      setIsSortLoaded(true);
      previousAmmunitionSortRef.current = ammunitionSort;
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPageAmmunition,
    perPageAmmunition,
    ammunitionQuery,
    ammunitionSort,
    selectedLanguage,
  ]);

  useEffect(() => {
    if (initialValues !== undefined && areCustomFieldsLoaded) {
      setAreInitialValuesLoaded(true);
    }
  }, [initialValues, areCustomFieldsLoaded]);

  useEffect(() => {
    if (areCustomFieldsLoaded) {
      setAreInitialValuesLoaded(false);
      if (expandType === EArtAncillaryExpandType.AMMUNITION) {
        setShowAdvanced(true);
        const queryValues = prepareQueryValues(
          {
            ...initialAncillariesSearchFormValues,
          },
          urlQuery,
          [
            'manufactureDateFrom',
            'manufactureDateTo',
            'expiryDateFrom',
            'expiryDateTo',
            ...customFieldsAmmunition
              .filter((cf) => cf.customFieldType === ECustomFieldType.DATE)
              .map((cf) => [`from_${cf.name}`, `to_${cf.name}`]),
          ].flat(),
        );
        setInitialValues({ ...initialAncillariesSearchFormValues, ...queryValues });
      } else {
        setInitialValues(initialAncillariesSearchFormValues);
        setAreInitialValuesLoaded(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, areCustomFieldsLoaded]);

  const getNewQueryPath = (values: Partial<ISearchAncillariesFormValues>): string => {
    const valuesWithoutPreset = { ...values };
    valuesWithoutPreset.preset = undefined;
    valuesWithoutPreset.formSubmitted = undefined;
    const objQuery = objectToQueryString(valuesWithoutPreset, false, shortDateFormat);

    if (
      objQuery.length &&
      (!isInitialLoad || expandType === EArtAncillaryExpandType.AMMUNITION)
    ) {
      setIsInitialLoad(false);
      return `${pathname}?expand=ammunition&${objQuery}`;
    }
    if (values.formSubmitted === true) {
      return `${pathname}?expand=ammunition`;
    }
    setIsInitialLoad(false);
    return `${pathname}`;
  };

  const handleSetAmmunitionQuery = (values: Partial<ISearchAncillariesFormValues>) => {
    const valuesWithoutPreset = { ...values };
    valuesWithoutPreset.preset = undefined;
    valuesWithoutPreset.formSubmitted = undefined;

    window.history.replaceState({}, '', getNewQueryPath(values));

    if (valuesWithoutPreset.expiryDateFrom) {
      valuesWithoutPreset.expiryDateFrom = formatDateTimeToIso(
        valuesWithoutPreset.expiryDateFrom,
      );
    }
    if (valuesWithoutPreset.expiryDateTo) {
      valuesWithoutPreset.expiryDateTo = formatDateTimeToIso(valuesWithoutPreset.expiryDateTo);
    }
    if (valuesWithoutPreset.manufactureDateFrom) {
      valuesWithoutPreset.manufactureDateFrom = formatDateTimeToIso(
        valuesWithoutPreset.manufactureDateFrom,
      );
    }
    if (valuesWithoutPreset.manufactureDateTo) {
      valuesWithoutPreset.manufactureDateTo = formatDateTimeToIso(
        valuesWithoutPreset.manufactureDateTo,
      );
    }

    setIsAmmunitionQueryLoading(true);
    setAmmunitionQuery(valuesWithoutPreset);
  };

  const exportCsv = async () => {
    setExportLoading(true);
    ammunitionQuery.ancillaryDictionaryUniqueId = EArtAncillaryType.AMMUNITION;
    await exportAncillariesCSV(ammunitionQuery, ammunitionSort);
    setExportLoading(false);
  };

  const handleRedirectToEditPageButton = (item: ITableDataSource) => {
    if (customFieldsAmmunition?.length && item.stateUniqueId !== EFirearmStateId.DISABLED) {
      navigate(
        ERouteLinks.EditAncillary.replace(':id', item.id as string).replace(
          ':type',
          'ammunition',
        ),
      );
    } else if (item.stateUniqueId === EFirearmStateId.DISABLED) {
      setAlert(ActionTypes.SET_AMMUNITION_ALERT, {
        text: t('ancillary.disabled.edit.info'),
        variant: EAlertVariants.error,
      } as IAlert);
    } else {
      setAlert(ActionTypes.SET_AMMUNITION_ALERT, {
        text: t('general.noAvaibleDataToEdit.label'),
        variant: EAlertVariants.warning,
      } as IAlert);
    }
  };

  return (
    <div>
      {initialValues && areInitialValuesLoaded && areCustomFieldsLoaded && (
        <AmmunitionSearchForm
          ownerOptions={ownerOptions}
          keeperOptions={keeperOptions}
          setOwnerOptions={setOwnerOptions}
          setKeeperOptions={setKeeperOptions}
          showAdvanced={showAdvanced}
          setShowAdvanced={setShowAdvanced}
          ammunitionUniqueIds={uniqueIds}
          isFromMap={isFromMapQuery}
          returnPath={`${pathname}?expand=ammunition`}
          getNewQueryPath={getNewQueryPath}
          onSubmit={handleSetAmmunitionQuery}
          isSearchButtonDisabled={isAmmunitionLoading || isAmmunitionQueryLoading}
          initialFormValues={initialValues ?? undefined}
          customFields={customFieldsAmmunition.filter(
            (item: ICustomFieldByObjectType) => item.searchCriteria,
          )}
        />
      )}
      <Grid
        item
        xs={12}
        container
        gap={1}
        sx={{ display: 'flex', justifyContent: 'flex-end', margin: '10px 0' }}
      >
        {!isAmmunitionLoading && (
          <>
            {isExportAvailable(paginatorAmmunition) ? (
              <Tooltip title={t('dataExportRecordsLimitAmount.label')} arrow>
                <span>
                  <Button
                    variant={EButtonVariants.outlined}
                    size={EButtonSizes.small}
                    permission={[EPermission.EXPORT_ANCILLARIES]}
                    label={t('ancillaries.accordion.export.button')}
                    type="submit"
                    onClick={exportCsv}
                    isLoading={isExportLoading}
                    disabled
                  />
                </span>
              </Tooltip>
            ) : (
              <Button
                variant={EButtonVariants.outlined}
                size={EButtonSizes.small}
                permission={[EPermission.EXPORT_ANCILLARIES]}
                label={t('ancillaries.accordion.export.button')}
                type="submit"
                onClick={exportCsv}
                isLoading={isExportLoading}
              />
            )}
            <Button
              variant={EButtonVariants.contained}
              size={EButtonSizes.small}
              label={t('ancillaries.accordion.showOnMap.button')}
              onClick={() => {
                navigate(ERouteLinks.RegisteredAncillariesMap, {
                  state: {
                    ancillaryType: 'ammunition',
                    ancillaryQuery: ammunitionQuery,
                    showAdvanced,
                    options: { ownerOptions, keeperOptions },
                    navigateBack: -1,
                  },
                });
              }}
            />
          </>
        )}
      </Grid>
      {isAmmunitionLoading || isAmmunitionQueryLoading ? (
        <Loader isLoading={true} />
      ) : (
        <>
          {isDesktop ? (
            <Table
              columns={AmmunitionTableColumns(
                {
                  handleEdit: handleRedirectToEditPageButton,
                  customFields: customFieldsAmmunition,
                  t,
                },
                (data: ITableDataSource) => renderDataColor(data).stateField,
                selectedLanguage?.uniqueId,
                shortDateFormat,
              )}
              specificRowColor={renderRowColor}
              specificRowColorHover={renderRowColorHover}
              dataSource={mapAmmunitionToDataSource(ammunition)}
              onSort={(it) => {
                setAmmunitionSort(it);
                scrollPosition.current = window.scrollY;
              }}
              translationsKeys={{
                noResults: 'ancillaries.accordion.noResults.text',
              }}
              isSortLoaded={isSortLoaded}
              customPaddingIfNoData="30px"
            />
          ) : (
            <CardList
              items={mapAmmunitionToDataSource(ammunition)}
              render={(data, index) => (
                <AmmunitionCard
                  data={data}
                  key={index}
                  renderColor={renderDataColor}
                  handleView={(id: string) =>
                    navigate(
                      ERouteLinks.ViewAncillary.replace(':id', id).replace(
                        ':type',
                        'ammunition',
                      ),
                    )
                  }
                  handleEdit={handleRedirectToEditPageButton}
                />
              )}
            />
          )}

          {paginatorAmmunition && (
            <Pagination
              count={paginatorAmmunition?.totalElements}
              perPage={perPageAmmunition}
              onChangePage={(page) => {
                setCurrentPageAmmunition(page);
              }}
              onChangePerPage={(value) => {
                setPerPageAmmunition(value);
              }}
              current={currentPageAmmunition}
              isLoading={isPaginationLoading}
              isVisible={isSortLoaded}
            />
          )}
        </>
      )}
      {ammunitionAlert && (
        <Box mt={2} sx={{ marginBottom: '10px' }}>
          <Alert text={ammunitionAlert.text} variant={ammunitionAlert.variant} />
        </Box>
      )}
    </div>
  );
};

export default AmmunitionPage;
