import React, { useEffect, useState } from 'react';
import { ERouteLinks } from 'models/route';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { StyledPageTitle } from 'theme/styles';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import { StyledDetailsWrapper } from 'pages/LegalEntitiesPage/LegalEntityDeleteCommentPage/styles';
import { useTranslations } from 'hooks/useTranslations';
import { emptyFieldHolder } from 'components/molecules/CardList';
import { useQuery } from 'hooks/useQuery';
import { ELegalEntityTypes } from 'constants/LegalEntityTypes';
import { Alert, EAlertVariants } from 'components/atoms/Alert';

const LegalEntityDeleteAncillariesGroupPage = () => {
  const { id, groupId } = useParams<{ id: string; groupId: string }>();
  const { t } = useTranslations();

  const query = useQuery();
  const type: string | null = query.get('type');

  const {
    handleDeleteAncillariesGroup,
    getAncillaryGroupDetail,
    navigateToDetails,
    ancillaryGroupDetail,
  } = useLegalEntities();

  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);

  const { state: routerState }: any = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    (async function getLegalEntityGroupDataInit() {
      if (id && groupId) {
        await getAncillaryGroupDetail(id, groupId);
      }
    })();
  }, [id, groupId]);

  const onDelete = async () => {
    setIsDeleteLoading(true);
    try {
      if (id && ancillaryGroupDetail) {
        try {
          const result = await handleDeleteAncillariesGroup(id, ancillaryGroupDetail.uniqueId);
          if (result) {
            navigateToDetails();
          }
        } catch (e: any) {
          console.error(e);
        }
      }
    } catch (e: any) {
      console.error(e);
    }
    setIsDeleteLoading(false);
  };

  const handleCancel = () => {
    if (routerState && routerState.returnLink) {
      navigate(routerState.returnLink);
    } else {
      navigateToDetails();
    }
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.legalEntity.name'), route: ERouteLinks.LegalEntities },
          {
            label:
              type === ELegalEntityTypes.individual
                ? t('legalEntities.individual.accordion.label')
                : t('legalEntities.organization.accordion.label'),
            route: `${ERouteLinks.LegalEntities}?expand=${type}`,
          },
          {
            label: t('legalEntityDetails.legalEntityDetails.text'),
            route: `${ERouteLinks.ViewLegalEntity.replace(
              ':id',
              id as string,
            )}?type=${type}&expand=ancillaryGroups`,
          },
          t('deleteAncillariesGroup.header'),
        ]}
      />
      {ancillaryGroupDetail && (
        <>
          <StyledPageTitle variant="h4">{t('deleteAncillariesGroup.header')}</StyledPageTitle>

          <Box my={3}>
            <Alert
              text={t('deleteAncillariesGroup.info.text')}
              variant={EAlertVariants.warning}
            />
          </Box>

          <StyledDetailsWrapper container>
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('deleteAncillariesGroup.groupName.label')}
              </Typography>
              <Typography variant="body2">
                {emptyFieldHolder(ancillaryGroupDetail.name)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('deleteAncillariesGroup.owner.label')}
              </Typography>
              <Typography variant="body2">
                {emptyFieldHolder(ancillaryGroupDetail.owner)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('deleteAncillariesGroup.keeper.label')}
              </Typography>
              <Typography variant="body2">
                {emptyFieldHolder(ancillaryGroupDetail.keeper)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('deleteAncillariesGroup.ancillaryAmount.label')}
              </Typography>
              <Typography variant="body2">
                {emptyFieldHolder(ancillaryGroupDetail.ancillaryAmount)}
              </Typography>
            </Grid>
          </StyledDetailsWrapper>
          <Grid container sx={{ mt: 2 }} spacing={2}>
            <Grid item xs={6} lg={3}>
              <Button
                fullWidth
                id="delete-group-yes-button"
                label={t('general.yes.button')}
                variant={EButtonVariants.contained}
                sx={{ mr: 1 }}
                onClick={async () => onDelete()}
                isLoading={isDeleteLoading}
              />
            </Grid>

            <Grid item xs={6} lg={3}>
              <Button
                fullWidth
                id="delete-group-no-button"
                label={t('general.no.button')}
                variant={EButtonVariants.outlined}
                onClick={handleCancel}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export { LegalEntityDeleteAncillariesGroupPage };
