import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { UserSearchForm } from 'pages/UsersPage/UserSearchForm';
import { StyledPageTitle } from 'theme/styles';
import { TableTopButtons } from 'components/atoms/TableTopButtons';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import { ERouteLinks } from 'models/route';
import { Table } from 'components/molecules/Table';
import { Pagination } from 'components/molecules/Pagination';
import { useAlert } from 'models/alertContext';
import { EPerPages, ISortOptions } from 'models/table';
import { ActionTypes } from 'state/actions/alert';
import { Alert } from 'components/atoms/Alert';
import { useUsers } from 'pages/UsersPage/hooks';
import {
  EUserStatusActivated,
  mapUsersToDataSource,
  usersTableColumns,
} from 'pages/UsersPage/helpers';
import { Loader } from 'components/atoms/Loader';
import { initialUserSearchFormValues, ISearchUserFormValues } from 'models/form';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { CardList } from 'components/molecules/CardList';
import { UserTableCard } from 'pages/UsersPage/UserTableCard';
import { useTranslations } from 'hooks/useTranslations';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { objectToQueryString } from 'helpers/searchQuery';
import { useAuth } from 'models/authContext';

export interface ISearchParams {
  [key: string]: string | Date;
}

export enum EUsersPageTranslationsKeys {
  noResults = 'users.search.noResults.info',
  editLink = 'users.usersTable.edit.link',
  activateLink = 'users.usersTable.activate.link',
  deactivateLink = 'users.usersTable.deactivate.link',
}

const UsersPage = () => {
  const navigate = useNavigate();
  const { pathname }: any = useLocation();
  const { userAlert, nextPageAlert, clearAlert } = useAlert();
  const { users, getUsersData, paginator, activateUser, unbanUser } = useUsers();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<EPerPages>(EPerPages.perPage25);
  const [userQuery, setUserQuery] = useState<Partial<ISearchUserFormValues>>({});
  const [sort, setSort] = useState<ISortOptions | null>(null);
  const [isLoader, setLoader] = useState(true);
  const [initialValues, setInitialValues] = useState<ISearchUserFormValues | null>(null);
  const [searchParams] = useSearchParams();
  const { isDesktop } = useThemeBreakpoint();
  const { t } = useTranslations();
  const { longDateFormat, getDateFormat } = useGlobalProperty();
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    getDateFormat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoader(true);
    (async function getUsersDataInit() {
      if (Object.keys(userQuery).length) {
        setIsPaginationLoading(true);
        await getUsersData(currentPage, perPage, userQuery, sort);
        setIsPaginationLoading(false);
      }
    })();
    setLoader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, perPage, userQuery, sort]);

  useEffect(
    () => () => {
      if (!isLoader) {
        clearAlert(ActionTypes.CLEAR_USER_ALERT);
        clearAlert(ActionTypes.CLEAR_NEXT_PAGE_ALERT);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoader],
  );

  useEffect(() => {
    const query = new URLSearchParams(searchParams);
    const queryValues: ISearchParams = {};
    query.forEach((value, field) => {
      if (field in initialUserSearchFormValues) {
        queryValues[field] = value;
      }
    });
    setInitialValues({ ...initialUserSearchFormValues, ...queryValues });
  }, [searchParams]);

  const handleSetUserQuery = (values: Partial<ISearchUserFormValues>) => {
    const query = objectToQueryString(values);

    if (query.length) {
      window.history.replaceState({}, '', `${pathname}?${query}`);
    }

    setUserQuery(values);
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('user.breadcrumbs.administration'), route: ERouteLinks.Administration },
          t('user.breadcrumbs.users'),
        ]}
      />
      <StyledPageTitle variant="h4" mb={2}>
        {t('users.header')}
      </StyledPageTitle>

      {userAlert && (
        <Box mt={2} mb={2}>
          <Alert text={userAlert.text} variant={userAlert.variant} />
        </Box>
      )}
      {nextPageAlert && (
        <Box mt={2} mb={2}>
          <Alert text={nextPageAlert.text} variant={nextPageAlert.variant} />
        </Box>
      )}
      {initialValues && (
        <UserSearchForm
          onSubmit={handleSetUserQuery}
          initialSearchForm={initialValues}
          isSearchButtonDisabled={isLoader}
        />
      )}

      <TableTopButtons>
        <Grid container justifyContent="flex-end" columns={10}>
          <Grid item lg={2} sm={5} xs={10}>
            <Button
              label={t('users.addUser.button')}
              variant={EButtonVariants.contained}
              icon={EIconTypes.plus}
              onClick={() => navigate(ERouteLinks.AddUser)}
              fullWidth={true}
            />
          </Grid>
        </Grid>
      </TableTopButtons>

      {isLoader ? (
        <Loader isLoading={isLoader} />
      ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {isDesktop && user && user.userId ? (
            <Table
              columns={usersTableColumns({
                handleEdit: (id: string) => navigate(ERouteLinks.EditUser.replace(':id', id)),
                handleActivate: (id: string) =>
                  navigate(
                    ERouteLinks.EditUserStatus.replace(':id', id).replace(
                      ':status',
                      EUserStatusActivated.activate,
                    ),
                  ),
                handleDeactivate: (id: string) =>
                  navigate(
                    ERouteLinks.EditUserStatus.replace(':id', id).replace(
                      ':status',
                      EUserStatusActivated.deactivate,
                    ),
                  ),
                handleUnban: (id: string) => unbanUser(id),
                longDateFormat,
                t,
                loggedUserId: user.userId,
              })}
              dataSource={mapUsersToDataSource(users)}
              onSort={setSort}
              translationsKeys={EUsersPageTranslationsKeys}
            />
          ) : (
            <CardList
              items={mapUsersToDataSource(users)}
              translationsKeys={EUsersPageTranslationsKeys}
              render={(data, index) => (
                <UserTableCard
                  data={data}
                  key={index}
                  activateUser={activateUser}
                  unbanUser={unbanUser}
                  translationsKeys={EUsersPageTranslationsKeys}
                />
              )}
            />
          )}
        </>
      )}

      {paginator && (
        <Pagination
          count={paginator.totalElements}
          onChangePage={(page) => setCurrentPage(page)}
          current={currentPage}
          perPage={perPage}
          onChangePerPage={(value) => setPerPage(value)}
          isLoading={isPaginationLoading}
        />
      )}
    </>
  );
};

export default UsersPage;
