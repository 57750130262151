import React from 'react';
import { ITableDataSource } from 'models/table';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { useTranslations } from 'hooks/useTranslations';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { emptyFieldHolder } from 'components/molecules/CardList';
import { useNavigate } from 'react-router-dom';
import { getAncillaryViewRoute } from 'pages/TransactionsPage/ViewTransactionPage';

interface ITransactionDetailsAncillariesCard {
  data: ITableDataSource;
  showDetail: boolean | null;
}

const TransactionDetailsAncillariesCard = ({
  data,
  showDetail,
}: ITransactionDetailsAncillariesCard) => {
  const { t } = useTranslations();
  const navigate = useNavigate();

  return (
    <Grid
      container
      sx={{
        border: colors.gray200,
        borderRadius: '4px',
        overflow: 'hidden',
        mt: 1,
        backgroundColor: colors.gray100,
      }}
    >
      <Grid item xs={12} sm={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('transactionDetails.ancillaryDetails.accordion.firecycleID.label')}
        </Typography>
        <Typography variant="body2">{data.fireCycleId}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('transactionDetails.ancillaryDetails.accordion.serialNumber.label')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.serialNumber)}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('transactionDetails.ancillaryDetails.accordion.lotNumber.label')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.lotNumber)}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('transactionDetails.ancillaryDetails.accordion.batchNumber.label')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.batchNumber)}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('transactionDetails.ancillaryDetails.accordion.ancillary.label')}
        </Typography>
        <Typography variant="body2">{data.ancillaryDictionaryValue}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('transactionDetails.ancillaryDetails.accordion.ancillaryType.label')}
        </Typography>
        <Typography variant="body2">{data.ancillarySpecificType}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('transactionDetails.ancillaryDetails.accordion.manufacturer.label')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.manufacturer)}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('transactionDetails.ancillaryDetails.accordion.calibre.label')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.calibre)}</Typography>
      </Grid>
      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTop: {
            xs: `1px solid ${colors.primary25}`,
            lg: 'none',
          },
        }}
        p={2}
      >
        <Grid item xs={12} sm={3} sx={{ mb: { xs: 2, sm: 0 } }}>
          <Typography variant="button" sx={{ textTransform: 'uppercase' }}>
            {t('ancillaryDetailsCard.accordion.actions.column')}:{' '}
          </Typography>
        </Grid>

        {showDetail && (
          <Grid item xs={12} sm={6}>
            <Button
              label={t('ancillaryDetailsCard.accordion.details.link')}
              variant={EButtonVariants.outlined}
              onClick={() =>
                navigate(getAncillaryViewRoute(data.ancillaryType, data.id as string))
              }
              size="small"
              fullWidth
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export { TransactionDetailsAncillariesCard };
