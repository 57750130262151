import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { StyledPageTitle } from 'theme/styles';
import { ERouteLinks } from 'models/route';
import { useTranslations } from 'hooks/useTranslations';
import { StyledDetailsWrapper } from 'pages/TransactionsPage/ViewTransactionPage/styles';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import { useTransactions } from 'pages/TransactionsPage/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { parseDictionary } from 'helpers/dictionary';
import { StyledButton } from 'pages/Firearms/ViewRegisteredFirearmPage/styles';
import { Accordion } from 'components/molecules/Accordion';
import Link from '@mui/material/Link';
import { colors } from 'theme/colors';
import { FormCheckbox } from 'components/molecules/FormCheckbox';
import { EIconTypes } from 'constants/Icons';
import { CommentAccordion } from 'components/molecules/CommentAccordion';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { useForm } from 'react-hook-form';
import { Loader } from 'components/atoms/Loader';
import { Pagination } from 'components/molecules/Pagination';
import { EPerPages, ISortOptions } from 'models/table';
import { CardList, emptyFieldHolder } from 'components/molecules/CardList';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import { useLang } from 'models/langContext';
import { formatDate } from 'utils/date';
import { ETransactionTypes } from 'constants/TransactionTypes';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { ECustomFieldObjectType } from 'models/customField';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import { ETransactionItemType } from 'constants/TransactionItemType';
import {
  mapTransactionDetailsAncillaries,
  mapTransactionDetailsFirearms,
  transactionDetailsAncillariesTableColumns,
  transactionDetailsFirearmsTableColumns,
} from 'pages/TransactionsPage/helpers';
import { Table } from 'components/molecules/Table';
import { TransactionDetailsFirearmsCard } from 'pages/TransactionsPage/TransactionDetailsFirearmsCard';
import {
  EArtAncillaryTypeName,
  EArtAncillaryTypeNameUpperCase,
} from 'constants/ArtAccessoryType';
import { TransactionDetailsAncillariesCard } from 'pages/TransactionsPage/TransactionDetailsAncillariesCard';
import ComponentDetails from 'components/organisms/AncillaryDetails/ComponentDetails';
import AmmunitionDetails from 'components/organisms/AncillaryDetails/AmmunitionDetails';
import AccessoryDetails from 'components/organisms/AncillaryDetails/AccessoryDetails';
import {
  isAncillaryGroupTransactionDiscardable,
  isGroupTransactionDiscardable,
} from 'requests/transaction';
import { usePermission } from 'hooks/usePermission';
import { EPermission } from 'models/permissions';
import { StyledLegalLink } from 'components/atoms/StyledLegalLink';
import { getLegalEntityType } from 'components/organisms/RegisteredFirearmDetails';
import { getHtmlText } from 'components/organisms/HtmlSlate';

const INACTIVE_FIREARM_ID = '11d094fc-0746-11ed-b939-0242ac120002';
const ILLEGAL_FIREARM_ID = 'ae5de668-67ac-4650-af26-23dd0cb97c89';

export enum ETransactionDetailsTranslationsKeys {
  noResults = 'users.search.noResults.info',
}

const shouldShowOnMap = (typeUniqueId: string) =>
  [
    ETransactionTypes.SEIZED,
    ETransactionTypes.RECOVERED,
    ETransactionTypes.STOLEN,
    ETransactionTypes.LOST,
    ETransactionTypes.SPOT_CHECK,
  ]
    .map((type) => type.valueOf())
    .includes(typeUniqueId);

export const getAncillaryViewRoute = (ancillaryType: string, uniqueId: string) => {
  switch (ancillaryType.toLowerCase()) {
    case EArtAncillaryTypeName.AMMUNITION.toLowerCase():
      return `${ERouteLinks.ViewAmmunition.replace(':id', uniqueId)}`;

    case EArtAncillaryTypeName.ACCESSORY.toLowerCase():
      return `${ERouteLinks.ViewAccessories.replace(':id', uniqueId)}`;

    case EArtAncillaryTypeName.COMPONENTS.toLowerCase():
    default:
      return `${ERouteLinks.ViewComponents.replace(':id', uniqueId)}`;
  }
};

const ViewTransactionPage = () => {
  const [isAllExpanded, setIsAllExpanded] = useState<boolean>(false);
  const [currentPageComments, setCurrentPageComments] = useState<number>(1);
  const [perPageComments, setPerPageComments] = useState<EPerPages>(EPerPages.perPage25);
  const [currentPageFirearmsAncillaries, setCurrentPageFirearmsAncillaries] =
    useState<number>(1);
  const [perPageFirearmsAncillaries, setPerPageFirearmsAncillaries] = useState<EPerPages>(
    EPerPages.perPage25,
  );
  const [sortFirearmsAncillaries, setSortFirearmsAncillaries] = useState<ISortOptions | null>(
    null,
  );
  const { hasPermissions } = usePermission();
  const { getValidTranslationName } = useLang();
  const { t } = useTranslations();
  const { selectedLanguage } = useLang();
  const { id = '' } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { isMobile, isDesktop } = useThemeBreakpoint();
  const {
    handleGetTransactionDetailsData,
    transactionDetails,
    loadDictionariesForTransactionDetails,
    transactionType,
    handleGetTransactionDetailsFirearmsData,
    transactionDetailsFirearmsContent,
    transactionDetailsFirearmsPaginator,
    handleGetTransactionDetailsAncillariesData,
    transactionDetailsAncillariesContent,
    transactionDetailsAncillariesPaginator,
    handleGetTransactionDetailsCommentsData,
    transactionDetailsCommentsContent,
    transactionDetailsCommentsPaginator,
    isTransactionDetailsCommentsDataLoading,
    checkIfTransactionIsLast,
  } = useTransactions();

  const { transactionAlert, clearAlert } = useAlert();
  const [isFrtInformationVisible, setIsFrtInformationVisible] = useState<boolean>(false);
  const [isArtInformationVisible, setIsArtInformationVisible] = useState<boolean>(false);
  const [isLastTransaction, setIsLastTransaction] = useState<boolean | null>(null);
  const [showOnMapPlace, setShowOnMapPlace] = useState(0);
  const { midDateFormat, longDateFormat, shortDateFormat, getDateFormat } =
    useGlobalProperty();
  const { getCustomField, customFieldsOLE } = useLegalEntities();
  const [isTransactionDiscardable, setIsTransactionDiscardable] = useState(false);
  const [isFirearmAncillaryPaginationLoading, setIsFirearmAncillaryPaginationLoading] =
    useState(false);
  const [isCommentPaginationLoading, setIsCommentPaginationLoading] = useState(false);

  const {
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      isShowHidden: false,
    },
  });
  const isShowHidden: boolean = watch('isShowHidden');

  useEffect(() => {
    if (transactionDetails) {
      if (
        transactionDetails.transactionItemType === ETransactionItemType.FIREARM &&
        transactionDetails.firearmGroupUniqueId
      ) {
        isGroupTransactionDiscardable(id).then((res) => setIsTransactionDiscardable(res));
      } else if (
        transactionDetails.transactionItemType === ETransactionItemType.ANCILLARY &&
        transactionDetails.ancillaryGroupUniqueId
      ) {
        isAncillaryGroupTransactionDiscardable(id).then((res) =>
          setIsTransactionDiscardable(res),
        );
      } else {
        setIsTransactionDiscardable(true);
      }
    }
  }, [id, transactionDetails]);

  useEffect(() => {
    getDateFormat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      await getCustomField(ECustomFieldObjectType.FIREARM);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async function loadDictionaryInit() {
      await loadDictionariesForTransactionDetails();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  useEffect(() => {
    if (transactionDetails) {
      checkIfTransactionIsLast(transactionDetails?.uniqueId).then((res) =>
        setIsLastTransaction(res.isLast),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionDetails]);

  useEffect(() => {
    (async function getTransactionDetailsInit() {
      if (id) {
        await handleGetTransactionDetailsData(id);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    (async function getTransactionDetailsFirearmsInit() {
      if (id && transactionDetails?.transactionItemType === ETransactionItemType.FIREARM) {
        setIsFirearmAncillaryPaginationLoading(true);
        await handleGetTransactionDetailsFirearmsData(
          id,
          currentPageFirearmsAncillaries,
          perPageFirearmsAncillaries,
          sortFirearmsAncillaries,
        );
        setIsFirearmAncillaryPaginationLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    id,
    sortFirearmsAncillaries,
    perPageFirearmsAncillaries,
    currentPageFirearmsAncillaries,
    transactionDetails,
  ]);

  useEffect(() => {
    (async function getTransactionDetailsAncillariesInit() {
      if (id && transactionDetails?.transactionItemType === ETransactionItemType.ANCILLARY) {
        setIsFirearmAncillaryPaginationLoading(true);
        await handleGetTransactionDetailsAncillariesData(
          id,
          currentPageFirearmsAncillaries,
          perPageFirearmsAncillaries,
          sortFirearmsAncillaries,
        );
        setIsFirearmAncillaryPaginationLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    id,
    sortFirearmsAncillaries,
    perPageFirearmsAncillaries,
    currentPageFirearmsAncillaries,
    transactionDetails,
  ]);

  useEffect(() => {
    (async function getTransactionDetailsCommentsDataInit() {
      if (id) {
        setIsCommentPaginationLoading(true);
        await handleGetTransactionDetailsCommentsData(
          id,
          currentPageComments,
          perPageComments,
          { withHidden: isShowHidden },
        );
        setIsCommentPaginationLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isShowHidden]);

  useEffect(
    () => () => clearAlert(ActionTypes.CLEAR_TRANSACTION_ALERT),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (transactionDetails) {
      if (shouldShowOnMap(transactionDetails.typeUniqueId)) {
        if (
          transactionDetails.latitude &&
          transactionDetails.longitude &&
          transactionDetails.initial &&
          !isLastTransaction
        ) {
          setShowOnMapPlace(1);
        } else if (
          transactionDetails.latitude &&
          transactionDetails.longitude &&
          transactionDetails.initial &&
          isLastTransaction
        ) {
          setShowOnMapPlace(1);
        } else if (transactionDetails.latitude && transactionDetails.longitude) {
          setShowOnMapPlace(2);
        }
      }
    }
  }, [transactionDetails, isLastTransaction]);

  useEffect(() => {
    const haveFirearmsSameFRT =
      !!transactionDetailsFirearmsContent.length &&
      transactionDetailsFirearmsContent.every(
        (f) => f.frtId === transactionDetailsFirearmsContent[0].frtId,
      );
    setIsFrtInformationVisible(haveFirearmsSameFRT);
  }, [transactionDetailsFirearmsContent]);

  useEffect(() => {
    const haveAncillariesSameART =
      !!transactionDetailsAncillariesContent.length &&
      transactionDetailsAncillariesContent.every(
        (f) => f.artUniqueId === transactionDetailsAncillariesContent[0].artUniqueId,
      );
    setIsArtInformationVisible(haveAncillariesSameART);
  }, [transactionDetailsAncillariesContent]);

  const renderShowFRTInformationButton = () => {
    if (isFrtInformationVisible && !transactionDetails?.firearmsWithDifferentMakesOrModels) {
      return (
        <Grid item xs={12} lg={6}>
          <Link
            href={`${ERouteLinks.FirearmsReferenceTableDetails}?frtId=${transactionDetailsFirearmsContent?.[0]?.frtUniqueId}`}
            underline="none"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              fullWidth
              label={t(
                'transactionDetails.firearmDetails.accordion.showFRTInformation.button',
              )}
              variant={EButtonVariants.contained}
              size={EButtonSizes.small}
            />
          </Link>
        </Grid>
      );
    }
    return null;
  };

  const renderShowARTInformationButton = () => {
    if (isArtInformationVisible && transactionDetails) {
      return (
        <Grid item xs={12} lg={6}>
          <Link
            href={`${ERouteLinks.ViewAncillaries.replace(
              ':type',
              transactionDetailsAncillariesContent?.[0]?.ancillaryType.toLowerCase(),
            ).replace(':id', transactionDetailsAncillariesContent?.[0]?.artUniqueId)}`}
            underline="none"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              fullWidth
              label={t(
                'transactionDetails.ancillaryDetails.accordion.showARTInformation.button',
              )}
              variant={EButtonVariants.contained}
              size={EButtonSizes.small}
            />
          </Link>
        </Grid>
      );
    }
    return null;
  };

  const renderAncillaryDetails = () => {
    const ancillary = transactionDetailsAncillariesContent[0];
    switch (ancillary.ancillaryType as EArtAncillaryTypeNameUpperCase) {
      case EArtAncillaryTypeNameUpperCase.ACCESSORY: {
        return <AccessoryDetails id={transactionDetailsAncillariesContent[0].uniqueId} />;
      }
      case EArtAncillaryTypeNameUpperCase.AMMUNITION: {
        return <AmmunitionDetails id={transactionDetailsAncillariesContent[0].uniqueId} />;
      }
      case EArtAncillaryTypeNameUpperCase.COMPONENT: {
        return <ComponentDetails id={transactionDetailsAncillariesContent[0].uniqueId} />;
      }
      default: {
        return null;
      }
    }
  };

  let frtDetailsTitle = '';
  if (
    transactionDetails?.transactionItemType === ETransactionItemType.FIREARM &&
    transactionDetailsFirearmsContent.length &&
    !transactionDetails?.firearmsWithDifferentMakesOrModels
  ) {
    const { make, model, type, action, caliber } = transactionDetailsFirearmsContent[0];
    frtDetailsTitle = `${make}, ${model}, ${type}, ${action}, ${caliber}`;
  } else if (
    transactionDetails?.transactionItemType === ETransactionItemType.ANCILLARY &&
    transactionDetailsAncillariesContent.length &&
    !transactionDetails?.firearmsWithDifferentMakesOrModels
  ) {
    const { ancillarySpecificType } = transactionDetailsAncillariesContent[0];
    frtDetailsTitle = ancillarySpecificType;
  }
  let showFrtDetails = true;
  if (
    (transactionDetails &&
      transactionDetails?.transactionItemType === ETransactionItemType.ANCILLARY &&
      transactionDetails?.ancillaryGroupUniqueId !== '') ||
    transactionDetailsFirearmsContent.length === 0
  ) {
    showFrtDetails = false;
  }

  let dataColor = EChipsVariants.primary;
  if (transactionDetails && transactionDetails.legalityUniqueId === ILLEGAL_FIREARM_ID) {
    dataColor = EChipsVariants.error;
  }
  if (transactionDetails && transactionDetails.activityUniqueId === INACTIVE_FIREARM_ID) {
    dataColor = EChipsVariants.inactive;
  }

  const navigateToFirearmsMap = () =>
    navigate(ERouteLinks.RegisteredFirearmsMap, {
      state: {
        transactionUniqueId: transactionDetails?.uniqueId,
        latitude: transactionDetails?.latitude,
        longitude: transactionDetails?.longitude,
        legalityUniqueId: transactionDetails?.legalityUniqueId,
        transactionFirearmsUniqueIds: transactionDetailsFirearmsContent.map(
          (firearm) => firearm.uniqueId,
        ),
        navigateBack: `${ERouteLinks.ViewTransaction.replace(':id', id as string)}`,
        showAdvanced: true,
        showLegalityBar: false,
        breadcrumbs: [
          {
            label: t('transactionDetails.transactionDetails.header'),
            route: ERouteLinks.Transactions,
          },
          {
            label: t('transactionDetails.transactionDetails.header'),
            route: `${ERouteLinks.ViewTransaction.replace(':id', id as string)}`,
          },
          t('registeredFirearms.firearmsLocation.showOnMap.label'),
        ],
      },
    });

  const navigateToAncillariesMap = () =>
    navigate(ERouteLinks.RegisteredAncillariesMap, {
      state: {
        transactionUniqueId: transactionDetails?.uniqueId,
        latitude: transactionDetails?.latitude,
        longitude: transactionDetails?.longitude,
        legalityUniqueId: transactionDetails?.legalityUniqueId,
        navigateBack: `${ERouteLinks.ViewTransaction.replace(':id', id as string)}`,
        showAdvanced: true,
        showLegalityBar: true,
        breadcrumbs: [
          {
            label: t('transactionDetails.transactionDetails.header'),
            route: ERouteLinks.Transactions,
          },
          {
            label: t('transactionDetails.transactionDetails.header'),
            route: `${ERouteLinks.ViewTransaction.replace(':id', id as string)}`,
          },
          t('ancillaries.ancillariesLocation.showOnMap.label'),
        ],
      },
    });

  return (
    <>
      <Breadcrumbs
        items={[
          {
            label: t('transaction.header'),
            route: ERouteLinks.Transactions,
          },
          t('transactionDetails.transactionDetails.header'),
        ]}
      />

      <StyledPageTitle variant="h4">
        {t('transactionDetails.transactionDetails.header')}
      </StyledPageTitle>

      {transactionDetails !== null ? (
        <Box my={3}>
          <StyledDetailsWrapper container rowGap={3} columns={10}>
            <Grid item xs={10} md={5} lg={2}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('transactionDetails.transactionType.label')}
              </Typography>
              <Grid sx={{ maxWidth: { md: '90%', xs: '100%' } }}>
                <Chips
                  text={
                    parseDictionary(transactionType, 'name')?.[
                      transactionDetails?.typeUniqueId
                    ]
                  }
                  variant={dataColor}
                />
              </Grid>
            </Grid>
            <Grid item xs={10} md={5} lg={2}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('transactionDetails.transactionDateAndTime.label')}
              </Typography>
              <Typography variant="body2">
                {formatDate(transactionDetails.date, longDateFormat)}
              </Typography>
            </Grid>

            <Grid item xs={10} md={5} lg={2}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('transactionDetails.createdOn.label')}
              </Typography>
              <Typography variant="body2">
                {formatDate(transactionDetails.createdDate, longDateFormat)}
              </Typography>
            </Grid>
            {(showOnMapPlace === 0 || showOnMapPlace === 2) && (
              <Grid item xs={10} md={5} lg={2}>
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t('transactionDetails.createdBy.label')}
                </Typography>
                <Typography variant="body2">{transactionDetails.createdBy}</Typography>
              </Grid>
            )}
            {hasPermissions([EPermission.DISCARD_TRANSACTION]) &&
              !transactionDetails.initial &&
              isLastTransaction &&
              isTransactionDiscardable && (
                <Grid
                  item
                  xs={10}
                  lg={2}
                  sx={{
                    borderTop: {
                      xs: `1px solid ${colors.gray200}`,
                    },
                    pt: {
                      xs: 3,
                      lg: 0,
                    },
                  }}
                >
                  <Button
                    label={t('transactionDetails.discard.button')}
                    variant={EButtonVariants.outlined}
                    onClick={() => navigate(ERouteLinks.CancelTransaction.replace(':id', id))}
                    fullWidth
                    size={EButtonSizes.small}
                  />
                </Grid>
              )}
            {showOnMapPlace === 1 && (
              <>
                <Grid
                  item
                  xs={10}
                  lg={4}
                  sx={{
                    pt: {
                      xs: 3,
                      lg: 0,
                    },
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    label={t('transactionDetails.showOnMap.button')}
                    variant={EButtonVariants.contained}
                    onClick={
                      transactionDetails.transactionItemType === ETransactionItemType.FIREARM
                        ? navigateToFirearmsMap
                        : navigateToAncillariesMap
                    }
                    fullWidth
                    size={EButtonSizes.small}
                  />
                </Grid>
                <Grid item xs={10} md={5} lg={2}>
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t('transactionDetails.createdBy.label')}
                  </Typography>
                  <Typography variant="body2">{transactionDetails.createdBy}</Typography>
                </Grid>
              </>
            )}
            {![
              ETransactionTypes.DECLARED_DISASSEMBLED,
              ETransactionTypes.DECLARED_MANUFACTURED,
              ETransactionTypes.DECLARED_LOT_BATCH_IMPORTED,
              ETransactionTypes.DECLARED_LOT_BATCH_MANUFACTURED,
              ETransactionTypes.DECLARED_LOT_BATCH_UNKNOWN_ORIGIN,
              ETransactionTypes.DECLARED_PIECEMEAL_UNKNOWN_ORIGIN,
              ETransactionTypes.RETURNED,
              ETransactionTypes.DECLARED_UNKNOWN_ORIGIN,
              ETransactionTypes.DECLARED_IMPORTED,
            ].includes(transactionDetails.typeUniqueId as ETransactionTypes) &&
              transactionDetails.address &&
              transactionDetails.latitude &&
              transactionDetails.longitude && (
                <>
                  <Grid item xs={10} md={5} lg={2}>
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t('transactionDetails.address.label')}
                    </Typography>
                    <Typography variant="body2">{transactionDetails.address}</Typography>
                  </Grid>
                  <Grid item xs={10} md={5} lg={2}>
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t('transactionDetails.latitude.label')}
                    </Typography>
                    <Typography variant="body2">{transactionDetails.latitude}</Typography>
                  </Grid>
                  <Grid item xs={10} md={5} lg={2}>
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t('transactionDetails.longitude.label')}
                    </Typography>
                    <Typography variant="body2">{transactionDetails.longitude}</Typography>
                  </Grid>
                </>
              )}
            {showOnMapPlace === 2 && (
              <Grid
                item
                xs={10}
                lg={!transactionDetails.initial && isLastTransaction ? 4 : 6}
                sx={{
                  pt: {
                    xs: 3,
                    lg: 0,
                  },
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  label={t('transactionDetails.showOnMap.button')}
                  variant={EButtonVariants.contained}
                  onClick={
                    transactionDetails.transactionItemType === ETransactionItemType.FIREARM
                      ? navigateToFirearmsMap
                      : navigateToAncillariesMap
                  }
                  size={EButtonSizes.small}
                  sx={{
                    minWidth: '100px',
                    width: !transactionDetails.initial && isLastTransaction ? '100%' : '67%',
                  }}
                />
              </Grid>
            )}
            {transactionDetails.countryOfImport &&
              transactionDetails.countryOfImport.translations && (
                <Grid item xs={10} md={5} lg={2}>
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t('transactionDetails.countryOfImport.label')}
                  </Typography>
                  <Typography variant="body2">
                    {getValidTranslationName(transactionDetails.countryOfImport.translations)}
                  </Typography>
                </Grid>
              )}
            {transactionDetails.importer && (
              <Grid item xs={10} md={5} lg={2}>
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t('transactionDetails.importer.label')}
                </Typography>
                <Typography variant="body2">{transactionDetails.importer}</Typography>
              </Grid>
            )}
            {transactionDetails.countryOfExport &&
              transactionDetails.countryOfExport.translations && (
                <Grid item xs={10} md={5} lg={2}>
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t('transactionDetails.countryOfExport.label')}
                  </Typography>
                  <Typography variant="body2">
                    {getValidTranslationName(transactionDetails.countryOfExport.translations)}
                  </Typography>
                </Grid>
              )}
            {transactionDetails.expirationDate && (
              <Grid item xs={10} md={5} lg={2}>
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {[ETransactionTypes.ASSIGNED_TEMPORARILY, ETransactionTypes.LEASED].includes(
                    transactionDetails.typeUniqueId as ETransactionTypes,
                  )
                    ? t('transactionDetails.expirationTime.label')
                    : t('transactionDetails.expirationDate.label')}
                </Typography>
                <Typography variant="body2">
                  {[ETransactionTypes.ASSIGNED_TEMPORARILY, ETransactionTypes.LEASED].includes(
                    transactionDetails.typeUniqueId as ETransactionTypes,
                  )
                    ? formatDate(transactionDetails?.expirationDate?.toString(), midDateFormat)
                    : formatDate(
                        transactionDetails?.expirationDate?.toString(),
                        shortDateFormat,
                      )}
                </Typography>
              </Grid>
            )}
            {transactionDetails?.typeUniqueId === ETransactionTypes.MODIFIED &&
              transactionDetails?.description && (
                <Grid item xs={12} md={12} lg={12}>
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t('ancillaries.transaction.modification.label')}
                  </Typography>
                  <Typography variant="body2">
                    {getHtmlText(transactionDetails.description)}
                  </Typography>
                </Grid>
              )}
          </StyledDetailsWrapper>
          <Grid item xs={12}>
            {transactionAlert && (
              <Box mt={2}>
                <Alert text={transactionAlert.text} variant={transactionAlert.variant} />
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', my: 2 }}>
            <StyledButton
              label={
                isAllExpanded
                  ? t('transactionDetails.collapseAll.link')
                  : t('transactionDetails.expandAll.link')
              }
              onClick={() => setIsAllExpanded((prev) => !prev)}
              variant={EButtonVariants.outlined}
              size={EButtonSizes.small}
            />
          </Grid>

          <Grid item xs={12}>
            <Accordion
              title={t('transactionDetails.ownerAndKeeper.accordion.name')}
              expanded={isAllExpanded}
            >
              <StyledDetailsWrapper container rowGap={3} mb={5}>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t('transactionDetails.ownerAndKeeper.accordion.owner.label')}
                  </Typography>
                  <Typography variant="body2">
                    <StyledLegalLink
                      legalId={transactionDetails.ownerId}
                      legalType={getLegalEntityType(transactionDetails.ownerType)}
                      labelText={transactionDetails.owner}
                      legalVisibilityUniqueId={transactionDetails.ownerVisibilityUniqueId}
                    />
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t('transactionDetails.ownerAndKeeper.accordion.keeper.label')}
                  </Typography>
                  <Typography variant="body2">
                    <StyledLegalLink
                      legalId={transactionDetails.keeperId}
                      legalType={getLegalEntityType(transactionDetails.keeperType)}
                      labelText={transactionDetails.keeper}
                      legalVisibilityUniqueId={transactionDetails.keeperVisibilityUniqueId}
                    />
                  </Typography>
                </Grid>
              </StyledDetailsWrapper>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <Accordion
              title={
                transactionDetails?.transactionItemType === ETransactionItemType.FIREARM
                  ? t('transactionDetails.firearmDetails.accordion.name')
                  : t('transactionDetails.ancillaryDetails.accordion.name')
              }
              name="firearmDetails"
              expanded={isAllExpanded}
            >
              {((transactionDetailsAncillariesContent &&
                transactionDetailsAncillariesContent.length === 0 &&
                transactionDetails?.transactionItemType === ETransactionItemType.ANCILLARY) ||
                (transactionDetailsFirearmsContent &&
                  transactionDetailsFirearmsContent.length === 0 &&
                  transactionDetails?.transactionItemType ===
                    ETransactionItemType.FIREARM)) && (
                <Box mt={2}>
                  <Alert
                    text={t('transactionDetails.noAssetPresent.message')}
                    variant={EAlertVariants.warning}
                  />
                </Box>
              )}
              {!transactionDetails.firearmsWithDifferentMakesOrModels && showFrtDetails && (
                <Grid
                  container
                  sx={{
                    border: `1px solid ${colors.primary25}`,
                    borderRadius: '4px',
                    overflow: 'hidden',
                  }}
                  mb={2}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      backgroundColor: colors.primary25,
                    }}
                    px={2}
                    py={1}
                  >
                    <Grid
                      item
                      xs={12}
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <Typography variant="h4" sx={{ fontWeight: 600 }}>
                        {frtDetailsTitle}
                      </Typography>
                      {transactionDetailsFirearmsContent.length === 1 &&
                        hasPermissions([EPermission.VIEW_FIREARMS]) && (
                          <Button
                            label={t(
                              'registeredFirearms.firearmsDetails.transactions.accordion.details.link',
                            )}
                            variant={EButtonVariants.outlined}
                            onClick={() =>
                              navigate(
                                `${ERouteLinks.ViewRegisteredFirearm.replace(
                                  ':id',
                                  transactionDetailsFirearmsContent?.[0].uniqueId as string,
                                )}`,
                              )
                            }
                            size="small"
                            fullWidth
                            sx={{ width: '16%', minWidth: '109px' }}
                          />
                        )}
                    </Grid>
                  </Grid>
                  <Grid item container p={2} rowSpacing={2}>
                    <Grid item xs={12} lg={6} container direction="column">
                      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {transactionDetails?.transactionItemType ===
                          ETransactionItemType.FIREARM &&
                          t(
                            'transactionDetails.firearmDetails.accordion.firearmsReferenceNo.label',
                          )}
                      </Typography>
                      <Typography variant="body2">
                        {transactionDetails?.transactionItemType ===
                          ETransactionItemType.FIREARM &&
                          transactionDetailsFirearmsContent?.[0]?.frtId}
                      </Typography>
                    </Grid>
                    {renderShowFRTInformationButton()}
                    {transactionDetailsFirearmsContent.length === 1 && (
                      <Grid
                        item
                        xs={12}
                        container
                        rowGap={3}
                        sx={{ borderTop: `1px solid ${colors.primary25}` }}
                        mt={3}
                      >
                        <Grid item xs={12} md={6} lg={3}>
                          <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                            {t(
                              'transactionDetails.firearmDetails.accordion.firecycleID.label',
                            )}
                          </Typography>
                          <Typography variant="body2">
                            {transactionDetailsFirearmsContent[0]?.fireCycleId || '-'}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                            {t(
                              'transactionDetails.firearmDetails.accordion.serialNumber.label',
                            )}
                          </Typography>
                          <Typography variant="body2">
                            {transactionDetailsFirearmsContent[0]?.serialNumber || '-'}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                            {t(
                              'transactionDetails.firearmDetails.accordion.externalReferenceNo.label',
                            )}
                          </Typography>
                          <Typography variant="body2">
                            {emptyFieldHolder(
                              transactionDetailsFirearmsContent[0]?.externalReferenceNumber,
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                            {t(
                              'transactionDetails.firearmDetails.accordion.manufacturedDate.label',
                            )}
                          </Typography>
                          <Typography variant="body2">
                            {formatDate(
                              transactionDetailsFirearmsContent[0]?.manufacturedAt,
                              shortDateFormat,
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                    {renderShowARTInformationButton()}
                  </Grid>
                </Grid>
              )}
              {transactionDetailsAncillariesContent &&
                transactionDetailsAncillariesContent.length === 1 &&
                renderAncillaryDetails()}
              <Grid item xs={12} container px={2} mb={2}>
                {transactionDetails.firearmsWithDifferentMakesOrModels && (
                  <Grid item xs={12} md={6} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t('transactionDetails.firearmDetails.accordion.firearmCount.label')}
                    </Typography>
                    <Typography variant="body2">
                      {transactionDetailsFirearmsContent.length}
                    </Typography>
                  </Grid>
                )}
                {transactionDetails?.transactionItemType === ETransactionItemType.FIREARM &&
                  transactionDetailsFirearmsContent.length > 0 &&
                  transactionDetails.firearmGroupName && (
                    <Grid item xs={12} md={6} container direction="column">
                      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {t('transactionDetails.firearmDetails.accordion.groupName.label')}
                      </Typography>
                      <Typography variant="body2">
                        {transactionDetails.firearmGroupName}
                      </Typography>
                    </Grid>
                  )}
                {transactionDetails?.transactionItemType === ETransactionItemType.ANCILLARY &&
                  transactionDetailsAncillariesContent.length > 0 &&
                  transactionDetails.ancillaryGroupName && (
                    <Grid item xs={12} md={6} container direction="column">
                      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {t('transactionDetails.firearmDetails.accordion.groupName.label')}
                      </Typography>
                      <Typography variant="body2">
                        {transactionDetails.ancillaryGroupName}
                      </Typography>
                    </Grid>
                  )}
              </Grid>
              {transactionDetails?.transactionItemType === ETransactionItemType.FIREARM &&
                transactionDetailsFirearmsContent.length > 1 && (
                  <Grid item xs={12}>
                    {isDesktop ? (
                      <Table
                        columns={transactionDetailsFirearmsTableColumns(
                          {
                            isDifferentMakesOrModels:
                              transactionDetails.firearmsWithDifferentMakesOrModels,
                            handleView: (uniqueId: string) =>
                              hasPermissions([EPermission.VIEW_FIREARMS])
                                ? navigate(
                                    `${ERouteLinks.ViewRegisteredFirearm.replace(
                                      ':id',
                                      uniqueId,
                                    )}`,
                                  )
                                : null,
                            customFields: customFieldsOLE,
                            t,
                          },
                          selectedLanguage?.uniqueId,
                          shortDateFormat,
                        )}
                        dataSource={mapTransactionDetailsFirearms(
                          transactionDetailsFirearmsContent,
                          shortDateFormat,
                        )}
                        onSort={setSortFirearmsAncillaries}
                        translationsKeys={ETransactionDetailsTranslationsKeys}
                      />
                    ) : (
                      <CardList
                        items={mapTransactionDetailsFirearms(
                          transactionDetailsFirearmsContent,
                          shortDateFormat,
                        )}
                        translationsKeys={ETransactionDetailsTranslationsKeys}
                        render={(data, index) => (
                          <TransactionDetailsFirearmsCard
                            data={data}
                            key={index}
                            isDifferentMakesOrModels={
                              transactionDetails.firearmsWithDifferentMakesOrModels
                            }
                            handleView={(uniqueId: string) =>
                              hasPermissions([EPermission.VIEW_FIREARMS])
                                ? navigate(
                                    ERouteLinks.ViewTransaction.replace(':id', uniqueId),
                                  )
                                : null
                            }
                          />
                        )}
                      />
                    )}

                    {transactionDetailsFirearmsPaginator && (
                      <Pagination
                        count={transactionDetailsFirearmsPaginator.totalElements}
                        onChangePage={(page) => setCurrentPageFirearmsAncillaries(page)}
                        current={currentPageFirearmsAncillaries}
                        perPage={perPageFirearmsAncillaries}
                        onChangePerPage={(value) => setPerPageFirearmsAncillaries(value)}
                        isLoading={isFirearmAncillaryPaginationLoading}
                      />
                    )}
                  </Grid>
                )}
              {transactionDetails?.transactionItemType === ETransactionItemType.ANCILLARY &&
                transactionDetailsAncillariesContent?.length > 1 && (
                  <Grid item xs={12}>
                    {isDesktop ? (
                      <Table
                        columns={transactionDetailsAncillariesTableColumns({
                          showDetail: hasPermissions([EPermission.VIEW_LIMITED_ANCILLARIES]),
                        })}
                        dataSource={mapTransactionDetailsAncillaries(
                          transactionDetailsAncillariesContent,
                          shortDateFormat,
                        )}
                        onSort={setSortFirearmsAncillaries}
                        translationsKeys={ETransactionDetailsTranslationsKeys}
                      />
                    ) : (
                      <CardList
                        items={mapTransactionDetailsAncillaries(
                          transactionDetailsAncillariesContent,
                          shortDateFormat,
                        )}
                        translationsKeys={ETransactionDetailsTranslationsKeys}
                        render={(data, index) => (
                          <TransactionDetailsAncillariesCard
                            data={data}
                            key={index}
                            showDetail={hasPermissions([EPermission.VIEW_LIMITED_ANCILLARIES])}
                          />
                        )}
                      />
                    )}
                    {transactionDetailsAncillariesPaginator && (
                      <Pagination
                        count={transactionDetailsAncillariesPaginator.totalElements}
                        onChangePage={(page) => setCurrentPageFirearmsAncillaries(page)}
                        current={currentPageFirearmsAncillaries}
                        perPage={perPageFirearmsAncillaries}
                        onChangePerPage={(value) => setPerPageFirearmsAncillaries(value)}
                        isLoading={isFirearmAncillaryPaginationLoading}
                      />
                    )}
                  </Grid>
                )}
            </Accordion>
          </Grid>
          {hasPermissions(EPermission.MANAGE_TRANSACTION_COMMENTS) && (
            <Grid item xs={12}>
              <Accordion
                title={t('transactionDetails.comments.accordion.name')}
                expanded={isAllExpanded}
                name="comments"
              >
                <Grid
                  item
                  xs={12}
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ marginBottom: { xs: '20px', sm: '16px' } }}
                  mb={5}
                >
                  <Grid item xs={12} sm={6}>
                    <FormCheckbox
                      name="isShowHidden"
                      label={t(
                        'transactionDetails.comments.accordion.showHiddenComments.label',
                      )}
                      control={control}
                      errors={errors}
                      withValidation={false}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{ display: 'flex', justifyContent: 'flex-end' }}
                  >
                    <Button
                      label={t('transactionDetails.comments.accordion.addComment.button')}
                      variant={EButtonVariants.contained}
                      icon={EIconTypes.plus}
                      onClick={() => {
                        navigate(
                          `${ERouteLinks.TransactionAddComment.replace(
                            ':id',
                            id as string,
                          )}?expand=comments`,
                        );
                      }}
                      fullWidth={isMobile}
                    />
                  </Grid>
                </Grid>
                {!isTransactionDetailsCommentsDataLoading ? (
                  transactionDetailsCommentsContent?.map((item) => (
                    <Box key={item.uniqueId} sx={{ marginBottom: '8px' }}>
                      <CommentAccordion
                        name="comments"
                        comment={item}
                        deleteLink={ERouteLinks.TransactionDeleteComment.replace(
                          ':id',
                          id,
                        ).replace(':commentId', item.uniqueId)}
                      />
                    </Box>
                  ))
                ) : (
                  <Loader isLoading={isTransactionDetailsCommentsDataLoading} />
                )}
                {!transactionDetailsCommentsContent?.length && (
                  <Typography
                    variant="subtitle2"
                    mb={2}
                    textAlign="center"
                    color={colors.primary100}
                  >
                    {t('legalEntities.individual.accordion.legalEntityTable.noResults.text')}
                  </Typography>
                )}
                {transactionDetailsCommentsPaginator && (
                  <Pagination
                    count={transactionDetailsCommentsPaginator?.totalElements}
                    perPage={perPageComments}
                    onChangePage={(page) => {
                      setCurrentPageComments(page);
                    }}
                    onChangePerPage={(value) => {
                      setPerPageComments(value);
                    }}
                    current={currentPageComments}
                    isLoading={isCommentPaginationLoading}
                  />
                )}
              </Accordion>
            </Grid>
          )}
        </Box>
      ) : (
        <Loader isLoading />
      )}
    </>
  );
};

export default ViewTransactionPage;
