import React, { useEffect } from 'react';
import {
  AuditLogDetailsWrapper,
  AuditLogEventName,
} from 'pages/AuditLogPage/AuditLogViewPage/styles';
import { Typography } from '@mui/material';
import { IAuditLogItemResponse } from 'models/auditLog';
import Grid from '@mui/material/Grid';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import Button from 'components/atoms/Button';
import { useTransactions } from 'pages/TransactionsPage/hooks';
import { useFirearms } from 'pages/Firearms/hooks';
import { EDictionaryTypes } from 'models/dictionary';
import { useLang } from 'models/langContext';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import { ERouteLinks } from 'models/route';
import { useNavigate } from 'react-router-dom';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { formatDate } from 'utils/date';
import { usePermission } from 'hooks/usePermission';
import { EPermission } from 'models/permissions';
import { useTranslations } from 'hooks/useTranslations';

interface ITransactionEventDetails {
  auditLog: IAuditLogItemResponse;
}

const TransactionEventDetails = ({ auditLog }: ITransactionEventDetails) => {
  const { transactionDetails, handleGetTransactionDetailsData } = useTransactions();
  const { loadDictionaries, transactionType } = useFirearms();
  const { selectedLanguage } = useLang();
  const navigate = useNavigate();
  const { longDateFormat, getDateFormat } = useGlobalProperty();
  const { t } = useTranslations();
  const { hasPermissions } = usePermission();

  useEffect(() => {
    (async () => {
      if (auditLog?.objectUniqueId && hasPermissions([EPermission.VIEW_TRANSACTIONS])) {
        await handleGetTransactionDetailsData(auditLog?.objectUniqueId, false);
      }
      getDateFormat();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async function init() {
      await loadDictionaries([EDictionaryTypes.TransactionType]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  let transactionName = '';
  if (transactionType) {
    const transactionTypeItem = transactionType.find(
      (type) => type.uniqueId === transactionDetails?.typeUniqueId,
    );
    if (transactionTypeItem) {
      transactionName = transactionTypeItem.name;
    }
  }

  return (
    <>
      <AuditLogEventName>
        <Typography variant="h5">{t('auditLogDetails.transactionsDetails.title')}</Typography>
      </AuditLogEventName>

      {transactionDetails && (
        <AuditLogDetailsWrapper
          container
          columns={10}
          rowSpacing={2}
          sx={{ marginTop: '-6px' }}
        >
          <Grid item xs={10} sm={5} lg={2}>
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('auditLogDetails.transactionsDetails.title')}
            </Typography>
            <Typography variant="body2">
              {transactionName ? (
                <Chips text={transactionName as string} variant={EChipsVariants.primary} />
              ) : (
                ''
              )}
            </Typography>
          </Grid>
          <Grid item xs={10} sm={5} lg={2}>
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('auditLogDetails.transactionsDetails.transactionDateAndTime')}
            </Typography>
            <Typography variant="body2">
              {transactionDetails?.date
                ? formatDate(transactionDetails.date, longDateFormat)
                : undefined}
            </Typography>
          </Grid>
          <Grid item xs={10} sm={5} lg={2}>
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('auditLogDetails.transactionsDetails.createdOn')}
            </Typography>
            <Typography variant="body2">
              {formatDate(transactionDetails.createdDate, longDateFormat)}
            </Typography>
          </Grid>
          <Grid item xs={10} sm={5} lg={2}>
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('auditLogDetails.transactionsDetails.createdBy')}
            </Typography>
            <Typography variant="body2">{transactionDetails?.createdBy}</Typography>
          </Grid>
          {transactionDetails?.visible && (
            <Grid item xs={10} sm={5} lg={2}>
              <Button
                variant={EButtonVariants.outlined}
                size={EButtonSizes.small}
                onClick={() =>
                  navigate(
                    ERouteLinks.ViewTransaction.replace(':id', transactionDetails.uniqueId),
                  )
                }
                label={t('auditLogDetails.transactionsDetails.details')}
                type="button"
                fullWidth
                sx={{
                  mt: {
                    lg: 1,
                    sm: 3.5,
                  },
                }}
              />
            </Grid>
          )}
        </AuditLogDetailsWrapper>
      )}
    </>
  );
};

export default TransactionEventDetails;
