import React from 'react';
import {
  AssignedLETemplate,
  EAssignedLETemplateTypes,
} from 'components/templates/AssignedLETemplate';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import { IAssignLE } from 'models/legalEntity';

export enum EAssignLEPageTranslationsKeys {
  assignLegalEntityHeader = 'assignLegalEntity.header',
  assignLegalEntityInfo = 'assignLegalEntity.info.text',
  assignLegalEntityInfo2 = 'assignLegalEntity.info2.text',
  deleteLegalEntityHeader = 'deleteLegalEntity.header',
  deleteLegalEntityInfo = 'deleteLegalEntityAssociation.info.text',
  assignLegalEntityError = 'assignLegalEntity.error.text',
}

const AssignLEPage = () => {
  const { handleAssignLE } = useLegalEntities();

  return (
    <AssignedLETemplate
      type={EAssignedLETemplateTypes.create}
      onSubmit={async (data: IAssignLE) => handleAssignLE(data)}
      translationsKeys={EAssignLEPageTranslationsKeys}
    />
  );
};

export { AssignLEPage };
