import React from 'react';
import { useTranslations } from 'hooks/useTranslations';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { Chips } from 'components/atoms/Chips';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { useNavigate } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import { IDictionary } from 'models/dictionary';
import { IAccessory, IAncillary } from 'models/ancillaries';
import { parseDictionary } from 'helpers/dictionary';
import { LEGAL_ID } from 'pages/Firearms/RegisteredFirearmsMapPage/FirearmsLocationMap';
import { renderDataColorByValues } from 'components/molecules/Table/helpers';

interface IAncillaryDataListItem {
  data: IAccessory | IAncillary;
  state: IDictionary[] | null;
}

const AccessoryDataListItem = ({ data, state }: IAncillaryDataListItem) => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const isLegal = data.legalityUniqueId === LEGAL_ID;

  return (
    <Grid
      container
      sx={{
        border: `1px solid ${colors.gray200}`,
        borderRadius: '4px',
        overflow: 'hidden',
        mt: 1,
        backgroundColor: `${isLegal ? colors.gray100 : colors.error15}`,
      }}
    >
      <Grid item xs={12} md={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('ancillaries.ancillariesLocation.accessoriesDetails.section.serialNumber.label')}
        </Typography>
        <Typography variant="body2">{data.serialNumber || '-'}</Typography>
      </Grid>

      <Grid item xs={12} md={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('ancillaries.ancillariesLocation.accessoriesDetails.section.firecycleId.label')}
        </Typography>
        <Typography variant="body2">{data.fireCycleId || '-'}</Typography>
      </Grid>

      <Grid item xs={12} md={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('ancillaries.ancillariesLocation.accessoriesDetails.section.accessoryType.label')}
        </Typography>
        <Typography variant="body2">{data.type || '-'}</Typography>
      </Grid>

      <Grid item xs={12} md={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('ancillaries.ancillariesLocation.accessoriesDetails.section.model.label')}
        </Typography>
        <Typography variant="body2">{data.hostType || '-'}</Typography>
      </Grid>

      <Grid item xs={12} md={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('ancillaries.ancillariesLocation.accessoriesDetails.section.state.label')}
        </Typography>
        <Chips
          text={parseDictionary(state, 'name')[data.stateUniqueId]}
          variant={
            renderDataColorByValues(
              data.activityUniqueId,
              data.legalityUniqueId,
              data.stateUniqueId,
            ).stateField
          }
        />
      </Grid>

      <Grid
        container
        sx={{
          margin: '0 10px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTop: {
            xs: `1px solid ${colors.gray200}`,
          },
        }}
        p={2}
      >
        <Grid item xs={12} sm={12}>
          <Button
            label={t('registeredFirearms.firearmsLocation.seeDetails.label')}
            variant={EButtonVariants.outlined}
            onClick={() =>
              navigate(`${ERouteLinks.ViewAccessories.replace(':id', data.uniqueId)}`)
            }
            size="small"
            fullWidth
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AccessoryDataListItem;
