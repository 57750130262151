import { useAlert } from 'models/alertContext';
import {
  IAccessoriesAttachments,
  IAccessoriesComments,
  IAccessoriesTransactions,
  IAccessory,
  IAccessoryDetails,
  IAmmunition,
  IAmmunitionAttachments,
  IAmmunitionComments,
  IAmmunitionDetails,
  IAmmunitionTransactions,
  IAncillariesReference,
  IAncillary,
  IARTAccessories,
  IARTAccessoriesFormValues,
  IARTAmmunition,
  IARTComponent,
  IARTComponentsFormValues,
  IComponent,
  IComponentDetails,
  IComponentsComments,
  IComponentsTransactions,
} from 'models/ancillaries';
import { EDictionaryTypes } from 'models/dictionary';
import { useDictionary } from 'models/dictionaryContext';
import { IPaginator } from 'models/paginator';
import { EPerPages, ISortOptions } from 'models/table';
import { useState } from 'react';
import {
  createARTAccessory,
  createARTAmmunition,
  createARTComponent,
  getAccessoriesComments,
  getAccessory,
  getAmmunition,
  getAmmunitionComments,
  getAmmunitionReferenceTable,
  getAmmunitionTransactions,
  getAncillaries,
  getAncillariesAccessories,
  getAncillariesAmmunition,
  getAncillariesByGroupIdSearch,
  getAncillariesComponents,
  getAncillariesForMap,
  getAncillariesGroupSearch,
  getAncillariesReferenceAccessory,
  getAncillariesTransactions,
  getAncillaryAttachments,
  getARTAccessory,
  getARTAmmunition,
  getARTComponent,
  getComponent,
  getComponentsComments,
  getComponentsReferenceTable,
  updateARTAccessory,
  updateARTAmmunition,
  updateARTComponent,
} from 'requests/ancillaries';
import { ActionTypes } from 'state/actions/alert';
import { useTranslations } from 'hooks/useTranslations';
import { IAmmunitionFormValues } from 'pages/AncillariesPage/AncillariesReferenceTablePage/AncillariesReferenceAmmunitionSearchForm';
import { clearObject } from 'helpers/objects';
import { EArtAncillaryType, EArtAncillaryTypeName } from 'constants/ArtAccessoryType';
import { createAncillaryComment, deleteComment } from 'requests/comment';
import { ICommentFormValues } from 'components/organisms/CommentForm';
import {
  addAncillaryAttachment,
  deleteAncillaryAttachment,
  previewAncillaryAttachment,
} from 'requests/attachment';
import { ECustomFieldObjectType, ICustomFieldByObjectType } from 'models/customField';
import { getCustomFieldByObjectType } from 'requests/customField';
import {
  getAncillaryLastTransaction,
  isAncillaryGroupTransactionDiscardable,
} from 'requests/transaction';
import { apiClient } from 'utils/apiClient';
import { ApiEndpoints } from 'models/apiEndpoints';
import { ILastTransactionUniqueId } from 'models/transaction';
import { EAlertVariants } from 'components/atoms/Alert';
import { usePermission } from 'hooks/usePermission';
import { EPermission } from 'models/permissions';

export const useAncillaries = () => {
  const [paginatorAccessories, setPaginatorAccessories] = useState<IPaginator | null>(null);
  const [paginatorAmmunition, setPaginatorAmmunition] = useState<IPaginator | null>(null);
  const [paginatorAncillariesReference, setPaginatorAncillariesReference] =
    useState<IPaginator | null>(null);
  const [paginatorAccessoriesAttachments, setPaginatorAccessoriesAttachments] =
    useState<IPaginator | null>(null);
  const [paginatorAmmunitionAttachments, setPaginatorAmmunitionAttachments] =
    useState<IPaginator | null>(null);
  const [paginatorComponentsAttachments, setPaginatorComponentsAttachments] =
    useState<IPaginator | null>(null);
  const [paginatorComponents, setPaginatorComponents] = useState<IPaginator | null>(null);
  const [paginatorAncillaries, setPaginatorAncillaries] = useState<IPaginator | null>(null);
  const [accessories, setAccessories] = useState<IAccessory[] | []>([]);
  const [ammunition, setAmmunition] = useState<IAmmunition[] | []>([]);
  const [components, setComponents] = useState<IComponent[] | []>([]);
  const [ancillaries, setAncillaries] = useState<IAncillary[]>([]);
  const [artAccessoryDetail, setArtAccessoryDetail] = useState<IARTAccessories | undefined>(
    undefined,
  );
  const [artAmmunitionDetail, setArtAmmunitionDetail] = useState<IARTAmmunition>();
  const [artComponentDetail, setArtComponentDetail] = useState<IARTComponent>();
  const [accessoryDetail, setAccessoryDetail] = useState<IAccessoryDetails | undefined>(
    undefined,
  );
  const [ammunitionDetail, setAmmunitionDetail] = useState<IAmmunitionDetails | undefined>(
    undefined,
  );
  const [componentDetail, setComponentDetail] = useState<IComponentDetails | undefined>(
    undefined,
  );
  const [ancillaryOption, setAncillaryOption] = useState<string | null>(null);
  const [ancillariesReference, setAncillariesReference] = useState<
    IAncillariesReference[] | IARTAmmunition[] | IARTComponent[] | []
  >([]);
  const [paginatorAccessoriesTransactions, setPaginatorAccessoriesTransactions] =
    useState<IPaginator | null>(null);
  const [paginatorAmmunitionTransactions, setPaginatorAmmunitionTransactions] =
    useState<IPaginator | null>(null);
  const [accessoriesTransactions, setAccessoriesTransactions] = useState<
    IAccessoriesTransactions[]
  >([]);
  const [ammunitionTransactions, setAmmunitionTransactions] = useState<
    IAmmunitionTransactions[]
  >([]);
  const [componentsTransactions, setComponentsTransactions] = useState<
    IComponentsTransactions[]
  >([]);
  const [paginatorAccessoriesComments, setPaginatorAccessoriesComments] =
    useState<IPaginator | null>(null);
  const [paginatorComponentsTransactions, setPaginatorComponentsTransactions] =
    useState<IPaginator | null>(null);
  const [accessoriesComments, setAccessoriesComments] = useState<IAccessoriesComments[]>([]);
  const [paginatorAmmunitionComments, setPaginatorAmmunitionComments] =
    useState<IPaginator | null>(null);
  const [ammunitionComments, setAmmunitionComments] = useState<IAmmunitionComments[]>([]);
  const [paginatorComponentsComments, setPaginatorComponentsComments] =
    useState<IPaginator | null>(null);
  const [componentsComments, setComponentsComments] = useState<IComponentsComments[]>([]);
  const [discardableTransactionId, setDiscardableTransactionId] = useState<string | null>(
    null,
  );

  const [accessoriesAttachments, setAccessoriesAttachments] = useState<
    IAccessoriesAttachments[]
  >([]);

  const [ammunitionAttachments, setAmmunitionAttachments] = useState<IAmmunitionAttachments[]>(
    [],
  );
  const [componentsAttachments, setComponentsAttachments] = useState<IAmmunitionAttachments[]>(
    [],
  );
  const [customFieldsAccessories, setCustomFieldsAccessories] = useState<
    ICustomFieldByObjectType[]
  >([]);
  const [customFieldsAmmunition, setCustomFieldsAmmunition] = useState<
    ICustomFieldByObjectType[]
  >([]);
  const [customFieldsComponents, setCustomFieldsComponents] = useState<
    ICustomFieldByObjectType[]
  >([]);
  const [customFieldsLoaded, setCustomFieldsLoaded] = useState(false);
  const [ancillaryIsEmpty, setAncillaryIsEmpty] = useState<boolean>(false);

  const { setAlert } = useAlert();
  const { t } = useTranslations();

  const {
    state,
    getState,
    legality,
    getLegality,
    getTransactionType,
    transactionType,
    artAncillaryOptions,
    artAccessoryCalibre,
    artAccessoryComposition,
    artAccessoryHostType,
    artAccessoryLightColour,
    artAccessoryMagnification,
    artAccessoryManufacturerFlashEliminator,
    artAccessoryManufacturerLaserLightModule,
    artAccessoryManufacturerMagazine,
    artAccessoryManufacturerOpticalSight,
    artAccessoryManufacturerSuppressor,
    artAccessoryReticle,
    artAccessoryType,
    artAccessoryUtility,
    artAccessoryProofHouse,
    make,
    model,
    manufacturer,
    firearmType,
    artComposition,
    getArtAncillaryOptions,
    getArtAccessoryCalibre,
    getArtAccessoryComposition,
    getArtAccessoryHostType,
    getArtAccessoryLightColour,
    getArtAccessoryMagnification,
    getArtAccessoryManufacturerFlashEliminator,
    getArtAccessoryManufacturerLaserLightModule,
    getArtAccessoryManufacturerMagazine,
    getArtAccessoryManufacturerOpticalSight,
    getArtAccessoryManufacturerSuppressor,
    getArtAccessoryReticle,
    getArtAccessoryType,
    getArtAccessoryUtility,
    getArtAccessoryProofHouse,
    artAmmunitionType,
    getArtAmmunitionType,
    artAmmunitionManufacturer,
    getArtAmmunitionManufacturer,
    artAmmunitionFactory,
    getArtAmmunitionFactory,
    artAmmunitionCalibreGauge,
    getArtAmmunitionCalibreGauge,
    artAmmunitionCartridgeComposition,
    getArtAmmunitionCartridgeComposition,
    artAmmunitionColourOfPrimerSealant,
    getArtAmmunitionColourOfPrimerSealant,
    artAmmunitionProjectileShape,
    getArtAmmunitionProjectileShape,
    artAmmunitionFunctionalType,
    getArtAmmunitionFunctionalType,
    artAmmunitionShotSize,
    getArtAmmunitionShotSize,
    artAmmunitionMunitionType,
    getArtAmmunitionMunitionType,
    artAmmunitionMunitionVelocity,
    getArtAmmunitionMunitionVelocity,
    getFirearmType,
    getManufacturer,
    getModel,
    getMake,
    artComponentType,
    getArtComponentType,
    artComponentManufacturer,
    getArtComponentManufacturer,
    artComponentCalibre,
    getArtComponentCalibre,
    artComponentCapacity,
    getArtComponentCapacity,
    artComponentProofHouse,
    getArtComponentProofHouse,
    getArtComposition,
  } = useDictionary();

  const { hasPermissions } = usePermission();

  const loadDictionaries = async (types: EDictionaryTypes[]) => {
    if (types.includes(EDictionaryTypes.ArtAncillaryOptions)) {
      getArtAncillaryOptions();
    }
    if (types.includes(EDictionaryTypes.Legality)) {
      getLegality();
    }
    if (types.includes(EDictionaryTypes.State)) {
      getState();
    }
    if (types.includes(EDictionaryTypes.TransactionType)) {
      getTransactionType();
    }
    if (types.includes(EDictionaryTypes.ArtAccessoryCalibre)) {
      getArtAccessoryCalibre();
    }
    if (types.includes(EDictionaryTypes.ArtaccessoryComposition)) {
      getArtAccessoryComposition();
    }
    if (types.includes(EDictionaryTypes.ArtAccessoryHostType)) {
      getArtAccessoryHostType();
    }
    if (types.includes(EDictionaryTypes.ArtAccessoryLightColour)) {
      getArtAccessoryLightColour();
    }
    if (types.includes(EDictionaryTypes.ArtAccessoryMagnification)) {
      getArtAccessoryMagnification();
    }
    if (types.includes(EDictionaryTypes.ArtAccessoryManufacturerFlashEliminator)) {
      getArtAccessoryManufacturerFlashEliminator();
    }
    if (types.includes(EDictionaryTypes.ArtAccessoryManufacturerLaserLightModule)) {
      getArtAccessoryManufacturerLaserLightModule();
    }
    if (types.includes(EDictionaryTypes.ArtAccessoryManufacturerMagazine)) {
      getArtAccessoryManufacturerMagazine();
    }
    if (types.includes(EDictionaryTypes.ArtAccessoryManufacturerOpticalSight)) {
      getArtAccessoryManufacturerOpticalSight();
    }
    if (types.includes(EDictionaryTypes.ArtAccessoryManufacturerSuppressor)) {
      getArtAccessoryManufacturerSuppressor();
    }
    if (types.includes(EDictionaryTypes.ArtAccessoryReticle)) {
      getArtAccessoryReticle();
    }
    if (types.includes(EDictionaryTypes.ArtAccessoryType)) {
      getArtAccessoryType();
    }
    if (types.includes(EDictionaryTypes.ArtAccessoryUtility)) {
      getArtAccessoryUtility();
    }
    if (types.includes(EDictionaryTypes.ArtAccessoryProofHouse)) {
      getArtAccessoryProofHouse();
    }
    if (types.includes(EDictionaryTypes.ArtAmmunitionType)) {
      getArtAmmunitionType();
    }
    if (types.includes(EDictionaryTypes.ArtAmmunitionManufacturer)) {
      getArtAmmunitionManufacturer();
    }
    if (types.includes(EDictionaryTypes.ArtAmmunitionFactory)) {
      getArtAmmunitionFactory();
    }
    if (types.includes(EDictionaryTypes.ArtAmmunitionCalibreGauge)) {
      getArtAmmunitionCalibreGauge();
    }
    if (types.includes(EDictionaryTypes.ArtAmmunitionCartridgeComposition)) {
      getArtAmmunitionCartridgeComposition();
    }
    if (types.includes(EDictionaryTypes.ArtAmmunitionColourOfPrimerSealant)) {
      getArtAmmunitionColourOfPrimerSealant();
    }
    if (types.includes(EDictionaryTypes.ArtAmmunitionProjectileShape)) {
      getArtAmmunitionProjectileShape();
    }
    if (types.includes(EDictionaryTypes.ArtAmmunitionFunctionalType)) {
      getArtAmmunitionFunctionalType();
    }
    if (types.includes(EDictionaryTypes.ArtAmmunitionShotSize)) {
      getArtAmmunitionShotSize();
    }
    if (types.includes(EDictionaryTypes.ArtAmmunitionMunitionType)) {
      getArtAmmunitionMunitionType();
    }
    if (types.includes(EDictionaryTypes.ArtAmmunitionMunitionVelocity)) {
      getArtAmmunitionMunitionVelocity();
    }
    if (types.includes(EDictionaryTypes.ArtComponentType)) {
      getArtComponentType();
    }
    if (types.includes(EDictionaryTypes.ArtComponentManufacturer)) {
      getArtComponentManufacturer();
    }
    if (types.includes(EDictionaryTypes.ArtComponentCalibre)) {
      getArtComponentCalibre();
    }
    if (types.includes(EDictionaryTypes.ArtComponentCapacity)) {
      getArtComponentCapacity();
    }
    if (types.includes(EDictionaryTypes.ArtComponentProofHouse)) {
      getArtComponentProofHouse();
    }
    if (types.includes(EDictionaryTypes.ArtComposition)) {
      getArtComposition();
    }
    if (types.includes(EDictionaryTypes.FirearmType)) {
      getFirearmType();
    }
    if (types.includes(EDictionaryTypes.Manufacturer)) {
      getManufacturer();
    }
    if (types.includes(EDictionaryTypes.Model)) {
      getModel();
    }
    if (types.includes(EDictionaryTypes.Make)) {
      getMake();
    }
  };

  const getAncillariesData = async (
    type: string,
    currentPage: number = 1,
    perPage: EPerPages = EPerPages.perPage25,
    query: Partial<any> = {},
    sort?: ISortOptions | null,
  ) => {
    const { owner, keeper, ...queryWithoutOwnerAndKeeper } = query;
    const ancillariesData = await getAncillaries(
      currentPage,
      perPage,
      {
        ...queryWithoutOwnerAndKeeper,
        ancillaryDictionaryUniqueId: type,
      },
      sort,
    );
    if (ancillariesData) {
      const { content, ...paginatorData } = ancillariesData;
      setAncillaries(content);
      setPaginatorAncillaries(paginatorData);
    } else {
      setAncillaries([]);
      setPaginatorAncillaries(null);
    }
  };

  const getAncillariesGroupSearchData = async (
    legalUniqueId: string,
    type: string,
    ancillariesList: IAncillary[],
    currentPage: number = 1,
    perPage: EPerPages = EPerPages.perPage25,
    query: Partial<any> = {},
  ) => {
    const { owner, keeper, ...queryWithoutOwnerAndKeeper } = query;
    const ancillariesData = await getAncillariesGroupSearch(
      legalUniqueId,
      currentPage,
      perPage,
      {
        ...queryWithoutOwnerAndKeeper,
        ancillaryDictionaryUniqueId: type,
        ...(ancillariesList && ancillariesList.length
          ? { uniqueIds: ancillariesList.map((a) => a.uniqueId) }
          : {}),
      },
    );
    if (ancillariesData) {
      const { content, ...paginatorData } = ancillariesData;
      setAncillaries(content);
      setPaginatorAncillaries(paginatorData);
    } else {
      setAncillaries([]);
      setPaginatorAncillaries(null);
    }
  };

  const getAncillariesByGroupIdSearchData = async (
    legalUniqueId: string,
    groupUniqueId: string,
    type: string,
    currentPage: number = 1,
    perPage: EPerPages = EPerPages.perPage25,
    query: Partial<any> = {},
  ) => {
    const { owner, keeper, ...queryWithoutOwnerAndKeeper } = query;
    const ancillariesData = await getAncillariesByGroupIdSearch(
      legalUniqueId,
      groupUniqueId,
      currentPage,
      perPage,
      {
        ...queryWithoutOwnerAndKeeper,
        ancillaryDictionaryUniqueId: type,
      },
    );
    if (ancillariesData) {
      const { content, ...paginatorData } = ancillariesData;
      setAncillaries(content);
      setPaginatorAncillaries(paginatorData);
    } else {
      setAncillaries([]);
      setPaginatorAncillaries(null);
    }
  };

  const getAccessoriesData = async (
    currentPage: number = 1,
    perPage: EPerPages = EPerPages.perPage25,
    query: Partial<any> = {},
    sort?: ISortOptions | null,
  ) => {
    const { owner, keeper, ...queryWithoutOwnerAndKeeper } = query;
    const accessoriesData = await getAncillariesAccessories(
      EArtAncillaryTypeName.ACCESSORY,
      currentPage,
      perPage,
      {
        ...queryWithoutOwnerAndKeeper,
        ancillaryDictionaryUniqueId: EArtAncillaryType.ACCESSORIES,
      },
      sort,
    );
    if (accessoriesData) {
      const { content, ...paginatorData } = accessoriesData;
      setAccessories(content);
      setPaginatorAccessories(paginatorData);
      setAncillaryIsEmpty(paginatorData.empty);
    } else {
      setAccessories([]);
      setPaginatorAccessories(null);
      setAncillaryIsEmpty(false);
    }
  };

  const getAccessoriesDataForMap = async (query: Partial<any> = {}) => {
    const { owner, keeper, ...queryWithoutOwnerAndKeeper } = query;
    const accessoriesData = (await getAncillariesForMap({
      ...queryWithoutOwnerAndKeeper,
      ancillaryDictionaryUniqueId: EArtAncillaryType.ACCESSORIES,
    })) as IAccessory[];
    if (accessoriesData) {
      setAccessories(accessoriesData);
      setAncillaryIsEmpty(accessoriesData.length === 0);
    } else {
      setAccessories([]);
      setAncillaryIsEmpty(false);
    }
  };

  const getAmmunitionDataForMap = async (query: Partial<any> = {}) => {
    const { owner, keeper, ...queryWithoutOwnerAndKeeper } = query;
    const ammunitionData = (await getAncillariesForMap({
      ...queryWithoutOwnerAndKeeper,
      ancillaryDictionaryUniqueId: EArtAncillaryType.AMMUNITION,
    })) as IAmmunition[];
    if (ammunitionData) {
      setAmmunition(ammunitionData);
      setAncillaryIsEmpty(ammunitionData.length === 0);
    } else {
      setAmmunition([]);
      setAncillaryIsEmpty(false);
    }
  };

  const getComponentsDataForMap = async (query: Partial<any> = {}) => {
    const { owner, keeper, ...queryWithoutOwnerAndKeeper } = query;
    const componentsData = (await getAncillariesForMap({
      ...queryWithoutOwnerAndKeeper,
      ancillaryDictionaryUniqueId: EArtAncillaryType.COMPONENTS,
    })) as IComponent[];
    if (componentsData) {
      setComponents(componentsData);
      setAncillaryIsEmpty(componentsData.length === 0);
    } else {
      setComponents([]);
      setAncillaryIsEmpty(false);
    }
  };

  const getAncillariesDataForMap = async (
    transactionUniqueId: string,
    legalityUniqueId?: string,
  ) => {
    const ancillariesData = await getAncillariesForMap({
      transactionUniqueId,
      legalityUniqueId,
    });
    if (ancillariesData) {
      setAncillaries(ancillariesData);
      setAncillaryIsEmpty(false);
    } else {
      setAncillaries([]);
      setAncillaryIsEmpty(true);
    }
  };

  const getComponentsData = async (
    currentPage: number = 1,
    perPage: EPerPages = EPerPages.perPage25,
    query: Partial<any> = {},
    sort?: ISortOptions | null,
  ) => {
    const { owner, keeper, ...queryWithoutOwnerAndKeeper } = query;
    const componentsData = await getAncillariesComponents(
      EArtAncillaryTypeName.COMPONENTS,
      currentPage,
      perPage,
      {
        ...queryWithoutOwnerAndKeeper,
        ancillaryDictionaryUniqueId: EArtAncillaryType.COMPONENTS,
      },
      sort,
    );
    if (componentsData) {
      const { content, ...paginatorData } = componentsData;
      setComponents(content);
      setPaginatorComponents(paginatorData);
      setAncillaryIsEmpty(paginatorData.empty);
    } else {
      setComponents([]);
      setPaginatorComponents(null);
      setAncillaryIsEmpty(false);
    }
  };

  const getAncillariesReferenceAccessoryData = async (
    currentPage: number = 1,
    perPage: EPerPages = EPerPages.perPage25,
    query: Partial<any> = {},
    sort?: ISortOptions | null,
  ) => {
    const data = await getAncillariesReferenceAccessory(currentPage, perPage, query, sort);
    if (data) {
      const { content, ...paginatorData } = data;
      setAncillariesReference(content);
      setPaginatorAncillariesReference(paginatorData);
    } else {
      setAncillariesReference([]);
      setPaginatorAncillariesReference(null);
    }
  };

  const getAncillariesReferenceAmmunitionData = async (
    currentPage: number = 1,
    perPage: EPerPages = EPerPages.perPage25,
    query: Partial<any> = {},
    sort?: ISortOptions | null,
  ) => {
    const data = await getAmmunitionReferenceTable(currentPage, perPage, query, sort);
    if (data) {
      const { content, ...paginatorData } = data;
      setAncillariesReference(content);
      setPaginatorAncillariesReference(paginatorData);
    } else {
      setAncillariesReference([]);
      setPaginatorAncillariesReference(null);
    }
  };

  const getAncillariesReferenceComponentsData = async (
    currentPage: number = 1,
    perPage: EPerPages = EPerPages.perPage25,
    query: Partial<any> = {},
    sort?: ISortOptions | null,
  ) => {
    const data = await getComponentsReferenceTable(currentPage, perPage, query, sort);
    if (data) {
      const { content, ...paginatorData } = data;
      setAncillariesReference(content);
      setPaginatorAncillariesReference(paginatorData);
    } else {
      setAncillariesReference([]);
      setPaginatorAncillariesReference(null);
    }
  };

  const getARTAccessoryDetail = async (id: string): Promise<any[] | null> => {
    const accessoryDetailData = await getARTAccessory(id);
    if (accessoryDetailData) {
      setArtAccessoryDetail(accessoryDetailData);
      return accessoryDetailData;
    }
    setArtAccessoryDetail(undefined);
    return null;
  };

  const handleCreateAccessory = async (data: IARTAccessoriesFormValues) => {
    const response = await createARTAccessory(data);
    if (response.status === 200) {
      setAlert(ActionTypes.SET_DICTIONARY_ALERT, {
        text: t('addART.success.text'),
      });
    }
    return response;
  };

  const handleCreateAmmunition = async (data: IAmmunitionFormValues) => {
    const response = await createARTAmmunition(clearObject(data));
    if (response.status === 200) {
      setAlert(ActionTypes.SET_DICTIONARY_ALERT, {
        text: t('addART.success.text'),
      });
    }
    return response;
  };

  const getARTAmmunitionDetail = async (id: string): Promise<any[] | null> => {
    const ammunitionDetailData = await getARTAmmunition(id);
    if (ammunitionDetailData) {
      setArtAmmunitionDetail(ammunitionDetailData);
      return ammunitionDetailData;
    }
    setArtAmmunitionDetail(undefined);
    return null;
  };

  const handleUpdateAccessory = async (data: IARTAccessoriesFormValues, id: string) => {
    try {
      const response = await updateARTAccessory(data, id);
      setAlert(ActionTypes.SET_ANCILLARY_ALERT, {
        text: t('updateARTAccessory.success.text'),
      });
      return response;
    } catch {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: t('ARTUsedSpecificationEditionProhibited'),
        variant: EAlertVariants.error,
      });
      return false;
    }
  };

  const handleUpdateAmmunition = async (data: IAmmunitionFormValues, id: string) => {
    try {
      const response = await updateARTAmmunition(data, id);
      setAlert(ActionTypes.SET_ANCILLARY_ALERT, {
        text: t('updateARTAmmunition.success.text'),
      });
      return response;
    } catch {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: t('ARTUsedSpecificationEditionProhibited'),
        variant: EAlertVariants.error,
      });
      return false;
    }
  };

  const handleCreateComponent = async (data: IARTComponentsFormValues) => {
    const response = await createARTComponent(clearObject(data));
    if (response.status === 200) {
      setAlert(ActionTypes.SET_DICTIONARY_ALERT, {
        text: t('addART.success.text'),
      });
    }
    return response;
  };

  const getARTComponentDetail = async (id: string): Promise<any[] | null> => {
    const componentDetailData = await getARTComponent(id);
    if (componentDetailData) {
      setArtComponentDetail(componentDetailData);
      return componentDetailData;
    }
    setArtAmmunitionDetail(undefined);
    return null;
  };

  const handleUpdateComponent = async (data: IARTComponentsFormValues, id: string) => {
    try {
      const response = await updateARTComponent(data, id);
      setAlert(ActionTypes.SET_ANCILLARY_ALERT, {
        text: t('updateARTComponent.success.text'),
      });
      return response;
    } catch {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: t('ARTUsedSpecificationEditionProhibited'),
        variant: EAlertVariants.error,
      });
      return false;
    }
  };

  const getAccessoryDetail = async (id: string): Promise<any[] | null> => {
    const accessoryDetailData = await getAccessory(id);
    if (accessoryDetailData) {
      setAccessoryDetail(accessoryDetailData);
      return accessoryDetailData;
    }
    setAccessoryDetail(undefined);
    return null;
  };

  const getAncillaryLastTransactionUniqueId = async (ancillaryUniqueId: string) => {
    const { data } = await apiClient.get<ILastTransactionUniqueId>(
      ApiEndpoints.GET_ANCILLARY_LAST_TRANSACTION_UNIQUE_ID(ancillaryUniqueId),
    );

    return data;
  };

  const getAccessoriesTransactionsData = async (
    id: string,
    currentPage: number = 1,
    perPage: EPerPages = EPerPages.perPage25,
    query: Partial<any> = {},
    transactionSort?: ISortOptions | null,
  ) => {
    const data = await getAncillariesTransactions(
      id,
      currentPage,
      perPage,
      query,
      transactionSort,
    );
    if (data) {
      const { content, ...paginatorData } = data;
      setAccessoriesTransactions(content);
      setPaginatorAccessoriesTransactions(paginatorData);
    } else {
      setAccessoriesTransactions([]);
      setPaginatorAccessoriesTransactions(null);
    }
  };

  const getAmmunitionTransactionsData = async (
    id: string,
    currentPage: number = 1,
    perPage: EPerPages = EPerPages.perPage25,
    query: Partial<any> = {},
    transactionSort?: ISortOptions | null,
  ) => {
    const data = await getAmmunitionTransactions(
      id,
      currentPage,
      perPage,
      query,
      transactionSort,
    );
    if (data) {
      const { content, ...paginatorData } = data;
      setAmmunitionTransactions(content);
      setPaginatorAmmunitionTransactions(paginatorData);
    } else {
      setAmmunitionTransactions([]);
      setPaginatorAmmunitionTransactions(null);
    }
  };

  const getComponentsTransactionsData = async (
    id: string,
    currentPage: number = 1,
    perPage: EPerPages = EPerPages.perPage25,
    query: Partial<any> = {},
    transactionSort?: ISortOptions | null,
  ) => {
    const data = await getAncillariesTransactions(
      id,
      currentPage,
      perPage,
      query,
      transactionSort,
    );
    if (data) {
      const { content, ...paginatorData } = data;
      setComponentsTransactions(content);
      setPaginatorComponentsTransactions(paginatorData);
    } else {
      setComponentsTransactions([]);
      setPaginatorComponentsTransactions(null);
    }
  };

  const getAccessoriesCommentsData = async (
    id: string,
    queryParams: {
      withHidden: boolean;
      currentPage: number;
      perPage: EPerPages;
    },
  ) => {
    const data = await getAccessoriesComments(id, queryParams);
    if (data) {
      const { content, ...paginatorData } = data;
      setAccessoriesComments(content);
      setPaginatorAccessoriesComments(paginatorData);
    } else {
      setAccessoriesComments([]);
      setPaginatorAccessoriesComments(null);
    }
  };

  const getComponentsCommentsData = async (
    id: string,
    queryParams: {
      withHidden: boolean;
      currentPage: number;
      perPage: EPerPages;
    },
  ) => {
    const data = await getComponentsComments(id, queryParams);
    if (data) {
      const { content, ...paginatorData } = data;
      setComponentsComments(content);
      setPaginatorComponentsComments(paginatorData);
    } else {
      setComponentsComments([]);
      setPaginatorComponentsComments(null);
    }
  };

  const getAmmunitionCommentsData = async (
    id: string,
    queryParams: {
      withHidden: boolean;
      currentPage: number;
      perPage: EPerPages;
    },
  ) => {
    const data = await getAmmunitionComments(id, queryParams);
    if (data) {
      const { content, ...paginatorData } = data;
      setAmmunitionComments(content);
      setPaginatorAmmunitionComments(paginatorData);
    } else {
      setAmmunitionComments([]);
      setPaginatorAmmunitionComments(null);
    }
  };

  const getAccessoriesAttachmentsData = async (
    id: string,
    queryParams: {
      sort: ISortOptions | null | undefined;
      currentPage: number;
      perPage: EPerPages;
    },
  ) => {
    const detailData = await getAncillaryAttachments(id, queryParams);
    if (detailData) {
      const { content, ...paginatorData } = detailData;
      setAccessoriesAttachments(content);
      setPaginatorAccessoriesAttachments(paginatorData);
    } else {
      setAccessoriesAttachments([]);
      setPaginatorAccessoriesAttachments(null);
    }
  };

  const getAmmunitionAttachmentsData = async (
    id: string,
    queryParams: {
      sort: ISortOptions | null | undefined;
      currentPage: number;
      perPage: EPerPages;
    },
  ) => {
    const detailData = await getAncillaryAttachments(id, queryParams);
    if (detailData) {
      const { content, ...paginatorData } = detailData;
      setAmmunitionAttachments(content);
      setPaginatorAmmunitionAttachments(paginatorData);
    } else {
      setAmmunitionAttachments([]);
      setPaginatorAmmunitionAttachments(null);
    }
  };

  const getDiscardableTransactionId = async (ancillaryId: string) => {
    if (!hasPermissions(EPermission.DISCARD_TRANSACTION)) return;
    getAncillaryLastTransaction(ancillaryId, false).then((transaction) => {
      if (transaction && transaction.groupUniqueId !== null) {
        isAncillaryGroupTransactionDiscardable(transaction.uniqueId).then((isDiscardable) => {
          if (isDiscardable) {
            setDiscardableTransactionId(transaction.uniqueId);
          } else {
            setDiscardableTransactionId(null);
          }
        });
      } else if (transaction) {
        setDiscardableTransactionId(transaction.uniqueId);
      } else {
        setDiscardableTransactionId(null);
      }
    });
  };

  const getComponentsAttachmentsData = async (
    id: string,
    queryParams: {
      sort: ISortOptions | null | undefined;
      currentPage: number;
      perPage: EPerPages;
    },
  ) => {
    const detailData = await getAncillaryAttachments(id, queryParams);
    if (detailData) {
      const { content, ...paginatorData } = detailData;
      setComponentsAttachments(content);
      setPaginatorComponentsAttachments(paginatorData);
    } else {
      setComponentsAttachments([]);
      setPaginatorComponentsAttachments(null);
    }
  };

  const handleDeleteComment = async (id: string, isHidden: any) => {
    const response = await deleteComment(id, isHidden);
    if (response) {
      await setAlert(ActionTypes.SET_ANCILLARY_ALERT, {
        text: isHidden
          ? t('ancillaryDetails.enableComment.save.success.text')
          : t('ancillaryDetails.disableComment.save.success.text'),
      });
    }
    return response;
  };

  const handleCreateComment = async (data: ICommentFormValues) => {
    const response = await createAncillaryComment(data);

    if (response) {
      await setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
        text: t('ancillaryDetails.addComment.commentAdded.text'),
      });
    }

    return response;
  };

  const handleAddAttachment = async (ancillaryId: string, data: any) => {
    const response = await addAncillaryAttachment(ancillaryId, data);
    if (response) {
      await setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
        text: t('addAttachment.attachmentAdded.text'),
      });
    }
    return response;
  };

  const handlePreviewAttachment = async (ancillaryId: string, attachmentId: string) =>
    previewAncillaryAttachment(ancillaryId, attachmentId);

  const handleDeleteAttachment = async (ancillaryId: string, attachmentId: string) => {
    const response = await deleteAncillaryAttachment(ancillaryId, attachmentId);
    if (response) {
      await setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
        text: t('deleteAttchment.attachmentRemoved.text'),
      });
    }
    return response;
  };

  const getAmmunitionData = async (
    currentPage: number = 1,
    perPage: EPerPages = EPerPages.perPage25,
    query: Partial<any> = {},
    sort?: ISortOptions | null,
  ) => {
    const { owner, keeper, ...queryWithoutOwnerAndKeeper } = query;
    const ammunitionData = await getAncillariesAmmunition(
      EArtAncillaryTypeName.AMMUNITION,
      currentPage,
      perPage,
      {
        ...queryWithoutOwnerAndKeeper,
        ancillaryDictionaryUniqueId: EArtAncillaryType.AMMUNITION,
      },
      sort,
    );
    if (ammunitionData) {
      const { content, ...paginatorData } = ammunitionData;
      // @ts-ignore
      setAmmunition(content);
      setAncillaryIsEmpty(paginatorData.empty);
      setPaginatorAmmunition(paginatorData);
    } else {
      setAmmunition([]);
      setPaginatorAmmunition(null);
      setAncillaryIsEmpty(false);
    }
  };

  const getAmmunitionDetail = async (id: string): Promise<any[] | null> => {
    const ammunitionDetailData = await getAmmunition(id);
    if (ammunitionDetailData) {
      setAmmunitionDetail(ammunitionDetailData);
      return ammunitionDetailData;
    }
    setAmmunitionDetail(undefined);
    return null;
  };

  const getComponentDetail = async (id: string): Promise<any[] | null> => {
    const componentDetailData = await getComponent(id);
    if (componentDetailData) {
      setComponentDetail(componentDetailData);
      return componentDetailData;
    }
    setComponentDetail(undefined);
    return null;
  };

  const getCustomField = async (
    objectType: ECustomFieldObjectType,
    filterFunc?: (arg: ICustomFieldByObjectType) => boolean,
  ) => {
    setCustomFieldsLoaded(false);
    const result = await getCustomFieldByObjectType(objectType);
    if (result) {
      if (objectType === ECustomFieldObjectType.AN_ACCESSORY) {
        setCustomFieldsAccessories(
          filterFunc ? result.content.filter(filterFunc) : result.content,
        );
      } else if (objectType === ECustomFieldObjectType.AN_AMMUNITION) {
        setCustomFieldsAmmunition(
          filterFunc ? result.content.filter(filterFunc) : result.content,
        );
      } else if (objectType === ECustomFieldObjectType.AN_COMPONENT) {
        setCustomFieldsComponents(
          filterFunc ? result.content.filter(filterFunc) : result.content,
        );
      }
      setCustomFieldsLoaded(true);
    }
  };

  const getCustomFields = async (objectTypes: ECustomFieldObjectType[]) => {
    setCustomFieldsLoaded(false);

    await Promise.all(
      objectTypes.map(async (objectType) => {
        const result = await getCustomFieldByObjectType(objectType);
        if (result) {
          if (objectType === ECustomFieldObjectType.AN_ACCESSORY) {
            setCustomFieldsAccessories(result.content);
          } else if (objectType === ECustomFieldObjectType.AN_AMMUNITION) {
            setCustomFieldsAmmunition(result.content);
          } else if (objectType === ECustomFieldObjectType.AN_COMPONENT) {
            setCustomFieldsComponents(result.content);
          }
        }
      }),
    );

    setCustomFieldsLoaded(true);
  };

  return {
    state,
    getState,
    legality,
    getLegality,
    ancillaryOption,
    artAccessoryDetail,
    artAmmunitionDetail,
    artComponentDetail,
    setAncillaryOption,
    getComponentsDataForMap,
    getAmmunitionDataForMap,
    getAccessoriesDataForMap,
    getAccessoriesData,
    getAncillariesReferenceAccessoryData,
    getAncillariesReferenceAmmunitionData,
    getAncillariesReferenceComponentsData,
    getARTAccessoryDetail,
    getARTAmmunitionDetail,
    getARTComponentDetail,
    accessoryDetail,
    getAccessoryDetail,
    paginatorAccessories,
    setPaginatorAccessories,
    paginatorAncillariesReference,
    setPaginatorAncillariesReference,
    accessories,
    ancillariesReference,
    loadDictionaries,
    getTransactionType,
    handleCreateAccessory,
    handleCreateAmmunition,
    handleCreateComponent,
    handleUpdateAccessory,
    handleUpdateAmmunition,
    handleUpdateComponent,
    artAncillaryOptions,
    artAccessoryCalibre,
    artAccessoryComposition,
    artAccessoryHostType,
    artAccessoryLightColour,
    artAccessoryMagnification,
    artAccessoryManufacturerFlashEliminator,
    artAccessoryManufacturerLaserLightModule,
    artAccessoryManufacturerMagazine,
    artAccessoryManufacturerOpticalSight,
    artAccessoryManufacturerSuppressor,
    artAccessoryReticle,
    artAccessoryType,
    artAccessoryUtility,
    artAccessoryProofHouse,
    artAmmunitionType,
    artAmmunitionManufacturer,
    artAmmunitionFactory,
    artAmmunitionCalibreGauge,
    artAmmunitionCartridgeComposition,
    artAmmunitionColourOfPrimerSealant,
    artAmmunitionProjectileShape,
    artAmmunitionFunctionalType,
    artAmmunitionShotSize,
    artAmmunitionMunitionType,
    artAmmunitionMunitionVelocity,
    artComponentType,
    artComponentManufacturer,
    artComponentCalibre,
    artComposition,
    artComponentCapacity,
    artComponentProofHouse,
    make,
    model,
    manufacturer,
    firearmType,
    transactionType,
    getAccessoriesTransactionsData,
    paginatorAccessoriesTransactions,
    accessoriesTransactions,
    getAccessoriesCommentsData,
    accessoriesComments,
    paginatorAccessoriesComments,
    getAccessoriesAttachmentsData,
    accessoriesAttachments,
    handleDeleteComment,
    handleCreateComment,
    paginatorAccessoriesAttachments,
    handleAddAttachment,
    handlePreviewAttachment,
    handleDeleteAttachment,
    paginatorAmmunition,
    ammunition,
    getCustomFields,
    getAmmunitionData,
    getAmmunitionDetail,
    ammunitionDetail,
    ammunitionTransactions,
    paginatorAmmunitionTransactions,
    getAmmunitionTransactionsData,
    ammunitionComments,
    paginatorAmmunitionComments,
    getAmmunitionCommentsData,
    paginatorAmmunitionAttachments,
    ammunitionAttachments,
    getAmmunitionAttachmentsData,
    paginatorComponents,
    components,
    getComponentsData,
    componentDetail,
    getComponentDetail,
    getComponentsTransactionsData,
    componentsTransactions,
    paginatorComponentsTransactions,
    componentsAttachments,
    paginatorComponentsAttachments,
    getComponentsAttachmentsData,
    componentsComments,
    paginatorComponentsComments,
    getComponentsCommentsData,
    getCustomField,
    customFieldsLoaded,
    customFieldsAccessories,
    customFieldsAmmunition,
    customFieldsComponents,
    ancillaries,
    getAncillariesData,
    getAncillariesGroupSearchData,
    getAncillariesByGroupIdSearchData,
    getDiscardableTransactionId,
    discardableTransactionId,
    paginatorAncillaries,
    ancillaryIsEmpty,
    getAncillaryLastTransactionUniqueId,
    getAncillariesDataForMap,
  };
};
