import { useTranslations } from 'hooks/useTranslations';
import * as yup from 'yup';
import { ValidationError } from 'yup';
import { ETransactionTypes } from 'constants/TransactionTypes';
import {
  TRANSACTION_INITIAL_ILLEGAL,
  TRANSACTION_INITIAL_TYPES_FOR_MANUFACTURED_DATE_FIELD,
  TRANSACTION_INITIAL_TYPES_FOR_MANUFACTURED_DATE_FIELD_REQUIRED,
  TRANSACTION_INITIAL_TYPES_ONLY_FOR_ORG_IDS,
  TRANSACTION_WITH_SERIAL_NUMBER,
} from 'pages/LegalEntitiesPage/LegalEntityAddAncillaryPage/index';
import { ECustomFieldType, ICustomFieldByObjectType } from 'models/customField';
import { CHARS_LIMIT } from 'components/organisms/FormCustomField';
import { isStringEmpty } from 'utils/string';
import { isDateAfter, isDateEqual } from 'utils/date';

export const useAddAncillaryFormSchema = (customFields: ICustomFieldByObjectType[]) => {
  const { t } = useTranslations();

  const customFieldsValidationsOne: { [key: string]: any } = {};
  const customFieldsValidationsMany: { [key: string]: any } = {};

  customFields.map((customField) => {
    const baseValidation = yup.string().nullable();

    if (customField.customFieldType === ECustomFieldType.TEXT && customField.mandatory) {
      customFieldsValidationsOne[customField.name] = baseValidation.when('firearmsQuantity', {
        is: 'one',
        then: (yupSchema) =>
          yupSchema
            .required(t('general.field.validation.required'))
            .max(
              CHARS_LIMIT,
              t('general.field.validation.maxCharacters').replace(
                '{0}',
                CHARS_LIMIT.toString(),
              ),
            ),
      });
      customFieldsValidationsMany[customField.name] = yup
        .string()
        .nullable()
        .required(t('general.field.validation.required'))
        .max(
          CHARS_LIMIT,
          t('general.field.validation.maxCharacters').replace('{0}', CHARS_LIMIT.toString()),
        );
    } else if (customField.customFieldType === ECustomFieldType.TEXT) {
      customFieldsValidationsOne[customField.name] = baseValidation.when('firearmsQuantity', {
        is: 'one',
        then: (yupSchema) =>
          yupSchema.max(
            CHARS_LIMIT,
            t('general.field.validation.maxCharacters').replace('{0}', CHARS_LIMIT.toString()),
          ),
      });
      customFieldsValidationsMany[customField.name] = yup
        .string()
        .nullable()
        .max(
          CHARS_LIMIT,
          t('general.field.validation.maxCharacters').replace('{0}', CHARS_LIMIT.toString()),
        );
    } else if (customField.mandatory) {
      customFieldsValidationsOne[customField.name] = baseValidation.when('firearmsQuantity', {
        is: 'one',
        then: (yupSchema) => yupSchema.required(t('general.field.validation.required')),
      });
      customFieldsValidationsMany[customField.name] = yup
        .string()
        .nullable()
        .required(t('general.field.validation.required'));
    }

    return false;
  });

  const errorsOfFields = (fields: string[]) => {
    let errors;
    if (fields.length === 1) {
      errors = [new ValidationError(t('general.field.validation.required'), null, fields[0])];
    } else {
      errors = fields
        .map(
          (field) =>
            new ValidationError(t('general.field.validation.anyIsRequired'), null, field),
        )
        .filter(Boolean);
    }

    return new ValidationError(errors);
  };

  const schema = yup
    .object({
      attachments: yup.array().of(
        yup.object({
          attachmentName: yup
            .string()
            .test('unique', t('general.field.validation.unique'), (value, params) => {
              // @ts-ignore
              const attachments = params?.from[1]?.value.attachments ?? [];
              if (!value) return true;
              if (attachments.filter((r: any) => r.attachmentName === value).length > 1) {
                return params.createError({
                  path: params.path,
                  message: t('general.field.validation.unique'),
                });
              }
              return true;
            })
            .required(t('general.field.validation.required'))
            .max(64, t('general.field.validation.maxCharacters').replace('{0}', '64'))
            .matches(/^[a-zA-Z0-9_ -]+$/, t('general.field.validation.attachmentName'))
            .trim(),
        }),
      ),

      typeUniqueId: yup.string().required(t('general.field.validation.required')),

      serialNumberEnabled: yup.boolean(),

      externalReferenceNumberMandatory: yup.boolean(),

      exhibitNumberEnabled: yup.boolean(),

      isAccessorySelected: yup.boolean(),

      firearmsQuantity: yup.string(),

      lotBatchEnabled: yup.boolean(),

      isLotBatchNumberNotPresent: yup.boolean(),

      transactionDate: yup.string().when('validationOff', {
        is: false,
        then: yup
          .string()
          .required(t('general.field.validation.required'))
          .test(
            'date-validation',
            t('legalEntity.validation.maxDate').replace('{0}', '255'),
            (value) => {
              if (typeof value !== 'string') {
                return false;
              }
              const maxValidDate = new Date();
              const passedDate = new Date(value);
              return passedDate.valueOf() <= maxValidDate.valueOf();
            },
          ),
        otherwise: yup.string().nullable(),
      }),
      isExhibitNumberChecked: yup.boolean(),

      lotNumber: yup.string().test('lotNumberTest', '', (val, obj) => {
        const {
          firearmsQuantity,
          batchNumber,
          exhibitNumber,
          exhibitNumberEnabled,
          lotBatchEnabled,
          typeUniqueId,
          isLotBatchNumberNotPresent,
        } = obj.parent;
        if (
          firearmsQuantity === 'one' &&
          !val &&
          lotBatchEnabled &&
          (!batchNumber || batchNumber?.length === 0) &&
          exhibitNumberEnabled &&
          (!exhibitNumber || exhibitNumber?.length === 0) &&
          !TRANSACTION_INITIAL_ILLEGAL.includes(typeUniqueId as ETransactionTypes)
        ) {
          return errorsOfFields(['lotNumber', 'batchNumber', 'exhibitNumber']);
        }

        if (
          firearmsQuantity === 'one' &&
          !val &&
          lotBatchEnabled &&
          (!batchNumber || batchNumber?.length === 0) &&
          TRANSACTION_INITIAL_ILLEGAL.includes(typeUniqueId as ETransactionTypes) &&
          !isLotBatchNumberNotPresent
        ) {
          return errorsOfFields(['lotNumber', 'batchNumber']);
        }

        return true;
      }),

      batchNumber: yup.string().test('batchNumberTest', '', (val, obj) => {
        const {
          firearmsQuantity,
          lotNumber,
          exhibitNumber,
          exhibitNumberEnabled,
          lotBatchEnabled,
          typeUniqueId,
        } = obj.parent;
        if (
          firearmsQuantity === 'one' &&
          !val &&
          lotBatchEnabled &&
          (!lotNumber || lotNumber?.length === 0) &&
          exhibitNumberEnabled &&
          (!exhibitNumber || exhibitNumber?.length === 0) &&
          !TRANSACTION_INITIAL_ILLEGAL.includes(typeUniqueId as ETransactionTypes)
        ) {
          return errorsOfFields(['lotNumber', 'batchNumber', 'exhibitNumber']);
        }

        if (
          firearmsQuantity === 'one' &&
          !val &&
          lotBatchEnabled &&
          (!lotNumber || lotNumber?.length === 0) &&
          !exhibitNumberEnabled
        ) {
          if (
            !(
              (typeUniqueId as ETransactionTypes) ===
              ETransactionTypes.DECLARED_PIECEMEAL_UNKNOWN_ORIGIN
            )
          ) {
            return errorsOfFields(['lotNumber', 'batchNumber']);
          }
          if (
            (typeUniqueId as ETransactionTypes) ===
              ETransactionTypes.DECLARED_PIECEMEAL_UNKNOWN_ORIGIN &&
            !obj.parent.assetId
          ) {
            return errorsOfFields(['lotNumber', 'batchNumber', 'assetId']);
          }
        }

        return true;
      }),

      assetId: yup
        .string()
        .max(50, t('general.field.validation.maxCharacters').replace('{0}', '50')),

      serialNumber: yup.string().when(['firearmsQuantity', 'typeUniqueId', 'validationOff'], {
        is: (
          firearmsQuantity: string,
          typeUniqueId: ETransactionTypes,
          validationOff: boolean,
        ) =>
          firearmsQuantity === 'one' &&
          TRANSACTION_WITH_SERIAL_NUMBER.includes(typeUniqueId) &&
          !validationOff,
        then: yup
          .string()
          .max(10, t('general.field.validation.maxCharacters').replace('{0}', '10')),
      }),
      serialNumberConfirmation: yup
        .string()
        .when(['firearmsQuantity', 'typeUniqueId', 'validationOff'], {
          is: (
            firearmsQuantity: string,
            typeUniqueId: ETransactionTypes,
            validationOff: boolean,
          ) =>
            firearmsQuantity === 'one' &&
            TRANSACTION_WITH_SERIAL_NUMBER.includes(typeUniqueId) &&
            !validationOff,
          then: yup
            .string()
            .test(
              'serialNumberConfirmationRequired',
              t('general.field.validation.required'),
              (value, obj) =>
                !(
                  obj.parent.serialNumberEnabled &&
                  !obj.parent.isExhibitNumberChecked &&
                  !value
                ),
            )
            .test(
              'serialNumberConfirmationValidation',
              t('general.field.validation.serialNumberConfirmationInvalid'),
              (value, obj) =>
                !(
                  obj.parent.serialNumberEnabled &&
                  !obj.parent.isExhibitNumberChecked &&
                  value &&
                  obj.parent.serialNumber &&
                  value !== obj.parent.serialNumber
                ),
            ),
        }),
      ...customFieldsValidationsOne,
      externalReferenceNumber: yup
        .string()
        .test(
          'externalReferenceNumberTest',
          t('general.field.validation.required'),
          (val, obj) => {
            const { externalReferenceNumberMandatory, firearmsQuantity } = obj.parent;
            if (
              externalReferenceNumberMandatory &&
              firearmsQuantity === 'one' &&
              (!val || val.trim() === '')
            ) {
              return errorsOfFields(['externalReferenceNumber']);
            }
            return true;
          },
        )
        .when(['firearmsQuantity', 'externalReferenceNumberMandatory'], {
          is: 'one',
          then: (yupSchema) =>
            yupSchema.max(
              20,
              t('general.field.validation.maxCharacters').replace('{0}', '20'),
            ),
        }),

      exhibitNumber: yup
        .string()
        .test('exhibitNumberTest', t('general.field.validation.required'), (val, obj) => {
          const {
            firearmsQuantity,
            exhibitNumberEnabled,
            lotBatchEnabled,
            typeUniqueId,
            isLotBatchNumberNotPresent,
            isAccessorySelected,
            isSerialNumberEnabled,
          } = obj.parent;

          if (
            firearmsQuantity === 'one' &&
            exhibitNumberEnabled &&
            TRANSACTION_INITIAL_ILLEGAL.includes(typeUniqueId as ETransactionTypes) &&
            lotBatchEnabled &&
            isLotBatchNumberNotPresent &&
            (!val || val.trim() === '')
          ) {
            return errorsOfFields(['exhibitNumber']);
          }

          if (
            firearmsQuantity === 'one' &&
            exhibitNumberEnabled &&
            TRANSACTION_INITIAL_ILLEGAL.includes(typeUniqueId as ETransactionTypes) &&
            isAccessorySelected &&
            isSerialNumberEnabled &&
            (!val || val.trim() === '')
          ) {
            return errorsOfFields(['exhibitNumber']);
          }

          return true;
        })
        .when('firearmsQuantity', {
          is: 'one',
          then: (yupSchema) =>
            yupSchema.max(
              10,
              t('general.field.validation.maxCharacters').replace('{0}', '10'),
            ),
        }),
      manufacturedAt: yup
        .string()
        .nullable()
        .test(
          'manufacture-date-validation',
          t('legalEntity.validation.maxDate'),
          (value, obj) => {
            if (!value) {
              return true;
            }
            if (
              !TRANSACTION_INITIAL_TYPES_FOR_MANUFACTURED_DATE_FIELD.includes(
                obj.parent.typeUniqueId,
              )
            ) {
              return true;
            }
            if (typeof value !== 'string') {
              return true;
            }
            const maxValidDate = new Date();
            const passedDate = new Date(value);
            return passedDate.valueOf() <= maxValidDate.valueOf();
          },
        )
        .when(['firearmsQuantity', 'typeUniqueId'], {
          is: (firearmsQuantity: string, typeUniqueId: ETransactionTypes) =>
            firearmsQuantity === 'one' &&
            TRANSACTION_INITIAL_TYPES_FOR_MANUFACTURED_DATE_FIELD_REQUIRED.includes(
              typeUniqueId,
            ),
          then: (yupSchema) => yupSchema.required(t('general.field.validation.required')),
        }),

      ammunitionExpiryDate: yup
        .string()
        .nullable()
        .test(
          'ammunition-expiry-date-validation',
          t('legalEntity.validation.isNotAfterManufacturedAt'),
          (value, obj) => {
            if (!value || !obj.parent.manufacturedAt) {
              return true;
            }
            if (typeof value !== 'string') {
              return true;
            }
            const res =
              isDateAfter(new Date(value), new Date(obj.parent.manufacturedAt)) ||
              isDateEqual(new Date(value), new Date(obj.parent.manufacturedAt));

            return !!res;
          },
        ),

      ancillaryList: yup.array().when(['firearmsQuantity', 'validationOff'], {
        is: (firearmsQuantity: string, validationOff: boolean) =>
          firearmsQuantity === 'many' && !validationOff,
        then: yup.array().of(
          yup.object().shape({
            isExhibitNumberChecked: yup.boolean(),
            isLotBatchNumberNotPresent: yup.boolean(),
            serialNumber: yup
              .string()
              .max(10, t('general.field.validation.maxCharacters').replace('{0}', '10'))
              .test('uniqueSerialNumber', t('addAncillary.fieldUnique.text'), (val, obj) => {
                if (!val) {
                  return true;
                }
                const ancillaryList = (obj as any)?.from?.[1]?.value?.ancillaryList;
                if (
                  ancillaryList &&
                  ancillaryList.filter((item: any) => item.serialNumber === val).length > 1
                ) {
                  return false;
                }
                return true;
              })
              .test('filledSerialNumber', '', (val, obj) => {
                const parent = (obj as any)?.from?.[1]?.value;
                const serialPath = obj.path;
                const assetPath = obj.path.replaceAll('serialNumber', 'assetId');
                const exhibitPath = obj.path.replaceAll('serialNumber', 'exhibitNumber');
                const indexOfStart = obj.path.indexOf('[');
                const indexOfEnd = obj.path.indexOf(']');
                const index = obj.path.slice(indexOfStart + 1, indexOfEnd);

                const serialValue = parent.ancillaryList[parseInt(index, 10)].serialNumber;
                const assetValue = parent.ancillaryList[parseInt(index, 10)].assetId;
                const exhibitValue = parent.ancillaryList[parseInt(index, 10)].exhibitNumber;
                const isExhibitChecked =
                  parent.ancillaryList[parseInt(index, 10)].isExhibitNumberChecked;

                if (!parent.typeUniqueId || parent.firearmsQuantity === 'one') {
                  return true;
                }

                const isExhibitNumberEnabled =
                  TRANSACTION_INITIAL_TYPES_ONLY_FOR_ORG_IDS.includes(
                    parent.typeUniqueId as ETransactionTypes,
                  );

                if (
                  !isExhibitNumberEnabled &&
                  parent.serialNumberEnabled &&
                  parent.isAccessorySelected &&
                  !serialValue
                ) {
                  return errorsOfFields([serialPath]);
                }

                if (
                  isExhibitNumberEnabled &&
                  !parent.serialNumberEnabled &&
                  parent.isAccessorySelected &&
                  !assetValue &&
                  !exhibitValue &&
                  !TRANSACTION_INITIAL_ILLEGAL.includes(
                    parent.typeUniqueId as ETransactionTypes,
                  )
                ) {
                  return errorsOfFields([assetPath, exhibitPath]);
                }

                if (
                  isExhibitNumberEnabled &&
                  !parent.serialNumberEnabled &&
                  parent.isAccessorySelected &&
                  !assetValue &&
                  !exhibitValue &&
                  TRANSACTION_INITIAL_ILLEGAL.includes(
                    parent.typeUniqueId as ETransactionTypes,
                  )
                ) {
                  return errorsOfFields([exhibitPath]);
                }

                if (
                  isExhibitNumberEnabled &&
                  parent.serialNumberEnabled &&
                  parent.isAccessorySelected &&
                  !TRANSACTION_INITIAL_ILLEGAL.includes(
                    parent.typeUniqueId as ETransactionTypes,
                  )
                ) {
                  if (isExhibitChecked) {
                    if (!assetValue && !exhibitValue) {
                      return errorsOfFields([assetPath, exhibitPath]);
                    }
                  } else if (!assetValue && !serialValue && !exhibitValue) {
                    return errorsOfFields([assetPath, serialPath, exhibitPath]);
                  }
                }

                if (
                  isExhibitNumberEnabled &&
                  parent.serialNumberEnabled &&
                  parent.isAccessorySelected &&
                  TRANSACTION_INITIAL_ILLEGAL.includes(
                    parent.typeUniqueId as ETransactionTypes,
                  )
                ) {
                  if (isExhibitChecked) {
                    if (!exhibitValue) {
                      return errorsOfFields([exhibitPath]);
                    }
                  } else if (!serialValue) {
                    return errorsOfFields([serialPath]);
                  }
                }

                if (
                  !isExhibitChecked &&
                  parent.serialNumberEnabled &&
                  !parent.isAccessorySelected &&
                  !serialValue
                ) {
                  return new ValidationError(
                    t('general.field.validation.required'),
                    null,
                    serialPath,
                  );
                }

                if (
                  isExhibitChecked &&
                  !parent.isAccessorySelected &&
                  parent.serialNumberEnabled &&
                  !exhibitValue
                ) {
                  return new ValidationError(
                    t('general.field.validation.required'),
                    null,
                    exhibitPath,
                  );
                }

                return true;
              }),
            serialNumberConfirmation: yup
              .string()
              .test(
                'serialNumberConfirmationRequired',
                t('general.field.validation.required'),
                (value, obj) => {
                  if (
                    TRANSACTION_WITH_SERIAL_NUMBER.includes(
                      (obj as any).from[1].value.typeUniqueId,
                    ) &&
                    (obj as any).from[1].value.serialNumberEnabled &&
                    !obj.parent.isExhibitNumberChecked &&
                    !value
                  ) {
                    return false;
                  }
                  return true;
                },
              )
              .test(
                'serialNumberConfirmationValidation',
                t('general.field.validation.serialNumberConfirmationInvalid'),
                (value, obj) =>
                  !(
                    TRANSACTION_WITH_SERIAL_NUMBER.includes(
                      (obj as any).from[1].value.typeUniqueId,
                    ) &&
                    (obj as any).from[1].value.serialNumberEnabled &&
                    !obj.parent.isExhibitNumberChecked &&
                    value &&
                    obj.parent.serialNumber &&
                    obj.parent.serialNumber !== value
                  ),
              ),
            assetId: yup
              .string()
              .max(50, t('general.field.validation.maxCharacters').replace('{0}', '10')),
            ...customFieldsValidationsMany,
            exhibitNumber: yup
              .string()
              .max(50, t('general.field.validation.maxCharacters').replace('{0}', '10'))
              .test(
                'exhibitNumberAmmunitionIllegalTest',
                t('general.field.validation.required'),
                (val, obj) => {
                  const parent = (obj as any)?.from?.[1]?.value;

                  if (
                    parent.firearmsQuantity === 'many' &&
                    parent.lotBatchEnabled &&
                    parent.exhibitNumberEnabled &&
                    obj.parent.isLotBatchNumberNotPresent &&
                    (!val || val.trim() === '')
                  ) {
                    return false;
                  }
                  return true;
                },
              )
              .when('isExhibitNumberChecked', {
                is: true,
                then: yup.string().required(t('general.field.validation.required')),
              })
              .test('uniqueExhibitNumber', t('addFirearm.fieldUnique.text'), (val, obj) => {
                const ancillaryList = (obj as any)?.from?.[1]?.value?.firearmsList;
                if (
                  ancillaryList &&
                  ancillaryList.filter(
                    (item: any) => !!item.exhibitNumber && item.exhibitNumber === val,
                  ).length > 1
                ) {
                  return false;
                }
                return true;
              }),

            lotNumber: yup
              .string()
              .max(10, t('general.field.validation.maxCharacters').replace('{0}', '10'))
              .test('lotNumberTest', '', (val, obj: any) => {
                const parent = (obj as any)?.from?.[1]?.value;
                const exhibitNumberEnabled =
                  TRANSACTION_INITIAL_TYPES_ONLY_FOR_ORG_IDS.includes(
                    parent.typeUniqueId as ETransactionTypes,
                  );

                const indexOfStart = obj.path.indexOf('[');
                const indexOfEnd = obj.path.indexOf(']');
                const index = obj.path.slice(indexOfStart + 1, indexOfEnd);

                const typeUniqueId = obj.from[1]?.value?.typeUniqueId;

                const batchNumber = obj.from[1]?.value?.ancillaryList[index].batchNumber;
                const assetId = obj.from[1]?.value?.ancillaryList[index].assetId;
                const isLotBatchNumberNotPresent =
                  obj.from[1]?.value?.ancillaryList[index].isLotBatchNumberNotPresent;
                const isExhibitNumberChecked =
                  obj.from[1]?.value?.ancillaryList[index].isExhibitNumberChecked;
                const exhibitNumber = obj.from[1]?.value?.ancillaryList[index].exhibitNumber;
                const lotPath = obj.path;
                const batchPath = obj.path.replaceAll('lotNumber', 'batchNumber');
                const assetIdPath = obj.path.replaceAll('lotNumber', 'assetId');
                const exhibitNumberPath = obj.path.replaceAll('lotNumber', 'exhibitNumber');
                if (obj.from[1]?.value?.lotBatchEnabled) {
                  if (
                    !val &&
                    !batchNumber &&
                    (!TRANSACTION_INITIAL_ILLEGAL.includes(
                      typeUniqueId as ETransactionTypes,
                    ) ||
                      (TRANSACTION_INITIAL_ILLEGAL.includes(
                        typeUniqueId as ETransactionTypes,
                      ) &&
                        !isLotBatchNumberNotPresent))
                  ) {
                    if (
                      !(
                        (typeUniqueId as ETransactionTypes) ===
                        ETransactionTypes.DECLARED_PIECEMEAL_UNKNOWN_ORIGIN
                      )
                    ) {
                      return errorsOfFields([lotPath, batchPath]);
                    }
                    if (
                      (typeUniqueId as ETransactionTypes) ===
                        ETransactionTypes.DECLARED_PIECEMEAL_UNKNOWN_ORIGIN &&
                      !assetId
                    ) {
                      return errorsOfFields([lotPath, batchPath, assetIdPath]);
                    }
                  }
                  if (
                    exhibitNumberEnabled &&
                    !exhibitNumber &&
                    !val &&
                    !batchNumber &&
                    !TRANSACTION_INITIAL_ILLEGAL.includes(typeUniqueId as ETransactionTypes)
                  ) {
                    return errorsOfFields([lotPath, batchPath, exhibitNumberPath]);
                  }

                  if (isStringEmpty(val)) return true;

                  // UNIQUE LOT AND BATCH NUMBER
                  // if (
                  //   obj.from?.[1]?.value?.ancillaryList.filter(
                  //     (item: any) => item.lotNumber === val,
                  //   )?.length > 1
                  // ) {
                  //   return new yup.ValidationError(
                  //     t('addAncillary.fieldUnique.text'),
                  //     null,
                  //     obj.path,
                  //   );
                  // }
                } else if (exhibitNumberEnabled && !exhibitNumber && isExhibitNumberChecked) {
                  return errorsOfFields([exhibitNumberPath]);
                }
                return true;
              }),

            batchNumber: yup
              .string()
              .max(10, t('general.field.validation.maxCharacters').replace('{0}', '10')),
            // UNIQUE LOT AND BATCH NUMBER
            // .test('batchNumberTest', '', (val, obj: any) => {
            //   if (obj.from[1]?.value?.lotBatchEnabled) {
            //     if (isStringEmpty(val)) return true;
            //
            //     if (
            //       obj.from?.[1]?.value?.ancillaryList.filter(
            //         (item: any) => item.batchNumber === val,
            //       )?.length > 1
            //     ) {
            //       return new yup.ValidationError(
            //         t('addAncillary.fieldUnique.text'),
            //         null,
            //         obj.path,
            //       );
            //     }
            //   }
            //   return true;
            // }),
            manufacturedAt: yup
              .string()
              .nullable()
              .test(
                'manufacture-date-validation',
                t('legalEntity.validation.maxDate'),
                (value, obj: any) => {
                  if (!value) {
                    return true;
                  }
                  if (
                    !TRANSACTION_INITIAL_TYPES_FOR_MANUFACTURED_DATE_FIELD.includes(
                      obj.from[1].value.typeUniqueId,
                    )
                  ) {
                    return true;
                  }
                  if (typeof value !== 'string') {
                    return true;
                  }
                  const maxValidDate = new Date();
                  const passedDate = new Date(value);
                  return passedDate.valueOf() <= maxValidDate.valueOf();
                },
              )
              .test('manufacturedAt', '', (val, obj: any) => {
                if (
                  !val &&
                  TRANSACTION_INITIAL_TYPES_FOR_MANUFACTURED_DATE_FIELD_REQUIRED.includes(
                    obj.from[1].value.typeUniqueId,
                  )
                ) {
                  return new yup.ValidationError(
                    t('addFirearm.fieldRequired.text'),
                    null,
                    obj.path,
                  );
                }
                return true;
              })
              .nullable(),
            externalReferenceNumber: yup
              .string()
              .test(
                'externalReferenceNumberTest',
                t('general.field.validation.required'),
                (val, obj) => {
                  const parent = (obj as any)?.from?.[1]?.value;

                  if (
                    parent.firearmsQuantity === 'many' &&
                    parent.externalReferenceNumberMandatory &&
                    (!val || val.trim() === '')
                  ) {
                    return new yup.ValidationError(
                      t('addFirearm.fieldRequired.text'),
                      null,
                      obj.path,
                    );
                  }
                  return true;
                },
              ),
          }),
        ),
      }),

      groupName: yup.string().when(['isCreateGroup', 'firearmsQuantity'], {
        is: (isCreateGroup: string, firearmsQuantity: string) =>
          isCreateGroup === 'yes' && firearmsQuantity === 'many',
        then: (yupSchema) =>
          yupSchema
            .max(255, t('general.field.validation.maxCharacters').replace('{0}', '255'))
            .required(t('general.field.validation.required')),
      }),
      countryOfImportUniqueId: yup
        .string()
        .test('countryOfImportTest', t('general.field.validation.required'), (val, obj) => {
          if (
            (obj.parent.typeUniqueId === ETransactionTypes.DECLARED_IMPORTED ||
              obj.parent.typeUniqueId === ETransactionTypes.DECLARED_LOT_BATCH_IMPORTED) &&
            !val
          ) {
            return false;
          }
          return true;
        }),
      importer: yup
        .string()
        .max(250, t('general.field.validation.maxCharacters').replace('{0}', '250')),
    })
    .test('idFieldsTest', '', (obj) => {
      if (!obj.typeUniqueId || obj.firearmsQuantity === 'many') {
        return true;
      }

      if (
        !obj.exhibitNumberEnabled &&
        obj.serialNumberEnabled &&
        obj.isAccessorySelected &&
        !obj.serialNumber
      ) {
        return errorsOfFields(['serialNumber']);
      }

      if (
        obj.exhibitNumberEnabled &&
        !obj.serialNumberEnabled &&
        obj.isAccessorySelected &&
        !obj.assetId &&
        !obj.exhibitNumber &&
        !TRANSACTION_INITIAL_ILLEGAL.includes(obj.typeUniqueId as ETransactionTypes)
      ) {
        return errorsOfFields(['assetId', 'exhibitNumber']);
      }

      if (
        obj.exhibitNumberEnabled &&
        !obj.serialNumberEnabled &&
        obj.isAccessorySelected &&
        !obj.assetId &&
        !obj.exhibitNumber &&
        TRANSACTION_INITIAL_ILLEGAL.includes(obj.typeUniqueId as ETransactionTypes)
      ) {
        return errorsOfFields(['exhibitNumber']);
      }

      if (obj.exhibitNumberEnabled && obj.serialNumberEnabled && obj.isAccessorySelected) {
        if (TRANSACTION_INITIAL_ILLEGAL.includes(obj.typeUniqueId as ETransactionTypes)) {
          if (obj.isExhibitNumberChecked) {
            if (!obj.exhibitNumber) {
              return errorsOfFields(['exhibitNumber']);
            }
          } else if (!obj.serialNumber) {
            return errorsOfFields(['serialNumber']);
          }
        } else if (obj.isExhibitNumberChecked) {
          if (!obj.assetId && !obj.exhibitNumber) {
            return errorsOfFields(['assetId', 'exhibitNumber']);
          }
        } else if (!obj.assetId && !obj.serialNumber && !obj.exhibitNumber) {
          return errorsOfFields(['assetId', 'serialNumber', 'exhibitNumber']);
        }
      }

      if (
        !obj.isExhibitNumberChecked &&
        obj.serialNumberEnabled &&
        !obj.isAccessorySelected &&
        !obj.serialNumber
      ) {
        return new ValidationError(
          t('general.field.validation.required'),
          null,
          'serialNumber',
        );
      }

      if (
        obj.isExhibitNumberChecked &&
        !obj.isAccessorySelected &&
        obj.serialNumberEnabled &&
        !obj.exhibitNumber
      ) {
        return new ValidationError(
          t('general.field.validation.required'),
          null,
          'exhibitNumber',
        );
      }

      return true;
    });

  return { schema };
};
