import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { FormInput } from 'components/molecules/FormInput';
import { EButtonSizesPX, EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import Button from 'components/atoms/Button';
import { FormDatePicker } from 'components/molecules/FormDatePicker';
import { Typography } from '@mui/material';
import { ICustomFieldByObjectType } from 'models/customField';
import FormCustomField from 'components/organisms/FormCustomField';
import { FormCheckbox } from 'components/molecules/FormCheckbox';
import { useTranslations } from 'hooks/useTranslations';
import { ETransactionTypes } from 'constants/TransactionTypes';
import { TRANSACTION_ILLEGAL } from 'pages/LegalEntitiesPage/LegalEntityAddFirearmPage';

interface IAddFirearmListItemProps {
  index: number;
  arrayName?: string;
  removeItem: () => void;
  fields: any[];
  isShowManufacturedDate: boolean;
  control: any;
  errors: any;
  setValue: any;
  customFields?: ICustomFieldByObjectType[];
  getValues: any;
  isExhibitNumber: boolean;
  transactionTypeValue: any;
  isReloadCausedLocationSelect: boolean;
  isDataImportedFromFile: boolean;
  setIsDataImportedFromFile: (val: boolean) => void;
}

const AddFirearmListItem = ({
  index,
  arrayName,
  removeItem,
  fields,
  isShowManufacturedDate,
  control,
  setValue,
  errors,
  customFields = [],
  getValues,
  isExhibitNumber,
  transactionTypeValue,
  isReloadCausedLocationSelect,
  isDataImportedFromFile,
  setIsDataImportedFromFile,
}: IAddFirearmListItemProps) => {
  const { t } = useTranslations();
  const [checkboxStates, setCheckboxStates] = useState<{ [key: number]: boolean }>(() => ({
    [index]: getValues(`${arrayName}[${index}].isExhibitNumberChecked`) || false,
  }));
  const isTransactionIllegal = TRANSACTION_ILLEGAL.includes(transactionTypeValue);

  useEffect(() => {
    if (transactionTypeValue) {
      const typeUniqueIdValue = getValues('typeUniqueId');
      if (
        typeUniqueIdValue !== ETransactionTypes.SEIZED &&
        typeUniqueIdValue !== ETransactionTypes.SURRENDERED &&
        typeUniqueIdValue !== ETransactionTypes.RECOVERED
      ) {
        setValue(`${arrayName}[${index}].isExhibitNumberChecked`, false);
        setCheckboxStates((prevStates) => ({
          ...prevStates,
          [index]: false,
        }));
      }
    }
  }, [transactionTypeValue]);

  useEffect(() => {
    if (transactionTypeValue && !isReloadCausedLocationSelect && !isDataImportedFromFile) {
      setCheckboxStates({ [index]: false });
      setValue(`${arrayName}[${index}].isExhibitNumberChecked`, false);
      setValue(`${arrayName}[${index}].serialNumber`, '');
      setValue(`${arrayName}[${index}].serialNumberConfirmation`, '');
      setValue(`${arrayName}[${index}].exhibitNumber`, '');
      setValue(`${arrayName}[${index}].externalReferenceNumber`, '');
      setValue(`${arrayName}[${index}].manufacturedAt`, '');

      customFields?.forEach((customField) =>
        setValue(`${arrayName}[${index}].${customField.name}`, ''),
      );
    }
    setIsDataImportedFromFile(false);
  }, [transactionTypeValue]);

  const handleCheckboxChange = () => {
    setCheckboxStates((prevStates) => ({
      ...prevStates,
      [index]: !prevStates[index],
    }));
    setValue(`${arrayName}[${index}].serialNumber`, '');
    setValue(`${arrayName}[${index}].serialNumberConfirmation`, '');
  };

  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={1} display="flex" alignItems="center">
        <Typography variant="subtitle1" mr={2}>
          {index + 1}.
        </Typography>
      </Grid>
      {isExhibitNumber && (
        <Grid item xs={12} lg={3} sx={{ display: 'flex', alignItems: 'center' }}>
          <FormCheckbox
            name={`${arrayName}[${index}].isExhibitNumberChecked`}
            label={t('addFirearm.firearmDetails.section.serialNumberRemoved.label')}
            control={control}
            errors={errors}
            onChange={handleCheckboxChange}
          />
        </Grid>
      )}
      {checkboxStates && (
        <Grid item xs={11} lg={3}>
          <FormInput
            name={`${arrayName}[${index}].serialNumber`}
            label={`${t('addFirearm.firearmList.section.serialNumber.label')}${
              (isTransactionIllegal && !checkboxStates[index]) || !isTransactionIllegal
                ? '*'
                : ''
            }`}
            control={control}
            setValue={setValue}
            errors={errors}
            arrayName={arrayName}
            arrayIndex={index}
            fieldName="serialNumber"
            charsLimit={10}
            disabled={checkboxStates[index]}
          />
        </Grid>
      )}
      {checkboxStates && (
        <Grid item xs={11} lg={3}>
          <FormInput
            name={`${arrayName}[${index}].serialNumberConfirmation`}
            label={`${t('addFirearm.firearmList.section.serialNumberConfirmation.label')}${
              (isTransactionIllegal && !checkboxStates[index]) || !isTransactionIllegal
                ? '*'
                : ''
            }`}
            control={control}
            setValue={setValue}
            errors={errors}
            arrayName={arrayName}
            disableCopyPaste
            arrayIndex={index}
            fieldName="serialNumberConfirmation"
            charsLimit={10}
            disabled={checkboxStates[index]}
          />
        </Grid>
      )}
      {isExhibitNumber && (
        <Grid item xs={12} sm={isShowManufacturedDate ? 6 : 12} lg={3}>
          <FormInput
            name={`${arrayName}[${index}].exhibitNumber`}
            label={`${t('addFirearm.firearmList.section.exhibitNumber.label')}${
              isTransactionIllegal && checkboxStates[index] ? '*' : ''
            }`}
            control={control}
            errors={errors}
            arrayName={arrayName}
            setValue={setValue}
            charsLimit={10}
            arrayIndex={index}
            fieldName="exhibitNumber"
          />
        </Grid>
      )}
      <Grid item xs={12} sm={isShowManufacturedDate ? 6 : 12} lg={3}>
        <FormInput
          name={`${arrayName}[${index}].externalReferenceNumber`}
          label={t('addFirearm.firearmList.section.externalReferenceNo.label')}
          control={control}
          errors={errors}
          arrayName={arrayName}
          setValue={setValue}
          arrayIndex={index}
          fieldName="externalReferenceNumber"
          charsLimit={20}
        />
      </Grid>
      {isShowManufacturedDate && (
        <Grid item xs={12} sm={6} lg={3}>
          <FormDatePicker
            name={`${arrayName}[${index}].manufacturedAt`}
            label={`${t('addFirearm.firearmList.section.manufacturedDate.label')}`}
            control={control}
            errors={errors}
            arrayName={arrayName}
            disabled={transactionTypeValue === ETransactionTypes.DECLARED_MANUFACTURED}
            arrayIndex={index}
            fieldName="manufacturedAt"
          />
        </Grid>
      )}
      {customFields.map((customField) => (
        <Grid item xs={12} sm={6} lg={3} key={customField.uniqueId}>
          <FormCustomField
            data={customField}
            control={control}
            setValue={setValue}
            errors={errors}
            arrayName={arrayName}
            arrayIndex={index}
          />
        </Grid>
      ))}
      {fields.length > 1 && (
        <Grid item xs={1}>
          <Button
            variant={EButtonVariants.outlined}
            icon={EIconTypes.delete}
            onClick={removeItem}
            sizePX={EButtonSizesPX.px32}
            sx={{ marginTop: '28px' }}
          />
        </Grid>
      )}
    </Grid>
  );
};
export { AddFirearmListItem };
