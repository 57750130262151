import React, { useEffect, useRef, useState } from 'react';
import { EPerPages } from 'models/table';
import { useNotifications } from 'pages/NotificationsPage/hooks';
import { StyledPageTitle } from 'theme/styles';
import { Grid } from '@mui/material';
import { NotificationListItem } from 'pages/NotificationsPage/NotificationListItem';
import { EButtonVariants } from 'constants/Buttons';
import Button from 'components/atoms/Button';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { Pagination } from 'components/molecules/Pagination';
import { NotificationListHeader } from 'pages/NotificationsPage/NotificationListHeader';
import { useTranslations } from 'hooks/useTranslations';
import { INotification } from 'models/notification';
import { Loader } from 'components/atoms/Loader';
import { EmptyNotificationResult } from './EmptyNotificationResult';

const NotificationsPage = () => {
  const {
    getNotificationsData,
    paginator,
    handleDeleteNotifications,
    handleMarkAsReadNotifications,
  } = useNotifications();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isPaginationLoading, setIsPaginationLoading] = useState<boolean>(false);
  const [isDeleteLoading, setIsDeletingLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<EPerPages>(EPerPages.perPage25);
  const [notificationsData, setNotificationsData] = useState<INotification[] | null>(null);
  const [checkedNotifications, setCheckedNotifications] = useState<string[]>([]);
  const { t } = useTranslations();

  const handleGetNotifications = async () => {
    setIsPaginationLoading(true);
    setNotificationsData(null);
    setIsLoading(true);
    const response = await getNotificationsData(currentPage, perPage);
    setNotificationsData(response?.content || []);
    setIsLoading(false);
    setIsPaginationLoading(false);
  };

  const previousPerPageRef = useRef(perPage);

  useEffect(() => {
    (async function initNotifications() {
      if (
        typeof perPage === 'number' ||
        (perPage as string) !== previousPerPageRef.current.toString()
      ) {
        await handleGetNotifications();
        previousPerPageRef.current = perPage;
      }
    })();
  }, [perPage]);

  useEffect(() => {
    (async function initNotifications() {
      await handleGetNotifications();
    })();
  }, [currentPage]);

  useEffect(() => {
    (async function handleReloadNotifications() {
      if (
        paginator &&
        paginator.totalPages > 1 &&
        notificationsData &&
        notificationsData.length === 0 &&
        checkedNotifications.length === notificationsData.length
      ) {
        await handleGetNotifications();
      }
    })();
  }, [notificationsData]);

  const handleDeleteListItem = async (ids: string[]) => {
    if (notificationsData) {
      setIsDeletingLoading(true);
      setCheckedNotifications(checkedNotifications.filter((id) => ids.includes(id)));
      setNotificationsData(
        notificationsData.filter((notification) => !ids.includes(notification.uniqueId)),
      );
      setCheckedNotifications((prevState) => prevState.filter((n) => !ids.includes(n)));
      await handleDeleteNotifications(ids);
      setIsDeletingLoading(false);
    }
  };

  const handleMarkAsReadListItem = async () => {
    if (notificationsData) {
      setNotificationsData(
        notificationsData.map((notification) => {
          if (checkedNotifications.includes(notification.uniqueId)) {
            notification.read = true;
          }
          return notification;
        }),
      );
      await handleMarkAsReadNotifications(checkedNotifications);
    }
  };

  const isEmptyNotification =
    !isLoading && notificationsData && notificationsData.length === 0;
  const hasNotifications = !isLoading && notificationsData && notificationsData.length > 0;

  return (
    <>
      <Breadcrumbs items={[t('notificationList.breadcrumb')]} />
      <Grid container>
        <StyledPageTitle mb={2} variant="h4">
          {t('notificationList.notifications.label')}
        </StyledPageTitle>

        {
          // eslint-disable-next-line no-nested-ternary
          isLoading && notificationsData === null ? (
            <Grid item xs={12} sx={{ justifyContent: 'center' }}>
              <Loader isLoading={true} />
            </Grid>
          ) : isEmptyNotification && !isDeleteLoading ? (
            <EmptyNotificationResult />
          ) : (
            hasNotifications && (
              <>
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="flex-end"
                  sx={{ marginBottom: '20px' }}
                  gap={2}
                >
                  <Grid item xs={12} sm="auto" minWidth="103px">
                    <Button
                      label={t('notificationList.delete.button')}
                      variant={EButtonVariants.outlined}
                      disabled={!checkedNotifications.length}
                      onClick={async () => {
                        await handleDeleteListItem(checkedNotifications);
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm="auto" minWidth="161px">
                    <Button
                      label={t('notificationList.markAsRead.button')}
                      variant={EButtonVariants.outlined}
                      disabled={!checkedNotifications.length}
                      onClick={handleMarkAsReadListItem}
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} container>
                  <NotificationListHeader
                    onChange={(isAllChecked) =>
                      setCheckedNotifications(
                        isAllChecked && !!notificationsData
                          ? notificationsData.map((item) => item.uniqueId)
                          : [],
                      )
                    }
                    isAllChecked={
                      !!notificationsData &&
                      notificationsData.length > 0 &&
                      checkedNotifications.length > 0 &&
                      notificationsData.length === checkedNotifications.length
                    }
                  />

                  {paginator &&
                    notificationsData &&
                    notificationsData.map((notification) => (
                      <NotificationListItem
                        key={notification.uniqueId}
                        item={notification}
                        isChecked={checkedNotifications.includes(notification.uniqueId)}
                        handleDelete={async () => {
                          await handleDeleteListItem([notification.uniqueId]);
                        }}
                        onChange={(id: string) => {
                          setCheckedNotifications((prevState) =>
                            prevState.includes(id)
                              ? prevState.filter((item) => item !== id)
                              : [...prevState, id],
                          );
                        }}
                      />
                    ))}
                </Grid>
              </>
            )
          )
        }

        {paginator && (
          <Pagination
            count={paginator?.totalElements}
            perPage={perPage}
            onChangePage={(page) => setCurrentPage(page)}
            onChangePerPage={(value) => setPerPage(value)}
            current={currentPage}
            isLoading={isPaginationLoading}
            isVisible={!isLoading && !isDeleteLoading}
          />
        )}
      </Grid>
    </>
  );
};

export default NotificationsPage;
