import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { AuditLogEventName } from 'pages/AuditLogPage/AuditLogViewPage/styles';
import { IAuditLogItemResponse } from 'models/auditLog';
import { DetailsTemplate, EDetailsPageTypes } from 'components/templates/DetailsTemplate';
import Grid from '@mui/material/Grid';
import { useTranslations } from 'hooks/useTranslations';
import { EPermission } from 'models/permissions';
import { usePermission } from 'hooks/usePermission';
import { formatDate } from 'utils/date';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import { IParsedDictionary, parseDictionary } from 'helpers/dictionary';
import { useDictionary } from 'models/dictionaryContext';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { getUser } from 'requests/user';
import { IUser } from 'models/user';
import { getRoles } from 'requests/role';
import { useUsers } from 'pages/UsersPage/hooks';
import { EAuditLogEvents } from 'pages/AuditLogPage/AuditLogViewPage/index';
import { ERouteLinks } from 'models/route';
import { useNavigate } from 'react-router-dom';

interface IAdministrationUserEventDetails {
  auditLog: IAuditLogItemResponse;
}

const AdministrationUserEventDetails = ({ auditLog }: IAdministrationUserEventDetails) => {
  const [userDetails, setUserDetails] = useState<IUser | null>();
  const [previousUserDetails, setPreviousUserDetails] = useState<IUser | null>();
  const navigate = useNavigate();
  const [roleNames, setRoleNames] = useState<string[]>([]);
  const [previousRoleNames, setPreviousRoleNames] = useState<string[]>([]);
  const [prefixNames, setPrefixNames] = useState<string[]>([]);
  const [previousPrefixNames, setPreviousPrefixNames] = useState<string[]>([]);
  const { t } = useTranslations();
  const { hasPermissions } = usePermission();
  const { loadDictionaries } = useUsers();
  const { phoneCode, legalEntityPrefix } = useDictionary();
  const { longDateFormat, getDateFormat } = useGlobalProperty();

  const parsedPhoneCodeDictionary: IParsedDictionary = parseDictionary(phoneCode, 'name');

  useEffect(() => {
    if (auditLog?.previousObjectValue) {
      (async () => {
        const previousObjectValue = auditLog?.previousObjectValue as any;
        const previousUserData: IUser = {
          active: previousObjectValue.active || '',
          assignedLegalIds: previousObjectValue.assignedLegalIds || [],
          blocked: previousObjectValue.blocked || '',
          created: previousObjectValue.created || '',
          email: previousObjectValue.email || '',
          firstName: previousObjectValue.firstName || '',
          lastLoginDate: previousObjectValue.lastLoginDate || '',
          lastName: previousObjectValue.lastName || '',
          password: '',
          phoneNumber: previousObjectValue.phoneNumber || {
            codeDictionaryUniqueId: '',
            value: '',
          },
          prefixGroupIds: previousObjectValue.prefixGroupIds || [],
          removed: previousObjectValue.removed || '',
          roleIds: previousObjectValue.roleIds || [],
          status: previousObjectValue.status || '',
          trustedDeviceIds: previousObjectValue.trustedDeviceIds || [],
          version: previousObjectValue.version || 0,
          uniqueId: '',
        };
        setPreviousUserDetails(previousUserData);
        if (previousObjectValue.roleIds && previousObjectValue.roleIds.length) {
          const responseRoles = await getRoles(1, 100, {}, null);
          if (responseRoles) {
            const roles = responseRoles.content
              .map((role) => previousObjectValue.roleIds.includes(role.uniqueId) && role.name)
              .filter((row) => !!row);
            setPreviousRoleNames(roles as string[]);
          }
        }
        if (previousObjectValue.prefixGroupIds && previousObjectValue.prefixGroupIds.length) {
          if (legalEntityPrefix) {
            const names = legalEntityPrefix
              .map(
                (prefix) =>
                  previousObjectValue.prefixGroupIds.includes(prefix.uniqueId.toString()) &&
                  prefix.name,
              )
              .filter((row) => !!row);
            setPreviousPrefixNames(names as string[]);
          }
        }
      })();
    }

    if (auditLog?.objectUniqueId && hasPermissions([EPermission.MANAGE_USERS])) {
      (async () => {
        const response = await getUser(auditLog.objectUniqueId);
        if (response) {
          setUserDetails(response);
          if (response.roleIds && response.roleIds.length) {
            const responseRoles = await getRoles(1, 100, {}, null);
            if (responseRoles) {
              const roles = responseRoles.content
                .map((role) => response.roleIds.includes(role.uniqueId) && role.name)
                .filter((row) => !!row);
              setRoleNames(roles as string[]);
            }
          }
          if (response.prefixGroupIds && response.prefixGroupIds.length) {
            if (legalEntityPrefix) {
              const names = legalEntityPrefix
                .map(
                  (prefix) =>
                    response.prefixGroupIds.includes(prefix.uniqueId.toString()) &&
                    prefix.name,
                )
                .filter((row) => !!row);
              setPrefixNames(names as string[]);
            }
          }
        }
      })();
    }
  }, [auditLog, legalEntityPrefix]);

  useEffect(() => {
    getDateFormat();
    loadDictionaries();
  }, []);

  const statusChip = (userDetailsData: IUser | null | undefined) => {
    if (userDetailsData) {
      if (userDetailsData.removed) {
        return <Chips text={t('users.removed.label')} variant={EChipsVariants.error200} />;
      }
      if (userDetailsData.blocked) {
        return <Chips text={t('users.blocked.label')} variant={EChipsVariants.warning100} />;
      }
      return (
        <Chips
          text={userDetailsData.active ? t('users.active.label') : t('users.inactive.label')}
          variant={userDetailsData.active ? EChipsVariants.success : EChipsVariants.error}
        />
      );
    }
    return '-';
  };

  return (
    <>
      {previousUserDetails && (
        <>
          <AuditLogEventName>
            <Typography variant="h5">
              {auditLog?.eventNameUniqueId !== EAuditLogEvents.ADD_USER
                ? `${t('user.breadcrumbs.userDetails')} ${t(
                    'auditLogDetails.before.event.title',
                  )}`
                : `${t('user.breadcrumbs.userDetails')} ${t(
                    'auditLogDetails.after.event.title',
                  )}`}
            </Typography>
          </AuditLogEventName>
          <DetailsTemplate
            pageType={EDetailsPageTypes.auditLogView}
            title={`${previousUserDetails.firstName} ${previousUserDetails.lastName}`}
            disableActions={true}
          >
            <Grid item xs={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('userDetails.firstName.label')}
              </Typography>
              <Typography variant="body2">{previousUserDetails.firstName}</Typography>
            </Grid>
            <Grid item xs={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('userDetails.lastName.label')}
              </Typography>
              <Typography variant="body2">{previousUserDetails.lastName}</Typography>
            </Grid>
            <Grid item xs={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('userDetails.phoneNumber.label')}
              </Typography>
              <Typography variant="body2">{`${
                previousUserDetails.phoneNumber &&
                parsedPhoneCodeDictionary?.[
                  previousUserDetails.phoneNumber.codeDictionaryUniqueId
                ]
                  ? `${
                      parsedPhoneCodeDictionary?.[
                        previousUserDetails.phoneNumber.codeDictionaryUniqueId
                      ]
                    }${previousUserDetails.phoneNumber.value}`
                  : '-'
              }`}</Typography>
            </Grid>
            <Grid item xs={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('userDetails.lastLogged.label')}
              </Typography>
              <Typography variant="body2">
                {!previousUserDetails.removed
                  ? formatDate(previousUserDetails.lastLoginDate, longDateFormat)
                  : '-'}
              </Typography>
            </Grid>
            <Grid item xs={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('userDetails.role.label')}
              </Typography>
              <div>
                {previousRoleNames.length > 0
                  ? previousRoleNames.map((roleItem) => (
                      <Chips key={roleItem} text={roleItem} variant={EChipsVariants.primary} />
                    ))
                  : '-'}
              </div>
            </Grid>
            <Grid item xs={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('userDetails.status.label')}
              </Typography>
              <div>{statusChip(previousUserDetails)}</div>
            </Grid>
            <Grid item xs={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('userDetails.prefix.label')}
              </Typography>
              <div>
                {previousPrefixNames.map((item) => (
                  <Chips key={item} text={item} variant={EChipsVariants.primary} />
                ))}
              </div>
            </Grid>
          </DetailsTemplate>
        </>
      )}

      {userDetails && auditLog?.eventNameUniqueId !== EAuditLogEvents.DELETE_USER && (
        <>
          <AuditLogEventName sx={{ marginTop: '20px' }}>
            <Typography variant="h5">
              {`${t('user.breadcrumbs.userDetails')} ${t(
                'auditLogDetails.current.data.event.title',
              )}`}
            </Typography>
          </AuditLogEventName>
          <DetailsTemplate
            pageType={EDetailsPageTypes.auditLogView}
            title={`${userDetails.firstName} ${userDetails.lastName}`}
            redirectToView={() =>
              navigate(
                `${ERouteLinks.ViewUser.replace(':id', userDetails.uniqueId)}?type=individual`,
              )
            }
            disableActions={userDetails.removed}
          >
            <Grid item xs={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('userDetails.firstName.label')}
              </Typography>
              <Typography variant="body2">{userDetails.firstName}</Typography>
            </Grid>
            <Grid item xs={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('userDetails.lastName.label')}
              </Typography>
              <Typography variant="body2">{userDetails.lastName}</Typography>
            </Grid>
            <Grid item xs={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('userDetails.phoneNumber.label')}
              </Typography>
              <Typography variant="body2">{`${
                userDetails.phoneNumber &&
                parsedPhoneCodeDictionary?.[userDetails.phoneNumber.codeDictionaryUniqueId]
                  ? `${
                      parsedPhoneCodeDictionary?.[
                        userDetails.phoneNumber.codeDictionaryUniqueId
                      ]
                    }${userDetails.phoneNumber.value}`
                  : '-'
              }`}</Typography>
            </Grid>
            <Grid item xs={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('userDetails.lastLogged.label')}
              </Typography>
              <Typography variant="body2">
                {!userDetails.removed
                  ? formatDate(userDetails.lastLoginDate, longDateFormat)
                  : '-'}
              </Typography>
            </Grid>
            <Grid item xs={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('userDetails.role.label')}
              </Typography>
              <div>
                {roleNames.length
                  ? roleNames.map((roleItem) => (
                      <Chips key={roleItem} text={roleItem} variant={EChipsVariants.primary} />
                    ))
                  : '-'}
              </div>
            </Grid>
            <Grid item xs={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('userDetails.status.label')}
              </Typography>
              <div>{statusChip(userDetails)}</div>
            </Grid>
            <Grid item xs={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('userDetails.prefix.label')}
              </Typography>
              <div>
                {prefixNames.map((item) => (
                  <Chips key={item} text={item} variant={EChipsVariants.primary} />
                ))}
              </div>
            </Grid>
          </DetailsTemplate>
        </>
      )}
    </>
  );
};

export default AdministrationUserEventDetails;
