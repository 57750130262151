import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { StyledPageTitle } from 'theme/styles';
import { ERouteLinks } from 'models/route';
import { ELegalEntityTypes } from 'constants/LegalEntityTypes';
import { useQuery } from 'hooks/useQuery';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import { Accordion } from 'components/molecules/Accordion';
import { Typography } from '@mui/material';
import { StyledDetailsWrapper } from 'pages/Firearms/RegisteredFirearmDeleteCommentPage/styles';
import { Loader } from 'components/atoms/Loader';
import { DetailsTemplate } from 'components/templates/DetailsTemplate';
import { useFRT } from 'pages/FRT/hooks';
import { Table } from 'components/molecules/Table';
import { CardList } from 'components/molecules/CardList';
import { FRTSpecificationsCard } from 'pages/FRT/FRTSpecificationsCard';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import {
  FRTSpecificationsTableColumns,
  mapFRTSpecificationToDataSource,
} from 'pages/FRT/helpers';
import { StyledButton } from 'pages/Firearms/ViewRegisteredFirearmPage/styles';
import { useTranslations } from 'hooks/useTranslations';
import { EPermission } from 'models/permissions';
import { usePermission } from 'hooks/usePermission';
import { getHtmlText } from 'components/organisms/HtmlSlate';

const FRTFirearmDetailsPage = () => {
  const { id = '', frtId = '' } = useParams<{ id: string; frtId: string }>();
  const { hasPermissions } = usePermission();
  const query = useQuery();
  const type: string | null = query.get('type');
  const navigate = useNavigate();
  const { t } = useTranslations();
  const [isFRTSpecificationsLoading, setIsFRTSpecificationsLoading] = useState<boolean>(false);
  const [isAllExpanded, setIsAllExpanded] = useState<boolean>(false);
  const [expandedOnPageLoad, setExpandedOnPageLoad] = useState<boolean>(true);
  const [buttonClicked, setButtonClicked] = useState<boolean>(false);
  const {
    getFRTDetailsData,
    FRTDetails,
    getFRTSpecificationsData,
    FRTSpecifications,
    getFRTNotesData,
    FRTNotes,
  } = useFRT();
  const { state: routerState }: any = useLocation();

  const { isDesktop } = useThemeBreakpoint();

  useEffect(() => {
    (async function getFRTSpecificationsDataInit() {
      try {
        setIsFRTSpecificationsLoading(true);
        await Promise.all([
          getFRTDetailsData(frtId),
          getFRTSpecificationsData(frtId),
          getFRTNotesData(frtId),
        ]);
      } catch (e) {
        console.error(e);
      }
      setIsFRTSpecificationsLoading(false);
    })();
  }, [frtId]);

  useEffect(() => {
    if (buttonClicked) {
      setExpandedOnPageLoad(isAllExpanded);
    }
  }, [buttonClicked]);

  let frtDetailsTitle = '';
  if (FRTDetails !== null) {
    const { make, model, action } = FRTDetails;
    frtDetailsTitle = `${make}, ${model}, ${action}`;
  }
  const calibres = FRTSpecifications.map((spec) => spec.caliber).join(', ');
  const frtNotes = FRTNotes.map((note) => (
    <Grid key={note.type} item xs={12}>
      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
        {note.type}
      </Typography>
      {getHtmlText(note.description)}
    </Grid>
  ));

  useEffect(() => {
    if (FRTSpecifications.length === 1) {
      navigate(
        `${ERouteLinks.LegalEntityAddFirearm.replace(
          ':id',
          id as string,
        )}?type=${type}&expand=firearms`,
        {
          state: {
            ...routerState,
            firearm: {
              ...routerState.firearm,
              specificationId: FRTSpecifications[0].id,
              calibre: FRTSpecifications[0].caliber,
            },
          },
        },
      );
    }
  }, [FRTSpecifications]);

  return (
    <div>
      {!isFRTSpecificationsLoading ? (
        <>
          <Breadcrumbs
            items={[
              { label: t('legalEntities.header'), route: ERouteLinks.LegalEntities },
              {
                label:
                  type === ELegalEntityTypes.individual
                    ? t('legalEntity.individual.label')
                    : t('legalEntity.organisation.label'),
                route: `${ERouteLinks.LegalEntities}?expand=${
                  type === ELegalEntityTypes.individual ? 'individual' : 'organisation'
                }`,
              },
              {
                label: t('addFirearm.searchForFRT.legalEntityDetails.header'),
                route: `${ERouteLinks.ViewLegalEntity.replace(
                  ':id',
                  id,
                )}?type=${type}&expand=firearms`,
              },
              {
                label: t('addFirearm.searchForFRT.addFirearm.header'),
                route: `${ERouteLinks.LegalEntityAddFirearm.replace(
                  ':id',
                  id,
                )}?type=${type}&expand=firearms`,
              },
              {
                label: t('addFirearm.searchForFRT.searchForFRTFirearm.header'),
                route: `${ERouteLinks.SearchForFRTFirearm.replace(
                  ':id',
                  id,
                )}?type=${type}&expand=firearms`,
              },
              t('addFirearm.searchForFRT.FRTFirearmDetails.header'),
            ]}
          />
          <Box sx={{ mb: 2 }}>
            {FRTDetails && FRTDetails.isLocal ? (
              <StyledPageTitle variant="h4">
                {t('addFirearm.searchForFRT.FRTFirearmLocalDetails.header')}
              </StyledPageTitle>
            ) : (
              <StyledPageTitle variant="h4">
                {t('addFirearm.searchForFRT.FRTFirearmDetails.header')}
              </StyledPageTitle>
            )}
          </Box>

          <Grid container>
            <Grid item xs={12} mb={5}>
              {isFRTSpecificationsLoading ? (
                <Loader isLoading={isFRTSpecificationsLoading} />
              ) : (
                <DetailsTemplate
                  title={frtDetailsTitle}
                  deletePermissions={[EPermission.MANAGE_FRT_FIREARM]}
                >
                  <Grid item xs={12} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t('addFirearm.searchForFRT.calibres.label')}
                    </Typography>
                    <Typography variant="body2">{calibres}</Typography>
                  </Grid>
                </DetailsTemplate>
              )}
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', mb: 1 }}>
              <StyledButton
                label={
                  isAllExpanded ? t('general.collapseAll.label') : t('general.expandAll.label')
                }
                onClick={() => {
                  setIsAllExpanded((prev) => !prev);
                  setExpandedOnPageLoad((prev) => !prev);
                  setButtonClicked(true);
                }}
                variant="text"
              />
            </Grid>
            <Grid item xs={12}>
              <Accordion
                title={t('addFirearm.searchForFRT.general.accordion.label')}
                expanded={isAllExpanded}
              >
                {FRTDetails && (
                  <Box mb={2}>
                    <StyledDetailsWrapper container rowGap={3}>
                      <Grid item xs={12} sm={6} lg={3}>
                        {FRTDetails.isLocal ? (
                          <Typography variant="subtitle2">
                            {t('addFirearm.searchForFRT.firearmReferenceLocalID.label')}
                          </Typography>
                        ) : (
                          <Typography variant="subtitle2">
                            {t('addFirearm.searchForFRT.firearmReferenceNo.label')}
                          </Typography>
                        )}
                        {FRTDetails.isLocal ? (
                          <Typography variant="body2" sx={{ marginTop: '4px' }}>
                            {FRTDetails.frtId}
                          </Typography>
                        ) : (
                          <Typography variant="body2" sx={{ marginTop: '4px' }}>
                            {FRTDetails.id}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} lg={3}>
                        <Typography variant="subtitle2">
                          {t('addFirearm.searchForFRT.validAsOfDate.label')}
                        </Typography>
                        {FRTDetails.isLocal ? (
                          <Typography variant="body2" sx={{ marginTop: '4px' }}>
                            {FRTDetails.frtId}
                          </Typography>
                        ) : (
                          <Typography variant="body2" sx={{ marginTop: '4px' }}>
                            {FRTDetails.id}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} lg={3}>
                        <Typography variant="subtitle2">Manufacturer</Typography>
                        <Typography variant="body2" sx={{ marginTop: '4px' }}>
                          {FRTDetails.manufacturer}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} lg={3}>
                        <Typography variant="subtitle2">
                          {t('addFirearm.searchForFRT.countryOfManufacture.label')}
                        </Typography>
                        <Typography variant="body2" sx={{ marginTop: '4px' }}>
                          {FRTDetails.country}
                        </Typography>
                      </Grid>
                    </StyledDetailsWrapper>
                  </Box>
                )}
              </Accordion>
            </Grid>
            <Grid item xs={12}>
              <Accordion
                title={t('addFirearm.searchForFRT.calibreShotsAndBarrelLength.label')}
                expanded={routerState?.prevRoute ? expandedOnPageLoad : isAllExpanded}
              >
                <Box mb={2}>
                  {isFRTSpecificationsLoading ? (
                    <Loader isLoading={isFRTSpecificationsLoading} />
                  ) : (
                    <div>
                      {isDesktop ? (
                        <Table
                          columns={FRTSpecificationsTableColumns({
                            handleSelect: (specificationId: string, calibre: string) => {
                              if (FRTDetails !== null) {
                                navigate(
                                  `${ERouteLinks.LegalEntityAddFirearm.replace(
                                    ':id',
                                    id as string,
                                  ).replace(
                                    ':frtId',
                                    FRTDetails?.id,
                                  )}?type=${type}&expand=firearms`,
                                  {
                                    state: {
                                      ...routerState,
                                      firearm: { ...FRTDetails, specificationId, calibre },
                                    },
                                  },
                                );
                              }
                            },
                            isLocal: FRTDetails && Boolean(FRTDetails.isLocal),
                            t,
                            hasPermissionsManageFRT: hasPermissions([
                              EPermission.MANAGE_FRT_FIREARM,
                            ]),
                          })}
                          dataSource={mapFRTSpecificationToDataSource(FRTSpecifications)}
                        />
                      ) : (
                        <CardList
                          items={mapFRTSpecificationToDataSource(FRTSpecifications)}
                          render={(data, index) => (
                            <FRTSpecificationsCard
                              data={data}
                              key={index}
                              handleSelect={() => {
                                navigate(
                                  `${ERouteLinks.LegalEntityAddFirearm.replace(
                                    ':id',
                                    id as string,
                                  ).replace(
                                    ':frtId',
                                    FRTDetails?.id as string,
                                  )}?type=${type}&expand=firearms`,
                                  {
                                    state: {
                                      ...routerState,
                                      firearm: {
                                        ...FRTDetails,
                                        specificationId: data.id,
                                        calibre: data.caliber,
                                      },
                                    },
                                  },
                                );
                              }}
                            />
                          )}
                        />
                      )}
                    </div>
                  )}
                </Box>
              </Accordion>
            </Grid>
            <Grid item xs={12}>
              <Accordion
                title={t('addFirearm.searchForFRT.notes.accordion.label')}
                expanded={isAllExpanded}
              >
                <div>
                  {isFRTSpecificationsLoading ? (
                    <Loader isLoading={isFRTSpecificationsLoading} />
                  ) : (
                    <StyledDetailsWrapper container rowGap={3}>
                      {frtNotes}
                    </StyledDetailsWrapper>
                  )}
                </div>
              </Accordion>
            </Grid>
          </Grid>
        </>
      ) : (
        <Loader isLoading={isFRTSpecificationsLoading} />
      )}
    </div>
  );
};
export { FRTFirearmDetailsPage };
