import styled from '@emotion/styled';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';
import { FormInput } from 'components/molecules/FormInput';
import { FormSelect } from 'components/molecules/FormSelect';
import Button from 'components/atoms/Button';
import { Theme } from '@mui/material';

export const StyledSearchWrapper = styled(Grid)`
  background-color: ${colors.primary25};
  border-radius: 4px;
  padding: 16px 20px;
  margin-bottom: 20px;

  ${({ theme }) => `
    ${(theme as Theme).breakpoints.up('lg')} {
      height: 80px;
      margin-bottom: 0;
      };
      `};
`;

export const StyledFormSelect = styled(FormSelect)`
  height: 40px;
  & fieldset {
    border: none;
    border-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    ${({ theme }) => `
          ${(theme as Theme).breakpoints.down('lg')} {
            border-radius: 4px;
            };
            `};
  }
  & .MuiOutlinedInput-root {
    height: 40px;
    font-size: 16px;
    border-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    ${({ theme }) => `
      ${(theme as Theme).breakpoints.down('lg')} {
        border-radius: 4px;
        };
        `};
    border: 1px solid ${colors.primary500};

    & .MuiSelect-select {
      height: 38px;
      padding: 9px 0 9px 13px;
      max-height: 100%;
    }
  }
`;

export const StyledSearchInput = styled(FormInput)`
  height: 40px;
  & fieldset {
    border: none;
    border-radius: 0;
    ${({ theme }) => `
      ${(theme as Theme).breakpoints.down('lg')} {
        border-radius: 4px;
        };
        `};
  }
  & .MuiOutlinedInput-root {
    padding: 9px 5px 9px 0px;
    border-radius: 0;
    font-size: 16px;
    ${({ theme }) => `
      ${(theme as Theme).breakpoints.down('lg')} {
        border-radius: 4px;
        };
        `};
    border: 1px solid ${colors.primary500};
  }
  & .MuiInputBase-input {
    padding: 0px;
    font-size: 16px;
  }
  & .MuiInputBase-root {
    margin: 0;
    height: 40px;
    ${({ theme }) => `
    ${(theme as Theme).breakpoints.up('lg')} {
      border-left: none !important;
      };
      `};
  }
  & .MuiInputAdornment-root {
    background-color: white;
    padding: 16px 11px 17px;
    border: none;
    border-radius: 0;
  }
`;

export const StyledSearchButton = styled(Button)`
  height: 40px;
  ${({ theme }) => `
    ${(theme as Theme).breakpoints.up('lg')} {
        border-radius: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      };
      `};
`;

export const StyledDashboardButton = styled(Button)`
  height: 56px;
  margin: 0;
`;
