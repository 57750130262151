import React, { ReactElement } from 'react';
import { EButtonSizes, EButtonSizesPX, EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import Icon from 'components/atoms/Icon/Icon';
import { CircularProgress, SxProps } from '@mui/material';
import { EPermission } from 'models/permissions';
import { usePermission } from 'hooks/usePermission';
import { StyledButton, StyledIconButton } from './styles';

export interface IButton {
  id?: string;
  label?: string;
  icon?: EIconTypes;
  endIcon?: boolean;
  variant?:
    | 'text'
    | 'outlined'
    | 'contained'
    | undefined
    | EButtonVariants.outlinedIconTertiary;
  onClick?: (...args: any) => void;
  disabled?: boolean;
  size?: 'small' | 'medium' | 'large' | undefined;
  tableButton?: boolean;
  sizePX?: EButtonSizesPX;
  className?: string;
  isLoading?: boolean;
  fullWidth?: boolean;
  type?: 'button' | 'submit' | 'reset';
  sx?: SxProps;
  permission?: EPermission | EPermission[];
  isUploadButton?: boolean;
  uploadButtonAcceptExtensions?: string;
  isMultiple?: boolean;
}

const Button = ({
  id,
  label,
  icon,
  endIcon = false,
  onClick,
  disabled = false,
  variant = EButtonVariants.contained,
  size = EButtonSizes.medium,
  tableButton = false,
  sizePX = EButtonSizesPX.px24,
  className = '',
  isLoading = false,
  fullWidth = false,
  type = 'button',
  sx,
  permission,
  isUploadButton = false,
  uploadButtonAcceptExtensions = '*',
  isMultiple = false,
}: IButton): ReactElement | null => {
  const { hasPermissions } = usePermission();

  if (permission && !hasPermissions(permission)) {
    return null;
  }

  return label ? (
    <StyledButton
      className={className}
      size={size}
      variant={variant}
      {...(!isUploadButton ? { onClick } : {})}
      disabled={disabled}
      disableElevation={true}
      disableRipple={true}
      startIcon={icon && !endIcon && !isLoading ? <Icon type={icon} /> : null}
      endIcon={icon && endIcon && !isLoading ? <Icon type={icon} /> : null}
      isLoading={isLoading}
      fullWidth={fullWidth}
      type={type}
      sx={sx}
      {...(!isUploadButton ? { id } : {})}
      {...(isUploadButton ? { component: 'label' } : {})}
    >
      {isLoading ? <CircularProgress size={20} /> : <span>{label}</span>}
      {isUploadButton && (
        <input
          id={id}
          hidden
          accept={uploadButtonAcceptExtensions}
          multiple={isMultiple}
          type="file"
          onChange={(e) => {
            if (onClick) {
              onClick(e.target.files);
              e.target.value = '';
            }
          }}
        />
      )}
    </StyledButton>
  ) : (
    <StyledIconButton
      className={className}
      variant={variant}
      disabled={disabled}
      onClick={onClick}
      tableButton={tableButton}
      sizePX={sizePX}
      disableRipple={true}
      sx={sx}
      id={id}
    >
      {icon ? <Icon type={icon} /> : null}
    </StyledIconButton>
  );
};

export default Button;
