import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { useNavigate, useParams } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import { Loader } from 'components/atoms/Loader';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import Grid from '@mui/material/Grid';
import { Accordion } from 'components/molecules/Accordion';
import { Table } from 'components/molecules/Table';
import {
  legalEntityGroupAncillariesDetailsTableColumns,
  legalEntityGroupAncillariesTableColumns,
  mapLegalEntityGroupAncillaryToDataSource,
} from 'pages/LegalEntitiesPage/helpers';
import { CardList } from 'components/molecules/CardList';
import { Pagination } from 'components/molecules/Pagination';
import { EPerPages, ISortOptions } from 'models/table';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { GroupAncillarySearchForm } from 'pages/LegalEntitiesPage/GroupAncillarySearchForm';
import { LegalEntityGroupAncillaryTableCard } from 'pages/LegalEntitiesPage/LegalEntityGroupAncillaryTableCard';
import { Box, Typography } from '@mui/material';
import { Alert, EAlertVariants, IAlert } from 'components/atoms/Alert';
import { useAlert } from 'models/alertContext';
import { useTranslations } from 'hooks/useTranslations';
import { ActionTypes } from 'state/actions/alert';
import { ISearchAncillaryFormValues } from 'models/form';
import { DetailsTemplate, EDetailsPageTypes } from 'components/templates/DetailsTemplate';
import { useQuery } from 'hooks/useQuery';
import { ELegalEntityTypes } from 'constants/LegalEntityTypes';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import { TableTopButtons } from 'components/atoms/TableTopButtons';
import { usePermission } from 'hooks/usePermission';
import { EPermission } from 'models/permissions';
import { ELegalVisibility } from 'constants/LegalVisibility';
import { useAuth } from 'models/authContext';
import { useAccount } from 'pages/AccountPage/hooks';

const ViewLegalEntityAncillaryGroupPage = () => {
  const { id = '', groupId = '' } = useParams<{ id: string; groupId: string }>();
  const query = useQuery();
  const type: string | null = query.get('type');
  const [isLegalEntityAncillariesGroupLoading, setIsLegalEntityAncillariesGroupLoading] =
    useState<boolean>(true);
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { hasPermissions } = usePermission();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPageLEF] = useState<EPerPages>(EPerPages.perPage25);
  const [groupAncillaryQuery, setGroupAncillaryQuery] = useState<
    Partial<ISearchAncillaryFormValues>
  >({});
  const [ancillaryOpenDetail, setAncillaryOpenDetail] = useState<string>('');
  const [ancillarySort, setAncillarySort] = useState<ISortOptions | null>(null);
  const [ancillaryTypeOpenDetail, setAncillaryTypeOpenDetail] = useState<string | undefined>(
    undefined,
  );
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);
  const {
    getAncillaryGroupDetail,
    ancillaryGroupDetail,
    getAncillaryGroupAncillariesSearch,
    legalEntityAncillaryGroupAncillaries,
    paginatorAncillaryGroupAncillaries,
  } = useLegalEntities();
  const { isDesktop } = useThemeBreakpoint();
  const { legalEntityAlert, nextPageAlert, samePageAlert, clearAlert, setAlert } = useAlert();

  const { user, getUser } = useAuth();
  const { account, getSelfAccountData } = useAccount();

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    (async function getAccountDataInit() {
      if (user?.userId) {
        await getSelfAccountData();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (ancillaryOpenDetail && legalEntityAncillaryGroupAncillaries?.length) {
      setAncillaryTypeOpenDetail(
        legalEntityAncillaryGroupAncillaries?.find(
          (item) => item.uniqueId === ancillaryOpenDetail,
        )?.ancillaryType,
      );
    } else {
      setAncillaryTypeOpenDetail(undefined);
    }
  }, [ancillaryOpenDetail, legalEntityAncillaryGroupAncillaries]);

  const getDetails = async () => {
    try {
      await getAncillaryGroupDetail(id, groupId as string);
    } catch (e: any) {
      navigate(
        `${ERouteLinks.ViewLegalEntity.replace(
          ':id',
          id,
        )}?type=${type}&expand=ancillaryGroups`,
      );
    }
  };

  useEffect(() => {
    (async function initDictionaries() {
      setIsLegalEntityAncillariesGroupLoading(true);
      await getDetails();
      setIsLegalEntityAncillariesGroupLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, groupId]);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_LEGAL_ENTITY_ALERT);
      clearAlert(ActionTypes.CLEAR_NEXT_PAGE_ALERT);
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const getAncillaries = async () => {
    await getAncillaryGroupAncillariesSearch(
      id as string,
      groupId as string,
      ancillarySort,
      currentPage,
      perPage,
      groupAncillaryQuery,
    );
  };

  useEffect(() => {
    (async function initAncillariesList() {
      setIsPaginationLoading(true);
      setIsLegalEntityAncillariesGroupLoading(true);
      getAncillaries();
      setIsLegalEntityAncillariesGroupLoading(false);
      setIsPaginationLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, perPage, groupAncillaryQuery, ancillarySort]);

  const handleAddTransaction = () => {
    if (legalEntityAncillaryGroupAncillaries.length) {
      navigate(
        `${ERouteLinks.LegalEntityAddTransactionForAncillaryGroup.replace(':id', id).replace(
          ':groupId',
          groupId,
        )}?type=${type}&expand=ancillaryGroups`,
        { state: { isDetailsContext: true, type } },
      );
    } else {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: t('ancillaryGroupDetails.emptyAncillaryList.warning.alert'),
        variant: EAlertVariants.warning,
      });
    }
  };

  const handleAddAncillaryToGroup = () => {
    if (!ancillaryGroupDetail?.hasDifferentStates) {
      navigate(
        `${ERouteLinks.AddToAncillaryGroup.replace(':groupId', groupId as string).replace(
          ':id',
          id as string,
        )}?type=${type}&expand=ancillaryGroups`,
        { state: { prevPage: ERouteLinks.ViewLegalEntityAncillaryGroup } },
      );
    } else {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: t('groupDetails.addToGroup.groupHasElementsWithDifferentStates.message'),
        variant: EAlertVariants.warning,
      } as IAlert);
    }
  };

  const isAddTransactionVisible = () => {
    if (hasPermissions(EPermission.VIEW_ALL_LE)) return true;
    if (hasPermissions(EPermission.VIEW_LE)) {
      return (
        (ancillaryGroupDetail?.keeperVisibilityUniqueId !== ELegalVisibility.NON_PUBLIC ||
          account?.assignedLegalIds.includes(ancillaryGroupDetail.keeperId)) &&
        (ancillaryGroupDetail?.ownerVisibilityUniqueId !== ELegalVisibility.NON_PUBLIC ||
          account?.assignedLegalIds.includes(ancillaryGroupDetail.ownerId))
      );
    }
    return false;
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.legalEntity.name'), route: ERouteLinks.LegalEntities },
          {
            label:
              type === ELegalEntityTypes.individual
                ? t('legalEntities.individual.accordion.label')
                : t('legalEntities.organization.accordion.label'),
            route: `${ERouteLinks.LegalEntities}?expand=${type}`,
          },
          {
            label: t('legalEntityDetails.legalEntityDetails.text'),
            route: `${ERouteLinks.ViewLegalEntity.replace(
              ':id',
              id as string,
            )}?type=${type}&expand=ancillaryGroups`,
          },
          t('groupDetails.header'),
        ]}
      />
      <div>
        {isLegalEntityAncillariesGroupLoading || !ancillaryGroupDetail ? (
          <Loader isLoading={isLegalEntityAncillariesGroupLoading} />
        ) : (
          <DetailsTemplate
            title={ancillaryGroupDetail.name}
            pageType={EDetailsPageTypes.view}
            editPermissions={[EPermission.MANAGE_GROUPS_LE]}
            redirectToEditView={() =>
              navigate(
                `${ERouteLinks.EditAncillaryGroup.replace(':id', id as string).replace(
                  ':groupId',
                  groupId,
                )}?type=${type}&expand=ancillaryGroups`,
                {
                  state: {
                    returnLink: `${ERouteLinks.ViewLegalEntityAncillaryGroup.replace(
                      ':id',
                      id as string,
                    ).replace(':groupId', groupId)}?type=${type}&expand=ancillaryGroups`,
                  },
                },
              )
            }
            redirectToView={() =>
              navigate(
                `${ERouteLinks.DeleteAncillaryGroup.replace(':id', id as string).replace(
                  ':groupId',
                  groupId,
                )}?type=${type}&expand=ancillaryGroups`,
                {
                  state: {
                    returnLink: `${ERouteLinks.ViewLegalEntityAncillaryGroup.replace(
                      ':id',
                      id as string,
                    ).replace(':groupId', groupId)}?type=${type}&expand=ancillaryGroups`,
                  },
                },
              )
            }
          >
            <Grid item xs={12} sm={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('deleteAncillariesGroup.groupName.label')}
              </Typography>
              <Typography variant="body2">{ancillaryGroupDetail.name}</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('deleteAncillariesGroup.owner.label')}
              </Typography>
              <Typography variant="body2">{ancillaryGroupDetail.owner || '-'}</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('deleteAncillariesGroup.keeper.label')}
              </Typography>
              <Typography variant="body2">{ancillaryGroupDetail.keeper || '-'}</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('deleteAncillariesGroup.ancillaryAmount.label')}
              </Typography>
              <Typography variant="body2">{ancillaryGroupDetail.ancillaryAmount}</Typography>
            </Grid>
          </DetailsTemplate>
        )}
      </div>

      <Grid container>
        <Grid item xs={12} sx={{ my: 2 }}>
          <Box sx={{ visibility: legalEntityAlert ? 'visible' : 'hidden' }}>
            <Alert
              text={legalEntityAlert ? legalEntityAlert.text : ''}
              variant={legalEntityAlert ? legalEntityAlert.variant : EAlertVariants.success}
            />
          </Box>
          {nextPageAlert && (
            <Box mt={2}>
              <Alert text={nextPageAlert.text} variant={nextPageAlert.variant} />
            </Box>
          )}
        </Grid>
        <Grid item xs={12}>
          <Accordion title={t('addGroup.ancillaryList.section.label')} alwaysExpanded={true}>
            <GroupAncillarySearchForm onSubmit={setGroupAncillaryQuery} />
            <TableTopButtons>
              {isAddTransactionVisible() && (
                <Button
                  variant={EButtonVariants.contained}
                  icon={EIconTypes.plus}
                  label={t('ancillaries.list.addTransactionToAncillary.button')}
                  onClick={handleAddTransaction}
                  permission={[EPermission.ADD_TRANSACTION]}
                  id="addTransactionToAncillary"
                />
              )}
              <Button
                variant={EButtonVariants.contained}
                icon={EIconTypes.plus}
                label={t('ancillaries.list.addAncillaryToGroup.button')}
                onClick={handleAddAncillaryToGroup}
                permission={[EPermission.MANAGE_GROUPS_LE]}
                id="addAncillaryToGroup"
              />
            </TableTopButtons>
            {samePageAlert && (
              <Grid mb={2}>
                <Alert variant={samePageAlert.variant} text={samePageAlert.text} />
              </Grid>
            )}
            {isDesktop ? (
              <Table
                columns={legalEntityGroupAncillariesTableColumns({
                  id,
                  groupId,
                  type,
                  ancillaryOpenDetail,
                  setAncillaryOpenDetail,
                })}
                detailId={ancillaryOpenDetail}
                detailTitle={`${ancillaryTypeOpenDetail} details`}
                onSort={setAncillarySort}
                detailsColumns={legalEntityGroupAncillariesDetailsTableColumns(
                  ancillaryTypeOpenDetail,
                )}
                dataSource={mapLegalEntityGroupAncillaryToDataSource(
                  legalEntityAncillaryGroupAncillaries,
                )}
                translationsKeys={{
                  noResults: 'registeredAncillaries.accordion.noResults.text',
                }}
              />
            ) : (
              <CardList
                items={mapLegalEntityGroupAncillaryToDataSource(
                  legalEntityAncillaryGroupAncillaries,
                )}
                render={(data, index) => (
                  <LegalEntityGroupAncillaryTableCard
                    data={data}
                    key={index}
                    id={id}
                    type={type}
                    groupId={groupId}
                    viewAction={(ancillaryId: string, ancillaryType: string) =>
                      navigate(
                        ERouteLinks.ViewAncillary.replace(':id', ancillaryId).replace(
                          ':type',
                          ancillaryType,
                        ),
                      )
                    }
                  />
                )}
              />
            )}

            {paginatorAncillaryGroupAncillaries && (
              <Pagination
                count={paginatorAncillaryGroupAncillaries?.totalElements}
                perPage={perPage}
                onChangePage={(page) => {
                  setCurrentPage(page);
                }}
                onChangePerPage={(value) => {
                  setPerPageLEF(value);
                }}
                current={currentPage}
                isLoading={isPaginationLoading}
              />
            )}
          </Accordion>
        </Grid>
      </Grid>
    </>
  );
};

export { ViewLegalEntityAncillaryGroupPage };
