import React, { ReactElement, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { FormInput } from 'components/molecules/FormInput';
import { useForm } from 'react-hook-form';
import { initialForgotPasswordFormValues } from 'models/form';
import { yupResolver } from '@hookform/resolvers/yup';
import { EButtonVariants } from 'constants/Buttons';
import { RouterLink } from 'components/atoms/RouterLink';
import { IForgotPasswordBodyRequest } from 'models/password';
import { Typography } from '@mui/material';
import Button from 'components/atoms/Button';
import { ERouteLinks } from 'models/route';
import { Box } from '@mui/system';
import {
  AuthTemplate,
  getBase64Data,
  StyledAuthHeader,
  StyledImg,
} from 'components/templates/AuthTemplate';
import { usePassword } from 'models/passwordContext';
import { changeForgotPasswordRecaptcha } from 'requests/auth';
import { colors } from 'theme/colors';
import useForgotPasswordFormSchema from 'pages/ForgotPasswordPage/schemaValidation';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { ELoginPartTypes } from 'models/loginScreenSetup';
import { IImageData, useLogoSetup } from 'pages/SystemSettingsPage/LogoSetupPage/hooks';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { Loader } from 'components/atoms/Loader';

const ForgotPasswordPage = (): ReactElement => {
  const [formStep, setFormStep] = useState(1);
  const [isLoadingLoginScreen, setLoadingLoginScreen] = useState<boolean>(true);
  const { schema } = useForgotPasswordFormSchema();
  const [recaptchaError, setRecaptchaError] = useState<string>('');
  const [isBannedAccountError, setIsBannedAccountError] = useState<boolean>(false);
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [isRecaptchaVerifiedSuccessfully, setIsRecaptchaVerifiedSuccessfully] =
    useState<boolean>(false);
  const { getLoginScreen, loginScreenDataParsed } = useLogoSetup();

  const anyLogoPresent: boolean =
    loginScreenDataParsed !== null &&
    loginScreenDataParsed !== undefined &&
    (loginScreenDataParsed[ELoginPartTypes.LOGO_1] !== undefined ||
      loginScreenDataParsed[ELoginPartTypes.LOGO_2] !== undefined ||
      loginScreenDataParsed[ELoginPartTypes.LOGO_3]) !== undefined;

  useEffect(() => {
    setLoadingLoginScreen(true);
    (async () => {
      await getLoginScreen().then(() => {
        setLoadingLoginScreen(false);
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => setFormStep(1), []);

  useEffect(() => {
    (async function handleRecaptchaValidation() {
      if (recaptchaToken && !isRecaptchaVerifiedSuccessfully) {
        setRecaptchaError('');
        const res = await changeForgotPasswordRecaptcha(recaptchaToken);
        if (res && Object.prototype.hasOwnProperty.call(res, 'success')) {
          setIsRecaptchaVerifiedSuccessfully(res.success);
        }
      }
    })();
  }, [recaptchaToken]);

  const toggleCaptchaBadge = (show: boolean) => {
    const badge = document.getElementsByClassName('grecaptcha-badge')[0];
    if (badge && badge instanceof HTMLElement) {
      badge.style.visibility = show ? 'visible' : 'hidden';
    }
  };

  useEffect(() => {
    toggleCaptchaBadge(true);
    return () => toggleCaptchaBadge(false);
  }, []);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: initialForgotPasswordFormValues,
    resolver: yupResolver(schema),
  });
  const { forgotPassword, isForgotPasswordLoading } = usePassword();

  const onSubmit = handleSubmit(async (data: IForgotPasswordBodyRequest) => {
    if ((formStep === 1 && isRecaptchaVerifiedSuccessfully) || formStep === 2) {
      forgotPassword(
        data,
        () => setFormStep(2),
        () => setIsBannedAccountError(true),
      );
    } else {
      setRecaptchaError('Please verify that you are not a robot.');
    }
  });

  const handleChangeRecaptchaToken = (token: string) => {
    if (!recaptchaToken) {
      setRecaptchaToken(token);
    }
  };

  const renderForgotPasswordFormStep = (step: number) => {
    if (step === 1) {
      return (
        <>
          <Grid item xs={12}>
            <Typography variant="body1" textAlign="center" mb={2}>
              Please enter your email address to set
              <br />a new password to access FireCycle.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <FormInput
                name="email"
                label="Email"
                placeholder="name@domain.com"
                control={control}
                errors={errors}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    height: { xs: 32, sm: 40 },
                  },
                }}
              />
              {recaptchaError && (
                <Box sx={{ minHeight: '24px' }}>
                  <Typography variant="caption" color={colors.error700}>
                    {recaptchaError}
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>
        </>
      );
    }
    if (step === 2) {
      return (
        <Grid item xs={12}>
          <Typography
            variant="body1"
            textAlign="center"
            sx={{
              mt: {
                xs: 0,
                sm: 5,
              },
            }}
          >
            A reset password link has been sent to <br />
            the provided email address. <br />
            Follow the link to set a new password <br />
            to access FireCycle.
          </Typography>
          <Typography variant="body1" textAlign="center" mt={2} mb={4}>
            The link is valid for 24 hours.
          </Typography>
          <Typography variant="body1" textAlign="center">
            {`${"Didn't receive an email?"}`}
          </Typography>
        </Grid>
      );
    }
    return null;
  };

  return (
    <AuthTemplate>
      <GoogleReCaptcha onVerify={handleChangeRecaptchaToken} />
      <Grid
        item
        container
        justifyContent="center"
        alignItems={anyLogoPresent ? 'flex-start' : 'center'}
        sm={10}
        md={7}
        lg={6}
        pb={5}
        sx={{ p: { xs: 4 } }}
        px={2}
      >
        {isLoadingLoginScreen && (
          <Grid item container justifyContent="center">
            <Loader isLoading={isLoadingLoginScreen} />
          </Grid>
        )}
        {anyLogoPresent && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
              minHeight: '151px',
              width: '100%',
            }}
            mt={3}
          >
            <Box>
              {loginScreenDataParsed?.[ELoginPartTypes.LOGO_1] && (
                <StyledImg
                  src={getBase64Data(
                    loginScreenDataParsed[ELoginPartTypes.LOGO_1] as IImageData,
                  )}
                  alt="Logo One"
                />
              )}
            </Box>
            <Box pr={2} pl={2}>
              {loginScreenDataParsed?.[ELoginPartTypes.LOGO_2] && (
                <StyledImg
                  src={getBase64Data(
                    loginScreenDataParsed[ELoginPartTypes.LOGO_2] as IImageData,
                  )}
                  alt="Logo Two"
                />
              )}
            </Box>
            <Box>
              {loginScreenDataParsed?.[ELoginPartTypes.LOGO_3] && (
                <StyledImg
                  src={getBase64Data(
                    loginScreenDataParsed[ELoginPartTypes.LOGO_3] as IImageData,
                  )}
                  alt="Logo Three"
                />
              )}
            </Box>
          </Box>
        )}
        <Grid item xl={6} container justifyContent="center" alignItems="flex-start">
          {isBannedAccountError && (
            <Grid mb={3}>
              <Alert
                variant={EAlertVariants.error}
                text="Your account has been blocked. Please contact your system administrator."
              />
            </Grid>
          )}
          <Grid item xs={12} mt={4}>
            <StyledAuthHeader mb={4}>Forgot Password?</StyledAuthHeader>
          </Grid>
          {renderForgotPasswordFormStep(formStep)}
          <Grid item xs={12} sm={8} xl={12} container justifyContent="center">
            <Grid item xs={12} my={3}>
              <Button
                label={formStep === 1 ? 'Reset password' : 'Resend Link'}
                variant={formStep === 1 ? EButtonVariants.contained : EButtonVariants.outlined}
                onClick={() => onSubmit()}
                isLoading={isForgotPasswordLoading}
                disabled={!isRecaptchaVerifiedSuccessfully}
                fullWidth
              />
            </Grid>
            <RouterLink to={ERouteLinks.AuthWithSlash} label="Return to login" replace />
          </Grid>
        </Grid>
      </Grid>
    </AuthTemplate>
  );
};
export { ForgotPasswordPage };
