import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Controller } from 'react-hook-form';
import FormLabel from 'components/atoms/FormLabel';
import FormErrorLabel from 'components/atoms/FormErrorLabel';
import { StyledFormElementWrapper } from 'theme/styles';
import { IFormElementOptionProp, IFormElementProps } from 'models/form';
import { colors } from 'theme/colors';

interface IFormRadioButtonGroup extends IFormElementProps {
  label: string;
  options: IFormElementOptionProp[];
  onChangeOption?: (arg: EventTarget) => void;
}
export const FormRadioGroup = ({
  name,
  label,
  options,
  control,
  errors,
  disabled,
  onChangeOption,
}: IFormRadioButtonGroup) => (
  <StyledFormElementWrapper>
    <FormLabel name={name} label={label} />
    <RadioGroup
      aria-labelledby={name}
      name={name}
      sx={{ flexDirection: 'row', alignItems: 'center' }}
    >
      {options.map((option: IFormElementOptionProp) => (
        <Controller
          key={option.value}
          name={name}
          control={control}
          render={({ field }) => (
            <FormControlLabel
              {...field}
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize: 14,
                  fontWeight: 400,
                  color: colors.black,
                },
              }}
              disabled={disabled}
              value={option.value}
              checked={field.value === option.value}
              onChange={(e) => {
                field.onChange(e);
                if (onChangeOption) {
                  onChangeOption(e.target);
                }
              }}
              control={
                <Radio
                  size="small"
                  id={`${name}-${option.value}`}
                  sx={{
                    color: colors.gray300,
                    '&.Mui-checked': {
                      color: colors.primary700,
                    },
                  }}
                />
              }
              label={option.label}
            />
          )}
        />
      ))}
    </RadioGroup>
    <FormErrorLabel label={errors[name]?.message} />
  </StyledFormElementWrapper>
);
