import { DashboardPage } from 'pages/DashboardPage';
import { EPageComponentTypes } from 'models/route';
import { TableTestPage } from 'pages/TableTestPage';
import LegalEntitiesPage from 'pages/LegalEntitiesPage';
import FirearmsPage from 'pages/Firearms';
import { ViewRegisteredFirearmPage } from 'pages/Firearms/ViewRegisteredFirearmPage';
import { EditRegisteredFirearmPage } from 'pages/Firearms/EditRegisteredFirearmPage';
import { RegisteredFirearmAddCommentPage } from 'pages/Firearms/RegisteredFirearmAddCommentPage';
import { RegisteredFirearmDeleteCommentPage } from 'pages/Firearms/RegisteredFirearmDeleteCommentPage';
import { RegisteredFirearmAddAttachmentPage } from 'pages/Firearms/RegisteredFirearmAddAttachmentPage';
import { RegisteredFirearmDeleteAttachmentPage } from 'pages/Firearms/RegisteredFirearmDeleteAttachmentPage';
import TransactionsPage from 'pages/TransactionsPage';
import UsersPage from 'pages/UsersPage';
import { AddUserPage } from 'pages/UsersPage/AddUserPage';
import RolesPage from 'pages/RolesPage';
import DictionariesPage from 'pages/DictionariesPage';
import ViewDictionaryPage from 'pages/DictionariesPage/ViewDictionaryPage';
import { AddDictionaryValuePage } from 'pages/DictionariesPage/ViewDictionaryPage/AddDictionaryValuePage';
import { EditDictionaryValuePage } from 'pages/DictionariesPage/ViewDictionaryPage/EditDictionaryValuePage';
import AdministrationPage from 'pages/AdministrationPage';
import SystemSettingsPage from 'pages/SystemSettingsPage';
import DateTimeSetupPage from 'pages/SystemSettingsPage/DateTimeSetupPage';
import NotificationsSetupPage from 'pages/SystemSettingsPage/NotificationsSetupPage';
import NotificationsPage from 'pages/NotificationsPage';
import { StyleGuidePage } from 'pages/StyleGuidePage';
import { AddRolePage } from 'pages/RolesPage/AddRolePage';
import { ForgotPasswordPage } from 'pages/ForgotPasswordPage';
import { ResetPasswordPage } from 'pages/ResetPasswordPage';
import { AuthPage } from 'pages/AuthPage';
import { EditRolePage } from 'pages/RolesPage/EditRolePage';
import { ViewRolePage } from 'pages/RolesPage/ViewRolePage';
import { DeleteRolePage } from 'pages/RolesPage/DeleteRolePage';
import AncillariesPage from 'pages/AncillariesPage';
import AddAncillariesReference from 'pages/AncillariesPage/AncillariesReferenceTablePage/AddAncillariesReference';
import EditAncillariesReference from 'pages/AncillariesPage/AncillariesReferenceTablePage/EditAncillariesReference';
import { AddLegalEntityPage } from 'pages/LegalEntitiesPage/AddLegalEntityPage';
import { EditLegalEntityPage } from 'pages/LegalEntitiesPage/EditLegalEntityPage';
import { DeleteLegalEntityPage } from 'pages/LegalEntitiesPage/DeleteLegalEntityPage';
import { ViewLegalEntityPage } from 'pages/LegalEntitiesPage/ViewLegalEntityPage';
import { LegalEntityAddCommentPage } from 'pages/LegalEntitiesPage/LegalEntityAddCommentPage';
import { LegalEntityDeleteCommentPage } from 'pages/LegalEntitiesPage/LegalEntityDeleteCommentPage';
import { AccountPage } from 'pages/AccountPage';
import { LegalEntityAddAddressPage } from 'pages/LegalEntitiesPage/LegalEntityAddAddressPage';
import { LegalEntityEditAddressPage } from 'pages/LegalEntitiesPage/LegalEntityEditAddressPage';
import { LegalEntityDeleteAddressPage } from 'pages/LegalEntitiesPage/LegalEntityDeleteAddressPage';
import { LegalEntityEditContactPage } from 'pages/LegalEntitiesPage/LegalEntityEditContactPage';
import { LegalEntityDeleteContactPage } from 'pages/LegalEntitiesPage/LegalEntityDeleteContactPage';
import { LegalEntityAddContactPage } from 'pages/LegalEntitiesPage/LegalEntityAddContactPage';
import { ViewLegalEntityGroupPage } from 'pages/LegalEntitiesPage/ViewLegalEntityGroupPage';
import { LegalEntityGroupAddFirearmPage } from 'pages/LegalEntitiesPage/LegalEntityGroupAddFirearmPage';
import { LegalEntityAddGroupPage } from 'pages/LegalEntitiesPage/LegalEntityAddGroupPage';
import { LegalEntityDeleteGroupPage } from 'pages/LegalEntitiesPage/LegalEntityDeleteGroupPage';
import { LegalEntityEditGroupPage } from 'pages/LegalEntitiesPage/LegalEntityEditGroupPage';
import { RemoveDevicePage } from 'pages/AccountPage/RemoveDevicePage';
import { ChangePasswordPage } from 'pages/AccountPage/ChangePasswordPage';
import { EditAccountPage } from 'pages/AccountPage/EditAccountPage';
import { ViewUserPage } from 'pages/UsersPage/ViewUserPage';
import { EditUserStatusPage } from 'pages/UsersPage/EditUserStatusPage';
import AssignLESearchPage from 'pages/UsersPage/AssignLESearchPage';
import { AssignLEPage } from 'pages/UsersPage/AssignLEPage';
import { EditUserPage } from 'pages/UsersPage/EditUserPage';
import { GeneratePasswordUserPage } from 'pages/UsersPage/GeneratePasswordUserPage';
import { DeleteAssignedLEPage } from 'pages/UsersPage/DeleteAssignedLEPage';
import { LegalEntityGroupFirearmSearchPage } from 'pages/LegalEntitiesPage/LegalEntityGroupFirearmSearchPage';
import { SavePresetPage } from 'pages/LegalEntitiesPage/SavePresetPage';
import { MyFiltersPage } from 'pages/MyFiltersPage';
import { DeleteFilterPage } from 'pages/MyFiltersPage/DeleteFilterPage';
import { LegalEntityAddFirearmPage } from 'pages/LegalEntitiesPage/LegalEntityAddFirearmPage';
import { LegalEntityAddAncillaryPage } from 'pages/LegalEntitiesPage/LegalEntityAddAncillaryPage';
import { LegalEntityAddTransactionForGroupPage } from 'pages/LegalEntitiesPage/LegalEntityAddTransactionForGroupPage';
import SearchForFRTFirearmPage from 'pages/Transactions/SearchForFRTFirearmPage';
import SearchForARTAncillaryPage from 'pages/Transactions/SearchForARTAncillaryPage';
import { FRTFirearmDetailsPage } from 'pages/Transactions/FRTFirearmDetailsPage';
import { RegisteredFirearmsMapPage } from 'pages/Firearms/RegisteredFirearmsMapPage';
import { SaveFirearmsPresetPage } from 'pages/Firearms/SaveFirearmsPresetPage';
import { FRTDetailsPage } from 'pages/FRT/FRTDetailsPage';
import { FRTEditPage } from 'pages/FRT/FRTEditPage';
import { FRTAddCalibrePage } from 'pages/FRT/FRTAddCalibrePage';
import { FRTEditCalibrePage } from 'pages/FRT/FRTEditCalibrePage';
import { SelectLegalEntityPage } from 'pages/LegalEntitiesPage/SelectLegalEntityPage';
import { FirearmsReferenceTableDetailsPage } from 'pages/Transactions/FirearmsReferenceTableDetailsPage';
import { SelectLocationPage } from 'pages/Transactions/SelectLocationPage';
import { FRTAddPage } from 'pages/FRT/FRTAddPage';
import ViewTransactionPage from 'pages/TransactionsPage/ViewTransactionPage';
import CancelTransactionPage from 'pages/TransactionsPage/CancelTransactionPage';
import { TransactionAddCommentPage } from 'pages/TransactionsPage/TransactionAddCommentPage';
import { TransactionDeleteCommentPage } from 'pages/TransactionsPage/TransactionDeleteCommentPage';
import { SaveTransactionPresetPage } from 'pages/TransactionsPage/SaveTransactionPresetPage';
import { ViewNotificationPage } from 'pages/NotificationsPage/ViewNotificationPage';
import { DisableRegisteredFirearmPage } from 'pages/Firearms/DisableRegisteredFirearmPage';
import DashboardSearchResultsPage from 'pages/DashboardSearchResultsPage';
import CustomFieldsPage from 'pages/CustomFieldsPage';
import AddCustomFieldPage from 'pages/CustomFieldsPage/AddCustomFieldPage';
import ToggleCustomFieldPage from 'pages/CustomFieldsPage/ToggleCustomFieldPage';
import EditCustomFieldPage from 'pages/CustomFieldsPage/EditCustomFieldPage';
import { NotFoundPage } from 'pages/NotFoundPage';
import { AccessDeniedPage } from 'pages/AccessDeniedPage';
import { ServerErrorPage } from 'pages/ServerErrorPage';
import DetailAncillariesReference from 'pages/AncillariesPage/AncillariesReferenceTablePage/DetailAncillariesReference';
import AuditLogPage from 'pages/AuditLogPage';
import AuthenticationSetupPage from 'pages/SystemSettingsPage/AuthenticationSetupPage';
import DetailAccessories from 'pages/AncillariesPage/AccessoriesPage/DetailAccessories';
import DetailComponents from 'pages/AncillariesPage/ComponentsPage/DetailComponents';
import DetailAmmunition from 'pages/AncillariesPage/AmmunitionPage/DetailAmmunition';
import AuditLogViewPage from 'pages/AuditLogPage/AuditLogViewPage';
import { ViewRegisteredFirearmAttachment } from 'pages/Firearms/ViewRegisteredFirearmAttachment';
import { AccessoriesDeleteCommentPage } from 'pages/AncillariesPage/AccessoriesPage/DetailAccessories/AccessoriesDeleteCommentPage';
import { AccessoriesAddCommentPage } from 'pages/AncillariesPage/AccessoriesPage/DetailAccessories/AccessoriesAddCommentPage';
import { AccessoriesAddAttachmentPage } from 'pages/AncillariesPage/AccessoriesPage/DetailAccessories/AccessoriesAddAttachmentPage';
import { AccessoriesAttachmentViewPage } from 'pages/AncillariesPage/AccessoriesPage/DetailAccessories/AccessoriesAttachmentViewPage';
import { AccessoriesDeleteAttachmentPage } from 'pages/AncillariesPage/AccessoriesPage/DetailAccessories/AccessoriesDeleteAttachmentPage';
import { ComponentsDeleteCommentPage } from 'pages/AncillariesPage/ComponentsPage/DetailComponents/ComponentsDeleteCommentPage';
import { ComponentsAddCommentPage } from 'pages/AncillariesPage/ComponentsPage/DetailComponents/ComponentsAddCommentPage';
import { ComponentsAddAttachmentPage } from 'pages/AncillariesPage/ComponentsPage/DetailComponents/ComponentsAddAttachmentPage';
import { ComponentsAttachmentViewPage } from 'pages/AncillariesPage/ComponentsPage/DetailComponents/ComponentsAttachmentViewPage';
import { ComponentsDeleteAttachmentPage } from 'pages/AncillariesPage/ComponentsPage/DetailComponents/ComponentsDeleteAttachmentPage';
import { RegisteredAncillariesMapPage } from 'pages/AncillariesPage/AncillariesMap';
import { SaveAncillariesPresetPage } from 'pages/AncillariesPage/SaveAncillariesPresetPage/SaveAncillariesPresetPage';
import { AmmunitionDeleteCommentPage } from 'pages/AncillariesPage/AmmunitionPage/DetailAmmunition/AmmunitionDeleteCommentPage';
import { AmmunitionAddCommentPage } from 'pages/AncillariesPage/AmmunitionPage/DetailAmmunition/AmmunitionAddCommentPage';
import { AmmunitionAddAttachmentPage } from 'pages/AncillariesPage/AmmunitionPage/DetailAmmunition/AmmunitionAddAttachmentsPage';
import { AmmunitionAttachmentsViewPage } from 'pages/AncillariesPage/AmmunitionPage/DetailAmmunition/AmmunitionAttachmentViewPage';
import { AmmunitionDeleteAttachmentPage } from 'pages/AncillariesPage/AmmunitionPage/DetailAmmunition/AmmunitionDeleteAttachmentPage';
import AncillariesAddTransactionPage from 'pages/AncillariesPage/AncillariesAddTransactionPage';
import DisableAncillaryPage from 'pages/AncillariesPage/DisableAncillaryPage';
import EditAncillaryPage from 'pages/AncillariesPage/EditAncillaryPage';
import { LicencePage } from 'pages/LicenceDetailsPage';
import { LegalEntityAddAncillaryGroupPage } from 'pages/LegalEntitiesPage/LegalEntityAddAncillaryGroupPage';
import LegalEntityAddAncillaryGroupSearchPage from 'pages/LegalEntitiesPage/LegalEntityAddAncillaryGroupSearchPage';
import { LegalEntityEditAncillaryGroupPage } from 'pages/LegalEntitiesPage/LegalEntityEditAncillaryGroupPage';
import { LegalEntityDeleteAncillariesGroupPage } from 'pages/LegalEntitiesPage/LegalEntityDeleteAncillariesGroupPage';
import { ViewLegalEntityAncillaryGroupPage } from 'pages/LegalEntitiesPage/ViewLegalEntityAncillaryGroupPage';
import ApiKeysPage from 'pages/ApiKeysPage';
import AddApiKeyPage from 'pages/ApiKeysPage/AddApiKeyPage';
import EditApiKeyPage from 'pages/ApiKeysPage/EditApiKeyPage';
import ViewApiKeyPage from 'pages/ApiKeysPage/ViewApiKeyPage';
import DeleteApiKeyPage from 'pages/ApiKeysPage/DeleteApiKeyPage';
import LegalEntityAddTransactionForAncillaryGroupPage from 'pages/LegalEntitiesPage/LegalEntityAddTransactionForAncillaryGroupPage';
import ReplaceLicencePage from 'pages/LicenceDetailsPage/ReplaceLicencePage';
import StartLicencePage from 'pages/StartLicencePage';
import LogoSetupPage from 'pages/SystemSettingsPage/LogoSetupPage';
import { DocumentsGeneratingSetupPage } from 'pages/SystemSettingsPage/DocumentsGeneratingSetupPage';
import { DeleteUserPage } from 'pages/UsersPage/DeleteUserPage';
import { LegalEntityGroupDeleteFirearmPage } from 'pages/LegalEntitiesPage/LegalEntityGroupDeleteFirearmPage';
import { LegalEntityGroupDeleteAncillaryPage } from 'pages/LegalEntitiesPage/LegalEntityGroupDeleteAncillaryPage';

export const PageComponentTypes = {
  [EPageComponentTypes.AuthPage]: AuthPage,
  [EPageComponentTypes.ForgotPasswordPage]: ForgotPasswordPage,
  [EPageComponentTypes.ResetPasswordPage]: ResetPasswordPage,
  [EPageComponentTypes.ResetPasswordLink]: ResetPasswordPage,
  [EPageComponentTypes.DashboardPage]: DashboardPage,
  [EPageComponentTypes.DashboardSearchResultsPage]: DashboardSearchResultsPage,
  [EPageComponentTypes.LegalEntitiesPage]: LegalEntitiesPage,
  [EPageComponentTypes.LegalEntitiesExpand]: LegalEntitiesPage,
  [EPageComponentTypes.AddLegalEntityPage]: AddLegalEntityPage,
  [EPageComponentTypes.EditLegalEntityPage]: EditLegalEntityPage,
  [EPageComponentTypes.DeleteLegalEntityPage]: DeleteLegalEntityPage,
  [EPageComponentTypes.ViewLegalEntityPage]: ViewLegalEntityPage,
  [EPageComponentTypes.LegalEntityAddCommentPage]: LegalEntityAddCommentPage,
  [EPageComponentTypes.LegalEntityDeleteCommentPage]: LegalEntityDeleteCommentPage,
  [EPageComponentTypes.LegalEntityAddAddressPage]: LegalEntityAddAddressPage,
  [EPageComponentTypes.LegalEntityEditAddressPage]: LegalEntityEditAddressPage,
  [EPageComponentTypes.LegalEntityDeleteAddressPage]: LegalEntityDeleteAddressPage,
  [EPageComponentTypes.LegalEntityAddContactPage]: LegalEntityAddContactPage,
  [EPageComponentTypes.LegalEntityEditContactPage]: LegalEntityEditContactPage,
  [EPageComponentTypes.LegalEntityDeleteContactPage]: LegalEntityDeleteContactPage,
  [EPageComponentTypes.LegalEntityAddGroupPage]: LegalEntityAddGroupPage,
  [EPageComponentTypes.LegalEntityGroupFirearmSearchPage]: LegalEntityGroupFirearmSearchPage,
  [EPageComponentTypes.LegalEntityEditGroupPage]: LegalEntityEditGroupPage,
  [EPageComponentTypes.LegalEntityDeleteGroupPage]: LegalEntityDeleteGroupPage,
  [EPageComponentTypes.LegalEntityGroupDeleteFirearmPage]: LegalEntityGroupDeleteFirearmPage,
  [EPageComponentTypes.LegalEntityGroupDeleteAncillaryPage]:
    LegalEntityGroupDeleteAncillaryPage,
  [EPageComponentTypes.ViewLegalEntityGroupPage]: ViewLegalEntityGroupPage,
  [EPageComponentTypes.LegalEntityGroupAddFirearmPage]: LegalEntityGroupAddFirearmPage,
  [EPageComponentTypes.SearchForFRTFirearmPage]: SearchForFRTFirearmPage,
  [EPageComponentTypes.SearchForARTAncillaryPage]: SearchForARTAncillaryPage,
  [EPageComponentTypes.LegalEntityAddAncillaryPage]: LegalEntityAddAncillaryPage,
  [EPageComponentTypes.LegalEntityAddAncillaryGroupPage]: LegalEntityAddAncillaryGroupPage,
  [EPageComponentTypes.ViewLegalEntityAncillaryGroupPage]: ViewLegalEntityAncillaryGroupPage,
  [EPageComponentTypes.LegalEntityEditAncillaryGroupPage]: LegalEntityEditAncillaryGroupPage,
  [EPageComponentTypes.LegalEntityDeleteAncillariesGroupPage]:
    LegalEntityDeleteAncillariesGroupPage,
  [EPageComponentTypes.LegalEntityAddAncillaryGroupSearchPage]:
    LegalEntityAddAncillaryGroupSearchPage,
  [EPageComponentTypes.LegalEntityAddFirearmPage]: LegalEntityAddFirearmPage,
  [EPageComponentTypes.LegalEntityAddTransactionForGroupPage]:
    LegalEntityAddTransactionForGroupPage,
  [EPageComponentTypes.LegalEntityAddTransactionForAncillaryGroupPage]:
    LegalEntityAddTransactionForAncillaryGroupPage,
  [EPageComponentTypes.FRTFirearmDetailsPage]: FRTFirearmDetailsPage,
  [EPageComponentTypes.FRTDetailsPage]: FRTDetailsPage,
  [EPageComponentTypes.FRTAddCalibrePage]: FRTAddCalibrePage,
  [EPageComponentTypes.FRTEditCalibrePage]: FRTEditCalibrePage,
  [EPageComponentTypes.EditFRTPage]: FRTEditPage,
  [EPageComponentTypes.AddFRTPage]: FRTAddPage,
  [EPageComponentTypes.FirearmsReferenceTableDetailsPage]: FirearmsReferenceTableDetailsPage,
  [EPageComponentTypes.SelectLocationPage]: SelectLocationPage,
  [EPageComponentTypes.FirearmsPage]: FirearmsPage,
  [EPageComponentTypes.SaveFirearmsPresetPage]: SaveFirearmsPresetPage,
  [EPageComponentTypes.ViewRegisteredFirearmPage]: ViewRegisteredFirearmPage,
  [EPageComponentTypes.EditRegisteredFirearmPage]: EditRegisteredFirearmPage,
  [EPageComponentTypes.DisableRegisteredFirearmPage]: DisableRegisteredFirearmPage,
  [EPageComponentTypes.ViewRegisteredFirearmAttachment]: ViewRegisteredFirearmAttachment,
  [EPageComponentTypes.RegisteredFirearmAddCommentPage]: RegisteredFirearmAddCommentPage,
  [EPageComponentTypes.RegisteredFirearmDeleteCommentPage]: RegisteredFirearmDeleteCommentPage,
  [EPageComponentTypes.RegisteredFirearmDeleteAttachmentPage]:
    RegisteredFirearmDeleteAttachmentPage,
  [EPageComponentTypes.RegisteredFirearmAddAttachmentPage]: RegisteredFirearmAddAttachmentPage,
  [EPageComponentTypes.RegisteredFirearmsMapPage]: RegisteredFirearmsMapPage,
  [EPageComponentTypes.RegisteredAncillariesMapPage]: RegisteredAncillariesMapPage,
  [EPageComponentTypes.SelectLegalEntityPage]: SelectLegalEntityPage,
  [EPageComponentTypes.AncillariesPage]: AncillariesPage,
  [EPageComponentTypes.AddAncillaries]: AddAncillariesReference,
  [EPageComponentTypes.DetailAncillaries]: DetailAncillariesReference,
  [EPageComponentTypes.DisableAncillary]: DisableAncillaryPage,
  [EPageComponentTypes.AncillaryDetails]: DetailAncillariesReference,
  [EPageComponentTypes.AccessoriesDeleteCommentPage]: AccessoriesDeleteCommentPage,
  [EPageComponentTypes.AccessoriesAddCommentPage]: AccessoriesAddCommentPage,
  [EPageComponentTypes.AccessoriesAddAttachmentPage]: AccessoriesAddAttachmentPage,
  [EPageComponentTypes.AccessoriesViewAttachmentPage]: AccessoriesAttachmentViewPage,
  [EPageComponentTypes.AccessoriesDeleteAttachmentPage]: AccessoriesDeleteAttachmentPage,
  [EPageComponentTypes.ComponentsDeleteCommentPage]: ComponentsDeleteCommentPage,
  [EPageComponentTypes.ComponentsAddCommentPage]: ComponentsAddCommentPage,
  [EPageComponentTypes.ComponentsAddAttachmentPage]: ComponentsAddAttachmentPage,
  [EPageComponentTypes.ComponentsViewAttachmentPage]: ComponentsAttachmentViewPage,
  [EPageComponentTypes.ComponentsDeleteAttachmentPage]: ComponentsDeleteAttachmentPage,
  [EPageComponentTypes.AmmunitionAddAttachmentPage]: AmmunitionAddAttachmentPage,
  [EPageComponentTypes.AmmunitionViewAttachmentPage]: AmmunitionAttachmentsViewPage,
  [EPageComponentTypes.AmmunitionDeleteAttachmentPage]: AmmunitionDeleteAttachmentPage,
  [EPageComponentTypes.AmmunitionDeleteCommentPage]: AmmunitionDeleteCommentPage,
  [EPageComponentTypes.AmmunitionAddCommentPage]: AmmunitionAddCommentPage,
  [EPageComponentTypes.DetailAccessories]: DetailAccessories,
  [EPageComponentTypes.DetailComponents]: DetailComponents,
  [EPageComponentTypes.DetailAmmunition]: DetailAmmunition,
  [EPageComponentTypes.EditAncillaries]: EditAncillariesReference,
  [EPageComponentTypes.EditAncillary]: EditAncillaryPage,
  [EPageComponentTypes.AncillariesAddTransactionPage]: AncillariesAddTransactionPage,
  [EPageComponentTypes.TransactionsPage]: TransactionsPage,
  [EPageComponentTypes.ViewTransactionPage]: ViewTransactionPage,
  [EPageComponentTypes.CancelTransactionPage]: CancelTransactionPage,
  [EPageComponentTypes.TransactionAddCommentPage]: TransactionAddCommentPage,
  [EPageComponentTypes.TransactionDeleteCommentPage]: TransactionDeleteCommentPage,
  [EPageComponentTypes.SaveTransactionPresetPage]: SaveTransactionPresetPage,
  [EPageComponentTypes.UsersPage]: UsersPage,
  [EPageComponentTypes.AddUserPage]: AddUserPage,
  [EPageComponentTypes.EditUserPage]: EditUserPage,
  [EPageComponentTypes.GeneratePasswordUserPage]: GeneratePasswordUserPage,
  [EPageComponentTypes.ViewUserPage]: ViewUserPage,
  [EPageComponentTypes.EditUserStatusPage]: EditUserStatusPage,
  [EPageComponentTypes.AssignLESearchPage]: AssignLESearchPage,
  [EPageComponentTypes.AssignLEPage]: AssignLEPage,
  [EPageComponentTypes.DeleteAssignedLEPage]: DeleteAssignedLEPage,
  [EPageComponentTypes.RolesPage]: RolesPage,
  [EPageComponentTypes.DictionariesPage]: DictionariesPage,
  [EPageComponentTypes.LicencePage]: LicencePage,
  [EPageComponentTypes.ReplaceLicencePage]: ReplaceLicencePage,
  [EPageComponentTypes.ViewDictionaryPage]: ViewDictionaryPage,
  [EPageComponentTypes.EditDictionaryPage]: EditDictionaryValuePage,
  [EPageComponentTypes.AddDictionaryValuePage]: AddDictionaryValuePage,
  [EPageComponentTypes.EditRolePage]: EditRolePage,
  [EPageComponentTypes.ViewRolePage]: ViewRolePage,
  [EPageComponentTypes.AddRolePage]: AddRolePage,
  [EPageComponentTypes.DeleteRolePage]: DeleteRolePage,
  [EPageComponentTypes.AdministrationPage]: AdministrationPage,
  [EPageComponentTypes.SystemSettingsPage]: SystemSettingsPage,
  [EPageComponentTypes.NotificationsSetupPage]: NotificationsSetupPage,
  [EPageComponentTypes.AuthenticationSetupPage]: AuthenticationSetupPage,
  [EPageComponentTypes.LogosSetupPage]: LogoSetupPage,
  [EPageComponentTypes.DocumentsGeneratingSetupPage]: DocumentsGeneratingSetupPage,
  [EPageComponentTypes.AuditLogPage]: AuditLogPage,
  [EPageComponentTypes.AuditLogViewPage]: AuditLogViewPage,
  [EPageComponentTypes.DateTimeSetupPage]: DateTimeSetupPage,
  [EPageComponentTypes.CustomFieldsPage]: CustomFieldsPage,
  [EPageComponentTypes.AddCustomFieldPage]: AddCustomFieldPage,
  [EPageComponentTypes.EditCustomFieldPage]: EditCustomFieldPage,
  [EPageComponentTypes.ToggleCustomFieldPage]: ToggleCustomFieldPage,
  [EPageComponentTypes.NotificationsPage]: NotificationsPage,
  [EPageComponentTypes.ViewNotificationPage]: ViewNotificationPage,
  [EPageComponentTypes.AccountPage]: AccountPage,
  [EPageComponentTypes.RemoveDevicePage]: RemoveDevicePage,
  [EPageComponentTypes.ChangePasswordPage]: ChangePasswordPage,
  [EPageComponentTypes.AccountEditPage]: EditAccountPage,
  [EPageComponentTypes.MyFiltersPage]: MyFiltersPage,
  [EPageComponentTypes.DeleteFilterPage]: DeleteFilterPage,
  [EPageComponentTypes.SavePresetPage]: SavePresetPage,
  [EPageComponentTypes.SaveAncillariesPresetPage]: SaveAncillariesPresetPage,
  [EPageComponentTypes.TableTestPage]: TableTestPage,
  [EPageComponentTypes.NotFoundPage]: NotFoundPage,
  [EPageComponentTypes.AccessDeniedPage]: AccessDeniedPage,
  [EPageComponentTypes.ServerErrorPage]: ServerErrorPage,
  [EPageComponentTypes.StyleGuidePage]: StyleGuidePage,
  [EPageComponentTypes.ApiKeysPage]: ApiKeysPage,
  [EPageComponentTypes.AddApiKeyPage]: AddApiKeyPage,
  [EPageComponentTypes.EditApiKeyPage]: EditApiKeyPage,
  [EPageComponentTypes.ViewApiKeyPage]: ViewApiKeyPage,
  [EPageComponentTypes.DeleteApiKeyPage]: DeleteApiKeyPage,
  [EPageComponentTypes.StartLicencePage]: StartLicencePage,
  [EPageComponentTypes.DeleteUserPage]: DeleteUserPage,
};
