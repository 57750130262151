import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { colors } from 'theme/colors';
import { FormCheckbox } from 'components/molecules/FormCheckbox';
import { useForm } from 'react-hook-form';
import {
  notificationListHeaderCheckboxStyle,
  StyledNotificationListHeaderItem,
} from 'pages/NotificationsPage/NotificationListHeader/styles';
import { useTranslations } from 'hooks/useTranslations';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';

interface INotificationListHeader {
  onChange: (arg: boolean) => void;
  isAllChecked: boolean;
}

const defaultValues = {
  isChecked: false,
};

const NotificationListHeader = ({ onChange, isAllChecked }: INotificationListHeader) => {
  const { t } = useTranslations();
  const {
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues,
  });

  useEffect(() => {
    setValue('isChecked', isAllChecked);
  }, [isAllChecked]);

  return (
    <>
      <Grid
        item
        xs={12}
        container
        alignItems="center"
        sx={{
          margin: '4px 0',
          padding: '0px 12px',
          borderRadius: '4px',
          backgroundColor: colors.primary900,
          lineHeight: '20px',
          fontSize: '14px',
          fontWeight: '600',
          display: { xs: 'none', sm: 'flex' },
        }}
      >
        <Grid item xs={1}>
          <FormCheckbox
            name="isChecked"
            control={control}
            errors={errors}
            onChange={(isChecked) => onChange(isChecked)}
            style={notificationListHeaderCheckboxStyle}
          />
        </Grid>
        <StyledNotificationListHeaderItem item xs={2}>
          {t('notificationList.header.date')}
        </StyledNotificationListHeaderItem>
        <StyledNotificationListHeaderItem item xs={7}>
          {t('notificationList.header.subject')}
        </StyledNotificationListHeaderItem>
        <Grid item xs={2} container justifyContent="flex-end">
          <StyledNotificationListHeaderItem item>
            {t('notificationList.header.actions')}
          </StyledNotificationListHeaderItem>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        justifyContent="flex-end"
        mb={2}
        sx={{ display: { xs: 'flex', sm: 'none' } }}
      >
        {!isAllChecked ? (
          <Button
            label={t('notificationList.selectAll.button')}
            variant={EButtonVariants.text}
            onClick={() => onChange(true)}
          />
        ) : (
          <Button
            label={t('notificationList.unselectAll.button')}
            variant={EButtonVariants.text}
            onClick={() => onChange(false)}
          />
        )}
      </Grid>
    </>
  );
};

export { NotificationListHeader };
